import { countryIsoOptions } from '../values/country-iso.options'

export function formatAddressToDisplay(addr?: AddressType) {
  if (!addr) return ''
  const address: string[] = []
  address.push(`${addr.street ? addr.street : addr.city}${addr.streetNumber ? ' ' + addr.streetNumber : ''}`.trim())
  address.push(`${addr.zip ?? ''} ${addr.city ?? ''}`.trim())
  address.push(countryIsoOptions.find(x => x.value === addr.countryIso)?.label ?? '')
  return address.join(', ')
}

export type AddressType = { street?: string | null; streetNumber?: string | null; city?: string | null; zip?: string | null; countryIso?: string | null }
