import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import LoadingContainer from '../../../../components/LoadingContainer'
import FormField, { inputType } from '../../../../components/formComponents/FormField'
import { isAnyQueryLoading } from '../../../../services/functions/queryHelpers'
import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import { materialQueryItemType } from '../../../../services/queries/materialsQuery'
import { MaterialTypeEnum, ProductMaterialEntity, ProductMaterialInputArgs } from '../../../../graphql/aminTypes'
import { useUpsertProductMaterialMutation } from './mutations/upsert-product-material.mutation'
import { useProductMaterialComponents } from '../../../ProductMaterialComponents/queries/product-material-component.query'
import ToggleField from '../../../../components/formComponents/ToggleField'
import SelectField from '../../../../components/formComponents/SelectField'
import { setupFields } from '../../../../services/formsServices/setupFields'

export default function AddOrEditMaterial({ materialToEdit, onExit }: { materialToEdit?: ProductMaterialEntity; onExit: () => void }) {
  const formMethods = useForm<ProductMaterialInputArgs>({ defaultValues: { isActive: true, isForPublic: true } })
  const [sectionedMaterials, setSectionedMaterials] = useState<{
    materials: materialQueryItemType[]
    types: materialQueryItemType[]
    fractions: materialQueryItemType[]
  }>({ materials: [], types: [], fractions: [] })

  const [upsertProductMaterialMut, upsertProductMaterialQR] = useUpsertProductMaterialMutation()
  const productMaterialComponentsQR = useProductMaterialComponents()

  useEffect(() => {
    if (!productMaterialComponentsQR.data) return
    const materials: any[] = []
    const types: any[] = []
    const fractions: any[] = []
    for (let i = 0; i < productMaterialComponentsQR.data.productMaterialComponents.length; i++) {
      const material = productMaterialComponentsQR.data.productMaterialComponents[i]
      if (material.type === MaterialTypeEnum.Material) materials.push(material)
      if (material.type === MaterialTypeEnum.Type) types.push(material)
      if (material.type === MaterialTypeEnum.Fraction) fractions.push(material)
    }
    setSectionedMaterials({ fractions, materials, types })
  }, [productMaterialComponentsQR.data])

  useEffect(() => {
    if (materialToEdit) {
      setupFields(materialToEdit, {
        formMethods,
        includeKeys: ['name', 'tooltip', 'materialId', 'typeId', 'fractionId', 'cubicToTonnes', 'uiOrder', 'note', 'isActive', 'isForPublic']
      })
    }
  }, [materialToEdit, sectionedMaterials])

  return (
    <LoadingContainer showLoader={isAnyQueryLoading(upsertProductMaterialQR, productMaterialComponentsQR)}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <div className="row mb-2">
            <div className="col-md-6">
              <ToggleField name={'isActive'} label={'Aktivní'} />
            </div>
            <div className="col-md-6">
              <ToggleField name={'isForPublic'} label={'Dostupný veřejně všem zákazníkům na klientském portálu'} />
            </div>
          </div>
          <div className="row">
            <div className="col col-md-4">
              <FormField type={inputType.text} name={'name'} label={'Jméno, které se zobrazí zákazníkovi'} placeholder="Jméno" required />
            </div>
            <div className="col col-md-8">
              <FormField type={inputType.text} name={'tooltip'} label={'Tooltip'} placeholder="Přidat tooltip" />
            </div>
            <div className="col col-md-4 mt-2">
              <SelectField
                name={'materialId'}
                label={'Materiál'}
                registerOptions={{ required: 'Vyberte materiál' }}
                required
                optionsData={sectionedMaterials.materials.map(x => ({ label: x.name, value: x.id }))}
              />
            </div>
            <div className="col col-md-4 mt-2">
              <SelectField
                name={'typeId'}
                label={'Typ'}
                registerOptions={{ required: 'Vyberte type' }}
                required
                optionsData={sectionedMaterials.types.map(x => ({ label: x.name, value: x.id }))}
              />
            </div>
            <div className="col col-md-4 mt-2">
              <SelectField name={'fractionId'} label={'Frakce'} optionsData={sectionedMaterials.fractions.map(x => ({ label: x.name, value: x.id }))} />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col col-md-3 mt-3">
              <FormField type={inputType.number} is2Decimal name={'cubicToTonnes'} label={'Přepočet 1 kubík na tuny'} valueAsNumber />
            </div>
            <div className="col col-md-3 mt-3">
              <FormField type={inputType.number} isPositive name={'uiOrder'} label={'Pořadí'} placeholder="Pořadí na frontendu" required valueAsNumber />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col col-md-12">
              <FormField type={inputType.text} name={'note'} label={'Poznámka'} />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col col-is-12 text-right">
              <button className="btn btn-primary" type="submit">
                {materialToEdit ? 'Upravit materiál' : 'Přidat materiál'}
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </LoadingContainer>
  )

  function handleFormSubmit(data: any) {
    const resultPromise = upsertProductMaterialMut({
      variables: {
        data: data,
        productMaterialId: materialToEdit?.id
      }
    })
    processPageOperation({
      promise: resultPromise,
      successAction: onExit,
      successMessage: `Materiál ${materialToEdit ? 'upraven' : 'přidán'}.`
    })
    return false
  }
}
