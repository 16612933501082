import React from 'react'
import { ThemeProvider, createTheme, CssBaseline, Container } from '@mui/material'

const muiTheme = createTheme({
  // Custom theme settings for MUI components in this container
  palette: {
    primary: {
      main: '#fcce5b'
    },
    secondary: {
      main: '#3498db'
    }
  }
})

export const MuiScopedContainer = ({ children }) => {
  return (
    <ThemeProvider theme={muiTheme}>
      {/* CssBaseline resets MUI-specific styling without affecting global Bootstrap styles */}
      <CssBaseline />
      <Container
        sx={{
          marginTop: '20px', // Override margin-top
          maxWidth: '100% !important', // Override max-width
          width: '100%' // Set full width
        }}
      >
        {children}
      </Container>
    </ThemeProvider>
  )
}
