import { gql, useQuery } from '@apollo/client'

export const listOrderContentFilesQuery = gql`
  query listOrderContentFiles($orderContentId: Int!) {
    listOrderContentFiles(orderContentId: $orderContentId) {
      filename
      supplier
      size
      key
    }
  }
`

export function useListOrderContentFilesQuery(orderContentId?: number) {
  return useQuery<{ listOrderContentFiles: { filename: string; size: number }[] }>(listOrderContentFilesQuery, {
    skip: !orderContentId,
    fetchPolicy: 'network-only',
    variables: { orderContentId: orderContentId }
  })
}
