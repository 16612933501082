import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const updateContainerDisabledMutation = gql`
  mutation updateContainerDisabled($containerId: Int!, $disabled: Boolean!) {
    updateContainerDisabled(containerId: $containerId, disabled: $disabled) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpdateContainerDisabledMutation() {
  return useMutation<{ updateContainerDisabled: IMutationResult }>(updateContainerDisabledMutation)
}

export function createParamsForUpdateContainerDisabledMutation(containerId: number, disabled: boolean) {
  return { containerId: containerId, disabled }
}

