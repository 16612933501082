import { gql, useQuery } from '@apollo/client'
import { FioTransactionStateStat } from '../../../../graphql/aminTypes'

const getFioTransactionStateStatsQuery = gql`
  query getFioTransactionStateStats {
    getFioTransactionStateStats {
      status
      count
    }
  }
`

export function useGetFioTransactionStateStatsQuery() {
  return useQuery<{ getFioTransactionStateStats: FioTransactionStateStat[] }>(getFioTransactionStateStatsQuery, {
    fetchPolicy: 'network-only'
  })
}
