import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const sendSetPwdEmailMutation= gql`
  mutation sendSetPwdEmail($loginId: Int!) {
    sendSetPwdEmail(loginId: $loginId) {
      status {
        reason
        result
      }
    }
  }
`

export function useSendSetPwdEmailMutation() {
  return useMutation<{ sendSetPwdEmail: IMutationResult }>(sendSetPwdEmailMutation)
}
