import { useLockOrderMutation } from './mutations/lock-order.mutation'
import { useReleaseOrderLockMutation } from './mutations/release-order-lock.mutation'
import React, { useEffect } from 'react'
import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import { useGetOrderLockQuery } from '../../../../components/OrderLockInfo/queries/get-order-lock.query'
import OrderLockInfo from '../../../../components/OrderLockInfo/OrderLockInfo'

export default function OrderLock({ orderId }: { orderId?: number }) {
  const orderLockQR = useGetOrderLockQuery(orderId)
  const [lockOrder, lockOrderQR] = useLockOrderMutation()
  const [releaseLock, releaseOrderLockQR] = useReleaseOrderLockMutation()
  useEffect(() => {
    if (orderId)
      processPageOperation({
        promise: lockOrder({ variables: { orderId } }),
        successMessage: 'Objednávka je rezervována pro vás na 10 minut'
      })
    return () => {
      if (orderId)
        processPageOperation({
          promise: releaseLock({ variables: { orderId } })
        })
    }
  }, [orderId])
  return (
    <div className={'row'}>
      <div className={'col-md-9'}>
        <OrderLockInfo orderId={orderId} />
      </div>
    </div>
  )
}
