import { PriceCalculatorResponse } from '../../../../graphql/aminTypes'
import { MuiScopedContainer } from '../../../../components/mui-react-table/MuiScopedContainer'
import React, { useMemo, useState } from 'react'
import { MaterialReactTable, MRT_Row } from 'material-react-table'
import { calculationVisualizerColumnDefinition, CalculationVisualizerRow, priceBreakdownColumns } from './services/calculator-visualizer-table-definition'

export default function CalculationResultVisualizer({
  calculationResults,
  setSelectedResultIndex
}: {
  calculationResults: PriceCalculatorResponse[] | undefined
  setSelectedResultIndex: (index: { resultIndex: number; optionIndex: number }) => void
}) {
  const [rowSelection, setRowSelection] = useState({})

  // Use useMemo to cache the data mapping
  const data = useMemo<CalculationVisualizerRow[]>(() => {
    if (!calculationResults) return [] // Return an empty array if data is not yet available

    const resultMap = calculationResults.flatMap((result, resultIndex) =>
      result.calculationOptions.map((option, optionIndex) => ({
        id: `${resultIndex * 100 + optionIndex}`,
        type: option.type ?? 'N/A',
        supplier: `${result.supplierBranch?.supplier?.name} - ${result.supplierBranch?.address?.city}`,
        container: option.container ? `${option.container.name} ${option.container.volumeM3}m³` : 'N/A',
        truck: option.truck ? `${option.truck.name} ${option.truck.capacityTonne}t` : 'N/A',
        priceWoVatKc: option.priceWoVatKc,
        priceInclVatKc: option.priceInclVatKc,
        distanceKm: option.distanceKm,
        thirdPartySupplier: option.thirdPartySupplierBranch
          ? `${option.thirdPartySupplierBranch?.supplier?.name} - ${option.thirdPartySupplierBranch?.address?.city}${option.thirdPartySupplierBranch?.specification ? ` (${option.thirdPartySupplierBranch?.specification})` : ''}`
          : '',
        index: [resultIndex, optionIndex] as [number, number],
        priceBreakdown: option.priceBreakdown
      }))
    )
    return resultMap.sort((a, b) => a.priceInclVatKc - b.priceInclVatKc)
  }, [calculationResults])

  React.useEffect(() => {
    if (data.length > 0) {
      const firstRowId = data[0].id // Assuming customId is the unique identifier
      setRowSelection({ [firstRowId]: true })
    }
  }, [data])

  return (
    <MuiScopedContainer>
      <MaterialReactTable
        columns={calculationVisualizerColumnDefinition}
        data={data}
        getRowId={row => row.id}
        onRowSelectionChange={setRowSelectionHandler}
        enableRowSelection={true}
        enableMultiRowSelection={false}
        enableExpanding={true}
        initialState={{
          density: 'compact',
          sorting: [{ id: 'priceInclVatKc', desc: false }],
          pagination: { pageIndex: 0, pageSize: 25 }
        }}
        state={{ rowSelection }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => selectRowHandler(row),
          style: {
            style: { cursor: 'pointer' },
            backgroundColor: rowSelection[row.id] ? '#e0f7fa' : 'transparent' // Change color if row is selected
          }
        })}
        renderDetailPanel={({ row }) => (
          <MaterialReactTable
            enableTableHead={false}
            enableTopToolbar={false}
            columns={priceBreakdownColumns}
            data={row.original.priceBreakdown} // Pass the breakdown data to the nested table
            initialState={{ density: 'compact' }}
            enablePagination={false}
            enableSorting={false}
            enableRowSelection={false}
            enableExpanding={false}
            muiTableBodyRowProps={{
              style: { backgroundColor: 'transparent' }
            }}
          />
        )}
      />
    </MuiScopedContainer>
  )

  function setRowSelectionHandler(updaterOrValue: any) {
    setRowSelection(updaterOrValue)
    const selectedRowIndex = parseInt(Object.keys(updaterOrValue(rowSelection))[0])

    setSelectedResultIndex({ resultIndex: Math.floor(selectedRowIndex / 100), optionIndex: selectedRowIndex % 100 })
  }
  function selectRowHandler(row: MRT_Row<any>) {
    setSelectedResultIndex({ resultIndex: row.original.index[0], optionIndex: row.original.index[1] })
    row.toggleSelected(true)
  }
}
