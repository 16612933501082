import React from 'react'
import classNames from 'classnames'

export interface IHtmlDataListParams {
  label?: string
  data?: DataListItem[]
  className?: string
}

export type DataListItem = {
  iconBeforeLabel?: string
  label: any
  text: any
  fontWeightLight?: boolean
}

export function HtmlDataList({ label, data, className }: IHtmlDataListParams) {
  if (!data || data.length <= 0) return null
  return (
    <section className={classNames('form-group', className)}>
      {label && (
        <header>
          <h2>{label}</h2>
        </header>
      )}
      <dl className="row">
        {data.map(x => {
          return (
            <React.Fragment key={x.label}>
              <dt className={`col-6 ${x.fontWeightLight ? 'font-weight-light' : ''} `}>
                {x.iconBeforeLabel && <i className={`mr-2 fe ${x.iconBeforeLabel}`}></i>}
                {x.label}
              </dt>
              <dd className="col-6">{x.text}</dd>
            </React.Fragment>
          )
        })}
      </dl>
    </section>
  )
}
