import React, { useEffect } from 'react'
import { MapContainer, Marker, Polyline, Popup, TileLayer, Tooltip, useMap } from 'react-leaflet'
import L, { LatLngExpression, LatLngLiteral } from 'leaflet'
import { decodePolylineFromGeometry } from '../../../services/maps/decode-polyline-from-geometry'
import { SupplierBranchEntity } from '../../../graphql/aminTypes'
import { createMultiServiceIcon } from '../../../services/maps/create-multiservice-icon'
import { serviceCategoryColors } from '../../SupplierSupplierMap/services/service-category-colors'
import { MinimapControl } from '../../../services/maps/MiniMap'
import { MapUpdater } from '../../../services/maps/MapUpdater'
import { useNavigate } from 'react-router-dom'

export default function DistanceMap({
  customerPosition,
  supplierPosition,
  terminalPosition,
  encodedGeometry,
  availableBranches
}: {
  customerPosition?: LatLngLiteral
  supplierPosition?: LatLngLiteral
  terminalPosition?: LatLngLiteral
  encodedGeometry?: string
  availableBranches?: SupplierBranchEntity[]
}) {
  const navigate = useNavigate()
  let polylinePositions: LatLngExpression[] | undefined = undefined
  if (encodedGeometry) {
    polylinePositions = decodePolylineFromGeometry(encodedGeometry, false)
  }
  const mapProps = customerPosition
    ? {
        center: customerPosition,
        zoom: 11
      }
    : {}

  return (
    <MapContainer {...mapProps} scrollWheelZoom={true} style={{ height: '800px', width: '100%' }} className={'sticky-container'}>
      <TileLayer
        url="http://sgx.geodatenzentrum.de/wmts_topplus_open/tile/1.0.0/web/default/WEBMERCATOR/{z}/{y}/{x}.png"
        maxZoom={18}
        attribution='Map data: &copy; <a href="http://www.govdata.de/dl-de/by-2-0">dl-de/by-2-0</a>'
      />
      <MinimapControl position="topright" />
      {customerPosition && (
        <Marker position={customerPosition} icon={L.icon({ iconUrl: '/images/customerMapPoint.png', iconSize: [48, 48], iconAnchor: [24, 38] })}>
          <Tooltip>Lokace zákazníka</Tooltip>
        </Marker>
      )}
      {supplierPosition && (
        <Marker position={supplierPosition} icon={L.icon({ iconUrl: '/images/highlightedMapPoint.png', iconSize: [48, 48], iconAnchor: [24, 48] })}>
          <Tooltip>Lokace Dodavatele</Tooltip>
        </Marker>
      )}
      {terminalPosition && (
        <Marker position={terminalPosition}>
          <Tooltip>Lokace Koncovky</Tooltip>
        </Marker>
      )}
      {availableBranches &&
        availableBranches.map(branch => (
          <Marker
            key={branch.id}
            position={[branch.gps.lat, branch.gps.lng]}
            icon={createMultiServiceIcon(branch.branchServices!.map(sc => serviceCategoryColors[sc.serviceCategory]))}
          >
            <Popup>
              <h3>
                <a href={`/configuration/supplier-branch-list?supplierId=${branch.supplier?.id}&supplierBranchId=${branch.id}`} target="_blank">
                  {branch?.supplier?.name}
                </a>
              </h3>

              <br />
            </Popup>
          </Marker>
        ))}
      {polylinePositions && <Polyline positions={polylinePositions} color="blue" />}
      <MapUpdater
        customerGps={customerPosition ? customerPosition : undefined}
        supplier={supplierPosition ? supplierPosition : undefined}
        terminal={terminalPosition ? terminalPosition : undefined}
      />
    </MapContainer>
  )
}
