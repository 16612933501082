/* istanbul ignore file */

import {OperationVariables, QueryResult} from '@apollo/client'

//TDATA, TVARIABLES
export type IQueryResult<T = any> = QueryResult<T, Record<string, any> | OperationVariables>

/* istanbul ignore file */
export interface IMutationResult {
  status: {
    result: mutationStatusResult
    reason: string
  }
}

/* istanbul ignore file */
export interface IMutationResultWithId extends IMutationResult {
  id: number
}

export enum mutationStatusResult {
  success = 'SUCCESS',
  failed = 'FAILED'
}
