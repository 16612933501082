import { gql, useQuery } from '@apollo/client'
import { OrderContentListResult } from '../../../../../graphql/aminTypes'

export const orderList = gql`
  query orderContentList($offset: Int, $limit: Int, $name: String) {
    orderContentList(offset: $offset, limit: $limit, name: $name) {
      total
      items {
        id
        onsiteContactFirstname
        onsiteContactLastname
        onsiteContactPhone
        order {
          id
          orderNumber
          companyName
          firstname
          lastname
          email
          phone
          street
          streetNumber
          companyResidence {
            id
            city
            street
            streetNumber
            zip
          }
          city
          zip
          ico
          dic
        }
      }
    }
  }
`

export function useOrderListForAutocompleteQuery() {
  return useQuery<{ orderContentList: OrderContentListResult }>(orderList, {
    fetchPolicy: 'network-only',
    skip: true
  })
}
