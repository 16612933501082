import { ColumnFormatterTypes, IRowAction, RowActionType, TableDefinitionTypes } from '../../../services/bootstrapTable/services/tableSpecificationTypes'
import { ProductMaterialComponentEntity } from '../../../graphql/aminTypes'

export const productMaterialComponentDataDefinition: TableDefinitionTypes<ProductMaterialComponentEntity> = {
  columnDefinition: {
    id: { hidden: true },
    type: { hidden: true },
    uiOrder: { name: 'Pořadí na FE', editable: true },
    name: { name: 'Jméno', sort: true, editable: true },
    tooltip: { name: 'Tooltip', editable: true },
    cubicToTonnes: { name: 'Výchozí m³/t', editable: true },
    actions: { name: 'Akce', headerStyle: { width: '80px' }, columnFormatterType: ColumnFormatterTypes.actionsObject }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    type: x => x.type,
    uiOrder: x => x.uiOrder,
    name: x => x.name,
    tooltip: x => x.tooltip,
    cubicToTonnes: x => x.cubicToTonnes,
    actions: x => {
      const ret: IRowAction[] = []
      ret.push({
        type: RowActionType.codeAction,
        value: 'deleteMaterialComponent',
        icon: 'fe-x',
        title: 'Odstranit Materiálovou komponentu'
      })
      return ret
    }
  }
}
