import { gql, useQuery } from '@apollo/client'
import { contextEndpoint, EndpointEnum } from '../../../../services/appConfiguration/contextEndpoint'

export const terminalsQuery = gql`
  query terminals {
    terminals {
      id
      name
      city
    }
  }
`

export type TerminalsResult = {
  id: number
  name: string
  city: string
}

export function useTerminals() {
  return useQuery<{ terminals: TerminalsResult[] }>(terminalsQuery, { context: contextEndpoint(EndpointEnum.admin) })
}
