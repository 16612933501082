import { FormProvider, useForm } from 'react-hook-form'
import React, { useEffect } from 'react'
import { isAnyQueryLoading } from '../../../../services/functions/queryHelpers'
import FormField, { inputType } from '../../../../components/formComponents/FormField'
import WasteTypesSelector from '../../../../components/dataComponents/WasteTypesSelector/WasteTypesSelector'
import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import LoadingContainer from '../../../../components/LoadingContainer'
import { useWasteFinalsQuery } from './query/waste-finals.query'
import { createParamsForUpdateWasteFinalMutation, useUpdateWasteFinalMutation } from './mutation/update-waste-final.mutation'
import { createParamsForCreateWasteFinalMutation, useCreateWasteFinalMutation } from './mutation/create-waste-final.mutation'
import WasteTerminalAutocomplete from '../../../../components/dataComponents/WasteTerminalAutocomplete/WasteTerminalAutocomplete'
import { useSuggestIczQuery } from '../../../../components/dataComponents/WasteTerminalAutocomplete/suggest-icz.query'
import ToggleField from '../../../../components/formComponents/ToggleField'
import { WarningAlert } from '../../../../components/formComponents/alert'

export default function WasteFinalEditor({
  orderContentId,
  wasteFinalId,
  onFinish,
  supplierIcz
}: {
  orderContentId: number
  wasteFinalId?: string
  onFinish: () => void
  supplierIcz?: string | null
}) {
  const [updateWasteFinalMut, updateWasteFinalQR] = useUpdateWasteFinalMutation(orderContentId)
  const [createWasteFinalMut, createWasteFinalQR] = useCreateWasteFinalMutation(orderContentId)
  const suggestIczQuery = useSuggestIczQuery(false)

  const wasteFinalsQR = useWasteFinalsQuery(orderContentId, !wasteFinalId)
  const currentWasteFinal = wasteFinalsQR.data?.wasteFinals?.find(x => x.id === wasteFinalId)
  const formMethods = useForm({
    defaultValues: {
      wasteTypeId: currentWasteFinal?.wasteTypeId ?? undefined,
      weightTonne: currentWasteFinal?.weightTonne ?? undefined,
      icz: currentWasteFinal?.icz ?? undefined,
      terminalName: currentWasteFinal?.terminalName ?? ''
    }
  })

  useEffect(() => {
    if (!wasteFinalId && supplierIcz) {
      suggestIczQuery.refetch({ icz: supplierIcz }).then(result => {
        formMethods.setValue('terminalName', result.data.suggestIcz[0]?.name ?? '')
        formMethods.setValue('icz', supplierIcz)
      })
    }
  }, [])
  useEffect(() => {
    formMethods.setValue('wasteTypeId', currentWasteFinal?.wasteTypeId ?? undefined)
    formMethods.setValue('weightTonne', currentWasteFinal?.weightTonne ?? undefined)
    formMethods.setValue('icz', currentWasteFinal?.icz ?? '')
    formMethods.setValue('terminalName', currentWasteFinal?.terminalName ?? '')
  }, [currentWasteFinal])

  return (
    <LoadingContainer showLoader={isAnyQueryLoading(updateWasteFinalQR, createWasteFinalQR, wasteFinalsQR)}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
          <fieldset className="form-fieldset">
            <div className="row mb-2">
              <div className="col-md-12">
                <WasteTypesSelector />
                <FormField
                  type={inputType.number}
                  name={'weightTonne'}
                  registerOptions={{ required: true }}
                  label={'Množství [t]'}
                  is2Decimal
                  required
                  isPositive
                />
                <WasteTerminalAutocomplete isForSupplier={false} />
                {!wasteFinalId && <ToggleField name={'generateConfirmation'} label={'Automaticky vygenerovat potvrzení o odvozu odpadu po uložení'} />}
                {wasteFinalId && currentWasteFinal?.s3key && (
                  <>
                    <br></br>
                    <WarningAlert
                      message={'Pdf s potvrzením o odvozu odpadu už bylo vygenerováno. Přegenerujte jej ručně, pokud chcete změněné údaje promítnout.'}
                    />
                  </>
                )}
              </div>
            </div>
          </fieldset>
          <div className="d-flex justify-content-md-center">
            <button type="submit" className="btn btn-primary ms-auto btn-lg mr-1" onClick={handleFormSubmit}>
              Uložit změny
            </button>
            <button type="button" className="btn btn-secondary ms-auto btn-lg" onClick={onFinish}>
              Zpět
            </button>
          </div>
        </form>
      </FormProvider>
    </LoadingContainer>
  )

  function handleFormSubmit(data: any) {
    if (!data.wasteTypeId || !data.weightTonne) return
    let promise
    if (wasteFinalId) {
      promise = updateWasteFinalMut({
        variables: createParamsForUpdateWasteFinalMutation(wasteFinalId, data)
      })
    } else {
      promise = createWasteFinalMut({
        variables: createParamsForCreateWasteFinalMutation(orderContentId, data, data.generateConfirmation)
      })
    }
    processPageOperation({
      promise: promise,
      successMessage: 'Váženka byla úspěšně uložena.',
      successAction: result => {
        onFinish()
      }
    })
  }
}
