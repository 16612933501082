import React, { useState } from 'react'
import { JWT_TYPE } from './tokenType'
import { jwtDecode } from 'jwt-decode'
import { ApolloClient } from '@apollo/client'
import { logoutMutation } from './logoutMutation'
import { contextEndpoint, EndpointEnum } from '../appConfiguration/contextEndpoint'
import dayjs from 'dayjs'

export const LoginContext = React.createContext<
  { login: (token: string) => JWT_TYPE | undefined; logout: Function; currentJWT: JWT_TYPE | undefined; refreshToken: Function; at: string } | undefined
>(undefined)

export function useLogin(
  setTokenExpiration: Function,
  setAuthToken: Function,
  clearAutomaticTokenRefresh: Function,
  apolloClientRef: ApolloClient<any>,
  refreshToken: Function
) {
  const [currentJWT, setCurrentJWT] = useState<JWT_TYPE>()
  const [at, setAt] = useState<string>('')

  function login(token: string) {
    let decodedToken: JWT_TYPE | undefined
    if ((decodedToken = loadTokenIfValid(token))) {
      setAuthToken(token)
      setAt(token)
      setTokenExpiration(dayjs.unix(decodedToken.exp).toDate())
      clearAutomaticTokenRefresh()
      return decodedToken
    }
    return undefined
  }

  async function logout() {
    return apolloClientRef
      ?.mutate({
        mutation: logoutMutation,
        context: contextEndpoint(EndpointEnum.login)
      })
      .then(() => {
        setAuthToken(undefined)
        setCurrentJWT(undefined)
        return apolloClientRef.clearStore()
      })
  }

  return { login, logout, currentJWT, refreshToken, at }

  function loadTokenIfValid(token: string) {
    if (token) {
      let decodedToken: any
      try {
        decodedToken = jwtDecode(token)
      } catch (e) {
        return undefined
      }
      if (isDecodedTokenValid(decodedToken as JWT_TYPE)) {
        setCurrentJWT(decodedToken as JWT_TYPE)
        return decodedToken as JWT_TYPE
      } else return undefined
    }
  }

  function isDecodedTokenValid(decodedToken: JWT_TYPE) {
    return Math.floor(Date.now() / 1000) < decodedToken.exp
  }
}
