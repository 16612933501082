import React, { useEffect, useRef, useState } from 'react'
import { coordinatesToGps } from '../../services/functions/coordinatesToGps'
import { IStringObject } from '../../services/types/IStringObject'
import { GpsItemType, useSuppliersQuery, useSuppliersTypesQuery } from './services/mapsSourceQuery'
import { FormProvider, useForm } from 'react-hook-form'
import PageCard from '../../components/Cards/pageCard'
import ToggleField from '../../components/formComponents/ToggleField'
import SelectField from '../../components/formComponents/SelectField'
import { Circle, MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'
import { getParamFromUrl } from '../../services/functions/getParamFromUrl'
import L from 'leaflet'

// Helper component to update bounds
function UpdateCenterPosition({ supplier }: { supplier: GpsItemType | undefined }) {
  const map = useMap()

  useEffect(() => {
    if (supplier) {
      map.setView(coordinatesToGps(supplier.gps), 13)
    }
  }, [supplier, map])

  return null
}
//https://icons8.com/icons/set/map-point
export default function SupplierMap() {
  const sourceDataSuppliersTypes = useSuppliersTypesQuery()

  const sourceDataQR = useSuppliersQuery()
  const formMethods = useForm<any>({
    defaultValues: {
      showDisabled: false,
      supplierType: 'all'
    }
  })
  const showDisabled = formMethods.watch('showDisabled')
  const supplierType = formMethods.watch('supplierType')
  const enhanceSupplierId: number | '' | null = getParamFromUrl('sourceId') && parseInt(getParamFromUrl('sourceId') ?? '')
  const enhanceSupplier = sourceDataQR.data?.suppliers.find(s => s.id === enhanceSupplierId)

  return (
    <PageCard headerTitle={'Mapa dodavatelů'}>
      <FormProvider {...formMethods}>
        <div className="row">
          <div className="col-md-3">
            <ToggleField name={'showDisabled'} label={'Zobrazit neaktivní'} />
          </div>
          <div className="col-md-3" style={{ position: 'relative', bottom: 8 }}>
            <SelectField
              required
              optionsData={[
                { value: 'all', label: 'Vše' },
                { value: 'collection', label: 'Odvoz' },
                { value: 'delivery', label: 'Návoz' }
              ]}
              name={'supplierType'}
            ></SelectField>
          </div>
        </div>
      </FormProvider>
      <MapContainer center={{ lat: 50.0741169, lng: 14.4094478 }} zoom={8} style={{ height: '70vh', width: '100%' }}>
        <TileLayer
          url="http://sgx.geodatenzentrum.de/wmts_topplus_open/tile/1.0.0/web/default/WEBMERCATOR/{z}/{y}/{x}.png"
          maxZoom={18}
          attribution='Map data: &copy; <a href="http://www.govdata.de/dl-de/by-2-0">dl-de/by-2-0</a>'
        />
        {enhanceSupplierId && sourceDataQR.data && <UpdateCenterPosition supplier={sourceDataQR.data?.suppliers.find(s => s.id === enhanceSupplierId)} />}

        {sourceDataQR.data &&
          sourceDataSuppliersTypes.data &&
          Object.values(sourceDataQR.data)[0].map((mapItem: IStringObject) => {
            const position = coordinatesToGps(mapItem.gps)
            if (!showDisabled && mapItem.disabled) return null
            if (mapItem.name === 'Cenový strop') return null

            if (supplierType === 'delivery' && !sourceDataSuppliersTypes.data?.suppliersIdsWithTypes.find(x => x.id === mapItem.id)?.types.includes('DELIVERY'))
              return null
            if (
              supplierType === 'collection' &&
              !sourceDataSuppliersTypes.data?.suppliersIdsWithTypes.find(x => x.id === mapItem.id)?.types.includes('COLLECTION')
            )
              return null

            return (
              <Marker
                position={position}
                key={mapItem.id}
                title={`${mapItem.name} (${mapItem.regionCode ?? ''} - ${mapItem.orpName ?? ''})`}
                icon={
                  enhanceSupplier && enhanceSupplier.id === mapItem.id
                    ? L.icon({ iconUrl: '/images/highlightedMapPoint.png', iconSize: [48, 48], iconAnchor: [24, 48] })
                    : new L.Icon.Default()
                }
              >
                <Popup>
                  <strong>
                    <a href={`/supplier-list?id=${mapItem.id}`} target="_blank" rel="noopener noreferrer">
                      {mapItem.name}
                    </a>
                  </strong>
                </Popup>
                <Circle
                  center={position}
                  radius={mapItem.operationRadiusM}
                  pathOptions={{
                    color: mapItem.disabled ? '#7F7E7E' : mapItem.priority === 1 ? '#FF0000' : mapItem.priority === 2 ? '#FF7700' : '#FFFF00',
                    fillOpacity: 0
                  }}
                />
              </Marker>
            )
          })}
      </MapContainer>
    </PageCard>
  )
}
