import { gql, useQuery } from '@apollo/client'
import { GetAvailableServicesForLocalityResponse, PriceCalculatorResponse } from '../../../graphql/aminTypes'
import { contextEndpoint, EndpointEnum } from '../../../services/appConfiguration/contextEndpoint'
import { WasteCategoryType } from '../../../services/types/waste-category'

export const calculateCollectionServiceOptionsQuery = gql`
  query calculateCollectionServiceOptions(
    $customerLatLng: LatLngInput!
    $radiusKm: Int!
    $calculationDate: String!
    $allowNearestLargerContainer: String!
    $routingStrategy: String!
    $containerId: ID!
    $productId: ID!
    $wasteCategory: WasteCategory!
    $containerRentLengthDays: Int!
    $customContainerCapacity: Float
  ) {
    calculateCollectionServiceOptions(
      customerLatLng: $customerLatLng
      radiusKm: $radiusKm
      calculationDate: $calculationDate
      allowNearestLargerContainer: $allowNearestLargerContainer
      routingStrategy: $routingStrategy
      containerId: $containerId
      productId: $productId
      wasteCategory: $wasteCategory
      containerRentLengthDays: $containerRentLengthDays
      customContainerCapacity: $customContainerCapacity
    ) {
      supplierBranch {
        id
        gps {
          lat
          lng
        }
        address {
          id
          city
        }
        supplier {
          id
          name
        }
      }

      calculationOptions {
        distanceKm
        encodedGeometry
        priceBreakdown {
          amount
          note
          marginDecimal
          priceItemType {
            id
            name
          }
        }
        priceInclVatKc
        priceWoVatKc
        type
        truck {
          id
          name
          capacityTonne
        }
        container {
          id
          name
          volumeM3
        }
        thirdPartySupplierBranch {
          id
          specification
          address {
            id
            city
          }
          gps {
            lat
            lng
          }
          supplier {
            id
            name
          }
        }
      }
    }
  }
`

export function useCalculateCollectionServiceOptionsQuery() {
  return useQuery<{ calculateCollectionServiceOptions: PriceCalculatorResponse[] }>(calculateCollectionServiceOptionsQuery, {
    fetchPolicy: 'network-only',
    skip: true,
    context: contextEndpoint(EndpointEnum.admin)
  })
}

export function createCalculateCollectionServiceOptionsVariables(data: any, wasteCategory: WasteCategoryType) {
  return {
    customerLatLng: { lat: parseFloat(data.customerAddress['lat']), lng: parseFloat(data.customerAddress['lng']) },
    radiusKm: data.radiusKm,
    calculationDate: data.calculationDate,
    allowNearestLargerContainer: data.allowNearestLargerContainer,
    routingStrategy: data.routingStrategy,
    containerId: data.productContainerId,
    productId: data.productWasteId,
    wasteCategory: wasteCategory,
    containerRentLengthDays: data.containerRentLengthDays,
    customContainerCapacity: data.customContainerCapacity
  }
}
