import {
  ColumnAlign,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../../services/bootstrapTable/services/tableSpecificationTypes'
import { moneyToLocaleString } from '../../../../services/dataToString/decimalToLocaleString'
import {TerminalPriceListType} from "./terminalPriceListQuery";

export const terminalPriceListDataDefinition: TableDefinitionTypes<TerminalPriceListType> = {
  columnDefinition: {
    id: { hidden: true },
    rawData: { hidden: true },
    productCode: { name: 'Kód', sort: true},
    product: { name: 'Popis odpadu', sort: true },
    pricePerTonneKc: { name: 'Cena za tunu [Kč]', editable: true },
    actions: {
      name: 'Akce',
      columnFormatterType: ColumnFormatterTypes.actionsObject,
      align: ColumnAlign.right,
      headerStyle: { width: '60px' }
    }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    rawData: x => x,
    productCode: x => x.wasteType.code,
    product: x => x.wasteType.name,
    pricePerTonneKc: x => moneyToLocaleString(x.pricePerTonneKc),
    actions: x => {
      const ret: IRowAction[] = []
      ret.push({
        type: RowActionType.codeAction,
        value: 'deletePriceList',
        icon: 'fe-x',
        title: `Odstranit ${x.wasteType.name}`
      })
      return ret
    }
  }
}
