import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import LoadingContainer from '../../../../components/LoadingContainer'
import FormField, { inputType } from '../../../../components/formComponents/FormField'
import { isAnyQueryLoading } from '../../../../services/functions/queryHelpers'
import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import ToggleField from '../../../../components/formComponents/ToggleField'
import { ProductWasteEntity, ProductWasteInputArgs } from '../../../../graphql/aminTypes'
import WasteTypesSelector from '../../../../components/dataComponents/WasteTypesSelector/WasteTypesSelector'
import SelectField from '../../../../components/formComponents/SelectField'
import { useUpsertProductWasteMutation } from './mutations/upsert-product-waste.mutation'
import { wasteCategoryTypeOptions } from '../../../../services/types/waste-category'
import { setupFields } from '../../../../services/formsServices/setupFields'

export default function AddOrEditWastes({
  wasteToEdit,
  onExit,
  parentWaste
}: {
  wasteToEdit?: ProductWasteEntity
  onExit: () => void
  parentWaste?: ProductWasteEntity
}) {
  const formMethods = useForm<ProductWasteInputArgs>({ defaultValues: { isActive: true, isForPublic: true } })

  const [upsertProductWasteMut, upsertProductWasteQR] = useUpsertProductWasteMutation()

  useEffect(() => {
    if (wasteToEdit) {
      setupFields(wasteToEdit, {
        formMethods,
        includeKeys: [
          'name',
          'tooltip',
          'wasteCodeId',
          'wasteCategory',
          'uiOrder',
          'note',
          'isActive',
          'isForPublic',
          'wasteDescription',
          'acceptedWaste',
          'unsupportedWaste'
        ]
      })
    }
  }, [wasteToEdit])
  useEffect(() => {
    if (parentWaste) {
      setupFields(
        { ...parentWaste, parentWasteId: parentWaste.id },
        {
          formMethods,
          includeKeys: ['parentWasteId', 'name', 'tooltip', 'wasteCodeId', 'wasteCategory', 'uiOrder', 'note', 'isActive', 'isForPublic']
        }
      )
    }
  }, [parentWaste])
  return (
    <LoadingContainer showLoader={isAnyQueryLoading(upsertProductWasteQR)}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <div className="row mb-2">
            <div className="col-md-6">
              <ToggleField name={'isActive'} label={'Aktivní'} />
              <input type="hidden" value={undefined} {...formMethods.register('parentWasteId')} />
            </div>
            <div className="col-md-6">
              <ToggleField name={'isForPublic'} label={'Dostupný veřejně všem zákazníkům na klientském portálu'} />
            </div>
          </div>
          <div className="row">
            <div className="col col-md-4">
              <FormField type={inputType.text} name={'name'} label={'Jméno, které se zobrazí zákazníkovi'} placeholder="Jméno" required />
            </div>
            <div className="col col-md-8">
              <FormField type={inputType.text} name={'tooltip'} label={'Tooltip'} placeholder="Přidat tooltip" />
            </div>
          </div>
          <div className="row">
            <div className="col col-md-4">
              <WasteTypesSelector name={'wasteCodeId'} required={false} />
            </div>
            <div className="col col-md-8">
              <SelectField required name={'wasteCategory'} label={'Kategorie odpadu'} optionsData={wasteCategoryTypeOptions} />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col col-md-3 mt-3">
              <FormField type={inputType.number} isPositive name={'uiOrder'} label={'Pořadí'} placeholder="Pořadí na frontendu" required valueAsNumber />
            </div>
            <div className="col col-md-9">
              <FormField type={inputType.text} name={'note'} label={'Poznámka'} />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col col-md-12">
              <FormField name={'wasteDescription'} label={'Popis odpadu'} isTextArea={true} textAreaRows={3} />
            </div>
            <div className="col col-md-12">
              <FormField name={'acceptedWaste'} label={'Co patří do kontejneru'} isTextArea={true} textAreaRows={3} />
            </div>
            <div className="col col-md-12">
              <FormField name={'unsupportedWaste'} label={'Co nepatří do kontejneru'} isTextArea={true} textAreaRows={3} />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col col-is-12 text-right">
              <button className="btn btn-primary" type="submit">
                {wasteToEdit ? 'Upravit materiál' : 'Přidat materiál'}
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </LoadingContainer>
  )

  function handleFormSubmit(data: any) {
    if (!data.parentWasteId) data.parentWasteId = undefined
    const resultPromise = upsertProductWasteMut({
      variables: {
        data: data,
        productWasteId: wasteToEdit?.id
      }
    })
    processPageOperation({
      promise: resultPromise,
      successAction: onExit,
      successMessage: `Materiál ${wasteToEdit ? 'upraven' : 'přidán'}.`
    })
    return false
  }
}
