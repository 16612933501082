import { gql, useQuery } from '@apollo/client'
import { contextEndpoint, EndpointEnum } from '../../../../services/appConfiguration/contextEndpoint'

const orderContentStatesQuery = gql`
  query orderContentStates {
    orderContentStates {
      id
      name
      sysName
    }
  }
`

export function useOrderContentStatesQuery() {
  return useQuery<{ orderContentStates: { id: number; name: string; sysName: string }[] }>(orderContentStatesQuery, {
    context: contextEndpoint(EndpointEnum.admin)
  })
}
