export function decimalToLocaleString(value: number): string {
  return Number(value).toLocaleString('CS')
}

export function moneyToLocaleString(value?: any, currency?: string, hideDecimal?: boolean): string {
  if (!Number.isFinite(value)) return ''
  if (!currency)
    return Number(value).toLocaleString('CS', {
      maximumFractionDigits: !!hideDecimal ? 0 : 2,
      minimumFractionDigits: !!hideDecimal ? 0 : 2
    })
  return Number(value).toLocaleString('CS', {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: !!hideDecimal ? 0 : 2,
    minimumFractionDigits: !!hideDecimal ? 0 : 2
  })
}
