import { getParamFromUrl } from '../functions/getParamFromUrl'
import { removeParamFromSearchString } from '../functions/httpBuildQuery'
import { useLocation, useNavigate } from 'react-router-dom'
import { IMutationResultWithId } from '../types/gqlTypes'

export function useHighlightRowAfterReturn() {
  const enhancedRowId = getParamFromUrl('enhancedRowId')
  const navigate = useNavigate()
  const location = useLocation()

  function createReturnUrl(rowId: string) {
    let searchData = removeParamFromSearchString(location.search, ['scrollOffset', 'enhancedRowId'])
    searchData.push('scrollOffset=' + window.scrollY)
    searchData.push('enhancedRowId=' + rowId)
    return encodeURIComponent(location.pathname + '?' + searchData.join('&'))
  }

  function rowClassForHighlightedRow(customHighlight?: (row: any) => string) {
    return (row: any) => {
      if (!enhancedRowId || (enhancedRowId && parseInt(row.id) !== parseInt(enhancedRowId))) return customHighlight ? customHighlight(row) : ''
      if (parseInt(row.id) === parseInt(enhancedRowId)) return 'yellowBackground'
    }
  }

  function moveScrollPosition() {
    const offsetY = getParamFromUrl('scrollOffset')
    if (offsetY) {
      window.scrollTo({ left: 0, top: parseInt(offsetY), behavior: 'auto' })
    }
    const searchData = removeParamFromSearchString(location.search, ['scrollOffset'])
    const searchString = searchData.length > 0 ? '?' + searchData.join('&') : ''
    navigate(location.pathname + searchString)
  }

  return { rowClassForHighlightedRow, moveScrollPosition, createReturnUrl }
}

export function useReturnBackWithHighlight(defaultGoBackUrl?: string) {
  const navigate = useNavigate()
  const returnUrl = getParamFromUrl('returnUrl')

  return {
    returnBack: (queryResult?: IMutationResultWithId) => {
      if (returnUrl) {
        navigate(decodeURIComponent(returnUrl))
        return
      }
      if (defaultGoBackUrl) {
        if (queryResult && queryResult.id) navigate(`${defaultGoBackUrl}?enhancedRowId=${queryResult.id}`)
        else navigate(defaultGoBackUrl)
        return
      }
      navigate(-1)
    }
  }
}
