import {
  ColumnAlign,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../services/bootstrapTable/services/tableSpecificationTypes'
import React from 'react'
import { SupplierContainerEntity } from '../../../graphql/aminTypes'
import { moneyToLocaleString } from '../../../services/dataToString/decimalToLocaleString'

export const supplierContainerDataDefinition: TableDefinitionTypes<SupplierContainerEntity> = {
  columnDefinition: {
    id: { hidden: true },
    isActive: {
      name: 'Aktivní',
      columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
      align: ColumnAlign.center,
      type: 'boolean',
      headerStyle: { width: '80px' },
      editable: true
    },
    container: { name: 'Kontejner' },
    availableCount: { name: 'Počet dostupných', editable: true, type: 'number' },
    rentRateName: { name: 'Cenová sazba', editable: false },
    adrHandling: { name: 'ADR', align: ColumnAlign.center, type: 'boolean', columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter, editable: true },
    adrSurchargeKc: { name: 'ADR příplatek', editable: true, type: 'number' },
    isVirtualContainer: {
      name: 'Virtuální',
      align: ColumnAlign.center,
      type: 'boolean',
      columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
      editable: true
    },
    actions: { name: 'Akce', columnFormatterType: ColumnFormatterTypes.actionsObject, align: ColumnAlign.center, headerStyle: { width: '80px' } }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    isActive: x => x.isActive,
    container: x => (
      <>
        {x.container.name} {x.container.volumeM3} m³
      </>
    ),
    availableCount: x => x.availableCount,
    rentRateName: x => x.containerRentRate.name,
    adrHandling: x => x.adrHandling,
    adrSurchargeKc: x => moneyToLocaleString(x.adrSurchargeKc),
    isVirtualContainer: x => x.isVirtualContainer,
    actions: x => {
      const ret: IRowAction[] = []
      ret.push({
        type: RowActionType.codeAction,
        value: 'deleteContainer',
        icon: 'fe-x',
        title: 'Odstranit kontejner'
      })
      return ret
    }
  }
}
