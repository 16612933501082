import React, { useCallback, useContext, useState } from 'react'
import 'react-user-avatar/example.css'
import NavItem from './MenuItem'
import { LoginContext } from '../../services/loginAndToken/useLogin'
import classNames from 'classnames'
import { BsMinecart, BsTruckFlatbed } from 'react-icons/bs'
import { FaMapLocation, FaPeopleRoof, FaRegTrashCan, FaTruckRampBox } from 'react-icons/fa6'
import { GiConcreteBag, GiLiquidSoap, GiNuclearWaste, GiPriceTag, GiTruck } from 'react-icons/gi'
import { GrCalculator, GrDocumentConfig } from 'react-icons/gr'
import { BiSolidBusiness, BiSolidFactory } from 'react-icons/bi'
import { FaCarCrash, FaTrailer } from 'react-icons/fa'
import { TbTransitionBottom } from 'react-icons/tb'
import { IoBusiness } from 'react-icons/io5'
import { SelectedSupplierModalContext } from '../../ScenesAdminPriceLists/SelectedSupplierModal/SelectedSupplierModal'
import { useNavigate } from 'react-router-dom'
import { PiTrashSimpleBold } from 'react-icons/pi'
import { FiTriangle } from 'react-icons/fi'
import { MdOutlineFactCheck } from 'react-icons/md'

export default function HeaderPriceLists() {
  const login = useContext(LoginContext)
  const [showMenu, setShowMenu] = useState(false)
  const navigate = useNavigate()
  const hideMenu = useCallback(() => setShowMenu(false), [])
  const { state } = useContext(SelectedSupplierModalContext)
  if (!login?.currentJWT?.isAdmin) return null
  return (
    <>
      <aside className="navbar navbar-vertical navbar-expand-lg navbar-dark d-print-none">
        <div className="container-fluid">
          <button
            className={`navbar-toggler ${showMenu ? '' : 'collapsed'}}`}
            type="button"
            onClick={() => {
              setShowMenu(before => !before)
            }}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <h1 className={'navbar-brand navbar-brand-autodark'}>
            <div className={'header__logo d-none-navbar-horizontal pe-0 pe-md-3'}>
              <a href={'/order-list'}>
                <img src={`/images/logo.svg`} height="32" alt="metrak.cz" className="navbar-brand-image" />
              </a>
              <div>
                Konfigurace
                <br /> ceníků
              </div>
            </div>
          </h1>

          <div className={classNames('navbar-collapse', showMenu ? '' : 'collapse')} id="navbar-menu">
            <ul className="navbar-nav pt-lg-3">
              <NavItem name={'Kontejnery'} link={'/configuration/container-list'} hideMenu={hideMenu} reactIcon={<BsMinecart />} />
              <NavItem name={'Odpady'} link={'/configuration/waste-list'} hideMenu={hideMenu} reactIcon={<FaRegTrashCan />} />
              <NavItem name={'Materiály'} link={'/configuration/material-list'} hideMenu={hideMenu} reactIcon={<GiNuclearWaste />} />
              <NavItem name={'Konfigurace Materiálů'} link={'/configuration/material-components'} hideMenu={hideMenu} reactIcon={<GrDocumentConfig />} />
              <NavItem name={'Konfigurace betonů'} link={'/configuration/concrete-components'} hideMenu={hideMenu} reactIcon={<GiConcreteBag />} />
              <hr />
              <input
                type={'text'}
                className={'form-control ml-2 mb-3 text-white border-secondary'}
                style={{ width: '90%' }}
                placeholder={'Hledat dodavatele'}
                id={'supplierSearch'}
                onKeyDown={searchForSupplier}
              />
              <NavItem name={'Kalkulátor'} link={'/configuration/calculator'} hideMenu={hideMenu} reactIcon={<GrCalculator />} />
              <NavItem name={'Kontrola ceníků'} link={'/configuration/price-list-check'} hideMenu={hideMenu} reactIcon={<MdOutlineFactCheck />} />
              <NavItem name={'Mapa dodavatelů'} link={'/configuration/supplier-map'} hideMenu={hideMenu} reactIcon={<FaMapLocation />} />
              <NavItem name={'Dodavatelé'} link={'/configuration/supplier-list'} hideMenu={hideMenu} reactIcon={<FaPeopleRoof />} />
              {state.selectedSupplier && (
                <>
                  <li className="nav-item active dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href={'#navbar-layout'}
                      onClick={goToLink(`/configuration/supplier-branch-list?supplierId=${state.selectedSupplier.id}`)}
                      role="button"
                    >
                      <span className="nav-link-icon d-md-none d-lg-inline-block">
                        <IoBusiness />
                      </span>
                      <span className="nav-link-title">{state.selectedSupplier.name}</span>
                    </a>
                  </li>
                  <div className={'ml-3'}>
                    <NavItem
                      name={'Pobočky a služby'}
                      link={`/configuration/supplier-branch-list?supplierId=${state.selectedSupplier.id}`}
                      hideMenu={hideMenu}
                      reactIcon={<BiSolidFactory />}
                    />
                    {state.selectedBranch && (
                      <>
                        <li className="nav-item active dropdown">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#navbar-layout"
                            role="button"
                            onClick={goToLink(`/configuration/supplier-branch-list?supplierId=${state.selectedSupplier.id}`)}
                          >
                            <span className="nav-link-icon d-md-none d-lg-inline-block">
                              <BiSolidBusiness />
                            </span>
                            <span className="nav-link-title">{`${state.selectedBranch.address?.city} - ${
                              state.selectedBranch.specification ? state.selectedBranch.specification : state.selectedBranch.address?.street
                            }`}</span>
                          </a>
                        </li>

                        <div className={'ml-2'}>
                          <NavItem
                            name={'Kontejnery'}
                            link={`/configuration/supplier/containers?supplierId=${state.selectedSupplier.id}&supplierBranchId=${state.selectedBranch.id}`}
                            hideMenu={hideMenu}
                            reactIcon={<FaTrailer />}
                          />
                          <NavItem
                            name={'Autodoprava'}
                            link={`/configuration/supplier/load-transport?supplierId=${state.selectedSupplier.id}&supplierBranchId=${state.selectedBranch.id}`}
                            hideMenu={hideMenu}
                            reactIcon={<GiTruck />}
                            enhanced={!!state.selectedBranch.branchServices?.find(x => x.serviceCategory === 'LOAD_TRANSPORT')}
                          />
                          <NavItem
                            name={'Pevný odpad'}
                            link={`/configuration/supplier/waste-collection-solid?supplierId=${state.selectedSupplier.id}&supplierBranchId=${state.selectedBranch.id}`}
                            hideMenu={hideMenu}
                            reactIcon={<PiTrashSimpleBold />}
                            enhanced={!!state.selectedBranch.branchServices?.find(x => x.serviceCategory === 'WASTE_COLLECTION_SOLID')}
                          />
                          <NavItem
                            name={'Kapalný odpad'}
                            link={`/configuration/supplier/waste-collection-liquid?supplierId=${state.selectedSupplier.id}&supplierBranchId=${state.selectedBranch.id}`}
                            hideMenu={hideMenu}
                            reactIcon={<GiLiquidSoap />}
                            enhanced={!!state.selectedBranch.branchServices?.find(x => x.serviceCategory === 'WASTE_COLLECTION_LIQUID')}
                          />
                          <NavItem
                            name={'Nebezpečný odpad'}
                            link={`/configuration/supplier/waste-collection-adr?supplierId=${state.selectedSupplier.id}&supplierBranchId=${state.selectedBranch.id}`}
                            hideMenu={hideMenu}
                            reactIcon={<GiNuclearWaste />}
                            enhanced={!!state.selectedBranch.branchServices?.find(x => x.serviceCategory === 'WASTE_COLLECTION_ADR')}
                          />
                          <NavItem
                            name={'Druhotné suroviny'}
                            link={`/configuration/supplier/waste-collection-scrap?supplierId=${state.selectedSupplier.id}&supplierBranchId=${state.selectedBranch.id}`}
                            hideMenu={hideMenu}
                            reactIcon={<FaCarCrash />}
                            enhanced={!!state.selectedBranch.branchServices?.find(x => x.serviceCategory === 'WASTE_COLLECTION_SCRAP')}
                          />
                          <NavItem
                            name={'Koncovka'}
                            link={`/configuration/supplier/waste-terminal?supplierId=${state.selectedSupplier.id}&supplierBranchId=${state.selectedBranch.id}`}
                            hideMenu={hideMenu}
                            reactIcon={<TbTransitionBottom />}
                            enhanced={!!state.selectedBranch.branchServices?.find(x => x.serviceCategory === 'WASTE_TERMINAL')}
                          />
                          <NavItem
                            name={'Doprava materiálů'}
                            link={`/configuration/supplier/transport-of-material?supplierId=${state.selectedSupplier.id}&supplierBranchId=${state.selectedBranch.id}`}
                            hideMenu={hideMenu}
                            reactIcon={<BsTruckFlatbed />}
                            enhanced={!!state.selectedBranch.branchServices?.find(x => x.serviceCategory === 'TRANSPORT_OF_MATERIAL')}
                          />
                          <NavItem
                            name={'Doprava betonu'}
                            link={`/configuration/supplier/transport-of-concrete?supplierId=${state.selectedSupplier.id}&supplierBranchId=${state.selectedBranch.id}`}
                            hideMenu={hideMenu}
                            reactIcon={<BsTruckFlatbed />}
                            enhanced={!!state.selectedBranch.branchServices?.find(x => x.serviceCategory === 'TRANSPORT_OF_CONCRETE')}
                          />
                          <NavItem
                            name={'Doprava paliva'}
                            link={`/configuration/supplier/transport-of-fuel?supplierId=${state.selectedSupplier.id}&supplierBranchId=${state.selectedBranch.id}`}
                            hideMenu={hideMenu}
                            reactIcon={<BsTruckFlatbed />}
                            enhanced={!!state.selectedBranch.branchServices?.find(x => x.serviceCategory === 'TRANSPORT_OF_FUEL')}
                          />
                          <NavItem
                            name={'Doprava strojů DEK'}
                            link={`/configuration/supplier/transport-of-dek-machinery?supplierId=${state.selectedSupplier.id}&supplierBranchId=${state.selectedBranch.id}`}
                            hideMenu={hideMenu}
                            reactIcon={<FaTruckRampBox />}
                            enhanced={!!state.selectedBranch.branchServices?.find(x => x.serviceCategory === 'TRANSPORT_OF_DEK_MACHINERY')}
                          />
                          <NavItem
                            name={'Prodej materiálu'}
                            link={`/configuration/supplier/material-seller?supplierId=${state.selectedSupplier.id}&supplierBranchId=${state.selectedBranch.id}`}
                            hideMenu={hideMenu}
                            reactIcon={<FiTriangle />}
                            enhanced={!!state.selectedBranch.branchServices?.find(x => x.serviceCategory === 'MATERIAL_SELLER')}
                          />
                          <NavItem
                            name={'Prodej betonu'}
                            link={`/configuration/supplier/concrete-seller?supplierId=${state.selectedSupplier.id}&supplierBranchId=${state.selectedBranch.id}`}
                            hideMenu={hideMenu}
                            reactIcon={<GiConcreteBag />}
                            enhanced={!!state.selectedBranch.branchServices?.find(x => x.serviceCategory === 'CONCRETE_SELLER')}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </ul>
          </div>
        </div>
      </aside>
    </>
  )
  function goToLink(link) {
    return (e: any) => {
      e.preventDefault()
      navigate(link)
    }
  }

  function searchForSupplier(e: any) {
    if (e.key === 'Enter') {
      const supplierSearch = document.getElementById('supplierSearch') as HTMLInputElement
      if (supplierSearch) {
        navigate(`/configuration/supplier-list?name=${supplierSearch.value}`)
        supplierSearch.value = ''
      }
    }
  }
}
