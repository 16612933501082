import React, { useContext } from 'react'
import { hasQueryArrayData, isAnyQueryLoading } from '../../../services/functions/queryHelpers'
import { useForm } from 'react-hook-form'
import LoadingContainer from '../../../components/LoadingContainer'
import BootstrapTable from 'react-bootstrap-table-next'
import useBootstrapTableDataMapper from '../../../services/bootstrapTable/useBootstrapTableDataMapper'
import { AccountingAdvanceAccountTransactionEntity, AccountingDocumentEntity } from '../../../graphql/aminTypes'
import { useAdvanceAccountTransactionsQuery } from './queries/customer-advance-accounts-transactions.query'
import { advanceAccountsTransactionsDataDefinition } from './services/advance-accounts-transactions-data-definition'

export default function CustomerAdvanceAccountTransactions({ advanceAccountId, exitHandler }: { advanceAccountId: string; exitHandler: () => void }) {
  const formMethods = useForm<Record<string, any>>()
  const transactionsQR = useAdvanceAccountTransactionsQuery(advanceAccountId)
  const { generateColumnListForBootstrapTable, mapDataArrayToTable } = useBootstrapTableDataMapper<AccountingAdvanceAccountTransactionEntity>(
    advanceAccountsTransactionsDataDefinition
  )

  const columnDefinition = generateColumnListForBootstrapTable({})

  let transactions: any[] = []
  if (transactionsQR.data && hasQueryArrayData(transactionsQR)) transactions = mapDataArrayToTable(transactionsQR.data.advanceAccountTransactions)

  return (
    <LoadingContainer showLoader={isAnyQueryLoading(transactionsQR)}>
      <div className="table-responsive-xl">
        <BootstrapTable
          bootstrap4
          striped
          condensed
          classes="table-responsive-lg digiTable"
          noDataIndication={() => <>"Žádné transakce nenalezeny"</>}
          keyField="id"
          data={transactions}
          columns={columnDefinition}
        />
      </div>
      <div className="modal-footer">
        <button type="button" className="btn me-auto" onClick={exitHandler}>
          Zpět
        </button>
      </div>
    </LoadingContainer>
  )
}
