import { gql, useQuery } from '@apollo/client'
import { CheckAdvanceAccountStatusResponseEnum, OrderContentsResult, PaymentMethodEnum, PaymentType } from '../../../graphql/aminTypes'
import { getParamFromUrl } from '../../../services/functions/getParamFromUrl'
import { Maybe } from 'graphql/jsutils/Maybe'

export const checkAdvanceAccountStatusQuery = gql`
  query checkAdvanceAccountStatus($orderId: ID!) {
    checkAdvanceAccountStatus(orderId: $orderId)
  }
`
export function useCheckAdvanceAccountStatusQuery(orderId?: number, paymentMethod?: Maybe<PaymentType> | undefined) {
  return useQuery<{ checkAdvanceAccountStatus: CheckAdvanceAccountStatusResponseEnum }>(checkAdvanceAccountStatusQuery, {
    fetchPolicy: 'network-only',
    skip: !paymentMethod || !orderId || paymentMethod !== PaymentType.AdvancePayment,
    variables: {
      orderId: orderId
    }
  })
}
