import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const updateSupplierContainerMutation = gql`
  mutation updateSupplierContainer($id: ID!, $data: UpdateSupplierContainerInputArgs!) {
    updateSupplierContainer(id: $id, data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpdateSupplierContainerMutation() {
  return useMutation<{ updateSupplierContainer: IMutationResult }>(updateSupplierContainerMutation, { refetchQueries: ['supplierContainerList'] })
}
