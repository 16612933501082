import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'

const upsertSupplierWasteCollectionFlatRateMutation = gql`
  mutation upsertSupplierWasteCollectionFlatRate($branchServiceId: ID!, $data: SupplierWasteCollectionFlatRateInputArgs!) {
    upsertSupplierWasteCollectionFlatRate(branchServiceId: $branchServiceId, data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpsertSupplierWasteCollectionFlatRateMutation() {
  return useMutation<{ upsertSupplierWasteCollectionFlatRate: IMutationResult }>(upsertSupplierWasteCollectionFlatRateMutation, {
    refetchQueries: ['supplierWasteCollectionFlatRates']
  })
}
