import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'
import { contextEndpoint, EndpointEnum } from '../../../../../services/appConfiguration/contextEndpoint'
import { orderDetailQuery } from '../../../queries/orderDetailQuery'
import { getParamFromUrl } from '../../../../../services/functions/getParamFromUrl'

const approveOrderContentMutation = gql`
  mutation approveOrderContent($orderContentId: Int!, $cancelApprove: Boolean!) {
    approveOrderContent(orderContentId: $orderContentId, cancelApprove: $cancelApprove) {
      status {
        reason
        result
      }
    }
  }
`

export function useApproveOrderContentMutation() {
  return useMutation<{ approveOrderContent: IMutationResult }>(approveOrderContentMutation, {
    context: contextEndpoint(EndpointEnum.admin),
    refetchQueries: [
      {
        query: orderDetailQuery,
        variables: {
          orderNumber: parseInt(getParamFromUrl('orderNumber') ?? ''),
          orderId: parseInt(getParamFromUrl('orderId') ?? ''),
          orderContentId: parseInt(getParamFromUrl('orderContentId') ?? '')
        },
        context: contextEndpoint(EndpointEnum.admin)
      }
    ]
  })
}
