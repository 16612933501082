import React from 'react'
import { useGetTerminalsWithWasteCategoryQuery } from './queries/get-branch-list-by-service.query'
import { FormProvider, useForm } from 'react-hook-form'
import SelectField from '../../../../../components/formComponents/SelectField'
import { useCreateWasteCollectionLinkMutation } from './mutations/create-waste-connection-link.mutation'
import LoadingContainer from '../../../../../components/LoadingContainer'
import { isAnyQueryLoading } from '../../../../../services/functions/queryHelpers'
import { processPageOperation } from '../../../../../services/formsServices/pageOperationProcessor'
import { WasteCategoryType } from '../../../../../services/types/waste-category'

export function AddTerminal({
  branchServiceId,
  doNotShowTerminals,
  wasteCategory
}: {
  wasteCategory: WasteCategoryType
  branchServiceId: string
  doNotShowTerminals: string[]
}) {
  const branchesQR = useGetTerminalsWithWasteCategoryQuery(wasteCategory)
  const formMethods = useForm({})
  const [createWasteCollectionLinkMut, createWasteCollectionLinkQR] = useCreateWasteCollectionLinkMutation()
  const terminals =
    branchesQR.data?.getTerminalsWithWasteCategory
      .filter(x => !doNotShowTerminals.includes(x.id))
      .map(x => ({ value: x.id, label: `${x.supplier?.name}${x.specification ? ` - ${x.specification}` : ''} (${x.address?.city})` })) || []
  return (
    <LoadingContainer showLoader={isAnyQueryLoading(branchesQR, createWasteCollectionLinkQR)}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
          <div className="row mt-2">
            <div className="col-4">
              <SelectField name={'terminalBranchId'} optionsData={terminals} label={'Pobočka dodavatele (koncovka)'}></SelectField>
            </div>
            <div className="col-4 pt-3">
              <button type={'submit'} className="btn btn-primary d-inline-block ml-3" disabled={!formMethods.watch('terminalBranchId')}>
                Přidat koncovku
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </LoadingContainer>
  )

  function handleFormSubmit(formData: any) {
    const promise = createWasteCollectionLinkMut({
      variables: {
        branchServiceId,
        terminalBranchId: formData.terminalBranchId,
        wasteCategory: wasteCategory
      }
    })
    processPageOperation({
      promise,
      successMessage: 'Koncovka byla přidána'
    })
  }
}
