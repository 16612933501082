import { gql, useQuery } from '@apollo/client'

export const getContainerFromToDatesQuery = gql`
  query getContainerFromToDates {
    getContainerFromToDates {
      fromDates
      toDates
    }
  }
`

export function useGetContainerFromToDates() {
  return useQuery<{ getContainerFromToDates: { fromDates: string[]; toDates: string[] } }>(getContainerFromToDatesQuery)
}
