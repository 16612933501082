import PageCard from '../../components/Cards/pageCard'
import React, { useContext } from 'react'
import useBootstrapTableDataMapper from '../../services/bootstrapTable/useBootstrapTableDataMapper'
import { hasQueryArrayData, isAnyQueryLoading } from '../../services/functions/queryHelpers'
import BootstrapTable from 'react-bootstrap-table-next'
import usePagingFromURLQuery from '../../services/bootstrapTable/usePagingFromURLQuery'
import useBootstrapTablePagingAndFiltration from '../../services/bootstrapTable/useBootstrapTablePagingAndFiltration'
// @ts-ignore
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import { pageCardHeaderButton } from '../../components/Cards/pageCardHeaderButton'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import { LoginItemType, useLoginsQuery } from './services/loginsQuery'
import { useLoginsCountQuery } from './services/loginsCount'
import { loginsListDataDefinition } from './services/loginsListDataDefinition'
import { ModalContext } from '../../components/Modal/ModalProvider'
import { useDeleteLoginMutation } from './services/deleteLogin'
import LoginEditor from '../../components/LoginEditor/LoginEditor'
import { useSendSetPwdEmailMutation } from './services/sendPwdRetrievalEmailMutation'
import { BootstrapTablePagingWrapper } from '../../components/bootstrapTable/BootstrapTablePagingWrapper'

export default function LoginList() {
  const { showModal, hideModal } = useContext(ModalContext)
  const { generateColumnListForBootstrapTable, mapDataArrayToTable, nameForGqlTranslation } =
    useBootstrapTableDataMapper<LoginItemType>(loginsListDataDefinition)
  const { pageConfiguration, gqlQueryParams } = usePagingFromURLQuery(['lastname', 'email', 'supplierId'], nameForGqlTranslation, {
    defaultPageSize: 100
  })

  const loginsQR = useLoginsQuery(gqlQueryParams)
  const loginsCountQR = useLoginsCountQuery(gqlQueryParams)
  const [deleteLoginMut, deleteLoginQR] = useDeleteLoginMutation()
  const [sendSetPwdEmailMut, sendSetPwdEmailQR] = useSendSetPwdEmailMutation()
  const { bootstrapTablePaginationSetup, handleTableChange } = useBootstrapTablePagingAndFiltration(pageConfiguration, loginsCountQR)

  const columnDefinition = generateColumnListForBootstrapTable({
    filterDefaultValues: pageConfiguration.filterBy,
    currentSort: pageConfiguration.sort,
    handleTableChange: handleTableChange,
    actionsReference: {
      editLogin: editLoginHandler,
      deleteLogin: deleteLoginHandler,
      sendPwdRetrievalEmail: sendPwdRetrievalEmailHandler
    }
  })

  let loginsData: any[] = []
  if (loginsQR.data && hasQueryArrayData(loginsQR)) loginsData = mapDataArrayToTable(loginsQR.data.logins)

  return (
    <PageCard
      showLoader={isAnyQueryLoading(loginsQR, loginsCountQR, deleteLoginQR, sendSetPwdEmailQR)}
      headerTitle={'Přístupy do aplikace'}
      cardOptions={pageCardHeaderButton('Přidat', showAddLogin, 'AddLogin')}
    >
      <PaginationProvider pagination={paginationFactory(bootstrapTablePaginationSetup)}>
        {({ paginationProps, paginationTableProps }: { paginationProps: any; paginationTableProps: any }) => (
          <div className="table-responsive-xl">
            <BootstrapTablePagingWrapper paginationProps={paginationProps} paginationTableProps={paginationTableProps}>
              <BootstrapTable
                bootstrap4
                striped
                hover
                condensed
                remote
                classes="table-responsive-lg digiTable"
                noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné záznamy"</>}
                keyField="id"
                data={loginsData}
                columns={columnDefinition}
                filter={filterFactory()}
                filterPosition={'inline'}
                sort={pageConfiguration.sort}
                onTableChange={handleTableChange}
                {...paginationTableProps}
              />
            </BootstrapTablePagingWrapper>
          </div>
        )}
      </PaginationProvider>
    </PageCard>
  )

  function showAddLogin() {
    showModal({
      title: 'Přidat nový přístup',
      hideFooter: true,
      modalContent: <LoginEditor onFinish={finishEditLogin} />
    })
  }

  function editLoginHandler(row: any) {
    showModal({
      title: 'Upravit přístup',
      onClose: finishEditLogin,
      hideFooter: true,
      modalContent: <LoginEditor onFinish={finishEditLogin} loginId={row.id} loadData={row.rawData} />
    })
  }

  function finishEditLogin() {
    loginsCountQR.refetch()
    loginsQR.refetch().then(() => {
      hideModal()
    })
  }

  function deleteLoginHandler(row: any) {
    if (!row.id) return
    if (window.confirm(`Opravdu zrušit přístup uživatele ${row.email}?`)) {
      const deleteLoginPromise = deleteLoginMut({ variables: { loginId: row.id } })
      processPageOperation({
        promise: deleteLoginPromise,
        successMessage: `Přístup byl odstraněn`,
        successAction: result => loginsQR.refetch()
      })
    }
  }

  function sendPwdRetrievalEmailHandler(row: any) {
    if (!row.id) return
    const setSendPwdPromise = sendSetPwdEmailMut({ variables: { loginId: row.id } })
    processPageOperation({
      promise: setSendPwdPromise,
      successMessage: `Email byl odeslán na uživatele`
    })
  }
}
