import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../services/types/gqlTypes'

const cancelAccountingDocumentMutation = gql`
  mutation cancelAccountingDocument($accountingDocumentId: ID!) {
    cancelAccountingDocument(accountingDocumentId: $accountingDocumentId) {
      status {
        reason
        result
      }
    }
  }
`

export function useCancelAccountingDocumentMutation() {
  return useMutation<{ updateAdvanceAccount: IMutationResult }>(cancelAccountingDocumentMutation, {
    awaitRefetchQueries: true,
    refetchQueries: ['advanceAccountInvoices']
  })
}
