import { ApolloClient, ApolloProvider } from '@apollo/client'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { contextEndpoint, EndpointEnum } from './contextEndpoint'
import { useTokenExpiration } from '../loginAndToken/useTokenExpiraton'
import { refreshTokenMutation } from './refreshTokenMutation'
import apolloClientGenerator from './apolloClientGenerator'
import { LoginContext, useLogin } from '../loginAndToken/useLogin'
import { mutationStatusResult } from '../types/gqlTypes'
import { ILoginMutationResult } from '../../ScenesLogin/LoginComponent/services/loginMutation'
import { toast } from 'react-toastify'

export default function SetupLoginAndApollo({ children }: { children: React.ReactNode }) {
  const [authToken, setAuthToken] = useState<string>('')
  const refreshTokenFn = useCallback(
    (apolloInstance: ApolloClient<any>) => () => {
      apolloInstance
        .mutate<{ refreshToken: ILoginMutationResult }>({
          mutation: refreshTokenMutation,
          context: contextEndpoint(EndpointEnum.login)
        })
        .then(result => {
          if (result && result.data && result.data.refreshToken.status.result === mutationStatusResult.success) {
            currentLogin.login(result.data.refreshToken.accessToken)
          } else {
            toast.clearWaitingQueue()
            toast.dismiss()
            toast.error('Nepodařilo se obnovit vaše přihlášení. Přihlašte se znovu')
          }
        })
    },
    []
  )
  const apolloClientRef = useMemo<ApolloClient<any>>(() => apolloClientGenerator(authToken, refreshTokenFn), [authToken])

  const { clearAutomaticTokenRefresh, setTokenExpiration } = useTokenExpiration(refreshTokenFn(apolloClientRef))

  const currentLogin = useLogin(setTokenExpiration, setAuthToken, clearAutomaticTokenRefresh, apolloClientRef, refreshTokenFn(apolloClientRef))

  //try to refresh token at start of application
  useEffect(() => {
    if (authToken) return
    refreshTokenFn(apolloClientRef)()
  }, [])

  return (
    <ApolloProvider client={apolloClientRef}>
      <LoginContext.Provider value={currentLogin}>{children}</LoginContext.Provider>
    </ApolloProvider>
  )
}
