import React from 'react'
import SelectField from '../../formComponents/SelectField'
import { IReactSelectItem } from '../../../services/types/ReactSelect'
import { useWasteTypes } from './services/wasteTypeQuery'
import { sortArrayBy } from '../../../services/functions/sortArrayBy'

export default function WasteTypesSelector({
  disabled,
  removeWasteIds,
  required = true,
  name,
  saveCodes,
  isForSupplier
}: {
  disabled?: boolean
  removeWasteIds?: number[]
  required?: boolean
  name?: string
  saveCodes?: boolean
  isForSupplier?: boolean
}) {
  const wasteTypesQR = useWasteTypes(isForSupplier)
  let wasteTypesData: IReactSelectItem<any>[] = []
  let wasteTypesDataUpdated: IReactSelectItem<any>[] = []
  if (!wasteTypesQR.loading && wasteTypesQR.data) {
    wasteTypesData = [...wasteTypesQR.data.wasteTypes].map(x => ({
      label: `[${x.code}] ${x.name} (${x.code.replace(/ /g, '')})`,
      value: saveCodes ? x.code : x.id,
      id: x.id,
      color: '#000'
    }))
    sortArrayBy(wasteTypesData, x => x.label)
  }

  if (wasteTypesData.length > 0 && removeWasteIds && removeWasteIds.length > 0) {
    wasteTypesDataUpdated = wasteTypesData.slice(0).filter(x => !removeWasteIds?.includes(x.id as number))
  }

  return (
    <SelectField
      name={name ?? 'wasteTypeId'}
      label={'Druh odpadu'}
      required={required}
      disabled={disabled}
      registerOptions={{ required: required }}
      optionsData={removeWasteIds && removeWasteIds.length > 0 ? wasteTypesDataUpdated : wasteTypesData}
      isLoading={wasteTypesQR.loading}
    />
  )
}
