import React, { useCallback, useState } from 'react'
import classnames from 'classnames'

export const ModalContext = React.createContext<{
  showModal: (modal: ModalType) => void
  hideModal: () => void
}>({
  showModal: _modal => {},
  hideModal: () => {
    return
  }
})

export function ModalProvider({ children }: { children: React.ReactNode }) {
  const [showModal, setShowModal] = useState(false)
  const [modalParams, setModalParams] = useState<ModalType>({
    modalContent: null
  })

  const showModalFn = useCallback(
    (modal: ModalType) => {
      const modalParams: ModalType = {
        title: modal.title ?? 'Metrák',
        hideFooter: modal.hideFooter ?? false,
        confirmLabel: modal.confirmLabel ?? 'Konec',
        modalContent: modal.modalContent,
        onConfirm: modal.onConfirm,
        onClose: modal.onClose,
        confirmDisabled: modal.confirmDisabled ?? false,
        showBackButton: modal.showBackButton ?? false
      }
      setModalParams(modalParams)
      setShowModal(true)
      if (!document.querySelector('body')?.classList.contains('modal-open')) document.querySelector('body')?.classList.add('model-open')
      if (!document.querySelector('html')?.classList.contains('disable--scroll')) document.querySelector('html')?.classList.add('disable--scroll')

      document.addEventListener('keydown', modalParams.hideFooter ? handleEventListener2 : handleEventListener)
    },
    [showModal, setShowModal, modalParams, setModalParams]
  )

  const hideModal = useCallback(
    (fnToRun?: Function) => {
      document.removeEventListener('keydown', handleEventListener)
      if (document.querySelector('body')?.classList.contains('disable--scroll')) document.querySelector('body')?.classList.remove('disable--scroll')
      if (document.querySelector('html')?.classList.contains('disable--scroll')) document.querySelector('html')?.classList.remove('disable--scroll')
      setShowModal(false)
      if (typeof fnToRun === 'function') {
        fnToRun()
      }
    },
    [showModal, setShowModal, modalParams, setModalParams]
  )
  //previously with modal-blur
  return (
    <ModalContext.Provider value={{ showModal: showModalFn, hideModal: hideModal }}>
      {children}
      {showModal && (
        <>
          <div
            className={classnames('modal fade', showModal ? 'show' : '')}
            id="modal-large"
            tabIndex={-1}
            style={{ display: 'block', paddingRight: '17px' }}
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">{modalParams.title}</h5>
                  <button type="button" className="btn-close" aria-label="Close" onClick={exit} />
                </div>
                <div className="modal-body">{modalParams.modalContent && modalParams.modalContent}</div>
                {!modalParams.hideFooter && (
                  <div className="modal-footer">
                    {modalParams.showBackButton && (
                      <button type="button" className="btn me-auto" onClick={exit}>
                        Zpět
                      </button>
                    )}
                    <button type="button" className="btn btn-primary" onClick={confirm} disabled={modalParams.confirmDisabled}>
                      {modalParams.confirmLabel}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={classnames('modal-backdrop', 'fade', showModal ? 'show' : '')} />
        </>
      )}
    </ModalContext.Provider>
  )

  function exit() {
    hideModal(modalParams.onClose)
  }

  function confirm() {
    if (!modalParams.confirmDisabled) {
      hideModal(modalParams.onConfirm)
    }
  }

  function handleEventListener(event: any) {
    if (event.key === 'Escape') exit()
    if (event.key === 'Enter') confirm()
  }

  function handleEventListener2(event: any) {
    if (event.key === 'Escape') exit()
  }
}

export type ModalType = {
  title?: string
  modalContent: React.ReactNode
  hideFooter?: boolean
  onClose?: () => void
  confirmLabel?: string
  confirmDisabled?: boolean
  onConfirm?: () => void
  showBackButton?: boolean
}
