import PageCard from '../../components/Cards/pageCard'
import React, { useContext, useState } from 'react'
import useBootstrapTableDataMapper from '../../services/bootstrapTable/useBootstrapTableDataMapper'
import { hasQueryArrayData, isAnyQueryLoading } from '../../services/functions/queryHelpers'
import BootstrapTable from 'react-bootstrap-table-next'
// @ts-ignore
import { ContainerItemType, useContainerTypesQuery } from '../../services/queries/containersQuery'
import { containerListDataDefinition } from './services/containerListDataDefinition'
import ContainerRowExpand from './components/ContainerRowExpand/ContainerRowExpand'
import { createParamsForUpdateContainerDisabledMutation, useUpdateContainerDisabledMutation } from './services/updateContainerDisabledMutation'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import { pageCardHeaderButton } from '../../components/Cards/pageCardHeaderButton'
import { ModalContext } from '../../components/Modal/ModalProvider'
import AddOrEditContainer from './components/AddOrEditContainer/AddOrEditContainer'

export default function ContainerList() {
  const { showModal, hideModal } = useContext(ModalContext)
  const [rowToExpand, setRowToExpand] = useState<number>()
  const [updateContainerDisabled, updateContainerDisabledQR] = useUpdateContainerDisabledMutation()
  const { generateColumnListForBootstrapTable, mapDataArrayToTable } = useBootstrapTableDataMapper<ContainerItemType>(containerListDataDefinition)
  const containersQR = useContainerTypesQuery()

  const columnDefinition = generateColumnListForBootstrapTable({
    columnAction: handleTableClick,
    actionsReference: {
      disableContainer: changeContainerDisabled(true),
      enableContainer: changeContainerDisabled(false),
      editContainer: (row: any) => addOrEditContainerHandler(row.id)
    }
  })

  let containersData: any[] = []
  if (containersQR.data && hasQueryArrayData(containersQR)) containersData = mapDataArrayToTable(containersQR.data.containerTypes)

  return (
    <PageCard
      showLoader={isAnyQueryLoading(containersQR, updateContainerDisabledQR)}
      headerTitle={'Kontejnery'}
      cardOptions={pageCardHeaderButton('Přidat', () => addOrEditContainerHandler(undefined), 'AddContainer')}
    >
      <div className="table-responsive-xl">
        <BootstrapTable
          bootstrap4
          striped
          hover
          condensed
          classes="table-responsive-lg digiTable"
          noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné záznamy"</>}
          keyField="id"
          data={containersData}
          columns={columnDefinition}
          expandRow={containerRowExpand(rowToExpand)}
          defaultSorted={[{ dataField: 'uiOrder', order: 'asc' }]}
        />
      </div>
    </PageCard>
  )

  function handleTableClick(e: Event, column: number, columnIndex: number, row: any) {
    if (columnIndex > 7) return
    setRowToExpand(rowToExpand === row.id ? undefined : row.id)
  }

  function addOrEditContainerHandler(id: number | undefined) {
    showModal({
      title: id ? 'Editovat kontejner' : 'Přidat kontejner',
      hideFooter: true,
      modalContent: (
        <AddOrEditContainer
          containerId={id}
          exitHandler={async () => {
            await Promise.all([containersQR.refetch()]).then(() => hideModal())
          }}
        />
      )
    })
  }

  function containerRowExpand(rowId: number | undefined) {
    return {
      renderer: function containerRowDetail() {
        if (!containersQR.data || rowId === undefined) return <></>
        const payload = containersQR.data.containerTypes.find(x => x.id === rowId)
        if (!payload) return <></>
        return <ContainerRowExpand data={payload.wasteCapacities} container={payload} />
      },
      onlyOneExpanding: true,
      expanded: [rowId],
      expandByColumnOnly: true
    }
  }

  function changeContainerDisabled(newValue: boolean) {
    return (row: any) => {
      if (!row.id) return
      const promise = updateContainerDisabled({ variables: createParamsForUpdateContainerDisabledMutation(row.id, newValue) })
      processPageOperation({
        promise: promise,
        successMessage: `Kontejneru byla změněna dostupnost`,
        successAction: result => containersQR.refetch()
      })
    }
  }
}
