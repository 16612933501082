import { gql, useQuery } from '@apollo/client'
import { contextEndpoint, EndpointEnum } from '../../../services/appConfiguration/contextEndpoint'

export const orderContentStatesQuery = gql`
  query orderContentStates {
    orderContentStates {
      id
      nameForSupplier
      sysName
    }
  }
`

export function useOrderContentStatesQuery() {
  return useQuery<{ orderContentStates: { nameForSupplier: string; sysName: string }[] }>(orderContentStatesQuery, {
    context: contextEndpoint(EndpointEnum.supplier)
  })
}
