import React, { ChangeEvent } from 'react'
import { IFormComponentBaseInput } from './components/IFormComponentBaseInput'
import { useFormContext } from 'react-hook-form'

interface IToggleFieldInput extends IFormComponentBaseInput {
  prependText?: string
  onChange?: ((value: boolean, event: ChangeEvent<HTMLInputElement>) => void) | undefined
}

export default function ToggleField(fields: IToggleFieldInput) {
  const { register } = useFormContext()

  return (
    <>
      {fields.prependText && <span className="mr-2">{fields.prependText}</span>}
      <label className="form-check form-switch d-inline-block">
        <input {...register(fields.name, fields.registerOptions)} id={fields.name} type="checkbox" className="form-check-input" disabled={fields.disabled} />
        <span className="form-check-label">{fields.label}</span>
      </label>
    </>
  )
}
