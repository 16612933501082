import { columnFilterFactory } from './columnFilterFactory'
import { ColumnFormatterTypes, TableDefinitionTypes } from './services/tableSpecificationTypes'
import { IStringObject } from '../types/IStringObject'
import { IPageSort, ISortConfiguration } from './usePagingFromURLQuery'
import { headerFormatterWithCustomSort } from '../../components/bootstrapTable/headerFormatterWithSort'
import { headerFormatterWithCustomIcon } from '../../components/bootstrapTable/headerFormatterWithIcon'
import { Column } from 'react-bootstrap-table-next'
import { useCallback, useMemo } from 'react'
import { filterOptionsCreatorType } from './useFilterOptions4CustomSelectCreator'
import { columnFormatterFactory } from './columnFormatters'
// @ts-ignore
import { Type } from 'react-bootstrap-table2-editor'

export default function useBootstrapTableDataMapper<T, D = IStringObject>(tableDefinition: TableDefinitionTypes<T>) {
  const generateColumnListForBootstrapTable = useCallback(generateColumnListForBootstrapTableFn, [tableDefinition])
  const mapDataArrayToTable = useCallback(mapDataArrayToTableFn, [tableDefinition])

  const nameForGqlTranslation = useMemo<IStringObject>(() => {
    const retValue: IStringObject = {}
    for (const item in tableDefinition.columnDefinition) {
      if (tableDefinition.columnDefinition[item].nameForGqlSort) retValue[item] = { sort: tableDefinition.columnDefinition[item].nameForGqlSort }
      if (tableDefinition.columnDefinition[item].nameForGqlFilter)
        retValue[item] = { ...retValue[item], filter: tableDefinition.columnDefinition[item].nameForGqlFilter }
    }
    return retValue
  }, [tableDefinition])

  let defaultSort: IPageSort | undefined = undefined
  for (const item in tableDefinition.columnDefinition)
    if (tableDefinition.columnDefinition[item].defaultRemoteSort)
      defaultSort = { sortField: item, sortOrder: tableDefinition.columnDefinition[item].defaultRemoteSort }

  return { generateColumnListForBootstrapTable, mapDataArrayToTable, nameForGqlTranslation, defaultSort }
  function generateColumnListForBootstrapTableFn({
    prefix = '',
    filterDefaultValues = {},
    filterOptions4CustomSelectCreator,
    actionsReference = undefined,
    columnAction = undefined,
    currentSort,
    handleTableChange
  }: {
    prefix?: string
    filterDefaultValues?: IStringObject
    filterOptions4CustomSelectCreator?: filterOptionsCreatorType
    actionsReference?: IStringObject<(row: any) => void> | undefined
    columnAction?: (e: Event, column: number, columnIndex: number, row: any, rowIndex: number) => void
    currentSort?: ISortConfiguration
    handleTableChange?: (type: TableChangeType, newState: TableChangeNewState) => void
  }) {
    const tableColumns: Column<string>[] = []
    const prefixWithDot = prefix ? prefix + '.' : 'formFields:'
    for (const columnName in tableDefinition.columnDefinition) {
      const column = tableDefinition.columnDefinition[columnName]
      if ((column.sort && column.remoteSort) || (column.remoteSort && !handleTableChange)) throw new Error('Wrong setting of sorting properties')

      const filterDef = column.filterType
        ? columnFilterFactory(
            column,
            columnName,
            filterDefaultValues,
            {},
            filterOptions4CustomSelectCreator ? filterOptions4CustomSelectCreator.getFilterOption(columnName) : undefined
          )
        : {}

      const columnDescriptor: Column<string> = {
        dataField: columnName,
        text: column.hidden ? columnName : (column.name ?? columnName),
        ...filterDef,
        sort: column.sort,
        hidden: !!column.hidden,
        formatter: column.columnFormatterType ? columnFormatterFactory(column.columnFormatterType) : (null as any),
        formatExtraData: {
          prefixWithDot: column.customTransPrefix === undefined ? prefixWithDot : column.customTransPrefix,
          actionsReference: column.columnFormatterType === ColumnFormatterTypes.actionsObject ? actionsReference : null,
          badgeColours: column.badgeColours,
          separator: column.listSeparator,
          translateMap: column.translateMap,
          type: column.type,
          isMobile: column.isMobile
        },
        headerStyle: column.headerStyle,
        align: column.align ? column.align : 'left',
        editable: !!column.editable,
        editor: undefined,
        editorRenderer: undefined
      }
      if (column.editable && column.type === 'boolean') columnDescriptor['editor'] = { type: Type.CHECKBOX, value: 'true:false' }
      if (column.remoteSort && handleTableChange) {
        columnDescriptor['headerFormatter'] = headerFormatterWithCustomSort(
          currentSort && currentSort.dataField === columnName ? currentSort.order : undefined,
          handleTableChange,
          column.defaultRemoteSort
        )
      }
      if (column.editable) {
        columnDescriptor['headerFormatter'] = headerFormatterWithCustomIcon()
      }
      if (column.editable && column.remoteSort && handleTableChange) {
        columnDescriptor['headerFormatter'] = headerFormatterWithCustomSort(
          currentSort && currentSort.dataField === columnName ? currentSort.order : undefined,
          handleTableChange,
          column.defaultRemoteSort,
          true
        )
      }
      if (columnAction) {
        columnDescriptor['events'] = { onClick: columnAction }
      }
      tableColumns.push(columnDescriptor)
    }
    return tableColumns
  }

  function mapDataArrayToTableFn(edge: any): D[] {
    const mappedData: D[] = []
    if (!edge) return mappedData
    for (const [i, value] of edge.entries()) {
      const dataItem: IStringObject = {}
      for (const item in tableDefinition.columnDefinition) {
        dataItem[item] = tableDefinition.sourceToColumnMapping[item](value, i)
      }
      mappedData.push(dataItem as D)
    }
    return mappedData
  }
}
