import { gql, useQuery } from '@apollo/client'
import { ProductWasteEntity } from '../../../graphql/aminTypes'

export const productWasteListFlatQuery = gql`
  query productWasteListFlat {
    productWasteListFlat {
      id
      isActive
      name
      tooltip
      wasteCategory
      uiOrder
      wasteType {
        id
        name
        code
      }
      hasAdditivesSpecification
      parentWasteId
      childWastes {
        id
      }
    }
  }
`

export function useProductWasteListFlatQuery() {
  return useQuery<{ productWasteListFlat: ProductWasteEntity[] }>(productWasteListFlatQuery, {
    fetchPolicy: 'network-only'
  })
}
