import React from 'react'
import FormField, { inputType } from '../../components/formComponents/FormField'
import { FormProvider, useForm } from 'react-hook-form'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import PageCard from '../../components/Cards/pageCard'
import { ILoginMutationResult } from '../LoginComponent/services/loginMutation'
import { createParamsForSetupPassword, useSetupPasswordMutation } from './services/setupPasswordMutation'
import { getParamFromUrl } from '../../services/functions/getParamFromUrl'
import { Link, useNavigate } from 'react-router-dom'

export default function SetupPassword() {
  const token = getParamFromUrl('token')
  const formMethods = useForm({ defaultValues: { email: getParamFromUrl('email') } })
  const [setupPasswordMut, setupPasswordQR] = useSetupPasswordMutation()
  const navigate = useNavigate()

  return (
    <FormProvider {...formMethods}>
      <section className="antialiased border-top-wide border-primary d-flex flex-column">
        <div className="flex-fill d-flex flex-column justify-content-center py-4">
          <div className="container-tight py-6">
            <div className="text-center mb-4">
              <img src={`/images/logo.svg`} height="47" alt="" />
            </div>
            <PageCard pageCardClass="card card-md" bodyTitle={'Zadání nového hesla'} showLoader={setupPasswordQR.loading}>
              <form autoComplete="off" onSubmit={formMethods.handleSubmit(handleLoginProcess)}>
                <div className="mb-3">
                  <FormField type={inputType.email} name={'email'} label={'E-mail'} registerOptions={{ required: true }} disabled />
                </div>
                <div className="mb-2">
                  <FormField type={inputType.password} name={'password'} label={'Heslo'} registerOptions={{ required: true }} />
                </div>
                <div className="mb-3">
                  <Link to={`/${formMethods.watch('email') ? '?email=' + formMethods.watch('email') : ''}`}>Heslo nechci měnit, zpět na přihlášení</Link>{' '}
                </div>
                <div className="form-footer">
                  <button type="submit" className="btn btn-primary w-100">
                    Nastavit heslo
                  </button>
                </div>
              </form>
            </PageCard>
          </div>
        </div>
      </section>
    </FormProvider>
  )

  function handleLoginProcess(data) {
    if (!token) return
    const promise = setupPasswordMut({ variables: createParamsForSetupPassword(data.password, token) })
    processPageOperation<ILoginMutationResult>({
      promise: promise,
      successAction: result => {
        navigate('/?password-change-succ=1&email=' + getParamFromUrl('email'), { replace: true })
      }
    })
  }
}
