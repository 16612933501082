export default function CustomSizePerPageRenderer({ options, currSizePerPage, onSizePerPageChange }: SizePerPageRenderer) {
  return (
    <div className="btn-group" role="group">
      {options.map(option => {
        const isSelect = parseInt(currSizePerPage) === option.page
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? 'btn-secondary' : 'btn-warning'}`}
          >
            {option.text}
          </button>
        )
      })}
    </div>
  )
}
