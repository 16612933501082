export const operationsDescription: Record<string, { infoMessage: string; successMessage: string }> = {
  CreateNewGoPayPayment: {
    infoMessage: 'Založí novou platbu v GoPay a naváže ji na tuto objednávku. Předchozí vyprší.',
    successMessage: 'Nová GoPay platba byla vytvořena.'
  },
  ProcessOrderPayment: {
    infoMessage:
      'Nad zakázkou se provede operace zaplacení (stejně jako když přijde platba z GoPay). Vytvoří se faktura, odejde email o zaplacení zákazníkovi. ',
    successMessage: 'Operace zaplacení byla úspěšně provedena.'
  },
  MarkOrderAsPaid: {
    infoMessage: 'Zakázce se jen nastaví stav platby na Zaplaceno. Nic víc se neprovede, nemá žádné návaznosti.',
    successMessage: 'Objednávka je nyní ve stavu Zaplaceno.'
  },
  ChangePaymentMethod: {
    infoMessage:
      'Můžete změnit platební metodu na jinou. Bude změněn stav platby, tak aby to odpovídalo. Pokud bude potřeba, bude zrušena/vystavena proforma faktura, nebo vytvořena faktura.',
    successMessage: 'Platební metoda byla upravena.'
  },
  Refund: {
    infoMessage:
      'Vytvoří se opravný daňový doklad. Stav platby se nastaví na Vráceno, jen při plné vratce. Poté je možné odeslat email zákazníkovi s ODD dalším tlačítkem. Platbu je nutné poslat ručně, není navázané na GoPay ani FIO.',
    successMessage: 'Vratka byla připravena'
  },
  RefundEmail2Customer: {
    infoMessage: 'Tlačítkem odešlete email a v příloze bude ODD. Po odeslání emailu se ODD nastaví jako zaplacený',
    successMessage: 'Email o vratce s přiloženým opravným daňovým dokumentem byl odeslán zákazníkovi'
  },
  UnlinkInvoice: { infoMessage: 'Zruší u stávající faktury napojení na proforma fakturu.', successMessage: 'Proforma faktura byla odpojena.' },
  CreateNewInvoice: {
    infoMessage: 'Vytvoří novou fakturu ve Vyfakturuj a napojí ke stávající objednávce.',
    successMessage: 'Faktura byla vytvořena ve Vyfakturuj a napojena.'
  },
  CreateAdditionalInvoice: {
    infoMessage: 'Vytvoří novou fakturu ve Vyfakturuj. Není napojena na žádnou jinou. Je nutné zadat cenu a důvod.',
    successMessage: 'Faktura byla vytvořena ve Vyfakturuj.'
  },
  LinkVyfakturujInvoice: {
    infoMessage: 'Jen připojí do stávající objednávky dokument, který byl ručně vystaven ve Vyfakturuj.',
    successMessage: 'Dokument byl úspěšně uložen.'
  },
  SendPaymentInstructionEmail: {
    infoMessage: 'Klientovi přijde email s platebními informacemi o platbě na FIO banku metráku.',
    successMessage: 'Email s platebními instrukcemi odeslán klientovi.'
  },
  OrderNotPaidEmailToClient: {
    infoMessage: 'Klientovi přijde email s informací o tom, že nezaplatil, a s platebními údaji pro platbu bankovním převodem.',
    successMessage: 'Email s notifikací o nezaplacení byl odeslán klientovi.'
  },
  CreateIncomeReceipt: {
    infoMessage: 'Bude vytvořen příjmový pokladní doklad. Musí existovat proforma faktura - tudíž jen pro platbu dobírkou.',
    successMessage: 'Příjmový pokladní doklad byl vytvořen.'
  },
  ChangePaymentStatus: {
    infoMessage:
      'Bude změněn stav zaplacení na vámi vybraný. Neprovedou se žádné kontroly ani návazné operace. Provádět jen jako korekci nějaké chyby, zasahuje to do běžného workflow. Značí to, že máme něco špatně, pokud je potřeba používat často!',
    successMessage: 'Stav zaplacení byl změněn.'
  },
  CancelPaymentAdvanceOrderPayment: {
    infoMessage: 'Na zálohovém účtu provede kreditní operaci v hodnotě objednávky. Vrátí objednávku do stavu nezaplaceno.',
    successMessage: 'Objednávce na zálohu byla vrácena platba.'
  }
}
