import React, { useContext, useEffect } from 'react'
import FormField, { inputType } from '../../components/formComponents/FormField'
import { Link, useNavigate } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { createParamsForLoginMutation, ILoginMutationResult, useLoginMutation } from './services/loginMutation'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import { LoginContext } from '../../services/loginAndToken/useLogin'
import PageCard from '../../components/Cards/pageCard'
import { getParamFromUrl } from '../../services/functions/getParamFromUrl'
import { toast } from 'react-toastify'

export default function LoginComponent() {
  const formMethods = useForm({
    defaultValues: { email: getParamFromUrl('email') }
  })
  const forwardToUrl = getParamFromUrl('url')
  const [loginMut, loginQR] = useLoginMutation()
  const navigate = useNavigate()
  const currentLogin = useContext(LoginContext)
  useEffect(() => {
    if (getParamFromUrl('password-change-succ')) {
      toast.success('Heslo bylo změněno, můžete se přihlásit')
    }
  }, [])

  useEffect(() => {
    if (currentLogin?.currentJWT && currentLogin.currentJWT.isAdmin) navigate(forwardToUrl ?? '/order-list', { replace: true })
    if (currentLogin?.currentJWT && !currentLogin.currentJWT.isAdmin) navigate(forwardToUrl ?? '/supplier/order-list', { replace: true })
  }, [currentLogin?.currentJWT])

  return (
    <FormProvider {...formMethods}>
      <section className="antialiased border-top-wide border-primary d-flex flex-column">
        <div className="flex-fill d-flex flex-column justify-content-center py-4">
          <div className="container-tight py-6">
            <div className="text-center mb-4">
              <img src={`/images/logo.svg`} height="47" alt="" />
            </div>
            <PageCard pageCardClass="card card-md" bodyTitle={'METRÁK Partner - přihlášení'} showLoader={loginQR.loading}>
              <form autoComplete="off" onSubmit={formMethods.handleSubmit(handleLoginProcess)}>
                <div className="mb-3">
                  <FormField type={inputType.text} name={'email'} label={'E-mail'} registerOptions={{ required: true }} autofocus />
                </div>
                <div className="mb-2">
                  <FormField
                    type={inputType.password}
                    name={'password'}
                    label={'Heslo'}
                    registerOptions={{ required: true }}
                    required
                    description={
                      <Link to={`/forgotten-password${formMethods.watch('email') ? '?email=' + formMethods.watch('email') : ''}`}>Obnova hesla</Link>
                    }
                  />
                </div>
                <div className="form-footer">
                  <button type="submit" className="btn btn-primary w-100">
                    Přihlásit
                  </button>
                </div>
              </form>
            </PageCard>
          </div>
        </div>
      </section>
    </FormProvider>
  )

  function handleLoginProcess(data) {
    toast.dismiss()
    const resendPromise = loginMut({ variables: createParamsForLoginMutation(data.email, data.password) })
    processPageOperation<ILoginMutationResult>({
      promise: resendPromise,
      successAction: result => {
        const token = currentLogin?.login(result.accessToken)
        if (token === undefined) {
          toast.error('Nepodařilo se přihlásit')
          return
        }
        if (token.isAdmin) navigate(forwardToUrl && forwardToUrl.length > 5 ? decodeURIComponent(forwardToUrl) : '/order-list', { replace: true })
        else navigate('/supplier/order-list', { replace: true })
      }
    })
  }
}
