import React, { useContext } from 'react'
import { useEmailHistoryQuery } from './query/email-history.query'
import LoadingContainer from '../../../../components/LoadingContainer'
import { isAnyQueryLoading } from '../../../../services/functions/queryHelpers'
import BootstrapTable from 'react-bootstrap-table-next'
import useBootstrapTableDataMapper from '../../../../services/bootstrapTable/useBootstrapTableDataMapper'
import { emailHistoryDataDefinition } from './services/emailHistoryDataDefinition'
import { ModalContext } from '../../../../components/Modal/ModalProvider'

export default function EmailHistory({ orderContentId }: { orderContentId: number }) {
  const { showModal, hideModal } = useContext(ModalContext)
  const emailHistoryQR = useEmailHistoryQuery(orderContentId)
  const { generateColumnListForBootstrapTable, mapDataArrayToTable } = useBootstrapTableDataMapper(emailHistoryDataDefinition)
  const columnDefinition = generateColumnListForBootstrapTable({
    columnAction: showEmailDataHandler
  })

  let emailHistory: any[] = []
  if (emailHistoryQR.data && emailHistoryQR.data.emailHistory) emailHistory = mapDataArrayToTable(emailHistoryQR.data.emailHistory)

  return (
    <fieldset className="form-fieldset">
      <header>
        <h2>Odeslané emaily</h2>
      </header>
      <LoadingContainer showLoader={isAnyQueryLoading(emailHistoryQR)}>
        <BootstrapTable
          bootstrap4
          hover
          striped
          condensed
          classes="table-responsive-lg digiTable"
          noDataIndication={() => <>Žádný email neodešel pro tuto zakázku</>}
          keyField="id"
          data={emailHistory}
          columns={columnDefinition}
        />
      </LoadingContainer>
    </fieldset>
  )

  function showEmailDataHandler(e: Event, column: number, columnIndex: number, row: any) {
    const email = emailHistoryQR.data?.emailHistory.find(x => x.id === row.id)
    const emailHTML = <iframe title="Email Content" srcDoc={email?.message} style={{ width: '100%', height: '500px', border: '1px solid' }} />

    showModal({
      hideFooter: false,
      showBackButton: false,
      title: email?.subject,
      modalContent: emailHTML
    })
  }
}
