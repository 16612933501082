import { gql, useQuery } from '@apollo/client'
import { contextEndpoint, EndpointEnum } from '../../../services/appConfiguration/contextEndpoint'

const collectionProductsForLocalityQuery = gql`
  query collectionProductsForLocality($locality: GpsCoordinations!) {
    collectionProductsForLocality(locality: $locality)
  }
`

export function useCollectionProductsForLocalityQuery(lng: string | undefined, lat: string | undefined, skip) {
  return useQuery<{ collectionProductsForLocality: number[] }>(collectionProductsForLocalityQuery, {
    skip: !lng || !lat || skip,
    context: contextEndpoint(EndpointEnum.public),
    variables: {
      locality: {
        lng,
        lat
      }
    }
  })
}
