import classnames from 'classnames'
import React from 'react'

type LoadingContainerParams = {
  showLoader?: boolean
  noContentWhileLoading?: boolean
  children?: React.ReactNode
}
export default function LoadingContainer({ showLoader, noContentWhileLoading, children }: LoadingContainerParams) {
  return (
    <div className={classnames('dimmer', showLoader ? ' active' : undefined)} style={{ minHeight: '50px' }}>
      <div className="loader" />
      <div className="dimmer-content">{!noContentWhileLoading && children}</div>
    </div>
  )
}
