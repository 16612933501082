import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../services/types/gqlTypes'

const deleteTerminalPriceListMutation = gql`
  mutation deleteTerminalPriceList($terminalPriceListId: Int!) {
    deleteTerminalPriceList(terminalPriceListId: $terminalPriceListId) {
      status {
        reason
        result
      }
    }
  }
`

export function useDeleteTerminalPriceListMutation() {
  return useMutation<{ deleteTerminalPriceList: IMutationResult }>(deleteTerminalPriceListMutation)
}
