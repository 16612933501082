import { IReactSelectItem } from '../../../services/types/ReactSelect'

export const priorityValues: IReactSelectItem<number>[] = [
  { value: 1, label: '1 - Vysoká priorita' },
  { value: 2, label: '2 - Normální priorita' },
  { value: 3, label: '3 - Nízká priorita' }
]

export const daysToMinutes: IReactSelectItem<number>[] = [
  { value: 0, label: 'Ten samý den' },
  { value: 1440, label: '1 den (následující prac. den)' },
  { value: 2880, label: '2 dny' },
  { value: 4320, label: '3 dny' },
  { value: 5760, label: '4 dny' }
]

export const hoursToMinutes: IReactSelectItem<number>[] = [
  { value: 480, label: '8:00' },
  { value: 540, label: '9:00' },
  { value: 600, label: '10:00' },
  { value: 660, label: '11:00' },
  { value: 720, label: '12:00' },
  { value: 780, label: '13:00' },
  { value: 840, label: '14:00' },
  { value: 900, label: '15:00' },
  { value: 960, label: '16:00' },
  { value: 1020, label: '17:00' },
  { value: 1080, label: '18:00' },
  { value: 1140, label: '19:00' },
  { value: 1200, label: '20:00' },
  { value: 1260, label: '21:00' },
  { value: 1320, label: '22:00' },
  { value: 1380, label: '23:00' },
  { value: 1440, label: '24:00' }
]
