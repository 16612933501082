import { gql, useMutation } from '@apollo/client'
import { IMutationResultWithId } from '../../../services/types/gqlTypes'

const createSupplierMutation = gql`
  mutation createSupplier($data: SupplierDataInput!, $duplicatePriceListFrom: Int) {
    createSupplier(data: $data, duplicatePriceListFrom: $duplicatePriceListFrom) {
      id
      status {
        reason
        result
      }
    }
  }
`

export function useCreateSupplierMutation() {
  return useMutation<{ createSupplier: IMutationResultWithId }>(createSupplierMutation)
}

export function createParamsForCreateSupplierMutation(data: any, duplicatePriceListFrom?: number) {
  return {
    duplicatePriceListFrom: duplicatePriceListFrom,
    data: {
      name: data.name,
      orpName: data.orpName,
      street: data.address.street,
      streetNumber: data.address.streetNumber,
      city: data.address.city,
      zip: data.address.zip,
      gps: { lng: data.address.lng, lat: data.address.lat },
      residenceStreet: data.residence.street,
      residenceStreetNumber: data.residence.streetNumber,
      residenceCity: data.residence.city,
      residenceZip: data.residence.zip,
      phone: data.phone,
      email: data.email ? data.email : null,
      web: !data.web || data.web === 'http://' ? null : data.web.startsWith('http') ? data.web : 'http://' + data.web,
      contactPerson: data.contactPerson,
      companyIdentification: data.companyIdentification,
      companyTaxId: data.companyTaxId,
      deliveryDayThresholdMinutes: data.deliveryDayThresholdMinutes,
      expressDeliveryFromMinutes: data.expressDeliveryFromMinutes,
      normalDeliveryFromMinutes: data.normalDeliveryFromMinutes,
      expressDeliveryFeeKc: parseFloat(data.expressDeliveryFeeKc),
      publicHolidayFeeKc: parseFloat(data.publicHolidayFeeKc),
      containerRentPriceFromDay: parseInt(data.containerRentPriceFromDay),
      containerRentPricePerDayKc: parseFloat(data.containerRentPricePerDayKc),
      ourMarginPercent: parseFloat(data.ourMarginPercent),
      pathCalculationStrategy: data.pathCalculationStrategy,
      autoApproveOrders: data.autoApproveOrders,
      note: data.note,
      priority: parseInt(data.priority),
      useFlatRatePriceList: data.useFlatRatePriceList,
      operationRadiusM: parseInt(data.operationRadiusM),
      regionCode: data.regionCode ? parseInt(data.regionCode) : undefined,
      sendOrderAllEmails: data.sendOrderAllEmails,
      priceListIsFinal: data.priceListIsFinal,
      allowWorkOnHolidays: data.allowWorkOnHolidays,
      allowWorkOnSaturday: data.allowWorkOnSaturday,
      allowWorkOnSunday: data.allowWorkOnSunday,
      acceptCod: data.acceptCod,
      disabled: data.disabled,
      priceLimitNotApplicable: data.priceLimitNotApplicable,
      hasIcz: data.hasIcz,
      iczNumber: data.iczNumber && data.iczNumber !== 'CZC' ? data.iczNumber : undefined,
      transportFlatRate: data.transportFlatRate ? parseFloat(data.transportFlatRate) : undefined,
      capability: data.capability,
      zpoNote: data.zpoNote
    }
  }
}
