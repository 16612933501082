import { gql, useQuery } from '@apollo/client'
import { contextEndpoint, EndpointEnum } from '../../../services/appConfiguration/contextEndpoint'
import { BasePricePartsType } from './calculatePriceQuery'

const calculateDeliveryPrice = gql`
  query calculateDeliveryPrice(
    $cod: Boolean
    $deliveryDate: String
    $locality: GpsCoordinations!
    $productId: Int!
    $weightTonnes: Float!
    $b2bCustomerId: Int
    $forcedSupplierId: Int
  ) {
    calculateDeliveryPrice(
      cod: $cod
      deliveryDate: $deliveryDate
      locality: $locality
      productId: $productId
      weightTonnes: $weightTonnes
      b2bCustomerId: $b2bCustomerId
      forcedSupplierId: $forcedSupplierId
    ) {
      distanceKm
      supplierId
      totalPrice {
        totalDiscountInclVat
        totalDiscountWoVat
        totalPriceKcInclVat
        totalPriceKcWoVat
        containerPrices {
          supplierProfitWoVatKc
          basePriceKcWoVat
          basePriceParts {
            transferPrice
            flatRate
            terminalPrice
            margin
            marginDiscount
            b2bMargin
            priceLimitDiscount
          }
          additionalFees {
            type
            priceKcWoVat
          }
        }
        additionalFees {
          type
          priceKcWoVat
        }
      }
    }
  }
`

export function useCalculateDeliveryPriceQuery() {
  return useQuery<{ calculateDeliveryPrice: DeliveryPriceType }>(calculateDeliveryPrice, {
    skip: true,
    context: contextEndpoint(EndpointEnum.admin)
  })
}

export function createParamsForCalculateDeliveryPriceQuery(
  lng: string,
  lat: string,
  productId: number,
  weightTonnes: number,
  cod?: boolean,
  deliveryDate?: string,
  b2bCustomerId?: number,
  forcedSupplierId?: number
) {
  return {
    locality: {
      lng,
      lat
    },
    productId,
    weightTonnes: +weightTonnes,
    cod,
    deliveryDate,
    b2bCustomerId,
    forcedSupplierId
  }
}

export type DeliveryPriceType = {
  distanceKm: number
  supplierId: number
  totalPrice: {
    totalDiscountInclVat: number
    totalDiscountWoVat: number
    totalPriceKcInclVat: number
    totalPriceKcWoVat: number
    containerPrices: [
      {
        supplierProfitWoVatKc: number
        basePriceKcWoVat: number
        basePriceParts: BasePricePartsType
        additionalFees: [
          {
            type: ExtraFeesEnum
            priceKcWoVat: number
          }
        ]
      }
    ]
    additionalFees: [
      {
        type: ExtraFeesEnum
        priceKcWoVat: number
      }
    ]
  }
}

export enum ExtraFeesEnum {
  COD = 'COD',
  expressStart = 'expressStart',
  startDuringWeekend = 'startDuringWeekend'
}
