import PageCard from '../../components/Cards/pageCard'
import React, { useContext } from 'react'
import { isAnyQueryLoading } from '../../services/functions/queryHelpers'
import { useProductContainerListQuery } from './queries/product-container-list.query'
import { SelectedSupplierModalContext } from '../SelectedSupplierModal/SelectedSupplierModal'
import SelectField from '../../components/formComponents/SelectField'
import { FormProvider, useForm } from 'react-hook-form'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import FormField, { inputType } from '../../components/formComponents/FormField'
import { IReactSelectItem } from '../../services/types/ReactSelect'
import ToggleField from '../../components/formComponents/ToggleField'
import { useSupplierContainerListQuery } from './queries/supplier-container-list.query'
import { useAddContainerToSupplierMutation } from './mutations/add-container-to-supplier.mutation'
import BootstrapTable from 'react-bootstrap-table-next'
import cellEditFactory from 'react-bootstrap-table2-editor'
import useBootstrapTableDataMapper from '../../services/bootstrapTable/useBootstrapTableDataMapper'
import { SupplierContainerEntity } from '../../graphql/aminTypes'
import { supplierContainerDataDefinition } from './services/supplier-container-data-definition'
import { useUpdateSupplierContainerMutation } from './mutations/update-supplier-container.mutation'
import { formatEditorValue } from '../../services/bootstrapTable/format-editor-value'
import { useDeleteContainerToSupplierMutation } from './mutations/delete-supplier-container.mutation'
import SupplierContainerRentRates from './components/SupplierContainerRentRates'
import { useSupplierContainerRentRatesQuery } from './components/queries/supplier-container-rent-rates.query'
import { ModalContext } from '../../components/Modal/ModalProvider'
import { UpdateContainerRentRate } from './UpdateContainerRentRate'

export default function SupplierContainerList() {
  const { state } = useContext(SelectedSupplierModalContext)
  const productContainerListQR = useProductContainerListQuery()
  const supplierContainerListQR = useSupplierContainerListQuery(state.selectedBranch?.id)
  const [addContainerToSupplierMut, addContainerToSupplierQR] = useAddContainerToSupplierMutation()
  const [updateSupplierContainerMut, updateSupplierContainerQR] = useUpdateSupplierContainerMutation()
  const [deleteContainerToSupplierMut, deleteContainerToSupplierQR] = useDeleteContainerToSupplierMutation()
  const rentRatesQR = useSupplierContainerRentRatesQuery(state.selectedBranch?.id)
  const { showModal, hideModal } = useContext(ModalContext)

  const formMethods = useForm()

  const containerList: IReactSelectItem[] =
    productContainerListQR.data?.productContainerList
      .filter(x => !supplierContainerListQR.data?.supplierContainerList.find(y => y.container.id === x.id))
      .map(
        x =>
          ({
            label: `${x.name} ${x.volumeM3}m³`,
            value: x.id
          }) as IReactSelectItem
      ) ?? []

  const { generateColumnListForBootstrapTable, mapDataArrayToTable } = useBootstrapTableDataMapper<SupplierContainerEntity>(supplierContainerDataDefinition)
  const columnDefinition = generateColumnListForBootstrapTable({
    actionsReference: {
      deleteContainer: deleteContainerToSupplierHandler
    },
    columnAction: handleTableClick
  })

  const tableData = mapDataArrayToTable(supplierContainerListQR.data?.supplierContainerList)

  return (
    <PageCard
      showLoader={isAnyQueryLoading(
        productContainerListQR,
        supplierContainerListQR,
        addContainerToSupplierQR,
        updateSupplierContainerQR,
        deleteContainerToSupplierQR
      )}
      headerTitle={`Kontejnery na pobočce ${state.selectedBranch?.address?.city} dodavatele ${state.selectedSupplier?.name}`}
    >
      <SupplierContainerRentRates supplierBranchId={state.selectedBranch?.id} />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <div className="row mb-2">
            <div className="col-md-3">
              <SelectField name={'containerId'} label={'Typ kontejneru'} optionsData={containerList} required />
            </div>
            <div className="col-md-2">
              <SelectField
                name={'containerRentRateId'}
                label={'Sazba za pronájem'}
                optionsData={(rentRatesQR.data?.supplierContainerRentRates ?? []).map(x => ({ label: x.name, value: x.id }))}
                registerOptions={{ required: true }}
                required
                isLoading={rentRatesQR.loading}
              />
            </div>
            <div className="col-md-2">
              <FormField type={inputType.number} isPositive name={'availableCount'} label={'Dostupné kusy'} valueAsNumber />
            </div>
            <div className="col-md-1">
              <ToggleField name={'adrHandling'} label={'ADR handling'} />
            </div>
            <div className="col-md-2">
              <FormField type={inputType.number} isMoney name={'adrSurchargeKc'} label={'ADR Příplatek'} valueAsNumber />
            </div>
            <div className="col-md-2">
              <ToggleField name={'isVirtualContainer'} label={'Virtuální kontejner'} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <button className={'btn btn-primary'} type={'submit'}>
                Přidat kontejner dodavateli
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
      <hr />
      <BootstrapTable
        bootstrap4
        striped
        hover
        condensed
        classes="table-responsive-lg digiTable"
        noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné záznamy"</>}
        keyField="id"
        data={tableData ? tableData : []}
        columns={columnDefinition}
        defaultSorted={[{ dataField: 'uiOrder', order: 'asc' }]}
        cellEdit={cellEditFactory({
          mode: 'click',
          blurToSave: true,
          autoSelectText: true,
          beforeSaveCell: onCellUpdate
        })}
      />
    </PageCard>
  )

  function handleFormSubmit(data: any) {
    const resultPromise = addContainerToSupplierMut({ variables: { branchId: state.selectedBranch?.id, data: data } })
    processPageOperation({
      promise: resultPromise,
      successMessage: 'Kontejner byl přidán dodavateli'
    })
    return false
  }

  function onCellUpdate(oldValue, newValue, row, column) {
    if (!row.id || oldValue === newValue) return
    const currentRow = supplierContainerListQR.data?.supplierContainerList.find(x => x.id === row.id)
    if (!currentRow) return
    const resultPromise = updateSupplierContainerMut({
      variables: {
        id: row.id,
        data: {
          ...currentRow,
          [column.dataField]: formatEditorValue(newValue, column),
          __typename: undefined,
          container: undefined,
          containerRentRate: undefined,
          id: undefined
        }
      }
    })
    processPageOperation({
      promise: resultPromise
    })
  }
  function deleteContainerToSupplierHandler(row: any) {
    const promise = deleteContainerToSupplierMut({ variables: { id: row.id } })
    return processPageOperation({
      promise: promise,
      successMessage: 'Kontejner byl odstraněn'
    })
  }
  function handleTableClick(e: Event, column: number, columnIndex: number, row: any) {
    if (columnIndex !== 3) return
    const supplierContainerRow = supplierContainerListQR.data?.supplierContainerList.find(x => x.id === row.id)
    if (!supplierContainerRow || !state.selectedBranch?.id) return
    showModal({
      title: 'Změna cenové sazby',
      modalContent: <UpdateContainerRentRate branchId={state.selectedBranch?.id} hideModal={hideModal} row={supplierContainerRow} />,
      hideFooter: true
    })
  }
}
