import { gql, useQuery } from '@apollo/client'
import { Product } from '../../../graphql/aminTypes'

const productsQuery = gql`
  query products($id: String) {
    products(id: $id) {
      id
      tonToCubes
    }
  }
`

export function useProducts(id) {
  return useQuery<{ products: Product[] }>(productsQuery, { variables: { id: `${id}` }, skip: !id })
}
