import {gql, useQuery} from '@apollo/client'


export const b2bPriceListsQuery = gql`
    query b2bPriceLists($id: Int) {
        b2bPriceLists(id: $id ) {
            id
            disabled
            fixedPrice
            priceCorrectionPercent
            container {
                id
            }
            product {
                isDelivery
                name
                id
            }
            supplier {
                name
                id
            }
        }
    }
`

export type B2bCustomProductType = {
    disabled: boolean
    fixedPrice?: number
    id: number
    priceCorrectionPercent?: number
    container?: {
        id: number
    }
    product: {
        isDelivery: boolean
        name: string
        id: number
    }
    supplier?: {
        id: number
        name: string
    }
}

export function useB2bCustomProductsQuery(id?:number) {
    return useQuery<{ b2bPriceLists: B2bCustomProductType[] }>(b2bPriceListsQuery, {
        fetchPolicy: 'network-only',
        variables: {
            id
        }
    })
}
