import { gql, useQuery } from '@apollo/client'
import { AccountingDocument } from '../../../../../graphql/aminTypes'

export const accountingDocumentsQuery = gql`
  query accountingDocuments($orderId: Int!) {
    accountingDocuments(orderId: $orderId) {
      id
      type
      createdAt
      amountInclVatKc
      documentId
      documentUrl
      documentWebUrl
      isCancelled
      isGroupInvoice
      isFullRefund
      emailSentAt
      isAdditionalInvoice
      note
    }
  }
`

export function useAccountingDocumentsQuery(orderId?: number) {
  return useQuery<{ accountingDocuments: AccountingDocument[] }>(accountingDocumentsQuery, {
    variables: { orderId },
    skip: !orderId,
    fetchPolicy: 'network-only'
  })
}
