import { gql, useQuery } from '@apollo/client'
import { ProductMaterialComponentEntity } from '../../../graphql/aminTypes'

export const productMaterialComponentsQuery = gql`
  query productMaterialComponents {
    productMaterialComponents {
      id
      name
      type
      tooltip
      uiOrder
      uiPicture
      cubicToTonnes
    }
  }
`

export function useProductMaterialComponents() {
  return useQuery<{
    productMaterialComponents: ProductMaterialComponentEntity[]
  }>(productMaterialComponentsQuery, { fetchPolicy: 'network-only' })
}
