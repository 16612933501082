import React, { useState } from 'react'
import CustomTextFilterInner, { ICustomFilterReturnValue } from './CustomTextFilterInner'

interface ICustomTextFilterParams {
  onFilter: (value: ICustomFilterReturnValue) => void
  placeholder: string
  defaultValue?: any
}

export default function CustomTextFilter({ onFilter, placeholder, defaultValue }: ICustomTextFilterParams) {
  const inputValue = useState<string>(defaultValue ? defaultValue : '')

  return <CustomTextFilterInner valueLink={inputValue} onFilter={onFilter} placeholder={placeholder}></CustomTextFilterInner>
}
