import React, { useContext } from 'react'
import useBootstrapTableDataMapper from '../../../../services/bootstrapTable/useBootstrapTableDataMapper'
import BootstrapTable from 'react-bootstrap-table-next'
import { b2bCustomProductsDataDefinition, b2bCustomProductsNoFixedPriceDataDefinition } from './services/B2bCustomProductsDataDefinition'
import { ModalContext } from '../../../../components/Modal/ModalProvider'
import { AddOrEditB2BPriceList } from './AddOrEditB2BPriceList'
import LoadingContainer from '../../../../components/LoadingContainer'
import { hasQueryArrayData, isAnyQueryLoading } from '../../../../services/functions/queryHelpers'
import { useB2bCustomProductsQuery } from './services/B2bProductListQuery'
import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import { useDeleteB2bPriceListMutation } from './services/deleteB2bPriceListMutation'

export default function B2bPriceListRow({ b2bId, companyInfo }: { b2bId: number | undefined; companyInfo: any }) {
  const b2bPriceListQR = useB2bCustomProductsQuery(b2bId)
  const [deleteB2bPriceListMut, deleteB2bPriceListMR] = useDeleteB2bPriceListMutation()
  const { generateColumnListForBootstrapTable, mapDataArrayToTable } = useBootstrapTableDataMapper<any>(
    companyInfo.hasFixedPrice ? b2bCustomProductsDataDefinition() : b2bCustomProductsNoFixedPriceDataDefinition()
  )
  const { showModal, hideModal } = useContext(ModalContext)
  const columnDefinition = generateColumnListForBootstrapTable({
    actionsReference: {
      editProduct: editPriceListHandler,
      deleteProduct: deletePriceListHandler
    }
  })

  let b2bPriceList: any[] = []
  if (b2bPriceListQR.data && hasQueryArrayData(b2bPriceListQR)) {
    b2bPriceList = mapDataArrayToTable(b2bPriceListQR.data.b2bPriceLists)
  }

  return (
    <LoadingContainer showLoader={isAnyQueryLoading(b2bPriceListQR, deleteB2bPriceListMR)}>
      <BootstrapTable
        bootstrap4
        condensed
        classes="table-responsive-lg digiTable"
        noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné objednávky"</>}
        keyField="id"
        data={b2bPriceList}
        columns={columnDefinition}
      />
      <div className="row">
        <div className="col col-is-12 text-right">
          <button
            type={'button'}
            className="btn btn-primary"
            onClick={() =>
              showModal({
                title: 'Přidat produkt pro ' + companyInfo.data.companyName,
                modalContent: (
                  <AddOrEditB2BPriceList
                    companyHasFixedPrice={companyInfo.data.hasFixedPrice}
                    companyId={companyInfo.data.id}
                    onSuccess={async () => {
                      await b2bPriceListQR.refetch()
                      hideModal()
                    }}
                  />
                ),
                hideFooter: true
              })
            }
          >
            Přidat produkt
          </button>
        </div>
      </div>
    </LoadingContainer>
  )

  function editPriceListHandler(row) {
    showModal({
      title: 'Upravit produkt pro ' + companyInfo.data.companyName,
      hideFooter: true,
      modalContent: (
        <AddOrEditB2BPriceList
          companyHasFixedPrice={companyInfo.data.hasFixedPrice}
          companyId={companyInfo.data.id}
          priceListData={row.data}
          onSuccess={async () => {
            await b2bPriceListQR.refetch()
            hideModal()
          }}
        />
      )
    })
  }

  function deletePriceListHandler(row) {
    if (!row.id) return
    if (window.confirm(`Opravdu zrušit definici ceníku ${row.product}?`)) {
      const deleteB2bPriceListPromise = deleteB2bPriceListMut({ variables: { id: row.id } })
      processPageOperation({
        promise: deleteB2bPriceListPromise,
        successAction: result => b2bPriceListQR.refetch()
      })
    }
  }
}
