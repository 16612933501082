import { gql, useQuery } from '@apollo/client'
import { WasteFinal } from '../../../../../graphql/aminTypes'
import { contextEndpoint, EndpointEnum } from '../../../../../services/appConfiguration/contextEndpoint'

export const wasteFinalsQuery = gql`
  query wasteFinals($orderContentId: Int!) {
    wasteFinals(orderContentId: $orderContentId) {
      id
      wasteTypeId
      weightTonne
      icz
      terminalName
      s3key
      fileName
      customerSentTimestamp
      wasteType {
        id
        code
        name
      }
    }
  }
`

export function useWasteFinalsQuery(orderContentId: number, skip: boolean) {
  return useQuery<{ wasteFinals: WasteFinal[] }>(wasteFinalsQuery, {
    context: contextEndpoint(EndpointEnum.admin),
    variables: { orderContentId },
    fetchPolicy: 'network-only',
    skip: skip
  })
}
