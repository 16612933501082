import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const createProductMaterialComponentMutation = gql`
  mutation createProductMaterialComponent($data: ProductMaterialComponentInputArgs!) {
    createProductMaterialComponent(data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useCreateProductMaterialComponent() {
  return useMutation<{ createProductMaterialComponent: IMutationResult }>(createProductMaterialComponentMutation, {
    refetchQueries: ['productMaterialComponents']
  })
}
