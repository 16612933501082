import { SupplierWasteCollectionSupplierLinkEntity } from '../../../../graphql/aminTypes'
import {
  ColumnAlign,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../../services/bootstrapTable/services/tableSpecificationTypes'

export const wasteCollectionLinkDataDefinition: TableDefinitionTypes<SupplierWasteCollectionSupplierLinkEntity> = {
  columnDefinition: {
    id: { hidden: true },
    terminal: { name: 'Koncovka' },
    transportFlatRateKc: { name: 'Paušál za dopravu', editable: true, type: 'number', columnFormatterType: ColumnFormatterTypes.moneyFormatter },
    includedKmInFlatRate: { name: 'km v paušálu', editable: true, type: 'number' },
    actions: {
      name: 'Akce',
      columnFormatterType: ColumnFormatterTypes.actionsObject,
      align: ColumnAlign.left,
      headerStyle: { width: '70px' }
    }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    transportFlatRateKc: x => x.transportFlatRateKc,
    includedKmInFlatRate: x => x.includedKmInFlatRate,
    terminal: x =>
      `${x.terminalBranch.supplier?.name}${x.terminalBranch.specification ? ` - ${x.terminalBranch.specification}` : ''} (${x.terminalBranch.address?.city})`,
    actions: x => {
      const ret: IRowAction[] = []
      ret.push({
        type: RowActionType.codeAction,
        value: 'deleteLink',
        icon: 'fe-x',
        title: 'Odstranit koncovku'
      })
      return ret
    }
  }
}
