import { gql, useQuery } from '@apollo/client'
import { SupplierConcretePriceListEntity } from '../../../graphql/aminTypes'

export const supplierServiceConcretePriceListQuery = gql`
  query supplierServiceConcretePriceList($branchServiceId: ID!) {
    supplierServiceConcretePriceList(branchServiceId: $branchServiceId) {
      id
      concreteComponentId
      priceKc
      priceForPublicKc
    }
  }
`

export function useSupplierServiceConcretePriceListQuery(branchServiceId?: string) {
  return useQuery<{
    supplierServiceConcretePriceList: SupplierConcretePriceListEntity[]
  }>(supplierServiceConcretePriceListQuery, {
    fetchPolicy: 'network-only',
    variables: { branchServiceId },
    skip: !branchServiceId
  })
}
