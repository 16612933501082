import { useSupplierOrderInfo } from './queries/suppliers.query'
import { DataListItem, HtmlDataList } from '../../../../components/HtmlDataList'
import React from 'react'
import LoadingContainer from '../../../../components/LoadingContainer'
import { isAnyQueryLoading } from '../../../../services/functions/queryHelpers'
import { moneyToLocaleString } from '../../../../services/dataToString/decimalToLocaleString'

export function SupplierInfo({ supplierId }: { supplierId?: number }) {
  const supplierQR = useSupplierOrderInfo(supplierId)
  if (!supplierId) return <></>
  const supplierItem = supplierQR?.data?.suppliers2.items![0]
  const supplierData: DataListItem[] = supplierItem
    ? [
        { label: 'Pronájem kontejneru', text: `${moneyToLocaleString(supplierItem.containerRentPricePerDayKc ?? 0, 'CZK', true)}/den` },
        { label: 'Poznámka', text: supplierItem.note ?? 'N/A' },
        { label: 'ZPO poznámka', text: supplierItem.zpoNote ?? 'N/A' }
      ]
    : []
  return (
    <LoadingContainer showLoader={isAnyQueryLoading(supplierQR)}>
      <fieldset className="form-fieldset mt-3">
        <HtmlDataList label={`Dodavatel - ${supplierItem?.name} ${supplierItem?.orpName ? `(${supplierItem?.orpName})` : ''}`} data={supplierData} />
      </fieldset>
    </LoadingContainer>
  )
}
