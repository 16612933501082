import { gql, useMutation } from '@apollo/client'
import {IMutationResult} from "../../../../services/types/gqlTypes";
import {contextEndpoint, EndpointEnum} from "../../../../services/appConfiguration/contextEndpoint";

const attachFileMutation = gql`
  mutation attachFile($orderContentId: Int!, $file: Upload!) {
    attachFile(orderContentId: $orderContentId, file: $file) {
      status {
        reason
        result
      }
    }
  }
`

export function useAttachFileMutation() {
  return useMutation<{ attachFile: IMutationResult }>(attachFileMutation, {
    context: contextEndpoint(EndpointEnum.supplier)
  })
}
