import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'
import { contextEndpoint, EndpointEnum } from '../../../services/appConfiguration/contextEndpoint'

export const loginMutation = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password, customer: false) {
      accessToken
      status {
        reason
        result
      }
    }
  }
`

export interface ILoginMutationResult extends IMutationResult {
  accessToken: string
}

export function createParamsForLoginMutation(email: string, password: string) {
  return {
    email: email,
    password: password
  }
}

export function useLoginMutation() {
  return useMutation<{ login: ILoginMutationResult }>(loginMutation, { context: contextEndpoint(EndpointEnum.login) })
}
