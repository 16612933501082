import React, { useEffect } from 'react'
import { coordinatesToGps } from '../../../services/functions/coordinatesToGps'
import { useOrdersForDashboardQuery } from './services/ordersForDashboardQuery'
import SelectGroup from '../../../components/formComponents/SelectGroup'
import { ActiveItemsOptions } from './services/showOptions'
import FormField, { inputType } from '../../../components/formComponents/FormField'
import { useFormContext } from 'react-hook-form'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { createColorPointIcon } from '../../../services/maps/create-color-point-icon'
import { stateToColor } from './services/state-to-color'

export default function ActiveOrdersMap() {
  const formMethods = useFormContext()
  const poolInterval = formMethods.watch('poolInterval')
  const activeOrdersQR = useOrdersForDashboardQuery(formMethods.watch('orderContentStates'), poolInterval * 1000 * 60)

  useEffect(() => {
    activeOrdersQR.stopPolling()
    activeOrdersQR.startPolling(poolInterval * 1000 * 60)
  }, [poolInterval])

  return (
    <>
      <fieldset className="form-fieldset">
        <div className="row">
          <div className="col-md-10">
            <SelectGroup name={'orderContentStates'} options={ActiveItemsOptions} caption={'Zobrazené objednávky'} />
          </div>
          <div className="col-md-2">
            <FormField type={inputType.number} name={'poolInterval'} label={'Obnovit data po [min]'} isPositive maxLength={2} />
          </div>
        </div>
      </fieldset>
      <MapContainer center={[49.8092647, 15.4008144]} zoom={8} style={{ height: '80vh', width: '100%' }}>
        <TileLayer
          url="http://sgx.geodatenzentrum.de/wmts_topplus_open/tile/1.0.0/web/default/WEBMERCATOR/{z}/{y}/{x}.png"
          maxZoom={18}
          attribution='Map data: &copy; <a href="http://www.govdata.de/dl-de/by-2-0">dl-de/by-2-0</a>'
        />
        {activeOrdersQR.data?.orderContentList.items &&
          activeOrdersQR.data.orderContentList.items.map(orderContent => {
            if (!orderContent.order?.gps) return null
            return (
              <Marker
                key={orderContent.order!.id}
                position={coordinatesToGps(orderContent.order!.gps as { coordinates: number[] })}
                icon={createColorPointIcon(stateToColor[orderContent.orderContentState.sysName])}
                title={orderContent.order!.orderNumber}
              >
                <Popup>
                  <h1>
                    <a href="/order-list?orderNumber=${orderContent.order.orderNumber}" target="_blank">
                      {orderContent.order.orderNumber}
                    </a>
                  </h1>
                  <p>
                    <strong>Zákazník: </strong>
                    {`${orderContent.order.firstname + ' ' + orderContent.order.lastname}`}
                  </p>
                  <p>
                    <strong>Stav: </strong>
                    {orderContent.orderContentState.name}
                  </p>
                  <p>
                    <strong>Dodavatel: </strong>
                    {`${orderContent.supplier?.name ?? ''} (${orderContent.supplier?.regionCode ?? ''} - ${orderContent.supplier?.orpName ?? ''})`}
                  </p>
                </Popup>
              </Marker>
            )
          })}
      </MapContainer>
    </>
  )
}
