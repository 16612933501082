import {
  ColumnAlign,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../services/bootstrapTable/services/tableSpecificationTypes'
import { SupplierWasteCollectionFlatResponse } from '../../../graphql/aminTypes'
import { moneyToLocaleString } from '../../../services/dataToString/decimalToLocaleString'

export const flatRatesDataDefinition: TableDefinitionTypes<SupplierWasteCollectionFlatResponse> = {
  columnDefinition: {
    id: { hidden: true },
    productWasteId: { hidden: true },
    isActive: {
      name: 'Aktivní',
      columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
      align: ColumnAlign.center,
      type: 'boolean',
      headerStyle: { width: '80px' }
    },
    waste: { name: 'Odpad' },
    containerPrices: { name: 'Kontejnery' },
    actions: { name: 'Akce', columnFormatterType: ColumnFormatterTypes.actionsObject, align: ColumnAlign.center, headerStyle: { width: '80px' } }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    productWasteId: x => x.productWaste.id,
    isActive: x => x.isActive,
    waste: x => x.productWaste.name,
    containerPrices: x =>
      x.productContainers
        .map((y, i) => `${y.name} ${y.volumeM3}m³ (${moneyToLocaleString(x.pricesKc[x.productContainers.indexOf(y)], 'CZK', true)})`)
        .join(', '),
    actions: x => {
      const ret: IRowAction[] = []
      ret.push({
        type: RowActionType.codeAction,
        value: 'deleteSupplierWasteCollectionFlatRate',
        icon: 'fe-x',
        title: 'Smazat paušál'
      })
      return ret
    }
  }
}
