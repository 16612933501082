import { gql, useMutation } from '@apollo/client'
import { BaseMutationResult } from '../../../../graphql/aminTypes'

const approveFioTransactionsMutation = gql`
  mutation approveFioTransactions($ids: [String!]!) {
    approveFioTransactions(ids: $ids) {
      status {
        reason
        result
      }
    }
  }
`

export function useApproveFioTransactionsMutation() {
  return useMutation<{ approveFioTransactions: BaseMutationResult }>(approveFioTransactionsMutation, { refetchQueries: ['getFioTransactionList'] })
}
