import React from 'react'
import LoadingContainer from '../LoadingContainer'
import { useNavigate } from 'react-router-dom'
import { IStringObject } from '../../services/types/IStringObject'
import styles from './statsCard.module.css'
import classNames from 'classnames'

export function StatsCard({
  caption,
  value,
  subValue,
  loading,
  linkTo,
  className
}: {
  caption: string
  value: string
  subValue?: string
  loading?: boolean
  linkTo?: string
  className?: string
}) {
  const navigate = useNavigate()

  const onClickSupport: IStringObject = linkTo
    ? {
        onClick: () => {
          navigate(linkTo)
        },
        style: { cursor: 'pointer' }
      }
    : {}
  return (
    <div className={classNames('card', className)} {...onClickSupport} style={{ minHeight: '175px' }}>
      <LoadingContainer showLoader={!!loading}>
        <div className="card-body p-3 text-center">
          <div className="h5 m-0">{caption}</div>
          <div className={value.toString().length > 4 ? styles.smallerHeadline : styles.headline}>{value}</div>
          {subValue && <div className="text-muted">{subValue}</div>}
        </div>
      </LoadingContainer>
    </div>
  )
}
