export function customerName(data: { firstname?: string | null; lastname?: string | null; companyName?: string | null }) {
  let name = ''
  if (data.firstname || data.lastname) name = `${data.firstname} ${data.lastname}`.trim()

  if (data.companyName) {
    if (name) name += ` (${data.companyName})`
    else name = data.companyName
  }
  return name
}
