import { gql, useQuery } from '@apollo/client'
import { contextEndpoint, EndpointEnum } from '../../../services/appConfiguration/contextEndpoint'

export const allOrderContentsForFileAttachQuery = gql`
  query allOrderContentsForFileAttach {
    allOrderContentsForFileAttach {
      id
      order {
        orderNumber
      }
    }
  }
`

export function useAllOrderContentsForFileAttachQuery() {
  return useQuery<{ allOrderContentsForFileAttach: { id: number; order: { orderNumber: number } }[] }>(allOrderContentsForFileAttachQuery, {
    fetchPolicy: "network-only",
    context: contextEndpoint(EndpointEnum.supplier)
  })
}
