import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'
import { contextEndpoint, EndpointEnum } from '../../../../../services/appConfiguration/contextEndpoint'
import { wasteFinalsQuery } from '../query/waste-finals.query'
import { listOrderContentFilesQuery } from '../../../queries/listfOfFilesQuery'

const deleteWasteFinalMutation = gql`
  mutation deleteWasteFinal($wasteFinalId: ID!) {
    deleteWasteFinal(wasteFinalId: $wasteFinalId) {
      status {
        reason
        result
      }
    }
  }
`

export function useDeleteWasteFinalMutation(orderContentId: number) {
  return useMutation<{ deleteWasteFinal: IMutationResult }>(deleteWasteFinalMutation, {
    context: contextEndpoint(EndpointEnum.admin),
    refetchQueries: result => [
      {
        query: wasteFinalsQuery,
        variables: { orderContentId },
        context: contextEndpoint(EndpointEnum.admin)
      },
      {
        query: listOrderContentFilesQuery,
        variables: { orderContentId },
        context: contextEndpoint(EndpointEnum.admin)
      }
    ]
  })
}
