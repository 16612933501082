import { gql, useQuery } from '@apollo/client'

const suppliersQuery = gql`
  query suppliers {
    suppliers {
      id
      name
      gps {
        coordinates
      }
      operationRadiusM
      disabled
      orpName
      regionCode
      priority
    }
  }
`

const suppliersTypesQuery = gql`
  query suppliersIdsWithTypes {
    suppliersIdsWithTypes {
      id
      types
    }
  }
`

export function useSuppliersQuery() {
  return useQuery<{ suppliers: GpsItemType[] }>(suppliersQuery, {
    fetchPolicy: 'network-only'
  })
}

export function useSuppliersTypesQuery() {
  return useQuery<{ suppliersIdsWithTypes: SupplierIdWithType[] }>(suppliersTypesQuery, {
    fetchPolicy: 'network-only'
  })
}

export type GpsItemType = {
  id: number
  name: string
  operationRadiusM: number
  disabled: boolean
  gps: {
    coordinates: number[]
  }
  orpName: string
  regionCode: number
  priority: number
}

export type SupplierIdWithType = {
  id: number
  types: ('COLLECTION' | 'DELIVERY')[]
}
