export const orderContentStateBadgeColors = {
  Nová: 'bg-white',
  Draft: 'bg-pink',
  'Čeká na platbu': 'bg-cyan',
  'Ke schválení adminem': 'bg-warning',
  'K potvrzení dodavatelem': 'bg-info',
  'Potvrzeno dodavatelem': 'bg-purple',
  'Odmítnuto dodavatelem': 'bg-danger',
  'Kontejner přistaven': 'bg-olive',
  'Kontejner odvezen': 'bg-maroon',
  'Dokončeno dodavatelem': 'bg-success',
  'Vyúčtováno adminem': 'bg-aqua',
  Zrušeno: 'bg-dark',
  'Materiál dovezen': 'bg-maroon-red'
}
