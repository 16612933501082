//usage onKeyDown={enterListener(runAction)}
export function enterListener(runOnEnter: () => void): (event: any) => void {
  return (event: any) => {
    const event2: KeyboardEvent = event as KeyboardEvent
    if (event2.key === 'Enter') {
      event2.preventDefault()
      runOnEnter()
    }
  }
}
