import React from 'react'
// @ts-ignore
import { useDropzone } from 'react-dropzone'
import classNames from 'classnames'

import './dropZone.scss'
import LoadingContainer from '../LoadingContainer'

export default function ImportDropZone({ processImport, disabled }: {disabled?: boolean, processImport: (acceptedFiles: File[]) => void }) {
  const dropZone = useDropzone({
    multiple: false,
    onDrop: processImport
  })
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = dropZone
  return (
    <LoadingContainer showLoader={false}>
      <div
        {...getRootProps()}
        className={classNames('text-center', 'dropZoneBase', 'dropzone', isDragAccept ? 'dropZoneActive' : '', isDragReject ? 'dropZoneReject' : '', {
          'dropzone--isActive': isDragActive
        })}
      >
        <input {...getInputProps()} disabled={disabled}/>

        {disabled && <div>Vyberte nejdříve zakázku</div>}
        {!disabled && !isDragActive && <div>Pro nahrání souboru k zakázce přetáhněte soubor zde, nebo zde klikněte a soubor vyberte.</div>}
        {!disabled && isDragReject && <div>Nepodporovaný soubor</div>}
        {!disabled && isDragAccept && !isDragReject && <div>Soubor byl akceptován</div>}

        <i className="fe fe-chevrons-down display-3 text-primary" />
      </div>
    </LoadingContainer>
  )
}
