import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const createLoginMutation = gql`
  mutation createLogin($data: LoginInput!, $sendNewAccountEmail: Boolean, $assignSupplierId: Int) {
    createLogin(data: $data, sendNewAccountEmail: $sendNewAccountEmail, assignSupplierId: $assignSupplierId) {
      status {
        reason
        result
      }
    }
  }
`

export function useCreateLoginMutation() {
  return useMutation<{ createLogin: IMutationResult }>(createLoginMutation)
}

export function createParamsForCreateLoginMutation(data: any) {
  return {
    data: {
      email: data.email,
      firstname: data.firstname,
      isMasterAdmin: !!data.isMasterAdmin,
      lastname: data.lastname
    }
  }
}
