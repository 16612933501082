import { ColumnFormatterTypes, IRowAction, RowActionType, TableDefinitionTypes } from '../../../services/bootstrapTable/services/tableSpecificationTypes'
import React from 'react'
import { ProductContainerEntity } from '../../../graphql/aminTypes'

export const productContainerDataDefinition: TableDefinitionTypes<ProductContainerEntity> = {
  columnDefinition: {
    id: { hidden: true },
    name: { name: 'Jméno' },
    volume: {
      name: 'Objem m³'
    },
    tooltip: {
      name: 'Tooltip'
    },
    uiPicture: {
      name: 'Obrázek'
    },
    uiOrder: { name: 'Pořadí zobrazení', headerStyle: { width: '100px' } },
    actions: { name: 'Akce', headerStyle: { width: '80px' }, columnFormatterType: ColumnFormatterTypes.actionsObject }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    name: x => x.name,
    volume: x => x.volumeM3,
    tooltip: x => x.tooltip,
    uiOrder: x => x.uiOrder,
    uiPicture: x => (
      <img src={`https://${process.env.REACT_APP_FE_HOSTNAME}/assets/images/containers/${x.uiPicture}.svg`} width="80" title={x.uiPicture} alt={x.uiPicture} />
    ),
    actions: x => {
      const ret: IRowAction[] = []
      ret.push({
        type: RowActionType.codeAction,
        value: 'showContainerCapacities',
        icon: 'fe-eye',
        title: 'Kapacity kontejnerů'
      })
      ret.push({
        type: RowActionType.codeAction,
        value: 'deleteContainer',
        icon: 'fe-x',
        title: 'Odstranit kontejner'
      })
      return ret
    }
  }
}
