import {
  ColumnAlign,
  ColumnFilterTypes,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../services/bootstrapTable/services/tableSpecificationTypes'
import React from 'react'
import { ProductWasteEntity } from '../../../graphql/aminTypes'
import { wasteCategoryTypeTranslation } from '../../../services/types/waste-category'

export const productWasteListDataDefinition: TableDefinitionTypes<ProductWasteEntity> = {
  columnDefinition: {
    id: { hidden: true },
    isActive: {
      name: 'Aktivní',
      columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
      align: ColumnAlign.center,
      type: 'boolean',
      headerStyle: { width: '100px' }
    },
    hasAdditivesSpecification: {
      name: 'Má přísady',
      columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
      align: ColumnAlign.center
    },
    name: { name: 'Jméno' },
    uiOrder: { name: 'Pořadí zobrazení', headerStyle: { width: '100px' } },
    wasteCategory: { name: 'Kategorie odpadu' },
    wasteCode: { name: 'Kód odpadu' },
    wasteCodeName: { name: 'Název odpadu' },
    uiPicture: { name: 'Obrázek' },
    tooltip: { name: 'Tooltip' },
    note: { name: 'Poznámka' },
    actions: {
      name: 'Akce',
      columnFormatterType: ColumnFormatterTypes.actionsObject,
      align: ColumnAlign.left,
      headerStyle: { width: '130px' }
    }
  },
  sourceToColumnMapping: {
    data: x => x,
    id: x => x.id,
    isActive: x => x.isActive,
    uiOrder: x => x.uiOrder,
    name: x => x.name,
    uiPicture: x =>
      x.uiPicture ? (
        <img src={`https://${process.env.REACT_APP_FE_HOSTNAME}/assets/images/${x.uiPicture}`} width="80" title={x.name} alt={x.name} />
      ) : undefined,
    tooltip: x => x.tooltip,
    note: x => x.note,
    hasAdditivesSpecification: x => x.hasAdditivesSpecification,
    wasteCategory: x => wasteCategoryTypeTranslation[x.wasteCategory],
    wasteCode: x => x.wasteType?.code ?? '',
    wasteCodeName: x => x.wasteType?.name ?? '',
    actions: x => {
      const ret: IRowAction[] = []
      ret.push({
        type: RowActionType.codeAction,
        value: 'addSubWaste',
        icon: 'fe-plus',
        title: 'Přidat produkt s další přísadou'
      })
      ret.push({
        type: RowActionType.codeAction,
        value: 'showContainerCapacities',
        icon: 'fe-eye',
        title: 'Kapacity kontejnerů'
      })
      ret.push({
        type: RowActionType.codeAction,
        value: 'fillWasteCapacitiesForWasteChildrens',
        icon: 'fe-zap',
        title: 'Doplnit kapacity kontejnerů pro podprodukty'
      })
      ret.push({
        type: RowActionType.codeAction,
        value: 'deleteWaste',
        icon: 'fe-x',
        title: 'Odstranit odpad'
      })
      return ret
    }
  }
}
