import React, { useCallback, useEffect } from 'react'
import PageCard from '../../../../components/Cards/pageCard'
import { FormProvider, useForm } from 'react-hook-form'
import ProductsSelector, { ProductTypeEnum } from '../../../../components/dataComponents/ProductsSelector/ProductsSelector'
import ContainerSelector from '../../../../components/dataComponents/ContainerSelector/ContainerSelector'
import ToggleField from '../../../../components/formComponents/ToggleField'
import SupplierSelector from '../../../../components/dataComponents/SupplierSelector/SupplierSelector'
import FormField, { inputType } from '../../../../components/formComponents/FormField'
import { createParamsForCreateB2bPriceListMutation, useCreateB2bPriceListMutation } from './services/createB2bPriceListMutation'
import { isAnyQueryLoading } from '../../../../services/functions/queryHelpers'
import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import { useUpdateB2bPriceListMutation } from './services/updateB2bPriceListMutation'
import { B2bCustomProductType } from './services/B2bProductListQuery'
import { IStringObject } from '../../../../services/types/IStringObject'

export function AddOrEditB2BPriceList({
  companyHasFixedPrice,
  companyId,
  onSuccess,
  priceListData
}: {
  companyHasFixedPrice: boolean
  companyId: number
  onSuccess: () => void
  priceListData?: B2bCustomProductType
}) {
  const formMethods = useForm<IStringObject>()
  const [createB2bPriceListMut, createB2bPriceListMR] = useCreateB2bPriceListMutation()
  const [updateB2bPriceListMut, updateB2bPriceListMR] = useUpdateB2bPriceListMutation()

  const updateFormValues = useCallback(() => {
    if (!priceListData) return
    for (const key of Object.keys(priceListData)) {
      formMethods.setValue(key, priceListData[key])
    }
    formMethods.setValue('containerId', priceListData.container?.id)
    formMethods.setValue('supplierId', priceListData.supplier?.id)
    formMethods.setValue('productId', priceListData.product.id)
  }, [])

  useEffect(() => {
    updateFormValues()
  }, [])

  return (
    <PageCard showLoader={isAnyQueryLoading(createB2bPriceListMR, updateB2bPriceListMR)}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
          <div className="row ">
            <div className=" col col-md-6 mb-3">
              <ProductsSelector productType={ProductTypeEnum.isCollection} required disabled={priceListData?.id !== undefined} />
            </div>
            {companyHasFixedPrice && (
              <>
                <div className=" col col-md-6 mb-3">
                  <ContainerSelector />
                </div>
                <div className=" col col-md-6 mb-3">
                  <SupplierSelector name={'supplierId'} label={'Dodavatel'} required />
                </div>
                <div className=" col col-md-6 mb-3">
                  <FormField type={inputType.number} isPositive name={'fixedPrice'} label={'Fixní cena (za odvoz pro dodavatele)'} required />
                </div>
                <div className=" col col-md-6 mb-3">
                  <FormField type={inputType.number} isPositive name={'priceCorrectionPercent'} label={'Marže pro metrák (z fixní ceny)'} required />
                </div>
              </>
            )}
            {!companyHasFixedPrice && (
              <div className=" col col-md-6 mb-3">
                <FormField type={inputType.number} isPositive name={'priceCorrectionPercent'} label={'Úprava marže (sleva) z vypočítané ceny [%]'} required />
              </div>
            )}
            <div className=" col col-md-12">
              <ToggleField name={'disabled'} label={'Nepoužívat'} required />
            </div>
            <div className=" col col-md-12 text-right">
              <button type={'button'} className="btn btn-primary">
                {priceListData?.id ? 'Uložit ceník' : 'Přidat ceník'}
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </PageCard>
  )

  function handleFormSubmit(data) {
    const updatedData = { b2bCustomerId: companyId, ...data }
    const resultPromise =
      priceListData?.id === undefined
        ? createB2bPriceListMut({ variables: createParamsForCreateB2bPriceListMutation(updatedData) })
        : updateB2bPriceListMut({ variables: { id: priceListData?.id, ...createParamsForCreateB2bPriceListMutation(updatedData) } })

    processPageOperation({
      promise: resultPromise,
      successAction: onSuccess
    })
  }
}
