import { gql, useQuery } from '@apollo/client'
import { contextEndpoint, EndpointEnum } from '../../../../services/appConfiguration/contextEndpoint'
import { ListPouEntity } from '../../../../graphql/aminTypes'

export const suggestPouQuery = gql`
  query suggestPou($search: String!) {
    suggestPou(search: $search) {
      id
      name
      orp {
        id
        name
      }
    }
  }
`

export function useSuggestPouQuery() {
  return useQuery<{ suggestPou: ListPouEntity[] }>(suggestPouQuery, {
    context: contextEndpoint(EndpointEnum.admin),
    skip: true
  })
}
