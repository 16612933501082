import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const deleteContainerToSupplierMutation = gql`
  mutation deleteContainerToSupplier($id: ID!) {
    deleteContainerToSupplier(id: $id) {
      status {
        reason
        result
      }
    }
  }
`

export function useDeleteContainerToSupplierMutation() {
  return useMutation<{ deleteContainerToSupplier: IMutationResult }>(deleteContainerToSupplierMutation, { refetchQueries: ['supplierContainerList'] })
}
