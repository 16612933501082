import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const updateLoginMutation = gql`
  mutation updateLogin($loginId: Int!, $data: LoginInput!) {
    updateLogin(loginId: $loginId, data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpdateLoginMutation() {
  return useMutation<{ updateLogin: IMutationResult }>(updateLoginMutation)
}
