import {
  ColumnAlign,
  ColumnFilterTypes,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes,
  WayOfIdHandover
} from '../../../services/bootstrapTable/services/tableSpecificationTypes'
import { decimalToLocaleString, moneyToLocaleString } from '../../../services/dataToString/decimalToLocaleString'
import { generalBadgeColors } from './badgeColours'
import { formatPhoneToDisplay } from '../../../services/dataToString/formatPhoneToDisplay'
import { httpBuildQueryString } from '../../../services/functions/httpBuildQuery'
import { OrderContentTypesEnum } from '../../../services/types/orderContentTypesEnum'
import { OrderContentsResult } from '../../../graphql/supplierTypes'
import dayjs from 'dayjs'
import { serviceTypeTranslation } from '../../../services/types/service-type'

function prepareContactName(x: OrderContentsResult) {
  return x.onsiteContactPhone ? `${x.onsiteContactFirstname ?? ''} ${x.onsiteContactLastname ?? ''}` : `${x.order.firstname ?? ''} ${x.order.lastname ?? ''}`
}

export const orderListDataDefinition: TableDefinitionTypes<OrderContentsResult> = {
  columnDefinition: {
    id: { hidden: true },
    serviceTypeData: { hidden: true },
    orderNumber: {
      name: 'Objednávka',
      filterType: ColumnFilterTypes.customNumeric,
      remoteSort: true,
      headerStyle: { width: '130px' },
      defaultRemoteSort: 'desc'
    },
    city: { name: 'Adresa', filterType: ColumnFilterTypes.customText, headerStyle: { width: '150px' } },
    serviceType: {
      name: 'Typ zakázky',
      filterType: ColumnFilterTypes.customSelect,
      align: ColumnAlign.center,
      headerStyle: { width: '150px' }
    },
    container: { name: 'Kontejner' },
    product: { name: 'Produkt' },
    weightTonnes: { name: 'Návoz materiálu [t]', headerStyle: { width: '80px' } },
    containerFromDate: {
      name: 'Přistavení',
      remoteSort: true,
      filterType: ColumnFilterTypes.date,
      headerStyle: { width: '150px', zIndex: 320 }
    },
    containerToDate: {
      name: 'Odvoz',
      remoteSort: true,
      filterType: ColumnFilterTypes.date,
      headerStyle: { width: '150px', zIndex: 300 }
    },
    orderNote: { name: 'Poznámka zákazníka' },
    supplierNote: { name: 'Poznámka od Metrák.cz' },
    supplierProfitWoVatKc: {
      name: 'Cena za zakázku bez DPH',
      headerStyle: { width: '100px' },
      align: ColumnAlign.right
    },
    name: { name: 'Jméno' },
    phone: { name: 'Kontakt', headerStyle: { minWidth: '100px' } },
    cod: {
      name: 'Vybrat dobírku',
      headerStyle: { width: '100px' },
      align: ColumnAlign.right
    },
    state: {
      name: 'Stav',
      columnFormatterType: ColumnFormatterTypes.showInBadge,
      align: ColumnAlign.center,
      badgeColours: generalBadgeColors,
      filterType: ColumnFilterTypes.customSelect,
      headerStyle: { width: '120px' }
    },
    gps: { name: 'gps', hidden: true },
    uuid: { hidden: true },
    actions: {
      name: 'Akce',
      columnFormatterType: ColumnFormatterTypes.actionsObject,
      headerStyle: { width: '100px' },
      align: ColumnAlign.right
    }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    serviceTypeData: x => x.serviceType,
    orderNumber: x => x.order.orderNumber,
    name: x => (showDetailData(x) ? prepareContactName(x) : '-'),
    phone: x =>
      showDetailData(x) ? (
        <a href={'tel:' + (x.onsiteContactPhone ? x.onsiteContactPhone : x.order.phone)}>
          {formatPhoneToDisplay(x.onsiteContactPhone ? x.onsiteContactPhone : x.order.phone)}
        </a>
      ) : (
        '-'
      ),
    city: x =>
      showDetailData(x) ? (
        <a href={`https://www.google.com/maps/place/${x.order.gps.coordinates![1]},${x.order.gps.coordinates![0]}`} target="_blank" rel="noopener noreferrer">
          {`${x.order.city}, ${x.order.street} ${x.order.streetNumber}`}
        </a>
      ) : (
        <a href={`https://www.google.com/maps/place/${x.order.street},${x.order.city}`} target="_blank" rel="noopener noreferrer">
          {`${x.order.city}, ${x.order.street}`}
        </a>
      ),
    supplierProfitWoVatKc: x => moneyToLocaleString(x.supplierProfitWoVatKc, 'CZK', false),
    totalDistance: x => (x.distanceTotalKm ? decimalToLocaleString(Math.ceil(x.distanceTotalKm)) : ''),
    cod: x => (showDetailData(x) && x.codToCollect ? moneyToLocaleString(x.codToCollect, 'CZK', true) : '-'),
    container: x => {
      if (x.container) {
        return (
          <>
            <strong>{x.containerCount}× </strong>
            {x.container.name} {x.container.capacityM3} m³
          </>
        )
      }
    },
    containerCount: x => (x.containerCount ? x.containerCount : ''),
    weightTonnes: x => (x.serviceType === 'MATERIAL_DELIVERY' ? x.weightTonnes : ''),
    serviceType: x => serviceTypeTranslation[x.serviceType],
    product: x => x.product.name,
    state: x => x.orderContentState.nameForSupplier,
    containerFromDate: x => (
      <>
        {`${dayjs(x.containerFromDate).format('ddd DD.MM.YYYY')} `} <br /> {x.arriveTime}
      </>
    ),
    containerToDate: x =>
      x.containerToDate ? (
        <>
          {dayjs(x.containerToDate).format('ddd DD.MM.YYYY')}
          <br /> {x.collectionTime ?? ''}
        </>
      ) : (
        ''
      ),
    orderNote: x => x.order.orderNote ?? '',
    supplierNote: x => x.supplierNote ?? '',
    gps: x => x.order.gps.coordinates,
    uuid: x => x.order.uuid,
    actions: x => {
      const ret: IRowAction[] = []
      if ('ForSupplierToConfirm' === x.orderContentState.sysName) {
        ret.push({
          type: RowActionType.codeAction,
          value: 'confirmBySupplier',
          icon: 'fe-check',
          title: 'Závazně přijmout poptávku'
        })
        ret.push({
          type: RowActionType.codeAction,
          value: 'cancelOrder',
          icon: 'fe-x',
          title: 'Odmítnout zakázku'
        })
      }
      if (x.serviceType === 'MATERIAL_DELIVERY' && OrderContentTypesEnum.ConfirmedBySupplier === x.orderContentState.sysName)
        ret.push({
          type: RowActionType.codeAction,
          value: 'materialDelivered',
          icon: 'fe-truck',
          title: 'Material byl dovezen'
        })
      if (x.serviceType === 'WASTE_COLLECTION' && OrderContentTypesEnum.ConfirmedBySupplier === x.orderContentState.sysName)
        ret.push({
          type: RowActionType.codeAction,
          value: 'containerArrived',
          icon: 'fe-download',
          title: 'Kontejner byl přistaven'
        })
      if (x.serviceType === 'WASTE_COLLECTION' && OrderContentTypesEnum.ContainerArrived === x.orderContentState.sysName)
        ret.push({
          type: RowActionType.codeAction,
          value: 'containerTakenBack',
          icon: 'fe-upload',
          title: 'Kontejner byl odvezen'
        })
      if (['ContainerTakenBack', 'MaterialDelivered'].includes(x.orderContentState.sysName))
        ret.push({
          type: RowActionType.codeAction,
          value: 'finishOrder',
          icon: 'fe-thumbs-up',
          title: 'Dokončit zakázku'
        })
      if (!['Cancelled', 'OrderChargedByAdmin'].includes(x.orderContentState.sysName))
        ret.push({
          type: RowActionType.routerLink,
          value: 'supplier/file-upload',
          wayOfIdHandover: WayOfIdHandover.search,
          searchStringBuilder: () => httpBuildQueryString({ orderContentId: x.id }),
          icon: 'fe-file',
          title: 'Přiložit soubor'
        })

      return ret
    }
  }
}

function showDetailData(data: OrderContentsResult) {
  return data.orderContentState.sysName !== 'ForSupplierToConfirm'
}
