import classnames from 'classnames'
import React from 'react'

export default function DisableContainer({ disabled, children }: { disabled: boolean; children: React.ReactNode }) {
  return (
    <div className={classnames('dimmer', disabled ? ' active' : undefined)} style={{ minHeight: '50px' }}>
      <div className="dimmer-content">{children}</div>
    </div>
  )
}
