import React from 'react'
import LoadingContainer from '../../../../components/LoadingContainer'
import { isAnyQueryLoading } from '../../../../services/functions/queryHelpers'
import { useApproveOrderContentMutation } from './mutation/approveOrderContent.mutation'
import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import { formatDateTimeForDisplayShort } from '../../../../services/dataToString/dateTimeFormatter'

export default function OrderOperations({
  orderContentId,
  disabled,
  approvedAt,
  approvedBy
}: {
  orderContentId: number
  disabled: boolean
  approvedBy?: string | null
  approvedAt?: string | null
}) {
  const [approveOrderContentMut, approveOrderContentQR] = useApproveOrderContentMutation()
  return (
    <LoadingContainer showLoader={isAnyQueryLoading(approveOrderContentQR)}>
      <div className="mt-3">
        <strong>Objednávka schválena operátorem:</strong>
        {approvedAt ? (
          <p className="ml-2">
            {approvedBy}, {formatDateTimeForDisplayShort(approvedAt)}
          </p>
        ) : (
          ' NE'
        )}
      </div>
      {!disabled && (
        <div className="mt-3">
          <button type="submit" className="btn btn-red ms-auto btn-sm" onClick={toggleApproval}>
            {approvedAt ? 'Zrušit schválení' : 'Schválit objednávku'}
          </button>
        </div>
      )}
    </LoadingContainer>
  )

  async function toggleApproval() {
    const promise = approveOrderContentMut({ variables: { orderContentId: orderContentId, cancelApprove: !!approvedAt } })
    return processPageOperation({
      promise: promise,
      successMessage: approvedAt ? 'Schválení objednávky zrušeno' : 'Objednávka schválena'
    })
  }
}
