import { useSupplierTransportSupplierLinkQuery } from './queries/supplier-transport-supplier-link.query'
import React from 'react'
import LoadingContainer from '../../../components/LoadingContainer'
import { isAnyQueryLoading } from '../../../services/functions/queryHelpers'
import BootstrapTable from 'react-bootstrap-table-next'
import useBootstrapTableDataMapper from '../../../services/bootstrapTable/useBootstrapTableDataMapper'
import { transportSupplierLinkDataDefinition } from './services/transport-supplier-link-data-definition'
import { processPageOperation } from '../../../services/formsServices/pageOperationProcessor'
import { TransportCategory } from '../../../graphql/aminTypes'
import { useDeleteSupplierTransportSupplierLinkMutation } from './mutations/delete-transport-supplier-link.mutation'
import { AddSupplier } from './components/AddSupplier/AddSupplier'
import cellEditFactory from 'react-bootstrap-table2-editor'
import { formatEditorValue } from '../../../services/bootstrapTable/format-editor-value'
import { useUpdateTransportSupplierLinkMutation } from './mutations/update-transport-supplier-link.mutation'

export function TransportLinkComponent({ transportCategory, branchServiceId }: { transportCategory: TransportCategory; branchServiceId: string }) {
  const supplierTransportSupplierLinkQR = useSupplierTransportSupplierLinkQuery(branchServiceId, transportCategory)

  const [deleteTransportLinkMut, deleteTransportLinkMR] = useDeleteSupplierTransportSupplierLinkMutation()
  const [updateTransportSupplierLinkMut, updateTransportSupplierLinkMR] = useUpdateTransportSupplierLinkMutation()

  const { generateColumnListForBootstrapTable, mapDataArrayToTable } = useBootstrapTableDataMapper(transportSupplierLinkDataDefinition)
  const columnDefinition = generateColumnListForBootstrapTable({
    actionsReference: {
      deleteLink: deleteLinkHandler
    }
  })

  const tableData = mapDataArrayToTable(supplierTransportSupplierLinkQR.data?.supplierTransportSupplierLink)
  return (
    <LoadingContainer showLoader={isAnyQueryLoading(supplierTransportSupplierLinkQR, deleteTransportLinkMR, updateTransportSupplierLinkMR)}>
      <AddSupplier
        transportCategory={transportCategory}
        branchServiceId={branchServiceId}
        doNotShowSuppliers={supplierTransportSupplierLinkQR.data?.supplierTransportSupplierLink.map(x => x.supplierBranchId) ?? []}
      />
      <br />{' '}
      <BootstrapTable
        bootstrap4
        striped
        condensed
        classes="table-responsive-lg digiTable"
        noDataIndication={() => <>Nejsou k dispozici žádní dodavatelé</>}
        keyField="id"
        data={tableData}
        columns={columnDefinition}
        cellEdit={cellEditFactory({
          mode: 'click',
          blurToSave: true,
          autoSelectText: true,
          beforeSaveCell: updateWasteCollectionLinkHandler
        })}
      />
    </LoadingContainer>
  )

  function deleteLinkHandler({ id }) {
    const promise = deleteTransportLinkMut({ variables: { id } })
    processPageOperation({
      promise,
      successMessage: 'Dodavatel byl odpojen'
    })
  }

  function updateWasteCollectionLinkHandler(oldValue, newValue, row, column) {
    if (!row.id || oldValue === newValue) return
    let variables = { transportSupplierLinkId: row.id, includedKmInFlatRate: row.includedKmInFlatRate, transportFlatRateKc: row.transportFlatRateKc }
    variables[column.dataField] = formatEditorValue(newValue, column)

    const resultPromise = updateTransportSupplierLinkMut({
      variables
    })
    processPageOperation({
      promise: resultPromise,
      successMessage: 'Hodnota byla upravena'
    })
  }
}
