import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'

const upsertProductMaterialMutation = gql`
  mutation upsertProductMaterial($productMaterialId: ID, $data: ProductMaterialInputArgs!) {
    upsertProductMaterial(data: $data, productMaterialId: $productMaterialId) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpsertProductMaterialMutation() {
  return useMutation<{ upsertProductMaterial: IMutationResult }>(upsertProductMaterialMutation, { refetchQueries: ['productMaterialList'] })
}
