import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'
import { convertDateForGql } from '../../../services/functions/convertDateForGql'

const runOrderOperationMutation = gql`
  mutation runOrderOperation($orderId: Int, $operation: orderOperationEnum!, $paramsDate: String) {
    runOrderOperation(orderId: $orderId, operation: $operation, paramsDate: $paramsDate) {
      status {
        reason
        result
      }
    }
  }
`

export function useRunOrderOperationMutation() {
  return useMutation<{ runOrderOperation: IMutationResult }>(runOrderOperationMutation, {
    refetchQueries: ['orderDetail', 'emailHistory']
  })
}

export function createParamsForRunOrderOperationMutation(orderId: number, operation: runOrderOperationEnum, date?: Date) {
  return { orderId, operation, paramsDate: date ? convertDateForGql(date) : undefined }
}

export enum runOrderOperationEnum {
  'CERTIFICATE_DONE' = 'CERTIFICATE_DONE',
  'UNDO_CERTIFICATE_DONE' = 'UNDO_CERTIFICATE_DONE',
  'CREATE_RECEIPT' = 'CREATE_RECEIPT',
  'INVOICING_NOTE_DONE_TRUE' = 'INVOICING_NOTE_DONE_TRUE',
  'INVOICING_NOTE_DONE_FALSE' = 'INVOICING_NOTE_DONE_FALSE',
  'SEND_ORDER_ACCEPTED_EMAIL_TO_CUSTOMER' = 'SEND_ORDER_ACCEPTED_EMAIL_TO_CUSTOMER',
  'SEND_SUPPLIER_FULL_ORDER_INFO_EMAIL' = 'SEND_SUPPLIER_FULL_ORDER_INFO_EMAIL'
}
