import PageCard from '../../components/Cards/pageCard'
import React, { useContext } from 'react'
import { isAnyQueryLoading } from '../../services/functions/queryHelpers'
import { useAllOrderContentsForFileAttachQuery } from './services/allOrderContentsForFileAttachQuery'
import ImportDropZone from '../../components/DropZone/ImportDropZone'
import { FormProvider, useForm } from 'react-hook-form'
import SelectField from '../../components/formComponents/SelectField'
import { IReactSelectItem } from '../../services/types/ReactSelect'
import { sortArrayBy } from '../../services/functions/sortArrayBy'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import useBootstrapTableDataMapper from '../../services/bootstrapTable/useBootstrapTableDataMapper'
import { getParamFromUrl } from '../../services/functions/getParamFromUrl'
import BootstrapTable from 'react-bootstrap-table-next'
import { useListOrderContentFilesQuery } from './services/listfOfFilesQuery'
import { fileListDataDefinition } from './services/fileListDataDefinition'
import { useDeleteFileMutation } from './services/deleteFileMutation'
import { useGetDownloadUrlQuery } from './services/getDownloadUrlQuery'
import { toast } from 'react-toastify'
import axios from 'axios'
import { LoginContext } from '../../services/loginAndToken/useLogin'

export default function FileUpload() {
  const orderContentIdFromParams = getParamFromUrl('orderContentId')
  const formMethods = useForm({ defaultValues: { orderContentId: orderContentIdFromParams ? parseInt(orderContentIdFromParams) : undefined } })
  const orderContentId = formMethods.watch('orderContentId')
  const currentLogin = useContext(LoginContext)

  const allOrderContentsForFileAttachQR = useAllOrderContentsForFileAttachQuery()
  const [deleteFileMutation, deleteFileQR] = useDeleteFileMutation(() => orderContentId ?? 0)
  const getDownloadUrlQR = useGetDownloadUrlQuery()

  const orderContentFilesQR = useListOrderContentFilesQuery(orderContentId)

  const { generateColumnListForBootstrapTable, mapDataArrayToTable } = useBootstrapTableDataMapper(fileListDataDefinition)
  const columnDefinition = generateColumnListForBootstrapTable({
    columnAction: handleTableClick,
    actionsReference: {
      deleteFile: deleteFileHandler
    }
  })
  let fileListData: any[] = []
  if (orderContentFilesQR.data && orderContentFilesQR.data.listOrderContentFiles)
    fileListData = mapDataArrayToTable(orderContentFilesQR.data.listOrderContentFiles)

  let supplierOrderList: IReactSelectItem<any>[] = []
  if (!allOrderContentsForFileAttachQR.loading && allOrderContentsForFileAttachQR.data) {
    supplierOrderList = allOrderContentsForFileAttachQR.data.allOrderContentsForFileAttach.map(x => ({
      label: x.order.orderNumber.toString(),
      value: x.id
    }))
    sortArrayBy(supplierOrderList, x => x.label)
  }

  return (
    <PageCard showLoader={isAnyQueryLoading(allOrderContentsForFileAttachQR, deleteFileQR, orderContentFilesQR)} headerTitle={'Nahrát soubory k zakázkám'}>
      <FormProvider {...formMethods}>
        <form autoComplete={'off'}>
          <div className="row">
            <div className="col-6">
              <SelectField
                name={'orderContentId'}
                label={'Vyberte zakázku'}
                required={true}
                registerOptions={{ required: true }}
                optionsData={supplierOrderList}
                isLoading={allOrderContentsForFileAttachQR.loading}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-6">
              <ImportDropZone processImport={processImport} disabled={!orderContentId} />
            </div>
            <div className="col-md-6">
              <BootstrapTable
                bootstrap4
                striped
                condensed
                classes="table-responsive-lg digiTable"
                noDataIndication={() => (orderContentId ? <>Zakázka u sebe nemá žádné soubory</> : <>Vyberte nejdříve zakázku</>)}
                keyField="id"
                data={fileListData}
                columns={columnDefinition}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </PageCard>
  )

  async function processImport(acceptedFiles: File[]) {
    if (!orderContentId) {
      toast.error('Vyberte nejdříve objednávku')
      return
    }
    if (acceptedFiles.length !== 1) {
      toast.error('Soubor může být jen jeden o velikosti max 10MB.')
    } else {
      const endpoint = `${process.env.REACT_APP_CONNECTION_PROTOCOL ?? 'https'}://${process.env.REACT_APP_BACKEND_HOSTNAME}/api/supplier/upload`
      const formData = new FormData()
      formData.append('file', acceptedFiles[0])
      formData.append('orderContentId', `${orderContentId}`)

      const response = await axios.post(endpoint, formData, {
        headers: {
          Authorization: `Bearer ${currentLogin?.at}`,
          'Content-Type': 'multipart/form-data'
        }
      })
      if (response.status === 200) {
        toast.success('Soubor byl nahrán')
        await orderContentFilesQR.refetch({ orderContentId: orderContentId })
      } else {
        toast.error('Nahrání souboru se nezdařilo')
      }
    }
  }

  function handleTableClick(e: Event, column: any, columnIndex: number, row: any) {
    if (columnIndex === 0) {
      const ret = getDownloadUrlQR.refetch({ key: row.key })
      if (!ret) return
      ret
        .then(data => {
          window.open(data.data.getDownloadUrl)
        })
        .catch(error => {
          toast.error(error.toString())
        })
    }
  }

  function deleteFileHandler(row: any) {
    if (window.confirm('Opravdu nevratně odstranit soubor ' + row.filename)) {
      const promise = deleteFileMutation({ variables: { key: row.key, orderContentId: orderContentId } })
      processPageOperation({
        promise: promise,
        successMessage: `Soubor ${row.filename} byl smazán`
      })
    }
  }
}
