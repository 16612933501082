import { useCheckAdvanceAccountAvailabilityQuery } from './queries/check-advance-account-status.query'
import React, { useEffect, useState } from 'react'
import { CheckAdvanceAccountAvailabilityResponse, CheckAdvanceAccountStatusResponseEnum } from '../../../../graphql/aminTypes'
import { useFormContext } from 'react-hook-form'
import { ErrorAlert, SuccessAlert, WarningAlert } from '../../../../components/formComponents/alert'
import { advanceAccountStatusMap } from '../../../../services/types/advance-account-status'
import { moneyToLocaleString } from '../../../../services/dataToString/decimalToLocaleString'

export default function AdvanceAccountInfo() {
  const advanceAccountAvailabilityQR = useCheckAdvanceAccountAvailabilityQuery()
  const [advanceAccountAvailability, setAdvanceAccountAvailability] = useState<CheckAdvanceAccountAvailabilityResponse>()
  const formMethods = useFormContext()

  const customerId = formMethods.watch('b2bCustomerId')
  const address = formMethods.watch('address')
  const totalInclVatKc = formMethods.watch('totalInclVatKc')
  const paymentMethod = formMethods.watch('paymentType')

  useEffect(() => {
    if (paymentMethod !== 'ADVANCE_PAYMENT' || !customerId || !address || !address.lat) return
    advanceAccountAvailabilityQR
      .refetch({
        customerId,
        orderTotalKc: totalInclVatKc ? parseFloat(totalInclVatKc) : undefined,
        gps: { lat: parseFloat(address['lat']), lng: parseFloat(address['lng']) }
      })
      .then(x => {
        if (!x || !x.data || !x.data.checkAdvanceAccountAvailability) return
        setAdvanceAccountAvailability(x.data.checkAdvanceAccountAvailability)
      })
  }, [totalInclVatKc, address, customerId, paymentMethod])

  if (paymentMethod === 'ADVANCE_PAYMENT' && (!customerId || !address || !address.lat))
    return <ErrorAlert message={'Není vybrán zákazník nebo adresa pro zjištění dostupnosti zálohového účtu'} />

  if (
    advanceAccountAvailability?.response === CheckAdvanceAccountStatusResponseEnum.MatchingAdvanceAccountFound ||
    advanceAccountAvailability?.response === CheckAdvanceAccountStatusResponseEnum.MatchingAdvanceAccountHasEnoughBalance
  )
    return (
      <SuccessAlert
        message={`${advanceAccountStatusMap[advanceAccountAvailability.response]}. Zůstatek na účtu: ${moneyToLocaleString(
          advanceAccountAvailability.accountRemainingBalanceKc,
          'CZK'
        )}`}
      />
    )
  else if (advanceAccountAvailability)
    return (
      <WarningAlert
        message={`${advanceAccountStatusMap[advanceAccountAvailability.response]}. ${
          advanceAccountAvailability.accountRemainingBalanceKc
            ? 'Zůstatek na účtu: ' + moneyToLocaleString(advanceAccountAvailability.accountRemainingBalanceKc, 'CZK')
            : ''
        }`}
      />
    )
  else return <></>
}
