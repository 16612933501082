import { TableDefinitionTypes } from '../../../services/bootstrapTable/services/tableSpecificationTypes'
import { SearchWholeDbResult } from './searchWholeDbQuery'
import { tableNameToSection } from './tableNameToSection'

export const searchWholeDbDataDefinition: TableDefinitionTypes<SearchWholeDbResult> = {
  columnDefinition: {
    id: { name: 'Id' },
    tableName: { hidden: true },
    section: { name: 'Oblast' }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    tableName: x => x.tableName,
    section: x => tableNameToSection[x.tableName],
    keyField:  x => `${x.tableName}${x.id}`
  }
}
