import { gql, useQuery } from '@apollo/client'
import { contextEndpoint, EndpointEnum } from '../../../services/appConfiguration/contextEndpoint'

export const calculatePriceQuery = gql`
  query calculatePrice($locality: GpsCoordinations!, $containers: [RequestedContainer!], $cod: Boolean!, $b2bCustomerId: Int, $forcedSupplierId: Int) {
    calculatePrice(locality: $locality, containers: $containers, cod: $cod, b2bCustomerId: $b2bCustomerId, forcedSupplierId: $forcedSupplierId) {
      routeSpecification {
        bestSupplierId
        bestTerminalId
        distanceKm
      }
      totalPrice {
        totalPriceKcInclVat
        totalPriceKcWoVat
        totalDiscountInclVat
        containerPrices {
          basePriceParts {
            transferPrice
            flatRate
            terminalPrice
            margin
            marginDiscount
            b2bMargin
            priceLimitDiscount
          }
          basePriceKcWoVat
          supplierProfitWoVatKc
          additionalFees {
            type
            priceKcWoVat
          }
        }
        additionalFees {
          type
          priceKcWoVat
        }
      }
    }
  }
`

export function useCalculatePriceQuery() {
  return useQuery<{ calculatePrice: containerCalcResult }>(calculatePriceQuery, {
    fetchPolicy: 'no-cache',
    skip: true,
    context: contextEndpoint(EndpointEnum.admin)
  })
}

export function createParamsForCalculatePriceQuery(
  lng: string,
  lat: string,
  cod: boolean,
  containers: containerCalcDefinition[],
  b2bCustomerId?: number,
  forcedSupplierId?: number
) {
  return {
    locality: {
      lng,
      lat
    },
    containers: containers,
    cod: cod,
    b2bCustomerId: b2bCustomerId,
    forcedSupplierId: forcedSupplierId
  }
}

export type containerCalcDefinition = {
  productId: number
  containerId: number
  count: number
  fromDate: string
  toDate?: string
  arriveTime: string
  collectionTime?: string
}

export type containerCalcResult = {
  routeSpecification: {
    bestSupplierId: number
    bestTerminalId: number
    distanceKm: number
  }
  totalPrice: {
    totalPriceKcInclVat: number
    totalPriceKcWoVat: number
    totalDiscountInclVat: number
    containerPrices: ContainerPriceItem[]
    additionalFees: AdditionalFeePrices[]
  }
}
export type ContainerPriceItem = {
  supplierProfitWoVatKc: number
  basePriceKcWoVat: number
  basePriceParts: BasePricePartsType
  additionalFees: AdditionalFeePrices[]
}

export type AdditionalFeePrices = {
  type: string
  priceKcWoVat: number
}

export type BasePricePartsType = {
  flatRate: number
  terminalPrice: number
  transferPrice: number
  margin: number
  marginDiscount: number
  b2bMargin: number
  priceLimitDiscount: number
}
