import { gql, useQuery } from '@apollo/client'
import { IGqlPagingQueryParams } from '../../../services/bootstrapTable/usePagingFromURLQuery'
import { SupplierBranchListResponse, SupplierSupplierListResponse } from '../../../graphql/aminTypes'
import { ServiceCategoryType } from '../../../services/types/service-category.type'

export const supplierBranchByIdQuery = gql`
  query supplierBranchList($id: ID) {
    supplierBranchList(id: $id) {
      items {
        id
        gps {
          lng
          lat
        }
        address {
          id
          city
          street
          streetNumber
          id
          zip
        }
        branchServices {
          id
          higherPriority
          isActive
          metrakMargin
          settings
          validFrom
          validTo
          serviceCategory
        }
      }
    }
  }
`

export function useSupplierBranchByIdQuery(id?: string | null, skip?: boolean) {
  return useQuery<{ supplierBranchList: SupplierBranchListResponse }>(supplierBranchByIdQuery, {
    fetchPolicy: 'network-only',
    variables: { id },
    skip: skip || !id
  })
}
