import React from 'react'
import SelectField from '../../formComponents/SelectField'
import { IReactSelectItem } from '../../../services/types/ReactSelect'
import { ContainerItemType, useContainerTypesQuery } from '../../../services/queries/containersQuery'

export default function ContainerSelector({
  disabled,
  removeContainers,
  allowedContainerIds
}: {
  disabled?: boolean
  removeContainers?: number[]
  allowedContainerIds?: number[]
}) {
  const containerTypesQR = useContainerTypesQuery()
  let containersData: IReactSelectItem[] = []
  let containersDataUpdated: IReactSelectItem[] = []
  if (!containerTypesQR.loading && containerTypesQR.data) {
    const sortedContainers = containerTypesQR.data.containerTypes.slice().sort((a: ContainerItemType, b: ContainerItemType) => a.uiOrder - b.uiOrder)
    containersData = sortedContainers.map(x => ({
      label: `${x.name} - ${x.capacityM3}m³`,
      value: x.id,
      id: x.id,
      color: !x.disabled && (!allowedContainerIds || allowedContainerIds?.includes(x.id)) ? 'black' : '#ccc'
    }))
  }
  if (containersData.length > 0 && removeContainers && removeContainers.length > 0) {
    containersDataUpdated = containersData.slice(0).filter(x => !removeContainers?.includes(x.id as number))
  }
  return (
    <SelectField
      disabled={disabled}
      name={'containerId'}
      label={'Kontejner'}
      required
      registerOptions={{ required: true }}
      optionsData={removeContainers && removeContainers.length > 0 ? containersDataUpdated : containersData}
      isLoading={containerTypesQR.loading}
    />
  )
}
