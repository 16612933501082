export const stateToColor = {
  WaitingForPayment: '#FF6384',
  ForAdminToApprove: '#36A2EB',
  ForSupplierToConfirm: '#FFCE56',
  RefusedBySupplier: '#4BC0C0',
  ConfirmedBySupplier: '#9966FF',
  ContainerArrived: '#FF9F40',
  ContainerTakenBack: '#8DD3C7',
  MaterialDelivered: '#FB8072',
  OrderFinishedBySupplier: '#80B1D3',
  OrderChargedByAdmin: '#B3DE69'
}
