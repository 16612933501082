import { gql, useQuery } from '@apollo/client'
import { OrderContentListResult } from '../../../../graphql/aminTypes'
import { convertDateForGql } from '../../../../services/functions/convertDateForGql'

const heatMapQuery = gql`
  query heatMapQuery($createdAtTo: String, $createdAtFrom: String, $serviceType: ServiceTypeEnum, $productId: String) {
    orderContentList(limit: 2000, createdAtFrom: $createdAtFrom, createdAtTo: $createdAtTo, serviceType: $serviceType, productId: $productId) {
      items {
        id
        serviceType
        order {
          id
          gps {
            coordinates
          }
        }
      }
    }
  }
`

export function createParamsForHeatMapsQuery(data: any) {
  return {
    createdAtFrom: data?.createdAtFrom ? convertDateForGql(data.createdAtFrom) : undefined,
    createdAtTo: data?.createdAtTo ? convertDateForGql(data.createdAtTo) : undefined,
    serviceType: data?.serviceType ? data?.serviceType : undefined,
    productId: data?.productId ? `${data.productId}` : undefined
  }
}

export function useHeatMapOrders(data: any, onCompleted: (result: { orderContentList: OrderContentListResult }) => void) {
  return useQuery<{ orderContentList: OrderContentListResult }>(heatMapQuery, {
    variables: createParamsForHeatMapsQuery(data),
    onCompleted: onCompleted
  })
}
