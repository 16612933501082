import { ColumnFormatterTypes, IRowAction, RowActionType, TableDefinitionTypes } from '../../../services/bootstrapTable/services/tableSpecificationTypes'
import { ProductConcreteComponentWithPrice } from '../../SupplierServiceConcreteSeller/types/ProductConcreteComponentWithPrice'

export const productConcreteComponentDataDefinition: TableDefinitionTypes<ProductConcreteComponentWithPrice> = {
  columnDefinition: {
    id: { hidden: true },
    type: { hidden: true },
    uiOrder: { name: 'Pořadí na FE', editable: true },
    name: { name: 'Jméno', sort: true, editable: true },
    tooltip: { name: 'Tooltip', editable: true },
    cubicToTonnes: { name: 'Výchozí m³/t', editable: true },
    isForPublic: { name: 'Pro veřejnost', editable: true, type: 'boolean' },
    actions: { name: 'Akce', headerStyle: { width: '80px' }, columnFormatterType: ColumnFormatterTypes.actionsObject }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    type: x => x.type,
    uiOrder: x => x.uiOrder,
    name: x => x.name,
    tooltip: x => x.tooltip,
    cubicToTonnes: x => x.cubicToTonnes,
    isForPublic: x => x.isForPublic,
    actions: x => {
      const ret: IRowAction[] = []
      ret.push({
        type: RowActionType.codeAction,
        value: 'deleteConcreteComponent',
        icon: 'fe-x',
        title: 'Odstranit kopmnentu betonu'
      })
      return ret
    }
  }
}
