import { FormProvider, useForm } from 'react-hook-form'
import { useSuggestZpoDataQuery } from './query/suggestZpoDataQuery'
import { useUpdateZpoDetailMutation } from './mutation/updateZpo'
import { useGetZpoDetailQuery } from './query/getZpoDetailQuery'
import React, { useState } from 'react'
import { isAnyQueryLoading } from '../../../../services/functions/queryHelpers'
import FormField, { inputType } from '../../../../components/formComponents/FormField'
import SelectField from '../../../../components/formComponents/SelectField'
import WasteTypesSelector from '../../../../components/dataComponents/WasteTypesSelector/WasteTypesSelector'
import { useListZujQuery } from './query/listZujQuery'
import { useListOrpQuery } from './query/listOrpQuery'
import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import LoadingContainer from '../../../../components/LoadingContainer'
import { toast } from 'react-toastify'
import { useGetWasteOriginDescSuggestions } from './query/getWasteOriginDescSuggestionsQuery'
import DatePickerComp from '../../../../components/formComponents/datePickerComp'

export default function ZpoEditor({ orderContentId, onFinish }: { orderContentId: number; onFinish: () => void }) {
  const [isLoading, setIsLoading] = useState(false)
  const getZpoDetailQR = useGetZpoDetailQuery(orderContentId, loadDefaults)
  const formMethods = useForm({
    defaultValues: {
      wasteTypeId: 0,
      zujId: '',
      orpId: '',
      wasteOriginDesc: '',
      notificationEmails: '',
      zpoCreatedDate: '',
      wasteCompositionDesc: ''
    }
  })
  const [updateZpoDetailMutation, updateZpoDetailQR] = useUpdateZpoDetailMutation(orderContentId)
  const suggestZpoDataQR = useSuggestZpoDataQuery()
  const listZujQR = useListZujQuery()
  const listOrpQR = useListOrpQuery()
  const wasteOriginSuggestionsQR = useGetWasteOriginDescSuggestions()

  const zujOptions = listZujQR.data?.listZuj.map(x => ({ label: `${x.name} (${x.zip})`, value: x.id })) ?? []
  const orpOptions = listOrpQR.data?.listOrp.map(x => ({ label: x.name, value: x.id })) ?? []
  const wasteOrigins = wasteOriginSuggestionsQR.data?.getWasteOriginDescSuggestions.map(x => ({ label: x, value: x })) ?? []
  return (
    <LoadingContainer
      showLoader={isLoading || isAnyQueryLoading(updateZpoDetailQR, suggestZpoDataQR, getZpoDetailQR, listZujQR, listOrpQR, wasteOriginSuggestionsQR)}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
          <fieldset className="form-fieldset">
            <div className="row mb-2">
              <div className="col-md-12 mb-2">
                <DatePickerComp name={'zpoCreatedDate'} label={'Datum vytvoření ZPO'} registerOptions={{ required: false }} dateAsString />
              </div>
              <div className="col-md-12 mb-2">
                <WasteTypesSelector />
              </div>
              <div className="col-md-12 mb-2">
                <SelectField
                  isCreatable={true}
                  optionsData={wasteOrigins}
                  name={'wasteOriginDesc'}
                  label={'Popis vzniku odpadu'}
                  registerOptions={{ required: false }}
                />
              </div>
              <div className="col-md-12 mb-2">
                <FormField
                  type={inputType.text}
                  name={'wasteCompositionDesc'}
                  label={'Údaje o složení odpadu'}
                  registerOptions={{ required: false }}
                  maxLength={512}
                />
              </div>
              <div className="col-md-6 mb-2">
                <SelectField optionsData={zujOptions} name={'zujId'} label={'ZUJ'} registerOptions={{ required: false }} handleChange={setOrpOnChange} />
              </div>
              <div className="col-md-6 mb-2">
                <SelectField optionsData={orpOptions} name={'orpId'} label={'ORP'} registerOptions={{ required: false }} />
              </div>
              <div className="col-md-12 mb-2">
                <FormField
                  type={inputType.text}
                  name={'notificationEmails'}
                  label={'Emaily pro zaslání ZPO na:'}
                  registerOptions={{ required: false }}
                  maxLength={150}
                />
              </div>
            </div>
          </fieldset>
          <div className="d-flex justify-content-md-center">
            <button type="submit" className="btn btn-primary ms-auto btn-lg mr-1">
              Uložit změny
            </button>
            <button type="button" className="btn btn-secondary ms-auto btn-lg mr-1" onClick={handleDataSuggest}>
              Navrhnout hodnoty
            </button>
            <button type="button" className="btn btn-secondary ms-auto btn-lg" onClick={onFinish}>
              Zpět
            </button>
          </div>
        </form>
      </FormProvider>
    </LoadingContainer>
  )

  function setOrpOnChange(value: string) {
    const orp = listZujQR.data?.listZuj.find(x => x.id === value)?.orp?.id
    if (orp) {
      formMethods.setValue('orpId', orp)
    }
  }
  function handleFormSubmit(data: any) {
    const promise = updateZpoDetailMutation({
      variables: {
        orderContentId: orderContentId,
        data: {
          wasteTypeId: data.wasteTypeId,
          listZujId: data.zujId,
          listOrpId: data.orpId,
          wasteOriginDesc: data.wasteOriginDesc,
          notificationEmails: data.notificationEmails,
          wasteCompositionDesc: data.wasteCompositionDesc,
          zpoCreatedDate: data.zpoCreatedDate
        }
      }
    })
    processPageOperation({
      promise: promise,
      successMessage: 'ZPO bylo úspěšně uloženo.',
      successAction: result => {
        onFinish()
      }
    })
  }
  async function handleDataSuggest() {
    setIsLoading(true)
    const suggestedData = await suggestZpoDataQR.refetch({ orderContentId: orderContentId })
    formMethods.reset({
      wasteTypeId: suggestedData.data.suggestZpoData.wasteTypeId ?? undefined,
      zujId: suggestedData.data.suggestZpoData.listZujId ?? undefined,
      orpId: suggestedData.data.suggestZpoData.listOrpId ?? undefined,
      wasteOriginDesc: suggestedData.data.suggestZpoData.wasteOriginDesc ?? '',
      notificationEmails: suggestedData.data.suggestZpoData.notificationEmails ?? '',
      zpoCreatedDate: suggestedData.data.suggestZpoData.zpoCreatedDate ?? '',
      wasteCompositionDesc: suggestedData.data.suggestZpoData.wasteCompositionDesc ?? ''
    })
    setIsLoading(false)
    toast.success('Navržené hodnoty jsou vyplněny ve formuláři')
  }
  async function loadDefaults() {
    formMethods.reset({
      wasteTypeId: getZpoDetailQR.data?.getZpoDetail?.wasteType?.id,
      zujId: getZpoDetailQR.data?.getZpoDetail?.zuj?.id,
      orpId: getZpoDetailQR.data?.getZpoDetail?.orp?.id,
      wasteOriginDesc: getZpoDetailQR.data?.getZpoDetail?.wasteOriginDesc ?? '',
      notificationEmails: getZpoDetailQR.data?.getZpoDetail?.notificationEmails ?? '',
      zpoCreatedDate: getZpoDetailQR.data?.getZpoDetail?.zpoCreatedDate ?? '',
      wasteCompositionDesc: getZpoDetailQR.data?.getZpoDetail?.wasteCompositionDesc ?? ''
    })
  }
}
