export type PaymentStatus = 'UNPAID' | 'PAID' | 'REFUNDED'

export type GoPayPaymentState = 'CREATED' | 'PAYMENT_METHOD_CHOSEN' | 'PAID' | 'AUTHORIZED' | 'CANCELED' | 'TIMEOUTED' | 'REFUNDED' | 'PARTIALLY_REFUNDED'

export const paymentStatusMap = {
  UNPAID: 'Čeká na platbu',
  PAID: 'Zaplaceno',
  REFUNDED: 'Vráceno'
}

export const paymentStatusMapInclGP = {
  ...paymentStatusMap,
  CREATED: 'GP Vytvořena',
  TIMEOUTED: 'GP Vypršela',
  PAYMENT_METHOD_CHOSEN: 'GP Převodem',
  CANCELED: 'GP Zrušeno',
  AUTHORIZED: 'GP Autorizováno'
}

export const paymentStatusOptions = Object.keys(paymentStatusMap).map(key => ({
  label: paymentStatusMap[key],
  value: key as PaymentStatus
}))
export const paymentStateBadgeColors = {
  'GP Vytvořena': 'bg-info',
  'GP Vypršela': 'bg-green-leaves',
  'GP Převodem': 'bg-secondary',
  Zaplaceno: 'bg-success',
  'GP Zrušeno': 'bg-dark',
  Vráceno: 'bg-grey',
  'Čeká na platbu': 'bg-cyan'
}
