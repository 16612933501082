import { gql, useQuery } from '@apollo/client'
import { DemandChangeLogEntity, EmailHistory } from '../../../../../graphql/aminTypes'
import { contextEndpoint, EndpointEnum } from '../../../../../services/appConfiguration/contextEndpoint'

export const orderChangeLogQuery = gql`
  query orderChangeLog($orderContentId: Int!) {
    orderChangeLog(orderContentId: $orderContentId) {
      id
      createdAt
      login {
        id
        firstname
        lastname
      }
      operation
      variables
      result
      source
    }
  }
`

export function useOrderChangeLogQuery(orderContentId?: number | undefined) {
  return useQuery<{ orderChangeLog: DemandChangeLogEntity[] }>(orderChangeLogQuery, {
    context: contextEndpoint(EndpointEnum.admin),
    variables: { orderContentId },
    skip: !orderContentId,
    fetchPolicy: 'network-only'
  })
}
