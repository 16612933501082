import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../services/types/gqlTypes'

const updateSupplierFlatRateMutation = gql`
  mutation updateSupplierFlatRate($flatRateId: Int!, $flatPriceKc: Float!) {
    updateSupplierFlatRate(flatRateId: $flatRateId, flatPriceKc: $flatPriceKc) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpdateSupplierFlatRateMutation() {
  return useMutation<{ updateSupplierFlatRate: IMutationResult }>(updateSupplierFlatRateMutation)
}

export function createParamsForUpdateSupplierFlatRateMutation(flatRateId: number, flatRate: Number) {
  return { flatRateId: flatRateId, flatPriceKc: flatRate }
}
