import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const deleteProductWasteMutation = gql`
  mutation deleteProductWaste($id: ID!) {
    deleteProductWaste(id: $id) {
      status {
        reason
        result
      }
    }
  }
`

export function useDeleteProductWasteMutation() {
  return useMutation<{ deleteProductWaste: IMutationResult }>(deleteProductWasteMutation, { refetchQueries: ['productWasteList'] })
}
