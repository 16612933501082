import { SelectedSupplierModalContext } from '../SelectedSupplierModal/SelectedSupplierModal'
import React, { useContext } from 'react'
import PageCard from '../../components/Cards/pageCard'
import SupplierServiceEnabler from '../common/components/SupplierServiceEnabler/SupplierServiceEnabler'
import { SettingsNode } from './components/SettingsNode'
import { serviceCategoryTranslation } from '../../services/types/service-category.type'
import { WasteLinkComponent } from '../common/WasteLinkComponent/WasteLinkComponent'

const currentServiceCategory = 'WASTE_COLLECTION_ADR'
export default function SupplierServiceWasteCollectionAdr() {
  const { state } = useContext(SelectedSupplierModalContext)
  const currentServiceSetting = state.selectedBranch?.branchServices?.find(x => x.serviceCategory === currentServiceCategory)
  return (
    <PageCard showLoader={false} headerTitle={serviceCategoryTranslation[currentServiceCategory]}>
      <SupplierServiceEnabler currentServiceSetting={currentServiceSetting} serviceCategory={currentServiceCategory} settingsNode={<SettingsNode />} />
      {currentServiceSetting && (
        <>
          <h2>Odvoz na koncovky</h2>
          <WasteLinkComponent branchServiceId={currentServiceSetting?.id} wasteCategory={'HAZARDOUS_WASTE'} />
        </>
      )}
    </PageCard>
  )
}
