export const containerTimesArrive = [
  { label: '07:00-09:00', value: '07:00-09:00' },
  { label: '08:00-09:00', value: '08:00-09:00' },
  { label: '08:00-10:00', value: '08:00-10:00' },
  { label: '09:00-11:00', value: '09:00-11:00' },
  { label: '10:00-12:00', value: '10:00-12:00' },
  { label: '11:00-13:00', value: '11:00-13:00' },
  { label: '12:00-14:00', value: '12:00-14:00' },
  { label: '13:00-15:00', value: '13:00-15:00' },
  { label: '14:00-16:00', value: '14:00-16:00' },
  { label: '15:00-17:00', value: '15:00-17:00' },
  { label: 'Ráno', value: 'Ráno' },
  { label: 'Dopoledne', value: 'Dopoledne' },
  { label: 'Odpoledne', value: 'Odpoledne' },
  { label: 'Během dne', value: 'Během dne' },
  { label: 'Co nejdříve', value: 'Co nejdříve' }
]

export const containerTimesLeave = [
  { label: '07:00-10:00', value: '07:00-10:00' },
  { label: '09:00-12:00', value: '09:00-12:00' },
  { label: '11:00-14:00', value: '11:00-14:00' },
  { label: '13:00-16:00', value: '13:00-16:00' },
  { label: '15:00-18:00', value: '15:00-18:00' },
  { label: 'Ráno', value: 'Ráno' },
  { label: 'Dopoledne', value: 'Dopoledne' },
  { label: 'Během dne', value: 'Během dne' },
  { label: 'Co nejdříve', value: 'Co nejdříve' },
  { label: 'Termín vyzvednutí bude upřesněn', value: 'Termín vyzvednutí bude upřesněn' }
]
