import {gql, useMutation} from '@apollo/client'
import {IMutationResult} from '../../../../../services/types/gqlTypes'

const createProductMutation = gql`
    mutation createProduct($data: ProductDataInput!, $productType: ProductType!) {
        createProduct(data: $data, productType: $productType) {
            status {
                reason
                result
            }
        }
    }
`

enum ProductType {
    COLLECTION = 'COLLECTION',
    DELIVERY = 'DELIVERY'
}
export function useCreateProductMutation() {
    return useMutation<{ createMutation: IMutationResult }>(createProductMutation)
}

export function createParamsForCreateProductMutation(data: any) {
    return {
        productType: ProductType.COLLECTION,
        data: {
            disabled: !data.isActive,
            margin: +data.margin,
            name: data.name,
            tooltip: data.tooltip,
            uiOrder: +data.uiOrder,
            uiPicture: data.uiPicture,
            wasteTypeId: data.wasteTypeId,
            isForPublic: data.isForPublic
        }
    }
}


export function createParamsForCreateProductForDeliveryMutation(data: any) {
    return {
        productType: ProductType.DELIVERY,
        data: {
            disabled: !data.isActive,
            margin: +data.margin,
            name: data.name,
            tooltip: data.tooltip,
            uiOrder: +data.uiOrder,
            fractionId: +data.fractionId,
            materialId: +data.materialId,
            typeId: +data.materialTypeId,
            isForPublic: data.isForPublic,
            tonToCubes: +data.tonToCubes,
        }
    }
}

