export function convertTextToValue(text: string, type: 'boolean' | 'number' | 'string') {
  switch (type) {
    case 'boolean':
      return text.toLowerCase() === 'true'
    case 'number':
      const num = parseFloat(text)
      if (isNaN(num)) {
        throw new Error(`Cannot convert "${text}" to a number`)
      }
      return num
    case 'string':
      return text
    default:
      throw new Error(`Unsupported type: ${type}`)
  }
}
