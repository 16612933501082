import { gql, useQuery } from '@apollo/client'
import { OrderLocksEntity } from '../../../graphql/aminTypes'

export const getOrderLockQuery = gql`
  query getOrderLock($orderId: Int!) {
    getOrderLock(orderId: $orderId) {
      id
      lockedUntil
      lockedByLogin {
        id
        firstname
        lastname
      }
    }
  }
`

export function useGetOrderLockQuery(orderId?: number) {
  return useQuery<{ getOrderLock?: OrderLocksEntity }>(getOrderLockQuery, {
    fetchPolicy: 'network-only',
    skip: !orderId,
    variables: {
      orderId: orderId
    }
  })
}
