import { gql, useQuery } from '@apollo/client'
import { IGqlPagingQueryParams } from '../../../services/bootstrapTable/usePagingFromURLQuery'
import { Customer, CustomerListPaginatedResponse, CustomerResponse } from '../../../graphql/aminTypes'

export const customerListQuery = gql`
  query customerList(
    $offset: Int
    $limit: Int
    $orderBy: String
    $id: ID
    $companyName: String
    $email: String
    $phone: String
    $lastname: String
    $firstname: String
    $isResellCompany: String
    $isActive: String
    $flags: [customerFilterFlagsEnum!]
  ) {
    customerList(
      offset: $offset
      limit: $limit
      orderBy: $orderBy
      id: $id
      companyName: $companyName
      email: $email
      phone: $phone
      lastname: $lastname
      firstname: $firstname
      isResellCompany: $isResellCompany
      isActive: $isActive
      flags: $flags
    ) {
      total
      items {
        id
        loginId
        companyIdentification
        companyTaxId
        companyName
        firstname
        lastname
        email
        phone
        street
        streetNumber
        city
        zip
        allowedPaymentMethods
        primaryPaymentMethod
        limitEndCustomerEmails
        createInvoices
        b2bMarginCorrection
        metrakMarginCorrection
        customerDiscount
        isResellCompany
        totalContainersOrdered
        isActive
        notes
        isPreRegisteredOnly
        requireCertificates
        passwordSetEmailExpiry
        hasFixedPrice
        updatedAt
        advanceAccountsSetup {
          isUsingAdvanceAccounts
        }
      }
    }
  }
`

export function useCustomerListQuery(params: IGqlPagingQueryParams, onCompleted?: any) {
  return useQuery<{ customerList: CustomerListPaginatedResponse }>(customerListQuery, {
    fetchPolicy: 'network-only',
    variables: params,
    onCompleted
  })
}
