import classNames from 'classnames'
import React, { useContext } from 'react'
import { useSupplierContainerRentRatesQuery } from './queries/supplier-container-rent-rates.query'
import LoadingContainer from '../../../components/LoadingContainer'
import { MuiScopedContainer } from '../../../components/mui-react-table/MuiScopedContainer'
import { MaterialReactTable, MRT_ActionMenuItem, MRT_Row } from 'material-react-table'
import { containerRentRatesTableDefinition } from './services/container-rent-rates-table-definition'
import { useDeleteContainerRentRateMutation } from './mutations/delete-container-rent-rate.mutation'
import { processPageOperation } from '../../../services/formsServices/pageOperationProcessor'
import { Delete, Edit } from '@mui/icons-material'
import { SupplierContainerRentRatesEntity } from '../../../graphql/aminTypes'
import { ModalContext } from '../../../components/Modal/ModalProvider'
import { ContainerRentRateEditor } from './ContainerRentRateEditor'
import { Box, Button } from '@mui/material'
import { isAnyQueryLoading } from '../../../services/functions/queryHelpers'

export default function SupplierContainerRentRates({ supplierBranchId }: { supplierBranchId?: string }) {
  const [deleteContainerRentRateMut, deleteContainerRentRateMR] = useDeleteContainerRentRateMutation()
  const rentRatesQR = useSupplierContainerRentRatesQuery(supplierBranchId)
  const { showModal, hideModal } = useContext(ModalContext)
  return (
    <fieldset className={classNames('form-fieldset')}>
      <h2>Cenové sazby za pronájem kontejnerů</h2>
      <LoadingContainer showLoader={isAnyQueryLoading(rentRatesQR, deleteContainerRentRateMR)}>
        <MuiScopedContainer>
          <MaterialReactTable
            columns={containerRentRatesTableDefinition}
            enableTopToolbar={true}
            renderTopToolbarCustomActions={({ table }) => (
              <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
                <Button color="secondary" onClick={() => createContainerRentHandler()} variant="contained">
                  Přidat novou sazbu
                </Button>
              </Box>
            )}
            enableBottomToolbar={false}
            enableRowActions={true}
            positionActionsColumn={'last'}
            data={rentRatesQR.data?.supplierContainerRentRates ?? []}
            getRowId={row => row.id}
            enablePagination={false}
            initialState={{
              density: 'compact'
            }}
            renderRowActionMenuItems={({ row, table }) => [
              <MRT_ActionMenuItem //or just use a normal MUI MenuItem component
                icon={<Edit />}
                key="edit"
                label="Edit"
                onClick={() => editContainerRentHandler(row)}
                table={table}
              />,
              <MRT_ActionMenuItem icon={<Delete />} key="delete" label="Delete" onClick={() => deleteContainerRentRateHandler(row)} table={table} />
            ]}
          />
        </MuiScopedContainer>
      </LoadingContainer>
    </fieldset>
  )

  function deleteContainerRentRateHandler(row: MRT_Row<SupplierContainerRentRatesEntity>) {
    const promise = deleteContainerRentRateMut({ variables: { id: row.id } })
    processPageOperation({
      promise,
      successMessage: 'Sazba byla úspěšně smazána'
    })
  }

  function editContainerRentHandler(row: MRT_Row<SupplierContainerRentRatesEntity>) {
    showModal({
      onClose: hideModal,
      hideFooter: true,
      title: 'Editace sazby za pronájem kontejneru',
      modalContent: <ContainerRentRateEditor row={row.original} branchId={supplierBranchId} hideModal={hideModal} />
    })
  }
  function createContainerRentHandler() {
    showModal({
      onClose: hideModal,
      hideFooter: true,
      title: 'Přidání sazby za pronájem kontejneru',
      modalContent: <ContainerRentRateEditor branchId={supplierBranchId} hideModal={hideModal} />
    })
  }
}
