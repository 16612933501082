import { useOrderListQuery } from './queries/order-content-list.query'
import PageCard from '../../components/Cards/pageCard'
import React, { useContext, useRef, useState } from 'react'
import useBootstrapTableDataMapper from '../../services/bootstrapTable/useBootstrapTableDataMapper'
import { orderListDataDefinition } from './services/orderListDataDefinition'
import { isAnyQueryLoading } from '../../services/functions/queryHelpers'
import BootstrapTable from 'react-bootstrap-table-next'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import usePagingFromURLQuery from '../../services/bootstrapTable/usePagingFromURLQuery'
import useBootstrapTablePagingAndFiltration from '../../services/bootstrapTable/useBootstrapTablePagingAndFiltration'
import { useFilterOptions4CustomSelectCreator } from '../../services/bootstrapTable/useFilterOptions4CustomSelectCreator'
// @ts-ignore
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import { useOrderContentStatesQuery } from './queries/order-content-states.query'
import { selectRow } from './services/orderListSettings'
import StatsButtonRow from './components/StatsButtonRow'
import { createParamsForPerformStateActionMutation, performStateActionEnum, usePerformStateActionMutation } from './mutations/perform-state-action.mutation'
import { useOrderContentStatesStatQuery } from './queries/order-content-states-stat.query'
import { ModalContext } from '../../components/Modal/ModalProvider'
import { FinishOrder } from './components/FinishOrder'
import { BootstrapTablePagingWrapper } from '../../components/bootstrapTable/BootstrapTablePagingWrapper'
import { OrderContentsResult } from '../../graphql/supplierTypes'
import { serviceTypeSelectOptions } from '../../services/types/service-type'

export default function SupplierOrderList() {
  const { showModal, hideModal } = useContext(ModalContext)
  const currentRowChange = useRef<number | undefined>(undefined)
  const { generateColumnListForBootstrapTable, mapDataArrayToTable, nameForGqlTranslation, defaultSort } =
    useBootstrapTableDataMapper<OrderContentsResult>(orderListDataDefinition)
  const { pageConfiguration, gqlQueryParams } = usePagingFromURLQuery(
    ['orderNumber', 'state', 'containerFromDate', 'containerToDate', 'serviceType', 'city'],
    nameForGqlTranslation,
    { defaultSort, defaultPageSize: 50 }
  )
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set())

  const orderContentStatesQR = useOrderContentStatesQuery()
  const orderContentStatesStatQR = useOrderContentStatesStatQuery()

  const orderListQR = useOrderListQuery(gqlQueryParams)
  const [performStateActionMut, performStateActionQR] = usePerformStateActionMutation()

  const { bootstrapTablePaginationSetup, handleTableChange } = useBootstrapTablePagingAndFiltration(
    pageConfiguration,
    undefined,
    undefined,
    orderListQR.data?.orderContentList.total
  )

  const filterOptions = useFilterOptions4CustomSelectCreator()
  if (orderContentStatesQR.data?.orderContentStates && !filterOptions.getFilterOption('state'))
    filterOptions.addFilterOption(
      'state',
      orderContentStatesQR.data.orderContentStates.map(x => ({ key: x.sysName, value: x.nameForSupplier })),
      (item: any) => item.key,
      (item: any) => item.value
    )
  filterOptions.addFilterOption(
    'serviceType',
    serviceTypeSelectOptions,
    (item: any) => item.value,
    (item: any) => item.label
  )

  const columnDefinition = generateColumnListForBootstrapTable({
    columnAction: handleTableClick,
    filterDefaultValues: pageConfiguration.filterBy,
    currentSort: pageConfiguration.sort,
    handleTableChange: handleTableChange,
    filterOptions4CustomSelectCreator: filterOptions,
    actionsReference: {
      finishOrder: finishOrderHandler,
      materialDelivered: row =>
        performActionHandler(
          [row.id],
          `Označit dovezení materiálu u zakázky ${row.orderNumber}?`,
          `Materiál byl dovezen u zakázky ${row.orderNumber}.`,
          performStateActionEnum.MATERIAL_DELIVERED
        ),
      containerArrived: row =>
        performActionHandler(
          [row.id],
          `Označit přistavení kontejneru zakázky ${row.orderNumber}?`,
          `Kontejner byl přistaven u zakázky ${row.orderNumber}.`,
          performStateActionEnum.CONTAINER_ARRIVE
        ),
      containerTakenBack: row =>
        performActionHandler(
          [row.id],
          `Označit odvezení kontejneru zakázky ${row.orderNumber}?`,
          `Kontejner byl odvezen u zakázky ${row.orderNumber}.`,
          performStateActionEnum.CONTAINER_TAKE
        ),
      confirmBySupplier: row =>
        performActionHandler(
          [row.id],
          `Opravdu závazně přijmout poptávku ${row.orderNumber}?`,
          `Poptávku ${row.orderNumber} vám byla přiřazena.`,
          performStateActionEnum.CONFIRM
        ),
      cancelOrder: cancelOrderHandler
    }
  })

  let ordersData: any[] = []
  if (orderListQR.data?.orderContentList.items) ordersData = mapDataArrayToTable(orderListQR.data.orderContentList.items)
  return (
    <PageCard showLoader={isAnyQueryLoading(orderListQR, performStateActionQR)} headerTitle={'Seznam zakázek'}>
      <StatsButtonRow orderContentStatesStat={orderContentStatesStatQR.data?.orderContentStatesStat ?? []} setSelectedRows={setSelectedRows} />
      <PaginationProvider pagination={paginationFactory(bootstrapTablePaginationSetup)}>
        {({ paginationProps, paginationTableProps }: { paginationProps: any; paginationTableProps: any }) => (
          <div className="table-responsive-xl">
            <BootstrapTablePagingWrapper paginationProps={paginationProps} paginationTableProps={paginationTableProps}>
              <BootstrapTable
                bootstrap4
                striped
                hover
                condensed
                remote
                classes="table-responsive-lg digiTable"
                noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné objednávky"</>}
                keyField="id"
                selectRow={selectRow(handleOnSelect, handleOnSelectAll, Array.from(selectedRows))}
                data={ordersData}
                columns={columnDefinition}
                filter={filterFactory()}
                filterPosition={'inline'}
                sort={pageConfiguration.sort}
                onTableChange={handleTableChangeWrapper}
                {...paginationTableProps}
              />
            </BootstrapTablePagingWrapper>
          </div>
        )}
      </PaginationProvider>

      <div className="row mt-3">
        <div className="col-sm-2 d-flex justify-content-md-center">
          <button
            type="button"
            disabled={selectedRows.size === 0}
            className="btn bg-lime btn-block"
            onClick={() =>
              performActionHandler(
                Array.from(selectedRows),
                `Závazně přijmout ${selectedRows.size} poptávku/ky?`,
                'Vybrané poptávky přijaty',
                performStateActionEnum.CONFIRM
              )
            }
          >
            <i className="fe fe-check p-1" />
            Závazně přijmout poptávku
          </button>
        </div>
        <div className="col-sm-2 d-flex justify-content-md-center">
          <button
            type="button"
            disabled={selectedRows.size === 0}
            className="btn bg-danger btn-block text-white"
            onClick={() =>
              performActionHandler(
                Array.from(selectedRows),
                `Označit přistavení kontejneru u ${selectedRows.size} objednávek?`,
                'U vybraných objednávek označeno přistavení kontejneru',
                performStateActionEnum.CONTAINER_ARRIVE
              )
            }
          >
            <i className="fe fe-download p-1" /> Kontejner byl přistaven
          </button>
        </div>
        <div className="col-sm-2 d-flex justify-content-md-center">
          <button
            type="button"
            disabled={selectedRows.size === 0}
            className="btn btn-block bg-cyan text-white"
            onClick={() =>
              performActionHandler(
                Array.from(selectedRows),
                `Označit odvezení kontejneru u ${selectedRows.size} objednávek?`,
                'U vybraných objednávek označeno odvezení kontejneru',
                performStateActionEnum.CONTAINER_TAKE
              )
            }
          >
            <i className="fe fe-upload p-1" />
            Kontejner byl odvezen
          </button>
        </div>
        <div className="col-sm-2 d-flex justify-content-md-center">
          <button
            type="button"
            disabled={selectedRows.size === 0}
            className="btn btn-block bg-maroon text-white"
            onClick={() =>
              performActionHandler(
                Array.from(selectedRows),
                `Označit navezení materiálu u ${selectedRows.size} objednávek?`,
                'U vybraných objednávek označeno navezení materiálu',
                performStateActionEnum.MATERIAL_DELIVERED
              )
            }
          >
            <i className="fe fe-fast-forward p-1" />
            Materiál byl dovezen
          </button>
        </div>
        {/*
        <div className="col-sm-2 d-flex justify-content-md-center">
          <button
            type="button"
            disabled={selectedRows.size === 0}
            className="btn btn-block bg-indigo text-white"
            onClick={() =>
              performActionHandler(
                Array.from(selectedRows),
                `Dokončit ${selectedRows.size} objednávek?`,
                'Vybrané objednávky dokončeny',
                performStateActionEnum.ORDER_FINISHED_BY_SUPPLIER
              )
            }
          >
            <i className="fe fe-zap p-1" />
            Zakázka dokončena
          </button>
        </div>
*/}
      </div>
    </PageCard>
  )

  function handleTableChangeWrapper(type: TableChangeType, { filters, page, sizePerPage, sortField, sortOrder }: TableChangeNewState) {
    setSelectedRows(new Set())
    handleTableChange(type, { filters, page, sizePerPage, sortOrder, sortField })
  }

  function handleTableClick(e: Event, column: number, columnIndex: number, row: any) {
    if (columnIndex === 1 || columnIndex === 11 || columnIndex === 14) {
      currentRowChange.current = row.id
    }
  }

  function cancelOrderHandler(row: any) {
    if (!row.id) return
    var cancelReason = prompt('Napište nám prosím důvod zrušení zakázky')
    if (cancelReason === null) return

    performActionHandler([row.id], undefined, `Objednávka ${row.orderNumber} byla zrušena.`, performStateActionEnum.CANCEL_BY_SUPPLIER, cancelReason)
  }

  function performActionHandler(id: number[], confirmMessage: string | undefined, successMessage: string, action: performStateActionEnum, reason?: string) {
    if (id.length <= 0) return
    if (!confirmMessage || window.confirm(confirmMessage)) {
      const promise = performStateActionMut({ variables: createParamsForPerformStateActionMutation(id, action, reason) })
      processPageOperation({
        promise: promise,
        successMessage,
        failedAction: result => {
          Promise.all([orderListQR.refetch(), orderContentStatesStatQR.refetch()]).then(() => setSelectedRows(new Set()))
        },
        successAction: result => {
          Promise.all([orderListQR.refetch(), orderContentStatesStatQR.refetch()]).then(() => setSelectedRows(new Set()))
          Promise.all([orderListQR.refetch(), orderContentStatesStatQR.refetch()]).then(() => setSelectedRows(new Set()))
        }
      })
    }
  }

  function handleOnSelect(row: any, isSelect: boolean) {
    if (currentRowChange.current === row.id) {
      currentRowChange.current = undefined
      return false
    }

    if (isSelect) setSelectedRows(new Set(selectedRows).add(row.id))
    else {
      selectedRows.delete(row.id)
      setSelectedRows(new Set(selectedRows))
    }
    return true
  }

  function handleOnSelectAll(isSelect: boolean, rows: any) {
    if (isSelect) rows.forEach((r: any) => selectedRows.add(r.id))
    else rows.forEach((r: any) => selectedRows.delete(r.id))
    setSelectedRows(new Set(selectedRows))
    return true
  }

  function orderFinishedBySupplier(orderContentId: number, orderNumber: number) {
    return () => {
      hideModal()
      performActionHandler([orderContentId], undefined, 'Objednávka ' + orderNumber + ' dokončena', performStateActionEnum.ORDER_FINISHED_BY_SUPPLIER)
    }
  }

  function finishOrderHandler(row: any) {
    if (row.serviceTypeData === 'MATERIAL_DELIVERY') {
      performActionHandler(
        [row.id],
        'Dokočit zakázku návozu materiálu ' + row.orderNumber + '?',
        'Objednávka ' + row.orderNumber + ' dokončena',
        performStateActionEnum.ORDER_FINISHED_BY_SUPPLIER
      )
      return
    }
    showModal({
      hideFooter: true,
      title: 'Dokončit zakázku ' + row.orderNumber,
      modalContent: <FinishOrder orderContentId={row.id} onCancel={hideModal} onFinish={orderFinishedBySupplier(row.id, row.orderNumber)} />
    })
  }
}
