import {
  ColumnAlign,
  ColumnFilterTypes,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../services/bootstrapTable/services/tableSpecificationTypes'
import React from 'react'
import { ProductMaterialEntity } from '../../../graphql/aminTypes'

export const productMaterialDataDefinition: TableDefinitionTypes<ProductMaterialEntity> = {
  columnDefinition: {
    id: { hidden: true },
    data: { hidden: true },
    isActive: {
      name: 'Aktivní',
      columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
      align: ColumnAlign.center,
      type: 'boolean',
      headerStyle: { width: '100px' }
    },
    isForPublic: { name: 'Pro veřejnost', columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter, headerStyle: { width: '100px' } },
    uiOrder: { name: 'Pořadí zobrazení', headerStyle: { width: '100px' } },
    name: { name: 'Jméno', filterType: ColumnFilterTypes.customText },
    material: {
      name: 'Materiál'
    },
    type: {
      name: 'Typ'
    },
    fraction: {
      name: 'Frakce'
    },
    cubicToTonnes: {
      name: '1 kubík na tuny'
    },
    uiPicture: {
      name: 'Obrázek'
    },
    tooltip: {
      name: 'Tooltip'
    },
    note: {
      name: 'Poznámka'
    },
    actions: {
      name: 'Akce',
      columnFormatterType: ColumnFormatterTypes.actionsObject,
      align: ColumnAlign.left,
      headerStyle: { width: '70px' }
    }
  },
  sourceToColumnMapping: {
    data: x => x,
    id: x => x.id,
    isForPublic: x => x.isForPublic,
    uiOrder: x => x.uiOrder,
    isActive: x => x.isActive,
    name: x => x.name,
    material: x => x.materialComponent.name,
    type: x => x.typeComponent.name,
    fraction: x => x.fractionComponent?.name,
    uiPicture: x =>
      x.uiPicture ? (
        <img src={`https://${process.env.REACT_APP_FE_HOSTNAME}/assets/images/${x.uiPicture}`} width="80" title={x.name} alt={x.name} />
      ) : undefined,
    tooltip: x => x.tooltip,
    note: x => x.note,
    cubicToTonnes: x => x.cubicToTonnes,
    actions: x => {
      const ret: IRowAction[] = []
      ret.push({
        type: RowActionType.codeAction,
        value: 'deleteProductMaterial',
        icon: 'fe-x',
        title: 'Odstranit materiál'
      })
      return ret
    }
  }
}
