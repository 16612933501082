import React, { useContext, useEffect, useRef } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { SelectedSupplierModalContext } from '../../../SelectedSupplierModal/SelectedSupplierModal'
import SelectField from '../../../../components/formComponents/SelectField'
import ToggleField from '../../../../components/formComponents/ToggleField'
import FormField, { inputType } from '../../../../components/formComponents/FormField'

import LoadingContainer from '../../../../components/LoadingContainer'

import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import { SupplierTerminalPriceListEntity, WasteCategory } from '../../../../graphql/aminTypes'
import { useProductWasteListFlatQuery } from '../../../common/components/ProductWasteSelector/queries/product-waste-list.query'
import { isAnyQueryLoading } from '../../../../services/functions/queryHelpers'
import { wasteCategoryTypeOptions } from '../../../../services/types/waste-category'
import { useUpsertSupplierTerminalPriceListMutation } from './mutations/upsert-supplier-terminal-price-list.mutation'

export default function WasteTerminalPriceListEditor({
  branchServiceId,
  onClose,
  editItem,
  doNotShowWastes
}: {
  editItem?: SupplierTerminalPriceListEntity
  branchServiceId?: string
  onClose: () => void
  doNotShowWastes: string[]
}) {
  const wasteFieldRef: React.RefObject<HTMLInputElement | null | undefined> = useRef()
  const [upsertSupplierTerminalPriceListMut, upsertSupplierTerminalPriceListQR] = useUpsertSupplierTerminalPriceListMutation()

  const formMethods = useForm<SupplierTerminalPriceListEntity>({
    defaultValues: {
      isActive: true,
      wasteCategory: WasteCategory.SolidWaste,
      priceKc: 0,
      productWasteId: undefined,
      isAcceptedAsTzs: false
    }
  })
  const productWasteListFlatQR = useProductWasteListFlatQuery(formMethods.watch('wasteCategory'), true)

  const availableWastes =
    productWasteListFlatQR.data?.productWasteListFlat
      .filter(x => !doNotShowWastes.includes(x.id))
      .map(x => ({
        value: x.id,
        disabled: !!x.mainWasteForCode1,
        label: `${x.hasAdditivesSpecification ? '* ' : ''}${x.parentWasteId ? '- ' : ''}${x.name} (${x.wasteType?.codeMerged ?? '-'})${
          x.childWastes && x.childWastes.length > 0 ? `(${x.childWastes.length})` : ''
        }`
      })) || []

  useEffect(() => {
    if (editItem && productWasteListFlatQR.data) {
      formMethods.reset({
        isActive: editItem.isActive,
        wasteCategory: editItem.wasteCategory,
        priceKc: editItem.priceKc,
        isAcceptedAsTzs: editItem.isAcceptedAsTzs,
        productWasteId: editItem.productWaste.id
      })
    }
  }, [editItem, productWasteListFlatQR.data])

  useEffect(() => {}, [formMethods.watch])

  return (
    <LoadingContainer showLoader={isAnyQueryLoading(upsertSupplierTerminalPriceListQR, productWasteListFlatQR)}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(updateTerminalWastePriceList)} autoComplete={'off'}>
          <div className="row mb-2">
            <div className="col-md-2">
              <ToggleField name={'isActive'} label={'Aktivní'} />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-3">
              <SelectField optionsData={wasteCategoryTypeOptions} name={'wasteCategory'} label={'Kategorie odpadu'} />
            </div>
            <div className="col-md-9">
              <SelectField name={'productWasteId'} label={'Vybraný odpad'} optionsData={availableWastes} disabled={!!editItem} forwardRef={wasteFieldRef} />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-4">
              <FormField type={inputType.number} name={'priceKc'} label={'Cena za likvidaci 1t pro Metrák.cz'} valueAsNumber />
            </div>
            <div className="col-md-4 p-3">
              <ToggleField name={'isAcceptedAsTzs'} label={'Je akceptován jako TZS'} />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-2 offset-6">
              <button type="button" className="btn btn-secondary btn-block" onClick={() => onClose()}>
                Zavřít bez uložení
              </button>
            </div>
            <div className="col-md-2">
              <button type="button" className="btn btn-primary btn-block" onClick={() => upsertAndClose()}>
                Uložit a zavřít
              </button>
            </div>
            <div className="col-md-2">
              {!editItem && (
                <button type="submit" className="btn btn-primary btn-block">
                  Uložit a přidat další
                </button>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
    </LoadingContainer>
  )

  function upsertAndClose() {
    updateTerminalWastePriceListHandler(formMethods.getValues(), onClose)
  }
  function updateTerminalWastePriceList(data: any) {
    updateTerminalWastePriceListHandler(data, () => {
      if (wasteFieldRef.current) wasteFieldRef.current.focus()
    })
  }

  function updateTerminalWastePriceListHandler(data: any, successAction?: () => void) {
    const promise = upsertSupplierTerminalPriceListMut({
      variables: {
        branchServiceId: branchServiceId,
        data,
        terminalPriceListId: editItem?.id
      }
    })
    return processPageOperation({
      promise: promise,
      successMessage: 'Ceník byl uložen',
      successAction: () => {
        doNotShowWastes.push(data.productWasteId)
        formMethods.reset({
          isActive: true,
          priceKc: 0,
          wasteCategory: data.wasteCategory,
          productWasteId: undefined
        })
        if (successAction) successAction()
      }
    })
  }
}
