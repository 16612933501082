import { gql, useQuery } from '@apollo/client'

const containerTypesQuery = gql`
  query containerTypes {
    containerTypes {
      id
      name
      priceFrom
      capacityM3
      disabled
      tooltip
      uiPicture
      offerOnUiAsDefault
      uiOrder
      wasteCapacities {
        id
        fullCapacityTonne
        product {
          id
          name
        }
      }
    }
  }
`

export type ContainerItemType = {
  id: number
  name: string
  priceFrom: number
  capacityM3: number
  disabled: boolean
  tooltip: string
  uiPicture: string
  offerOnUiAsDefault: boolean
  uiOrder: number
  wasteCapacities: WasteCapacitiesType[]
}
export type WasteCapacitiesType = {
  id: number
  fullCapacityTonne: number
  product: {
    id: number
    name: string
  }
}

export function useContainerTypesQuery(onCompleted?: (data) => void) {
  return useQuery<{ containerTypes: ContainerItemType[] }>(containerTypesQuery, {
    fetchPolicy: 'network-only',
    onCompleted
  })
}
