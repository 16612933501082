import {
  ColumnAlign,
  ColumnFilterTypes,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../services/bootstrapTable/services/tableSpecificationTypes'
import { LoginItemType } from './loginsQuery'

export const loginsListDataDefinition: TableDefinitionTypes<LoginItemType> = {
  columnDefinition: {
    id: { hidden: true },
    rawData: { hidden: true },
    isActive: {
      name: 'Aktivován',
      columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
      align: ColumnAlign.center
    },
    firstname: { name: 'Jméno' },
    lastname: { name: 'Příjmení', filterType: ColumnFilterTypes.customText, remoteSort: true },
    email: { name: 'E-mail', filterType: ColumnFilterTypes.customText, remoteSort: true },
    isMasterAdmin: {
      name: 'Administrátor',
      columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
      headerStyle: { width: '150px' },
      align: ColumnAlign.center
    },
    supplier: { name: 'Dodavatelé' },
    actions: {
      name: 'Akce',
      columnFormatterType: ColumnFormatterTypes.actionsObject,
      align: ColumnAlign.right,
      headerStyle: { width: '100px' }
    }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    rawData: x => x,
    isActive: x => x.isActive,
    firstname: x => x.firstname,
    lastname: x => x.lastname,
    email: x => x.email,
    isMasterAdmin: x => x.isMasterAdmin,
    supplier: x => (x.loginToSupplier.length > 0 ? x.loginToSupplier.map(x => x.supplier.name).join(', ') : ''),
    actions: x => {
      const ret: IRowAction[] = []
      if (x.email === 'sysuser') return ret
      if (!x.isActive)
        ret.push({
          type: RowActionType.codeAction,
          value: 'sendPwdRetrievalEmail',
          icon: 'fe-mail',
          title: 'Zaslat uživateli email pro nastavení hesla'
        })
      ret.push({
        type: RowActionType.codeAction,
        value: 'editLogin',
        icon: 'fe-edit',
        title: 'Upravit uživatele'
      })
      ret.push({
        type: RowActionType.codeAction,
        value: 'deleteLogin',
        icon: 'fe-x',
        title: 'Odstranit uživatele'
      })
      return ret
    }
  }
}
