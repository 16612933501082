import { gql, useQuery } from '@apollo/client'
import { ProductContainerCapacityEntity } from '../../../graphql/aminTypes'

export const productContainerCapacityQuery = gql`
  query productContainerCapacity($containerId: ID, $wasteId: ID) {
    productContainerCapacity(containerId: $containerId, wasteId: $wasteId) {
      id
      container {
        id
        name
        volumeM3
      }
      waste {
        id
        name
      }
      capacityTonne
      calcCapacityTonne
      containerId
      wasteId
    }
  }
`

export function useProductContainerCapacityQuery(args: { wasteId?: String; containerId?: String }) {
  return useQuery<{ productContainerCapacity: ProductContainerCapacityEntity[] }>(productContainerCapacityQuery, {
    fetchPolicy: 'network-only',
    variables: args
  })
}
