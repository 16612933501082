import { gql, useQuery } from '@apollo/client'
import { FioSyncLatestResult, FioSyncSettings } from '../../../../../../graphql/aminTypes'

const getFioSyncLatestResultQuery = gql`
  query getFioSyncLatestResult {
    getFioSyncLatestResult {
      foundTransactions
      status
      latestSync
      pairedTransactions
    }
  }
`

export function useGetFioSyncLatestResultQuery() {
  return useQuery<{ getFioSyncLatestResult: FioSyncLatestResult }>(getFioSyncLatestResultQuery, {
    fetchPolicy: 'network-only'
  })
}
