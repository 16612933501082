import React from 'react'

interface PageInfo {
  active: boolean
  page: string
  disabled: boolean
  title: string
}

// shows always all buttons << | < | P-1 | P | P+1 | > | >>
export function pageListRenderer({ pages, onPageChange }: { pages: PageInfo[]; onPageChange: (page: string) => null }) {
  const activePage = pages.find(p => p.active)
  if (activePage === undefined) return <></>

  const activePageIsFirst = activePage && Number(activePage.page) === 1
  const activePageIsLast = pages.findIndex(p => p.active) === pages.length - 2

  const pageListPanel = [{ active: false, disabled: activePageIsFirst, page: '<<', title: 'První strana' }]
  pageListPanel.push(pages[0]) // <

  if (!activePageIsFirst) {
    pageListPanel.push({
      active: false,
      disabled: false,
      page: (Number(activePage.page) - 1).toString(),
      title: 'Previous'
    })
  }

  pageListPanel.push(activePage)

  if (!activePageIsLast) {
    pageListPanel.push({
      active: false,
      disabled: false,
      page: activePage.page + 1,
      title: 'Next'
    })
  }

  pageListPanel.push(pages[pages.length - 1]) // >
  pageListPanel.push({ active: false, disabled: activePageIsLast, page: ">>", title: "Poslední strana" });

  return (
    <div className="react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6">
      <ul className="pagination react-bootstrap-table-page-btns-ul">
        {pageListPanel.map(p => (
          <li className={'page-item' + (p.active ? ' active ' : '') + (p.disabled ? ' disabled ' : '')} title={p.title} key={p.page}>
            <a href="# " className="page-link" onClick={handleClick(p, onPageChange)}>
              {' '}
              {p.page}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

function handleClick(p: PageInfo, onPageChange: (page: string) => void) {
  return (e: any) => {
    if (p.active) {
      e.preventDefault()
    } else {
      e.preventDefault()
      onPageChange(p.page)
    }
  }
}

export function paginationTotalRenderer(from: number, to: number, size: number) {
  return <span className="react-bootstrap-table-pagination-total">&nbsp;{`Zobrazeny záznamy od ${from} do ${to} z ${size}`}</span>
}
