import { gql, useQuery } from '@apollo/client'
import { FindByCompanyIdResponse } from '../../../../../graphql/aminTypes'

export const findByCompanyIdQuery = gql`
  query findByCompanyId($companyId: String!) {
    findByCompanyId(companyId: $companyId) {
      ares {
        name
        street
        streetNumber
        city
        zip
        dic
      }
    }
  }
`

export function useFindByCompanyIdQuery() {
  return useQuery<{ findByCompanyId: FindByCompanyIdResponse }>(findByCompanyIdQuery, {
    fetchPolicy: 'network-only',
    skip: true
  })
}
