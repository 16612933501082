import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export default function StatsButtonRow({
  orderContentStatesStat,
  setSelectedRows
}: {
  orderContentStatesStat: { contentStateSysName: string; count: number }[]
  setSelectedRows: any
}) {
  const forSupplierToConfirm = orderContentStatesStat.find(x => x.contentStateSysName === 'ForSupplierToConfirm')?.count ?? 0
  const confirmedBySupplier = orderContentStatesStat.find(x => x.contentStateSysName === 'ConfirmedBySupplier')?.count ?? 0
  const containerArrived = orderContentStatesStat.find(x => x.contentStateSysName === 'ContainerArrived')?.count ?? 0
  const containerTakenBack = orderContentStatesStat.find(x => x.contentStateSysName === 'ContainerTakenBack')?.count ?? 0
  const materialDelivered = orderContentStatesStat.find(x => x.contentStateSysName === 'MaterialDelivered')?.count ?? 0
  const orderFinishedBySupplier = orderContentStatesStat.find(x => x.contentStateSysName === 'OrderFinishedBySupplier')?.count ?? 0

  const navigate = useNavigate()
  const filterPage = useCallback(
    (sys: string) => (event: any) => {
      setSelectedRows(new Set())
      navigate(sys ? '/supplier/order-list?state=' + sys : '/supplier/order-list')
    },
    [navigate]
  )

  return (
    <div className="row mb-3">
      <div className="col-12">
        <div className="d-flex flex-row flex-wrap justify-content-md-around">
          <button type="button" className="btn bg-dark text-white btn-block" onClick={filterPage('')}>
            Bez filtru
          </button>
          <button type="button" className="btn bg-info btn-block" onClick={filterPage('ForSupplierToConfirm')}>
            K potvrzení ({forSupplierToConfirm})
          </button>
          <button type="button" className="btn btn-block bg-lime" onClick={filterPage('ConfirmedBySupplier')}>
            Vámi potvrzeno ({confirmedBySupplier})
          </button>
          <button type="button" className="btn bg-danger btn-block text-white" onClick={filterPage('ContainerArrived')}>
            Kontejner přistaven ({containerArrived})
          </button>
          <button type="button" className="btn bg-cyan btn-block text-white" onClick={filterPage('ContainerTakenBack')}>
            Kontejner odvezen ({containerTakenBack})
          </button>
          <button type="button" className="btn bg-maroon btn-block text-white" onClick={filterPage('MaterialDelivered')}>
            Materiál dovezen ({materialDelivered})
          </button>
          <button type="button" className="btn bg-indigo btn-block text-white" onClick={filterPage('OrderFinishedBySupplier')}>
            Zakázka dokončena ({orderFinishedBySupplier})
          </button>
        </div>
      </div>
    </div>
  )
}
