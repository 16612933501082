import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useGetAvailableResourcesForTransportOfMaterialQuery } from './queries/get-available-resources-for-waste-collection.query'
import dayjs from 'dayjs'
import Big from 'big.js'
import ToggleField from '../../../../components/formComponents/ToggleField'
import FormField, { inputType } from '../../../../components/formComponents/FormField'
import SelectField from '../../../../components/formComponents/SelectField'
import { ProductMaterialSelector } from '../../../common/components/ProductMaterialSelector/ProductMaterialSelector'
import { useProductMaterialListQuery } from '../../../common/components/ProductMaterialSelector/queries/product-material-list.query'
import { ProductMaterialEntity } from '../../../../graphql/aminTypes'

export default function DeliveryOfMaterial() {
  const formMethods = useFormContext()
  const [selectedMaterial, setSelectedMaterial] = useState<ProductMaterialEntity | undefined>(undefined)
  const address = formMethods.watch('customerAddress')
  const productMaterialListQR = useProductMaterialListQuery()
  const availableResourcesForTransportOfMaterialQR = useGetAvailableResourcesForTransportOfMaterialQuery({
    customerLatLng: address ? { lat: parseFloat(address['lat']), lng: parseFloat(address['lng']) } : undefined,
    radiusKm: formMethods.watch('radiusKm'),
    calculationDate: dayjs(formMethods.watch('calculationDate')).format('YYYY-MM-DD')
  })
  useEffect(() => {
    formMethods.setValue('containerRentLengthDays', 0)
    formMethods.setValue('routingStrategy', 'recommended')
    formMethods.setValue('inputInTonnes', true)
  }, [])
  const selectedProductMaterialId = formMethods.watch('materialId')

  useEffect(() => {
    if (!selectedProductMaterialId) return
    setSelectedMaterial(productMaterialListQR.data?.productMaterialList.find(x => x.id === selectedProductMaterialId))
  }, [selectedProductMaterialId])

  const inputInTonnes = formMethods.watch('inputInTonnes')
  const deliverMaterialCubic = formMethods.watch('volumeCubic')
  const deliverMaterialTonne = formMethods.watch('weightTonne')
  useEffect(() => {
    if (inputInTonnes && Number.isFinite(selectedMaterial?.cubicToTonnes) && Number.isFinite(deliverMaterialTonne) && selectedMaterial?.cubicToTonnes)
      formMethods.setValue('volumeCubic', new Big(deliverMaterialTonne).div(selectedMaterial.cubicToTonnes).round(2, 3))
    if (!inputInTonnes && Number.isFinite(deliverMaterialCubic) && Number.isFinite(selectedMaterial?.cubicToTonnes) && selectedMaterial?.cubicToTonnes)
      formMethods.setValue('weightTonne', new Big(deliverMaterialCubic).times(selectedMaterial.cubicToTonnes).round(2, 3))
  }, [inputInTonnes, deliverMaterialCubic, deliverMaterialTonne, selectedMaterial])

  const routingOptions = [
    { value: 'recommended', label: 'Doporučená trasa' },
    { value: 'fastest', label: 'Nejrychlejší' },
    { value: 'shortest', label: 'Nejkratší' }
  ]

  return (
    <>
      <fieldset className="form-fieldset bg-pink-lt">
        <div className="row">
          <div className="col-md-6">
            <ToggleField name={'inputInTonnes'} label={'Zadávat materiál v tunách'} />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-md-6">
            <ProductMaterialSelector
              label={'Vyberte materiál'}
              availableWastes={availableResourcesForTransportOfMaterialQR.data?.getAvailableResourcesForTransportOfMaterial.availableMaterialIds}
            />
          </div>
          <div className="col-md-3">
            <FormField
              type={inputType.number}
              is2Decimal
              name={'weightTonne'}
              label={'Množství materiálu [tun]'}
              valueAsNumber
              disabled={!formMethods.watch('inputInTonnes')}
            />
          </div>
          <div className="col-md-3">
            <FormField
              type={inputType.number}
              is2Decimal
              name={'volumeCubic'}
              label={'Množství materiálu [m³]'}
              valueAsNumber
              disabled={formMethods.watch('inputInTonnes')}
            />
          </div>
        </div>
        <div className="row mb-2 mt-2">
          <div className="col-md-6">
            <ToggleField name={'leaveContainerOnPlace'} label={'Ponechat materiál s kontejnerem na místě'} />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-md-3">
            <FormField
              type={inputType.number}
              name={'containerRentLengthDays'}
              label={'Délka zapůjčení kontejneru [dni] '}
              valueAsNumber
              isPositive
              disabled={!formMethods.watch('leaveContainerOnPlace')}
            />
          </div>
          <div className="col-md-3 offset-md-1">
            <SelectField name={'routingStrategy'} label={'Hledání trasy'} optionsData={routingOptions} required />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-2 offset-4">
            <button type="submit" className="btn btn-primary btn-block">
              Spočítat ceny
            </button>
          </div>
        </div>
      </fieldset>
    </>
  )
}
