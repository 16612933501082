import { gql, useQuery } from '@apollo/client'
import { Login } from '../../../graphql/aminTypes'

export const adminOwnersQuery = gql`
  query adminOwners {
    adminOwners {
      id
      firstname
      lastname
    }
  }
`

export function useAdminOwnersQuery() {
  return useQuery<{ adminOwners: Login[] }>(adminOwnersQuery)
}
