import { gql, useQuery } from '@apollo/client'
import { ProductWasteEntity, WasteCategory } from '../../../../../graphql/aminTypes'

export const productWasteListFlatQuery = gql`
  query productWasteListFlat($wasteCategory: WasteCategory, $excludeWasteFromWasteType: Boolean) {
    productWasteListFlat(wasteCategory: $wasteCategory, excludeWasteFromWasteType: $excludeWasteFromWasteType) {
      id
      isActive
      name
      tooltip
      wasteCategory
      uiOrder
      mainWasteForCode1
      wasteType {
        id
        name
        code
        codeMerged
      }
      hasAdditivesSpecification
      parentWasteId
      childWastes {
        id
      }
    }
  }
`

export function useProductWasteListFlatQuery(wasteCategory: WasteCategory, showAllAvailableWastes?: boolean) {
  return useQuery<{ productWasteListFlat: ProductWasteEntity[] }>(productWasteListFlatQuery, {
    fetchPolicy: 'network-only',
    variables: { wasteCategory, excludeWasteFromWasteType: !showAllAvailableWastes }
  })
}
