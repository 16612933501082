import { gql, useMutation } from '@apollo/client'
import { BaseMutationResult } from '../../../../../../graphql/aminTypes'

const setFioSyncSettingsMutation = gql`
  mutation setFioSyncSettings($allowScheduledSync: Boolean!, $allowAutoPaymentPaired: Boolean!) {
    setFioSyncSettings(allowScheduledSync: $allowScheduledSync, allowAutoPaymentPaired: $allowAutoPaymentPaired) {
      status {
        reason
        result
      }
    }
  }
`

export function useSetFioSyncSettingsMutation() {
  return useMutation<{ setFioSyncSettings: BaseMutationResult }>(setFioSyncSettingsMutation, {
    refetchQueries: ['getFioSyncSettings']
  })
}
