import { gql, useQuery } from '@apollo/client'
import { ProductConcreteComponentEntity } from '../../../graphql/aminTypes'

export const productConcreteComponentsQuery = gql`
  query productConcreteComponents {
    productConcreteComponents {
      id
      name
      type
      tooltip
      uiOrder
      uiPicture
      cubicToTonnes
    }
  }
`

export function useProductConcreteComponents() {
  return useQuery<{
    productConcreteComponents: ProductConcreteComponentEntity[]
  }>(productConcreteComponentsQuery, { fetchPolicy: 'network-only' })
}
