import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import FormField, { inputType } from '../../../../components/formComponents/FormField'
import ToggleField from '../../../../components/formComponents/ToggleField'
import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import { createParamsForCreateContainerMutation, useCreateContainerMutation } from './services/createContainerMutation'
import { useUpdateContainerMutation } from './services/updateContainerMutation'
import { ContainerItemType, useContainerTypesQuery } from '../../../../services/queries/containersQuery'
import { setupFields } from '../../../../services/formsServices/setupFields'
import LoadingContainer from '../../../../components/LoadingContainer'
import { isAnyQueryLoading } from '../../../../services/functions/queryHelpers'

export default function AddOrEditContainer({ containerId, exitHandler }: { containerId: number | undefined; exitHandler: () => Promise<void> }) {
  const formMethods = useForm()
  const containersQR = useContainerTypesQuery(loadContainerData)
  const [createContainerMut, createContainerQR] = useCreateContainerMutation()
  const [updateContainerMut, updateContainerQR] = useUpdateContainerMutation()
  const containerImages = [
    'small-container',
    'mid-container',
    'large-container1',
    'large-container2',
    'large-container3',
    'big-bag',
    'bath-tube-small',
    'bath-tube-mid',
    'bath-tube-large',
    'transport'
  ]

  return (
    <LoadingContainer showLoader={isAnyQueryLoading(containersQR, createContainerQR, updateContainerQR)}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
          <fieldset className="form-fieldset">
            <div className="row mb-2">
              <div className="col-md-6">
                <FormField type={inputType.text} name={'name'} label={'Název'} required registerOptions={{ required: true }} />
              </div>
              <div className="col-md-6">
                <FormField
                  type={inputType.number}
                  name={'capacityM3'}
                  label={'Kapacita [m³]'}
                  required
                  isDecimal
                  registerOptions={{ required: true }}
                  valueAsNumber
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-6">
                <FormField type={inputType.number} name={'uiOrder'} label={'Pořadí zobrazení'} registerOptions={{ required: true }} required />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-6">
                <FormField type={inputType.text} name={'tooltip'} label={'Tooltip'} />
              </div>
              <div className="col-md-6">
                <FormField type={inputType.number} name={'priceFrom'} label={'Cena od pro výchozí kontejnery [Kč]'} />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12">
                <label className="form-label">Ikona pro uživatele</label>
                <div className="form-selectgroup">
                  {containerImages.map(containerUiPicture => (
                    <label className="form-selectgroup-item " key={containerUiPicture}>
                      <input
                        type="radio"
                        value={containerUiPicture}
                        className="form-selectgroup-input"
                        {...formMethods.register('uiPicture', { required: true })}
                      />
                      <span className="form-selectgroup-label">
                        <img
                          src={`https://${process.env.REACT_APP_FE_HOSTNAME}/assets/images/containers/${containerUiPicture}.svg`}
                          width="100"
                          alt={containerUiPicture}
                        />
                      </span>
                    </label>
                  ))}
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-6">
                <ToggleField name={'offerOnUiAsDefault'} label={'Výchozí kontejner'} />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-6">
                <ToggleField name={'disabled'} label={'Zakázán'} />
              </div>
            </div>
            <div className="form-footer">
              <button type="submit" className="btn btn-primary w-100">
                {containerId ? 'Uložit' : 'Přidat'}
              </button>
            </div>
          </fieldset>
        </form>
      </FormProvider>
    </LoadingContainer>
  )

  function handleFormSubmit(data: Record<string | number | symbol, any>) {
    let resultPromise = containerId
      ? updateContainerMut({ variables: { containerId: containerId, ...createParamsForCreateContainerMutation(data) } })
      : createContainerMut({ variables: createParamsForCreateContainerMutation(data) })

    processPageOperation({
      promise: resultPromise,
      successAction: exitHandler,
      successMessage: containerId ? 'Kontejner byl upraven' : 'Kontejner byl přidán'
    })
    return false
  }

  function loadContainerData(data: { containerTypes: ContainerItemType[] }) {
    if (!containerId) return
    const containerData = data.containerTypes.find(x => x.id === containerId)
    if (!containerData) throw new Error('Kontejner nebyl nalezen')
    setupFields(containerData, { formMethods })
  }
}
