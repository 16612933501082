import { gql, useQuery } from '@apollo/client'
import { GetAvailableResourcesForWasteCollectionResponse, GetAvailableServicesForLocalityResponse } from '../../../../../graphql/aminTypes'
import { WasteCategoryType } from '../../../../../services/types/waste-category'

export const getAvailableResourcesForWasteCollectionQuery = gql`
  query getAvailableResourcesForWasteCollection(
    $customerLatLng: LatLngInput!
    $radiusKm: Int!
    $calculationDate: String!
    $wasteCategory: WasteCategory!
    $productId: ID
  ) {
    getAvailableResourcesForWasteCollection(
      wasteCategory: $wasteCategory
      customerLatLng: $customerLatLng
      radiusKm: $radiusKm
      calculationDate: $calculationDate
      productId: $productId
    ) {
      availableContainers
      availableWastes
    }
  }
`

export function useAvailableResourcesForWasteCollectionQuery(variables: {
  wasteCategory: WasteCategoryType
  customerLatLng: { lat: number; lng: number } | undefined
  radiusKm: number
  calculationDate: string
  productId?: string
}) {
  return useQuery<{ getAvailableResourcesForWasteCollection: GetAvailableResourcesForWasteCollectionResponse }>(getAvailableResourcesForWasteCollectionQuery, {
    fetchPolicy: 'network-only',
    skip: !variables.customerLatLng,
    variables: {
      wasteCategory: variables.wasteCategory,
      customerLatLng: variables.customerLatLng,
      radiusKm: variables.radiusKm,
      calculationDate: variables.calculationDate,
      productId: variables.productId
    }
  })
}
