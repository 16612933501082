import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../../services/types/gqlTypes'

const createSupplierTransportSupplierLinkMutation = gql`
  mutation createSupplierTransportSupplierLink($branchServiceId: ID!, $supplierBranchId: ID!, $transportCategory: TransportCategory!) {
    createSupplierTransportSupplierLink(branchServiceId: $branchServiceId, supplierBranchId: $supplierBranchId, transportCategory: $transportCategory) {
      status {
        reason
        result
      }
    }
  }
`

export function useCreateSupplierTransportSupplierLinkMutation() {
  return useMutation<{ createSupplierTransportSupplierLink: IMutationResult }>(createSupplierTransportSupplierLinkMutation, {
    refetchQueries: ['supplierTransportSupplierLink']
  })
}
