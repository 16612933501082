import MasterPage from './masterPage'
import React, { ReactElement } from 'react'
import preval from 'preval.macro'

export const MasterPageForPublic = (Component: any) => {
  return (
    <MasterPage isForPublic>
      <PageContainer>
        <Component />
      </PageContainer>
    </MasterPage>
  )
}

export function MasterPageForAdminUser({ Component }: { Component: () => ReactElement<any, any> }) {
  return (
    <MasterPage isForAdmin>
      <PageContainer>
        <Component></Component>
      </PageContainer>
    </MasterPage>
  )
}

export function MasterPageForSuppliers({ Component }: { Component: () => ReactElement<any, any> }) {
  return (
    <MasterPage isForSuppliers>
      <PageContainer>
        <Component />
      </PageContainer>
    </MasterPage>
  )
}

export function PageContainer({ children }: { children: ReactElement<any, any> }) {
  return (
    <div className="container-fluid">
      <div className="row row-cards">
        <div className="col-12">{children}</div>
        <div className="col-12">Build date: {preval`module.exports = new Date().toLocaleString();`}</div>
      </div>
    </div>
  )
}
