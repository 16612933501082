import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../services/types/gqlTypes'

const migrateSupplierMutation = gql`
  mutation migrateSupplier($supplierId: Int!, $overwriteExisting: Boolean!, $branchForSupplierWithName: String, $treatAsBranch: Boolean) {
    migrateSupplier(
      supplierId: $supplierId
      overwriteExisting: $overwriteExisting
      treatAsBranch: $treatAsBranch
      branchForSupplierWithName: $branchForSupplierWithName
    ) {
      status {
        reason
        result
      }
    }
  }
`

export function useMigrateSupplierMutation() {
  return useMutation<{ migrateSupplier: IMutationResult }>(migrateSupplierMutation, {
    refetchQueries: ['suppliersCount', 'suppliers']
  })
}
