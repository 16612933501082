import React, {useContext} from 'react'
import useBootstrapTableDataMapper from '../../../services/bootstrapTable/useBootstrapTableDataMapper'
import {hasQueryArrayData, isAnyQueryLoading} from '../../../services/functions/queryHelpers'
import BootstrapTable from 'react-bootstrap-table-next'
import LoadingContainer from '../../../components/LoadingContainer'
import {useSupplierPriceListQuery} from './services/supplierPriceListQuery'
import {supplierPriceListCollectionDataDefinition} from './services/supplierPriceListCollectionDataDefinition'
import {useDeleteSupplierPriceListMutation} from './services/deleteSupplierPriceListMutation'
import {processPageOperation} from '../../../services/formsServices/pageOperationProcessor'
import {ModalContext} from '../../../components/Modal/ModalProvider'
import SupplierPriceListEditor from './SupplierPriceListEditor'
import {SupplierItemType} from '../services/suppliersQuery'
import {supplierPriceListDeliveryDataDefinition} from "./services/supplierPriceListDeliveryDataDefinition";
import {useSupplierDeliveryPriceListQuery} from "./services/supplierDeliveryPriceListQuery";
import {useDeleteSupplierDeliveryPriceListMutation} from "./services/deleteSupplierDeliveryPriceList";
import SupplierPriceListMaterialEditor from "./SupplierPriceListMaterialEditor";


export default function SupplierPriceListRowExpand({supplier, showProductsForCollection}: { supplier: SupplierItemType; showProductsForCollection: boolean }) {
    const isDelivery = !showProductsForCollection
    const {generateColumnListForBootstrapTable, mapDataArrayToTable} = useBootstrapTableDataMapper<any>(
        isDelivery ? supplierPriceListDeliveryDataDefinition() : supplierPriceListCollectionDataDefinition(supplier.useFlatRatePriceList)
    )
    const supplierDeliveryPriceListQR = useSupplierDeliveryPriceListQuery(supplier.id)
    const supplierCollectionPriceListsQR = useSupplierPriceListQuery(supplier.id)
    const [deleteSupplierPriceListMut, deleteSupplierPriceListQR] = useDeleteSupplierPriceListMutation()
    const [deleteSupplierDeliveryPriceListMut, deleteSupplierDeliveryPriceListMR] = useDeleteSupplierDeliveryPriceListMutation()
    const {showModal, hideModal} = useContext(ModalContext)

    const columnDefinition = generateColumnListForBootstrapTable({
        columnAction: handleTableClick,
        actionsReference: {
            editPriceList: editPriceListHandler,
            deletePriceList: deletePriceListHandler
        }
    })

    let priceList: any[] = []
    if ((supplierCollectionPriceListsQR.data && hasQueryArrayData(supplierCollectionPriceListsQR)) || (supplierDeliveryPriceListQR.data && hasQueryArrayData(supplierDeliveryPriceListQR))) {
        if (isDelivery && supplierDeliveryPriceListQR.data) {
            priceList = mapDataArrayToTable(supplierDeliveryPriceListQR.data.deliveryPriceLists)
        }
        if (!isDelivery && supplierCollectionPriceListsQR.data) {
            priceList = mapDataArrayToTable(supplierCollectionPriceListsQR.data.supplierPriceList.slice().sort((a, b) => a.container.uiOrder - b.container.uiOrder))
        }
    }
    return (
        <LoadingContainer
            showLoader={isAnyQueryLoading(deleteSupplierPriceListQR, supplierCollectionPriceListsQR, supplierDeliveryPriceListQR, deleteSupplierDeliveryPriceListMR)}>
            <BootstrapTable
                bootstrap4
                striped
                condensed
                classes="table-responsive-lg digiTable"
                noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné záznamy"</>}
                keyField="id"
                data={priceList}
                columns={columnDefinition}
                defaultSorted={isDelivery ? [{ dataField: 'uiOrder', order: 'asc' }]: []}
            />
            <div className="text-right">
                <button type="submit" className="btn btn-primary ms-auto btn-sm" onClick={addPriceListHandler}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <line x1="12" y1="5" x2="12" y2="19"/>
                        <line x1="5" y1="12" x2="19" y2="12"/>
                    </svg>
                    Přidat
                </button>
            </div>
        </LoadingContainer>
    )

    function deletePriceListHandler(row: any) {
        if (!row.id) return
        if (!isDelivery) {
            if (window.confirm(`Opravdu zrušit definici kontejneru ${row.container}?`)) {
                const deleteSupplierPromise = deleteSupplierPriceListMut({variables: {supplierPriceListId: row.id}})
                processPageOperation({
                    promise: deleteSupplierPromise,
                    successAction: result => supplierCollectionPriceListsQR.refetch()
                })
            }
        }
        if (isDelivery) {
            if (window.confirm(`Opravdu zrušit definici produktu ${row.product}?`)) {
                const deleteSupplierDeliveryPromise = deleteSupplierDeliveryPriceListMut({variables: {id: row.id}})
                processPageOperation({
                    promise: deleteSupplierDeliveryPromise,
                    successAction: result => supplierDeliveryPriceListQR.refetch()
                })
            }
        }
    }

    function handleTableClick(e: Event, column: number, columnIndex: number, row: any) {
        if (columnIndex < 5) editPriceListHandler(row)
    }

    function editPriceListHandler(row: any) {
        showModal({
            title: isDelivery ? ('Upravit ceny produktu pro návoz pro ' + supplier.name) : ('Upravit ceny produktu pro odvoz pro ' + supplier.name),
            hideFooter: true,
            onClose: finishEditPriceList,
            modalContent: (
                isDelivery ?
                    <SupplierPriceListMaterialEditor
                        onFinish={finishEditPriceList}
                        supplierId={supplier.id}
                        supplierPriceListId={row.id}
                    />
                    :
                    <SupplierPriceListEditor
                        onFinish={finishEditPriceList}
                        supplierId={supplier.id}
                        supplierPriceListId={row.id}
                        isForFlatRate={supplier.useFlatRatePriceList}
                    />
            )
        })
    }

    function addPriceListHandler(e: any) {
        e.preventDefault()
        showModal({
            title: isDelivery ? ('Přidat nový ceník produktů pro návoz pro ' + supplier.name) : ('Přidat nový ceník produktů pro odvoz pro ' + supplier.name),
            hideFooter: true,
            onClose: finishEditPriceList,
            modalContent: (
                isDelivery ?
                    <SupplierPriceListMaterialEditor
                        onFinish={finishEditPriceList}
                        supplierId={supplier.id}
                        currentlyDefinedMaterials={supplierDeliveryPriceListQR?.data?.deliveryPriceLists.map(x => x.product.id)}
                    />
                    :
                    <SupplierPriceListEditor
                        onFinish={finishEditPriceList}
                        supplierId={supplier.id}
                        isForFlatRate={supplier.useFlatRatePriceList}
                        hideContainers={priceList.map(x => x.containerId)}
                    />
            )
        })
    }

    function finishEditPriceList() {
        let ret
        if (isDelivery) ret = supplierDeliveryPriceListQR.refetch()
        if (!isDelivery) ret = supplierCollectionPriceListsQR.refetch()
        if (ret) ret.then(() => hideModal())
        //    hideModal()
    }
}
