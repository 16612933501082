import { gql, useMutation } from '@apollo/client'
import {IMutationResult} from "../../../services/types/gqlTypes";

const addSupplierToLoginMutation = gql`
  mutation addSupplierToLogin($supplierId: Int!, $loginId: Int!) {
    addSupplierToLogin(supplierId: $supplierId, loginId: $loginId) {
      status {
        reason
        result
      }
    }
  }
`

export function useAddSupplierToLoginMutation() {
  return useMutation<{ addSupplierToLogin: IMutationResult }>(addSupplierToLoginMutation)
}
