import { useGetOrderLockQuery } from './queries/get-order-lock.query'
import classNames from 'classnames'
import React, { useContext, useEffect } from 'react'
import { LoginContext } from '../../services/loginAndToken/useLogin'
import dayjs from 'dayjs'
import { useLockOrderMutation } from '../../scenesAdmin/AddOrEditOrder/components/OrderLock/mutations/lock-order.mutation'
import { useReleaseOrderLockMutation } from '../../scenesAdmin/AddOrEditOrder/components/OrderLock/mutations/release-order-lock.mutation'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import { ErrorAlert } from '../formComponents/alert'
import { toast } from 'react-toastify'

export default function OrderLockInfo({ orderId, toastNotifyAboutLock }: { orderId?: number; toastNotifyAboutLock?: boolean }) {
  const currentLogin = useContext(LoginContext)
  const orderLockQR = useGetOrderLockQuery(orderId)
  const [lockOrder, lockOrderQR] = useLockOrderMutation()
  const [releaseLock, releaseOrderLockQR] = useReleaseOrderLockMutation()
  const lockedByLoginId = orderLockQR.data?.getOrderLock?.lockedByLogin.id
  const isMyReservation = currentLogin?.currentJWT?.userId === (lockedByLoginId ? parseInt(lockedByLoginId) : null)

  useEffect(() => {
    orderLockQR.startPolling(10000)
    return () => {
      orderLockQR.stopPolling()
    }
  }, [])

  useEffect(() => {
    if (toastNotifyAboutLock) {
      if (orderLockQR.data?.getOrderLock?.lockedByLogin) {
        toast.dismiss()
        toast.error(
          `Objednávka je uzamčena uživatelem ${orderLockQR.data.getOrderLock.lockedByLogin.firstname} ${
            orderLockQR.data.getOrderLock.lockedByLogin.lastname
          } do ${dayjs(orderLockQR.data.getOrderLock.lockedUntil).format('HH:mm')}`,
          { autoClose: false }
        )
      }
    }
    return () => {
      if (toastNotifyAboutLock) toast.dismiss()
    }
  }, [toastNotifyAboutLock, orderLockQR.data])

  if (orderLockQR.data?.getOrderLock?.lockedByLogin)
    return (
      <fieldset className={classNames('form-fieldset')}>
        {!isMyReservation && <ErrorAlert message={'Objednávka je rezervována pro jiného uživatele'} />}
        <dl className="row">
          <dt className={`col-6`}>Rezervace pro</dt>
          <dd className="col-6">
            {orderLockQR.data?.getOrderLock?.lockedByLogin.firstname} {orderLockQR.data?.getOrderLock?.lockedByLogin.lastname} {isMyReservation ? '(Vaše)' : ''}
          </dd>
        </dl>
        <dl className="row">
          <dt className={`col-6`}>Vyprší</dt>
          <dd className="col-6">{dayjs(orderLockQR.data?.getOrderLock?.lockedUntil).format('DD.MM.YYYY HH:mm')}</dd>
        </dl>
        <div className={'d-flex'}>
          <div className={'flex-row'}>
            {isMyReservation && (
              <button
                type={'button'}
                className="btn btn-danger mr-3"
                onClick={event => {
                  event.preventDefault()
                  releaseOrderLockHandler()
                }}
              >
                Zrušit rezervaci
              </button>
            )}
            {isMyReservation && (
              <button
                type={'button'}
                className="btn btn-green  mr-3"
                onClick={event => {
                  event.preventDefault()
                  lockOrderHandler()
                }}
              >
                Změnit dobu rezervace
              </button>
            )}
            <button
              type={'button'}
              className="btn btn-blue  mr-3"
              onClick={event => {
                event.preventDefault()
                refreshHandler()
              }}
            >
              <i className={`pl-2 pt-1 fe fe-refresh-cw `}></i>
            </button>
          </div>
        </div>
      </fieldset>
    )
  else
    return (
      <fieldset className={classNames('form-fieldset')}>
        <strong>Objednávka nemá rezervaci</strong>
        <div className={'d-flex mt-3'}>
          <div className={'flex-row'}>
            <button
              type={'button'}
              className="btn btn-green  mr-3"
              onClick={event => {
                event.preventDefault()
                lockOrderHandler()
              }}
            >
              Rezervovat objednávku
            </button>
            <button
              type={'button'}
              className="btn btn-blue  mr-3"
              onClick={event => {
                event.preventDefault()
                refreshHandler()
              }}
            >
              <i className={`pl-2 pt-1 fe fe-refresh-cw `}></i>
            </button>
          </div>
        </div>
      </fieldset>
    )

  function releaseOrderLockHandler() {
    const promise = releaseLock({ variables: { orderId } })
    processPageOperation({ promise, successMessage: 'Rezervace objednávky byla zrušena' })
  }

  function refreshHandler() {
    return orderLockQR.refetch()
  }

  function lockOrderHandler() {
    const lockDurationMin = prompt('Zadejte novou dobu zamknutí v minutách od teď', '10')
    if (!lockDurationMin) return
    if (isNaN(parseInt(lockDurationMin))) return alert('Zadejte číslo')
    if (parseInt(lockDurationMin) < 1) return alert('Zadejte číslo větší než 0')
    if (parseInt(lockDurationMin) > 1440) return alert('Nelze rezervovat na více než den')

    const promise = lockOrder({ variables: { orderId, lockDurationMin: parseInt(lockDurationMin) } })
    processPageOperation({ promise, successMessage: 'Rezervace objednávky byla vytvořena' })
  }
}
