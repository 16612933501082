import { gql, useMutation } from '@apollo/client'
import { BaseMutationResult } from '../../../../graphql/aminTypes'

const payMatchedFioTransactionsMutation = gql`
  mutation payMatchedFioTransactions($ids: [String!]!) {
    payMatchedFioTransactions(ids: $ids) {
      status {
        reason
        result
      }
    }
  }
`

export function usePayMatchedFioTransactionsMutation() {
  return useMutation<{ payMatchedFioTransactions: BaseMutationResult }>(payMatchedFioTransactionsMutation, {
    refetchQueries: ['getFioTransactionList']
  })
}
