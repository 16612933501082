import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const deleteSellerConcretePriceListMutation = gql`
  mutation deleteSellerConcretePriceList($id: ID!) {
    deleteSellerConcretePriceList(id: $id) {
      status {
        reason
        result
      }
    }
  }
`

export function useDeleteSellerConcretePriceListMutation() {
  return useMutation<{ deleteSellerConcretePriceList: IMutationResult }>(deleteSellerConcretePriceListMutation, {
    refetchQueries: ['supplierServiceConcretePriceList']
  })
}
