import { gql, useQuery } from '@apollo/client'

const statisticsProductsQuery = gql`
  query statisticsProducts($fromDate: String, $toDate: String){
    statisticsProducts(fromDate: $fromDate, toDate: $toDate){
      count
      name
    }
  }
`

export function useStatisticsProductsQuery(params?: {fromDate?: string, toDate?: string}) {
  return useQuery<{ statisticsProducts: StatisticsProductsType[] }>(statisticsProductsQuery, {variables: params})
}

export type StatisticsProductsType = {
  name: string
  count: number
}
