import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'
import { contextEndpoint, EndpointEnum } from '../../../services/appConfiguration/contextEndpoint'

export const requestResetPasswordMutation = gql`
  mutation requestResetPassword($email: String!, $recaptchaToken: String!) {
    requestResetPassword(email: $email, recaptchaToken: $recaptchaToken) {
      status {
        reason
        result
      }
    }
  }
`

export function createParamsForRequestResetPassword(email: string, token: string) {
  return {
    recaptchaToken: token,
    email: email
  }
}

export function useRequestResetPasswordMutation() {
  return useMutation<{ requestResetPassword: IMutationResult }>(requestResetPasswordMutation, { context: contextEndpoint(EndpointEnum.login) })
}
