import React, { useContext, useEffect } from 'react'
import Header from '../appComponents/Header'
import { useLocation, useNavigate } from 'react-router-dom'
import { LoginContext } from '../../services/loginAndToken/useLogin'
import 'react-toastify/dist/ReactToastify.css'
import HeaderPriceLists from '../appComponents/Header-price-lists'

type MasterPageType = {
  isForSuppliers?: boolean
  isForPublic?: boolean
  isForAdmin?: boolean
  children?: React.ReactNode
}

export default function MasterPage({ isForSuppliers, isForPublic, isForAdmin, children }: MasterPageType) {
  const navigate = useNavigate()
  const location = useLocation()
  const currentLogin = useContext(LoginContext)

  useEffect(() => {
    if (!currentLogin?.currentJWT) {
      currentLogin?.refreshToken()
      setTimeout(() => {
        if (!currentLogin?.currentJWT) navigate('/?url=' + encodeURIComponent(location.pathname + location.search))
      }, 500)
    }
  }, [navigate, currentLogin?.currentJWT])

  return (
    <div className="page">
      {!location.pathname.includes('configuration') && <Header />}
      {location.pathname.includes('configuration') && <HeaderPriceLists />}
      <div className="content">{children}</div>
    </div>
  )
}
