import { gql, useQuery } from '@apollo/client'

const statisticsProductsTurnoverQuery = gql`
  query statisticsProductsTurnover($fromDate: String, $toDate: String){
    statisticsProductsTurnover(fromDate: $fromDate, toDate: $toDate){
      name
      turnover
    }
  }
`

export function useStatisticsProductsTurnoverQuery(params?: {fromDate?: string, toDate?: string}) {
  return useQuery<{ statisticsProductsTurnover: StatisticsProductsTurnoverType[] }>(statisticsProductsTurnoverQuery, {variables: params})
}

export type StatisticsProductsTurnoverType = {
  name: string
  turnover: number
}
