import React, { useContext } from 'react'
import { useWasteFinalsQuery } from './query/waste-finals.query'
import LoadingContainer from '../../../../components/LoadingContainer'
import { isAnyQueryLoading } from '../../../../services/functions/queryHelpers'
import BootstrapTable from 'react-bootstrap-table-next'
import useBootstrapTableDataMapper from '../../../../services/bootstrapTable/useBootstrapTableDataMapper'
import { wasteFinalsDataDefinition } from './services/wasteFinalsDataDefinition'
import { useDeleteWasteFinalMutation } from './mutation/delete-waste-final.mutation'
import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import { ModalContext } from '../../../../components/Modal/ModalProvider'
import WasteFinalEditor from './WasteFinalEditor'
import axios from 'axios'
import { toast } from 'react-toastify'
import { LoginContext } from '../../../../services/loginAndToken/useLogin'
import { useGenerateWasteFinalConfirmationMutation } from './mutation/generate-waste-final-confirmation.mutation'
import { useSendConfirmationByEmailMutation } from './mutation/send-confirmation-by-email.mutation'
import { Maybe } from '../../../../graphql/aminTypes'

export default function WasteFinals({ orderContentId, supplierIcz }: { orderContentId: number; supplierIcz?: Maybe<string> }) {
  const { showModal, hideModal } = useContext(ModalContext)
  const loginData = useContext(LoginContext)
  const wasteFinalsQR = useWasteFinalsQuery(orderContentId, false)
  const [deleteWasteFinalMut, deleteWasteFinalQR] = useDeleteWasteFinalMutation(orderContentId)
  const [sendConfirmationByEmailMut, sendConfirmationByEmailQR] = useSendConfirmationByEmailMutation(orderContentId)
  const [generateWasteFinalConfirmationMut, generateWasteFinalConfirmationQR] = useGenerateWasteFinalConfirmationMutation(orderContentId)
  const { generateColumnListForBootstrapTable, mapDataArrayToTable } = useBootstrapTableDataMapper(wasteFinalsDataDefinition)
  const columnDefinition = generateColumnListForBootstrapTable({
    actionsReference: {
      deleteWasteFinal: deleteWasteFinalHandler,
      editWasteFinal: editWasteFinalHandler,
      sendConfirmationByEmail: sendConfirmationByEmailHandler,
      generateWasteFinalConfirmation: generateWasteFinalConfirmationHandler,
      downloadWasteFinalConfirmation: downloadWasteFinalConfirmationHandler
    }
  })

  let wasteFinals: any[] = []
  if (wasteFinalsQR.data && wasteFinalsQR.data.wasteFinals) wasteFinals = mapDataArrayToTable(wasteFinalsQR.data.wasteFinals)

  return (
    <fieldset className="form-fieldset">
      <header>
        <h2>Váženky</h2>
      </header>
      <LoadingContainer showLoader={isAnyQueryLoading(wasteFinalsQR, deleteWasteFinalQR, generateWasteFinalConfirmationQR, sendConfirmationByEmailQR)}>
        <BootstrapTable
          bootstrap4
          hover
          striped
          condensed
          classes="table-responsive-lg digiTable"
          noDataIndication={() => <>Zakázka u sebe nemá váženky</>}
          keyField="id"
          data={wasteFinals}
          columns={columnDefinition}
        />
      </LoadingContainer>
      <button type="button" className="btn btn-primary w-100" onClick={addWasteFinalsHandler}>
        Přidat
      </button>
    </fieldset>
  )

  function deleteWasteFinalHandler(row: any) {
    if (window.confirm('Opravdu nevratně odstranit váženku pro ' + row.wasteType)) {
      const promise = deleteWasteFinalMut({
        variables: { wasteFinalId: row.id }
      })
      processPageOperation({
        promise: promise,
        successMessage: `Váženka byla smazána`
      })
    }
  }

  function editWasteFinalHandler(row: any) {
    showModal({
      hideFooter: true,
      title: 'Úprava Váženky',
      modalContent: <WasteFinalEditor orderContentId={orderContentId} wasteFinalId={row.id} onFinish={hideModal} />
    })
  }

  function addWasteFinalsHandler(row: any) {
    showModal({
      hideFooter: true,
      title: 'Přidat Váženku',
      modalContent: <WasteFinalEditor orderContentId={orderContentId} onFinish={hideModal} supplierIcz={supplierIcz} />
    })
  }

  function generateWasteFinalConfirmationHandler(row: any) {
    const promise = generateWasteFinalConfirmationMut({ variables: { wasteFinalId: row.id } })
    return processPageOperation({
      promise: promise,
      successMessage: 'Potvrzení o odvozu odpadu je připraveno'
    })
  }

  function sendConfirmationByEmailHandler(row: any) {
    if (window.confirm('Potvrzení o odvozu odpadu bude odesláno zákazníkovi. Poté již nebude možné váženku editovat. Pokračovat?')) {
      const promise = sendConfirmationByEmailMut({ variables: { wasteFinalId: row.id } })
      return processPageOperation({
        promise: promise,
        successMessage: 'Potvrzení o odvozu odpadu bylo odesláno zákazníkovi'
      })
    }
  }

  async function downloadWasteFinalConfirmationHandler(row: any) {
    try {
      const endpoint = `${process.env.REACT_APP_CONNECTION_PROTOCOL ?? 'https'}://${process.env.REACT_APP_BACKEND_HOSTNAME}/api/waste-finals/${
        row.id
      }/confirmation`

      const response = await axios.get(endpoint, {
        responseType: 'arraybuffer',
        headers: { Authorization: `Bearer ${loginData?.at}` }
      })

      const fileName = response.headers['x-suggested-filename']
      const blob = new Blob([response.data], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = fileName // Set the file name extracted from headers
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      toast.error('Chyba při generování potvrzení o odvozu odpadu. ' + (error as Error).message)
    }
  }
}
