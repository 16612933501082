import { gql, useQuery } from '@apollo/client'
import { SubjectInfoType } from '../../../../graphql/publicTypes'
import { contextEndpoint, EndpointEnum } from '../../../../services/appConfiguration/contextEndpoint'

const searchByCompanyNameInAresQuery = gql`
  query searchByCompanyNameInAres($companyName: String!) {
    searchByCompanyNameInAres(companyName: $companyName) {
      name
      street
      streetNumber
      city
      zip
      dic
      ico
    }
  }
`

export function useSearchByCompanyNameInAresQuery() {
  return useQuery<{ searchByCompanyNameInAres: SubjectInfoType[] }>(searchByCompanyNameInAresQuery, {
    skip: true,
    context: contextEndpoint(EndpointEnum.public)
  })
}
