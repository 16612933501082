import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const deleteProductMaterialComponentMutation = gql`
  mutation deleteProductMaterialComponent($id: ID!) {
    deleteProductMaterialComponent(id: $id) {
      status {
        reason
        result
      }
    }
  }
`

export function useDeleteProductMaterialComponentMutation() {
  return useMutation<{ deleteProductMaterialComponent: IMutationResult }>(deleteProductMaterialComponentMutation, {
    refetchQueries: ['productMaterialComponents']
  })
}
