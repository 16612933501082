import React from 'react'
import SelectField from '../../formComponents/SelectField'
import { IReactSelectItem } from '../../../services/types/ReactSelect'
import { sortArrayBy } from '../../../services/functions/sortArrayBy'
import { useSuppliers } from './services/suppliersQuery'

export default function SupplierSelector({
  required,
  removeSuppliers,
  name,
  label,
  disabled,
  withProductId,
  includingDisabled
}: {
  disabled?: boolean
  required?: boolean
  removeSuppliers?: number[]
  name?: string
  label?: string
  withProductId?: number
  includingDisabled?: boolean
}) {
  const suppliersWithProductQR = useSuppliers({ disabled: includingDisabled ? undefined : 'false', withProductId: withProductId })
  const allSuppliersQR = useSuppliers({ disabled: includingDisabled ? undefined : 'false' })

  let suppliersData: IReactSelectItem<any>[] = []
  let suppliersDataUpdated: IReactSelectItem<any>[] = []
  if (!allSuppliersQR.loading && allSuppliersQR.data) {
    suppliersData = (allSuppliersQR.data.suppliers2.items ?? []).map(x => ({
      label: `${x.name} (${x.regionCode ?? ''} - ${x.orpName ?? ''})`,
      value: x.id,
      color:
        withProductId && suppliersWithProductQR.data?.suppliers2.items && !suppliersWithProductQR.data?.suppliers2.items.find(s => s.id === x.id)
          ? '#ccc'
          : 'black'
    }))
    sortArrayBy(suppliersData, x => x.label)
  }

  if (suppliersData.length > 0 && removeSuppliers && removeSuppliers.length > 0) {
    suppliersDataUpdated = suppliersData.slice(0).filter(x => !removeSuppliers?.includes(x.value))
  }

  return (
    <SelectField
      name={name ?? 'supplierId'}
      label={label ?? 'Dodavatel'}
      disabled={disabled}
      required={required}
      registerOptions={{ required: !!required }}
      optionsData={removeSuppliers && removeSuppliers.length > 0 ? suppliersDataUpdated : suppliersData}
      isLoading={allSuppliersQR.loading}
    />
  )
}
