import { gql, useQuery } from '@apollo/client'
import { IGqlPagingQueryParams } from '../../../services/bootstrapTable/usePagingFromURLQuery'
import { SupplierBranchListResponse, SupplierSupplierListResponse } from '../../../graphql/aminTypes'

export const supplierBranchListQuery = gql`
  query supplierBranchList($supplierId: ID, $id: ID) {
    supplierBranchList(supplierId: $supplierId, id: $id) {
      total
      items {
        id
        isActive
        icz
        useGpsAddressInput
        setupIsFinal
        specification
        gps {
          lat
          lng
        }
        contacts {
          main {
            name
            phone
            email
          }
          shop {
            name
            phone
            email
          }
          transport {
            name
            phone
            email
          }
          weight {
            name
            phone
            email
          }
          zpo {
            name
            phone
            email
          }
        }
        address {
          street
          streetNumber
          city
          zip
        }
        notes {
          carrierNote
          internalNote
          zpoNote
        }
        orderNotificationOptions
        paymentCapabilities
        extraServices
        settings {
          hasContractWithMetrak
          sendMaterialOrderWhenPaidAndIAmNotTransportingOrder
          sendAllEmailsAlsoToContactPerson
          sendZpoToContactAutomaticallyAfterApprovalByCarrier
          sendZpoToCarrier
        }
        workingHours {
          dayName
          from
          to
          isWorking
          surcharge
        }
        vacations {
          vacationFrom
          vacationTo
        }

        orderDeliverySettings {
          allowOrdersForToday
          requiredDaysBeforeOrderStart
          shiftOrderPossibleStartByOneFromTodayTime
          todayDeliveryAvailableTillTime
        }
        branchServices {
          isActive
          id
          serviceCategory
          higherPriority
          validFrom
          validTo
          settings
          metrakMargin
        }
        supplier {
          id
          companyIdentification
        }
        defaultMetrakMargin
        pou {
          id
          name
          code
        }
      }
    }
  }
`

export function useSupplierBranchListQuery(params: IGqlPagingQueryParams, opts?: { skip?: boolean }) {
  return useQuery<{ supplierBranchList: SupplierBranchListResponse }>(supplierBranchListQuery, {
    fetchPolicy: 'network-only',
    variables: params,
    skip: opts?.skip
  })
}
