import PageCard from '../../components/Cards/pageCard'
import React, { useContext } from 'react'
import useBootstrapTableDataMapper from '../../services/bootstrapTable/useBootstrapTableDataMapper'
import { hasQueryArrayData, isAnyQueryLoading } from '../../services/functions/queryHelpers'
import BootstrapTable from 'react-bootstrap-table-next'
import usePagingFromURLQuery from '../../services/bootstrapTable/usePagingFromURLQuery'
import useBootstrapTablePagingAndFiltration from '../../services/bootstrapTable/useBootstrapTablePagingAndFiltration'
// @ts-ignore
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import { ModalContext } from '../../components/Modal/ModalProvider'
import { ProductContainerEntity } from '../../graphql/aminTypes'
import { productContainerDataDefinition } from './services/productContainerDataDefinition'
import { useProductContainerListQuery } from './queries/product-container-list.query'
import AddOrEditContainer from './components/AddOrEditContainer/AddOrEditContainer'
import { pageCardHeaderButton } from '../../components/Cards/pageCardHeaderButton'
import AddOrEditWastes from '../ProductWasteList/components/AddOrEditWaste/AddOrEditWaste'
import { useDeleteProductContainerMutation } from './mutations/delete-product-container.mutation'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import { ProductContainerCapacityOfContainer } from '../ProductContainerCapacity/ProductContainerCapacityOfContainer'

export default function ProductContainerList() {
  const { showModal, hideModal } = useContext(ModalContext)
  const { generateColumnListForBootstrapTable, mapDataArrayToTable, nameForGqlTranslation } =
    useBootstrapTableDataMapper<ProductContainerEntity>(productContainerDataDefinition)
  const { pageConfiguration, gqlQueryParams } = usePagingFromURLQuery([], nameForGqlTranslation, { defaultPageSize: 100 })

  const productContainerListQR = useProductContainerListQuery()
  const [deleteProductContainerMut, deleteProductContainerQR] = useDeleteProductContainerMutation()
  const { handleTableChange } = useBootstrapTablePagingAndFiltration(pageConfiguration, undefined, true, 1)

  const columnDefinition = generateColumnListForBootstrapTable({
    filterDefaultValues: pageConfiguration.filterBy,
    currentSort: pageConfiguration.sort,
    columnAction: handleTableClick,
    handleTableChange: handleTableChange,
    actionsReference: {
      editProduct: handleProductEdit,
      deleteContainer: deleteProductContainerHandler,
      showContainerCapacities: showContainerCapacitiesHandler
    }
  })

  let productContainers: any[] = []

  if (productContainerListQR.data && hasQueryArrayData(productContainerListQR))
    productContainers = mapDataArrayToTable(productContainerListQR.data.productContainerList)

  return (
    <PageCard
      showLoader={isAnyQueryLoading(productContainerListQR, deleteProductContainerQR)}
      headerTitle={'Seznam kontejnerů'}
      cardOptions={pageCardHeaderButton(
        'Přidat kontejner',
        () =>
          showModal({
            title: 'Přidat kontejner',
            modalContent: <AddOrEditContainer exitHandler={hideModal} />,
            hideFooter: true
          }),
        'CreateWaste'
      )}
    >
      <div className="table-responsive-xl">
        <BootstrapTable
          bootstrap4
          striped
          condensed
          hover
          remote={{ filter: true, pagination: false, sort: false }}
          classes="table-responsive-lg digiTable"
          noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné záznamy"</>}
          keyField="id"
          data={productContainers}
          columns={columnDefinition}
          filter={filterFactory()}
          sort={pageConfiguration.sort}
          onTableChange={handleTableChange}
        />
      </div>
    </PageCard>
  )

  function handleTableClick(e: Event, column: number, columnIndex: number, row: any) {
    if (columnIndex > 4) return
    const container = productContainerListQR.data?.productContainerList.find(x => x.id === row.id)
    showModal({
      title: 'Editace kontejneru ' + row.name + ' ' + row.volume + 'm³',
      modalContent: <AddOrEditContainer containerToEdit={container} exitHandler={hideModal} />,
      hideFooter: true
    })
  }

  function handleProductEdit(row: any) {
    if (!row.id) return
    showModal({
      title: 'Upravit ' + row.name,
      hideFooter: true,
      modalContent: <></>
    })
  }
  function showContainerCapacitiesHandler(row: any) {
    if (!row.id) return
    showModal({
      title: 'Kapacity kontejnerů pro odpad ' + row.name,
      hideFooter: false,
      modalContent: <ProductContainerCapacityOfContainer containerId={row.id} />
    })
  }

  function deleteProductContainerHandler(row: any) {
    const promise = deleteProductContainerMut({ variables: { id: row.id } })
    return processPageOperation({
      promise: promise,
      successMessage: 'Kontejner byl smazán'
    })
  }
}
