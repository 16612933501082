import { IReactSelectItem } from '../../../services/types/ReactSelect'
import { performStateActionEnum } from './usePerformStateActionMutation'

export const AdminActionList: IReactSelectItem<any>[] = [
  { value: performStateActionEnum.CANCEL_BY_SUPPLIER, label: 'Odmítnuto dodavatelem' },
  { value: performStateActionEnum.CONFIRM, label: 'Přijato dodavatelem' },
  { value: performStateActionEnum.CONTAINER_ARRIVE, label: 'Kontejner byl přistaven' },
  { value: performStateActionEnum.CONTAINER_TAKE, label: 'Kontejner byl odvezen' },
  { value: performStateActionEnum.MATERIAL_DELIVERED, label: 'Materiál byl dovezen' },
  { value: performStateActionEnum.ORDER_FINISHED_BY_SUPPLIER, label: 'Dokončeno dodavatelem' }
]
