import { gql, useQuery } from '@apollo/client'
import { AccountingAdvanceAccountEntity, AccountingDocumentEntity, CustomerListPaginatedResponse } from '../../../../graphql/aminTypes'

export const customerAdvanceAccountInvoicesQuery = gql`
  query advanceAccountInvoices($advanceAccountId: ID!) {
    advanceAccountInvoices(advanceAccountId: $advanceAccountId) {
      id
      type
      createdAt
      note
      datePaid
      emailSentAt
      isCancelled
      amountInclVatKc
      documentId
      documentUrl
      documentWebUrl
      dueDate
      variableSymbol
    }
  }
`

export function useCustomerAdvanceAccountInvoices(advanceAccountId?: string) {
  return useQuery<{ advanceAccountInvoices: AccountingDocumentEntity[] }>(customerAdvanceAccountInvoicesQuery, {
    fetchPolicy: 'network-only',
    variables: { advanceAccountId },
    skip: !advanceAccountId
  })
}
