import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const activateDraftMutation = gql`
  mutation activateDraft($orderId: Int!) {
    activateDraft(orderId: $orderId) {
      status {
        reason
        result
      }
    }
  }
`

export function useActivateDraftMutation() {
  return useMutation<{ activateDraft: IMutationResult }>(activateDraftMutation)
}
