import React from 'react'

export enum alertType {
  danger = 'danger',
  warning = 'warning',
  info = 'info',
  success = 'success',
  secondary = 'secondary',
  primary = 'primary'
}

export const SuccessAlert = (props: IAlertParams) => <Alert type={alertType.success} {...props} />
export const ErrorAlert = (props: IAlertParams) => <Alert type={alertType.danger} {...props} />
export const WarningAlert = (props: IAlertParams) => <Alert type={alertType.warning} {...props} />
export const InfoAlert = (props: IAlertParams) => <Alert type={alertType.info} {...props} />

export interface IAlertType extends IAlertParams {
  type: alertType
}

export interface IAlertParams {
  message?: React.ReactElement | string
  centeredText?: boolean
  isDismissible?: boolean
  onDismiss?: any
  children?: React.ReactNode
  cardAlert?: boolean
}

export function Alert({ type, message, centeredText, isDismissible, onDismiss, cardAlert, children }: IAlertType) {
  if (!type || (!message && !children)) return null

  let className: string = `alert mb-0 alert-${type} ${cardAlert ? 'card-alert' : ''}`
  if (centeredText) className = className + ' text-center'
  if (isDismissible) className = className + ' alert-dismissible'

  return (
    <div className={className + ' mb-3'} role="alert">
      {!!isDismissible && onDismiss && <button type="button" className="btn-close" onClick={onDismiss} />}
      {type === alertType.warning && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M12 9v2m0 4v.01" />
          <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" />
        </svg>
      )}
      {type === alertType.danger && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="12" r="9" />
          <line x1="12" y1="8" x2="12" y2="12" />
          <line x1="12" y1="16" x2="12.01" y2="16" />
        </svg>
      )}
      {type === alertType.info && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="12" r="9" />
          <line x1="12" y1="8" x2="12.01" y2="8" />
          <polyline points="11 12 12 12 12 16 13 16" />
        </svg>
      )}
      {type === alertType.success && (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 48 48">
          <path
            strokeWidth="3"
            stroke="#4caf50"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
          ></path>
          <path fill="#4caf50" d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"></path>
        </svg>
      )}
      &nbsp;
      {message}
      {children}
    </div>
  )
}
