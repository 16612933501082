import { gql, useQuery } from '@apollo/client'

const terminalsQuery = gql`
  query terminals {
    terminals {
      id
      name
      gps {
        coordinates
      }
      operationRadiusM
      disabled
    }
  }
`

export function useTerminalsQuery(opts?: { skip?: boolean }) {
  return useQuery<{ terminals: TerminalGpsItemType[] }>(terminalsQuery, {
    fetchPolicy: 'network-only',
    skip: opts?.skip
  })
}

export type TerminalGpsItemType = {
  id: number
  name: string
  operationRadiusM: number
  disabled: boolean
  gps: {
    coordinates: number[]
  }
}
