import PageCard from '../../components/Cards/pageCard'
import React, { useContext, useEffect } from 'react'
import useBootstrapTableDataMapper from '../../services/bootstrapTable/useBootstrapTableDataMapper'
import { hasQueryData, isAnyQueryLoading } from '../../services/functions/queryHelpers'
import BootstrapTable from 'react-bootstrap-table-next'
import usePagingFromURLQuery from '../../services/bootstrapTable/usePagingFromURLQuery'
import useBootstrapTablePagingAndFiltration from '../../services/bootstrapTable/useBootstrapTablePagingAndFiltration'
// @ts-ignore
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import { ModalContext } from '../../components/Modal/ModalProvider'
import { pageCardHeaderButton } from '../../components/Cards/pageCardHeaderButton'
import { SupplierBranchEntity } from '../../graphql/aminTypes'
import { supplierBranchDataDefinition } from './services/supplier-branch-data-definition'
import SupplierBranchEditor from './components/SupplierBranchEditor/SupplierBranchEditor'
import { SelectedSupplierModalContext } from '../SelectedSupplierModal/SelectedSupplierModal'
import { useSupplierBranchListQuery } from './queries/supplier-branch-list.query'
import { toast } from 'react-toastify'
import { useSupplierSupplierByIdQuery } from './queries/supplier-supplier-by-id.query'
import { getParamFromUrl } from '../../services/functions/getParamFromUrl'
import { useNavigate } from 'react-router-dom'
import { useDuplicateSupplierBranchMutation } from './mutations/duplicate-supplier-branch.mutation'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import { useDeleteSupplierBranchMutation } from './mutations/delete-supplier-branch.mutation'

export default function SupplierBranchList() {
  const { showModal, hideModal } = useContext(ModalContext)
  const navigate = useNavigate()
  const { generateColumnListForBootstrapTable, mapDataArrayToTable, nameForGqlTranslation, defaultSort } =
    useBootstrapTableDataMapper<SupplierBranchEntity>(supplierBranchDataDefinition)
  const [duplicateSupplierBranchMut, duplicateSupplierBranchMR] = useDuplicateSupplierBranchMutation()
  const [deleteSupplierBranchMut, deleteSupplierBranchMR] = useDeleteSupplierBranchMutation()
  const { pageConfiguration, gqlQueryParams } = usePagingFromURLQuery(['id', 'supplierId'], nameForGqlTranslation, {
    defaultSort,
    defaultPageSize: 100
  })
  const supplierBranchesQR = useSupplierBranchListQuery(gqlQueryParams)
  const { state, dispatch } = useContext(SelectedSupplierModalContext)
  const suppliersQR = useSupplierSupplierByIdQuery(getParamFromUrl('supplierId'), !!state.selectedSupplier)

  useEffect(() => {
    if (!state.selectedSupplier || state.selectedSupplier.id !== getParamFromUrl('supplierId')) {
      dispatch({
        type: 'SET_SELECTED_SUPPLIER',
        payload: suppliersQR.data?.supplierSupplierList.items[0]
      })
      if (suppliersQR.data?.supplierSupplierList.items[0].branches?.length === 1) {
        dispatch({
          type: 'SET_SELECTED_BRANCH',
          payload: suppliersQR.data?.supplierSupplierList.items[0].branches[0]
        })
        dispatch({
          type: 'SET_RELOAD_FUNCTION',
          payload: reloadBranch(suppliersQR.data?.supplierSupplierList.items[0].branches[0].id)
        })
        toast.success(`Pobočka ${suppliersQR.data?.supplierSupplierList.items[0].branches[0].address?.city} byla vybrána`)
      } else
        dispatch({
          type: 'SET_SELECTED_BRANCH',
          payload: undefined
        })
    }
  }, [suppliersQR.data])

  const { bootstrapTablePaginationSetup, handleTableChange } = useBootstrapTablePagingAndFiltration(
    pageConfiguration,
    undefined,
    undefined,
    supplierBranchesQR.data?.supplierBranchList.total
  )

  const columnDefinition = generateColumnListForBootstrapTable({
    filterDefaultValues: pageConfiguration.filterBy,
    currentSort: pageConfiguration.sort,
    columnAction: handleTableClick,
    handleTableChange: handleTableChange,
    actionsReference: {
      editBranch: editBranchHandler,
      showOnMap: showOnMapHandler,
      duplicateBranch: duplicateBranchHandler,
      deleteSupplierBranch: deleteSupplierBranchHandler
    }
  })

  let supplierBranches: any[] = []
  if (supplierBranchesQR.data && hasQueryData(supplierBranchesQR)) supplierBranches = mapDataArrayToTable(supplierBranchesQR.data.supplierBranchList.items)

  useEffect(() => {
    if (supplierBranchesQR.data?.supplierBranchList.total === 1) {
      dispatch({
        type: 'SET_SELECTED_BRANCH',
        payload: supplierBranchesQR.data?.supplierBranchList.items[0]
      })
      dispatch({
        type: 'SET_RELOAD_FUNCTION',
        payload: reloadBranch(supplierBranchesQR.data?.supplierBranchList.items[0].id)
      })
    }
  }, [supplierBranchesQR.data])
  return (
    <PageCard
      showLoader={isAnyQueryLoading(supplierBranchesQR, duplicateSupplierBranchMR, deleteSupplierBranchMR)}
      headerTitle={`Pobočky dodavatele ${state.selectedSupplier?.name ?? ''}`}
      cardOptions={
        <>
          <span>
            {pageCardHeaderButton(
              'Přidat',
              () =>
                showModal({
                  title: 'Přidat pobočku',
                  hideFooter: true,
                  modalContent: <SupplierBranchEditor exitHandler={hideModal} />
                }),
              'AddBranch'
            )}
          </span>
        </>
      }
    >
      <div className="table-responsive-xl">
        <BootstrapTable
          bootstrap4
          striped
          hover
          condensed
          remote
          classes="table-responsive-lg digiTable"
          noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné záznamy"</>}
          keyField="id"
          data={supplierBranches}
          columns={columnDefinition}
          filter={filterFactory()}
          sort={pageConfiguration.sort}
          onTableChange={handleTableChange}
        />
      </div>
    </PageCard>
  )

  function handleTableClick(e: Event, column: any, columnIndex: number, row: any) {
    if (columnIndex <= 7) {
      const branch = supplierBranchesQR.data?.supplierBranchList.items.find(x => x.id === row.id)
      dispatch({
        type: 'SET_SELECTED_BRANCH',
        payload: branch
      })
      dispatch({
        type: 'SET_RELOAD_FUNCTION',
        payload: reloadBranch(row.id)
      })
      toast.success(`Pobočka ${branch?.address?.city} byla vybrána`)
    }
  }

  function reloadBranch(branchId: string) {
    return async () => {
      const data = await supplierBranchesQR.refetch({ id: branchId })
      dispatch({
        type: 'SET_SELECTED_BRANCH',
        payload: data.data?.supplierBranchList.items[0]
      })
    }
  }

  function editBranchHandler(row: any) {
    if (!row.id) return
    showModal({
      title: 'Upravit ' + row.address,
      hideFooter: true,
      modalContent: <SupplierBranchEditor supplierBranchId={row.id} exitHandler={hideModal} />
    })
  }

  function showOnMapHandler(row: any) {
    if (!row.id) return
    navigate('/configuration/supplier-map?supplierBranchId=' + row.id)
  }

  function duplicateBranchHandler(row: any) {
    if (!row.id) return
    const promise = duplicateSupplierBranchMut({ variables: { branchId: row.id } })
    return processPageOperation({
      promise: promise,
      successMessage: 'Pobočka byla duplikována'
    })
  }
  function deleteSupplierBranchHandler(row: any) {
    if (!row.id) return
    if (!window.confirm(`Opravdu chcete smazat pobočku ${row.address}?`)) return
    const promise = deleteSupplierBranchMut({ variables: { branchId: row.id } })
    return processPageOperation({
      promise: promise,
      successMessage: 'Pobočka byla smazána'
    })
  }
}
