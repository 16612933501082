import { gql, useQuery } from '@apollo/client'
import { OrderCommentsEntity } from '../../../graphql/aminTypes'

export const getOrderCommentsQuery = gql`
  query getOrderComments($orderId: Int!) {
    getOrderComments(orderId: $orderId) {
      id
      comment
      createdAt
      login {
        id
        firstname
        lastname
      }
    }
  }
`

export function useGetOrderComments(orderId?: number) {
  return useQuery<{ getOrderComments: [OrderCommentsEntity] }>(getOrderCommentsQuery, {
    fetchPolicy: 'network-only',
    skip: !orderId,
    variables: {
      orderId: orderId
    }
  })
}
