import { gql, useQuery } from '@apollo/client'
import { contextEndpoint, EndpointEnum } from '../../../services/appConfiguration/contextEndpoint'

const deliveryProductsForLocalityQuery = gql`
  query deliveryProductsForLocality($locality: GpsCoordinations!) {
    deliveryProductsForLocality(locality: $locality) {
      id
      tonToCubes
    }
  }
`

export function useDeliveryProductsForLocalityQuery(lng: string | undefined, lat: string | undefined, skip: boolean) {
  return useQuery<{ deliveryProductsForLocality: { id: number; tonToCubes: number }[] }>(deliveryProductsForLocalityQuery, {
    skip: !lng || !lat || skip,
    context: contextEndpoint(EndpointEnum.public),
    variables: {
      locality: {
        lng,
        lat
      }
    }
  })
}
