import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../services/types/gqlTypes'

const deleteSupplierPriceListMutation = gql`
  mutation deleteSupplierPriceList($supplierPriceListId: Int!) {
    deleteSupplierPriceList(supplierPriceListId: $supplierPriceListId) {
      status {
        reason
        result
      }
    }
  }
`

export function useDeleteSupplierPriceListMutation() {
  return useMutation<{ deleteSupplierPriceList: IMutationResult }>(deleteSupplierPriceListMutation)
}
