import { FormProvider, useForm } from 'react-hook-form'
import ToggleField from '../../../components/formComponents/ToggleField'
import React from 'react'
import SelectField from '../../../components/formComponents/SelectField'
import { PaymentMethod, paymentMethodsOptions } from '../../../services/types/payment-method'

export default function AllowedPaymentMethodsEditor({
  b2bCustomerId,
  handleFormSubmit,
  primaryPaymentMethod,
  allowedPaymentsMethods
}: {
  b2bCustomerId: number
  handleFormSubmit: any
  allowedPaymentsMethods: PaymentMethod[]
  primaryPaymentMethod: string
}) {
  const formMethods = useForm<any>({
    defaultValues: {
      primaryPaymentMethod,
      ...Object.fromEntries(paymentMethodsOptions.map(paymentType => [paymentType.value, allowedPaymentsMethods.includes(paymentType.value)]))
    }
  })
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(data => handleFormSubmit(b2bCustomerId, data))} autoComplete={'off'}>
        <fieldset className="form-fieldset">
          {paymentMethodsOptions.map((paymentType, index) => {
            return (
              <div className="row mt-3" key={paymentType.value}>
                <div className="col-12">
                  <ToggleField name={paymentType.value} label={paymentType.label} />
                </div>
              </div>
            )
          })}
        </fieldset>
        <fieldset className="form-fieldset">
          <SelectField optionsData={paymentMethodsOptions} name="primaryPaymentMethod" label={'Primární platební metoda'} />
        </fieldset>
        <div className="modal-footer">
          <button type="submit" className="btn btn-primary">
            Uložit změny
          </button>
        </div>
      </form>
    </FormProvider>
  )
}
