import { gql, useQuery } from '@apollo/client'
import { GetAvailableServicesForLocalityResponse, PriceCalculatorResponse } from '../../../graphql/aminTypes'

export const getAvailableServicesForLocalityQuery = gql`
  query getAvailableServicesForLocality($customerLatLng: LatLngInput!, $radiusKm: Int!, $calculationDate: String!) {
    getAvailableServicesForLocality(customerLatLng: $customerLatLng, radiusKm: $radiusKm, calculationDate: $calculationDate) {
      availableServices
      supplierBranches {
        id
        supplier {
          id
          name
        }
        branchServices {
          id
          serviceCategory
        }
        gps {
          lat
          lng
        }
      }
    }
  }
`

export function useGetAvailableServicesForLocalityQuery(variables: {
  customerLatLng: { lat: number; lng: number } | undefined
  radiusKm: number
  calculationDate: string
}) {
  return useQuery<{ getAvailableServicesForLocality: GetAvailableServicesForLocalityResponse }>(getAvailableServicesForLocalityQuery, {
    fetchPolicy: 'network-only',
    skip: !variables.customerLatLng,
    variables: {
      customerLatLng: variables.customerLatLng,
      radiusKm: variables.radiusKm,
      calculationDate: variables.calculationDate
    }
  })
}
