import { MuiScopedContainer } from '../../../../components/mui-react-table/MuiScopedContainer'
import React from 'react'
import { MaterialReactTable, MRT_Row, MRT_TableOptions } from 'material-react-table'
import { useGetTerminalFeeSettings } from './queries/get-terminal-fee-settings'
import { useUpdateTerminalFeeSettings } from './mutations/update-terminal-fee-settings'
import { useTerminalFeeSettingsTableDefinition } from './services/terminal-fee-settings-table-definition'
import LoadingContainer from '../../../../components/LoadingContainer'
import { isAnyQueryLoading } from '../../../../services/functions/queryHelpers'
import { Box, Button, IconButton, Tooltip } from '@mui/material'

import { TerminalFeeSettingsWithId } from './services/terminal-fee-settings-with-id.type'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import dayjs from 'dayjs'

export default function TerminalFeeEditor({ onExit }: { onExit: () => void }) {
  const terminalFeeQR = useGetTerminalFeeSettings()
  const columnDefinition = useTerminalFeeSettingsTableDefinition()
  const [updateTerminalFeeSettingsMut, updateTerminalFeeSettingsMR] = useUpdateTerminalFeeSettings()
  const [tableData, setTableData] = React.useState<TerminalFeeSettingsWithId[]>([])
  React.useEffect(() => {
    if (terminalFeeQR.data) {
      setTableData(
        terminalFeeQR.data.getTerminalFeeSettings.map((x, index) => ({
          id: `${index}`,
          validFrom: x.validFrom ? new Date(x.validFrom) : null,
          validTo: x.validTo ? new Date(x.validTo) : null,
          legalFeeBase: x.legalFeeBase,
          legalFeeLower: x.legalFeeLower,
          legalFeeHigher: x.legalFeeHigher,
          recultivationFeeBase: x.recultivationFeeBase,
          recultivationFeeHigher: x.recultivationFeeHigher
        }))
      )
    }
  }, [terminalFeeQR.data])

  const handleCreateRow: MRT_TableOptions<TerminalFeeSettingsWithId>['onCreatingRowSave'] = async ({ values, table }) => {
    setTableData(prevState => [
      ...prevState,
      {
        ...values,
        legalFeeBase: parseInt(values.legalFeeBase),
        legalFeeLower: parseInt(values.legalFeeLower),
        legalFeeHigher: parseInt(values.legalFeeHigher),
        recultivationFeeBase: parseInt(values.recultivationFeeBase),
        recultivationFeeHigher: parseInt(values.recultivationFeeHigher),
        id: `${prevState.length}`
      }
    ])
    table.setCreatingRow(null) //exit creating mode
  }
  const handleSaveEditingRow: MRT_TableOptions<TerminalFeeSettingsWithId>['onEditingRowSave'] = async ({ values, table, row }) => {
    setTableData(prev => {
      const newData = [...prev]
      newData[row.index] = {
        ...values,
        legalFeeBase: parseInt(values.legalFeeBase),
        legalFeeLower: parseInt(values.legalFeeLower),
        legalFeeHigher: parseInt(values.legalFeeHigher),
        recultivationFeeBase: parseInt(values.recultivationFeeBase),
        recultivationFeeHigher: parseInt(values.recultivationFeeHigher)
      }
      return newData
    })
    table.setEditingRow(null) //exit creating mode
  }
  const deleteSettingRow = (row: MRT_Row<TerminalFeeSettingsWithId>) => {
    if (window.confirm('Opravdu smazatu tento řádek s cenovým nastavením?')) {
      setTableData(prevState => prevState.filter(x => x.id !== row.id))
    }
  }

  return (
    <LoadingContainer showLoader={isAnyQueryLoading(updateTerminalFeeSettingsMR, terminalFeeQR)}>
      <MuiScopedContainer>
        <MaterialReactTable
          columns={columnDefinition}
          data={tableData}
          getRowId={row => row.id}
          enableEditing={true}
          createDisplayMode="row"
          editDisplayMode="row"
          enableBottomToolbar={false}
          onCreatingRowSave={handleCreateRow}
          onEditingRowSave={handleSaveEditingRow}
          initialState={{
            density: 'compact',
            sorting: [{ id: 'validFrom', desc: false }],
            pagination: { pageIndex: 0, pageSize: 25 }
          }}
          renderTopToolbarCustomActions={({ table }) => (
            <Button
              variant="contained"
              onClick={() => {
                table.setCreatingRow(true)
              }}
            >
              Přidat nový záznam
            </Button>
          )}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip title="Edit">
                <IconButton onClick={() => table.setEditingRow(row)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton color="error" onClick={() => deleteSettingRow(row)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        />
      </MuiScopedContainer>
      <div className="row mb-2">
        <div className="col-md-3 offset-md-7">
          <div className="text-right mt-4">
            <button type="button" className="btn btn-red ms-auto btn-lg" onClick={saveSettings}>
              Uložit nastavení
            </button>
          </div>
        </div>
        <div className="col-md-2">
          <div className="text-right mt-4">
            <button type="button" className="btn btn-primary ms-auto btn-lg" onClick={onExit}>
              Zpět
            </button>
          </div>
        </div>
      </div>
    </LoadingContainer>
  )

  function saveSettings() {
    const promise = updateTerminalFeeSettingsMut({
      variables: {
        feeIntervals: tableData.map(x => ({
          validFrom: x.validFrom ? dayjs(x.validFrom).format('YYYY-MM-DD') : null,
          validTo: x.validTo ? dayjs(x.validTo).format('YYYY-MM-DD') : null,
          legalFeeBase: x.legalFeeBase,
          legalFeeLower: x.legalFeeLower,
          legalFeeHigher: x.legalFeeHigher,
          recultivationFeeBase: x.recultivationFeeBase,
          recultivationFeeHigher: x.recultivationFeeHigher
        }))
      }
    })

    processPageOperation({
      promise: promise,
      successMessage: 'Nastavení bylo uloženo',
      successAction: onExit
    })
  }
}
