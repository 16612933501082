import { noFiltrationId } from './columnFilterFactory'
import { IStringObject } from '../types/IStringObject'
import { IReactSelectItem } from '../types/ReactSelect'
import { useState } from 'react'
import { isAnyQueryLoading } from '../functions/queryHelpers'

export enum predefinedFilterOptions {
  packetState,
  countries,
  carriers
}

export type filterOptionsCreatorType = {
  addFilterOption: (
    name: string,
    options: IStringObject<any>[],
    valueSelector?: Function,
    labelSelector?: Function,
    allTranslationKey?: string,
    addAllOptions?: boolean
  ) => void
  getFilterOption: (name: string) => IReactSelectItem[]
  loading: boolean
}

export function useFilterOptions4CustomSelectCreator(
  loadDefaultFilters: { type: predefinedFilterOptions; columnName: string }[] = []
): filterOptionsCreatorType {
  const [filterOptions, setFilterOptions] = useState<IStringObject<IReactSelectItem[]>>({})

  return { addFilterOption, getFilterOption, loading: isAnyQueryLoading() }

  function addFilterOption(
    name: string,
    options: IStringObject<any>[],
    valueSelector?: Function,
    labelSelector?: Function,
    allTranslationKey = 'Vše',
    addAllOptions = true
  ) {
    if (filterOptions[name]) return

    const optionsList: IReactSelectItem[] = []
    if (addAllOptions) optionsList.push({ value: noFiltrationId, label: allTranslationKey })

    for (const item of options) {
      optionsList.push({
        value: valueSelector ? valueSelector(item) : item['value'],
        label: labelSelector ? labelSelector(item) : item['label']
      })
    }
    setFilterOptions(x => ({ ...x, [name]: optionsList }))
  }

  function getFilterOption(name: string) {
    return filterOptions[name]
  }
}

export interface IFilterOptionsCreator {
  addFilterOption(
    name: string,
    options: IStringObject<any>[],
    keySelector: Function,
    valueSelector: Function,
    allTranslationKey?: string,
    addAllOptions?: boolean
  ): void

  getFilterOption(name: string): IStringObject<string> | IReactSelectItem[]
}
