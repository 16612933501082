import { gql, useQuery } from '@apollo/client'

const statisticsSuppliersQuery = gql`
  query statisticsSuppliers($fromDate: String, $toDate: String){
    statisticsSuppliers(fromDate: $fromDate, toDate: $toDate){
      count
      name
    }
  }
`

export function useStatisticsSuppliers(params?: {fromDate?: string, toDate?: string}) {
  return useQuery<{ statisticsSuppliers: StatisticsSuppliersType[] }>(statisticsSuppliersQuery, {variables: params})
}

export type StatisticsSuppliersType = {
  name: string
  count: number
}
