import { gql, useMutation } from '@apollo/client'
import {IMutationResult} from "../../../../services/types/gqlTypes";

const updateSupplierPriceListMutation = gql`
  mutation updateSupplierDisabled($supplierPriceListId: Int!, $data: SupplierPriceListDataInput!) {
    updateSupplierPriceList(supplierPriceListId: $supplierPriceListId, data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpdateSupplierPriceListMutation() {
  return useMutation<{ updateSupplierPriceList: IMutationResult }>(updateSupplierPriceListMutation)
}
