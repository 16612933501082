import { gql, useQuery } from '@apollo/client'
import { OrderContentStateHistory } from '../../../../../graphql/aminTypes'

export const orderContentStateHistoryQuery = gql`
  query orderContentStateHistory($orderContentId: Int!) {
    orderContentStateHistory(orderContentId: $orderContentId) {
      id
      nextState {
        id
        name
        sysName
      }
      stateReason
      timestamp
      supplier {
        id
        name
      }
    }
  }
`

export function useOrderContentStateHistoryQuery(orderContentId?: number) {
  return useQuery<{ orderContentStateHistory: OrderContentStateHistory[] }>(orderContentStateHistoryQuery, {
    skip: !orderContentId,
    fetchPolicy: 'network-only',
    variables: { orderContentId: orderContentId }
  })
}
