import { gql, useQuery } from '@apollo/client'
import { OrderContentListResult } from '../../../../graphql/aminTypes'

const orderList = gql`
  query orderContentList($offset: Int, $limit: Int, $orderNumber: String) {
    orderContentList(offset: $offset, limit: $limit, orderNumber: $orderNumber) {
      total
      items {
        id
        orderContentState {
          id
          name
        }

        order {
          id
          orderNumber
          companyName
          firstname
          lastname
          createdAt
          totalInclVatKc
          paymentStatus
          paymentType
        }
      }
    }
  }
`

export function useOrderListForTransactionMatchingAutocompleteQuery() {
  return useQuery<{ orderContentList: OrderContentListResult }>(orderList, {
    fetchPolicy: 'network-only',
    skip: true
  })
}
