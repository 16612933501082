import { gql, useQuery } from '@apollo/client'
import { IGqlPagingQueryParams } from '../../../services/bootstrapTable/usePagingFromURLQuery'
import { contextEndpoint, EndpointEnum } from '../../../services/appConfiguration/contextEndpoint'
import { OrderContentListResult } from '../../../graphql/supplierTypes'

export const orderContentListQuery = gql`
  query orderContentList(
    $offset: Int
    $limit: Int
    $order: String
    $orderNumber: String
    $serviceType: ServiceTypeEnum
    $name: String
    $city: String
    $state: String
    $containerFromDate: String
    $containerToDate: String
  ) {
    orderContentList(
      offset: $offset
      limit: $limit
      order: $order
      orderNumber: $orderNumber
      serviceType: $serviceType
      city: $city
      name: $name
      state: $state
      containerFromDate: $containerFromDate
      containerToDate: $containerToDate
    ) {
      items {
        id
        codToCollect
        container {
          id
          name
          capacityM3
        }
        containerFromDate
        arriveTime
        collectionTime
        onsiteContactFirstname
        onsiteContactLastname
        onsiteContactPhone
        containerToDate
        distanceTotalKm
        supplierProfitWoVatKc
        supplierNote
        serviceType
        weightTonnes
        containerCount
        product {
          id
          name
        }
        terminal {
          id
          name
        }
        supplier {
          id
          name
        }
        orderContentState {
          id
          sysName
          nameForSupplier
        }
        order {
          id
          uuid
          createdAt
          orderNumber
          firstname
          lastname
          email
          phone
          street
          streetNumber
          city
          zip
          paymentType
          orderNote
          ico
          dic
          gps {
            coordinates
          }
        }
      }
      total
    }
  }
`

export function useOrderListQuery(params: IGqlPagingQueryParams) {
  return useQuery<{ orderContentList: OrderContentListResult }>(orderContentListQuery, {
    fetchPolicy: 'network-only',
    variables: params,
    context: contextEndpoint(EndpointEnum.supplier)
  })
}
