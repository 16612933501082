import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'

const createContainerMutation = gql`
  mutation createContainer($data: ContainerInput!) {
    createContainer(data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useCreateContainerMutation() {
  return useMutation<{ createMutation: IMutationResult }>(createContainerMutation)
}

export function createParamsForCreateContainerMutation(data: any) {
  return {
    data: {
      name: data.name,
      capacityM3: parseFloat(data.capacityM3),
      tooltip: data.tooltip,
      uiOrder: parseInt(data.uiOrder),
      uiPicture: data.uiPicture,
      offerOnUiAsDefault: data.offerOnUiAsDefault,
      priceFrom: parseFloat(data.priceFrom),
      disabled: data.disabled,
      isForPublic: data.isForPublic
    }
  }
}
