import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { IStringObject } from '../../services/types/IStringObject'
import classNames from 'classnames'

export default function NavItem({
  name,
  link,
  className,
  hideMenu,
  reactIcon,
  enhanced
}: {
  name: string
  link: string
  className?: string
  hideMenu: Function
  reactIcon?: React.ReactNode
  enhanced?: boolean
}) {
  const location = useLocation()
  const navigate = useNavigate()
  const isActive = link.startsWith(location.pathname)
  return (
    <li className={classNames('nav-item', isActive ? 'active' : '')}>
      <a className={'nav-link'} onClick={goToLink} href={link}>
        <span className="nav-link-icon d-md-none d-lg-inline-block">
          {className && <i className={'fe ' + className}></i>}
          {reactIcon && reactIcon}
        </span>
        <span className={`nav-link-title ${enhanced ? 'stronger' : ''}`}>{name}</span>
      </a>
    </li>
  )

  function goToLink(e: any) {
    e.preventDefault()
    if (hideMenu) hideMenu()
    if (isActive) {
      navigate('/reload', { replace: true })
      setTimeout(() => {
        navigate(link, { replace: true })
      })
    } else {
      navigate(link)
    }
  }
}
