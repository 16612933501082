import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../../services/types/gqlTypes'

const createWasteCollectionLinkMutation = gql`
  mutation createWasteCollectionLink($branchServiceId: ID!, $terminalBranchId: ID!, $wasteCategory: WasteCategory!) {
    createWasteCollectionLink(branchServiceId: $branchServiceId, terminalBranchId: $terminalBranchId, wasteCategory: $wasteCategory) {
      status {
        reason
        result
      }
    }
  }
`

export function useCreateWasteCollectionLinkMutation() {
  return useMutation<{ createWasteCollectionLink: IMutationResult }>(createWasteCollectionLinkMutation, { refetchQueries: ['supplierWasteCollectionLinks'] })
}
