import {gql, useQuery} from '@apollo/client'

export const productsQuery = gql`
    query products {
        products {
            id
            name
            tonToCubes
        }
    }
`

export type ProductItem = {
    id: number
    name: string
    tonToCubes: number
}

export function useProducts() {
    return useQuery<{ products: ProductItem[] }>(productsQuery)
}
