import PageCard from '../../components/Cards/pageCard'
import React, { useContext, useState } from 'react'
import useBootstrapTableDataMapper from '../../services/bootstrapTable/useBootstrapTableDataMapper'
import { hasQueryArrayData, isAnyQueryLoading } from '../../services/functions/queryHelpers'
import BootstrapTable from 'react-bootstrap-table-next'
import usePagingFromURLQuery from '../../services/bootstrapTable/usePagingFromURLQuery'
import useBootstrapTablePagingAndFiltration from '../../services/bootstrapTable/useBootstrapTablePagingAndFiltration'
// @ts-ignore
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import { BootstrapTablePagingWrapper } from '../../components/bootstrapTable/BootstrapTablePagingWrapper'
import { ProductsType, useProductsQuery } from './services/productsQuery'
import { useProductsCountQuery } from './services/productsCountQuery'
import { productsDeliveryDataDefinition, productsCollectionDataDefinition } from './services/productsDataDefinition'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import { ModalContext } from '../../components/Modal/ModalProvider'
import { useFilterOptions4CustomSelectCreator } from '../../services/bootstrapTable/useFilterOptions4CustomSelectCreator'
import { AddOrEditProduct } from './components/AddOrEditProduct/AddOrEditProduct'
import { pageCardHeaderButton } from '../../components/Cards/pageCardHeaderButton'
import { useDeleteProductMutation } from './services/deleteProductMutation'
import { Link } from 'react-router-dom'
import AddOrEditMaterial from './components/AddOrEditMaterial/AddOrEditMaterial'

export default function ProductList() {
  const { showModal, hideModal } = useContext(ModalContext)
  const [isDelivery, setIsDelivery] = useState(false)
  const [deleteProductMut, deleteProductMR] = useDeleteProductMutation()
  const { generateColumnListForBootstrapTable, mapDataArrayToTable, nameForGqlTranslation } = useBootstrapTableDataMapper<ProductsType>(
    isDelivery ? productsDeliveryDataDefinition : productsCollectionDataDefinition
  )
  const { pageConfiguration, gqlQueryParams } = usePagingFromURLQuery(
    ['id', 'name', 'isCollection', 'isDelivery', 'isActive', 'wasteCode'],
    nameForGqlTranslation,
    { defaultPageSize: 100 }
  )
  const productsEditorQR = useProductsQuery(gqlQueryParams)
  const productsEditorCountQR = useProductsCountQuery(gqlQueryParams)
  const { bootstrapTablePaginationSetup, handleTableChange } = useBootstrapTablePagingAndFiltration(pageConfiguration, productsEditorCountQR)
  const filterOptions = useFilterOptions4CustomSelectCreator()
  filterOptions.addFilterOption(
    'isActive',
    [
      { key: 'false', value: 'Aktivní' },
      { key: 'true', value: 'Neaktivní' }
    ],
    (item: any) => item.key,
    (item: any) => item.value
  )

  const columnDefinition = generateColumnListForBootstrapTable({
    filterOptions4CustomSelectCreator: filterOptions,
    filterDefaultValues: pageConfiguration.filterBy,
    currentSort: pageConfiguration.sort,
    handleTableChange: handleTableChange,
    actionsReference: {
      deleteProduct: handleProductDelete,
      editProduct: handleProductEdit
    }
  })

  let productsEditorData: any[] = []

  if (productsEditorQR.data && hasQueryArrayData(productsEditorQR)) productsEditorData = mapDataArrayToTable(productsEditorQR.data.products)

  return (
    <PageCard
      showLoader={isAnyQueryLoading(productsEditorQR, productsEditorCountQR, deleteProductMR)}
      headerTitle={isDelivery ? 'Seznam produktů - rozvoz materiálu' : 'Seznam produktů - odvoz odpadu'}
      cardOptions={
        <>
          {isDelivery && (
            <span className="mr-3">
              {pageCardHeaderButton(
                'Materiály',
                () =>
                  showModal({
                    title: 'Materiály',
                    modalContent: <AddOrEditMaterial />,
                    hideFooter: true
                  }),
                'ShowMaterials'
              )}
            </span>
          )}
          <span>
            {pageCardHeaderButton(
              'Přidat',
              () =>
                showModal({
                  title: 'Přidat',
                  modalContent: <AddOrEditProduct isAddDeliveryProductsPopup={isDelivery} hideModal={hideModal} />,
                  hideFooter: true
                }),
              'AddProduct'
            )}
            <span className="ml-3">
              <Link to={isDelivery ? '/product-list?isCollection=true' : '/product-list?isDelivery=true'}>
                {pageCardHeaderButton(`Přepnout na produkty pro ${isDelivery ? 'odvoz' : 'návoz'}`, () => setIsDelivery(prev => !prev), 'switchMat')}
              </Link>
            </span>
          </span>
        </>
      }
    >
      <PaginationProvider pagination={paginationFactory(bootstrapTablePaginationSetup)}>
        {({ paginationProps, paginationTableProps }: { paginationProps: any; paginationTableProps: any }) => (
          <div className="table-responsive-xl">
            <BootstrapTablePagingWrapper paginationProps={paginationProps} paginationTableProps={paginationTableProps}>
              <BootstrapTable
                bootstrap4
                striped
                hover
                condensed
                remote
                classes="table-responsive-lg digiTable"
                noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné záznamy"</>}
                keyField="id"
                data={productsEditorData}
                columns={columnDefinition}
                filter={filterFactory()}
                filterPosition={'inline'}
                sort={pageConfiguration.sort}
                onTableChange={handleTableChange}
                {...paginationTableProps}
              />
            </BootstrapTablePagingWrapper>
          </div>
        )}
      </PaginationProvider>
    </PageCard>
  )

  function handleProductDelete(row: any) {
    if (!row.id) return
    if (window.confirm(`Opravdu smazat produkt ${row.name}?`)) {
      const deleteProductPromise = deleteProductMut({ variables: { productId: row.id } })
      processPageOperation({
        promise: deleteProductPromise,
        successMessage: `Produkt '${row.name}' byl odstraněn.`,
        successAction: result => {
          productsEditorQR.refetch().then(r => productsEditorCountQR.refetch())
        }
      })
    }
  }

  function handleProductEdit(row: any) {
    if (!row.id) return
    showModal({
      title: 'Upravit ' + row.name,
      hideFooter: true,
      modalContent: <AddOrEditProduct isAddDeliveryProductsPopup={isDelivery} hideModal={hideModal} productId={row.id} />
    })
  }
}
