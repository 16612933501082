import PageCard from '../../components/Cards/pageCard'
import React from 'react'
import useBootstrapTableDataMapper from '../../services/bootstrapTable/useBootstrapTableDataMapper'
import { hasQueryData, isAnyQueryLoading } from '../../services/functions/queryHelpers'
import BootstrapTable from 'react-bootstrap-table-next'
import { useGetResponsesQuery } from './services/getResponsesQuery'
import { customResponsesDataDefinition, responseDataDefinition } from './services/responseDataDefinition'
import { useDownloadExport } from '../../hooks/useDownloadExport'
import { PriceListsExport, SuppliersExport } from '../SupplierList/components/export-buttons'
import { pageCardHeaderButton } from '../../components/Cards/pageCardHeaderButton'
import { ResponseExport } from './components/export-buttons'

export default function ResponseList() {
  const { generateColumnListForBootstrapTable, mapDataArrayToTable } = useBootstrapTableDataMapper(responseDataDefinition)
  const customStats = useBootstrapTableDataMapper(customResponsesDataDefinition)
  const download = useDownloadExport()
  const responsesQR = useGetResponsesQuery()

  const columnDefinition = generateColumnListForBootstrapTable({})
  const columnDefinition2 = customStats.generateColumnListForBootstrapTable({})

  let responsesData: any[] = []
  let customResponsesData: any[] = []
  if (responsesQR.data && hasQueryData(responsesQR)) {
    responsesData = mapDataArrayToTable(responsesQR.data.getResponses.stats)
    customResponsesData = customStats.mapDataArrayToTable(responsesQR.data.getResponses.customResponses)
  }

  return (
    <PageCard showLoader={isAnyQueryLoading(responsesQR)} headerTitle={'Odpovědi uživatelů'} cardOptions={<ResponseExport download={download} />}>
      <div className="table-responsive-xl">
        <BootstrapTable
          bootstrap4
          striped
          hover
          condensed
          classes="table-responsive-lg digiTable"
          noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné záznamy"</>}
          keyField="id"
          data={responsesData}
          columns={columnDefinition}
          defaultSorted={[{ dataField: 'count', order: 'desc' }]}
        />
      </div>
      <h3>Vlastní odpovědi</h3>
      <div className="table-responsive-xl">
        <BootstrapTable
          bootstrap4
          striped
          hover
          condensed
          classes="table-responsive-lg digiTable"
          noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné záznamy"</>}
          keyField="id"
          data={customResponsesData}
          columns={columnDefinition2}
          defaultSorted={[{ dataField: 'count', order: 'desc' }]}
        />
      </div>
    </PageCard>
  )
}
