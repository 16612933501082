import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const runOrderOperationMutation = gql`
  mutation runOrderOperation($orderId: Int, $operation: orderOperationEnum!) {
    runOrderOperation(orderId: $orderId, operation: $operation) {
      status {
        reason
        result
      }
    }
  }
`

export function useRunOrderOperationMutation() {
  return useMutation<{ runOrderOperation: IMutationResult }>(runOrderOperationMutation)
}

export function createParamsForRunOrderOperationMutation(orderId: number, operation: runOrderOperationEnum) {
  return { orderId, operation }
}

export enum runOrderOperationEnum {
  'CERTIFICATE_DONE' = 'CERTIFICATE_DONE',
  'UNDO_CERTIFICATE_DONE' = 'UNDO_CERTIFICATE_DONE',
  'CREATE_RECEIPT' = 'CREATE_RECEIPT',
  'INVOICING_NOTE_DONE_TRUE' = 'INVOICING_NOTE_DONE_TRUE',
  'INVOICING_NOTE_DONE_FALSE' = 'INVOICING_NOTE_DONE_FALSE',
}
