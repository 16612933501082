import { MRT_ColumnDef } from 'material-react-table'
import { MissingConfigurationType, SupplierBranchEntity } from '../../../graphql/aminTypes'
import { serviceCategoryTranslation } from '../../../services/types/service-category.type'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export function useMissingSupplierConfigurationDataDefinition(): MRT_ColumnDef<MissingConfigurationType, any>[] {
  let navigate = useNavigate()
  return [
    {
      header: 'Dodavatel',
      accessorKey: 'supplierBranch.supplier.name'
    },
    {
      header: 'Pobočka',
      accessorKey: 'supplierBranch',
      enableEditing: true,
      enableSorting: false,
      maxSize: 100,
      Cell: ({ cell }) => {
        const branch: SupplierBranchEntity = cell.getValue()

        return (
          <>
            <button
              className={'btn-link'}
              type={'button'}
              onClick={() => navigate(`/configuration/supplier-branch-list?supplierId=${branch.supplier?.id}&branchId=${branch.id}`)}
            >
              {`${branch.address?.city}, ${branch.address?.street} - ${branch.specification ?? ''}`}
            </button>
            &nbsp;
            <a
              href={`/configuration/supplier-branch-list?supplierId=${branch.supplier?.id}&branchId=${branch.id}`}
              target="_blank"
              rel="noopener noreferrer"
              className="pl-4 mt-2"
            >
              <i className="fe fe-external-link" />
            </a>
          </>
        )
      }
    },
    {
      header: 'Služba',
      accessorKey: 'serviceCategory',
      Cell: ({ cell }) => serviceCategoryTranslation[cell.getValue()]
    },
    {
      header: 'Nalezený problém',
      accessorKey: 'foundProblem'
    }
  ]
}
