import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../services/types/gqlTypes'

const markSupplierAsMigratedMutation = gql`
  mutation markSupplierAsMigrated($supplierId: Int!, $newSupplierName: String) {
    markSupplierAsMigrated(supplierId: $supplierId, newSupplierName: $newSupplierName) {
      status {
        reason
        result
      }
    }
  }
`

export function useMarkSupplierAsMigratedMutation() {
  return useMutation<{ markSupplierAsMigrated: IMutationResult }>(markSupplierAsMigratedMutation, {
    refetchQueries: ['suppliersCount', 'suppliers']
  })
}
