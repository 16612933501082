import React from 'react'
import Select, { SelectInstance } from 'react-select'
import AsyncSelect from 'react-select/async'
import CreatableSelect from 'react-select/creatable'
import { IReactSelectItem } from '../../services/types/ReactSelect'
import { FormComponentBaseInput } from './components/FormComponentBaseInput'
import { IFormComponentBaseInput } from './components/IFormComponentBaseInput'
import { useFormContext } from 'react-hook-form'
import { createErrorMessages } from '../../services/formsServices/createErrorMessages'

interface ISelectFieldParams extends IFormComponentBaseInput {
  optionsData?: IReactSelectItem<any>[]
  handleChange?: Function
  isLoading?: boolean
  placeholder?: string
  noOptionsMessage?: string
  customSelectedValue?: any
  styles?: any
  isCreatable?: boolean
  isAsync?: boolean
  loadOptions?: (inputValue: string, callback: (options: IReactSelectItem<any>[]) => void) => void
  forwardRef?: any
}

export default function SelectField({
  optionsData,
  handleChange,
  isLoading,
  placeholder,
  noOptionsMessage,
  customSelectedValue,
  styles,
  ...otherOptions
}: ISelectFieldParams) {
  const { watch, setValue, register, formState } = useFormContext()

  register(otherOptions.name, createErrorMessages(otherOptions.registerOptions))
  const currentValue = watch(otherOptions.name)
  let selectedValue = customSelectedValue ?? undefined
  if (!selectedValue && !otherOptions.isCreatable && currentValue !== null && !otherOptions.isAsync && optionsData) {
    selectedValue = optionsData.find(x => x.value === currentValue)
  }
  if (!selectedValue && currentValue !== null && otherOptions.isCreatable) {
    selectedValue = { label: currentValue, value: currentValue }
  }

  const colourStyles = {
    menu: provided => ({
      ...provided,
      zIndex: 1000, // Use a high value to ensure it's on top of other components
      position: 'relative' // Ensure position is not 'static' for z-index to work
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        ...(isDisabled ? {} : { color: data.color })
      }
    }
  }

  let SelectComponent = otherOptions.isCreatable ? CreatableSelect : Select
  SelectComponent = otherOptions.isAsync ? AsyncSelect : SelectComponent

  return (
    <FormComponentBaseInput {...otherOptions}>
      <SelectComponent
        id={otherOptions.name}
        {...(otherOptions.isAsync
          ? {
              cacheOptions: true,
              loadOptions: otherOptions.loadOptions
            }
          : { options: optionsData })}
        placeholder={placeholder ?? 'Vyberte z možností'}
        noOptionsMessage={() => noOptionsMessage ?? 'Nenalezeny žádné možnosti'}
        value={selectedValue ? selectedValue : null}
        ref={otherOptions.forwardRef}
        onChange={(v: any) => {
          setValue(otherOptions.name, v ? v.value : null)
          if (handleChange) handleChange(v ? v.value : null, v ? v.data : null)
        }}
        isClearable={!otherOptions.required}
        menuPlacement="auto"
        menuPosition="fixed"
        className={`react-select ${formState.errors[otherOptions.name] ? 'is-invalid' : ''}`}
        classNamePrefix="reactSelect"
        isDisabled={otherOptions.disabled}
        isLoading={isLoading}
        styles={colourStyles}
      />
    </FormComponentBaseInput>
  )
}
