import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useCreateProductMaterialComponent } from './mutations/create-material-component.mutation'
import BootstrapTable from 'react-bootstrap-table-next'
import { productMaterialComponentDataDefinition } from './services/product-material-component-data-definition'
import cellEditFactory from 'react-bootstrap-table2-editor'
import { createParamsForUpdateMaterialMutation, useUpdateProductMaterialComponentMutation } from './mutations/update-material-component.mutation'
import { useProductMaterialComponents } from './queries/product-material-component.query'
import { ProductMaterialComponentEntity } from '../../graphql/aminTypes'
import useBootstrapTableDataMapper from '../../services/bootstrapTable/useBootstrapTableDataMapper'
import { isAnyQueryLoading } from '../../services/functions/queryHelpers'
import FormField, { inputType } from '../../components/formComponents/FormField'
import PageCard from '../../components/Cards/pageCard'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import { useDeleteProductMaterialComponentMutation } from './mutations/delete-material-component.mutation'

export default function ProductMaterialComponents() {
  const formMethods = useForm({ defaultValues: { type: 'MATERIAL' } })
  const selectedSection = formMethods.watch('type')
  const [tableData, setTableData] = useState<any>([])
  const materialsQR = useProductMaterialComponents()
  const [createMaterialComponentMut, createMaterialComponentQR] = useCreateProductMaterialComponent()
  const [updateMaterialComponentMut, updateMaterialComponentQR] = useUpdateProductMaterialComponentMutation()
  const [deleteMaterialComponentMut, deleteMaterialComponentQR] = useDeleteProductMaterialComponentMutation()

  const { generateColumnListForBootstrapTable, mapDataArrayToTable } =
    useBootstrapTableDataMapper<ProductMaterialComponentEntity>(productMaterialComponentDataDefinition)
  const columnDefinition = generateColumnListForBootstrapTable({
    actionsReference: {
      deleteMaterialComponent: deleteMaterialComponentHandler
    }
  })

  useEffect(() => {
    setTableData(mapDataArrayToTable(materialsQR.data?.productMaterialComponents.filter(x => x.type === selectedSection)))
  }, [selectedSection, materialsQR.data])

  return (
    <PageCard
      showLoader={isAnyQueryLoading(materialsQR, deleteMaterialComponentQR, updateMaterialComponentQR, createMaterialComponentQR)}
      headerTitle={'Seznam komponent pro materiály'}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleFormSubmit}>
          <div className="row">
            <div className="col">
              <span className="mr-5">
                <input type="radio" id="material" {...formMethods.register('type')} value="MATERIAL" />
                <label htmlFor="material" className="pl-2 d-inline-block strong">
                  Materiál
                </label>
              </span>
              <span className="mr-5">
                <input type="radio" id="type" {...formMethods.register('type')} value="TYPE" />
                <label htmlFor="type" className="pl-2 d-inline-block strong">
                  Typ
                </label>
              </span>
              <span className="mr-5">
                <input type="radio" id="fraction" {...formMethods.register('type')} value="FRACTION" />
                <label htmlFor="fraction" className="pl-2 d-inline-block strong">
                  Frakce
                </label>
              </span>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col col-sm-3">
              <FormField type={inputType.text} name="name" required label="Jméno" placeholder="Jméno" />
            </div>
            <div className="col col-sm-5">
              <FormField type={inputType.text} name="tooltip" label="Tooltip" placeholder="Tooltip" />
            </div>
            <div className="col col-sm-2">
              <FormField type={inputType.number} isPositive name="uiOrder" label="Pořadí na FE" placeholder="Pořadí na FE" required />
            </div>
            <div className="col col-sm-1">
              <FormField
                type={inputType.text}
                name="cubicToTonnes"
                label={
                  <span>
                    1 m<sup>3</sup>/t
                  </span>
                }
                disabled={selectedSection === 'FRACTION'}
              />
            </div>
            <div className="col col-sm-1">
              <button className="btn btn-primary mt-3">Přidat</button>
            </div>
          </div>
        </form>
      </FormProvider>
      <div className="mt-4">
        <BootstrapTable
          bootstrap4
          striped
          hover
          condensed
          classes="table-responsive-lg digiTable"
          noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné záznamy"</>}
          keyField="id"
          data={tableData ? tableData : []}
          columns={columnDefinition}
          defaultSorted={[{ dataField: 'uiOrder', order: 'asc' }]}
          cellEdit={cellEditFactory({
            mode: 'click',
            blurToSave: true,
            autoSelectText: true,
            beforeSaveCell: onCellUpdate
          })}
        />
      </div>
    </PageCard>
  )

  function handleFormSubmit(e) {
    e.preventDefault()

    let data: any = formMethods.getValues()
    data = { name: data.name, tooltip: data.tooltip, type: selectedSection, uiOrder: +data['uiOrder'], cubicToTonnes: +data['cubicToTonnes'] }
    const resultPromise = createMaterialComponentMut({ variables: { data: data } })
    processPageOperation({
      promise: resultPromise,
      successMessage: `Komponenta materiálu '${data.name}' byla vytvořena`
    })
    return false
  }

  function deleteMaterialComponentHandler(row: any) {
    const promise = deleteMaterialComponentMut({ variables: { id: row.id } })
    processPageOperation({
      promise,
      successMessage: `Komponenta materiálu '${row.name}' byla odstraněna`
    })
  }
  function onCellUpdate(oldValue, newValue, row, column) {
    if (!row.id || oldValue === newValue) return
    const resultPromise = updateMaterialComponentMut({
      variables: { id: row.id, data: createParamsForUpdateMaterialMutation(row, column.dataField, newValue) }
    })
    processPageOperation({
      promise: resultPromise,
      successMessage: `Komponenta materiálu '${row.name}' byla upravena`
    })
  }
}
