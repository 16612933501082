import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../services/types/gqlTypes'

const updateTerminalPriceListMutation = gql`
  mutation updateTerminalDisabled($terminalPriceListId: Int!, $data: TerminalPriceListDataInput!) {
    updateTerminalPriceList(terminalPriceListId: $terminalPriceListId, data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpdateTerminalPriceListMutation() {
  return useMutation<{ updateTerminalPriceList: IMutationResult }>(updateTerminalPriceListMutation)
}
