import { Column } from 'react-bootstrap-table-next'
import React from 'react'
import classNames from 'classnames'

export function headerFormatterWithCustomIcon(
) {
    return (column: Column<string>, colIndex: number, {  filterElement }: any) =>
        headerFormatterWithIcon(
            column,
            colIndex,
            {
                filterElement
            },
        )
}

function headerFormatterWithIcon(
    column: Column<string>,
    colIndex: number,
    { filterElement }: any
) {
    let orderClassName = ''
    return (
        <>
            {column.text}
            <span
                tabIndex={0}
                className={classNames(orderClassName)}
                role="button"
            />
            <i className="fe fe-edit-3"/>
            {filterElement}
        </>
    )
}
