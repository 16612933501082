import React, { useLayoutEffect, useState } from 'react'
import PageCard from '../../components/Cards/pageCard'
import { FormProvider, useForm } from 'react-hook-form'
import AddressPicker from '../../components/formComponents/AddressPicker/AddressPicker'
import FormField, { inputType } from '../../components/formComponents/FormField'
import { getParamFromUrl } from '../../services/functions/getParamFromUrl'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import { SupplierDetailItemType, useSuppliersDetailQuery } from './services/suppliersDetailQuery'
import { createParamsForCreateSupplierMutation, useCreateSupplierMutation } from './services/createSupplierMutation'
import { useUpdateSupplierMutation } from './services/updateSupplierMutation'
import SelectField from '../../components/formComponents/SelectField'
import { daysToMinutes, hoursToMinutes, priorityValues } from './services/optionValues'
import { supplierDefaultValues } from './services/supplierDefaultValues'
import ToggleField from '../../components/formComponents/ToggleField'
import { pathCalculationStrategyOptions } from '../../services/types/pathCalculationStrategyOptions'
import { InfoAlert } from '../../components/formComponents/alert'
import { useGetCompanyInfoFromAresQuery } from '../../services/queries/getCompanyInfoFromAresQuery'
import AddressPickerWrapper from '../../components/formComponents/AddressPicker/AddressPickerWrapper'
import { setupFields } from '../../services/formsServices/setupFields'
import { useReturnBackWithHighlight } from '../../services/pageHooks/highlightRowAfterReturn'
import { isAnyQueryLoading } from '../../services/functions/queryHelpers'
import SelectGroup from '../../components/formComponents/SelectGroup'
import { toast } from 'react-toastify'

export default function AddOrEditSupplier() {
  const formMethods = useForm<any>({ defaultValues: supplierDefaultValues })
  const supplierId = getParamFromUrl('supplierId') as string | null
  const isToDuplicate = getParamFromUrl('duplicate') === '1'
  const supplierDetailQR = useSuppliersDetailQuery(supplierId, loadSupplierData)
  const [createSupplierMut, createSupplierMutQR] = useCreateSupplierMutation()
  const [updateSupplierMut, updateSupplierMutQR] = useUpdateSupplierMutation()
  const companyInfoQR = useGetCompanyInfoFromAresQuery()
  const { returnBack } = useReturnBackWithHighlight('/supplier-list')
  const [disableGetDataFromAres, setDisableGetDataFromAres] = useState(false)

  useLayoutEffect(() => {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
  }, [])
  return (
    <PageCard
      headerTitle={supplierId ? 'Editace dodavatele' : isToDuplicate ? 'Duplikace dodavatele' : 'Nový dodavatel'}
      showLoader={isAnyQueryLoading(companyInfoQR, supplierDetailQR, createSupplierMutQR, updateSupplierMutQR)}
    >
      <FormProvider {...formMethods}>
        <AddressPickerWrapper instancesCount={2}>
          <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
            <h2>Dodavatel:</h2>
            <fieldset className="form-fieldset bg-lime-lt">
              <div className="row">
                <div className="col-md-3">
                  <FormField type={inputType.text} name={'companyIdentification'} label={'IČO'} />
                </div>
                <div className="col-md-3">
                  <FormField type={inputType.text} name={'companyTaxId'} label={'DIČ'} />
                </div>
                <div className="col-md-3">
                  <FormField type={inputType.number} name={'regionCode'} label={'Kód oblasti'} />
                </div>
                <div className="col-md-3">
                  <FormField type={inputType.text} name={'orpName'} label={'ORP'} />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-5">
                  <div className="row">
                    <div className="col-xl-5">
                      <button type="button" className="btn btn-primary mt-3" onClick={loadDataFromAresHandler} disabled={disableGetDataFromAres}>
                        Načíst z ARES
                      </button>
                    </div>
                    <div className="col-xl-7 mt-2">
                      <ToggleField name={'overwriteSupplierCenter'} label={'Přepsat adresu stanoviště techniky'} />
                      <br />
                      <ToggleField name={'overwriteResidence'} label={'Přepsat adresu sídla'} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-3">
                  <FormField type={inputType.text} name={'name'} label={'Jméno'} required registerOptions={{ required: true }} />
                </div>
                <div className="col-md-3">
                  <div className="mt-md-4">
                    <ToggleField name={'hasIcz'} label={'Může nakládat s odpady (má IČZ)'} />
                  </div>
                </div>
                <div className="col-md-3">
                  <FormField
                    type={inputType.text}
                    name={'iczNumber'}
                    label={'IČZ'}
                    maxLength={8}
                    disabled={!formMethods.watch('hasIcz')}
                    registerOptions={{
                      required: false,
                      maxLength: 8
                    }}
                  />
                </div>
              </div>
            </fieldset>
            <h2>Adresy:</h2>
            <fieldset className="form-fieldset bg-orange-lt">
              <h2 className="form-label required">Stanoviště techniky (bod, ke kterému se počítají vzdálenosti):</h2>
              <AddressPicker
                name={'address'}
                required
                registerOptions={{ required: 'Poloha nebyla nalezena.' }}
                className="bg-orange-lt"
                instanceName={'supplierCenter'}
              />
              <h2 className="form-label required">Adresa sídla</h2>
              <AddressPicker
                required
                registerOptions={{ required: 'Poloha nebyla nalezena.' }}
                name={'residence'}
                className="bg-orange-lt"
                instanceName={'residence'}
              />
            </fieldset>
            <h2>Detaily:</h2>
            <fieldset className="form-fieldset bg-purple-lt">
              <div className="row mb-2">
                <div className="col-md-6">
                  <FormField type={inputType.text} name={'contactPerson'} label={'Kontaktní osoba'} />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-12">
                  <FormField type={inputType.text} name={'email'} label={'E-mail/y pro zasílání nabídek (více emailů oddělte středníkem)'} />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-6">
                  <FormField type={inputType.text} name={'web'} label={'Web'} />
                </div>
                <div className="col-md-6">
                  <FormField type={inputType.text} name={'phone'} label={'Telefon'} />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-12">
                  <FormField type={inputType.text} name={'note'} label={'Poznámka'} />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-12">
                  <FormField type={inputType.text} name={'zpoNote'} label={'Poznámka k ZPO'} />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-12">
                  <ToggleField name={'priceListIsFinal'} label={'U dodavatele máme již nastaveny finální ceník (jen příznak, nemá žádnou funkci)'} />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-12">
                  <ToggleField name={'sendOrderAllEmails'} label={'Zasílat dodavateli emaily o přistavení a odvoz kontejneru v daný den'} />
                </div>
              </div>
              <div className="row mb-2">
                <SelectGroup
                  caption="Schopnosti"
                  name={'capability'}
                  options={[
                    {
                      label: 'BO',
                      value: 'BO'
                    },
                    { label: 'O', value: 'O' },
                    {
                      label: 'IČZM',
                      value: 'ICZM'
                    },
                    {
                      label: 'IČZSS',
                      value: 'ICZSS'
                    },
                    { label: 'IČZSR', value: 'ICZSR' }
                  ]}
                  type={'checkbox'}
                ></SelectGroup>
              </div>
            </fieldset>
            <h2>Ceny:</h2>
            <fieldset className="form-fieldset bg-cyan-lt">
              <div className="row mb-2">
                <div className="col-md-4">
                  <SelectField
                    name={'expressDeliveryFromMinutes'}
                    label={'Expresní doručení od'}
                    required
                    registerOptions={{ required: true }}
                    optionsData={daysToMinutes}
                  />
                </div>
                <div className="col-md-4">
                  <SelectField
                    name={'normalDeliveryFromMinutes'}
                    label={'Normální doručení od'}
                    required
                    registerOptions={{ required: true }}
                    optionsData={daysToMinutes.slice(1)}
                  />
                </div>
                <div className="col-md-4">
                  <SelectField
                    name={'deliveryDayThresholdMinutes'}
                    label={'Možný termín odvozu (express i normal) se posouvá o +1 den od dnešní [h]'}
                    required
                    registerOptions={{ required: true }}
                    optionsData={hoursToMinutes}
                  />
                </div>
              </div>
              {formMethods.watch('expressDeliveryFromMinutes') === 0 && (
                <div className="row mb-2">
                  <div className="col-md-12">
                    <InfoAlert
                      message={
                        'Pokud máte vybráno doručení ještě tento den, platí tyto pravidla: Lze objednat na dnešní čas 14-16 do 11h. Na zítra jde objednat vždy, ale od 14h už jen na časy od 10h dále.'
                      }
                    />
                  </div>
                </div>
              )}
              <div className="row mb-2">
                <div className="col-md-6">
                  <FormField
                    type={inputType.number}
                    isPositive
                    isMoney
                    name={'expressDeliveryFeeKc'}
                    label={'Příplatek za expresní doručení [Kč]'}
                    required
                    defaultValue={'0'}
                    registerOptions={{ required: true }}
                  />
                </div>
                <div className="col-md-6">
                  <FormField
                    type={inputType.number}
                    isPositive
                    isMoney
                    name={'publicHolidayFeeKc'}
                    label={'Příplatek za doručení o svátku'}
                    required
                    defaultValue={'200'}
                    registerOptions={{ required: true }}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-6">
                  <FormField
                    type={inputType.number}
                    isPositive
                    name={'containerRentPriceFromDay'}
                    label={'Počet dní pronájmu kontejneru zdarma'}
                    required
                    defaultValue={'3'}
                    registerOptions={{ required: true }}
                  />
                </div>
                <div className="col-md-6">
                  <FormField
                    type={inputType.number}
                    isPositive
                    isMoney
                    name={'containerRentPricePerDayKc'}
                    label={'Cena za pronájem kontejneru za den [Kč]'}
                    required
                    defaultValue={'100'}
                    registerOptions={{ required: true }}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-6">
                  <SelectField
                    name={'priority'}
                    label={'Priorita dodavatele v regionu'}
                    required
                    registerOptions={{ required: true }}
                    optionsData={priorityValues}
                  />
                </div>
                <div className="col-md-6">
                  <SelectField
                    name={'pathCalculationStrategy'}
                    label={'Způsob výpočtu ceny dopravy'}
                    required
                    registerOptions={{ required: true }}
                    optionsData={pathCalculationStrategyOptions}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-6">
                  <ToggleField name={'useFlatRatePriceList'} label={'Používat paušální ceník u dopravce'} />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-6">
                  <ToggleField name={'autoApproveOrders'} label={'Automaticky posílat objednávky na tohoto dodavatele'} />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-6">
                  <FormField
                    type={inputType.number}
                    isPositive
                    isDecimal
                    name={'ourMarginPercent'}
                    label={'Naše marže [%]'}
                    required
                    defaultValue={'10'}
                    registerOptions={{ required: true }}
                  />
                </div>
                <div className="col-md-6">
                  <FormField
                    type={inputType.number}
                    isPositive
                    name={'operationRadiusM'}
                    label={'Rádius vzdušnou čarou [m]'}
                    required
                    defaultValue={'15000'}
                    registerOptions={{ required: true }}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-6">
                  <FormField
                    type={inputType.number}
                    isPositive
                    isDecimal
                    name={'transportFlatRate'}
                    label={'Paušální cena za dopravu, v případě použití vlastní koncovky'}
                    defaultValue={'0'}
                    registerOptions={{ required: false }}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-6">
                  <ToggleField name={'allowWorkOnSaturday'} label={'Dopravce pracuje v sobotu'} />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-6">
                  <ToggleField name={'allowWorkOnSunday'} label={'Dopravce pracuje v neděli'} />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-6">
                  <ToggleField name={'allowWorkOnHolidays'} label={'Dopravce pracuje i o státních svátcích'} />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-6">
                  <ToggleField name={'acceptCod'} label={'Dopravce akceptuje platbu dobírkou'} />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-6">
                  <ToggleField name={'disabled'} label={'Dopravce není aktivní (není nabízený zákazníkům)'} />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-6">
                  <ToggleField name={'priceLimitNotApplicable'} label={'Pro tohoto dopravce neplatí cenový strop'} />
                </div>
              </div>
            </fieldset>
            <div className="card-footer ">
              <div className="justify-content-center d-flex">
                <button type="button" onClick={() => returnBack()} className="btn btn-primary ms-auto btn-lg">
                  Zpět bez uložení
                </button>
                <button type="submit" className="btn btn-primary ms-auto btn-lg ml-2">
                  {!supplierId && 'Vytvořit dodavatele'}
                  {supplierId && !isToDuplicate && 'Uložit změny'}
                  {supplierId && isToDuplicate && 'Duplikovat dodavatele'}
                </button>
              </div>
            </div>
          </form>
        </AddressPickerWrapper>
      </FormProvider>
    </PageCard>
  )

  function handleFormSubmit(data: Record<string | number | symbol, any>) {
    if (data.autoApproveOrders === true && !data.email) {
      toast.error('Je nutné nastavit email, pokud je nastaveno automatické schvalování objednávek')
      return
    }

    let resultPromise =
      supplierId && !isToDuplicate
        ? updateSupplierMut({ variables: { supplierId: parseInt(supplierId), ...createParamsForCreateSupplierMutation(data) } })
        : createSupplierMut({ variables: createParamsForCreateSupplierMutation(data, isToDuplicate && supplierId ? parseInt(supplierId) : undefined) })

    processPageOperation({
      promise: resultPromise,
      successAction: returnBack,
      successMessage: `Dodavatel byl ${supplierId ? 'upraven' : 'vytvořen'}.`
    })

    return false
  }

  function loadSupplierData(data: { suppliers: SupplierDetailItemType[] }) {
    if (data.suppliers.length === 1) {
      const supplier = data.suppliers[0]
      setupFields(supplier, {
        skipFields: ['id', 'gps', 'street', 'streetNumber', 'city', 'zip', 'residenceStreet', 'residenceStreetNumber', 'residenceCity', 'residenceZip'],
        formMethods
      })
      formMethods.setValue('address', {
        lng: supplier.gps.coordinates[0].toString(),
        lat: supplier.gps.coordinates[1].toString(),
        street: supplier.street ?? '',
        streetNumber: supplier.streetNumber ?? '',
        city: supplier.city ?? '',
        zip: supplier.zip ?? ''
      })
      formMethods.setValue('residence', {
        street: supplier.residenceStreet ?? '',
        streetNumber: supplier.residenceStreetNumber ?? '',
        city: supplier.residenceCity ?? '',
        zip: supplier.residenceZip ?? ''
      })
    }
  }

  async function loadDataFromAresHandler() {
    const ico = formMethods.watch('companyIdentification')
    if (!ico || ico.length !== 8) {
      window.alert('Vyplňte IĆO aby mělo 8 znaků')
      return
    }
    const result = await companyInfoQR.refetch({ ico })

    if (!result || !result.data || !result.data.getCompanyInfoFromAres) {
      window.alert('Nepodařilo se získat data z registru')
      return
    }
    setDisableGetDataFromAres(true)
    const subjectInfo = result.data.getCompanyInfoFromAres
    formMethods.setValue('name', subjectInfo.name)
    if (subjectInfo.dic) formMethods.setValue('companyTaxId', subjectInfo.dic)

    if (formMethods.watch('overwriteSupplierCenter')) {
      formMethods.setValue('address', {
        street: subjectInfo.street,
        streetNumber: subjectInfo.streetNumber,
        city: subjectInfo.city,
        zip: subjectInfo.zip
      })
    }
    if (formMethods.watch('overwriteResidence')) {
      formMethods.setValue('residence', {
        street: subjectInfo.street,
        streetNumber: subjectInfo.streetNumber,
        city: subjectInfo.city,
        zip: subjectInfo.zip
      })
    }
  }
}
