import {gql, useQuery} from '@apollo/client'
import {contextEndpoint, EndpointEnum} from "../../../../../services/appConfiguration/contextEndpoint";

export const suppliersQuery = gql`
    query suppliers($id: String) {
        suppliers(id: $id) {
            id
            transportFlatRate
        }
    }
`

export type SuppliersResult = {
    id: number
    transportFlatRate: number
}

export function useSupplierQuery(variables: { id?: string }, onCompleted: (data: { suppliers: SuppliersResult[]; }) => void, skip: boolean) {
    return useQuery<{ suppliers: SuppliersResult[] }>(suppliersQuery, {
        fetchPolicy: 'network-only',
        context: contextEndpoint(EndpointEnum.admin),
        variables,
        onCompleted,
        skip: skip

    })
}