import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'

const upsertSupplierTerminalPriceListMutation = gql`
  mutation upsertSupplierTerminalPriceList($terminalPriceListId: ID, $data: SupplierTerminalPriceListInputArgs!, $branchServiceId: ID!) {
    upsertSupplierTerminalPriceList(data: $data, terminalPriceListId: $terminalPriceListId, branchServiceId: $branchServiceId) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpsertSupplierTerminalPriceListMutation() {
  return useMutation<{ upsertSupplierTerminalPriceList: IMutationResult }>(upsertSupplierTerminalPriceListMutation, {
    refetchQueries: ['supplierTerminalPriceLists']
  })
}
