import React from 'react'
import ToggleField from '../../../components/formComponents/ToggleField'
import FormField, { inputType } from '../../../components/formComponents/FormField'

export function SettingsNode() {
  return (
    <>
      <div className="row mb-2">
        <div className="col-md-6">
          <ToggleField name={'settings.offersDeliveryInBigBag'} label={'Nabízí dovoz v Big Bag'} />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-6">
          <FormField type={inputType.number} valueAsNumber name={'settings.bigBagPriceKc'} label={'Cena za kus [Kč]'} />
        </div>
        <div className="col-md-6">
          <FormField type={inputType.number} valueAsNumber name={'settings.bigBagCapacityTonne'} label={'Nosnost [t]'} />
        </div>
      </div>
    </>
  )
}
