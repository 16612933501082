import { ColumnFormatterTypes, TableDefinitionTypes } from '../../../services/bootstrapTable/services/tableSpecificationTypes'
import { ProductConcreteComponentWithPrice } from '../types/ProductConcreteComponentWithPrice'
import { moneyToLocaleString } from '../../../services/dataToString/decimalToLocaleString'

export const productConcreteComponentDataDefinition: TableDefinitionTypes<ProductConcreteComponentWithPrice> = {
  columnDefinition: {
    id: { hidden: true },
    branchServicePriceListId: { hidden: true },
    name: { name: 'Jméno' },
    cubicToTonnes: { name: 'Výchozí m³/t' },
    priceKc: { name: 'Cena pro Metrák Kč', editable: true },
    priceForPublicKc: { name: 'Cena veřejná Kč', editable: true },
    actions: { name: 'Akce', headerStyle: { width: '80px' }, columnFormatterType: ColumnFormatterTypes.actionsObject }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    branchServicePriceListId: x => x.branchServicePriceListId,
    name: x => x.name,
    cubicToTonnes: x => x.cubicToTonnes,
    priceKc: x => moneyToLocaleString(x.priceKc, 'CZK'),
    priceForPublicKc: x => moneyToLocaleString(x.priceForPublicKc, 'CZK'),
    actions: x => {
      return [
        {
          type: 'codeAction',
          value: 'deleteConcreteComponentPrice',
          icon: 'fe-x',
          title: 'Nenabízet tuto cenu'
        }
      ]
    }
  }
}
