import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const performStateActionMutation = gql`
  mutation performStateAction($orderContentIds: [Int]!, $action: performStateActionEnum!) {
    performStateAction(orderContentId: $orderContentIds, action: $action) {
      status {
        reason
        result
      }
    }
  }
`

export function usePerformStateActionMutation() {
  return useMutation<{ performStateAction: IMutationResult }>(performStateActionMutation)
}

export function createParamsForPerformStateActionMutation(ids: number[], action: performStateActionEnum) {
  return { orderContentIds: ids, action: action }
}

export enum performStateActionEnum {
  'PAY' = 'PAY',
  'SKIP_PAYMENT' = 'SKIP_PAYMENT',
  'CANCEL_BY_ADMIN' = 'CANCEL_BY_ADMIN',
  'APPROVE' = 'APPROVE',
  'UNDO_APPROVE' = 'UNDO_APPROVE',
  'CANCEL_BY_SUPPLIER' = 'CANCEL_BY_SUPPLIER',
  'CONFIRM' = 'CONFIRM',
  'CONTAINER_ARRIVE' = 'CONTAINER_ARRIVE',
  'CONTAINER_TAKE' = 'CONTAINER_TAKE',
  'ORDER_FINISHED_BY_SUPPLIER' = 'ORDER_FINISHED_BY_SUPPLIER',
  'ORDER_CHARGED_BY_ADMIN' = 'ORDER_CHARGED_BY_ADMIN',
  'MATERIAL_DELIVERED' = 'MATERIAL_DELIVERED'
}
