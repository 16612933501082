import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import LoadingContainer from '../../../../components/LoadingContainer'
import FormField, { inputType } from '../../../../components/formComponents/FormField'
import { isAnyQueryLoading } from '../../../../services/functions/queryHelpers'
import { createParamsForCreateProductMutation, useCreateMaterialMutation } from './services/createMaterialQuery'
import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import BootstrapTable from 'react-bootstrap-table-next'
import { materialQueryItemType, useMaterialQuery } from '../../../../services/queries/materialsQuery'
import useBootstrapTableDataMapper from '../../../../services/bootstrapTable/useBootstrapTableDataMapper'
import { materialListDataDefinition } from './services/materialListDataDefinition'
import { useDeleteMaterialMutation } from './services/deleteMaterialMutation'
import cellEditFactory from 'react-bootstrap-table2-editor'
import { createParamsForUpdateMaterialMutation, useUpdateMaterialMutation } from './services/updateMaterialMutation'

function AddOrEditMaterial() {
  const formMethods = useForm({ defaultValues: { type: 'MATERIAL' } })
  const selectedSection = formMethods.watch('type')
  const [sectionedMaterials, setSectionedMaterials] = useState<{
    materials: materialQueryItemType[]
    types: materialQueryItemType[]
    fractions: materialQueryItemType[]
  }>({ materials: [], types: [], fractions: [] })
  const [tableData, setTableData] = useState<any>([])
  const materialsQR = useMaterialQuery()
  const [createMaterialMut, createMaterialMR] = useCreateMaterialMutation()
  const [deleteMaterialMut, deleteMaterialMR] = useDeleteMaterialMutation()
  const [updateMaterialMut, updateMaterialMR] = useUpdateMaterialMutation()

  useEffect(() => {
    if (!materialsQR.data) return
    const materials: any[] = []
    const types: any[] = []
    const fractions: any[] = []
    for (let i = 0; i < materialsQR.data.materials.length; i++) {
      const material = materialsQR.data.materials[i]
      if (material.isMaterial) materials.push(material)
      if (material.isType) types.push(material)
      if (material.isFraction) fractions.push(material)
    }
    setSectionedMaterials({ fractions, materials, types })
  }, [materialsQR])

  const { generateColumnListForBootstrapTable, mapDataArrayToTable } = useBootstrapTableDataMapper<materialQueryItemType>(materialListDataDefinition)
  const columnDefinition = generateColumnListForBootstrapTable({
    actionsReference: {
      deletePriceList: deleteMaterialHandler
    }
  })

  useEffect(() => {
    switch (selectedSection) {
      case 'MATERIAL':
        setTableData(sectionedMaterials.materials.length > 0 ? mapDataArrayToTable(sectionedMaterials.materials) : [])
        return
      case 'TYPE':
        setTableData(sectionedMaterials.types.length > 0 ? mapDataArrayToTable(sectionedMaterials.types) : [])
        return
      case 'FRACTION':
        setTableData(sectionedMaterials.fractions.length > 0 ? mapDataArrayToTable(sectionedMaterials.fractions) : [])
        return
    }
  }, [selectedSection, sectionedMaterials])

  return (
    <LoadingContainer showLoader={isAnyQueryLoading(createMaterialMR, materialsQR, deleteMaterialMR, updateMaterialMR)}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleFormSubmit}>
          <div className="row">
            <div className="col">
              <span className="mr-5">
                <input type="radio" id="material" {...formMethods.register('type')} value="MATERIAL" />
                <label htmlFor="material" className="pl-2 d-inline-block strong">
                  Materiál
                </label>
              </span>
              <span className="mr-5">
                <input type="radio" id="type" {...formMethods.register('type')} value="TYPE" />
                <label htmlFor="type" className="pl-2 d-inline-block strong">
                  Typ
                </label>
              </span>
              <span className="mr-5">
                <input type="radio" id="fraction" {...formMethods.register('type')} value="FRACTION" />
                <label htmlFor="fraction" className="pl-2 d-inline-block strong">
                  Frakce
                </label>
              </span>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col col-sm-3">
              <FormField type={inputType.text} name="name" required label="Jméno" placeholder="Jméno" />
            </div>
            <div className="col col-sm-5">
              <FormField type={inputType.text} name="tooltip" label="Tooltip" placeholder="Tooltip" />
            </div>
            <div className="col col-sm-2">
              <FormField type={inputType.number} isPositive name="uiOrder" label="Pořadí na FE" placeholder="Pořadí na FE" required />
            </div>
            <div className="col col-sm-1">
              <FormField
                type={inputType.text}
                name="tonToCubes"
                label={
                  <span>
                    1 t/m<sup>3</sup>
                  </span>
                }
                disabled={selectedSection === 'fraction'}
              />
            </div>
            <div className="col col-sm-1">
              <button className="btn btn-primary mt-3">Přidat</button>
            </div>
          </div>
        </form>
      </FormProvider>
      <div className="mt-4">
        <BootstrapTable
          bootstrap4
          striped
          hover
          condensed
          classes="table-responsive-lg digiTable"
          noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné záznamy"</>}
          keyField="id"
          data={tableData ? tableData : []}
          columns={columnDefinition}
          defaultSorted={[{ dataField: 'uiOrder', order: 'asc' }]}
          cellEdit={cellEditFactory({
            mode: 'click',
            blurToSave: true,
            autoSelectText: true,
            beforeSaveCell: onCellUpdate
          })}
        />
      </div>
    </LoadingContainer>
  )

  function handleFormSubmit(e) {
    e.preventDefault()
    let data = formMethods.getValues()
    const resultPromise = createMaterialMut({ variables: createParamsForCreateProductMutation(data) })
    processPageOperation({
      promise: resultPromise,
      successAction: async () => materialsQR.refetch()
    })
    return false
  }
  function deleteMaterialHandler(row: any) {
    if (window.confirm(`Opravdu smazat materiál ${row.name}?`)) {
      const resultPromise = deleteMaterialMut({ variables: { id: row.id } })
      processPageOperation({
        promise: resultPromise,
        successMessage: `Produkt '${row.name}' byl odstraněn.`,
        successAction: async () => materialsQR.refetch()
      })
    }
    return false
  }
  function onCellUpdate(oldValue, newValue, row, column) {
    if (!row.id || oldValue === newValue) return
    const resultPromise = updateMaterialMut({ variables: { id: row.id, data: createParamsForUpdateMaterialMutation(row, column.dataField, newValue) } })
    processPageOperation({
      promise: resultPromise,
      successMessage: `Produkt '${row.name}' byl upraven.`,
      successAction: async () => materialsQR.refetch()
    })
  }
}

export default AddOrEditMaterial
