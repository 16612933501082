import { MRT_ColumnDef } from 'material-react-table'
import { TerminalFeeSettingsWithId } from '../../../ProductWasteList/components/TerminalFeeEditor/services/terminal-fee-settings-with-id.type'
import { SupplierContainerRentRatesEntity } from '../../../../graphql/aminTypes'
import { mrtMoneyFormatter } from '../../../../components/mui-react-table/mrt-formatters'

export const containerRentRatesTableDefinition: MRT_ColumnDef<SupplierContainerRentRatesEntity, any>[] = [
  {
    header: 'Název',
    accessorKey: 'name',
    enableEditing: false,
    maxSize: 100,
    enableSorting: false,
    Cell: ({ cell }) => {
      const value = cell.getValue()
      return <strong>{value}</strong>
    }
  },
  { header: 'Dní pronájmu zdarma', accessorKey: 'rentFreeForDays', enableEditing: false, maxSize: 100, enableSorting: false },
  { header: 'Základní cena za den', accessorKey: 'baseRentPerDayKc', enableEditing: false, maxSize: 100, enableSorting: false, ...mrtMoneyFormatter },
  { header: 'Maximální délka pronájmu', accessorKey: 'maximumRentDays', enableEditing: false, maxSize: 100, enableSorting: false },
  { header: 'Paušál za měsíc', accessorKey: 'monthlyRentPriceKc', enableEditing: false, maxSize: 100, enableSorting: false, ...mrtMoneyFormatter },
  { header: 'Aplikovat paušál po tomto dni ', accessorKey: 'monthlyRentAfterDays', enableEditing: false, maxSize: 100, enableSorting: false },
  {
    header: 'Doplňkové ceny',
    accessorKey: 'additionalPrices',
    enableSorting: false,
    enableEditing: false,
    Cell: ({ cell }) => {
      const additionalPrices = cell.getValue()
      return additionalPrices.map((price, index) => (
        <div key={index}>
          Od {price.fromDay}. dne: {price.rentPerDayKc} Kč
        </div>
      ))
    }
  }
]
