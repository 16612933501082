import React from 'react'
import useBootstrapTableDataMapper from '../../../services/bootstrapTable/useBootstrapTableDataMapper'
import {hasQueryArrayData, isAnyQueryLoading} from '../../../services/functions/queryHelpers'
import BootstrapTable from 'react-bootstrap-table-next'
import LoadingContainer from '../../../components/LoadingContainer'
import {TerminalPriceListType, useTerminalPriceListQuery} from './services/terminalPriceListQuery'
import {terminalPriceListDataDefinition} from './services/terminalPriceListDataDefinition'
import {useDeleteTerminalPriceListMutation} from './services/deleteTerminalPriceListMutation'
import {processPageOperation} from '../../../services/formsServices/pageOperationProcessor'
import cellEditFactory from "react-bootstrap-table2-editor";
import {useUpdateTerminalPriceListMutation} from "./services/updateTerminalPriceList";
import {createParamsForCreateTerminalPriceListMutation} from "./services/createTerminalPriceListMutation";

export default function TerminalPriceListRow({terminalId}: {terminalId: number}) {
    const {generateColumnListForBootstrapTable, mapDataArrayToTable} = useBootstrapTableDataMapper<TerminalPriceListType>(terminalPriceListDataDefinition)
    const terminalPriceListsQR = useTerminalPriceListQuery(terminalId)
    const [deleteTerminalPriceListMut, deleteTerminalPriceListQR] = useDeleteTerminalPriceListMutation()
    const [updatePriceListMut, updatePriceListQR] = useUpdateTerminalPriceListMutation()

    const columnDefinition = generateColumnListForBootstrapTable({
        actionsReference: {
            deletePriceList: deletePriceListHandler
        }
    })

    let priceList: any[] = []
    if (terminalPriceListsQR.data && hasQueryArrayData(terminalPriceListsQR)) priceList = mapDataArrayToTable(terminalPriceListsQR.data.terminalPriceList)
    return (
        <LoadingContainer
            showLoader={isAnyQueryLoading(deleteTerminalPriceListQR, terminalPriceListsQR, updatePriceListQR)}>
            <BootstrapTable
                bootstrap4
                striped
                hover
                condensed
                classes="table-responsive-lg digiTable"
                noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné záznamy"</>}
                keyField="id"
                data={priceList}
                columns={columnDefinition}
                cellEdit={cellEditFactory({
                    mode: 'click',
                    blurToSave: true,
                    autoSelectText: true,
                    beforeSaveCell: updateMaterialPriceHandler
                })}
            />
        </LoadingContainer>
    )

    function deletePriceListHandler(row: any) {
        if (!row.id) return
        const deleteTerminalPromise = deleteTerminalPriceListMut({variables: {terminalPriceListId: row.id}})
        processPageOperation({
            promise: deleteTerminalPromise,
            successAction: result => terminalPriceListsQR.refetch()
        })
    }

    async function updateMaterialPriceHandler(oldValue, newValue, row, column, doneCallback) {
        if (!row.id || oldValue === newValue) return
        const data = {
            productId: row.id,
            pricePerTonneKc: newValue,
        }

        const promise = updatePriceListMut({variables: {terminalPriceListId: row.id, ...createParamsForCreateTerminalPriceListMutation(data)}})
        processPageOperation({
            promise: promise,
            successAction: async result => {
                await terminalPriceListsQR.refetch()
            }
        })
        await terminalPriceListsQR.refetch()
    }
}
