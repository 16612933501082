import React, { useContext } from 'react'
import useBootstrapTableDataMapper from '../../services/bootstrapTable/useBootstrapTableDataMapper'
import BootstrapTable from 'react-bootstrap-table-next'
import { ProductWasteEntity } from '../../graphql/aminTypes'
import { productWasteListRowDataDefinition } from './services/product-waste-list-row-data-definition'
import AddOrEditWastes from './components/AddOrEditWaste/AddOrEditWaste'
import { ModalContext } from '../../components/Modal/ModalProvider'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import { useDeleteProductWasteMutation } from './mutations/delete-product-waste.mutation'
import { ProductContainerCapacityOfWaste } from '../ProductContainerCapacity/ProductContainerCapacityOfWaste'

export default function ProductWasteListRow({ data }: { data: ProductWasteEntity[] }) {
  const { generateColumnListForBootstrapTable, mapDataArrayToTable, nameForGqlTranslation } =
    useBootstrapTableDataMapper<ProductWasteEntity>(productWasteListRowDataDefinition)
  const { showModal, hideModal } = useContext(ModalContext)
  const [deleteWasteMut, deleteWasteQR] = useDeleteProductWasteMutation()

  const columnDefinition = generateColumnListForBootstrapTable({
    actionsReference: { editWaste: editWasteHandler, deleteWaste: deleteWasteHandler, showContainerCapacities: showContainerCapacitiesHandler }
  })

  let productWasteList: any[] = []

  if (data.length > 0) productWasteList = mapDataArrayToTable(data)

  return (
    <BootstrapTable
      bootstrap4
      striped
      condensed
      remote
      classes="table-responsive-lg digiTable"
      noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné záznamy"</>}
      keyField="id"
      data={productWasteList}
      columns={columnDefinition}
    />
  )

  function editWasteHandler(row: any) {
    if (!row.id) return
    const waste = data.find(x => x.id === row.id)
    showModal({
      title: 'Editace odpadu ' + row.name,
      hideFooter: true,
      modalContent: <AddOrEditWastes onExit={hideModal} wasteToEdit={waste} />
    })
  }

  function showContainerCapacitiesHandler(row: any) {
    if (!row.id) return
    showModal({
      title: 'Kapacity kontejnerů pro odpad ' + row.name,
      hideFooter: false,
      modalContent: <ProductContainerCapacityOfWaste wasteId={row.id} />
    })
  }
  function deleteWasteHandler(row: any) {
    const promise = deleteWasteMut({ variables: { id: row.id } })
    processPageOperation({
      promise,
      successMessage: 'Odpad byl odstraněn'
    })
  }
}
