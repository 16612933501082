import { WasteFinal } from '../../../../../graphql/aminTypes'
import {
  ColumnAlign,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../../../services/bootstrapTable/services/tableSpecificationTypes'

export const wasteFinalsDataDefinition: TableDefinitionTypes<WasteFinal> = {
  columnDefinition: {
    id: { hidden: true },
    wasteType: { name: 'Odpad' },
    weightTonne: { name: 'Hmotnost [t]' },
    icz: { name: 'ICZ' },
    terminalName: { name: 'Koncovka' },
    customerNotifiedDate: { name: 'Odesláno' },
    actions: {
      name: 'Akce',
      columnFormatterType: ColumnFormatterTypes.actionsObject,
      headerStyle: { width: '110px' },
      align: ColumnAlign.right
    }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    wasteType: x => (x.wasteType ? `${x.wasteType?.code} - ${x.wasteType?.name}` : 'N/A'),
    weightTonne: x =>
      x.weightTonne
        ? x.weightTonne.toLocaleString('cs-CZ', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 3
          })
        : '',
    icz: x => x.icz,
    terminalName: x => x.terminalName,
    customerNotifiedDate: x => x.customerSentTimestamp,
    actions: x => {
      const ret: IRowAction[] = []
      if (x.s3key)
        ret.push({
          type: RowActionType.codeAction,
          value: 'sendConfirmationByEmail',
          icon: 'fe-mail',
          title: 'Odeslat zákazníkovi'
        })
      if (x.s3key)
        ret.push({
          type: RowActionType.codeAction,
          value: 'downloadWasteFinalConfirmation',
          icon: 'fe-download-cloud',
          title: 'Stáhnout potvrzení o odvozu odpadu'
        })
      if (!x.customerSentTimestamp)
        ret.push({
          type: RowActionType.codeAction,
          value: 'generateWasteFinalConfirmation',
          icon: 'fe-file-text',
          title: 'Vygenerovat potvrzení o odvozu odpadu'
        })
      if (!x.customerSentTimestamp)
        ret.push({
          type: RowActionType.codeAction,
          value: 'editWasteFinal',
          icon: 'fe-edit',
          title: 'Editovat'
        })
      if (!x.customerSentTimestamp)
        ret.push({
          type: RowActionType.codeAction,
          value: 'deleteWasteFinal',
          icon: 'fe-x',
          title: 'Nevratně odstranit'
        })
      return ret
    }
  }
}
