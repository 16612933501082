import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../services/types/gqlTypes'
import { customerListQuery } from '../../../CustomerList/services/customerListQuery'

const createCustomerFromAdminMutation = gql`
  mutation createCustomerFromAdmin($data: CreateCustomerInputArgs!) {
    createCustomerFromAdmin(data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useCreateCustomerFromAdmin() {
  return useMutation<{ createCustomerFromAdmin: IMutationResult }>(createCustomerFromAdminMutation, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: customerListQuery }]
  })
}
