import { gql, useQuery } from '@apollo/client'
import { IGqlPagingQueryParams } from '../../../services/bootstrapTable/usePagingFromURLQuery'

export const productsQuery = gql`
  query products(
    $disabled: String
    $id: String
    $isCollection: String
    $isDelivery: String
    $limit: Int
    $name: String
    $offset: Int
    $order: String
    $wasteTypeId: Int
  ) {
    products(
      offset: $offset
      limit: $limit
      order: $order
      disabled: $disabled
      id: $id
      name: $name
      isCollection: $isCollection
      isDelivery: $isDelivery
      wasteTypeId: $wasteTypeId
    ) {
      disabled
      id
      isCollection
      isDelivery
      margin
      name
      tooltip
      uiOrder
      uiPicture
      isForPublic
      tonToCubes
      wasteDescription
      acceptedWaste
      unsupportedWaste
      ignoreContainerRentPrice
      wasteType {
        id
        code
        name
      }
      material {
        id
        name
      }
      type {
        id
        name
        uiPicture
      }
      fraction {
        id
        name
      }
    }
  }
`

export type ProductsType = {
  disabled: boolean
  id: number
  isCollection: boolean
  isDelivery: boolean
  margin: number
  name: string
  tooltip?: string
  uiOrder: number
  uiPicture?: string
  isForPublic: boolean
  tonToCubes: number
  wasteDescription?: String
  acceptedWaste?: String
  unsupportedWaste?: String
  ignoreContainerRentPrice: boolean
  wasteType?: {
    id: number
    code: string
    name: string
  }
  material?: {
    id: number
    name: string
  }
  type?: {
    id: number
    name: string
    uiPicture: string
  }
  fraction?: {
    id: number
    name: string
  }
}

export function useProductsQuery(params: IGqlPagingQueryParams, onCompleted?: (data) => void, skip?: boolean) {
  return useQuery<{ products: ProductsType[] }>(productsQuery, {
    fetchPolicy: 'network-only',
    variables: params,
    onCompleted,
    skip: skip
  })
}
