import { gql, useQuery } from '@apollo/client'
import { Maybe } from 'graphql/jsutils/Maybe'
import { CheckAdvanceAccountAvailabilityResponse, CheckAdvanceAccountStatusResponseEnum } from '../../../../../graphql/aminTypes'

export const checkAdvanceAccountAvailabilityQuery = gql`
  query checkAdvanceAccountAvailability($customerId: Int!, $orderTotalKc: Float, $gps: GpsCoordinationInput!) {
    checkAdvanceAccountAvailability(gps: $gps, customerId: $customerId, orderTotalKc: $orderTotalKc) {
      accountRemainingBalanceKc
      response
    }
  }
`
export function useCheckAdvanceAccountAvailabilityQuery() {
  return useQuery<{ checkAdvanceAccountAvailability: CheckAdvanceAccountAvailabilityResponse }>(checkAdvanceAccountAvailabilityQuery, {
    fetchPolicy: 'network-only',
    skip: true
  })
}
