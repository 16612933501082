// @ts-ignore
import { PaginationListStandalone, PaginationTotalStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator'
import React from 'react'

export function BootstrapTablePagingWrapper({
  paginationProps,
  middleColumnHtml,
  children
}: {
  paginationProps: any
  paginationTableProps: any
  middleColumnHtml?: any
  children: React.ReactNode
}) {
  return (
    <>
      <div className="row d-md-flex d-none">
        <div className="col-md-3">
          <SizePerPageDropdownStandalone {...paginationProps} />
        </div>
        <div className="col-md-5">{middleColumnHtml && middleColumnHtml}</div>
        <div className="col-md-4">
          <div className="float-right">
            <PaginationListStandalone {...paginationProps} />
          </div>
        </div>
      </div>
      {children}
      <div className="row d-md-flex d-xs-none">
        <div className="col-md-6">
          <SizePerPageDropdownStandalone {...paginationProps} />
          <span className="ml-3">
            <PaginationTotalStandalone {...paginationProps} />
          </span>
        </div>
        <div className="col-md-4 offset-2">
          <div className="float-right">
            <PaginationListStandalone {...paginationProps} />
          </div>
        </div>
      </div>
    </>
  )
}
