import { gql, useQuery } from '@apollo/client'
import { TerminalFeeSetting } from '../../../../../graphql/aminTypes'

export const getTerminalFeeSettings = gql`
  query getTerminalFeeSettings {
    getTerminalFeeSettings {
      validFrom
      validTo
      legalFeeBase
      legalFeeLower
      legalFeeHigher
      recultivationFeeBase
      recultivationFeeHigher
    }
  }
`

export function useGetTerminalFeeSettings() {
  return useQuery<{ getTerminalFeeSettings: TerminalFeeSetting[] }>(getTerminalFeeSettings, { fetchPolicy: 'network-only' })
}
