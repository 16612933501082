import { moneyToLocaleString } from '../../../services/dataToString/decimalToLocaleString'
import { AdditionalFeePrices, BasePricePartsType } from '../queries/calculatePriceQuery'
import { useOrderContentPriceItems } from '../queries/orderContentPriceItemsQuery'
import { DataListItem } from '../../../components/HtmlDataList'
import { OrderContentPriceItem, OrderContentPrices } from '../../../graphql/aminTypes'

export function getPriceSeparation(orderPriceData?: OrderContentPrices[] | null): DataListItem[] {
  if (!orderPriceData) return []
  const inputData = [...orderPriceData].sort((a, b) => a.orderContentPriceItem.uiOrder - b.orderContentPriceItem.uiOrder)
  let result: DataListItem[] = []
  for (const x of inputData) {
    result.push({
      label: x.orderContentPriceItem.name,
      text: moneyToLocaleString(x.priceWoVatKc, 'CZK'),
      fontWeightLight: !x.orderContentPriceItem.partOfTotalPrice
    })
  }
  return result
}

export function useGetPriceSeparationForCalc() {
  const orderContentPriceItemsQR = useOrderContentPriceItems()

  return {
    getPriceSeparationForCalc: (
      prices: BasePricePartsType,
      orderAdditionalPrices: AdditionalFeePrices[],
      containerAdditionalPrices: AdditionalFeePrices[],
      isDelivery: boolean
    ) => {
      let inputData: ProcessedOrderContentPrices[] = [
        ...orderAdditionalPrices.map(x => ({
          priceWoVatKc: x.priceKcWoVat,
          orderContentPriceItem: orderContentPriceItemsQR.data?.orderContentPriceItems.find(ocpi => ocpi.sysName === x.type)!
        })),
        ...containerAdditionalPrices.map(x => ({
          priceWoVatKc: x.priceKcWoVat,
          orderContentPriceItem: orderContentPriceItemsQR.data?.orderContentPriceItems.find(ocpi => ocpi.sysName === x.type)!
        }))
      ]
      if (prices.flatRate && isDelivery)
        inputData.push({
          priceWoVatKc: prices.flatRate,
          orderContentPriceItem: orderContentPriceItemsQR.data?.orderContentPriceItems.find(ocpi => ocpi.sysName === 'priceForProduct')!
        })
      if (prices.flatRate && !isDelivery)
        inputData.push({
          priceWoVatKc: prices.flatRate,
          orderContentPriceItem: orderContentPriceItemsQR.data?.orderContentPriceItems.find(ocpi => ocpi.sysName === 'flatRate')!
        })
      if (prices.margin)
        inputData.push({
          priceWoVatKc: prices.margin,
          orderContentPriceItem: orderContentPriceItemsQR.data?.orderContentPriceItems.find(ocpi => ocpi.sysName === 'margin')!
        })
      if (prices.terminalPrice)
        inputData.push({
          priceWoVatKc: prices.terminalPrice,
          orderContentPriceItem: orderContentPriceItemsQR.data?.orderContentPriceItems.find(ocpi => ocpi.sysName === 'terminalStorage')!
        })
      if (prices.transferPrice)
        inputData.push({
          priceWoVatKc: prices.transferPrice,
          orderContentPriceItem: orderContentPriceItemsQR.data?.orderContentPriceItems.find(ocpi => ocpi.sysName === 'transfer')!
        })
      if (prices.priceLimitDiscount)
        inputData.push({
          priceWoVatKc: prices.priceLimitDiscount,
          orderContentPriceItem: orderContentPriceItemsQR.data?.orderContentPriceItems.find(ocpi => ocpi.sysName === 'priceLimitDiscount')!
        })
      if (prices.b2bMargin)
        inputData.push({
          priceWoVatKc: prices.b2bMargin,
          orderContentPriceItem: orderContentPriceItemsQR.data?.orderContentPriceItems.find(ocpi => ocpi.sysName === 'b2bMargin')!
        })
      if (prices.marginDiscount)
        inputData.push({
          priceWoVatKc: prices.marginDiscount,
          orderContentPriceItem: orderContentPriceItemsQR.data?.orderContentPriceItems.find(ocpi => ocpi.sysName === 'marginDiscount')!
        })

      inputData.sort((a, b) => a.orderContentPriceItem.uiOrder - b.orderContentPriceItem.uiOrder)
      let result: DataListItem[] = []
      for (const x of inputData) {
        result.push({
          label: `${x.orderContentPriceItem.name ?? x.orderContentPriceItem.sysName}`,
          text: moneyToLocaleString(x.priceWoVatKc, 'CZK'),
          fontWeightLight: !x.orderContentPriceItem.partOfTotalPrice
        })
      }
      return { visualResult: result, parsedData: inputData }
    }
  }
}

export type ProcessedOrderContentPrices = {
  priceWoVatKc: number
  orderContentPriceItem: OrderContentPriceItem
}
