import { TableDefinitionTypes } from '../../../../services/bootstrapTable/services/tableSpecificationTypes'
import React from 'react'
import { AccountingGroupOrders } from '../../../../graphql/aminTypes'
import { formatAddressToDisplay } from '../../../../services/dataToString/formatAddressToDisplay'
import { moneyToLocaleString } from '../../../../services/dataToString/decimalToLocaleString'

export const groupOrdersDataDefinition: TableDefinitionTypes<AccountingGroupOrders> = {
  columnDefinition: {
    id: { hidden: true },
    index: { hidden: true },
    customer: { name: 'Zákazník' },
    location: { name: 'Akce' },
    count: { name: 'Počet' },
    orders: { name: 'Objednávky' },
    total: { name: 'Celkem' }
  },
  sourceToColumnMapping: {
    id: (x, index) => x.orders[0].id,
    index: (x, index) => index,
    customer: x => {
      if (x.customer) {
        return x.customer.companyName || x.customer.firstname + ' ' + x.customer.lastname
      }
      return x.orders[0].companyName
    },
    location: x => formatAddressToDisplay(x.orders[0]),
    count: x => x.orders.length,
    orders: x =>
      x.orders.map(o => (
        <div key={o.orderNumber}>
          {o.orderNumber} - {o.orderContent?.containerCount ? `${o.orderContent.containerCount}× ` : ''}
          {o.orderContent?.weightTonnes ? `${o.orderContent.weightTonnes}t ` : ''}
          {o.orderContent?.product.name} {o.orderContent?.container ? `${o.orderContent.container.name} ${o.orderContent.container.capacityM3} m³` : ''}
        </div>
      )),
    total: x => (
      <>
        <div>{moneyToLocaleString(x.totalWoVatKc, 'CZK')} bez DPH</div>
        <strong>{moneyToLocaleString(x.totalInclVatKc, 'CZK')} s DPH</strong>
      </>
    )
  }
}
