import { gql, useQuery } from '@apollo/client'

const statisticsOverviewQuery = gql`
  query statisticsOverview($fromDate: String, $toDate: String){
    statisticsOverview(fromDate: $fromDate, toDate: $toDate){
      monthYear
      containersSum
      ordersCount
      profitForMetrak
      supplierProfitWoVatKc
      turnover
    }
  }
`

export function useStatisticsOverviewQuery(params?: {fromDate?: string, toDate?: string}) {
  return useQuery<{ statisticsOverview: ordersForDashboardType[] }>(statisticsOverviewQuery, {variables: params})
}

export type ordersForDashboardType = {
  monthYear: string
  containersSum: number
  ordersCount: number
  profitForMetrak: number
  supplierProfitWoVatKc: number
  turnover: number
}
