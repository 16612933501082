import React, { useContext, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import classNames from 'classnames'
import { serviceCategoryTranslation, ServiceCategoryType } from '../../../../services/types/service-category.type'
import { SelectedSupplierModalContext } from '../../../SelectedSupplierModal/SelectedSupplierModal'
import { useChangeBranchServiceState } from './mutations/change-branch-service-state.mutation'
import ToggleField from '../../../../components/formComponents/ToggleField'
import FormField, { inputType } from '../../../../components/formComponents/FormField'
import DatePickerComp from '../../../../components/formComponents/datePickerComp'
import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import { SupplierBranchServiceEntity } from '../../../../graphql/aminTypes'
import { useUpdateBranchServiceMutation } from './mutations/update-branch-service.mutation'
import dayjs from 'dayjs'
import LoadingContainer from '../../../../components/LoadingContainer'
import { isAnyQueryLoading } from '../../../../services/functions/queryHelpers'

export default function SupplierServiceEnabler({
  serviceCategory,
  settingsNode,
  currentServiceSetting
}: {
  serviceCategory: ServiceCategoryType
  settingsNode: JSX.Element
  currentServiceSetting?: SupplierBranchServiceEntity
}) {
  const formMethods = useForm({})
  const [changeBranchServiceStateMut, changeBranchServiceStateMR] = useChangeBranchServiceState()
  const [updateBranchServiceMut, updateBranchServiceMR] = useUpdateBranchServiceMutation()
  const { state } = useContext(SelectedSupplierModalContext)

  useEffect(() => {
    if (currentServiceSetting) {
      formMethods.reset({
        isActive: currentServiceSetting.isActive,
        higherPriority: currentServiceSetting.higherPriority,
        metrakMargin: currentServiceSetting.metrakMargin,
        validFrom: currentServiceSetting.validFrom ? new Date(currentServiceSetting.validFrom) : undefined,
        validTo: currentServiceSetting.validTo ? new Date(currentServiceSetting.validTo) : undefined,
        settings: currentServiceSetting.settings
      })
    }
  }, [currentServiceSetting])
  return (
    <LoadingContainer showLoader={isAnyQueryLoading(changeBranchServiceStateMR, updateBranchServiceMR)}>
      {!currentServiceSetting && (
        <>
          <div className="alert alert-warning" role="alert">
            Tato pobočka nemá povolenou službu {serviceCategoryTranslation[serviceCategory]}
          </div>
          <button type="button" className="btn btn-primary btn-block" onClick={() => enableService()}>
            Zapnout službu {serviceCategoryTranslation[serviceCategory]}
          </button>
        </>
      )}
      {currentServiceSetting && (
        <>
          <fieldset className={classNames('form-fieldset')}>
            <FormProvider {...formMethods}>
              <form onSubmit={formMethods.handleSubmit(updateServiceParams)} autoComplete={'off'}>
                <div className="row mb-2">
                  <div className="col-md-2">
                    <ToggleField name={'isActive'} label={'Aktivní'} />
                  </div>
                  <div className="col-md-2">
                    <ToggleField name={'higherPriority'} label={'Prioritní služba v okolí'} />
                  </div>
                  <div className="col-md-2">
                    <FormField type={inputType.number} name={'metrakMargin'} label={'Marže pro Metrák'} valueAsNumber />
                  </div>
                  <div className="col-md-2">
                    <DatePickerComp name={'validFrom'} label={'Ceník platný od'} />
                  </div>
                  <div className="col-md-2">
                    <DatePickerComp name={'validTo'} label={'Ceník platný do'} />
                  </div>
                </div>
                {settingsNode}
                <div className="row mb-2">
                  <div className="col-md-2">
                    <button type="submit" className="btn btn-primary btn-block">
                      Uložit
                    </button>
                  </div>
                  <div className="offset-md-9 col-md-1">
                    <button type="button" className="btn btn-secondary btn-block" onClick={() => deleteService()}>
                      Zrušit službu
                    </button>
                  </div>
                </div>
              </form>
            </FormProvider>
          </fieldset>
        </>
      )}
    </LoadingContainer>
  )

  function enableService() {
    processPageOperation({
      promise: changeBranchServiceStateMut({ variables: { branchId: state.selectedBranch?.id, serviceCategory, state: 'enable' } }),
      successAction: state.reloadBranchData,
      successMessage: `Služba ${serviceCategoryTranslation[serviceCategory]} byla zapnuta`
    })
  }
  function deleteService() {
    if (!window.confirm('Opravdu chcete odstranit službu?')) return
    processPageOperation({
      promise: changeBranchServiceStateMut({ variables: { branchId: state.selectedBranch?.id, serviceCategory, state: 'disable' } }),
      successAction: state.reloadBranchData,
      successMessage: `Služba ${serviceCategoryTranslation[serviceCategory]} byla odstraněna`
    })
  }
  function updateServiceParams(data: any) {
    if (data.validFrom) data.validFrom = dayjs(data.validFrom).format('YYYY-MM-DD')
    if (data.validTo) data.validTo = dayjs(data.validTo).format('YYYY-MM-DD')
    if (!data.settings) data.settings = {}
    if (!currentServiceSetting?.id) return

    processPageOperation({
      promise: updateBranchServiceMut({ variables: { branchServiceId: currentServiceSetting?.id, data } }),
      successAction: state.reloadBranchData,
      successMessage: `Služba ${serviceCategoryTranslation[serviceCategory]} byla aktualizována`
    })
  }
}
