import { gql, useMutation } from '@apollo/client'
import { BaseMutationResult } from '../../../../graphql/aminTypes'

const pairFioTransactionWithOrderMutation = gql`
  mutation pairFioTransactionWithOrder($fioTransactionPairingId: String!, $orderId: Int!, $performOrderContentStateTransition: Boolean!) {
    pairFioTransactionWithOrder(
      fioTransactionPairingId: $fioTransactionPairingId
      orderId: $orderId
      performOrderContentStateTransition: $performOrderContentStateTransition
    ) {
      status {
        reason
        result
      }
    }
  }
`

export function usePairFioTransactionWithOrderMutation() {
  return useMutation<{
    pairFioTransactionWithOrder: BaseMutationResult
  }>(pairFioTransactionWithOrderMutation, { refetchQueries: ['getFioTransactionList'] })
}
