import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'
import { contextEndpoint, EndpointEnum } from '../../../../../services/appConfiguration/contextEndpoint'
import { orderDetailQuery } from '../../../queries/orderDetailQuery'
import { accountingDocumentsQuery } from '../../AccountingDocuments/query/accountingDocumentsQuery'
import { getParamFromUrl } from '../../../../../services/functions/getParamFromUrl'

const paymentActionMutation = gql`
  mutation paymentAction($orderId: Int!, $action: PaymentActionEnum!, $paramsJson: String) {
    paymentAction(orderId: $orderId, action: $action, paramsJson: $paramsJson) {
      status {
        reason
        result
      }
    }
  }
`

export function usePaymentActionMutation(orderId?: number | undefined) {
  return useMutation<{ paymentAction: IMutationResult }>(paymentActionMutation, {
    context: contextEndpoint(EndpointEnum.admin),
    refetchQueries: result => [
      'emailHistory',
      {
        query: orderDetailQuery,
        variables: {
          orderNumber: parseInt(getParamFromUrl('orderNumber') ?? ''),
          orderId: parseInt(getParamFromUrl('orderId') ?? ''),
          orderContentId: parseInt(getParamFromUrl('orderContentId') ?? '')
        },
        context: contextEndpoint(EndpointEnum.admin)
      }
    ]
  })
}
