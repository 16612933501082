import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'
import { supplierBranchListQuery } from '../../../../SupplierBranchList/queries/supplier-branch-list.query'

const changeBranchServiceStateMutation = gql`
  mutation changeBranchServiceState($branchId: ID!, $serviceCategory: ServiceCategory!, $state: String!) {
    changeBranchServiceState(branchId: $branchId, serviceCategory: $serviceCategory, state: $state) {
      status {
        reason
        result
      }
    }
  }
`

export function useChangeBranchServiceState() {
  return useMutation<{ enableBranchService: IMutationResult }>(changeBranchServiceStateMutation, { refetchQueries: [supplierBranchListQuery] })
}
