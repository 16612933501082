import {
    ColumnAlign,
    ColumnFormatterTypes,
    IRowAction,
    RowActionType,
    TableDefinitionTypes
} from "../../../../../services/bootstrapTable/services/tableSpecificationTypes";
import {B2bCustomProductType} from "./B2bProductListQuery";


export function b2bCustomProductsDataDefinition(): TableDefinitionTypes<B2bCustomProductType> {
    return {
        columnDefinition: {
            id: { hidden: true },
            data: {hidden: true},
            enabled: {
                name: 'Aktivní',
                columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
                align: ColumnAlign.center,
                headerStyle: {width: '100px'}
            },
            product: {
                name: "Produkt",
            },
            supplier: {
                name: 'Dodavatel'
            },
            fixedPrice: {
                name: "Fixní cena (za odvoz pro dodavatele)",
                columnFormatterType: ColumnFormatterTypes.moneyFormatter,
                type: 'number'
            },
            margin: {
                name: "Marže pro metrák (z fixní ceny)",
                columnFormatterType: ColumnFormatterTypes.percentage,
                type: 'number'
            },
            actions: {
                name: 'Akce',
                columnFormatterType: ColumnFormatterTypes.actionsObject,
                headerStyle: { width: '80px' },
                align: ColumnAlign.center
            },
        },
        sourceToColumnMapping: {
            data: x => x,
            id: x => x.id,
            enabled: x=> !x.disabled,
            product: x => x.product.name,
            margin: x => x.priceCorrectionPercent,
            fixedPrice: x => x.fixedPrice,
            supplier: x => x.supplier?.name,
            actions: x => {
                const ret: IRowAction[] = []
                ret.push({
                    type: RowActionType.codeAction,
                    value: 'deleteProduct',
                    icon: 'fe-trash',
                    title: 'Smazat ceník pro produkt'
                })
                ret.push({
                    type: RowActionType.codeAction,
                    value: 'editProduct',
                    icon: 'fe-edit',
                    title: 'Upravit ceník pro produkt'
                })

                return ret
            }
        }
    }
}

export function b2bCustomProductsNoFixedPriceDataDefinition(): TableDefinitionTypes<any> {
    return {
        columnDefinition: {
            id: { hidden: true },
            data: {hidden: true},
            disabled: {
                name: 'Aktivní',
                columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
                align: ColumnAlign.center,
                headerStyle: {width: '100px'}
            },
            product: {
                name: "Produkt",
            },
            priceCorrectionPercent: {
                name: 'Úprava marže (sleva) z vypočítané ceny [%]'
            },
            actions: {
                name: 'Akce',
                columnFormatterType: ColumnFormatterTypes.actionsObject,
                headerStyle: { width: '80px' },
                align: ColumnAlign.center
            },
        },
        sourceToColumnMapping: {
            data: x => x,
            id: x => x.id,
            disabled: x=> !x.disabled,
            product: x => x.product.name,
            priceCorrectionPercent: x => x.priceCorrectionPercent,
            actions: x => {
                const ret: IRowAction[] = []
                ret.push({
                    type: RowActionType.codeAction,
                    value: 'deleteProduct',
                    icon: 'fe-trash',
                    title: 'Smazat produkt'
                })
                ret.push({
                    type: RowActionType.codeAction,
                    value: 'editProduct',
                    icon: 'fe-edit',
                    title: 'Upravit ceník pro produkt'
                })

                return ret
            }
        }
    }
}


