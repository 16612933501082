import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useChangeOwnerMutation } from '../mutations/changeOwner'
import SelectField from '../../../components/formComponents/SelectField'
import { useMasterAdminLoginsQuery } from '../../../services/queries/useMasterAdminLoginsQuery'
import { processPageOperation } from '../../../services/formsServices/pageOperationProcessor'

export default function ChangeOwnerDropdown({ exitHandler, orderData }: { exitHandler: any; orderData: any }) {
  const formMethods = useForm()
  const [changeOwnerMutation] = useChangeOwnerMutation()
  const loginsQR = useMasterAdminLoginsQuery()
  const newOwnerList =
    loginsQR.data?.logins?.map(x => ({
      label: `${x.firstname} ${x.lastname} (${x.email})`,
      value: x.id
    })) ?? []
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
        <fieldset className="form-fieldset">
          <SelectField name={'newOwnerId'} label={'Vlastník'} required optionsData={newOwnerList} noOptionsMessage={'Neexistují další možní vlastníci'} />
          <div className="form-footer d-flex justify-content-end">
            <button type="submit" className="btn btn-primary" onClick={handleFormSubmit}>
              Změnit vlastníka
            </button>
            <button type="submit" className="btn btn-red ml-3" onClick={exitHandler}>
              Zrušit
            </button>
          </div>
        </fieldset>
      </form>
    </FormProvider>
  )

  function handleFormSubmit() {
    const promise = changeOwnerMutation({
      variables: {
        orderId: orderData?.order?.id,
        loginId: formMethods.watch('newOwnerId')
      }
    })
    processPageOperation({
      promise: promise,
      successAction: exitHandler,
      successMessage: `Změna vlastníka byla provedena`
    })
  }
}
