import { gql, useQuery } from '@apollo/client'
import { contextEndpoint, EndpointEnum } from '../../../../../services/appConfiguration/contextEndpoint'
import { ZpoDetail } from '../../../../../graphql/aminTypes'

export const getZpoDetailQuery = gql`
  query getZpoDetailQuery($orderContentId: Int!) {
    getZpoDetail(orderContentId: $orderContentId) {
      id
      wasteType {
        id
        name
        code
      }
      zuj {
        id
        name
      }
      orp {
        id
        name
      }
      fileName
      s3key
      wasteOriginDesc
      notificationEmails
      supplierNotifiedTimestamp
      wasteCompositionDesc
      zpoCreatedDate
    }
  }
`
export function useGetZpoDetailQuery(orderContentId: number, onCompleted?: () => void) {
  return useQuery<{ getZpoDetail: ZpoDetail }>(getZpoDetailQuery, {
    variables: { orderContentId },
    context: contextEndpoint(EndpointEnum.admin),
    onCompleted
  })
}
