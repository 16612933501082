import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const fillWasteCapacitiesForWasteChildrenMutation = gql`
  mutation fillWasteCapacitiesForWasteChildren($productWasteId: ID!) {
    fillWasteCapacitiesForWasteChildren(productWasteId: $productWasteId) {
      status {
        reason
        result
      }
    }
  }
`

export function useFillWasteCapacitiesForWasteChildrenMutation() {
  return useMutation<{ fillWasteCapacitiesForWasteChildren: IMutationResult }>(fillWasteCapacitiesForWasteChildrenMutation, {
    refetchQueries: ['productWasteList']
  })
}
