import { gql, useMutation } from '@apollo/client'
import {IMutationResultWithId} from '../../../../services/types/gqlTypes'

const createSupplierDeliveryPriceListMutation = gql`
    mutation createDeliveryPriceList($data: DeliveryPriceListDataInput) {
        createDeliveryPriceList(data: $data) {
            status {
                reason
                result
            }
        }
    }
`

export function useCreateSupplierDeliveryPriceListMutation() {
    return useMutation<{ createSupplierDeliveryPriceList: IMutationResultWithId }>(createSupplierDeliveryPriceListMutation)
}

export function createParamsForCreateSupplierDeliveryPriceListMutation(data: any , supplierId?: any | undefined) {
    return {
        data: {
            baseTransportPriceKc: +data.baseTransportPriceKc,
            baseTransportPriceBiggerTruckKc: +data.baseTransportPriceBiggerTruckKc,
            baseTransportRadiusKm: +data.baseTransportRadiusKm,
            disabled: data.disabled,
            margin: +data.margin,
            pricePerKmKc: +data.pricePerKmKc,
            pricePerKmBiggerTruckKc: +data.pricePerKmBiggerTruckKc,
            pricePerTonneKc: +data.pricePerTonneKc,
            productId: data.productId,
            supplierId: supplierId
        }
    }
}
