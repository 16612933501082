import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const deleteSupplierTerminalPriceListMutation = gql`
  mutation deleteSupplierTerminalPriceList($id: ID!) {
    deleteSupplierTerminalPriceList(id: $id) {
      status {
        reason
        result
      }
    }
  }
`

export function useDeleteSupplierTerminalPriceListMutation() {
  return useMutation<{ deleteSupplierTerminalPriceList: IMutationResult }>(deleteSupplierTerminalPriceListMutation, {
    refetchQueries: ['supplierTerminalPriceLists']
  })
}
