import {
  ColumnAlign,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../services/bootstrapTable/services/tableSpecificationTypes'

export const fileListDataDefinition: TableDefinitionTypes<any> = {
  columnDefinition: {
    id: { hidden: true },
    filename: { name: 'Jméno souboru', sort: true, columnFormatterType: ColumnFormatterTypes.showAsLink },
    supplier: { name: 'Od dodavatele' },
    size: { name: 'Velikost [KB]', columnFormatterType: ColumnFormatterTypes.numberFormatter, align: ColumnAlign.right },
    key: { name: 'key', hidden: true },
    actions: {
      name: 'Akce',
      columnFormatterType: ColumnFormatterTypes.actionsObject,
      headerStyle: { width: '80px' },
      align: ColumnAlign.center
    }
  },
  sourceToColumnMapping: {
    id: (x, index) => index,
    filename: x => x.filename,
    supplier: x => x.supplier,
    key: x => x.key,
    size: x => Math.floor(x.size / 1000),
    actions: x => {
      const ret: IRowAction[] = []
      ret.push({
        type: RowActionType.codeAction,
        value: 'deleteFile',
        icon: 'fe-x',
        title: 'Nevratně odstranit'
      })
      return ret
    }
  }
}
