import { gql, useQuery } from '@apollo/client'
import { SupplierTruckEntity } from '../../../graphql/aminTypes'

export const supplierServiceLoadTransportQuery = gql`
  query supplierServiceLoadTransport($supplierBranchId: ID!) {
    supplierServiceLoadTransport(supplierBranchId: $supplierBranchId) {
      id
      isActive
      capacityTonne
      name
      truckType
      hasHydraulicArm
      hydraulicArmPriceKc
      manipulationPriceKc
      pricePerKmKc
      minimumPriceOfTransport
      supportedCollectionServices
      supportedContainerTypes
      supportedDeliveryServices
    }
  }
`

export function useSupplierServiceLoadTransport(supplierBranchId?: string) {
  return useQuery<{ supplierServiceLoadTransport: SupplierTruckEntity[] }>(supplierServiceLoadTransportQuery, {
    fetchPolicy: 'network-only',
    variables: { supplierBranchId },
    skip: !supplierBranchId
  })
}
