import {
  ColumnAlign,
  ColumnFilterTypes,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../services/bootstrapTable/services/tableSpecificationTypes'
import { ProductsType } from './productsQuery'
import React from 'react'

export const productsDeliveryDataDefinition: TableDefinitionTypes<ProductsType> = {
  columnDefinition: {
    id: { hidden: true },
    data: { hidden: true },
    isActive: {
      name: 'Aktivní',
      columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
      align: ColumnAlign.center,
      type: 'boolean',
      filterType: ColumnFilterTypes.customSelect,
      headerStyle: { width: '100px' },
      nameForGqlFilter: 'disabled',
      remoteSort: true
    },
    uiOrder: { name: 'Pořadí zobrazení', headerStyle: { width: '100px' }, remoteSort: true },
    name: { name: 'Jméno', filterType: ColumnFilterTypes.customText },
    material: {
      name: 'Materiál'
    },
    type: {
      name: 'Typ'
    },
    fraction: {
      name: 'Frakce'
    },
    tonToCubes: {
      name: '1 kubík na tuny'
    },
    uiPicture: {
      name: 'Obrázek'
    },
    tooltip: {
      name: 'Tooltip'
    },
    margin: {
      name: 'Marže navíc pro Metrák',
      columnFormatterType: ColumnFormatterTypes.percentage
    },
    isForPublic: {
      name: 'Dostupné pro veřejnost',
      columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
      align: ColumnAlign.center
    },
    actions: {
      name: 'Akce',
      columnFormatterType: ColumnFormatterTypes.actionsObject,
      align: ColumnAlign.left,
      headerStyle: { width: '70px' }
    }
  },
  sourceToColumnMapping: {
    data: x => x,
    id: x => x.id,
    uiOrder: x => x.uiOrder,
    isActive: x => !x.disabled,
    name: x => x.name,
    material: x => x.material?.name,
    type: x => x.type?.name,
    fraction: x => x.fraction?.name,
    uiPicture: x =>
      x.type?.uiPicture ? (
        <img src={`https://${process.env.REACT_APP_FE_HOSTNAME}/assets/images/${x.type?.uiPicture}`} width="80" title={x.uiPicture} alt={x.uiPicture} />
      ) : undefined,
    tooltip: x => x.tooltip,
    margin: x => x.margin,
    tonToCubes: x => x.tonToCubes,
    isForPublic: x => x.isForPublic,
    actions: x => {
      const ret: IRowAction[] = []
      ret.push({
        type: RowActionType.codeAction,
        value: 'deleteProduct',
        icon: 'fe-x',
        title: 'Smazat produkt'
      })
      ret.push({
        type: RowActionType.codeAction,
        value: 'editProduct',
        icon: 'fe-edit',
        title: 'Upravit produkt'
      })
      return ret
    }
  }
}

export const productsCollectionDataDefinition: TableDefinitionTypes<ProductsType> = {
  columnDefinition: {
    id: { hidden: true },
    data: { hidden: true },
    isActive: {
      name: 'Aktivní',
      columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
      align: ColumnAlign.center,
      type: 'boolean',
      filterType: ColumnFilterTypes.customSelect,
      headerStyle: { width: '100px' },
      nameForGqlFilter: 'disabled'
    },
    uiOrder: { name: 'Pořadí zobrazení', headerStyle: { width: '100px' }, remoteSort: true },
    name: { name: 'Jméno', filterType: ColumnFilterTypes.customText },
    uiPicture: {
      name: 'Obrázek'
    },
    tooltip: { name: 'Tooltip' },
    margin: {
      name: 'Marže navíc pro Metrák',
      columnFormatterType: ColumnFormatterTypes.percentage
    },
    isForPublic: {
      name: 'Dostupné pro veřejnost',
      columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
      align: ColumnAlign.center
    },
    wasteCode: { name: 'Jméno odpadu' },
    actions: {
      name: 'Akce',
      columnFormatterType: ColumnFormatterTypes.actionsObject,
      align: ColumnAlign.left,
      headerStyle: { width: '70px' }
    }
  },
  sourceToColumnMapping: {
    data: x => x,
    id: x => x.id,
    uiOrder: x => x.uiOrder,
    isActive: x => !x.disabled,
    wasteCode: x => (x.wasteType?.name ? `${x.wasteType?.name} (${x.wasteType?.code})` : ''),
    name: x => x.name,
    uiPicture: x =>
      x.uiPicture ? <img src={`https://${process.env.REACT_APP_FE_HOSTNAME}/assets/images/${x.uiPicture}`} width="80" title={x.uiPicture} alt={x.name} /> : '',
    tooltip: x => x.tooltip,
    isForPublic: x => x.isForPublic,
    margin: x => x.margin,
    actions: x => {
      const ret: IRowAction[] = []
      ret.push({
        type: RowActionType.codeAction,
        value: 'editProduct',
        icon: 'fe-edit',
        title: 'Upravit produkt'
      })
      ret.push({
        type: RowActionType.codeAction,
        value: 'deleteProduct',
        icon: 'fe-x',
        title: 'Smazat produkt'
      })
      return ret
    }
  }
}
