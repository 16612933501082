import { gql, useQuery } from '@apollo/client'

export const loginsQuery = gql`
  query logins($email: String) {
    logins(email: $email) {
      id
      loginToSupplier {
        supplier {
          id
        }
      }
    }
  }
`

export function useLoginsQuery() {
  return useQuery<{
    logins: {
      id: number
      loginToSupplier: {
        supplier: {
          id: number
        }
      }[]
    }[]
  }>(loginsQuery, { fetchPolicy: 'network-only', variables: {}, skip: true })
}
