import { gql, useQuery } from '@apollo/client'

const getResponsesQuery = gql`
  query getResponses {
    getResponses {
      customResponses
      stats {
        count
        response
      }
    }
  }
`

export function useGetResponsesQuery() {
  return useQuery<{ getResponses: {customResponses: string[], stats: {count: number, response: string}[]} }>(getResponsesQuery, { fetchPolicy: 'network-only' })
}
