import { gql, useQuery } from '@apollo/client'
import { ListZuj } from '../../../../../graphql/aminTypes'
import { contextEndpoint, EndpointEnum } from '../../../../../services/appConfiguration/contextEndpoint'

export const listZujQuery = gql`
  query listZuj {
    listZuj {
      id
      name
      code
      zip
      orp {
        id
        code
        name
      }
    }
  }
`

export function useListZujQuery() {
  return useQuery<{ listZuj: ListZuj[] }>(listZujQuery, {
    context: contextEndpoint(EndpointEnum.admin)
  })
}
