 import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../services/types/gqlTypes'

const createTerminalPriceListMutation = gql`
  mutation createTerminalPriceList($terminalId: Int!, $data: TerminalPriceListDataInput!) {
    createTerminalPriceList(terminalId: $terminalId, data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useCreateTerminalPriceListMutation() {
  return useMutation<{ createTerminalPriceList: IMutationResult }>(createTerminalPriceListMutation)
}

export function createParamsForCreateTerminalPriceListMutation(data: any) {
  return {
    data: {
      wasteTypeId: data.wasteTypeId,
      pricePerTonneKc: parseFloat(data.pricePerTonneKc),
      disabled: false
    }
  }
}
