import React from 'react'
import { useMissingSupplierConfigurationQuery } from './queries/missing-supplier-configuration.query'
import PageCard from '../../components/Cards/pageCard'
import { MuiScopedContainer } from '../../components/mui-react-table/MuiScopedContainer'
import { MaterialReactTable } from 'material-react-table'
import { useMissingSupplierConfigurationDataDefinition } from './services/missing-supplier-configuration-data-definition'

export default function SupplierMissingConfiguration() {
  const missingSupplierConfigurationQR = useMissingSupplierConfigurationQuery()
  const columnDef = useMissingSupplierConfigurationDataDefinition()

  return (
    <PageCard headerTitle="Výpočet cen za služby" showLoader={missingSupplierConfigurationQR.loading}>
      <MuiScopedContainer>
        <MaterialReactTable
          columns={columnDef}
          data={missingSupplierConfigurationQR.data?.missingSupplierConfiguration || []}
          enableBottomToolbar={true}
          initialState={{
            density: 'compact',
            pagination: { pageIndex: 0, pageSize: 25 }
          }}
        />
      </MuiScopedContainer>
    </PageCard>
  )
}
