import React, { useEffect } from 'react'
import { isAnyQueryLoading } from '../../../services/functions/queryHelpers'
import { FormProvider, useForm } from 'react-hook-form'
import AddressPicker from '../../../components/formComponents/AddressPicker/AddressPicker'
import ToggleField from '../../../components/formComponents/ToggleField'
import FormField, { inputType } from '../../../components/formComponents/FormField'
import { useUpdateCustomerMutation } from './mutations/udate-customer.mutation'
import { useReturnBackWithHighlight } from '../../../services/pageHooks/highlightRowAfterReturn'
import { processPageOperation } from '../../../services/formsServices/pageOperationProcessor'
import LoadingContainer from '../../../components/LoadingContainer'
import { OperationVariables, QueryResult } from '@apollo/client'
import { CustomerListPaginatedResponse } from '../../../graphql/aminTypes'
import SelectField from '../../../components/formComponents/SelectField'
import { countryIsoOptions } from '../../../services/values/country-iso.options'
import { CustomerFormData, customerFormFields } from './types/customer-form-data'
import { setupFields } from '../../../services/formsServices/setupFields'
import { useCreateCustomerFromAdmin } from './mutations/create-customer.mutation'
import { useGetCompanyInfoFromAresQuery } from '../../../services/queries/getCompanyInfoFromAresQuery'
import { getParamFromUrl } from '../../../services/functions/getParamFromUrl'
import { useOrderDetailQuery } from './queries/order-detail.query'

export default function CustomerEditor({ customerQR }: { customerQR?: QueryResult<{ customerList: CustomerListPaginatedResponse }, OperationVariables> }) {
  const loadFromOrderNumber = getParamFromUrl('fromOrder')
  const companyInfoQR = useGetCompanyInfoFromAresQuery()
  const orderDetailQR = useOrderDetailQuery(loadFromOrderNumber)
  const formMethods = useForm<CustomerFormData>({ defaultValues: { customerDiscount: 0, metrakMarginCorrection: 0, b2bMarginCorrection: 0 } })
  const [updateCustomerMut, updateCustomerMR] = useUpdateCustomerMutation()
  const [createCustomerFromAdminMut, createCustomerFromAdminMR] = useCreateCustomerFromAdmin()
  const { returnBack } = useReturnBackWithHighlight()
  const isCreatingNewCustomer = customerQR === undefined

  useEffect(() => {
    if (customerQR?.data) {
      const address = {
        street: customerQR.data.customerList.items[0].street,
        streetNumber: customerQR.data.customerList.items[0].streetNumber,
        city: customerQR.data.customerList.items[0].city,
        zip: customerQR.data.customerList.items[0].zip
      }
      setupFields(customerQR.data.customerList.items[0], { formMethods, includeKeys: customerFormFields })
      formMethods.setValue('address', address)
    }
  }, [customerQR?.data])

  useEffect(() => {
    if (orderDetailQR.data && orderDetailQR.data.orderDetail) {
      const cr = orderDetailQR.data.orderDetail.order.companyResidence
      if (cr) {
        formMethods.setValue('address', {
          street: cr.street ?? '',
          streetNumber: cr.streetNumber ?? '',
          city: cr.city ?? '',
          zip: cr.zip ?? ''
        })
      }
      setupFields(orderDetailQR.data.orderDetail.order, { formMethods, includeKeys: ['companyName', 'firstname', 'lastname', 'email', 'phone'] })
      formMethods.setValue('companyIdentification', orderDetailQR.data.orderDetail.order.ico ?? '')
      formMethods.setValue('companyTaxId', orderDetailQR.data.orderDetail?.order.dic ?? '')
      formMethods.setValue('countryIso', orderDetailQR.data.orderDetail.order.companyCountryIso ?? 'CZ')
    }
  }, [orderDetailQR.data])
  return (
    <LoadingContainer showLoader={isAnyQueryLoading(customerQR, updateCustomerMR, createCustomerFromAdminMR, orderDetailQR)}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
          <fieldset className="form-fieldset bg-lime-lt">
            <div className="row mb-2">
              <div className="col-md-4">
                <FormField type={inputType.text} name={'companyName'} label={'Jméno firmy'} />
              </div>
              <div className="col-md-3">
                <FormField type={inputType.text} name={'companyIdentification'} label={'IČO'} />
              </div>
              <div className="col-md-1">
                <button type="button" className="btn btn-primary mt-3" onClick={loadDataFromAresHandler}>
                  Načíst z ARES
                </button>
              </div>
              <div className="col-md-4">
                <FormField type={inputType.text} name={'companyTaxId'} label={'DIČ'} />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-6">
                <FormField type={inputType.text} name={'firstname'} label={'Jméno'} />
              </div>
              <div className="col-md-6">
                <FormField type={inputType.text} name={'lastname'} label={'Příjmení'} />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-4">
                <FormField type={inputType.text} name={'email'} label={'Email'} disabled={!isCreatingNewCustomer} />
              </div>
              <div className="col-md-4">
                <FormField type={inputType.text} name={'phone'} label={'Telefon'} />
              </div>
              <div className="col-md-4">
                <FormField type={inputType.text} name={'invoicingEmail'} label={'Fakturační email (pro ručně zasílané doklady přes obálku)'} />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-4">
                <SelectField name={'countryIso'} label={'Stát'} required optionsData={countryIsoOptions} />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12">
                <AddressPicker
                  label={'Fakturační adresa'}
                  name={'address'}
                  required
                  registerOptions={{ required: 'Poloha nebyla nalezena.' }}
                  className="bg-orange-lt"
                  countryIso={formMethods.watch('countryIso')}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-4">
                <FormField name={'b2bMarginCorrection'} label="Úprava marže pro B2B" type={inputType.number} isDecimal valueAsNumber />
              </div>
              <div className="col-md-4">
                <FormField name={'metrakMarginCorrection'} label="Úprava marže Metrák" type={inputType.number} isDecimal valueAsNumber />
              </div>
              <div className="col-md-4">
                <FormField name={'customerDiscount'} label="Zákaznická sleva" type={inputType.number} isDecimal valueAsNumber />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12">
                <FormField name={'notes'} label="Poznámky" type={inputType.text} />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12">
                <ToggleField name={'requireCertificates'} label="Zákazník požaduje váženky" />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12">
                <ToggleField name={'isResellCompany'} label="Přeprodej (DEK)" />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12">
                <ToggleField name={'hasFixedPrice'} label="Fixní ceny u vlastních produktů" />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12">
                <ToggleField name={'advanceAccountsSetup.isUsingAdvanceAccounts'} label="Používat zálohové účty" />
                <div className="row mb-2 ml-2">
                  <div className="col-md-12">
                    <FormField
                      name={'advanceAccountsSetup.negativeBalanceAllowance'}
                      label="Povolit jít do mínusu (na každé akci zvlášť) až o"
                      type={inputType.number}
                      valueAsNumber
                    />
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <div className="row mb-2">
            <div className="col-md-2 mt-2 offset-10">
              <button type="submit" className="btn btn-primary ms-auto btn-lg">
                {isCreatingNewCustomer ? 'Vytvořit nového zákazníka' : 'Uložit změny'}
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </LoadingContainer>
  )

  async function loadDataFromAresHandler() {
    const ico = formMethods.watch('companyIdentification')
    const countryIso = formMethods.watch('countryIso')
    if (!ico || !['CZ', 'SK'].includes(countryIso)) {
      window.alert('Zkontrolujte IČO a zemi sídla firmy. Lze napovídat jen pro CZ a SK firmy.')
      return
    }
    const result = await companyInfoQR.refetch({ ico, countryIso: countryIso })

    if (!result || !result.data || !result.data.getCompanyInfoFromAres) {
      window.alert('Nepodařilo se získat data z registru')
      return
    }
    const subjectInfo = result.data.getCompanyInfoFromAres
    formMethods.setValue('companyName', subjectInfo.name)
    formMethods.setValue('address.street', subjectInfo.street)
    formMethods.setValue('address.streetNumber', subjectInfo.streetNumber ?? '')
    formMethods.setValue('address.city', subjectInfo.city ?? '')
    formMethods.setValue('address.zip', subjectInfo.zip ?? '')
    if (subjectInfo.dic) formMethods.setValue('companyTaxId', subjectInfo.dic)
  }

  function handleFormSubmit(data: Record<string | number | symbol, any>) {
    const address = data.address
    delete data.address
    delete address.lat
    delete address.lng

    let resultPromise
    if (!isCreatingNewCustomer) {
      delete data.email
      resultPromise = updateCustomerMut({
        variables: {
          customerId: customerQR?.data?.customerList.items[0].id,
          data: { ...data, ...address, updatedAt: customerQR.data?.customerList.items[0].updatedAt ?? null }
        }
      })
    } else {
      resultPromise = createCustomerFromAdminMut({
        variables: {
          data: { ...data, ...address }
        }
      })
    }
    processPageOperation({
      promise: resultPromise,
      successAction: returnBack
    })
  }
}
