import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../services/types/gqlTypes'

const deleteSupplierTransportSupplierLinkMutation = gql`
  mutation deleteSupplierTransportSupplierLink($id: ID!) {
    deleteSupplierTransportSupplierLink(id: $id) {
      status {
        reason
        result
      }
    }
  }
`

export function useDeleteSupplierTransportSupplierLinkMutation() {
  return useMutation<{ deleteSupplierTransportSupplierLink: IMutationResult }>(deleteSupplierTransportSupplierLinkMutation, {
    refetchQueries: ['supplierTransportSupplierLink']
  })
}
