import { gql, useQuery } from '@apollo/client'
import {
  AccountingAdvanceAccountEntity,
  AccountingAdvanceAccountTransactionEntity,
  AccountingDocumentEntity,
  CustomerListPaginatedResponse
} from '../../../../graphql/aminTypes'

export const advanceAccountTransactionsQuery = gql`
  query advanceAccountTransactions($advanceAccountId: ID!) {
    advanceAccountTransactions(advanceAccountId: $advanceAccountId) {
      id
      type
      createdAt
      fioTransactionId
      orderId
      newBalanceKc
      amountKc
      description
      order {
        id
        orderNumber
      }
      fioTransaction {
        id
        transactionId
      }
    }
  }
`

export function useAdvanceAccountTransactionsQuery(advanceAccountId?: string) {
  return useQuery<{ advanceAccountTransactions: AccountingAdvanceAccountTransactionEntity[] }>(advanceAccountTransactionsQuery, {
    fetchPolicy: 'network-only',
    variables: { advanceAccountId },
    skip: !advanceAccountId
  })
}
