import { FormProvider, useForm } from 'react-hook-form'
import React, { useCallback, useEffect, useState } from 'react'
import FormField, { inputType } from '../../../components/formComponents/FormField'
import { isAnyQueryLoading } from '../../../services/functions/queryHelpers'
import PageCard from '../../../components/Cards/pageCard'
import { SupplierPriceListType, useSupplierPriceListQuery } from './services/supplierPriceListQuery'
import { processPageOperation } from '../../../services/formsServices/pageOperationProcessor'
import ProductsSelector, { ProductTypeEnum } from '../../../components/dataComponents/ProductsSelector/ProductsSelector'
import { IMutationResultWithId } from '../../../services/types/gqlTypes'
import { Alert, alertType } from '../../../components/formComponents/alert'
import ToggleField from '../../../components/formComponents/ToggleField'
import { createParamsForCreateSupplierDeliveryPriceListMutation, useCreateSupplierDeliveryPriceListMutation } from './services/createDeliveryPriceListMutation'
import { useSupplierDeliveryPriceListQuery } from './services/supplierDeliveryPriceListQuery'
import { useUpdateSupplierDeliveryPriceListMutation } from './services/updateDeliveryPriceListMutation'

export default function SupplierPriceListMaterialEditor({
  supplierId,
  supplierPriceListId,
  onFinish,
  currentlyDefinedMaterials
}: {
  supplierId: number
  onFinish: Function
  supplierPriceListId?: number
  currentlyDefinedMaterials?: number[]
}) {
  const [supplierPriceListArray, setSupplierPriceListArray] = useState<SupplierPriceListType[] | undefined>(undefined)
  const [supplierPriceListArrayIndex, setSupplierPriceListArrayIndex] = useState<number | undefined>(undefined)
  const [priceListDeliveryData, setPriceListDeliveryData] = useState<any | undefined>(undefined)
  const supplierPriceListsQR = useSupplierPriceListQuery(supplierId, data => {
    setupPriceListArrayFromResponse(data, supplierPriceListId)
  })

  const supplierPriceList =
    supplierPriceListArray && supplierPriceListArrayIndex !== undefined && supplierPriceListArrayIndex >= 0
      ? supplierPriceListArray[supplierPriceListArrayIndex]
      : undefined

  const formMethods = useForm({ defaultValues: { ...supplierPriceList } })

  const supplierDeliveryPriceListQR = useSupplierDeliveryPriceListQuery(supplierId)

  const [createDeliveryPriceListMut, createDeliveryPriceListMR] = useCreateSupplierDeliveryPriceListMutation()
  const [updateDeliveryPriceListMut, updateDeliveryPriceListMR] = useUpdateSupplierDeliveryPriceListMutation()

  const updateDeliveryFormValues = useCallback(() => {
    setPriceListDeliveryData(supplierDeliveryPriceListQR?.data?.deliveryPriceLists.find(x => supplierPriceListId === x.id))
    if (priceListDeliveryData) {
      for (const key of Object.keys(priceListDeliveryData)) {
        formMethods.setValue(key as any, priceListDeliveryData[key])
      }
      formMethods.setValue('productId' as any, priceListDeliveryData.product.id)
    }
  }, [supplierDeliveryPriceListQR, formMethods.setValue, priceListDeliveryData])

  useEffect(() => {
    updateDeliveryFormValues()
  }, [supplierPriceListArrayIndex, supplierPriceListArray, priceListDeliveryData, updateDeliveryFormValues])

  return (
    <PageCard showLoader={isAnyQueryLoading(createDeliveryPriceListMR, updateDeliveryPriceListMR, supplierDeliveryPriceListQR)}>
      <div className="row ">
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(handleFormSubmit())} autoComplete={'off'}>
            <div className="row">
              <div className="col col-12">
                <ProductsSelector productType={ProductTypeEnum.isDelivery} required removeIds={currentlyDefinedMaterials} disabled={!!priceListDeliveryData} />
              </div>
              <div className="col col-12 mt-3">
                <FormField type={inputType.number} isPositive name={'pricePerTonneKc'} label={'Cena za tunu [Kč]'} required />
              </div>
              <div className="col col-12 mt-3">
                <FormField type={inputType.number} isPositive name={'baseTransportPriceKc'} label={'Paušál za dovoz pod 6.5 tun [Kč]'} required />
              </div>
              <div className="col col-12 mt-3">
                <FormField type={inputType.number} isPositive name={'baseTransportPriceBiggerTruckKc'} label={'Paušál za dovoz nad 6.5 tun [Kč]'} required />
              </div>
              <div className="col col-12 mt-3">
                <FormField
                  type={inputType.number}
                  isPositive
                  name={'baseTransportRadiusKm'}
                  label={'Ujetá vzdálenost, pokrytá v rámci paušálu [Km]'}
                  required
                />
              </div>
              <div className="col col-12 mt-3">
                <FormField type={inputType.number} isPositive name={'pricePerKmKc'} label={'Cena za kilometr pod 6.5 tun nad rámec paušálu'} required />
              </div>
              <div className="col col-12 mt-3">
                <FormField
                  type={inputType.number}
                  isPositive
                  name={'pricePerKmBiggerTruckKc'}
                  label={'Cena za kilometr nad 6.5 tun nad rámec paušálu'}
                  required
                />
              </div>
              <div className="col col-12 mt-3 mb-3">
                <FormField type={inputType.number} isPositive name={'margin'} label={'Marže navíc pro tento materiál'} required />
              </div>
              <div className="col col-12 mt-3 mb-3">
                <ToggleField name={'disabled'} label={'Je zakázán?'} />
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
      {formMethods.formState.isDirty && <Alert type={alertType.warning}>Změny nejsou uloženy</Alert>}
      <div className="text-right">
        {!(supplierPriceList || supplierPriceListId) && (
          <button type="button" className="btn btn-primary ms-auto btn-lg ml-1" onClick={handleFormSubmitAndNew}>
            {'Přidat & nový'}
          </button>
        )}
        <button type="button" className="btn btn-primary ms-auto btn-lg ml-1" onClick={handleFormSubmitAndClose}>
          {supplierPriceList || supplierPriceListId ? 'Uložit & zavřít' : 'Přidat & zavřít'}
        </button>
      </div>
    </PageCard>
  )

  async function handleFormSubmitAndClose() {
    await handleFormSubmit(onFinish)(formMethods.getValues())
  }

  async function handleFormSubmitAndNew() {
    await handleFormSubmit(clearForNewEntry)(formMethods.getValues())
  }

  function clearForNewEntry(data: any) {
    formMethods.reset({ ...data, productId: null, pricePerTonneKc: '' })
  }

  function handleFormSubmit(onSuccess?: Function) {
    return async (data: Record<string | number | symbol, any>) => {
      await formMethods.trigger()
      const errors = formMethods.formState.errors
      if (Object.keys(errors).length > 0) return
      let resultPromise
      resultPromise =
        supplierPriceListId === undefined
          ? createDeliveryPriceListMut({ variables: { ...createParamsForCreateSupplierDeliveryPriceListMutation(data, supplierId) } })
          : updateDeliveryPriceListMut({ variables: { id: supplierPriceListId, ...createParamsForCreateSupplierDeliveryPriceListMutation(data, supplierId) } })

      processPageOperation({
        promise: resultPromise,
        successMessage: 'Nastavení bylo uloženo',
        successAction: mutationResult => {
          formMethods.reset(data)
          reloadData(mutationResult)
          if (onSuccess) onSuccess(data)
        }
      })

      return false
    }
  }

  function setupPriceListArrayFromResponse(data: any, supplierPriceListId: number | undefined) {
    const sortedList = data?.supplierPriceList.slice().sort((a, b) => a.container.uiOrder - b.container.uiOrder)
    setSupplierPriceListArray(sortedList)
    setSupplierPriceListArrayIndex(sortedList?.findIndex(x => x.id === supplierPriceListId))
  }

  function reloadData(mutationResult?: any) {
    const isCreateNew = supplierPriceList === undefined
    supplierPriceListsQR.refetch().then(response => {
      setupPriceListArrayFromResponse(response.data, isCreateNew && mutationResult ? (mutationResult as IMutationResultWithId).id : supplierPriceList?.id)
    })
  }
}
