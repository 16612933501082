import { gql, useQuery } from '@apollo/client'

export const supplierDeliveryPriceListQuery = gql`
    query deliveryPriceLists($id: Int) {
        deliveryPriceLists(id: $id) {
            id
            baseTransportPriceKc
            baseTransportPriceBiggerTruckKc
            baseTransportRadiusKm
            margin
            disabled
            pricePerKmKc
            pricePerKmBiggerTruckKc
            pricePerTonneKc
            product {
                id
                name
                uiOrder
            }
        }
    }
`

export type SupplierDeliveryPriceListType = {
    id: number
    baseTransportPriceKc: number
    baseTransportRadiusKm: number
    pricePerKmBiggerTruckKc: number
    baseTransportPriceBiggerTruckKc: number
    disabled: boolean
    margin: number
    pricePerKmKc: number
    pricePerTonneKc: number
    product: {
        id: number
        name: string
        uiOrder: number
    }
}


export function useSupplierDeliveryPriceListQuery(id: number,  onCompleted?: any) {
    return useQuery<{
        deliveryPriceLists: SupplierDeliveryPriceListType[]}>(supplierDeliveryPriceListQuery, { variables: { id }, fetchPolicy: "network-only", onCompleted: onCompleted})
}
