import { gql, useQuery } from '@apollo/client'

export const terminalPriceListQuery = gql`
  query terminalPriceList($id: Int) {
    terminalPriceList(id: $id) {
      id
      wasteType {
        id
        name
        code
      }
      pricePerTonneKc
    }
  }
`

export type TerminalPriceListType = {
  id: number
  wasteType: {
    id: number
    name: string
    code: string
  }
  pricePerTonneKc: number
}

export function useTerminalPriceListQuery(id) {
  return useQuery<{ terminalPriceList: TerminalPriceListType[] }>(terminalPriceListQuery, {
    variables: {id}
  })
}
