import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const deleteSupplierSupplierMutation = gql`
  mutation deleteSupplierSupplierMutation($supplierId: String!) {
    deleteSupplierSupplier(supplierId: $supplierId) {
      status {
        reason
        result
      }
    }
  }
`

export function useDeleteSupplierSupplierMutation() {
  return useMutation<{ deleteSupplierSupplier: IMutationResult }>(deleteSupplierSupplierMutation, { refetchQueries: ['supplierSupplierList'] })
}
