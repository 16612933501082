import { Maybe, Supplier, Terminal } from '../../graphql/aminTypes'
import { useMap } from 'react-leaflet'
import { useEffect } from 'react'
import L from 'leaflet'

export function MapUpdater({
  customerGps,
  supplier,
  terminal
}: {
  customerGps?: { lat: number; lng: number }
  supplier?: { lat: number; lng: number }
  terminal?: { lat: number; lng: number }
}) {
  const map = useMap()

  useEffect(() => {
    if (customerGps && supplier) {
      const customerLatLng = L.latLng(customerGps.lat, customerGps.lng)
      const supplierLatLng = L.latLng(supplier.lat, supplier.lng)
      const terminalLatLng = terminal ? L.latLng(terminal.lat, terminal.lng) : null

      const newBounds = L.latLngBounds([customerLatLng, supplierLatLng, ...(terminalLatLng ? [terminalLatLng] : [])]) // Create new bounds including customer and supplier
      map.fitBounds(newBounds, { paddingBottomRight: [20, 20], paddingTopLeft: [20, 20] }) // Add some padding for better visibility
    } else if (customerGps) {
      map.flyTo([customerGps.lat, customerGps.lng], 11)
    }
  }, [supplier, customerGps, terminal, map])

  return null
}
