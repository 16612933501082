import { AccountingGroupInvoice } from '../../../../graphql/aminTypes'
import {
  ColumnAlign,
  ColumnFilterTypes,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../../services/bootstrapTable/services/tableSpecificationTypes'
import { formatDateForDisplayShort, formatDateTimeForDisplayShort } from '../../../../services/dataToString/dateTimeFormatter'
import { moneyToLocaleString } from '../../../../services/dataToString/decimalToLocaleString'
import { addInvoiceActions } from '../../../../services/bootstrapTable/add-invoice-actions'

export const groupInvoicesDataDefinition: TableDefinitionTypes<AccountingGroupInvoice> = {
  columnDefinition: {
    id: { hidden: true },
    index: { hidden: true },
    variableSymbol: { name: 'VS', filterType: ColumnFilterTypes.customText },
    externalReference: { name: 'Externí reference', filterType: ColumnFilterTypes.customText, editable: true },
    orders: { name: 'Za objednávky' },
    createdDate: { name: 'Vytvořena', filterType: ColumnFilterTypes.dateRange, headerStyle: { width: '100px' } },
    dueDate: { name: 'Splatnost' },
    duzpDate: { name: 'DUZP' },
    amountInclVatKc: { name: 'Částka s DPH' },
    customer: { name: 'Zákazník', filterType: ColumnFilterTypes.customText },
    clientEmail: { name: 'Email zákazníka', editable: true },
    emailSentAt: { name: 'Email odeslán', filterType: ColumnFilterTypes.customSelect },
    paidOn: { name: 'Zapl.', columnFormatterType: ColumnFormatterTypes.dateFormatter, align: ColumnAlign.center },
    actions: {
      name: 'Akce',
      columnFormatterType: ColumnFormatterTypes.actionsObject,
      headerStyle: { width: '210px' }
    }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    index: (x, index) => index,
    customer: x =>
      x.customer
        ? x.customer.companyName || `${x.customer.firstname} ${x.customer.lastname}`
        : x.orders[0].companyName || `${x.orders[0].firstname} ${x.orders[0].lastname}`,
    orders: x => x.orders.map(o => o.orderNumber).join(', ') ?? '',
    createdDate: x => formatDateForDisplayShort(x.createdDate),
    dueDate: x => (x.dueDate ? formatDateForDisplayShort(x.dueDate) : 'N/A'),
    duzpDate: x => (x.duzpDate ? formatDateForDisplayShort(x.duzpDate) : 'N/A'),
    amountInclVatKc: x => (x.amountInclVatKc ? moneyToLocaleString(x.amountInclVatKc, 'CZK') : 'N/A'),
    variableSymbol: x => x.variableSymbol,
    clientEmail: x => x.clientEmail,
    emailSentAt: x => (x.emailSentAt ? formatDateTimeForDisplayShort(parseInt(x.emailSentAt)) : ''),
    externalReference: x => x.externalReference ?? '',
    paidOn: x => x.paidOn,
    actions: x => {
      const ret: IRowAction[] = []
      ret.push({
        type: RowActionType.codeAction,
        value: 'markAsEmailSent',
        icon: 'fe-calendar',
        title: 'Ručně odesláno'
      })
      ret.push({
        type: RowActionType.codeAction,
        value: 'markAsPaid',
        icon: 'fe-dollar-sign',
        title: 'Zaplacena'
      })
      ret.push({
        type: RowActionType.codeAction,
        value: 'downloadCsv',
        icon: 'fe-file-text',
        title: 'Stáhnout CSV export'
      })
      addInvoiceActions(ret, x)
      ret.push({
        type: RowActionType.codeAction,
        value: 'deleteGroupInvoice',
        icon: 'fe-x',
        title: 'Smazat fakturu'
      })
      return ret
    }
  }
}

const openInvoiceLink = (invoiceLink: string) => () => {
  window.open(invoiceLink, '_blank', 'noopener,noreferrer')
}
