import React, { useState } from 'react'
import { IStringObject } from '../../services/types/IStringObject'
import { enterListener } from '../../services/functions/enterListener'

export interface ICustomTextInnerFilterParams {
  onFilter: (value: ICustomFilterReturnValue) => void
  placeholder: string
  valueLink: [string, React.Dispatch<React.SetStateAction<string>>]
}

export enum filterAction {
  set,
  reset
}

export interface ICustomFilterReturnValue extends IStringObject {
  action: filterAction
  value?: any
}

export default function CustomTextFilterInner({ onFilter, placeholder, valueLink }: ICustomTextInnerFilterParams) {
  const [showEnter, setShowEnter] = useState<boolean>(false)
  const [inputValue, setInputValue] = valueLink
  function runFiltration() {
    if (inputValue) onFilter({ action: filterAction.set, value: inputValue })
    else onFilter({ action: filterAction.reset })
  }

  function resetFiltration() {
    setInputValue('')
    onFilter({ action: filterAction.reset })
  }

  return (
    <div className="input-group">
      <input
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        type="text"
        className="form-control"
        placeholder={placeholder}
        onKeyDown={enterListener(runFiltration)}
        onBlur={() => {
          runFiltration()
          setShowEnter(false)
        }}
        onFocus={() => setShowEnter(true)}
      />
      {showEnter && (
        <button className="btn btn-primary header-button" type="button">
          <i className="fe fe-corner-right-down" />
        </button>
      )}
      {!showEnter && !!inputValue && <button type="button" className="form-input__cancel-icon" aria-label="Clear" onClick={resetFiltration} />}
    </div>
  )
}
