import {
  ColumnAlign,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../services/bootstrapTable/services/tableSpecificationTypes'
import { ContainerItemType } from '../../../services/queries/containersQuery'
import React from 'react'

export const containerListDataDefinition: TableDefinitionTypes<ContainerItemType> = {
  columnDefinition: {
    id: { hidden: true },
    uiOrder: { name: 'Pořadí zobrazení', sort: true, align: ColumnAlign.center },
    enabled: {
      name: 'Povolen',
      columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
      align: ColumnAlign.center
    },
    name: { name: 'Název', sort: true },
    capacityM3: { name: 'Kapacita[m³]', align: ColumnAlign.center },
    tooltip: { name: 'Tooltip' },
    uiPicture: { name: 'Obrázek' },
    offerOnUiAsDefault: {
      name: 'Zobrazit na UI před vyplněním adresy',
      columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
      align: ColumnAlign.center
    },
    priceFrom: { name: 'Zobrazená cena od [Kč]', sort: true, align: ColumnAlign.center },
    actions: {
      name: 'Akce',
      columnFormatterType: ColumnFormatterTypes.actionsObject,
      align: ColumnAlign.right,
      headerStyle: { width: '80px' }
    }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    name: x => x.name,
    uiPicture: x => (
      <img src={`https://${process.env.REACT_APP_FE_HOSTNAME}/assets/images/containers/${x.uiPicture}.svg`} width="80" title={x.uiPicture} alt={x.uiPicture} />
    ),
    priceFrom: x => x.priceFrom,
    capacityM3: x => x.capacityM3,
    enabled: x => !x.disabled,
    tooltip: x => x.tooltip,
    offerOnUiAsDefault: x => x.offerOnUiAsDefault,
    uiOrder: x => x.uiOrder,
    actions: x => {
      const ret: IRowAction[] = []
      if (!x.disabled)
        ret.push({
          type: RowActionType.codeAction,
          value: 'disableContainer',
          icon: 'fe-arrow-down',
          title: 'Nepoužívat kontejner'
        })
      else
        ret.push({
          type: RowActionType.codeAction,
          value: 'enableContainer',
          icon: 'fe-arrow-up',
          title: 'Povolit kontejner'
        })
      ret.push({
        type: RowActionType.codeAction,
        value: 'editContainer',
        icon: 'fe-edit',
        title: 'Upravit kontejner'
      })
      return ret
    }
  }
}
