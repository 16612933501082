export const supplierDefaultValues = {
  deliveryDayThresholdMinutes: 840,
  expressDeliveryFromMinutes: 2880,
  normalDeliveryFromMinutes: 2880,
  priority: 2,
  overwriteResidence: true,
  overwriteSupplierCenter: true,
  sendOrderAllEmails: true,
  useFlatRatePriceList: true,
  operationRadiusM: 15000,
  pathCalculationStrategy: '4Ways',
  disabled: true,
  iczNumber: 'CZC',
  capability: []
}
