import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const addOrderCommentMutation = gql`
  mutation addOrderComment($orderId: Int!, $comment: String!) {
    addOrderComment(orderId: $orderId, comment: $comment) {
      status {
        reason
        result
      }
    }
  }
`

export function useAddOrderCommentMutation() {
  return useMutation<{ addOrderComment: IMutationResult }>(addOrderCommentMutation, {
    refetchQueries: ['getOrderComments']
  })
}
