import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../services/types/gqlTypes'
import { contextEndpoint, EndpointEnum } from '../../../../services/appConfiguration/contextEndpoint'

const createWasteFinalMutation = gql`
  mutation createWasteFinal($orderContentId: Int!, $data: WasteFinalDateInput!) {
    createWasteFinal(orderContentId: $orderContentId, data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useCreateWasteFinalMutation() {
  return useMutation<{ createWasteFinal: IMutationResult }>(createWasteFinalMutation, {
    context: contextEndpoint(EndpointEnum.supplier)
  })
}
