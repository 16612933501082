import SelectField from '../../../../components/formComponents/SelectField'
import React from 'react'
import { useProductWasteListFlatQuery } from './queries/product-waste-list.query'
import { WasteCategory } from '../../../../graphql/aminTypes'

export function ProductWasteSelector({
  doNotShowWastes,
  availableWastes,
  wasteCategory,
  disabled,
  showAllAvailableWastes,
  label
}: {
  doNotShowWastes?: string[]
  availableWastes?: string[]
  wasteCategory: WasteCategory
  disabled?: boolean
  showAllAvailableWastes?: boolean
  label: string
}) {
  const productWasteListFlatQR = useProductWasteListFlatQuery(wasteCategory, showAllAvailableWastes)
  const productWastes =
    productWasteListFlatQR.data?.productWasteListFlat
      .filter(x => !(doNotShowWastes ?? []).includes(x.id))
      .map(x => ({
        value: x.id,
        disabled: !!x.mainWasteForCode1,
        color: availableWastes ? (availableWastes.includes(x.id) ? 'black' : '#ccc') : 'black',
        label: `${x.hasAdditivesSpecification ? '* ' : ''}${x.parentWasteId ? '- ' : ''}${x.name} [${x.wasteType?.codeMerged ?? ''}] ${
          x.childWastes && x.childWastes.length > 0 ? `(${x.childWastes.length})` : ''
        }`
      })) || []

  return <SelectField name={'productWasteId'} label={label} optionsData={productWastes} disabled={disabled} required />
}
