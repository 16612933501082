/* istanbul ignore file */

import { IStringObject } from '../../types/IStringObject'

export type columnDefinitionType = IStringObject<IColumnDefinitionType>
export type sourceToColumnMappingType<T> = IStringObject<(x: T, index: number) => any>

export interface TableDefinitionTypes<T> {
  columnDefinition: columnDefinitionType
  sourceToColumnMapping: sourceToColumnMappingType<T>
}

export interface IColumnDefinitionType extends IStringObject {
  hidden?: boolean
  name?: string
  sort?: boolean
  remoteSort?: boolean
  customTransPrefix?: string
  filterType?: ColumnFilterTypes
  columnFormatterType?: ColumnFormatterTypes
  headerStyle?: { width?: string; maxWidth?: string; minWidth?: string; zIndex?: number }
  align?: ColumnAlign
  editable?: boolean
  type?: string
  gqlType?: 'string' | 'number' | 'boolean'
  badgeColours?: { [key: string]: string }
  listSeparator?: string
  nameForGqlFilter?: string
  nameForGqlSort?: string
  defaultRemoteSort?: 'asc' | 'desc'
  translateMap?: IStringObject
  isMobile?: boolean
}

export enum ColumnFormatterTypes {
  actionsObject = 'actionsObject',
  htmlListWithTranslations = 'htmlListWithTranslations',
  htmlList = 'htmlList',
  translateCell = 'translateCell',
  showInBadge = 'showInBadge',
  dateFormatter = 'dateFormatter',
  datetimeFormatter = 'datetimeFormatter',
  numberFormatter = 'numberFormatter',
  moneyFormatter = 'moneyFormatter',
  badgeAndTranslate = 'badgeAndTranslate',
  yesNoOptionalFormatter = 'yesNoOptionalFormatter',
  monospacedFont = 'monospacedFont',
  percentage = 'percentage',
  showAsLink = 'showAsLink'
}

export enum ColumnFilterTypes {
  text = 'text',
  customText = 'customText',
  customNumeric = 'customNumeric',
  select = 'select',
  customSelect = 'customSelect',
  date = 'date',
  dateRange = 'dateRange',
  numberRange = 'numberRange'
}

export enum ColumnAlign {
  left = 'left',
  center = 'center',
  right = 'right'
}

export interface IRowAction {
  type: RowActionType
  title: string
  icon: string
  value: string
  wayOfIdHandover?: WayOfIdHandover
  stateValueExtension?: IStringObject
  searchStringBuilder?: (row: any) => string
}

export enum WayOfIdHandover {
  search = 'search',
  state = 'state'
}

export enum RowActionType {
  link = 'link',
  routerLink = 'routerLink',
  codeAction = 'codeAction'
}
