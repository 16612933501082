import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const addContainerToSupplierMutation = gql`
  mutation addContainerToSupplier($branchId: ID!, $data: SupplierContainerInputArgs!) {
    addContainerToSupplier(branchId: $branchId, data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useAddContainerToSupplierMutation() {
  return useMutation<{ addContainerToSupplier: IMutationResult }>(addContainerToSupplierMutation, { refetchQueries: ['supplierContainerList'] })
}
