import { useSupplierWasteCollectionLinksQuery } from './queries/supplier-waste-collection-links.query'
import React, { useContext } from 'react'
import { SelectedSupplierModalContext } from '../../SelectedSupplierModal/SelectedSupplierModal'
import { WasteCategoryType } from '../../../services/types/waste-category'
import { useDeleteWasteCollectionLinkMutation } from './mutations/delete-waste-connection-link.mutation'
import LoadingContainer from '../../../components/LoadingContainer'
import { isAnyQueryLoading } from '../../../services/functions/queryHelpers'
import { AddTerminal } from './components/AddTerminal/AddTerminal'
import BootstrapTable from 'react-bootstrap-table-next'
import useBootstrapTableDataMapper from '../../../services/bootstrapTable/useBootstrapTableDataMapper'
import { wasteCollectionLinkDataDefinition } from './services/waste-collection-link-data-definition'
import { processPageOperation } from '../../../services/formsServices/pageOperationProcessor'
import { useUpdateWasteCollectionLinkMutation } from './mutations/update-waste-connection-link.mutation'
import cellEditFactory from 'react-bootstrap-table2-editor'
import { formatEditorValue } from '../../../services/bootstrapTable/format-editor-value'

export function WasteLinkComponent({ wasteCategory, branchServiceId }: { wasteCategory: WasteCategoryType; branchServiceId: string }) {
  const supplierWasteCollectionLinksQR = useSupplierWasteCollectionLinksQuery(branchServiceId, wasteCategory)

  const [deleteWasteCollectionLinkMut, deleteWasteCollectionLinkQR] = useDeleteWasteCollectionLinkMutation()
  const [updateWasteCollectionLinkMut, updateWasteCollectionLinkQR] = useUpdateWasteCollectionLinkMutation()

  const { generateColumnListForBootstrapTable, mapDataArrayToTable } = useBootstrapTableDataMapper(wasteCollectionLinkDataDefinition)
  const columnDefinition = generateColumnListForBootstrapTable({
    actionsReference: {
      deleteLink: deleteLinkHandler
    }
  })

  const tableData = mapDataArrayToTable(supplierWasteCollectionLinksQR.data?.supplierWasteCollectionLinks)
  return (
    <LoadingContainer showLoader={isAnyQueryLoading(supplierWasteCollectionLinksQR, deleteWasteCollectionLinkQR, updateWasteCollectionLinkQR)}>
      <AddTerminal
        wasteCategory={wasteCategory}
        branchServiceId={branchServiceId}
        doNotShowTerminals={supplierWasteCollectionLinksQR.data?.supplierWasteCollectionLinks.map(x => x.terminalBranch.id) ?? []}
      />
      <br />
      <BootstrapTable
        bootstrap4
        striped
        condensed
        classes="table-responsive-lg digiTable"
        noDataIndication={() => <>Nejsou k dispozici žádné koncovky</>}
        keyField="id"
        data={tableData}
        columns={columnDefinition}
        cellEdit={cellEditFactory({
          mode: 'click',
          blurToSave: true,
          autoSelectText: true,
          beforeSaveCell: updateWasteCollectionLinkHandler
        })}
      />
    </LoadingContainer>
  )
  function deleteLinkHandler({ id }) {
    const promise = deleteWasteCollectionLinkMut({ variables: { id } })
    processPageOperation({
      promise,
      successMessage: 'Koncovka byla odpojena'
    })
  }

  function updateWasteCollectionLinkHandler(oldValue, newValue, row, column) {
    if (!row.id || oldValue === newValue) return

    let variables = { wasteCollectionLinkId: row.id, includedKmInFlatRate: row.includedKmInFlatRate, transportFlatRateKc: row.transportFlatRateKc }
    variables[column.dataField] = formatEditorValue(newValue, column)

    const resultPromise = updateWasteCollectionLinkMut({
      variables
    })
    processPageOperation({
      promise: resultPromise,
      successMessage: 'Hodnota byla upravena'
    })
  }
}
