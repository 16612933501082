import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const deleteSupplierMutation = gql`
  mutation deleteSupplier($supplierId: Int!) {
    deleteSupplier(supplierId: $supplierId) {
      status {
        reason
        result
      }
    }
  }
`

export function useDeleteSupplierMutation() {
  return useMutation<{ deleteSupplier: IMutationResult }>(deleteSupplierMutation)
}
