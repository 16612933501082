import React from 'react'
import SelectField from '../SelectField'
import { useSearchByCompanyNameInAresQuery } from './queries/getCompanyInfoFromAresQuery'
import { IReactSelectItem } from '../../../services/types/ReactSelect'
import { SubjectInfoType } from '../../../graphql/publicTypes'

export default function CompanyNamePicker({
  label,
  disabled,
  name,
  setupCompanyDataFromSelection
}: {
  label: string
  disabled?: boolean
  name: string
  setupCompanyDataFromSelection: (data: SubjectInfoType) => void
}) {
  const searchByCompanyQR = useSearchByCompanyNameInAresQuery()
  return (
    <div>
      <SelectField
        name={name}
        label={label}
        placeholder={'Napište název společnosti...'}
        disabled={disabled}
        isAsync
        loadOptions={loadCompanyNames}
        handleChange={handleChange}
      />
    </div>
  )

  function handleChange(ico: string, data?: SubjectInfoType) {
    if (!data) return
    setupCompanyDataFromSelection(data)
  }
  function loadCompanyNames(inputValue: string, callback: (options: IReactSelectItem<SubjectInfoType>[]) => void) {
    if (!inputValue || inputValue.length < 2) return
    searchByCompanyQR.refetch({ companyName: inputValue }).then(x => {
      if (!x || !x.data || !x.data.searchByCompanyNameInAres || x.data.searchByCompanyNameInAres.length === 0) {
        callback([])
        return
      }
      callback(
        x.data.searchByCompanyNameInAres.map(y => ({
          value: y.ico,
          label: `${y.name} ICO: ${y.ico} (${y.city} - ${y.street ?? ''} ${y.streetNumber ?? ''})`,
          data: y
        }))
      )
    })
  }
}
