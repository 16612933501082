import React from 'react'
import classNames from 'classnames'

export function FlagIcon({
  className,
  value,
  desc,
  useEmptyIcon,
  onClick
}: {
  className: string
  value: boolean
  desc: string
  useEmptyIcon?: boolean
  onClick?: any
}) {
  if (!useEmptyIcon)
    return <i className={classNames('mt-2', 'mr-2_5', className, value ? undefined : 'fe-disabled-icon')} title={desc} onClick={onClick} />
  else return <i className={classNames('mt-2', 'mr-2_5', value ? className : 'icon-empty')} title={desc} onClick={onClick} />
}
