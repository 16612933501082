import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'
import { contextEndpoint, EndpointEnum } from '../../../services/appConfiguration/contextEndpoint'

const performStateActionMutation = gql`
  mutation performStateAction($orderContentIds: [Int]!, $action: performStateActionEnum!, $reason: String) {
    performStateAction(orderContentId: $orderContentIds, action: $action, reason: $reason) {
      status {
        reason
        result
      }
    }
  }
`

export function usePerformStateActionMutation() {
  return useMutation<{ performStateAction: IMutationResult }>(performStateActionMutation, { context: contextEndpoint(EndpointEnum.supplier) })
}

export function createParamsForPerformStateActionMutation(ids: number[], action: performStateActionEnum, reason?: string) {
  return { orderContentIds: ids, action: action, reason: reason }
}

export enum performStateActionEnum {
  'CANCEL_BY_ADMIN' = 'CANCEL_BY_ADMIN',
  'APPROVE' = 'APPROVE',
  'CANCEL_BY_SUPPLIER' = 'CANCEL_BY_SUPPLIER',
  'CONFIRM' = 'CONFIRM',
  'CONTAINER_ARRIVE' = 'CONTAINER_ARRIVE',
  'CONTAINER_TAKE' = 'CONTAINER_TAKE',
  'ORDER_FINISHED_BY_SUPPLIER' = 'ORDER_FINISHED_BY_SUPPLIER',
  'MATERIAL_DELIVERED' = 'MATERIAL_DELIVERED'
}
