export const actionTranslation: Record<
  string,
  { buttonClass: string; actionConfirmation: string; buttonLabel: string; notification: (orderNumber: string) => string }
> = {
  APPROVE: {
    actionConfirmation: 'Schválení objednávky',
    buttonLabel: 'POTVRZUJI',
    buttonClass: 'btn-success',
    notification: (orderNumber: string) => `Potvrďte schválení objednávky č. ${orderNumber} a předání na dodavatele.`
  },
  CONFIRM: {
    actionConfirmation: 'Přijetí poptávky',
    buttonLabel: 'PŘIJÍMÁM',
    buttonClass: 'btn-success',
    notification: (orderNumber: string) => `Potvrďte, prosím, přijetí zakázky č. ${orderNumber}.`
  },
  CANCEL_BY_SUPPLIER: {
    actionConfirmation: 'Odmítnutí poptávky',
    buttonLabel: 'ODMÍTÁM',
    buttonClass: 'btn-danger',
    notification: (orderNumber: string) => `Skutečně chcete zakázku č. ${orderNumber} odmítnout?`
  },
  CONTAINER_ARRIVE: {
    actionConfirmation: 'Potvrzení přistavení kontejneru u objednávky',
    buttonLabel: 'PŘISTAVEN',
    buttonClass: 'btn-success',
    notification: (orderNumber: string) => `Potvrzuji, že kontejner z objednávky č. ${orderNumber} byl přistaven.`
  },
  CONTAINER_TAKE: {
    actionConfirmation: 'Potvrzení odvozu kontejneru u objednávky',
    buttonLabel: 'ODVEZEN',
    buttonClass: 'btn-success',
    notification: (orderNumber: string) => `Potvrzuji, že kontejner z objednávky č. ${orderNumber} byl odvezen.`
  },
  MATERIAL_DELIVERED: {
    actionConfirmation: 'Potvrzení návozu materiálu',
    buttonLabel: 'Materiál navezen',
    buttonClass: 'btn-success',
    notification: (orderNumber: string) => `Potvrzuji, že byl materiál z objednávky č. ${orderNumber} navezen.`
  }
}
