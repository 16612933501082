import { gql, useMutation } from '@apollo/client'
import {IMutationResult} from "../../../../../services/types/gqlTypes";

const updateContainerMutation = gql`
  mutation updateContainer($containerId: Int!, $data: ContainerInput!) {
    updateContainer(containerId: $containerId, data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpdateContainerMutation() {
  return useMutation<{ updateContainer: IMutationResult }>(updateContainerMutation)
}
