import PageCard from '../../components/Cards/pageCard'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import ActiveOrdersMap from './ActiveOrdersMap/ActiveOrdersMap'
import Statistics from './Statistics/Statistics'
import HeatMap from './heat-map/heat-map'

type DashboardProps = {
  pageCardTabs: 'statistics' | 'activeOrdersMap' | 'heatMap'
  orderContentStates: 'ActiveOrders' | 'ConfirmedBySupplier' | 'ContainerArrived' | 'ContainerTakenBack'
  poolInterval: number
}

export default function Dashboard() {
  const formMethods = useForm<DashboardProps>({ defaultValues: { pageCardTabs: 'statistics', orderContentStates: 'ActiveOrders', poolInterval: 10 } })
  const showWindow = formMethods.watch('pageCardTabs')
  return (
    <FormProvider {...formMethods}>
      <PageCard
        headerTitle="Dashboard"
        cardTabs={[
          { value: 'statistics', label: 'Statistiky' },
          {
            value: 'activeOrdersMap',
            label: 'Mapa aktivních objednávek'
          },
          {
            value: 'heatMap',
            label: 'Heat mapa'
          }
        ]}
      >
        {showWindow === 'activeOrdersMap' && <ActiveOrdersMap />}
        {showWindow === 'statistics' && <Statistics />}
        {showWindow === 'heatMap' && <HeatMap />}
      </PageCard>
    </FormProvider>
  )
}
