import { IFormComponentBaseInput } from './IFormComponentBaseInput'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import classNames from 'classnames'
import { isArray } from 'util'

export function FormComponentBaseInput({ name, label, required, children, description, hideValidationError, defaultComponentStyle }: IFormComponentBaseInput) {
  const { formState } = useFormContext()
  const errors = formState.errors
  let componentName = name
  let index: number | undefined = undefined
  let hasIndexError = false
  if (name.indexOf('[') >= 0) {
    componentName = name.slice(0, name.indexOf('['))
    index = Number.parseInt(name.slice(name.indexOf('[') + 1))
    hasIndexError = index !== undefined && errors?.[componentName]?.[index] !== undefined
  }
  return (
    <div className={classNames(defaultComponentStyle)}>
      {label && (
        <label htmlFor={name} className={classNames('form-label', required ? 'required' : '')}>
          {label}
          {description && <span className="form-label-description">{description}</span>}
        </label>
      )}
      {children && children}
      {!hasIndexError && errors[name] && errors[name]?.message && !hideValidationError && (
        <div className="invalid-feedback">{errors[name].message.toString()}</div>
      )}
      {hasIndexError && index && !hideValidationError && <div className="invalid-feedback">{String(errors[componentName]?.[index]?.message)}</div>}
    </div>
  )
}
