import React from 'react'
import { useNavigate } from 'react-router-dom'
import PageCard from '../../components/Cards/pageCard'
import { FormProvider, useForm } from 'react-hook-form'
import AddressPicker from '../../components/formComponents/AddressPicker/AddressPicker'
import FormField, { inputType } from '../../components/formComponents/FormField'
import { getParamFromUrl } from '../../services/functions/getParamFromUrl'
import { isAnyQueryLoading } from '../../services/functions/queryHelpers'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import { createParamsForCreateTerminalMutation, useCreateTerminalMutation } from './services/createTerminalMutation'
import { useUpdateTerminalMutation } from './services/updateTerminalMutation'
import { TerminalDetailItemType, useTerminalsDetailQuery } from './services/terminalDetailQuery'
import { setupFields } from '../../services/formsServices/setupFields'

export default function AddOrEditTerminal() {
  const formMethods = useForm()
  const terminalId = getParamFromUrl('terminalId') as string | null
  const terminalDetailQR = useTerminalsDetailQuery(terminalId, loadTerminalData)
  const [createTerminalMut, createTerminalMutQR] = useCreateTerminalMutation()
  const [updateTerminalMut, updateTerminalMutQR] = useUpdateTerminalMutation()
  const navigate = useNavigate()

  return (
    <PageCard
      headerTitle={terminalId ? 'Editace Koncovky' : 'Nová koncovka'}
      showLoader={isAnyQueryLoading(terminalDetailQR, createTerminalMutQR, updateTerminalMutQR)}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
          <h2>Adresa:</h2>
          <AddressPicker name={'address'} required registerOptions={{ required: 'Poloha nebyla nalezena.' }} />
          <h2>Dodavatel:</h2>
          <fieldset className="form-fieldset">
            <div className="row mb-2">
              <div className="col-md-12 mb-2">
                <FormField type={inputType.text} name={'name'} label={'Název'} required registerOptions={{ required: true }} />
              </div>
              <div className="col-md-6">
                <FormField type={inputType.text} name={'contactPerson'} label={'Kontaktní osoba'} />
              </div>
              <div className="col-md-6">
                <FormField type={inputType.text} name={'companyIdentification'} label={'IČO'} />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-6">
                <FormField type={inputType.text} name={'web'} label={'Web'} />
              </div>
              <div className="col-md-6">
                <FormField type={inputType.text} name={'phone'} label={'Telefon'} />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-6">
                <FormField type={inputType.email} name={'email'} label={'E-mail'} />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-6">
                <FormField
                  type={inputType.number}
                  isPositive
                  name={'operationRadiusM'}
                  label={'Rádius [m]'}
                  required
                  defaultValue={'35000'}
                  registerOptions={{ required: true }}
                />
              </div>
            </div>
          </fieldset>
          <div className="card-footer ">
            <div className="justify-content-center d-flex">
              <button type="submit" className="btn btn-primary ms-auto btn-lg">
                {terminalId ? 'Uložit změny' : 'Vytvořit koncovku'}
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </PageCard>
  )

  function handleFormSubmit(data: Record<string | number | symbol, any>) {
    let resultPromise = terminalId
      ? updateTerminalMut({ variables: { terminalId: parseInt(terminalId), ...createParamsForCreateTerminalMutation(data) } })
      : createTerminalMut({ variables: createParamsForCreateTerminalMutation(data) })

    processPageOperation({
      promise: resultPromise,
      successAction: () => {
        if (terminalId) navigate(-1)
        else navigate('/terminal-list')
      }
    })

    return false
  }

  function loadTerminalData(data: { terminals: TerminalDetailItemType[] }) {
    if (data.terminals.length === 1) {
      const terminal = data.terminals[0]
      setupFields(terminal, { skipFields: ['id', 'gps', 'street', 'city'], formMethods })
      formMethods.setValue('address', {
        lng: terminal.gps.coordinates[0].toString(),
        lat: terminal.gps.coordinates[1].toString(),
        street: terminal.street,
        streetNumber: terminal.streetNumber,
        city: terminal.city,
        zip: terminal.zip
      })
    }
  }
}
