import { DemandChangeLogEntity, EmailHistory } from '../../../../../graphql/aminTypes'
import {
  ColumnAlign,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../../../services/bootstrapTable/services/tableSpecificationTypes'
import dayjs from 'dayjs'

export const orderChangeLogDataDefinition: TableDefinitionTypes<DemandChangeLogEntity> = {
  columnDefinition: {
    id: { hidden: true },
    createdAt: { name: 'Datum' },
    login: { name: 'Uživatel' },
    source: { name: 'Zdroj' },
    operation: { name: 'Operace' },
    variables: { name: 'Vstupní parametry' },
    result: { name: 'Výsledek' }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    createdAt: x => dayjs(x.createdAt).format('LLL'),
    login: x => (x.login ? x.login.firstname + ' ' + x.login.lastname : 'N/A'),
    operation: x => x.operation,
    variables: x => (x.variables ? JSON.stringify(x.variables) : ''),
    result: x => (x.result ? JSON.stringify(x.result) : ''),
    source: x => x.source
  }
}
