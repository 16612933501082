import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'

const upsertProductContainerMutation = gql`
  mutation upsertProductContainer($productContainerId: ID, $data: ProductContainerInputArgs!) {
    upsertProductContainer(productContainerId: $productContainerId, data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpsertProductContainerMutation() {
  return useMutation<{ upsertProductContainer: IMutationResult }>(upsertProductContainerMutation, { refetchQueries: ['productContainerList'] })
}
