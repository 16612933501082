import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'
import { contextEndpoint, EndpointEnum } from '../../../../../services/appConfiguration/contextEndpoint'
import { wasteFinalsQuery } from '../query/waste-finals.query'

const updateWasteFinalMutation = gql`
  mutation updateWasteFinal($wasteFinalId: ID!, $data: WasteFinalDateInput!) {
    updateWasteFinal(wasteFinalId: $wasteFinalId, data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpdateWasteFinalMutation(orderContentId: number) {
  return useMutation<{ updateWasteFinal: IMutationResult }>(updateWasteFinalMutation, {
    context: contextEndpoint(EndpointEnum.admin),
    refetchQueries: result => [
      {
        query: wasteFinalsQuery,
        variables: { orderContentId },
        context: contextEndpoint(EndpointEnum.admin)
      }
    ]
  })
}

export function createParamsForUpdateWasteFinalMutation(wasteFinalId: string, data: any) {
  return {
    wasteFinalId,
    data: {
      wasteTypeId: data.wasteTypeId,
      weightTonne: +data.weightTonne,
      icz: data.icz ? data.icz : null,
      terminalName: data.terminalName ? data.terminalName : null
    }
  }
}
