import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const updateProductMaterialComponentMutation = gql`
  mutation updateProductMaterialComponent($id: ID!, $data: ProductMaterialComponentInputArgs!) {
    updateProductMaterialComponent(id: $id, data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpdateProductMaterialComponentMutation() {
  return useMutation<{ updateProductMaterialComponent: IMutationResult }>(updateProductMaterialComponentMutation, {
    refetchQueries: ['productMaterialComponents']
  })
}

export function createParamsForUpdateMaterialMutation(row, field, newValue) {
  const update = {
    name: row.name,
    cubicToTonnes: +row.cubicToTonnes,
    tooltip: row.tooltip,
    type: row.type,
    uiOrder: +row.uiOrder
  }
  if (field === 'cubicToTonnes' || field === 'uiOrder') {
    update[field] = +newValue
  } else {
    update[field] = newValue
  }
  return update
}
