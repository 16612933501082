import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../services/types/gqlTypes'

const upsertContainerRentRateMutation = gql`
  mutation upsertContainerRentRate($containerRentRateId: ID, $data: SupplierContainerRentRateInputArgs!) {
    upsertContainerRentRate(containerRentRateId: $containerRentRateId, data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpsertContainerRentRateMutation() {
  return useMutation<{ upsertContainerRentRate: IMutationResult }>(upsertContainerRentRateMutation, { refetchQueries: ['supplierContainerRentRates'] })
}
