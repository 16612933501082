import { gql, useQuery } from '@apollo/client'
import { IGqlPagingQueryParams } from '../../../services/bootstrapTable/usePagingFromURLQuery'

export const terminalsQuery = gql`
  query terminals($offset: Int, $limit: Int, $id: String, $order: String,  $name: String, $disabled: String) {
    terminals(offset: $offset, limit: $limit, id: $id, order: $order, name: $name, disabled: $disabled) {
      id
      name
      city
      street
      streetNumber
      zip
      gps {
        coordinates
      }
      companyIdentification
      contactPerson
      email
      phone
      disabled
    }
  }
`

export type TerminalItemType = {
  id: number
  name: string
  city: string
  street: string
  streetNumber: string
  zip: string
  gps: {
    coordinates: number[]
  }
  companyIdentification: string
  contactPerson: string
  email: string
  phone: string
  disabled: boolean
}

export function useTerminalsQuery(params: IGqlPagingQueryParams) {
  return useQuery<{ terminals: TerminalItemType[] }>(terminalsQuery, { fetchPolicy: 'network-only', variables: params })
}
