import { ColumnAlign, TableDefinitionTypes } from '../../../services/bootstrapTable/services/tableSpecificationTypes'

export const responseDataDefinition: TableDefinitionTypes<any> = {
  columnDefinition: {
    id: { hidden: true },
    response: {
      name: 'Důvod',
      sort: true
    },
    count: { name: 'Počet', sort: true, align: ColumnAlign.center }
  },
  sourceToColumnMapping: {
    id: x => x.response,
    response: x => x.response,
    count: x => x.count
  }
}



export const customResponsesDataDefinition: TableDefinitionTypes<any> = {
  columnDefinition: {
    id: { hidden: true },
    response: {
      name: 'Vlastní důvod',
    }
  },
  sourceToColumnMapping: {
    id: (x, index) => index,
    response: x => x,
  }
}

