import PageCard from '../../components/Cards/pageCard'
import React, { useContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { TransactionPairing } from './transaction-pairing/TransactionPairing'
import { LoginContext } from '../../services/loginAndToken/useLogin'
import { GroupOrders } from './group-orders/GroupOrders'
import { useNavigate } from 'react-router-dom'
import GroupInvoices from './group-invoices/GroupInvoices'

export default function Accounting() {
  const login = useContext(LoginContext)
  const urlParts = new URL(window.location.href).pathname.split('/')
  const defaultPage = urlParts.length === 3 ? urlParts[2] : 'transaction-pairing'
  const formMethods = useForm({ defaultValues: { pageCardTabs: defaultPage } })
  const showWindow = formMethods.watch('pageCardTabs')
  const navigate = useNavigate()
  if (!['info@metrak.cz', 'patrik.postelt@metrak.cz', 'ilona.kubickova@metrak.cz'].includes(login?.currentJWT?.email || '')) return <></>

  return (
    <FormProvider {...formMethods}>
      <PageCard
        headerTitle="Účetnictví"
        cardTabs={[
          { value: 'transaction-pairing', label: 'Párování plateb', onClick: goToTransactionPairingHandler },
          { value: 'group-orders', label: 'Hromadné objednávky', onClick: goToGroupOrders },
          { value: 'group-invoices', label: 'Hromadné faktury', onClick: goToGroupInvoices }
        ]}
      >
        {showWindow === 'transaction-pairing' && <TransactionPairing />}
        {showWindow === 'group-orders' && <GroupOrders />}
        {showWindow === 'group-invoices' && <GroupInvoices />}
      </PageCard>
    </FormProvider>
  )

  function goToTransactionPairingHandler() {
    navigate('/accounting/transaction-pairing')
  }

  function goToGroupOrders() {
    navigate('/accounting/group-orders')
  }
  function goToGroupInvoices() {
    navigate('/accounting/group-invoices')
  }
}
