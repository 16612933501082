import React from 'react'
import DatePicker from 'react-datepicker'
import { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { cs } from 'date-fns/locale'
registerLocale('cs', cs)

export function useMrtDatepickerEditor() {
  const [value, setValue] = React.useState()
  return ({ cell, column, row, table }) => {
    setValue(cell.getValue())
    table.setEditingRow(row)
    const handleChange = date => {
      setValue(date)
      row._valuesCache[column.id] = date
    }
    return (
      <DatePicker
        dateFormat="dd/MM/yyyy"
        selected={value ?? null}
        onChange={handleChange}
        locale="cs"
        placeholderText={'Vyberte datum'}
        className="form-control"
        todayButton="Dnes"
        isClearable
        popperPlacement="bottom-start" // Ensures correct placement
        popperModifiers={[
          {
            name: 'preventOverflow',
            options: {
              boundary: 'viewport'
            },
            fn: state => {
              return state
            }
          }
        ]}
        portalId="root-portal" // Ensure it's rendered outside the table
      />
    )
  }
}
