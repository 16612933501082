import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../services/types/gqlTypes'

const updateTransportSupplierLinkMutation = gql`
  mutation updateTransportSupplierLink($transportSupplierLinkId: ID!, $transportFlatRateKc: Float, $includedKmInFlatRate: Int) {
    updateTransportSupplierLink(
      transportSupplierLinkId: $transportSupplierLinkId
      transportFlatRateKc: $transportFlatRateKc
      includedKmInFlatRate: $includedKmInFlatRate
    ) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpdateTransportSupplierLinkMutation() {
  return useMutation<{ updateTransportSupplierLink: IMutationResult }>(updateTransportSupplierLinkMutation, {
    refetchQueries: ['supplierTransportSupplierLink']
  })
}
