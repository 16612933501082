import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../services/types/gqlTypes'

const deleteDraftMutation = gql`
  mutation deleteDraft($orderId: Int!) {
    deleteDraft(orderId: $orderId) {
      status {
        reason
        result
      }
    }
  }
`

export function useDeleteDraftMutation() {
  return useMutation<{ deleteDraft: IMutationResult }>(deleteDraftMutation)
}
