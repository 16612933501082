import { gql, useQuery } from '@apollo/client'
import {
  GetAvailableResourcesForTransportOfMaterialResponse,
  GetAvailableResourcesForWasteCollectionResponse,
  GetAvailableServicesForLocalityResponse
} from '../../../../../graphql/aminTypes'
import { WasteCategoryType } from '../../../../../services/types/waste-category'

export const getAvailableResourcesForTransportOfMaterialQuery = gql`
  query getAvailableResourcesForTransportOfMaterial($customerLatLng: LatLngInput!, $radiusKm: Int!, $calculationDate: String!) {
    getAvailableResourcesForTransportOfMaterial(customerLatLng: $customerLatLng, radiusKm: $radiusKm, calculationDate: $calculationDate) {
      availableMaterialIds
    }
  }
`

export function useGetAvailableResourcesForTransportOfMaterialQuery(variables: {
  customerLatLng: { lat: number; lng: number } | undefined
  radiusKm: number
  calculationDate: string
}) {
  return useQuery<{ getAvailableResourcesForTransportOfMaterial: GetAvailableResourcesForTransportOfMaterialResponse }>(
    getAvailableResourcesForTransportOfMaterialQuery,
    {
      fetchPolicy: 'network-only',
      skip: !variables.customerLatLng,
      variables: {
        customerLatLng: variables.customerLatLng,
        radiusKm: variables.radiusKm,
        calculationDate: variables.calculationDate
      }
    }
  )
}
