import { DivIcon } from 'leaflet'

export const createColorPointIcon = (color: string) => {
  return new DivIcon({
    className: 'custom-div-icon',
    html: `<div style="background-color: ${color}; width: 25px; height: 25px; border-radius: 50%; border: 3px solid black;"></div>`,
    iconSize: [20, 20],
    iconAnchor: [10, 10] // Center the icon
  })
}
