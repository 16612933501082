import {
  ColumnAlign,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../../services/bootstrapTable/services/tableSpecificationTypes'
import { moneyToLocaleString } from '../../../../services/dataToString/decimalToLocaleString'
import { SupplierPriceListType } from './supplierPriceListQuery'

export function supplierPriceListCollectionDataDefinition(isFlatRate: boolean): TableDefinitionTypes<SupplierPriceListType> {
  return {
    columnDefinition: {
      id: { hidden: true },
      containerId: { hidden: true },
      disabled: {
        name: 'Aktivní',
        columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
        align: ColumnAlign.center,
        headerStyle: { width: '50px' }
      },
      container: { name: 'Kontejner', sort: true },
      baseRentPriceKc: { hidden: isFlatRate, name: 'Cena za manipulaci [Kč]' },
      pricePerKmKc: { name: 'Cena za km [Kč]' },
      minimumPriceForTransport: { hidden: isFlatRate, name: 'Minimální cena za dopravu [Kč]' },
      flatRateRadius: {
        hidden: !isFlatRate,
        name: 'Započítaná vzdálenost v paušálu [km]',
        headerStyle: { width: '250px' }
      },
      flatPriceFor: {
        hidden: !isFlatRate,
        name: 'Paušál nastaven pro typy odpadu',
        columnFormatterType: ColumnFormatterTypes.showAsLink,
        align: ColumnAlign.right
      },
      actions: {
        name: 'Akce',
        columnFormatterType: ColumnFormatterTypes.actionsObject,
        align: ColumnAlign.right,
        headerStyle: { width: '60px' }
      }
    },
    sourceToColumnMapping: {
      id: x => x.id,
      containerId: x => x.container.id,
      container: x => `${x.container.name} ${x.container.capacityM3}m³`,
      baseRentPriceKc: x => moneyToLocaleString(x.baseRentPriceKc),
      disabled: x => !x.disabled,
      pricePerKmKc: x => moneyToLocaleString(x.pricePerKmKc),
      minimumPriceForTransport: x => moneyToLocaleString(x.minimumPriceForTransport),
      flatPriceFor: x =>
        [...x.supplierFlatRates]
          .sort((a, b) => a.product.uiOrder - b.product.uiOrder)
          .map(x => `${x.product.name} (${x.flatPriceKc}Kč)`)
          .join(', '),
      flatRateRadius: x => x.flatRateRadius,
      actions: x => {
        const ret: IRowAction[] = []
        ret.push({
          type: RowActionType.codeAction,
          value: 'editPriceList',
          icon: 'fe-edit',
          title: `Upravit ${x.container.name} ${x.container.capacityM3}m³`
        })
        ret.push({
          type: RowActionType.codeAction,
          value: 'deletePriceList',
          icon: 'fe-x',
          title: `Odstranit ${x.container.name} ${x.container.capacityM3}m³`
        })
        return ret
      }
    }
  }
}
