import React, { useState } from 'react'
import { Column } from 'react-bootstrap-table-next'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { cs } from 'date-fns/locale'
import dayjs from 'dayjs'

registerLocale('cs', cs) // register it with the name you want

interface IDateRangeSelectorParams {
  onFilter: Function
  column: Column<string>
  defaultDateFrom?: Date
  defaultDateTo?: Date
}

export default function DateRangeSelectorFilter({ onFilter, column, defaultDateFrom, defaultDateTo }: IDateRangeSelectorParams) {
  const [startDate, setStartDate] = useState(defaultDateFrom && dayjs(defaultDateFrom).isValid() ? dayjs(defaultDateFrom).toDate() : undefined)
  const [endDate, setEndDate] = useState(defaultDateTo && dayjs(defaultDateTo).isValid() ? dayjs(defaultDateTo).toDate() : undefined)
  const onChange = dates => {
    if (!dates) dates = [null, null]
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
    onFilter({ from: start, to: end })
  }
  return (
    <DatePicker
      selectsRange
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      isClearable
      dateFormat="dd/MM/yyyy"
      locale="cs"
      className="form-control"
      placeholderText={'Vyberte datum'}
      todayButton="Dnes"
    />
  )
}
