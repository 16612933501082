import { gql, useQuery } from '@apollo/client'
import { contextEndpoint, EndpointEnum } from '../../../services/appConfiguration/contextEndpoint'

export const containerTypesForProductQuery = gql`
  query containerTypesForProduct($locality: GpsCoordinations!, $productId: Int!) {
    containerTypesForProduct(productId: $productId, locality: $locality) {
      containerId
      priceKcWoVat
      normalDeliveryFromDate
      expressDeliveryFromDate
      containerRentPriceFromDay
      expressDeliveryFeeKc
      publicHolidayFeeKc
      containerRentPricePerDayKc
      allowWorkOnHolidays
      allowWorkOnSaturday
      allowWorkOnSunday
      acceptCod
    }
  }
`

export function useContainerTypesForProductQuery(
  lng: string | undefined,
  lat: string | undefined,
  productId: number,
  onCompleted?: (data: { containerTypesForProduct: ContainerTypesForProductResult[] }) => void
) {
  return useQuery<{ containerTypesForProduct: ContainerTypesForProductResult[] }>(containerTypesForProductQuery, {
    skip: !lng || !lat || !productId,
    onCompleted,
    context: contextEndpoint(EndpointEnum.public),
    fetchPolicy: 'no-cache',
    variables: {
      productId: productId,
      locality: {
        lng,
        lat
      }
    }
  })
}

export interface ContainerTypesForProductResult {
  containerId: number
  priceKcWoVat: number
  normalDeliveryFromDate: string
  expressDeliveryFromDate: string
  containerRentPriceFromDay: number
  expressDeliveryFeeKc: number
  publicHolidayFeeKc: number
  containerRentPricePerDayKc: number
  allowWorkOnHolidays: boolean
  allowWorkOnSaturday: boolean
  allowWorkOnSunday: boolean
  acceptCod: boolean
}
