import { gql, useMutation } from '@apollo/client'
import { IMutationResult} from "../../../../../services/types/gqlTypes";

const deleteMaterialMutation = gql`
    mutation deleteMaterial($id: Int!) {
        deleteMaterial(id: $id) {
            status {
                reason
                result
            }
        }
    }
`

export function useDeleteMaterialMutation() {
    return useMutation<{ deleteMaterial: IMutationResult }>(deleteMaterialMutation)
}
