import React, { ReactFragment } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

export const pageCardHeaderButton = (label: string | ReactFragment, action: any, key: string, title?: string, style?: string) => (
  <button type="button" key={key} className={classNames('btn btn-block', style ?? 'btn-primary')} onClick={action} title={title}>
    {label}
  </button>
)

export function PageCardLinkHeaderButton(label: string, url: string, title?: string) {
  const navigate = useNavigate()
  return (
    <button type="button" key={url} className="btn btn-primary btn-block" onClick={() => navigate(url)} title={title}>
      {label}
    </button>
  )
}

export function PageCardLinkHeaderText(label: string, url: string) {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {label}
    </a>
  )
}
