import Big from 'big.js'
const vat = parseFloat(process.env.REACT_APP_ACCOUNTING_VAT ?? '1.21')

export function addVatToPrice(priceWoVat: number) {
  if (!priceWoVat) return { priceInclVatRounded: 0, rounding: 0 }
  if (typeof priceWoVat !== 'number') priceWoVat = parseFloat(priceWoVat)
  const priceBig = new Big(priceWoVat)
  const vatBig = new Big(vat)

  const priceInclVat = priceBig.times(vatBig).round(2, 1)
  const priceInclVatRounded = priceInclVat.round(0, 1)
  const rounding = priceInclVatRounded.minus(priceInclVat)

  return { priceInclVatRounded: priceInclVatRounded.toNumber(), rounding: rounding.toNumber() }
}
