import { gql, useQuery } from '@apollo/client'

const getDownloadUrlQuery = gql`
  query getDownloadUrl($key: String!) {
    getDownloadUrl(key: $key)
  }
`

export function useGetDownloadUrlQuery() {
  return useQuery<{ getDownloadUrl: string }>(getDownloadUrlQuery, {
    skip: true,
    fetchPolicy: 'network-only'
  })
}
