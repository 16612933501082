import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const createTerminalMutation = gql`
  mutation createTerminal($data: TerminalDataInput!) {
    createTerminal(data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useCreateTerminalMutation() {
  return useMutation<{ createTerminal: IMutationResult }>(createTerminalMutation)
}

export function createParamsForCreateTerminalMutation(data: any) {
  return {
    data: {
      name: data.name,
      street: data.address.street,
      streetNumber: data.address.streetNumber,
      city: data.address.city,
      zip: data.address.zip,
      gps: { lng: data.address.lng, lat: data.address.lat },
      phone: data.phone,
      email: data.email ? data.email : null,
      web: data.web,
      contactPerson: data.contactPerson,
      companyIdentification: data.companyIdentification,
      operationRadiusM: parseInt(data.operationRadiusM)
    }
  }
}
