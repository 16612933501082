import PageCard from '../../components/Cards/pageCard'
import React, { useContext } from 'react'
import useBootstrapTableDataMapper from '../../services/bootstrapTable/useBootstrapTableDataMapper'
import { isAnyQueryLoading } from '../../services/functions/queryHelpers'
import BootstrapTable from 'react-bootstrap-table-next'
import { ModalContext } from '../../components/Modal/ModalProvider'
import { useProductWasteListQuery } from './queries/product-waste-list.query'
import usePagingFromURLQuery from '../../services/bootstrapTable/usePagingFromURLQuery'
import { productWasteListDataDefinition } from './services/product-waste-list-data-definition'
import { ProductWasteEntity } from '../../graphql/aminTypes'
import useBootstrapTablePagingAndFiltration from '../../services/bootstrapTable/useBootstrapTablePagingAndFiltration'
// @ts-ignore
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import { BootstrapTablePagingWrapper } from '../../components/bootstrapTable/BootstrapTablePagingWrapper'
import filterFactory from 'react-bootstrap-table2-filter'
import { usePrepareFilterOptions } from '../../scenesAdmin/OrderList/services/usePrepareFilterOptions'
import { serviceTypeSelectOptions } from '../../services/types/service-type'
import { wasteCategoryTypeOptions } from '../../services/types/waste-category'
import ProductWasteListRow from './ProductWasteListRow'
import AddOrEditMaterial from '../ProductMaterialList/components/AddOrEditMaterial/AddOrEditMaterial'
import AddOrEditWastes from './components/AddOrEditWaste/AddOrEditWaste'
import { pageCardHeaderButton } from '../../components/Cards/pageCardHeaderButton'
import { useDeleteProductWasteMutation } from './mutations/delete-product-waste.mutation'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import { ProductContainerCapacityOfWaste } from '../ProductContainerCapacity/ProductContainerCapacityOfWaste'
import { PriceListsExport, SuppliersExport } from '../../scenesAdmin/SupplierList/components/export-buttons'
import TerminalFeeEditor from './components/TerminalFeeEditor/TerminalFeeEditor'
import { useFillWasteCapacitiesForWasteChildrenMutation } from './mutations/fil-waste-capacities-for-waste-childrens.mutation'

export default function ProductWasteList() {
  const { showModal, hideModal } = useContext(ModalContext)
  const [rowToExpand, setRowToExpand] = React.useState<string | undefined>(undefined)

  const { generateColumnListForBootstrapTable, mapDataArrayToTable, nameForGqlTranslation } =
    useBootstrapTableDataMapper<ProductWasteEntity>(productWasteListDataDefinition)
  const { pageConfiguration, gqlQueryParams } = usePagingFromURLQuery(['name', 'wasteCode', 'wasteCategory'], nameForGqlTranslation, { defaultPageSize: 100 })
  const [deleteWasteMut, deleteWasteMR] = useDeleteProductWasteMutation()
  const [fillWasteCapacities, fillWasteCapacitiesMR] = useFillWasteCapacitiesForWasteChildrenMutation()
  const productWasteListQR = useProductWasteListQuery(gqlQueryParams)
  const { bootstrapTablePaginationSetup, handleTableChange } = useBootstrapTablePagingAndFiltration(
    pageConfiguration,
    undefined,
    true,
    productWasteListQR.data?.productWasteList.length
  )

  const columnDefinition = generateColumnListForBootstrapTable({
    filterDefaultValues: pageConfiguration.filterBy,
    currentSort: pageConfiguration.sort,
    handleTableChange: handleTableChange,
    actionsReference: {
      addSubWaste: addSubWasteHandler,
      deleteWaste: deleteWasteHandler,
      showContainerCapacities: showContainerCapacitiesHandler,
      fillWasteCapacitiesForWasteChildrens: fillWasteCapacitiesForWasteChildrensHandler
    },
    columnAction: handleTableClick
  })

  let productWasteList: any[] = []

  if (productWasteListQR.data && productWasteListQR.data.productWasteList.length > 0)
    productWasteList = mapDataArrayToTable(productWasteListQR.data.productWasteList)

  return (
    <PageCard
      showLoader={isAnyQueryLoading(productWasteListQR, deleteWasteMR, fillWasteCapacitiesMR)}
      headerTitle={'Seznam odpadů'}
      cardOptions={
        <>
          <span className="mr-3">
            {pageCardHeaderButton(
              'Poplatky za odvoz',
              () =>
                showModal({
                  title: 'Poplatky za odvoz',
                  modalContent: <TerminalFeeEditor onExit={hideModal} />,
                  hideFooter: true
                }),
              'TerminalFeeEditor'
            )}
          </span>
          <span>
            {pageCardHeaderButton(
              'Přidat odpad',
              () =>
                showModal({
                  title: 'Přidat odpad',
                  modalContent: <AddOrEditWastes onExit={hideModal} />,
                  hideFooter: true
                }),
              'CreateWaste'
            )}
          </span>
        </>
      }
    >
      <div className="table-responsive-xl">
        <BootstrapTable
          bootstrap4
          striped
          hover
          condensed
          remote
          classes="table-responsive-lg digiTable"
          noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné záznamy"</>}
          keyField="id"
          data={productWasteList}
          columns={columnDefinition}
          filter={filterFactory()}
          sort={pageConfiguration.sort}
          onTableChange={handleTableChange}
          expandRow={expandedRow(rowToExpand)}
        />
      </div>
    </PageCard>
  )

  function handleTableClick(e: Event, column: number, columnIndex: number, row: any) {
    if (!row.id || columnIndex > 9) return
    const waste = productWasteListQR.data?.productWasteList.find(x => x!.id === row.id)
    showModal({
      title: 'Upravit odpad ' + row.name,
      hideFooter: true,
      modalContent: <AddOrEditWastes wasteToEdit={waste} onExit={hideModal} />
    })
  }
  function addSubWasteHandler(row: any) {
    if (!row.id) return
    const waste = productWasteListQR.data?.productWasteList.find(x => x!.id === row.id)
    showModal({
      title: 'Přidat podskupinu odpadu ' + row.name,
      hideFooter: true,
      modalContent: <AddOrEditWastes onExit={hideModal} parentWaste={waste} />
    })
  }
  function showContainerCapacitiesHandler(row: any) {
    if (!row.id) return
    showModal({
      title: 'Kapacity kontejnerů pro odpad ' + row.name,
      hideFooter: false,
      modalContent: <ProductContainerCapacityOfWaste wasteId={row.id} />
    })
  }

  function deleteWasteHandler(row: any) {
    const promise = deleteWasteMut({ variables: { id: row.id } })
    processPageOperation({
      promise,
      successMessage: 'Odpad byl odstraněn'
    })
  }

  function fillWasteCapacitiesForWasteChildrensHandler(row: any) {
    const promise = fillWasteCapacities({ variables: { productWasteId: row.id } })
    processPageOperation({
      promise,
      successMessage: 'Kapacity pro podskupiny odpadu byly vyplněny'
    })
  }

  function expandedRow(rowId: string | undefined) {
    return {
      renderer: function productWasteRowExpand() {
        if (!productWasteListQR.data || rowId === undefined) return <></>
        const payload = productWasteListQR.data.productWasteList.find(x => x?.id === rowId)
        if (!payload?.childWastes || payload?.childWastes.length <= 0) return <></>
        return <ProductWasteListRow data={payload?.childWastes} />
      },
      onlyOneExpanding: true,
      expanded: [rowId],
      showExpandColumn: true,
      expandByColumnOnly: true,
      onExpand: row => {
        if (row.hasAdditivesSpecification) setRowToExpand(row.id)
        else setRowToExpand(undefined)
      }
    }
  }
}
