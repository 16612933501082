import { SelectedSupplierModalContext } from '../SelectedSupplierModal/SelectedSupplierModal'
import React, { useContext } from 'react'
import PageCard from '../../components/Cards/pageCard'
import SupplierServiceEnabler from '../common/components/SupplierServiceEnabler/SupplierServiceEnabler'
import cellEditFactory from 'react-bootstrap-table2-editor'

import useBootstrapTableDataMapper from '../../services/bootstrapTable/useBootstrapTableDataMapper'
import BootstrapTable from 'react-bootstrap-table-next'
import { useProductConcreteComponents } from './queries/product-concrete-component.query'
import { ProductConcreteComponentWithPrice } from './types/ProductConcreteComponentWithPrice'
import { useUpsertSellerConcretePriceListMutation } from './mutations/upsert-seller-concrete-price-list.mutation'
import { isAnyQueryLoading } from '../../services/functions/queryHelpers'
import { productConcreteComponentDataDefinition } from './services/product-concrete-component-data-definition'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import { FormProvider, useForm } from 'react-hook-form'
import { useDeleteSellerConcretePriceListMutation } from './mutations/delete-seller-concrete-price-list.mutation'
import { useSupplierServiceConcretePriceListQuery } from './queries/supplier-service-concrete-price-list.query'

const currentServiceCategory = 'CONCRETE_SELLER'
export default function SupplierServiceConcreteSeller() {
  const { state } = useContext(SelectedSupplierModalContext)
  const currentServiceSetting = state.selectedBranch?.branchServices?.find(x => x.serviceCategory === currentServiceCategory)
  const formMethods = useForm({ defaultValues: { type: 'STRENGTH_CLASS' } })
  const concretePriceListQR = useSupplierServiceConcretePriceListQuery(currentServiceSetting?.id)
  const concreteQR = useProductConcreteComponents()
  const [upsertSellerConcretePriceListMut, upsertSellerConcretePriceListQR] = useUpsertSellerConcretePriceListMutation()
  const [deleteSellerConcretePriceListMut, deleteSellerConcretePriceListQR] = useDeleteSellerConcretePriceListMutation()

  const selectedSection = formMethods.watch('type')
  const concreteDataWithPrice: ProductConcreteComponentWithPrice[] =
    concreteQR.data?.productConcreteComponents
      .filter(x => x.type === selectedSection)
      .map(x => ({
        ...x,
        priceKc: concretePriceListQR.data?.supplierServiceConcretePriceList?.find(y => y.concreteComponentId === x.id)?.priceKc || undefined,
        priceForPublicKc: concretePriceListQR.data?.supplierServiceConcretePriceList?.find(y => y.concreteComponentId === x.id)?.priceForPublicKc || undefined,
        branchServicePriceListId: concretePriceListQR.data?.supplierServiceConcretePriceList?.find(y => y.concreteComponentId === x.id)?.id || undefined
      })) || []

  const { generateColumnListForBootstrapTable, mapDataArrayToTable } =
    useBootstrapTableDataMapper<ProductConcreteComponentWithPrice>(productConcreteComponentDataDefinition)

  const columnDefinition = generateColumnListForBootstrapTable({
    actionsReference: {
      deleteConcreteComponentPrice: deleteConcreteComponentPriceHandler
    }
  })

  const tableData = mapDataArrayToTable(concreteDataWithPrice)

  return (
    <PageCard
      showLoader={isAnyQueryLoading(concreteQR, concretePriceListQR, upsertSellerConcretePriceListQR, deleteSellerConcretePriceListQR)}
      headerTitle={'Prodej betonu'}
    >
      <SupplierServiceEnabler currentServiceSetting={currentServiceSetting} serviceCategory={currentServiceCategory} settingsNode={<></>} />
      {currentServiceSetting && (
        <>
          <FormProvider {...formMethods}>
            <div className="row mt-3 mb-3">
              <div className="col">
                <span className="mr-5">
                  <input type="radio" id="strengthClass" {...formMethods.register('type')} value="STRENGTH_CLASS" />
                  <label htmlFor="strengthClass" className="pl-2 d-inline-block strong">
                    Pevnostní třída
                  </label>
                </span>
                <span className="mr-5">
                  <input type="radio" id="consistency" {...formMethods.register('type')} value="CONSISTENCY" />
                  <label htmlFor="consistency" className="pl-2 d-inline-block strong">
                    Konzistence
                  </label>
                </span>
                <span className="mr-5">
                  <input type="radio" id="aggregate" {...formMethods.register('type')} value="AGGREGATE" />
                  <label htmlFor="aggregate" className="pl-2 d-inline-block strong">
                    Příměsi
                  </label>
                </span>
                <span className="mr-5">
                  <input type="radio" id="surcharge" {...formMethods.register('type')} value="SURCHARGE" />
                  <label htmlFor="surcharge" className="pl-2 d-inline-block strong">
                    Příplatky
                  </label>
                </span>
              </div>
            </div>
          </FormProvider>
          <div className="row">
            <div className="col-md-6">
              <BootstrapTable
                bootstrap4
                striped
                hover
                condensed
                classes="table-responsive-lg digiTable"
                noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné záznamy"</>}
                keyField="id"
                data={tableData ? tableData : []}
                columns={columnDefinition}
                cellEdit={cellEditFactory({
                  mode: 'click',
                  blurToSave: true,
                  autoSelectText: true,
                  beforeSaveCell: onCellUpdate
                })}
              />
            </div>
          </div>
        </>
      )}
    </PageCard>
  )

  function deleteConcreteComponentPriceHandler(row: any) {
    const promise = deleteSellerConcretePriceListMut({
      variables: {
        id: row.branchServicePriceListId
      }
    })
    processPageOperation({
      promise,
      successMessage: `Cena za složku betonu ${row.name} byla smazana`
    })
  }
  function onCellUpdate(oldValue, newValue, row, column) {
    if (isNaN(newValue) || !currentServiceSetting?.id || oldValue === newValue) return
    const variables = {
      branchServiceId: currentServiceSetting?.id,
      data: {
        id: row.branchServicePriceListId ?? undefined,
        concreteComponentId: row.id,
        priceKc: column.dataField === 'priceKc' ? parseFloat(newValue) : undefined,
        priceForPublicKc: column.dataField === 'priceForPublicKc' ? parseFloat(newValue) : undefined
      }
    }

    const promise = upsertSellerConcretePriceListMut({ variables })
    processPageOperation({
      promise,
      successMessage: `Cena za složku betonu ${row.name} byla upravena`
    })
  }
}
