import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'

const lockOrderMutation = gql`
  mutation lockOrder($orderId: Int!, $lockDurationMin: Int) {
    lockOrder(orderId: $orderId, lockDurationMin: $lockDurationMin) {
      status {
        reason
        result
      }
    }
  }
`

export function useLockOrderMutation() {
  return useMutation<{ lockOrder: IMutationResult }>(lockOrderMutation, {
    refetchQueries: ['getOrderLock']
  })
}
