import React from 'react'

export function SuppliersExport({ download }: { download: any }) {
  return (
    <button type="button" className="btn btn-sm" onClick={() => download(`/api/export-suppliers`)}>
      Export dodavatelů
    </button>
  )
}

export function PriceListsExport({ download }: { download: any }) {
  return (
    <button type="button" className="btn btn-sm" onClick={() => download(`/api/export-price-lists`)}>
      Export ceníků
    </button>
  )
}
