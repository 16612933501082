import {gql, useQuery} from '@apollo/client'

export const productsQuery = gql`
    query products {
        products(order: "uiOrder") {
            id
            name
            uiOrder
            isCollection
            isDelivery
        }
    }
`

export type ProductItem = {
    id: number
    name: string
    uiOrder: number
    isCollection: boolean
    isDelivery: boolean
}

export function useProducts() {
    return useQuery<{ products: ProductItem[] }>(productsQuery)
}
