import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'
import { emailHistoryQuery } from '../components/EmailHistory/query/email-history.query'

const recheckGPPaymentStatusMutation = gql`
  mutation recheckGPPaymentStatus($orderId: Int!) {
    recheckGPPaymentStatus(orderId: $orderId) {
      status {
        reason
        result
      }
    }
  }
`

export function useRecheckGPPaymentStatus(orderContentId?: number) {
  return useMutation<{ recheckGPPaymentStatus: IMutationResult }>(recheckGPPaymentStatusMutation, {
    refetchQueries: [{ query: emailHistoryQuery, variables: { orderContentId } }]
  })
}
