import { gql, useQuery } from '@apollo/client'
import { IGqlPagingQueryParams } from '../../../../services/bootstrapTable/usePagingFromURLQuery'
import {
  SupplierBranchListResponse,
  SupplierContainerEntity,
  SupplierContainerRentRatesEntity,
  SupplierSupplierListResponse
} from '../../../../graphql/aminTypes'

export const supplierContainerRentRatesQuery = gql`
  query supplierContainerRentRates($supplierBranchId: ID!) {
    supplierContainerRentRates(supplierBranchId: $supplierBranchId) {
      id
      name
      rentFreeForDays
      baseRentPerDayKc
      maximumRentDays
      monthlyRentAfterDays
      monthlyRentPriceKc
      additionalPrices {
        fromDay
        rentPerDayKc
      }
    }
  }
`

export function useSupplierContainerRentRatesQuery(supplierBranchId?: string) {
  return useQuery<{ supplierContainerRentRates: SupplierContainerRentRatesEntity[] }>(supplierContainerRentRatesQuery, {
    fetchPolicy: 'network-only',
    variables: { supplierBranchId },
    skip: !supplierBranchId
  })
}
