import { useStatisticsTotalQuery } from './services/statisticsTotalQuery'
import React from 'react'
import { StatsCard } from '../../../../components/Cards/statsCard'

export function StatTotals({ fromDate, toDate }: { fromDate?: string; toDate?: string }) {
  const statisticsTotalQR = useStatisticsTotalQuery({ fromDate, toDate })
  return (
    <>
      <div className="col-md-3">
        <StatsCard
          loading={statisticsTotalQR.loading}
          caption={'Objednávek celkem'}
          value={statisticsTotalQR?.data?.statisticsTotal?.totalOrders?.toString() ?? 'N/A'}
        />
      </div>
      <div className="col-md-3">
        <StatsCard
          loading={statisticsTotalQR.loading}
          caption={'Objednávek B2B'}
          value={statisticsTotalQR?.data?.statisticsTotal?.ordersByB2b?.toString() ?? 'N/A'}
        />
      </div>
      <div className="col-md-3">
        <StatsCard
          loading={statisticsTotalQR.loading}
          caption={'Počet dobírek'}
          value={statisticsTotalQR?.data?.statisticsTotal?.codCount?.toString() ?? 'N/A'}
        />
      </div>
      <div className="col-md-3">
        <StatsCard
          loading={statisticsTotalQR.loading}
          caption={'Najeto KM'}
          value={Math.floor(statisticsTotalQR?.data?.statisticsTotal?.distanceTotalKm ?? 0).toString()}
        />
      </div>
    </>
  )
}
