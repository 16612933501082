import React, { useEffect, useRef } from 'react'
import { createParamsForHeatMapsQuery, useHeatMapOrders } from './services/heat-map.query'
import { FormProvider, useForm } from 'react-hook-form'
import { OrderContentListResult, OrderContentsResult } from '../../../graphql/aminTypes'
import DatePickerComp from '../../../components/formComponents/datePickerComp'
import ProductsSelector from '../../../components/dataComponents/ProductsSelector/ProductsSelector'
import SelectField from '../../../components/formComponents/SelectField'
import LoadingContainer from '../../../components/LoadingContainer'
import { serviceTypeSelectOptions } from '../../../services/types/service-type'
import { Circle, MapContainer, TileLayer } from 'react-leaflet'

export default function HeatMap() {
  const formMethods = useForm({})
  const data = formMethods.watch()
  const heatMapOrdersQR = useHeatMapOrders(data, onCompleted)

  useEffect(() => {
    heatMapOrdersQR.refetch(createParamsForHeatMapsQuery(data))
  }, [data])

  function onCompleted(result: { orderContentList: OrderContentListResult }) {}

  return (
    <FormProvider {...formMethods}>
      <fieldset className="form-fieldset">
        <div className="row">
          <div className="col-md-2">
            <DatePickerComp name={'createdAtFrom'} label={'Vytvořené od'} maxDate={new Date()} />
          </div>
          <div className="col-md-2">
            <DatePickerComp name={'createdAtTo'} label={'Vytvořené do'} maxDate={new Date()} />
          </div>
          <div className="col-md-2">
            <SelectField name={'serviceType'} label={'Služba'} required={false} optionsData={serviceTypeSelectOptions} />
          </div>
          <div className="col-md-2">
            <ProductsSelector required={false} />
          </div>
        </div>
      </fieldset>
      <LoadingContainer showLoader={heatMapOrdersQR.loading}>
        <MapContainer center={[49.8092647, 15.4008144]} zoom={8} style={{ height: '80vh', width: '100%' }}>
          <TileLayer
            url="http://sgx.geodatenzentrum.de/wmts_topplus_open/tile/1.0.0/web/default/WEBMERCATOR/{z}/{y}/{x}.png"
            maxZoom={18}
            attribution='Map data: &copy; <a href="http://www.govdata.de/dl-de/by-2-0">dl-de/by-2-0</a>'
          />
          {heatMapOrdersQR.data?.orderContentList.items &&
            heatMapOrdersQR.data?.orderContentList.items.map((orderContent, index) => (
              <Circle
                key={index}
                center={[orderContent.order.gps.coordinates[1], orderContent.order.gps.coordinates[0]]}
                radius={4000}
                pathOptions={{ fillColor: orderContent.serviceType === 'WASTE_COLLECTION' ? '#4BC0C0' : '#FF5900', fillOpacity: 0.2, stroke: false }}
              />
            ))}{' '}
        </MapContainer>
      </LoadingContainer>
    </FormProvider>
  )
}
