import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'

const upsertContainerWasteType = gql`
  mutation upsertContainerWasteType($containerId: Int!, $productId: Int!, $capacity: Float!) {
    upsertContainerWasteType(containerId: $containerId, productId: $productId, capacity: $capacity) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpsertContainerWasteType() {
  return useMutation<{ upsertContainerWasteType: IMutationResult }>(upsertContainerWasteType, { refetchQueries: ['containerTypes'] })
}

export function createParamsForUpsertContainerWasteType(containerId: number, productId: number, capacity: string) {
  return {
    containerId,
    productId,
    capacity: +capacity
  }
}
