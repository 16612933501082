import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'

const updateSupplierSupplierMutation = gql`
  mutation updateSupplierSupplier($id: ID!, $data: SupplierSupplierInputArgs!) {
    updateSupplierSupplier(id: $id, data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpdateSupplierSupplierMutation() {
  return useMutation<{ updateSupplierSupplier: IMutationResult }>(updateSupplierSupplierMutation, { refetchQueries: ['supplierSupplierList'] })
}
