import dayjs from 'dayjs'

export function formDefaults(isDek: boolean, email?: string) {
  const createdDate = dayjs().set('day', 15).isBefore(dayjs()) ? dayjs().set('day', 15) : dayjs()
  return {
    createdDate: createdDate.toDate(),
    dueDate: createdDate.add(30, 'days').toDate(),
    duzpDate: dayjs().subtract(1, 'month').endOf('month').toDate(),
    externalReference: isDek ? `EO-${dayjs().subtract(1, 'month').year().toString().slice(2)}-` : '',
    clientEmail: email,
    noTotalRounding: isDek
  }
}
