import { ApolloClient, ApolloLink, InMemoryCache, HttpLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { cacheTypePolicies } from './typePolicies'
import { toast } from 'react-toastify'

export default function apolloClientGenerator(authToken: string, refreshTokenFn: (apolloInstance: ApolloClient<any>) => () => void) {
  const authMiddlewareLink = new ApolloLink((operation, forward) => {
    let authHeader = authToken ? 'Bearer ' + authToken : ''
    const ctx = operation.getContext()

    const protocol: string = process.env.REACT_APP_CONNECTION_PROTOCOL || 'https'
    const hostname: string = process.env.REACT_APP_BACKEND_HOSTNAME || 'localhost:8008'
    const endpoint: string = ctx.endpoint ? ctx.endpoint.toString() : 'admin'

    const url = `${protocol}://${hostname}/api/${endpoint}`
    operation.setContext(({ headers = {} }) => ({
      headers: {
        authorization: authHeader
      },
      uri: url
    }))
    return forward(operation)
  })

  const httpLink = new HttpLink({})

  var apolloInstance: ApolloClient<any> | undefined = undefined

  const errorLink = onError(({ graphQLErrors, networkError, response, operation }) => {
    const gqlErrors = graphQLErrors ? JSON.stringify(graphQLErrors) : undefined
    if (gqlErrors && (gqlErrors.includes('UNAUTHENTICATED') || gqlErrors.includes('jwt expired'))) {
      if (apolloInstance) refreshTokenFn(apolloInstance)()
      else toast.error(gqlErrors)
    } else {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) => {
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
          if (JSON.stringify(operation).includes(`"operation":"query"`)) toast.error(`Operation: ${operation.operationName} - ${message}`, { autoClose: false })
        })
      }
      if (networkError) console.log(`[Network error]: ${networkError}`)
    }
  })

  apolloInstance = new ApolloClient({
    link: ApolloLink.from([authMiddlewareLink, errorLink, httpLink]),
    cache: new InMemoryCache({ typePolicies: cacheTypePolicies })
  })
  return apolloInstance
}
