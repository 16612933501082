import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../services/types/gqlTypes'

const deleteSupplierDeliveryPriceListMutation = gql`
    mutation deleteDeliveryPriceList($id: Int!) {
        deleteDeliveryPriceList(id: $id) {
            status {
                reason
                result
            }
        }
    }
`

export function useDeleteSupplierDeliveryPriceListMutation() {
    return useMutation<{ deleteDeliveryPriceList: IMutationResult }>(deleteSupplierDeliveryPriceListMutation)
}
