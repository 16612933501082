import { gql, useQuery } from '@apollo/client'

const suppliersDetailQuery = gql`
  query suppliers($id: String) {
    suppliers(id: $id) {
      id
      name
      orpName
      street
      streetNumber
      city
      zip
      residenceStreet
      residenceStreetNumber
      residenceCity
      residenceZip
      gps {
        coordinates
      }
      phone
      email
      web
      contactPerson
      companyIdentification
      companyTaxId
      deliveryDayThresholdMinutes
      expressDeliveryFromMinutes
      normalDeliveryFromMinutes
      expressDeliveryFeeKc
      publicHolidayFeeKc
      containerRentPriceFromDay
      containerRentPricePerDayKc
      ourMarginPercent
      pathCalculationStrategy
      autoApproveOrders
      note
      regionCode
      priority
      useFlatRatePriceList
      operationRadiusM
      sendOrderAllEmails
      priceListIsFinal
      allowWorkOnHolidays
      allowWorkOnSaturday
      allowWorkOnSunday
      acceptCod
      disabled
      priceLimitNotApplicable
      hasIcz
      iczNumber
      transportFlatRate
      capability
      zpoNote
    }
  }
`
export type SupplierDetailItemType = {
  id: number
  name: string
  orpName: string
  city: string
  street: string
  streetNumber: string
  zip: string
  residenceCity: string
  residenceStreet: string
  residenceStreetNumber: string
  residenceZip: string
  gps: {
    coordinates: number[]
  }
  regionCode: number
  companyIdentification: string
  companyTaxId: string
  contactPerson: string
  email: string
  phone: string
  web: string
  containerRentPriceFromDay: number
  containerRentPricePerDayKc: number
  publicHolidayFeeKc: number
  pathCalculationStrategy: string
  ourMarginPercent: number
  normalDeliveryFromMinutes: number
  deliveryDayThresholdMinutes: number
  expressDeliveryFeeKc: number
  expressDeliveryFromMinutes: number
  autoApproveOrders: boolean
  note: string
  priority: number
  useFlatRatePriceList: boolean
  operationRadiusM: number
  sendOrderAllEmails: boolean
  priceListIsFinal: boolean
  allowWorkOnHolidays: boolean
  allowWorkOnSaturday: boolean
  allowWorkOnSunday: boolean
  acceptCod: boolean
  disabled: boolean
  priceLimitNotApplicable: boolean
  hasIcz: boolean
  iczNumber: string
  transportFlatRate: number
  capability: CapabilityType
  zpoNote: string
}

export function useSuppliersDetailQuery(id: string | null, setupFields: (data: { suppliers: SupplierDetailItemType[] }) => void) {
  return useQuery<{ suppliers: SupplierDetailItemType[] }>(suppliersDetailQuery, {
    fetchPolicy: 'network-only',
    variables: { id: id },
    skip: !id,
    onCompleted: setupFields
  })
}

export type CapabilityType = ('BO' | 'O' | 'ICZM' | 'ICZSS' | 'ICZSR')[]
