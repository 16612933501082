import { IReactSelectItem } from '../../../../../services/types/ReactSelect'

export const additionalInvoiceReasons: IReactSelectItem[] = [
  { label: 'změna velikosti kontejneru', value: 'změna velikosti kontejneru' },
  { label: 'změna druhu odpadu', value: 'změna druhu odpadu' },
  { label: 'prodloužení doby pronájmu', value: 'prodloužení doby pronájmu' },
  { label: 'nadlimitní hmotnost odpadu', value: ' nadlimitní hmotnost odpadu' },
  { label: 'marná jízda', value: 'marná jízda' },
  { label: 'přeložení kontejneru', value: 'přeložení kontejneru' }
]
