import { DataListItem, HtmlDataList } from '../../../../components/HtmlDataList'
import React from 'react'
import LoadingContainer from '../../../../components/LoadingContainer'
import { isAnyQueryLoading } from '../../../../services/functions/queryHelpers'
import { useCustomerListQuery } from './queries/b2bCustomersQuery'

export function CustomerInfo({ customerId }: { customerId?: string }) {
  const customerQR = useCustomerListQuery(customerId)
  if (!customerId) return <></>
  const customerItem = customerQR?.data?.customerList.items![0]
  const customerData: DataListItem[] = customerItem
    ? [
        { label: 'Jméno', text: customerItem.companyName ? customerItem.companyName : `${customerItem.firstname} ${customerItem.lastname}` },
        { label: 'Poznámka', text: customerItem.notes ? customerItem.notes : '-' },
        { label: 'Vyžaduje váženku', text: customerItem.requireCertificates ? 'ANO' : 'NE' }
      ]
    : []
  if (customerItem?.isPreRegisteredOnly) customerData.push({ label: 'Před-registrovaný (neví o své registraci)', text: 'ANO' })
  else customerData.push({ label: 'Účet aktivovaný (má nastavené heslo)', text: customerItem?.isActive ? 'ANO' : 'NE' })

  return (
    <LoadingContainer showLoader={isAnyQueryLoading(customerQR)}>
      <fieldset className="form-fieldset mt-3">
        <HtmlDataList label={`Registrovaný zákazník`} data={customerData} />
      </fieldset>
    </LoadingContainer>
  )
}
