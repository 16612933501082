import SelectField from '../../../../components/formComponents/SelectField'
import React from 'react'
import { useProductContainerListQuery } from './queries/product-container-list.query'
import { ProductContainerEntity } from '../../../../graphql/aminTypes'

function sortAvailableContainersFirst(availableContainers?: string[]) {
  return function (p1: ProductContainerEntity, p2: ProductContainerEntity) {
    const p1IsAvailable = availableContainers?.includes(p1.id) ?? false
    const p2IsAvailable = availableContainers?.includes(p2.id) ?? false
    if (p1IsAvailable && !p2IsAvailable) return -1
    if (!p1IsAvailable && p2IsAvailable) return 1
    return 0
  }
}

export function ProductContainerSelector({
  doNotShowContainers,
  availableContainers,
  disabled,
  label
}: {
  doNotShowContainers?: string[]
  availableContainers?: string[]
  disabled?: boolean
  label: string
}) {
  const productContainerListQR = useProductContainerListQuery()
  const productContainers =
    productContainerListQR.data?.productContainerList
      .filter(x => !(doNotShowContainers ?? []).includes(x.id))
      .sort(sortAvailableContainersFirst(availableContainers))
      .map(x => ({
        value: x.id,
        color: (availableContainers ?? []).includes(x.id) ? 'black' : '#ccc',
        label: `${x.name} ${x.volumeM3}m³`
      })) || []

  return <SelectField name={'productContainerId'} label={label} optionsData={productContainers} disabled={disabled} required />
}
