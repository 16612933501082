import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
// @ts-ignore
import { IOrderContentListItem } from '../queries/order-list.query'
import useBootstrapTableDataMapper from '../../../services/bootstrapTable/useBootstrapTableDataMapper'
import { orderListExpandDataDefinition } from './services/orderListExpandDataDefinition'
import { useNavigate } from 'react-router-dom'
import { performStateActionEnum } from '../services/usePerformStateActionMutation'
import { httpBuildQueryString } from '../../../services/functions/httpBuildQuery'
import { processPageOperation } from '../../../services/formsServices/pageOperationProcessor'
import { useDeleteDraftMutation } from './services/deleteDraftMutation'
import { useProducts } from '../../OrderDetail/queries/productsQuery'

export default function OrderListExpand({
  data,
  appendAdminNote,
  performActionHandler,
  changeOrderStateHandler,
  createReturnUrl,
  refetchAll
}: {
  data: IOrderContentListItem
  performActionHandler: any
  appendAdminNote: any
  changeOrderStateHandler: any
  createReturnUrl: any
  refetchAll: () => void
}) {
  const navigate = useNavigate()
  const allProducts = useProducts()
  const { generateColumnListForBootstrapTable, mapDataArrayToTable } = useBootstrapTableDataMapper<IOrderContentListItem>(
    orderListExpandDataDefinition(allProducts, data.product, data.serviceType)
  )
  const [deleteDraftMut] = useDeleteDraftMutation()
  const columnDefinition = generateColumnListForBootstrapTable({
    actionsReference: {
      cancelOrder: async row => {
        const reason = window.prompt(`Opravdu zrušit objednávku ${row.orderNumber}? Můžete zadat důvod: `)
        if (reason === null) return
        await appendAdminNote(row.id, reason)
        await performActionHandler([row.id], undefined, `Objednávka ${row.orderNumber} byla zrušena.`, performStateActionEnum.CANCEL_BY_ADMIN)
      },
      deleteDraft: deleteDraftHandler,
      changeOrderState: changeOrderStateHandler
    }
  })

  let ordersData: any[] = []
  if (data) ordersData = mapDataArrayToTable([data])
  return (
    <>
      <BootstrapTable
        bootstrap4
        condensed
        classes="table-responsive-lg digiTable"
        noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné objednávky"</>}
        keyField="id"
        data={ordersData}
        columns={columnDefinition}
      />
      <div className="justify-content-center d-flex">
        <button type="button" className="btn btn-primary ms-auto btn-lg" onClick={navigateToOrderDetail}>
          Detail objednávky
        </button>
      </div>
    </>
  )

  function navigateToOrderDetail() {
    const fwQueryString = httpBuildQueryString({
      orderNumber: data.order.orderNumber,
      returnUrl: createReturnUrl(data.id)
    })
    navigate('/order-detail' + fwQueryString)
  }

  function deleteDraftHandler(row) {
    if (!window.confirm(`Opravdu smazat draft ${data.order.orderNumber}?`)) return

    const promise = deleteDraftMut({
      variables: { orderId: data.order.id }
    })

    processPageOperation({
      promise,
      successMessage: 'Draft byl smazán',
      successAction: refetchAll
    })
  }
}
