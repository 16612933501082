import { IStringObject } from '../types/IStringObject'

export function httpBuildQueryString(param: IStringObject) {
  return (
    '?' +
    Object.keys(param)
      .map(key => {
        if (param[key] !== false && !param[key]) return ''
        return key + '=' + encodeURIComponent(param[key])
      })
      .filter(x => !!x)
      .join('&')
  )
}

export function removeParamFromSearchString(searchString: string, paramToRemove: string[]): string[] {
  searchString = searchString.replace('?', '')
  if (!searchString) return []
  let searchData = searchString.replace('?', '').split('&')
  for (const param of paramToRemove) {
    const indexOfPoint = searchData.findIndex(x => x.indexOf(param) >= 0)
    if (indexOfPoint >= 0) searchData.splice(indexOfPoint, 1)
  }
  return searchData
}
