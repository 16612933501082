import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const updateTerminalMutation = gql`
  mutation updateTerminal($terminalId: Int!, $data: TerminalDataInput!) {
    updateTerminal(terminalId: $terminalId, data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpdateTerminalMutation() {
  return useMutation<{ updateTerminal: IMutationResult }>(updateTerminalMutation)
}
