import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'
import { contextEndpoint, EndpointEnum } from '../../../../../services/appConfiguration/contextEndpoint'
import { wasteFinalsQuery } from '../query/waste-finals.query'
import { listOrderContentFilesQuery } from '../../../queries/listfOfFilesQuery'

const createWasteFinalMutation = gql`
  mutation createWasteFinal($orderContentId: Int!, $data: WasteFinalDateInput!, $generateConfirmation: Boolean) {
    createWasteFinal(orderContentId: $orderContentId, data: $data, generateConfirmation: $generateConfirmation) {
      status {
        reason
        result
      }
    }
  }
`

export function useCreateWasteFinalMutation(orderContentId: number) {
  return useMutation<{ createWasteFinal: IMutationResult }>(createWasteFinalMutation, {
    context: contextEndpoint(EndpointEnum.admin),
    refetchQueries: result => [
      {
        query: wasteFinalsQuery,
        variables: { orderContentId },
        context: contextEndpoint(EndpointEnum.admin)
      },
      {
        query: listOrderContentFilesQuery,
        variables: { orderContentId },
        context: contextEndpoint(EndpointEnum.admin)
      }
    ]
  })
}

export function createParamsForCreateWasteFinalMutation(orderContentId: number, data: any, generateConfirmation: boolean) {
  return {
    orderContentId,
    data: {
      wasteTypeId: data.wasteTypeId,
      weightTonne: +data.weightTonne,
      icz: data.icz ? data.icz : null,
      terminalName: data.terminalName ? data.terminalName : null
    },
    generateConfirmation
  }
}
