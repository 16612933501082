import { gql, useMutation } from '@apollo/client'
import { IMutationResultWithId} from '../../../../services/types/gqlTypes'

const createSupplierPriceListMutation = gql`
  mutation createSupplierPriceList($supplierId: Int!, $data: SupplierPriceListDataInput!) {
    createSupplierPriceList(supplierId: $supplierId, data: $data) {
      id
      status {
        reason
        result
      }
    }
  }
`

export function useCreateSupplierPriceListMutation() {
  return useMutation<{ createSupplierPriceList: IMutationResultWithId }>(createSupplierPriceListMutation)
}

export function createParamsForCreateSupplierPriceListMutation(data: any) {
  return {
    data: {
      containerId: data.containerId,
      baseRentPriceKc: data.baseRentPriceKc!== undefined ? parseFloat(data.baseRentPriceKc) : null,
      minimumPriceForTransport: data.minimumPriceForTransport !== undefined ? parseFloat(data.minimumPriceForTransport) : null,
      pricePerKmKc: parseFloat(data.pricePerKmKc),
      flatRateRadius: data.flatRateRadius !== undefined ? parseInt(data.flatRateRadius) : null,
      disabled: data.disabled
    }
  }
}
