import { gql, useQuery } from '@apollo/client'
import { SubjectInfoType } from '../../graphql/publicTypes'
import { contextEndpoint, EndpointEnum } from '../appConfiguration/contextEndpoint'

const getCompanyInfoFromAresQuery = gql`
  query getCompanyInfoFromAres($ico: String!, $countryIso: String) {
    getCompanyInfoFromAres(ico: $ico, countryIso: $countryIso) {
      name
      street
      streetNumber
      city
      zip
      dic
    }
  }
`

export function useGetCompanyInfoFromAresQuery() {
  return useQuery<{ getCompanyInfoFromAres: SubjectInfoType }>(getCompanyInfoFromAresQuery, {
    skip: true,
    context: contextEndpoint(EndpointEnum.public)
  })
}
