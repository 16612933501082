import FormField, { inputType } from '../../../../components/formComponents/FormField'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { OrderContentPriceItemType } from '../../queries/orderContentPriceItemsQuery'
import { IStringObject } from '../../../../services/types/IStringObject'
import { moneyToLocaleString } from '../../../../services/dataToString/decimalToLocaleString'
import { SuppliersResult, useSupplierQuery } from './services/suppliersQuery'
import LoadingContainer from '../../../../components/LoadingContainer'
import { isQueryLoading } from '../../../../services/functions/queryHelpers'
import { percentageFormatter } from '../../../../services/bootstrapTable/columnFormatters'
import classNames from 'classnames'
import { ProcessedOrderContentPrices } from '../../services/getPriceSeparation'

export default function PriceBreakUp({
  orderContentPriceItems,
  isForDelivery,
  priceBreakUp,
  handlePriceUpdate,
  originalTotalPrice,
  supplierId,
  splitPriceWithTerminal
}: {
  priceBreakUp?: ProcessedOrderContentPrices[] | null
  orderContentPriceItems: OrderContentPriceItemType[]
  handlePriceUpdate: (data: Record<string, any>) => void
  splitPriceWithTerminal?: boolean
  isForDelivery?: boolean
  originalTotalPrice?: number
  supplierId?: number
}) {
  const supplierQR = useSupplierQuery({ id: supplierId?.toString() }, supplierLoaded, !splitPriceWithTerminal)
  const formMethods = useForm<Record<string, any>>({ defaultValues: reducePriceBreakUp(priceBreakUp) })
  let sortedItems = [...orderContentPriceItems].sort((a, b) => a.uiOrder - b.uiOrder)
  sortedItems = sortedItems.filter(x => !splitPriceWithTerminal || !['priceForProduct', 'margin', 'flatRate'].includes(x.sysName))
  if (isForDelivery === false) sortedItems = sortedItems.filter(x => !['priceForProduct'].includes(x.sysName))
  if (isForDelivery === true) sortedItems = sortedItems.filter(x => !['flatRate', 'terminalStorage', 'containerRent', 'endDuringWeekend'].includes(x.sysName))

  const priceItemsForTotalValue = orderContentPriceItems.filter(x => x.partOfTotalPrice && (!splitPriceWithTerminal || x.sysName !== 'margin'))
  const [newTotalPriceWoMargin, setNewTotalPriceWoMargin] = useState(0)
  const [newMarginPercent, setNewMarginPercent] = useState(0)

  const allItems = formMethods.watch()
  useEffect(() => {
    if (!splitPriceWithTerminal) return
    const newTotalPriceWoMargin = priceItemsForTotalValue.reduce(
      (previousValue, currentValue) => previousValue + (allItems[currentValue.sysName] ? parseFloat(allItems[currentValue.sysName]) : 0),
      0
    )
    const newMarginKc = originalTotalPrice! - newTotalPriceWoMargin
    setNewTotalPriceWoMargin(newTotalPriceWoMargin)
    setNewMarginPercent(newMarginKc / originalTotalPrice!)
  }, [allItems])

  return (
    <LoadingContainer showLoader={isQueryLoading(supplierQR)}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
          {splitPriceWithTerminal && (
            <>
              <div className="row mb-3">
                <div className={'col-md-4'}>
                  Celková cena bez DPH <strong>{moneyToLocaleString(originalTotalPrice!, 'CZK')}</strong>
                </div>
                <div className="col-md-4 text-red">
                  Nová marže <strong>{percentageFormatter(newMarginPercent * 100)}</strong>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-12">
                  Nová marže s ohledem na původní celkovou cenu: <strong>{moneyToLocaleString(originalTotalPrice! - newTotalPriceWoMargin, 'CZK')}</strong>
                </div>
              </div>
            </>
          )}
          <div className="row mb-2">
            <div className="col-md-12">
              {sortedItems
                .filter(fi => fi.partOfTotalPrice || !splitPriceWithTerminal)
                .map(x => (
                  <span
                    key={x.sysName}
                    className={classNames([['margin', 'terminalStorage', 'transfer'].includes(x.sysName) && splitPriceWithTerminal ? 'text-red' : ''])}
                  >
                    <FormField
                      key={x.sysName}
                      isDecimal
                      type={inputType.number}
                      name={x.sysName}
                      label={`${x.name}${!x.partOfTotalPrice ? '  - (Nepočítá se do celkového součtu, už je odečteno ze základní hodnoty)!' : ''}`}
                    />
                  </span>
                ))}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-4">
              <div className="text-right mt-4">
                <button type="submit" className="btn btn-red ms-auto btn-lg">
                  Aktualizovat objednávku
                </button>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </LoadingContainer>
  )

  function handleFormSubmit(data: any) {
    const notEmptyEntries = Object.fromEntries(Object.entries(data).filter(([_, v]) => !!v))
    if (splitPriceWithTerminal) notEmptyEntries['margin'] = originalTotalPrice! - newTotalPriceWoMargin
    if (splitPriceWithTerminal) notEmptyEntries['flatRate'] = 0
    handlePriceUpdate(notEmptyEntries)
  }

  function supplierLoaded(data: { suppliers: SuppliersResult[] }) {
    if (data.suppliers.length === 1) {
      formMethods.setValue('transfer', data.suppliers[0].transportFlatRate)
      formMethods.setValue('flatRate', 0)
    }
  }
}

function reducePriceBreakUp(prices?: ProcessedOrderContentPrices[] | null): IStringObject {
  if (!prices) return {}
  return prices.map(x => ({ [x.orderContentPriceItem.sysName]: x.priceWoVatKc })).reduce((p, c) => ({ ...p, ...c }), {})
}
