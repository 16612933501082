import { Column } from 'react-bootstrap-table-next'
import React from 'react'
import classNames from 'classnames'

export function headerFormatterWithCustomSort(
  sortOrder: SortOrder,
  handleTableChange: (type: TableChangeType, newState: TableChangeNewState) => void,
  defaultRemoteSort: SortOrder,
  isEditable?: boolean
) {
  return (column: Column<string>, colIndex: number, { sortElement, filterElement }: any) =>
    headerFormatterWithSort(
      column,
      colIndex,
      {
        sortElement,
        filterElement
      },
      sortOrder,
      handleTableChange,
      defaultRemoteSort,
      isEditable
    )
}

function headerFormatterWithSort(
  column: Column<string>,
  colIndex: number,
  { filterElement }: any,
  sortOrder: SortOrder,
  handleTableChange: (type: TableChangeType, newState: TableChangeNewState) => void,
  defaultRemoteSort: SortOrder,
  isEditable?: boolean
) {
  let orderClassName = 'order-4'
  let nextOrder: SortOrder = 'desc'
  if (sortOrder === 'desc') {
    orderClassName = 'caret-4-desc'
    nextOrder = 'asc'
  }
  if (sortOrder === 'asc') {
    orderClassName = 'caret-4-asc'
    nextOrder = undefined
  }
  if (defaultRemoteSort === 'desc' && sortOrder === 'desc') nextOrder = 'asc'

  return (
    <>
      {column.text}
      {isEditable && <i className="fe fe-edit-3" />}
      <span
        tabIndex={0}
        className={classNames(orderClassName, 'cursorPointer')}
        role="button"
        onClick={() => handleTableChange('sort', { sortField: column.dataField, sortOrder: nextOrder })}
        onKeyDown={() => handleTableChange('sort', { sortField: column.dataField, sortOrder: nextOrder })}
      />
      {filterElement}
    </>
  )
}
