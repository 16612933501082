import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../services/types/gqlTypes'

const deleteSupplierFlatRateMutation = gql`
  mutation deleteSupplierFlatRate($flatRateId: Int!) {
    deleteSupplierFlatRate(flatRateId: $flatRateId) {
      status {
        reason
        result
      }
    }
  }
`

export function useDeleteSupplierFlatRateMutation() {
  return useMutation<{ deleteSupplierFlatRate: IMutationResult }>(deleteSupplierFlatRateMutation)
}
