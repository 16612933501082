import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'
import { LoginToSupplierType } from '../../../../../scenesAdmin/LoginList/services/loginsQuery'

const addSupplierToLoginMutation = gql`
  mutation addSupplierToLogin($supplierId: Int!, $loginId: Int!) {
    addSupplierToLogin(supplierId: $supplierId, loginId: $loginId) {
      data {
        id
        supplier {
          id
          name
        }
      }
      status {
        reason
        result
      }
    }
  }
`

export interface AddSupplierToLoginMutationResult extends IMutationResult {
  data: LoginToSupplierType
}

export function useAddSupplierToLoginMutation() {
  return useMutation<{ addSupplierToLogin: AddSupplierToLoginMutationResult }>(addSupplierToLoginMutation)
}
