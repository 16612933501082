import React, { useEffect, useState } from 'react'
import FormField, { inputType } from '../../../../components/formComponents/FormField'
import { FormProvider, useForm } from 'react-hook-form'
import ToggleField from '../../../../components/formComponents/ToggleField'
import {
  createParamsForCreateProductForDeliveryMutation,
  createParamsForCreateProductMutation,
  useCreateProductMutation
} from './services/createProductMutation'
import { isAnyQueryLoading } from '../../../../services/functions/queryHelpers'
import { ProductsType, useProductsQuery } from '../../services/productsQuery'
import LoadingContainer from '../../../../components/LoadingContainer'
import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import WasteTypesSelector from '../../../../components/dataComponents/WasteTypesSelector/WasteTypesSelector'
import { createParamsForUpdateProductMutation, useUpdateProductMutation } from './services/updateProductMutation'
import { setupFields } from '../../../../services/formsServices/setupFields'
import MaterialSelector from '../../../../components/dataComponents/MaterialSelector/MaterialSelector'
import MaterialTypeSelector from '../../../../components/dataComponents/MaterialTypeSelector/MaterialTypeSelector'
import FractionSelector from '../../../../components/dataComponents/FractionSelector/FractionSelector'
import { useMaterialQuery } from '../../../../services/queries/materialsQuery'
import { IStringObject } from '../../../../services/types/IStringObject'

export const AddOrEditProduct = ({
  isAddDeliveryProductsPopup,
  hideModal,
  productId
}: {
  isAddDeliveryProductsPopup: boolean
  hideModal: () => void
  productId?: number
}) => {
  const [materials, setMaterials] = useState<any>([])
  const [presetTonToCubes, setPresetTonToCubes] = useState('')
  const formMethods = useForm<IStringObject>({ defaultValues: { margin: 0 } })
  const productEditorQR = useProductsQuery({ id: productId?.toString() }, loadProductData, !productId)
  const [createProductMut, createProductMR] = useCreateProductMutation()
  const [updateProductMut, updateProductMR] = useUpdateProductMutation()
  const collectionImages = [
    'product_soilWithStones.jpg',
    'product_asphaltMixture.jpg',
    'product_woodWaste.jpg',
    'product_mixedWaste.jpg',
    'product_bulkyWaste.jpg',
    'product_bioWaste.jpg',
    'product_mixedConstructionWaste.jpg',
    'product_buildingRubble.jpg',
    'product_plasterboard.jpg',
    'product_eternit.jpg',
    'product_concrete.jpg',
    'product_other.jpg',
    'product-dek-pujcovna.jpg'
  ]

  const selectedMaterial = formMethods.watch('materialId')
  const selectedType = formMethods.watch('materialTypeId')
  const materialQR = useMaterialQuery()

  useEffect(() => {
    setMaterials(materialQR)
  })

  useEffect(() => {
    if (!selectedMaterial) return
    const selectedMaterialData = materialQR.data?.materials.find(x => x.id === selectedMaterial && x.isMaterial)
    if (selectedMaterialData?.tonToCubes) setPresetTonToCubes(selectedMaterialData.tonToCubes + '')
  }, [materialQR, selectedMaterial])

  useEffect(() => {
    if (!selectedType) return
    const selectedTypeData = materialQR.data?.materials.find(x => x.id === selectedType && x.isType)
    if (selectedTypeData?.tonToCubes) setPresetTonToCubes(selectedTypeData.tonToCubes + '')
  }, [materialQR, selectedType])

  return (
    <LoadingContainer showLoader={isAnyQueryLoading(productEditorQR, createProductMR, updateProductMR)}>
      <FormProvider {...formMethods}>
        {isAddDeliveryProductsPopup && (
          <form onSubmit={handleDeliveryFormSubmit}>
            <div className="row">
              <div className="col col-md-4">
                <FormField type={inputType.text} name={'name'} label={'Jméno, které se zobrazí zákazníkovi'} placeholder="Jméno" required />
              </div>
              <div className="col col-md-8">
                <FormField type={inputType.text} name={'tooltip'} label={'Tooltip'} placeholder="Přidat tooltip" />
              </div>
              <div className="col col-md-4 mt-2">
                <MaterialSelector materialsQuery={materials} />
              </div>
              <div className="col col-md-4 mt-2">
                <MaterialTypeSelector materialsQuery={materials} disabled={!selectedMaterial} selectedMaterial={selectedMaterial} />
              </div>
              <div className="col col-md-4 mt-2">
                <FractionSelector materialsQuery={materials} disabled={!selectedType} selectedMaterial={selectedMaterial} selectedType={selectedType} />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col col-md-3 mt-3">
                <FormField
                  type={inputType.number}
                  is2Decimal
                  name={'tonToCubes'}
                  label={'Přepočet 1 kubík na tuny'}
                  placeholder="Přidat přepočet"
                  defaultValue={presetTonToCubes}
                  required
                />
              </div>
              <div className="col col-md-3 mt-3">
                <FormField type={inputType.number} isPositive name={'margin'} label={'Marže navíc'} placeholder="Přidat marži" defaultValue="0" />
              </div>
              <div className="col col-md-3 mt-3">
                <FormField type={inputType.number} isPositive name={'uiOrder'} label={'Pořadí'} placeholder="Pořadí na frontendu" required />
              </div>
              <div className="col col-md-12 mt-4">
                <ToggleField name={'isActive'} label={'Je aktivní'} />
              </div>
              <div className="col col-md-12 mt-4">
                <ToggleField name={'isForPublic'} label={'Je veřejně nabízený všem'} />
              </div>
            </div>
            <div className="row">
              <div className="col col-is-12 text-right">
                <button className="btn btn-primary" type="submit">
                  {' '}
                  {productId ? 'Upravit produkt' : 'Přidat produkt'}
                </button>
              </div>
            </div>
          </form>
        )}
        {!isAddDeliveryProductsPopup && (
          <form onSubmit={handleFormSubmit}>
            <div className="row">
              <div className="col col-md-6">
                <FormField
                  type={inputType.text}
                  name={'name'}
                  label={'Jméno které se zobrazí zákazníkovi'}
                  placeholder="Přidat produkt pro odvoz odpadu"
                  required
                />
              </div>
              <div className="col col-md-6">
                <WasteTypesSelector required={false} />
              </div>
            </div>
            <p className="form-label mt-3">
              Ikona pro uživatele<span className="text-red">*</span>
            </p>
            <div className="form-selectgroup">
              {collectionImages.map(productName => (
                <label className="form-selectgroup-item" key={productName}>
                  <input type="radio" value={productName} className="form-selectgroup-input" {...formMethods.register('uiPicture', { required: true })} />
                  <span className="form-selectgroup-label">
                    <img src={`https://${process.env.REACT_APP_FE_HOSTNAME}/assets/images/${productName}`} width="100" alt={productName} />
                  </span>
                </label>
              ))}
              <label className="form-selectgroup-item">
                <input type="radio" value={undefined} className="form-selectgroup-input" {...formMethods.register('uiPicture', { required: true })} />
                <span className="form-selectgroup-label h-100 place-center">
                  <i className="fe fe-larger fe-maximize align-self-end" />
                  Bez obrázku
                </span>
              </label>
            </div>
            <div className="row mt-2">
              <div className="col col-is-12 col-md-12">
                <FormField type={inputType.text} name={'tooltip'} label={'Tooltip'} placeholder="Přidat tooltip" />
              </div>
              <div className="col col-md-6 mt-3">
                <FormField type={inputType.number} isPositive name={'margin'} label={'Marže navíc'} placeholder="Marže navíc" required />
              </div>
              <div className="col col-md-6 mt-3">
                <FormField type={inputType.number} isPositive name={'uiOrder'} label={'Pořadí zobrazování na UI'} placeholder="Pořadí na frontendu" required />
              </div>
              <div className="col col-md-12 mt-1">
                <ToggleField name={'isActive'} label={'Je aktivní'} />
              </div>
              <div className="col col-md-12 mt-1">
                <ToggleField name={'isForPublic'} label={'Je veřejně nabízený všem'} />
              </div>
              <div className="col col-md-12 mt-1">
                <ToggleField name={'ignoreContainerRentPrice'} label={'Nepočítat do ceny dobu pronájmu kontejneru'} />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col col-is-12 col-md-12">
                <FormField type={inputType.text} name={'wasteDescription'} label={'Popis odpadu'} maxLength={730} />
              </div>
              <div className="col col-is-12 col-md-12">
                <FormField type={inputType.text} name={'acceptedWaste'} label={'Co patří do kontejneru'} maxLength={730} />
              </div>
              <div className="col col-is-12 col-md-12">
                <FormField type={inputType.text} name={'unsupportedWaste'} label={'Co nepatří do kontejneru'} maxLength={730} />
              </div>
            </div>
            <div className="row mt-1">
              <div className="col col-is-12 text-right">
                <button className="btn btn-primary" type="submit">
                  {' '}
                  {productId ? 'Upravit produkt' : 'Přidat produkt'}
                </button>
              </div>
            </div>
          </form>
        )}
      </FormProvider>
    </LoadingContainer>
  )

  function handleFormSubmit(e) {
    e.preventDefault()
    let data = formMethods.watch()
    const resultPromise = productId
      ? updateProductMut({
          variables: createParamsForUpdateProductMutation(data, productId)
        })
      : createProductMut({ variables: createParamsForCreateProductMutation(data) })
    processPageOperation({
      promise: resultPromise,
      successAction: exitHandler,
      successMessage: `Produkt byl úspěšně ${productId ? 'upraven' : 'přidán'}`
    })
    return false
  }

  function handleDeliveryFormSubmit(e) {
    e.preventDefault()
    let data = formMethods.getValues()
    const resultPromise = productId
      ? updateProductMut({
          variables: createParamsForUpdateProductMutation(data, productId)
        })
      : createProductMut({ variables: createParamsForCreateProductForDeliveryMutation(data) })
    processPageOperation({
      promise: resultPromise,
      successAction: exitHandler
    })
    return false
  }

  function exitHandler() {
    productEditorQR.refetch()
    hideModal()
  }

  function loadProductData(data: { products: ProductsType[] }) {
    if (!productId || !data.products || data.products.length !== 1) throw new Error('Produkt nebyl nalezen')
    const productData = data.products[0]
    setupFields(productData, {
      includeKeys: [
        'name',
        'tooltip',
        'uiPicture',
        'uiOrder',
        'margin',
        'isForPublic',
        'tonToCubes',
        'wasteDescription',
        'acceptedWaste',
        'unsupportedWaste',
        'ignoreContainerRentPrice'
      ],
      formMethods
    })
    formMethods.setValue('isActive', !productData.disabled)
    formMethods.setValue('wasteTypeId', productData.wasteType?.id)
    formMethods.setValue('materialId', productData.material?.id)
    formMethods.setValue('fractionId', productData.fraction?.id)
    formMethods.setValue('materialTypeId', productData.type?.id)
  }
}
