import React, { useCallback, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
// @ts-ignore
import UserAvatar from 'react-user-avatar'
import 'react-user-avatar/example.css'
import NavItem from './MenuItem'
import { LoginContext } from '../../services/loginAndToken/useLogin'
import classNames from 'classnames'
import { avatarColor } from './avatar-color'
import { BsListColumnsReverse } from 'react-icons/bs'

//export const avatarBgColors = ['#FF0000', '#FF7373', '#FF7400', '#FFB273', '#CD0074', '#E667AF', '#00CC00', '#67E667']
//export const avatarBgColors2 = ['#ff0000', '#A60000', '#ff7373', '#ff7400', '#A64B00', '#FFB273', '#CD0074', '#85004B', '#E667AF', '#00CC00', '#008500', '#67E667']

export default function Header() {
  const login = useContext(LoginContext)
  const navigate = useNavigate()
  const [showMenu, setShowMenu] = useState(false)
  const avatarName = login?.currentJWT?.lastname ? [login?.currentJWT?.firstname, login?.currentJWT?.lastname].join(' ') : login?.currentJWT?.email
  const hideMenu = useCallback(() => setShowMenu(false), [])
  return (
    <>
      <header className="navbar navbar-expand-md navbar-light d-print-none">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => {
              setShowMenu(before => !before)
            }}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <a className={'header__logo d-none-navbar-horizontal pe-0 pe-md-3'} href={'/order-list'}>
            <img src={`/images/logo.svg`} height="32" alt="metrak.cz" className="navbar-brand-image" />
            <span>{login?.currentJWT?.isAdmin ? 'METRÁK Administrátor' : 'METRÁK Partner'}</span>
          </a>
          <div className="navbar-nav flex-row order-md-last">
            <div className="d-none d-md-flex me-3">
              <UserAvatar size={32} name={!!avatarName ? avatarName : 'User'} colors={avatarColor(avatarName)} />
              {login?.currentJWT?.isAdmin && (
                <div className="d-none d-xl-block px-2">
                  <div>{avatarName}</div>
                  <div className="mt-1 small text-muted">Administrator</div>
                </div>
              )}
              {!login?.currentJWT?.isAdmin && (
                <div className="d-none d-xl-block px-2">
                  <div>{[login?.currentJWT?.firstname, login?.currentJWT?.lastname].join(' ')}</div>
                  <div className="mt-1 small text-muted">{login?.currentJWT?.email}</div>
                </div>
              )}
            </div>
            <button
              className="link p-2"
              type={'button'}
              onClick={() =>
                login?.logout().then(() => {
                  navigate('/', { replace: true })
                })
              }
            >
              Logout
            </button>
          </div>
        </div>
      </header>

      <div className="navbar-expand-md">
        <div className={classNames('navbar-collapse', showMenu ? '' : 'collapse')} id="navbar-menu">
          <div className="navbar navbar-light">
            <div className="container-fluid">
              {login?.currentJWT?.isAdmin && (
                <ul className="navbar-nav">
                  <NavItem name={'Dashboard'} link={'/dashboard'} className="fe-bar-chart-2" hideMenu={hideMenu} />
                  <NavItem name={'Seznam zakázek'} link={'/order-list'} className="fe-align-justify" hideMenu={hideMenu} />
                  <NavItem name={'Nová objednávka'} link={'/add-order'} className="fe-plus-square" hideMenu={hideMenu} />
                  <NavItem name={'Produkty'} link={'/product-list?isCollection=true'} className="fe-shopping-cart" hideMenu={hideMenu} />
                  <NavItem name={'Kontejnery'} link={'/container-list'} className="fe-package" hideMenu={hideMenu} />
                  <NavItem name={'Dodavatelé'} link={'/supplier-list'} className="fe-truck" hideMenu={hideMenu} />
                  <NavItem name={'Mapa dodavatelů'} link={'/supplier-map'} className="fe-map" hideMenu={hideMenu} />
                  <NavItem name={'Zákazníci'} link={'/customer-list'} className="fe-briefcase" hideMenu={hideMenu} />
                  <NavItem name={'Přístupy'} link={'/login-list'} className="fe-user" hideMenu={hideMenu} />
                  <NavItem name={'Zpětné vazby'} link={'/response'} className="fe-users" hideMenu={hideMenu} />
                  <NavItem name={'Prohledat DB'} link={'/search-whole-db'} className="fe-search" hideMenu={hideMenu} />
                  {['info@metrak.cz', 'patrik.postelt@metrak.cz', 'ilona.kubickova@metrak.cz'].includes(login?.currentJWT?.email || '') && (
                    <NavItem
                      name={'Účetnictví'}
                      link={
                        '/accounting/transaction-pairing?page=1&pairingStatus=PROBLEMATICAL_WO_GOPAY&processingStatus=NEW_BY_PAYMENT_SERVICE&sortField=transactionDate&sortOrder=desc&limit=50'
                      }
                      className="fe-dollar-sign"
                      hideMenu={hideMenu}
                    />
                  )}
                  <NavItem name={'Nové ceníky'} link={'/configuration/supplier-list'} hideMenu={hideMenu} reactIcon={<BsListColumnsReverse />} />
                </ul>
              )}
              {!login?.currentJWT?.isAdmin && (
                <ul className="navbar-nav">
                  <NavItem name={'Seznam zakázek'} link={'/supplier/order-list'} className="fe-align-justify" hideMenu={hideMenu} />
                  <NavItem name={'Přiložit soubor'} link={'/supplier/file-upload'} className="fe-upload-cloud" hideMenu={hideMenu} />
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
