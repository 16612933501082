import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const deleteTerminalMutation = gql`
  mutation deleteTerminal($terminalId: Int!) {
    deleteTerminal(terminalId: $terminalId) {
      status {
        reason
        result
      }
    }
  }
`

export function useDeleteTerminalMutation() {
  return useMutation<{ deleteTerminal: IMutationResult }>(deleteTerminalMutation)
}
