import React from 'react'
import SelectField from '../../formComponents/SelectField'
import { IReactSelectItem } from '../../../services/types/ReactSelect'
import {useOrderContentStatesQuery} from "./services/orderContentStatesQuery";

export default function OrderContentStateSelector({ required, removeStates, name, label, disabled}: { disabled?: boolean; required?: boolean; removeStates?: string[], name?: string, label?: string }) {
  const orderContentStatesQR = useOrderContentStatesQuery()
  let contentStates: IReactSelectItem<any>[] = []
  let contentStatesUpdated: IReactSelectItem<any>[] = []
  if (!orderContentStatesQR.loading && orderContentStatesQR.data) {
    contentStates = orderContentStatesQR.data.orderContentStates.map(x => ({
      label: x.name,
      value: x.sysName,
    }))
  }

  if (contentStates.length > 0 && removeStates && removeStates.length > 0) {
    contentStatesUpdated = contentStates.slice(0).filter(x => !removeStates?.includes(x.value))
  }

  return (
    <SelectField
      name={name ?? 'orderContentState'}
      label={label ?? 'Status'}
      disabled={disabled}
      required={required}
      registerOptions={{ required: !!required }}
      optionsData={removeStates && removeStates.length > 0 ? contentStatesUpdated : contentStates}
      isLoading={orderContentStatesQR.loading}
    />
  )
}
