import { gql, useQuery } from '@apollo/client'

const suppliersQuery = gql`
  query suppliers {
    suppliers(order: "name") {
      id
      name
    }
  }
`

type SupplierItemType = {
  id: number
  name: string
}

export function useSuppliersQuery() {
  return useQuery<{ suppliers: SupplierItemType[] }>(suppliersQuery)
}
