export enum OrderContentTypesEnum {
  'Initial' = 'Initial',
  'WaitingForPayment' = 'WaitingForPayment',
  'ForAdminToApprove' = 'ForAdminToApprove',
  'ForSupplierToConfirm' = 'ForSupplierToConfirm',
  'ConfirmedBySupplier' = 'ConfirmedBySupplier',
  'RefusedBySupplier' = 'RefusedBySupplier',
  'ContainerArrived' = 'ContainerArrived',
  'ContainerTakenBack' = 'ContainerTakenBack',
  'OrderFinishedBySupplier' = 'OrderFinishedBySupplier',
  'OrderChargedByAdmin' = 'OrderChargedByAdmin',
  'Cancelled' = 'Cancelled'
}
