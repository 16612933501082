export type PairingStatusType =
  | 'NOT_PROCESSED'
  | 'TRANSACTION_HAS_NO_ORDER_NUMBER'
  | 'ORDER_NOT_FOUND'
  | 'ORDER_ALREADY_PAID'
  | 'TRANSACTION_HAS_DIFFERENT_AMOUNT_PAID'
  | 'ORDER_HAS_ADVANCE_PAYMENT_PAYMENT_TYPE'
  | 'ERROR_PROCESSING_PAYMENT'
  | 'MATCHED'
  | 'MATCHED_AND_PAID'

export const pairingStatusTranslation = {
  NOT_PROCESSED: 'Nezpracovaná',
  TRANSACTION_HAS_NO_ORDER_NUMBER: 'Transakce neobsahuje číslo objednávky',
  ORDER_NOT_FOUND: 'Nenalezena objednávka z VS transakce',
  ORDER_ALREADY_PAID: 'Objednávka již byla uhrazena - duplikátní platba',
  TRANSACTION_HAS_DIFFERENT_AMOUNT_PAID: 'Transakce má jinou částku než objednávka',
  ORDER_HAS_ADVANCE_PAYMENT_PAYMENT_TYPE: 'Objednávka je zálohová platba, nelze párovat',
  ERROR_PROCESSING_PAYMENT: 'Chyba při provádění operace zaplacení nad objednávkou',
  MATCHED: 'Úspěšně napárováno',
  MATCHED_AND_PAID: 'Úspěšně napárováno a uhrazeno'
}

export const pairingStatusBadgeColor = {
  Nezpracovaná: 'bg-secondary',
  'Transakce neobsahuje číslo objednávky': 'bg-yellow',
  'Nenalezena objednávka z VS transakce': 'bg-blue',
  'Objednávka již byla uhrazena - duplikátní platba': 'bg-warning',
  'Transakce má jinou částku než objednávka': 'bg-indigo',
  'Objednávka je zálohová platba, nelze párovat': 'bg-maroon',
  'Chyba při provádění operace zaplacení nad objednávkou': 'bg-danger',
  'Úspěšně napárováno': 'bg-green-leaves',
  'Úspěšně napárováno a uhrazeno': 'bg-success'
}

export const pairingStatusOptions = () => {
  return [
    ...Object.keys(pairingStatusTranslation).map(key => {
      return {
        value: key,
        label: pairingStatusTranslation[key]
      }
    }),
    { value: 'PROBLEMATICAL_WO_GOPAY', label: 'Problémové (bez GoPay)' }
  ]
}
