import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'
import { SupplierSupplierInputArgs } from '../../../../../graphql/aminTypes'

const createSupplierSupplierMutation = gql`
  mutation createSupplierSupplier($data: SupplierSupplierInputArgs!) {
    createSupplierSupplier(data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useCreateSupplierSupplierMutation() {
  return useMutation<{ createSupplierSupplier: IMutationResult }>(createSupplierSupplierMutation, { refetchQueries: ['supplierSupplierList'] })
}
