import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'

const upsertProductWasteMutation = gql`
  mutation upsertProductWaste($productWasteId: ID, $data: ProductWasteInputArgs!) {
    upsertProductWaste(data: $data, productWasteId: $productWasteId) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpsertProductWasteMutation() {
  return useMutation<{ upsertProductWaste: IMutationResult }>(upsertProductWasteMutation, { refetchQueries: ['productWasteList'] })
}
