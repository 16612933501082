import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'

const updateTerminalFeeSettings = gql`
  mutation updateTerminalFeeSettings($feeIntervals: [TerminalFeeSettingInputArgs!]!) {
    updateTerminalFeeSettings(feeIntervals: $feeIntervals) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpdateTerminalFeeSettings() {
  return useMutation<{ updateTerminalFeeSettings: IMutationResult }>(updateTerminalFeeSettings, { refetchQueries: ['getTerminalFeeSettings'] })
}
