import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const updateOrderMutation = gql`
  mutation updateOrder($orderId: Int!, $data: OrderDataInput!) {
    updateOrder(orderId: $orderId, data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpdateOrderMutation() {
  return useMutation<{ updateOrder: IMutationResult }>(updateOrderMutation)
}
