import { gql, useQuery } from '@apollo/client'
import { OrderContentsResult } from '../../../../graphql/aminTypes'

const orderDetailQuery = gql`
  query orderDetail($orderNumber: Int) {
    orderDetail(orderNumber: $orderNumber) {
      id
      order {
        id
        firstname
        lastname
        email
        phone
        street
        streetNumber
        companyName
        city
        zip
        ico
        dic
        companyCountryIso
        targetCompanyIco
        targetCompanyName
        companyResidence {
          id
          street
          streetNumber
          city
          zip
          countryIso
        }
      }
    }
  }
`

export function useOrderDetailQuery(orderNumber: string | null) {
  return useQuery<{ orderDetail: OrderContentsResult }>(orderDetailQuery, {
    fetchPolicy: 'network-only',
    variables: { orderNumber: orderNumber ? parseInt(orderNumber) : 0 },
    skip: !orderNumber
  })
}
