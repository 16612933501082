import { gql, useQuery } from '@apollo/client'
import { SupplierBranchEntity, TransportCategory } from '../../../../../../graphql/aminTypes'

export const getTerminalsWithWasteCategoryQuery = gql`
  query getTerminalsWithWasteCategory($transportCategory: TransportCategory!) {
    getBranchListByTransportCategory(transportCategory: $transportCategory) {
      id
      specification
      address {
        id
        city
        street
        zip
      }
      pou {
        id
        name
      }
      supplier {
        id
        name
      }
    }
  }
`

export function useGetBranchListByTransportCategoryQuery(transportCategory: TransportCategory) {
  return useQuery<{ getBranchListByTransportCategory: SupplierBranchEntity[] }>(getTerminalsWithWasteCategoryQuery, {
    fetchPolicy: 'network-only',
    variables: { transportCategory }
  })
}
