import { gql, useMutation } from '@apollo/client'
import { BaseMutationResult } from '../../../../graphql/aminTypes'

const forceMatchFioTransactionMutation = gql`
  mutation forceMatchFioTransaction($id: String!) {
    forceMatchFioTransaction(id: $id) {
      status {
        reason
        result
      }
    }
  }
`

export function useForceMatchFioTransactionMutation() {
  return useMutation<{ forceMatchFioTransaction: BaseMutationResult }>(forceMatchFioTransactionMutation, {
    refetchQueries: ['getFioSyncLatestResult', 'getFioTransactionList']
  })
}
