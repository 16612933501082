import { gql, useQuery } from '@apollo/client'
import { EmailHistory } from '../../../../../graphql/aminTypes'
import { contextEndpoint, EndpointEnum } from '../../../../../services/appConfiguration/contextEndpoint'

export const emailHistoryQuery = gql`
  query emailHistory($orderContentId: Int!) {
    emailHistory(orderContentId: $orderContentId) {
      id
      createdAt
      message
      recipientsTo
      subject
      templateName
    }
  }
`

export function useEmailHistoryQuery(orderContentId: number) {
  return useQuery<{ emailHistory: EmailHistory[] }>(emailHistoryQuery, {
    context: contextEndpoint(EndpointEnum.admin),
    variables: { orderContentId },
    fetchPolicy: 'network-only'
  })
}
