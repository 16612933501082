import { customFilter, selectFilter, textFilter } from 'react-bootstrap-table2-filter'
import DateRangeSelectorFilter from '../../components/bootstrapTable/DateRangeSelectorFilter'
import React from 'react'
import CustomTextFilter from '../../components/bootstrapTable/CustomTextFilter'
import { ICustomFilterReturnValue } from '../../components/bootstrapTable/CustomTextFilterInner'
import CustomSelectFilter from '../../components/bootstrapTable/CustomSelectFilter'
import {ColumnFilterTypes, IColumnDefinitionType} from './services/tableSpecificationTypes'
import { IStringObject } from '../types/IStringObject'
import { IReactSelectItem } from '../types/ReactSelect'
import NumberRangeFilter from '../../components/bootstrapTable/NumberRangeFilter'
import DateSelectorFilter from '../../components/bootstrapTable/DateSelectorFilter'

export const noFiltrationId = '0'

export function columnFilterFactory(
  columnDefinition: IColumnDefinitionType,
  columnName: string,
  filterDefaultValues: IStringObject,
  filterFunc: IStringObject<Function>,
  filterSelectOptions?: IReactSelectItem[]
) {
  if (columnDefinition.filterType === ColumnFilterTypes.text) {
    return {
      filter: textFilter({
        placeholder: "Zadejte filtr",
        defaultValue: filterDefaultValues[columnName] ? filterDefaultValues[columnName] : '',
        getFilter: filter => {
          if (filterFunc) filterFunc[columnName] = filter
        }
      })
    }
  }

  if (columnDefinition.filterType === ColumnFilterTypes.customText) {
    return {
      filter: customFilter({ type: 'customText', caseSensitive: false }),
      filterRenderer: function customTextFilter(onFilter: (value: ICustomFilterReturnValue) => {}) {
        return <CustomTextFilter onFilter={onFilter} placeholder={"Zadejte filtr"} defaultValue={filterDefaultValues[columnName]} />
      }
    }
  }
  if (columnDefinition.filterType === ColumnFilterTypes.customNumeric) {
    return {
      filter: customFilter({ type: 'customNumeric', caseSensitive: false }),
      filterRenderer: function customTextFilter(onFilter: (value: ICustomFilterReturnValue) => {}) {
        return <CustomTextFilter onFilter={onFilter} placeholder={"Zadejte filtr"} defaultValue={filterDefaultValues[columnName]} />
      }
    }
  }

  if (columnDefinition.filterType === ColumnFilterTypes.select) {
    if (!filterSelectOptions || filterSelectOptions.length <= 0) {
      return null
    }

    return {
      filter: selectFilter({
        options: filterSelectOptions,
        withoutEmptyOption: true,
        defaultValue: filterDefaultValues[columnName] ? filterDefaultValues[columnName] : noFiltrationId,
        getFilter: filter => {
          if (filterFunc) filterFunc[columnName] = filter
        }
      })
    }
  }

  if (columnDefinition.filterType === ColumnFilterTypes.customSelect) {
    return {
      filter: customFilter({ type: 'customSelect', caseSensitive: false }),
      filterRenderer: function customSelectFilter(onFilter: (value: string) => {}) {
        return (
          <CustomSelectFilter
            onFilter={onFilter}
            selectOptions={filterSelectOptions ?? []}
            defaultValue={filterDefaultValues[columnName] ? filterDefaultValues[columnName] : noFiltrationId}
          />
        )
      }
    }
  }

  if (columnDefinition.filterType === ColumnFilterTypes.dateRange) {
    return {
      filter: customFilter({ type: 'dateRange', caseSensitive: false }),
      filterRenderer: function dateRangeSelectorFilter(onFilter: Function, column: any) {
        return (
          <DateRangeSelectorFilter
            defaultDateFrom={filterDefaultValues[`${columnName}From`]}
            defaultDateTo={filterDefaultValues[`${columnName}To`]}
            onFilter={onFilter}
            column={column}
          />
        )
      }
    }
  }

  if (columnDefinition.filterType === ColumnFilterTypes.date) {
    return {
      filter: customFilter({ type: 'date', caseSensitive: false }),
      filterRenderer: function dateSelectorFilter(onFilter: Function, column: any) {
        return (
          <DateSelectorFilter
            defaultDate={filterDefaultValues[`${columnName}`]}
            onFilter={onFilter}
            column={column}
            highlightDates={columnDefinition.highlightDates}
          />
        )
      }
    }
  }

  if (columnDefinition.filterType === ColumnFilterTypes.numberRange) {
    return {
      filter: customFilter({ type: 'numberRange', caseSensitive: false }),
      filterRenderer: (onFilter: any, column: any) => {
        return (
          <NumberRangeFilter
            defaultValueFrom={filterDefaultValues[`${columnName}From`]}
            defaultValueTo={filterDefaultValues[`${columnName}To`]}
            onFilter={onFilter}
            column={column}
          />
        )
      }
    }
  }
  throw new Error('Wrong formatter type')
}
