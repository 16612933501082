import axios from 'axios'
import { useContext } from 'react'
import { LoginContext } from '../services/loginAndToken/useLogin'
export function useDownloadExport() {
  const loginData = useContext(LoginContext)
  const protocol: string = process.env.REACT_APP_CONNECTION_PROTOCOL || 'https'
  const hostname: string = process.env.REACT_APP_BACKEND_HOSTNAME || 'localhost:8008'

  return (serverDownloadPath: string) => {
    const serverDownloadPathWithProtocol = `${protocol}://${hostname}${serverDownloadPath}`
    axios
      .get(serverDownloadPathWithProtocol, { headers: { Authorization: `Bearer ${loginData?.at}` } })
      .then(response => {
        const blob = new Blob([response.data], { type: response.headers['content-type'] })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        const disposition = response.headers['content-disposition']
        const suggestedFilename = response.headers['x-suggested-filename']
        const matches = /filename="([^"]+)"/.exec(disposition)
        const filename = matches != null && matches[1] ? matches[1] : suggestedFilename ?? 'download.csv'

        a.download = filename
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
      })
      .catch(error => {
        console.error('Error fetching file:', error)
      })
  }
}
