import React from 'react'
import { IReactSelectItem } from '../../services/types/ReactSelect'
import { RegisterOptions, useFormContext } from 'react-hook-form'

interface SelectGroupParams {
  name: string
  caption?: string
  options: IReactSelectItem[]
  registerOptions?: RegisterOptions
  handleChange?: (v: string) => void
  inline?: boolean
  type?: 'radio' | 'checkbox'
}

export default function SelectGroup({ name, caption, options, registerOptions, handleChange, inline = false, type = 'radio' }: SelectGroupParams) {
  const { register, setValue } = useFormContext()

  return (
    <div className={inline ? 'ml-5 mt-2' : ''}>
      {caption && <label className="form-label">{caption}</label>}
      <div className="form-selectgroup form-selectgroup-pills">
        {options.map(item => {
          return (
            <label className="form-selectgroup-item" key={item.value}>
              <input type={type} className="form-selectgroup-input" {...register(name, registerOptions)} onChange={handleChangeValue} value={item.value} />
              <span className="form-selectgroup-label">{item.label}</span>
            </label>
          )
        })}
      </div>
    </div>
  )
  function handleChangeValue(e: React.ChangeEvent<HTMLInputElement>) {
    setValue(name, e.target.value)
    const selectedOption = options.find(x => x.value === e.target.value)
    if (selectedOption && selectedOption.onClick) selectedOption.onClick(e)
  }
}
