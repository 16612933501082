import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'

const createB2bPriceListMutation = gql`
    mutation createB2bPriceList($data: B2bPriceListDataInput!) {
        createB2bPriceList(data: $data) {
            status {
                reason
                result
            }
        }
    }
`

export function useCreateB2bPriceListMutation() {
    return useMutation<{ createMutation: IMutationResult }>(createB2bPriceListMutation)
}

export function createParamsForCreateB2bPriceListMutation(data: any) {
    return {
        data: {
            containerId: data.containerId,
            disabled: data.disabled,
            fixedPrice: +data.fixedPrice,
            priceCorrectionPercent: +data.priceCorrectionPercent,
            productId: data.productId,
            supplierId: data.supplierId,
            b2bCustomerId: data.b2bCustomerId
        }
    }
}