import { gql, useQuery } from '@apollo/client'
 const loginsQuery = gql`
  query logins {
    logins(isMasterAdmin: true, limit: 100, offset: 0) {
      id
      firstname
      lastname
      email
    }
  }
`
export type LoginItemType = {
  id: number
  email: string
  firstname: string
  lastname: string
}

export function useMasterAdminLoginsQuery() {
  return useQuery<{ logins: LoginItemType[] }>(loginsQuery)
}
