import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'

const upsertSupplierServiceLoadTransportMutation = gql`
  mutation upsertSupplierServiceLoadTransport($branchServiceId: ID!, $data: ServiceLoadTransportInput!) {
    upsertSupplierServiceLoadTransport(branchServiceId: $branchServiceId, data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpsertSupplierServiceLoadTransportMutation() {
  return useMutation<{ upsertSupplierServiceLoadTransport: IMutationResult }>(upsertSupplierServiceLoadTransportMutation, {
    refetchQueries: ['supplierServiceLoadTransport']
  })
}
