import React from 'react'
import { coordinatesToGps } from '../../../../services/functions/coordinatesToGps'
import { useTerminalsQuery } from './services/terminalsQuery'
import { useSuppliersQuery } from './services/suppliersQuery'
import { useFormContext } from 'react-hook-form'
import ToggleField from '../../../../components/formComponents/ToggleField'
import { Circle, MapContainer, Marker, Popup, TileLayer, Tooltip } from 'react-leaflet'
import L from 'leaflet'
import { createColorPointIcon } from '../../../../services/maps/create-color-point-icon'
import { MapUpdater } from '../../../../services/maps/MapUpdater'
import { MinimapControl } from '../../../../services/maps/MiniMap'

//https://icons8.com/icons/set/map-point
//mapový podklad: TopPlusOpen.Color z https://leaflet-extras.github.io/leaflet-providers/preview/

export default function OrderMap({
  customerGps,
  supplierId,
  isForDelivery
}: {
  customerGps?: { lat: string; lng: string }
  supplierId?: number
  isForDelivery?: boolean
}) {
  const formMethods = useFormContext()
  const suppliersQR = useSuppliersQuery({ isForDelivery, disabled: formMethods.watch('disabled'), customerGps })
  const terminalsQR = useTerminalsQuery({ skip: !customerGps })
  const showTerminals = formMethods.watch('showTerminals')
  const showDekSuppliers = formMethods.watch('dekSuppliersToggle')
  const currentSupplier = suppliersQR.data?.suppliers2.items?.find(s => s.id === supplierId)

  return (
    <>
      <MapContainer center={[49.8, 14.4094478]} zoom={8} style={{ height: '780px', width: '100%' }} scrollWheelZoom={true}>
        <TileLayer
          url="http://sgx.geodatenzentrum.de/wmts_topplus_open/tile/1.0.0/web/default/WEBMERCATOR/{z}/{y}/{x}.png"
          maxZoom={18}
          attribution='Map data: &copy; <a href="http://www.govdata.de/dl-de/by-2-0">dl-de/by-2-0</a>'
        />
        <MinimapControl position="topright" />
        {customerGps && (
          <MapUpdater
            customerGps={{ lat: parseFloat(customerGps.lat), lng: parseFloat(customerGps.lng) }}
            supplier={currentSupplier ? { lat: currentSupplier.gps.coordinates[1], lng: currentSupplier.gps.coordinates[0] } : undefined}
          />
        )}
        {suppliersQR.data?.suppliers2.items &&
          suppliersQR.data?.suppliers2.items.map(supp => {
            if (supp.name.includes('DEK') && !showDekSuppliers) return null
            if (supp.name === 'Cenový strop') return null

            const position = coordinatesToGps(supp.gps as { coordinates: number[] })
            const color = supp.disabled ? '#7F7E7E' : supp.name.includes('DEK') ? '#7f0e0e' : '#0096FF'
            const icon = createColorPointIcon(color)
            return (
              <React.Fragment key={supp.id}>
                <Marker key={supp.id} position={position} icon={icon}>
                  <Popup autoPan>{`${supp.name} (${supp.regionCode ?? ''} - ${supp.orpName ?? ''})`}</Popup>
                  <Tooltip>{`${supp.name} (${supp.regionCode ?? ''} - ${supp.orpName ?? ''})`}</Tooltip>
                </Marker>
                <Circle center={position} radius={supp.operationRadiusM as number} color={color} fill={false} opacity={0.5} />
              </React.Fragment>
            )
          })}
        {terminalsQR.data?.terminals &&
          showTerminals &&
          terminalsQR.data?.terminals.map(terminal => {
            if (terminal.disabled) return null
            const position = coordinatesToGps(terminal.gps as { coordinates: number[] })
            const color = terminal.disabled ? '#BEBADA' : '#FDB462'
            const icon = createColorPointIcon(color)
            return (
              <React.Fragment key={terminal.id}>
                <Marker key={terminal.id} position={position} icon={icon}>
                  <Popup>{`Koncovka ${terminal.name}`}</Popup>
                  <Tooltip>{`Koncovka ${terminal.name}`}</Tooltip>
                </Marker>
                <Circle center={position} radius={terminal.operationRadiusM as number} color={color} fill={false} pathOptions={{ fill: false }} />
              </React.Fragment>
            )
          })}

        {customerGps && customerGps.lat && customerGps.lng && (
          <Marker
            position={[parseFloat(customerGps.lat), parseFloat(customerGps.lng)]}
            icon={L.icon({ iconUrl: '/images/customerMapPoint.png', iconSize: [48, 48], iconAnchor: [24, 38] })}
            title="Zákazník"
          />
        )}
        {currentSupplier && (
          <Marker
            position={[currentSupplier!.gps.coordinates[1], currentSupplier!.gps.coordinates[0]]}
            icon={L.icon({ iconUrl: '/images/highlightedMapPoint.png', iconSize: [48, 48], iconAnchor: [24, 48] })}
          >
            <Tooltip>Dodavatel {currentSupplier!.name}</Tooltip>
          </Marker>
        )}
      </MapContainer>
      <div className="row mt-3">
        <div className="col-md-12">
          <div className={'d-flex flex-row'}>
            <ToggleField name={'showTerminals'} label={'Zobrazit koncovky'} />
            <div
              className={'ml-1 mt-1'}
              dangerouslySetInnerHTML={{
                __html: `<div style='background-color: #FDB462; width: 15px; height: 15px; border-radius: 50%; border: 2px solid white; margin-right: 8px;'></div>`
              }}
            />
            <ToggleField name={'disabled'} label={'Zobrazit neaktivní dodavatele'} />
            <div
              className={'ml-1 mt-1'}
              dangerouslySetInnerHTML={{
                __html: `<div style='background-color: #7F7E7E; width: 15px; height: 15px; border-radius: 50%; border: 2px solid white; margin-right: 8px;'></div>`
              }}
            />
            <ToggleField name={'dekSuppliersToggle'} label={'Zobrazit dodavatele pro DEK'} />
            <div
              className={'ml-1 mt-1'}
              dangerouslySetInnerHTML={{
                __html: `<div style="background-color: #7f0e0e; width: 15px; height: 15px; border-radius: 50%; border: 2px solid white; margin-right: 8px;"></div>`
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}
