import React from 'react'
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts'
import {chartItemType} from './services/chartItemType'
import {colorsSchema1, colorsSchema2} from './services/chartColours'
import {IStringObject} from '../../services/types/IStringObject'

export function BarChartComponent({
                                      layout,
                                      data,
                                      onClick,
                                      height = 400,
                                      alternateColorSchema = false,
                                      dataKeys,
                                      enableLabels = false,
                                      hideLegend = false,
                                      yAxisWidth = 90,
                                      colorSchemaOffset = 0,
                                      dataFormatter

                                  }: {
    layout: 'vertical' | 'horizontal'
    data: chartItemType[]
    onClick?: (item: chartItemType) => void
    height?: number
    alternateColorSchema?: boolean
    dataKeys: Record<string, string>[]
    enableLabels?: boolean
    hideLegend?: boolean
    yAxisWidth?: number
    colorSchemaOffset?: number
    dataFormatter?: (value, name, props) => string

}) {
    const colorSchema = alternateColorSchema ? colorsSchema2 : colorsSchema1
    const onClickFeature: IStringObject = onClick ? {onClick: onClick, cursor: 'pointer'} : {}

    const labelsProps: Record<string, any> = enableLabels ? {label: {position: layout === 'vertical' ? 'right' : 'top'}} : {}
    return (
        <ResponsiveContainer width="99%" height={height}>
            <BarChart layout={layout} data={data}>
                <CartesianGrid strokeDasharray="3 3"/>
                {layout === 'vertical' && <YAxis dataKey="name" type="category" width={yAxisWidth}/>}
                {layout === 'vertical' && <XAxis type="number" allowDecimals={false} domain={[0, getMaxDomain]}/>}
                {layout === 'horizontal' && <XAxis dataKey="name"/>}
                {layout === 'horizontal' && <YAxis domain={[0, getMaxDomain]}/>}
                <Tooltip formatter={dataFormatter ? dataFormatter : undefined}/>
                {!hideLegend && <Legend/>}
                {dataKeys.map((x, i) => <Bar key={Object.keys(x)[0]} isAnimationActive={false} dataKey={Object.keys(x)[0]}
                                             name={Object.values(x)[0]}
                                             fill={colorSchema[(i + colorSchemaOffset) % colorSchema.length]}
                                             {...labelsProps} {...onClickFeature}>
                </Bar>)}
            </BarChart>
        </ResponsiveContainer>
    )
}

function getMaxDomain(datamax: number) {
    let upperBound = datamax * 1.2
    const nrOfDecPlaces = Math.floor(Math.log10(upperBound))
    const lowerDimension = Math.pow(10, nrOfDecPlaces - 1)
    return Math.round(Math.floor(upperBound / lowerDimension) * lowerDimension)
}
