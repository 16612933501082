import { DivIcon } from 'leaflet'
import { createColorPointIcon } from './create-color-point-icon'

// Function to create a special icon for multiple service categories
export const createMultiServiceIcon = (colors: string[]) => {
  if (colors.length === 1) return createColorPointIcon(colors[0])

  const numberOfColors = colors.length
  const dotSize = 20

  // Create multiple small dots within a parent div, each with its own color
  const dotsHTML = colors
    .map((color, index) => {
      const angle = (360 / numberOfColors) * index
      const positionX = Math.cos((angle * Math.PI) / 180) * 8
      const positionY = Math.sin((angle * Math.PI) / 180) * 8
      return `<div style="background-color: ${color}; 
                          width: ${dotSize}px; 
                          height: ${dotSize}px; 
                          border-radius: 50%; 
                          border: 2px solid black; 
                          position: absolute; 
                          left: ${7.5 + positionX}px; 
                          top: ${7.5 + positionY}px;"></div>`
    })
    .join('')

  // Create the container for the dots
  const iconHTML = `
    <div style="position: relative; width: ${dotSize * 2}px; height: ${dotSize * 2}px;">
      ${dotsHTML}
    </div>
  `

  // Return a DivIcon with the combined HTML
  return new DivIcon({
    className: 'custom-div-icon',
    html: iconHTML,
    iconSize: [dotSize * 2, dotSize * 2],
    iconAnchor: [dotSize, dotSize] // Center the icon
  })
}
