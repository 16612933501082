import { createRoot } from 'react-dom/client'
import MetrakAdminMain from './MetrakAdminMain'
import * as Sentry from '@sentry/react'

require('dayjs/locale/cs')

Sentry.init({
  dsn: 'https://751a38cdc0d04023b26b4af6a8daf91c@sentry.integritty.cz/15',
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT
})

const container = document.getElementById('root')
const root = createRoot(container!) // createRoot(container!) if you use TypeScript
root.render(<MetrakAdminMain />)
