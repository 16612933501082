import { gql, useMutation } from '@apollo/client'
import {IMutationResult} from "../../../../services/types/gqlTypes";

const updateSupplierPriceListDeliveryMutation = gql`
    mutation updateDeliveryPriceList($id: Int!, $data: DeliveryPriceListDataInput!) {
        updateDeliveryPriceList(id: $id, data: $data) {
            status {
                reason
                result
            }
        }
    }
`

export function useUpdateSupplierDeliveryPriceListMutation() {
    return useMutation<{ updateSupplierDeliveryPriceList: IMutationResult }>(updateSupplierPriceListDeliveryMutation)
}
