import { gql, useQuery } from '@apollo/client'
import { IGqlPagingQueryParams } from '../../../services/bootstrapTable/usePagingFromURLQuery'

const loginsCountQuery = gql`
  query loginsCount($offset: Int, $limit: Int, $order: String, $lastname: String, $email: String) {
    loginsCount(offset: $offset, limit: $limit, order: $order, lastname: $lastname, email: $email) 
  }
`

export function useLoginsCountQuery(params: IGqlPagingQueryParams) {
  return useQuery<{ loginsCount: number }>(loginsCountQuery, { fetchPolicy: 'network-only', variables: params })
}
