import { useFormContext } from 'react-hook-form'
import React, { useRef } from 'react'
import { useAddSupplierToLoginMutation } from './services/addSupplierToLoginMutation'
import { useDeleteSupplierToLoginMutation } from './services/deleteSupplierToLoginMutation'
import LoadingContainer from '../../../LoadingContainer'
import { isAnyQueryLoading } from '../../../../services/functions/queryHelpers'
import useBootstrapTableDataMapper from '../../../../services/bootstrapTable/useBootstrapTableDataMapper'
import { LoginToSupplierType } from '../../../../scenesAdmin/LoginList/services/loginsQuery'
import { suppliersToLoginDataDefinition } from './services/suppliersToLoginDataDefinition'
import SupplierSelector from '../../../dataComponents/SupplierSelector/SupplierSelector'
import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import BootstrapTable from 'react-bootstrap-table-next'
import classNames from 'classnames'

export default function SupplierChooser({
  loadData,
  loginId,
}: {
  loadData?: LoginToSupplierType[]
  loginId?: number
}) {
  const [addSuppToLoginMut, addSuppToLoginQR] = useAddSupplierToLoginMutation()
  const [deleteSuppToLoginMut, deleteSuppToLoginQR] = useDeleteSupplierToLoginMutation()
  const { setValue, watch } = useFormContext()
  const { generateColumnListForBootstrapTable, mapDataArrayToTable } = useBootstrapTableDataMapper<any>(suppliersToLoginDataDefinition)
  const columnDefinition = generateColumnListForBootstrapTable({
    actionsReference: {
      deleteSupplier: deleteSuppToLoginHandler
    }
  })
  const loginsData = useRef(loadData ? mapDataArrayToTable(loadData) : [])

  const supplierId = watch('supplierId')
  return (
    <LoadingContainer showLoader={isAnyQueryLoading(addSuppToLoginQR, deleteSuppToLoginQR)}>
      <div className="d-flex">
        <div className="p-2 flex-fill">
          <SupplierSelector required={false} removeSuppliers={loginsData.current.map(x => x.supplierId)} />
        </div>
        <div className="p-2 align-self-center">
          <i
            role={'button'}
            tabIndex={0}
            className={classNames('fe fe-plus-circle strong h1', supplierId ? 'cursorPointer text-primary' : '')}
            onClick={addSuppToLoginHandler}
            onKeyDown={addSuppToLoginHandler}
          />
        </div>
      </div>

      <BootstrapTable
        bootstrap4
        striped
        condensed
        classes="table-responsive-lg digiTable"
        noDataIndication={() => <>'Uživatel nemá přiřazeny žádné dodavatele'</>}
        keyField="id"
        data={loginsData.current}
        columns={columnDefinition}
      />
    </LoadingContainer>
  )

  function deleteSuppToLoginHandler(data: Record<string | number | symbol, any>) {
    let resultPromise = deleteSuppToLoginMut({
      variables: { supplierToLoginId: data.id },
      update: (cache, mutationResult) => {
        if (loadData) {
          const suppToDeleteIndex = loginsData.current.findIndex(x => x.id === data.id)
          if (suppToDeleteIndex >= 0) loginsData.current.splice(suppToDeleteIndex, 1)
        }
      }
    })
    processPageOperation({
      promise: resultPromise,
    })
  }

  function addSuppToLoginHandler() {
    if (!supplierId || !loginId) return
    let resultPromise = addSuppToLoginMut({
      variables: { loginId: loginId, supplierId: supplierId },
      update: (cache, mutationResult) => {
        if (mutationResult.data && mutationResult.data.addSupplierToLogin.data) {
          loginsData.current.push(mapDataArrayToTable([mutationResult.data.addSupplierToLogin.data])[0])
        }
      }
    })
    processPageOperation({
      promise: resultPromise,
      successAction: result => {
        setValue('supplierId', undefined)
      }
    })
  }
}
