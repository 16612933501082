import { gql, useQuery } from '@apollo/client'
import { IGqlPagingQueryParams } from '../../../services/bootstrapTable/usePagingFromURLQuery'

export const loginsQuery = gql`
  query logins($offset: Int, $limit: Int, $order: String, $lastname: String, $email: String) {
    logins(offset: $offset, limit: $limit, order: $order, lastname: $lastname, email: $email) {
      id
      email
      isActive
      firstname
      lastname
      isMasterAdmin
      loginToSupplier {
        id
        supplier {
          id
          name
        }
      }
    }
  }
`

export type LoginItemType = {
  id: number
  isActive: boolean
  email: string
  firstname: string
  lastname: string
  isMasterAdmin: boolean
  loginToSupplier: LoginToSupplierType[]
}

export type LoginToSupplierType = {
  id: number
  supplier: {
    id: number
    name: string
  }
}

export function useLoginsQuery(params: IGqlPagingQueryParams) {
  return useQuery<{ logins: LoginItemType[] }>(loginsQuery, { fetchPolicy: 'network-only', variables: params })
}
