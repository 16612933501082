import React from 'react'
import { MapContainer, Marker, Popup, TileLayer, Tooltip } from 'react-leaflet'
import { SupplierMapItem } from '../SupplierSupplierMap'
import { serviceCategoryColors } from '../services/service-category-colors'
import { createMultiServiceIcon } from '../../../services/maps/create-multiservice-icon'
import { serviceCategoryTranslation } from '../../../services/types/service-category.type'
import { useLocation, useNavigate } from 'react-router-dom'
import { getParamFromUrl } from '../../../services/functions/getParamFromUrl'

export default function SuppliersMap({ supplierBranches }: { supplierBranches: SupplierMapItem[] }) {
  let navigate = useNavigate()
  const supplierId = getParamFromUrl('supplierId')
  const supplierBranchId = getParamFromUrl('supplierBranchId')
  return (
    <MapContainer center={[50.0741169, 15.3004478]} zoom={8} style={{ height: '100vh', width: '100%' }}>
      <TileLayer
        url="http://sgx.geodatenzentrum.de/wmts_topplus_open/tile/1.0.0/web/default/WEBMERCATOR/{z}/{y}/{x}.png"
        maxZoom={18}
        attribution='Map data: &copy; <a href="http://www.govdata.de/dl-de/by-2-0">dl-de/by-2-0</a>'
      />
      {supplierBranches.map((supplier, idx) => (
        <>
          <Marker
            key={idx}
            position={[supplier.gps.lat, supplier.gps.lng]}
            icon={createMultiServiceIcon(supplier.serviceCategory.map(sc => serviceCategoryColors[sc]))}
          >
            <Popup>
              <button className={'btn-link'} type={'button'} onClick={() => handleRedirect(supplier)}>
                {supplier.name}
              </button>
              <br /> {supplier.serviceCategory.map(c => serviceCategoryTranslation[c]).join(', ')}
            </Popup>

            <Tooltip>
              {supplier.name} <br /> {supplier.serviceCategory.map(c => serviceCategoryTranslation[c]).join(', ')}
            </Tooltip>
          </Marker>
          {supplier.supplierId === supplierId && <Marker position={[supplier.gps.lat, supplier.gps.lng]}></Marker>}
          {supplier.branchId === supplierBranchId && <Marker position={[supplier.gps.lat, supplier.gps.lng]}></Marker>}
        </>
      ))}
    </MapContainer>
  )

  function handleRedirect(supplier: SupplierMapItem) {
    navigate(`/configuration/supplier-branch-list?supplierId=${supplier.supplierId}`)
  }
}
