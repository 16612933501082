export function formatPhoneToDisplay(phone: string) {
  if(!phone || phone.length < 9) return phone

  const fixedPhone = phone.replace(/ /g,'')
  const phoneLength = fixedPhone.length
  const last3 = fixedPhone.substr(phoneLength - 3, 3)
  const mid3 = fixedPhone.substr(phoneLength - 6, 3)
  const first3 = fixedPhone.substr(phoneLength - 9, 3)
  return `${fixedPhone.substr(0, phoneLength-9)} ${first3} ${mid3} ${last3}`
}
