import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const deleteProductMutation = gql`
    mutation deleteProduct($productId: Int!) {
        deleteProduct(productId: $productId) {
            status {
                reason
                result
            }
        }
    }
`

export function useDeleteProductMutation() {
    return useMutation<{ deleteProduct: IMutationResult }>(deleteProductMutation)
}
