import { gql, useQuery } from '@apollo/client'
import { ZpoSuggestion } from '../../../../../graphql/aminTypes'
import { contextEndpoint, EndpointEnum } from '../../../../../services/appConfiguration/contextEndpoint'

const suggestZpoDataQuery = gql`
  query suggestZpoData($orderContentId: Int!) {
    suggestZpoData(orderContentId: $orderContentId) {
      wasteTypeId
      listZujId
      listOrpId
      wasteOriginDesc
      notificationEmails
      wasteCompositionDesc
      zpoCreatedDate
    }
  }
`
export function useSuggestZpoDataQuery() {
  return useQuery<{ suggestZpoData: ZpoSuggestion }>(suggestZpoDataQuery, {
    context: contextEndpoint(EndpointEnum.admin),
    skip: true
  })
}
