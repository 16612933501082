import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'
import { contextEndpoint, EndpointEnum } from '../../../services/appConfiguration/contextEndpoint'

const handleActionFromEmailMutation = gql`
  mutation handleActionFromEmail($token: String!) {
    handleActionFromEmail(token: $token) {
      status {
        reason
        result
      }
    }
  }
`

export function useHandleActionFromEmailMutation() {
  return useMutation<{ handleActionFromEmail: IMutationResult }>(handleActionFromEmailMutation, { context: contextEndpoint(EndpointEnum.public) })
}
