export const generalBadgeColors = {
  'K potvrzení': 'bg-info',
  'Vámi potvrzeno': 'bg-lime',
  'Vámi odmítnuto': 'bg-warning',
  'Materiál dovezen': 'bg-maroon',
  'Kontejner přistaven': 'bg-danger',
  'Kontejner odvezen': 'bg-cyan',
  'Zakázka dokončena': 'bg-indigo',
  'Zakázka vyúčtována': 'bg-secondary',
  Zrušeno: 'bg-dark'
}
