import {
  ColumnAlign,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../services/bootstrapTable/services/tableSpecificationTypes'
import { SupplierTerminalPriceListEntity } from '../../../graphql/aminTypes'
import { moneyToLocaleString } from '../../../services/dataToString/decimalToLocaleString'
import { wasteCategoryTypeTranslation } from '../../../services/types/waste-category'

export const supplierTerminalPriceListDataDefinition: TableDefinitionTypes<SupplierTerminalPriceListEntity> = {
  columnDefinition: {
    id: { hidden: true },
    isActive: {
      name: 'Aktivní',
      columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
      align: ColumnAlign.center,
      headerStyle: { width: '80px' }
    },
    name: { name: 'Odpad' },
    wasteCategory: { name: 'Kategorie odpadu' },
    priceKc: { name: 'Cena za likvidaci 1t pro Metrák.cz', editable: true, type: 'number' },
    isAcceptedAsTzs: { name: 'Akceptován jako TZS', columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter },
    actions: { name: 'Akce', columnFormatterType: ColumnFormatterTypes.actionsObject, align: ColumnAlign.center, headerStyle: { width: '80px' } }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    isActive: x => x.isActive,
    name: x => `${x.productWaste.name} (${x.productWaste.wasteType?.code})`,
    wasteCategory: x => wasteCategoryTypeTranslation[x.wasteCategory],
    priceKc: x => moneyToLocaleString(x.priceKc),
    isAcceptedAsTzs: x => x.isAcceptedAsTzs,
    actions: x => {
      const ret: IRowAction[] = []
      ret.push({
        type: RowActionType.codeAction,
        value: 'deleteTerminalPriceList',
        icon: 'fe-x',
        title: 'Odstranit ceník'
      })
      return ret
    }
  }
}
