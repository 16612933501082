import { gql, useQuery } from '@apollo/client'
import { ProductContainerEntity } from '../../../graphql/aminTypes'

export const productContainerListQuery = gql`
  query productContainerList {
    productContainerList {
      id
      name
      volumeM3
    }
  }
`

export function useProductContainerListQuery() {
  return useQuery<{ productContainerList: ProductContainerEntity[] }>(productContainerListQuery, {
    fetchPolicy: 'network-only'
  })
}
