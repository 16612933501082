import React from 'react'
import LoadingContainer from '../../../../components/LoadingContainer'
import { isAnyQueryLoading } from '../../../../services/functions/queryHelpers'
import BootstrapTable from 'react-bootstrap-table-next'
import useBootstrapTableDataMapper from '../../../../services/bootstrapTable/useBootstrapTableDataMapper'
import { orderChangeLogDataDefinition } from './services/order-change-log.data-definition'
import { useOrderChangeLogQuery } from './query/order-change-log.query'

export default function OrderChangeLog({ orderContentId }: { orderContentId?: number }) {
  const changeLogQR = useOrderChangeLogQuery(orderContentId)
  const { generateColumnListForBootstrapTable, mapDataArrayToTable } = useBootstrapTableDataMapper(orderChangeLogDataDefinition)
  const columnDefinition = generateColumnListForBootstrapTable({})

  let changeLog: any[] = []
  if (changeLogQR.data && changeLogQR.data.orderChangeLog) changeLog = mapDataArrayToTable(changeLogQR.data.orderChangeLog)
  return (
    <LoadingContainer showLoader={isAnyQueryLoading(changeLogQR)}>
      <BootstrapTable
        bootstrap4
        hover
        striped
        condensed
        classes="table-responsive-lg digiTable"
        noDataIndication={() => <>Žádná historie zatím nebyla zaznamenána</>}
        keyField="id"
        data={changeLog}
        columns={columnDefinition}
      />
    </LoadingContainer>
  )
}
