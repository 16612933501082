import { processPageOperation } from '../../formsServices/pageOperationProcessor'
import { useSyncAccountingDocument } from './mutations/sync-accounting-document.mutation'
import { MutationResult } from '@apollo/client'

export function useSyncAccountingDocumentHandler(refetchQueries?: string[]): [(row: any) => void, MutationResult<any>] {
  const [syncAccountingDocumentMut, syncAccountingDocumentQR] = useSyncAccountingDocument(refetchQueries)
  function syncAccountingDocumentHandlerFn(row: any) {
    const promise = syncAccountingDocumentMut({
      variables: {
        accountingDocumentId: row.id
      }
    })
    processPageOperation({
      promise: promise,
      successMessage: 'Synchronizace proběhla úspěšně.'
    })
  }
  return [syncAccountingDocumentHandlerFn, syncAccountingDocumentQR]
}
