import React from 'react'
import PageCard from '../../../components/Cards/pageCard'
import { useStatisticsOverviewQuery } from './services/statisticsOverviewQuery'
import { hasQueryArrayData, isQueryLoading } from '../../../services/functions/queryHelpers'
import { BarChartComponent } from '../../../components/Charts/BarChartComponent'
import { chartItemType } from '../../../components/Charts/services/chartItemType'
import { NoData } from '../../../components/Charts/NoData'
import { StatTotals } from './components/StatTotals'
import { useStatisticsContainersQuery } from './services/statisticsContainersQuery'
import { useStatisticsStatesQuery } from './services/statisticsStatesQuery'
import { useStatisticsProductsQuery } from './services/statisticsProductsQuery'
import { chartMoneyFormatter } from './services/chartMoneyFormatter'
import { useStatisticsSuppliers } from './services/statisticsSuppliers'
import { useStatisticsProductsTurnoverQuery } from './services/statisticsProductsTurnoverQuery'
import DatePickerComp from '../../../components/formComponents/datePickerComp'
import { useForm, FormProvider } from 'react-hook-form'
import dayjs from 'dayjs'

export default function Statistics() {
  const formMethods = useForm<any>()

  const fromDate = formMethods.watch('fromDate') ? dayjs(formMethods.watch('fromDate')).format('YYYY-MM-DD') : undefined
  const toDate = formMethods.watch('toDate') ? dayjs(formMethods.watch('toDate')).format('YYYY-MM-DD') : undefined
  const statisticsOverviewQR = useStatisticsOverviewQuery({ fromDate, toDate })
  const statisticsContainersQR = useStatisticsContainersQuery({ fromDate, toDate })
  const statisticsStatesQR = useStatisticsStatesQuery({ fromDate, toDate })
  const statisticsProductsQR = useStatisticsProductsQuery({ fromDate, toDate })
  const statisticsSuppliersQR = useStatisticsSuppliers({ fromDate, toDate })
  const statisticsProductsTurnoverQR = useStatisticsProductsTurnoverQuery({ fromDate, toDate })

  let ordersCountStatData: chartItemType[] = []
  let financeStatData: chartItemType[] = []
  let containersData: chartItemType[] = []
  let productsData: chartItemType[] = []
  let statesData: chartItemType[] = []
  let suppliersData: chartItemType[] = []
  let statisticsProductsTurnoverData: chartItemType[] = []
  if (hasQueryArrayData(statisticsContainersQR)) {
    containersData =
      statisticsContainersQR?.data?.statisticsContainers.map(x => ({
        name: `${x.name} ${x.capacityM3}m³`,
        totalOrders: x.count
      })) ?? []
  }
  if (hasQueryArrayData(statisticsProductsQR)) {
    productsData =
      statisticsProductsQR?.data?.statisticsProducts.map(x => ({
        name: x.name,
        totalOrders: x.count
      })) ?? []
  }
  if (hasQueryArrayData(statisticsStatesQR)) {
    statesData =
      statisticsStatesQR?.data?.statisticsStates.map(x => ({
        name: x.name,
        totalOrders: x.count
      })) ?? []
  }
  if (hasQueryArrayData(statisticsSuppliersQR)) {
    suppliersData =
      statisticsSuppliersQR?.data?.statisticsSuppliers.map(x => ({
        name: x.name,
        totalOrders: x.count
      })) ?? []
  }
  if (hasQueryArrayData(statisticsProductsTurnoverQR)) {
    statisticsProductsTurnoverData =
      statisticsProductsTurnoverQR?.data?.statisticsProductsTurnover.map(x => ({
        name: x.name,
        turnover: Math.floor(x.turnover)
      })) ?? []
  }
  if (hasQueryArrayData(statisticsOverviewQR)) {
    ordersCountStatData =
      statisticsOverviewQR?.data?.statisticsOverview.map(x => ({
        name: dayjs(x.monthYear).format('MM.YYYY'),
        objednavek: x.ordersCount,
        kontejneru: x.containersSum
      })) ?? []
    financeStatData =
      statisticsOverviewQR?.data?.statisticsOverview.map(x => ({
        name: dayjs(x.monthYear).format('MM.YYYY'),
        turnover: x.turnover,
        supplierProfitWoVatKc: x.supplierProfitWoVatKc,
        profitForMetrak: x.profitForMetrak
      })) ?? []
  }
  return (
    <div className="row row-cards">
      <div className="col-12">
        <h2>Výběr časového období</h2>
        <FormProvider {...formMethods}>
          <div className="row">
            <div className="col-xl-3 col-md-6 col-12">
              <DatePickerComp name={'fromDate'} label="Datum od" />
            </div>
            <div className="col-xl-3 col-md-6 col-12">
              <DatePickerComp name={'toDate'} label="Datum do" />
            </div>
          </div>
        </FormProvider>
      </div>
      <StatTotals fromDate={fromDate} toDate={toDate} />
      <div className="col-12 col-md-6 col-xl-6 center-block">
        <PageCard headerTitle={'Počty objednávek/odvezených kontejnerů'} showLoader={isQueryLoading(statisticsOverviewQR)}>
          {ordersCountStatData.length <= 0 && <NoData />}
          {ordersCountStatData.length > 0 && (
            <BarChartComponent
              data={ordersCountStatData}
              alternateColorSchema={true}
              layout={'horizontal'}
              enableLabels
              colorSchemaOffset={1}
              dataKeys={[{ objednavek: '# objednávek' }, { kontejneru: '# kontejneru' }]}
            />
          )}
        </PageCard>
      </div>
      <div className="col-12 col-md-6 col-xl-6 center-block">
        <PageCard headerTitle={'Finance'} showLoader={isQueryLoading(statisticsOverviewQR)}>
          {financeStatData.length <= 0 && <NoData />}
          {financeStatData.length > 0 && (
            <BarChartComponent
              data={financeStatData}
              dataFormatter={chartMoneyFormatter}
              alternateColorSchema={false}
              layout={'horizontal'}
              dataKeys={[{ turnover: 'Obrat' }, { supplierProfitWoVatKc: 'Zisk pro dodavatele' }, { profitForMetrak: 'Zisk pro Metrák' }]}
            />
          )}
        </PageCard>
      </div>
      <div className="col-12 col-md-6 col-xl-6 center-block">
        <PageCard headerTitle={'Odvezených kontejnerů podle typu odpadu'} showLoader={isQueryLoading(statisticsProductsQR)}>
          {productsData.length <= 0 && <NoData />}
          {productsData.length > 0 && (
            <BarChartComponent
              data={productsData}
              alternateColorSchema={false}
              layout={'vertical'}
              hideLegend
              colorSchemaOffset={1}
              yAxisWidth={180}
              dataKeys={[{ totalOrders: 'Odvezených kontejnerů' }]}
            />
          )}
        </PageCard>
      </div>
      <div className="col-12 col-md-6 col-xl-6 center-block">
        <PageCard headerTitle={'Obrat podle typu odpadu'} showLoader={isQueryLoading(statisticsProductsTurnoverQR)}>
          {statisticsProductsTurnoverData.length <= 0 && <NoData />}
          {statisticsProductsTurnoverData.length > 0 && (
            <BarChartComponent
              data={statisticsProductsTurnoverData}
              alternateColorSchema={false}
              layout={'vertical'}
              hideLegend
              colorSchemaOffset={6}
              yAxisWidth={180}
              dataFormatter={chartMoneyFormatter}
              dataKeys={[{ turnover: 'Obrat' }]}
            />
          )}
        </PageCard>
      </div>
      <div className="col-12 col-md-6 col-xl-6 center-block">
        <PageCard headerTitle={'Odvezených kontejnerů'} showLoader={isQueryLoading(statisticsContainersQR)}>
          {containersData.length <= 0 && <NoData />}
          {containersData.length > 0 && (
            <BarChartComponent
              data={containersData}
              alternateColorSchema={false}
              layout={'vertical'}
              hideLegend
              height={903}
              yAxisWidth={120}
              colorSchemaOffset={0}
              dataKeys={[{ totalOrders: 'Odvezených kontejnerů' }]}
            />
          )}
        </PageCard>
      </div>
      <div className="col-12 col-md-6 col-xl-6 center-block">
        <div className="row">
          <div className="col-12 center-block">
            <PageCard headerTitle={'Aktuální stavy objednávek'} showLoader={isQueryLoading(statisticsStatesQR)}>
              {statesData.length <= 0 && <NoData />}
              {statesData.length > 0 && (
                <BarChartComponent
                  data={statesData}
                  alternateColorSchema={false}
                  layout={'vertical'}
                  hideLegend
                  colorSchemaOffset={2}
                  yAxisWidth={250}
                  dataKeys={[{ totalOrders: 'Objednávek v tomto stavu' }]}
                />
              )}
            </PageCard>
          </div>

          <div className="col-12 center-block mt-3">
            <PageCard headerTitle={'Nejpoužívanější dodavatelé'} showLoader={isQueryLoading(statisticsSuppliersQR)}>
              {suppliersData.length <= 0 && <NoData />}
              {suppliersData.length > 0 && (
                <BarChartComponent
                  data={suppliersData}
                  alternateColorSchema={false}
                  layout={'vertical'}
                  hideLegend
                  colorSchemaOffset={3}
                  yAxisWidth={250}
                  dataKeys={[{ totalOrders: 'Odvezených objednávek' }]}
                />
              )}
            </PageCard>
          </div>
        </div>
      </div>
    </div>
  )
}
