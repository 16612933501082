import React from 'react'

/**
 * @usage
 * <TimelineItem time={time} text={text} />
 */
export default function TimelineItem({ time, text, subtext }: { time: string; text: string, subtext?: string }) {
  return (
    <li>
      <i className="fa" />
      <div className="time-line-item">
        <span className="time">
          <i className="fa fa-clock-o" /> {time}
        </span>
        <span className="time-line-header">{text}</span>
        {subtext && <div>{subtext}</div>}
      </div>
    </li>
  )
}
