import { gql, useMutation } from '@apollo/client'
import { IMutationResult} from "../../../../../services/types/gqlTypes";

const createMaterialMutation = gql`
    mutation createMaterial($data: CreateMaterialInput!) {
        createMaterial(data: $data) {
            status {
                reason
                result
            }
        }
    }
`

export function useCreateMaterialMutation() {
    return useMutation<{ createMutation: IMutationResult }>(createMaterialMutation)
}

export function createParamsForCreateProductMutation(data: any) {
    return {
        data: {
            name: data.name,
            tonToCubes: +data.tonToCubes,
            tooltip: data.tooltip,
            type: data.type,
            uiOrder: +data.uiOrder,
            uiPicture: data.uiPicture
        }
    }
}


export enum MaterialTypeEnum {
    FRACTION,
    MATERIAL,
    TYPE,
}
