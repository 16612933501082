export const serviceCategoryColors = {
  LOAD_TRANSPORT: '#FF6384',
  WASTE_COLLECTION_SOLID: '#36A2EB',
  WASTE_COLLECTION_LIQUID: '#FFCE56',
  WASTE_COLLECTION_ADR: '#4BC0C0',
  WASTE_COLLECTION_SCRAP: '#9966FF',
  WASTE_TERMINAL: '#FF9F40',
  TRANSPORT_OF_MATERIAL: '#8DD3C7',
  TRANSPORT_OF_CONCRETE: '#FB8072',
  TRANSPORT_OF_FUEL: '#80B1D3',
  TRANSPORT_OF_DEK_MACHINERY: '#B3DE69',
  MATERIAL_SELLER: '#FDB462',
  CONCRETE_SELLER: '#BEBADA'
}
