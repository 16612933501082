import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const deleteProductConcreteComponentMutation = gql`
  mutation deleteProductConcreteComponent($id: ID!) {
    deleteProductConcreteComponent(id: $id) {
      status {
        reason
        result
      }
    }
  }
`

export function useDeleteProductConcreteComponentMutation() {
  return useMutation<{ deleteProductConcreteComponent: IMutationResult }>(deleteProductConcreteComponentMutation, {
    refetchQueries: ['productConcreteComponents']
  })
}
