import React from 'react'

export const AuthorisationAlertSvg = ({ color }: { color?: string }) => {
  return (
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
           width="16.000000pt" height="16.000000pt" viewBox="0 0 16.000000 16.000000"
           preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,16.000000) scale(0.006667,-0.006667)"
             fill={color ? color : "#000000"} stroke="none">
              <path d="M1040 2311 c-232 -118 -484 -203 -715 -242 -109 -19 -123 -24 -149
-52 l-29 -31 6 -185 c18 -529 158 -944 434 -1286 59 -73 215 -222 298 -284
116 -88 273 -181 304 -181 34 0 173 80 285 164 300 225 506 510 628 866 64
187 99 363 117 593 17 201 15 296 -8 334 -23 36 -24 37 -151 58 -219 36 -457
116 -718 241 -73 35 -145 64 -160 63 -15 0 -79 -26 -142 -58z m251 -180 c224
-105 470 -189 665 -228 l84 -16 0 -67 c0 -235 -60 -548 -147 -770 -124 -317
-351 -592 -635 -770 l-68 -42 -67 43 c-380 237 -635 620 -733 1100 -26 122
-50 334 -50 435 l0 71 112 22 c182 36 507 153 683 244 28 14 50 26 51 27 1 0
48 -22 105 -49z"/>
              <path d="M1070 1749 c-35 -14 -60 -49 -60 -84 0 -16 22 -162 48 -322 42 -257
51 -296 72 -318 32 -34 88 -34 119 -1 19 20 29 67 71 317 27 162 50 307 50
322 0 38 -25 73 -62 86 -38 14 -204 13 -238 0z"/>
              <path d="M1145 896 c-43 -19 -66 -49 -72 -94 -10 -76 40 -132 117 -132 77 0
127 56 117 132 -6 46 -29 75 -74 94 -39 17 -50 17 -88 0z"/>
          </g>
      </svg>


  )
}
