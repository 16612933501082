import { MRT_ColumnDef } from 'material-react-table'
import { moneyToLocaleString } from '../../../../../services/dataToString/decimalToLocaleString'
import { formatDateForDisplayShort } from '../../../../../services/dataToString/dateTimeFormatter'
import { TerminalFeeSettingsWithId } from './terminal-fee-settings-with-id.type'
import { mrtNumberColumn } from '../../../../../components/mui-react-table/mrt-number-column'
import { useMrtDatepickerEditor } from '../../../../../components/mui-react-table/mrt-datepicker-edit'

export function useTerminalFeeSettingsTableDefinition(): MRT_ColumnDef<TerminalFeeSettingsWithId, any>[] {
  return [
    {
      header: 'Platný od',
      accessorKey: 'validFrom',
      enableEditing: true,
      maxSize: 100,
      Cell: ({ cell }) => formatDateForDisplayShort(cell.getValue()),
      Edit: useMrtDatepickerEditor()
    },
    {
      header: 'Platný do',
      accessorKey: 'validTo',
      enableEditing: true,
      enableSorting: false,
      maxSize: 100,
      Cell: ({ cell }) => formatDateForDisplayShort(cell.getValue()),
      Edit: useMrtDatepickerEditor()
    },
    {
      header: 'Rekultivační poplatek odpady 01-19',
      accessorKey: 'recultivationFeeBase',
      enableEditing: true,
      enableSorting: false,
      ...mrtNumberColumn,
      Cell: ({ cell }) => moneyToLocaleString(cell.getValue(), 'CZK', true),
      maxSize: 120
    },
    {
      header: 'Rekultivační poplatek odpady 20',
      accessorKey: 'recultivationFeeHigher',
      enableEditing: true,
      enableSorting: false,
      ...mrtNumberColumn,
      Cell: ({ cell }) => moneyToLocaleString(cell.getValue(), 'CZK', true),
      maxSize: 120
    },
    {
      header: 'Zákonný poplatek snížený',
      accessorKey: 'legalFeeLower',
      enableEditing: true,
      enableSorting: false,
      ...mrtNumberColumn,
      Cell: ({ cell }) => moneyToLocaleString(cell.getValue(), 'CZK', true),
      maxSize: 120
    },
    {
      header: 'Zákonný poplatek ostatní odpady',
      accessorKey: 'legalFeeBase',
      enableEditing: true,
      enableSorting: false,
      ...mrtNumberColumn,
      Cell: ({ cell }) => moneyToLocaleString(cell.getValue(), 'CZK', true),
      maxSize: 120
    },
    {
      header: 'Zákonný poplatek nebezpečné odpady',
      accessorKey: 'legalFeeHigher',
      enableEditing: true,
      enableSorting: false,
      ...mrtNumberColumn,
      Cell: ({ cell }) => moneyToLocaleString(cell.getValue(), 'CZK', true),
      maxSize: 120
    }
  ]
}
