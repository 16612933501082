import { getParamFromUrl } from '../../../../services/functions/getParamFromUrl'

export function getDefaultValues() {
  const dateFrom = getParamFromUrl('dateFrom')
  const dateTo = getParamFromUrl('dateTo')
  const isDek = getParamFromUrl('isDek')

  return {
    dateFrom: dateFrom ? new Date(dateFrom) : undefined,
    dateTo: dateTo ? new Date(dateTo) : undefined,
    isDek: isDek ? JSON.parse(isDek) : false
  }
}
