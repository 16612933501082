import PageCard from '../../components/Cards/pageCard'
import React, { useContext } from 'react'
import useBootstrapTableDataMapper from '../../services/bootstrapTable/useBootstrapTableDataMapper'
import { hasQueryArrayData, isAnyQueryLoading } from '../../services/functions/queryHelpers'
import BootstrapTable from 'react-bootstrap-table-next'
import usePagingFromURLQuery from '../../services/bootstrapTable/usePagingFromURLQuery'
import useBootstrapTablePagingAndFiltration from '../../services/bootstrapTable/useBootstrapTablePagingAndFiltration'
// @ts-ignore
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import { ModalContext } from '../../components/Modal/ModalProvider'
import { pageCardHeaderButton } from '../../components/Cards/pageCardHeaderButton'
import { ProductMaterialEntity } from '../../graphql/aminTypes'
import { productMaterialDataDefinition } from './services/productMaterialDataDefinition'
import { useProductMaterialListQuery } from './queries/product-material-list.query'
import AddOrEditMaterial from './components/AddOrEditMaterial/AddOrEditMaterial'
import { useDeleteProductMaterialMutation } from './mutations/delete-product-material.mutation'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'

export default function ProductMaterialList() {
  const { showModal, hideModal } = useContext(ModalContext)
  const { generateColumnListForBootstrapTable, mapDataArrayToTable, nameForGqlTranslation } =
    useBootstrapTableDataMapper<ProductMaterialEntity>(productMaterialDataDefinition)
  const { pageConfiguration, gqlQueryParams } = usePagingFromURLQuery(['name'], nameForGqlTranslation, { defaultPageSize: 100 })
  const [deleteProductMaterialMut, deleteProductMaterialQR] = useDeleteProductMaterialMutation()
  const productMaterialListQR = useProductMaterialListQuery(gqlQueryParams)
  const { handleTableChange } = useBootstrapTablePagingAndFiltration(pageConfiguration, undefined, true, 1)

  const columnDefinition = generateColumnListForBootstrapTable({
    filterDefaultValues: pageConfiguration.filterBy,
    currentSort: pageConfiguration.sort,
    handleTableChange: handleTableChange,
    columnAction: handleTableClick,
    actionsReference: {
      deleteProductMaterial: deleteProductMaterialHandler
    }
  })

  let productMaterials: any[] = []

  if (productMaterialListQR.data && hasQueryArrayData(productMaterialListQR))
    productMaterials = mapDataArrayToTable(productMaterialListQR.data.productMaterialList)

  return (
    <PageCard
      stickyHeader
      showLoader={isAnyQueryLoading(productMaterialListQR, deleteProductMaterialQR)}
      headerTitle={'Seznam materiálů pro návoz'}
      cardOptions={pageCardHeaderButton(
        'Přidat materiál',
        () =>
          showModal({
            title: 'Přidat materiál',
            modalContent: <AddOrEditMaterial onExit={hideModal} />,
            hideFooter: true
          }),
        'CreateMaterial'
      )}
    >
      <div className="table-responsive-xl">
        <BootstrapTable
          bootstrap4
          striped
          condensed
          hover
          remote={{ filter: true, pagination: false, sort: false }}
          classes="table-responsive-lg digiTable react-bootstrap-table-sticky-page"
          noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné záznamy"</>}
          keyField="id"
          data={productMaterials}
          columns={columnDefinition}
          filter={filterFactory()}
          sort={pageConfiguration.sort}
          onTableChange={handleTableChange}
        />
      </div>
    </PageCard>
  )

  function deleteProductMaterialHandler(row: any) {
    const promise = deleteProductMaterialMut({ variables: { id: row.id } })
    processPageOperation({
      promise,
      successMessage: 'Materiál byl odstraněn'
    })
  }
  function handleTableClick(e: Event, column: number, columnIndex: number, row: any) {
    if (!row.id || columnIndex >= 10) return
    const material = productMaterialListQR.data?.productMaterialList.find(x => x.id === row.id)
    showModal({
      title: 'Upravit materiál ' + row.name,
      hideFooter: true,
      modalContent: <AddOrEditMaterial materialToEdit={material} onExit={hideModal} />
    })
  }
}
