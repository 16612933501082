import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'
import { supplierBranchListQuery } from '../../../../SupplierBranchList/queries/supplier-branch-list.query'

const updateBranchServiceMutation = gql`
  mutation updateBranchService($branchServiceId: ID!, $data: SupplierBranchServiceInputArgs!) {
    updateBranchService(branchServiceId: $branchServiceId, data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpdateBranchServiceMutation() {
  return useMutation<{ updateBranchService: IMutationResult }>(updateBranchServiceMutation, { refetchQueries: [supplierBranchListQuery] })
}
