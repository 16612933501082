import { gql, useQuery } from '@apollo/client'

export const supplierPriceListQuery = gql`
  query supplierPriceList($id: Int) {
    supplierPriceList(id: $id) {
      id
      container {
        id
        name
        capacityM3
        uiOrder
      }
      baseRentPriceKc
      disabled
      pricePerKmKc
      minimumPriceForTransport
      flatRateRadius
      supplierFlatRates {
        id
        flatPriceKc
        product {
          id
          name
          uiOrder
        }
      }
    }
  }
`

export type SupplierPriceListType = {
  id: number
  disabled: boolean
  container: {
    id: number
    name: string
    capacityM3: number
    uiOrder: number
  }
  flatRateRadius: number
  baseRentPriceKc: number
  pricePerKmKc: number
  minimumPriceForTransport: number
  supplierFlatRates: SupplierFlatRateType[]
}

export type SupplierFlatRateType = {
  id: number
  flatPriceKc: number
  product: {
    id: number
    name: string
    uiOrder: number
  }
}

export function useSupplierPriceListQuery(id: number, onCompleted?: any) {
  return useQuery<{supplierPriceList: SupplierPriceListType[]}>(supplierPriceListQuery, {variables: { id }, fetchPolicy: "network-only", onCompleted: onCompleted})
}
