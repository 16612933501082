import React from 'react'
import FormField, { inputType } from '../../../components/formComponents/FormField'
import ToggleField from '../../../components/formComponents/ToggleField'

export function SettingsNode() {
  return (
    <>
      <div className="row mb-2">
        <div className="col-md-3">
          <strong>Malá mechanizace do 0,5t</strong>
        </div>
        <div className="col-md-3">
          <FormField name={'settings.minimumPriceOfTransportSmallMachineryKc'} label={'Minimální cena za dopravu'} type={inputType.number} valueAsNumber />
        </div>
        <div className="col-md-3">
          <FormField
            name={'settings.includedKmInMinimumTransportPriceSmallMachinery'}
            label={'Km v ceně minimální dopravy'}
            type={inputType.number}
            valueAsNumber
          />
        </div>
        <div className="col-md-3">
          <FormField name={'settings.manipulationsPerOrderSmallMachinery'} label={'Počet manipulací na zakázku'} type={inputType.number} valueAsNumber />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-3">
          <strong>Malá mechanizace do 3,5t</strong>
        </div>
        <div className="col-md-3">
          <FormField name={'settings.minimumPriceOfTransportMediumMachineryKc'} label={'Minimální cena za dopravu'} type={inputType.number} valueAsNumber />
        </div>
        <div className="col-md-3">
          <FormField
            name={'settings.includedKmInMinimumTransportPriceMediumMachinery'}
            label={'Km v ceně minimální dopravy'}
            type={inputType.number}
            valueAsNumber
          />
        </div>
        <div className="col-md-3">
          <FormField name={'settings.manipulationsPerOrderMediumMachinery'} label={'Počet manipulací na zakázku'} type={inputType.number} valueAsNumber />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-3">
          <strong>Malá mechanizace nad 3,5t</strong>
        </div>
        <div className="col-md-3">
          <FormField name={'settings.minimumPriceOfTransportHeavyMachineryKc'} label={'Minimální cena za dopravu'} type={inputType.number} valueAsNumber />
        </div>
        <div className="col-md-3">
          <FormField
            name={'settings.includedKmInMinimumTransportPriceHeavyMachinery'}
            label={'Km v ceně minimální dopravy'}
            type={inputType.number}
            valueAsNumber
          />
        </div>
        <div className="col-md-3">
          <FormField name={'settings.manipulationsPerOrderHeavyMachinery'} label={'Počet manipulací na zakázku'} type={inputType.number} valueAsNumber />
        </div>
      </div>
    </>
  )
}
