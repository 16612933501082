import {gql, useQuery} from '@apollo/client'
import {IGqlPagingQueryParams} from '../../../services/bootstrapTable/usePagingFromURLQuery'

const productsCountQuery = gql`
    query productsCount($disabled: String, $id: String, $isCollection: String, $isDelivery: String, $limit: Int, $name: String, $offset: Int, $order: String, $wasteTypeId: Int) {
        productsCount(disabled: $disabled, id: $id, isCollection: $isCollection, isDelivery: $isDelivery, limit: $limit, name: $name, offset: $offset,  order: $order, wasteTypeId: $wasteTypeId ) 
    }
`

export function useProductsCountQuery(params: IGqlPagingQueryParams) {
    return useQuery<{ productsCount: number }>(productsCountQuery, {
        fetchPolicy: 'network-only',
        variables: params
    })
}
