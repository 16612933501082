import React, { useEffect, useState } from 'react'
import PageCard from '../../components/Cards/pageCard'
import { getParamFromUrl } from '../../services/functions/getParamFromUrl'
import { useHandleActionFromEmailMutation } from './services/handleActionFromEmailMutation'
import { Alert, alertType } from '../../components/formComponents/alert'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import { IMutationResult } from '../../services/types/gqlTypes'
import { actionTranslation } from './services/actionTranslations'
import { IStringObject } from '../../services/types/IStringObject'
import classnames from 'classnames'
import { toast } from 'react-toastify'

export default function HandleActionFromEmail() {
  const token = getParamFromUrl('token')
  let type = getParamFromUrl('type')
  const orderNumber = getParamFromUrl('orderNumber')
  const [responseMessage, setResponseMessage] = useState<IStringObject | undefined>(undefined)

  const [handleActionMut, handleActionQR] = useHandleActionFromEmailMutation()

  let messageSetup = actionTranslation[type ?? '']
  useEffect(() => {
    if (!type || !actionTranslation[type] || !token) toast.error('Chybí povinné parametry pro potvrzení', { autoClose: false })
  }, [])

  return (
    <section className="antialiased border-top-wide border-primary d-flex flex-column">
      <div className="flex-fill d-flex flex-column justify-content-center py-4">
        <div className="container-tight py-6">
          <div className="text-center mb-4">
            <img src={`/images/logo.svg`} height="47" alt="" />
          </div>
          <PageCard pageCardClass="card card-md" bodyTitle={messageSetup?.notification(orderNumber ?? '')} showLoader={handleActionQR.loading}>
            {messageSetup && !responseMessage && (
              <>
                <button type="button" onClick={handleActionFromEmailMutation} className={classnames('btn w-100', messageSetup.buttonClass)}>
                  {messageSetup?.buttonLabel}
                </button>
                <div className="mb-3"></div>
              </>
            )}
            {messageSetup && responseMessage && (
              <>
                <Alert type={responseMessage.type} message={responseMessage.message}></Alert>
                {/*
                <div className="form-footer">
                  <button type="button" onClick={() => navigate('/')} className="btn btn-primary w-100">
                    Přejít do admin sekce
                  </button>
                </div>
*/}
              </>
            )}
          </PageCard>
        </div>
      </div>
    </section>
  )

  function handleActionFromEmailMutation() {
    const promise = handleActionMut({ variables: { token: token } })
    processPageOperation<IMutationResult>({
      promise: promise,
      failedAction: () =>
        setResponseMessage({
          type: alertType.danger,
          message: 'Tuto akci nelze už nad touto objednávkou/zakázkou provést. Kontaktujte administrátora, pokud potřebujete poradit.'
        }),
      successAction: result => {
        setResponseMessage({
          type: alertType.success,
          message: messageSetup?.actionConfirmation + ' proběhlo úspěšně. Okno můžete zavřít.'
        })
      },
      persistentError: true
    })
  }
}
