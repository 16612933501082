// Classes used by Leaflet to position controls
import { MapContainer, Rectangle, TileLayer, useMap, useMapEvent } from 'react-leaflet'
import React, { useCallback, useMemo, useState } from 'react'
import { useEventHandlers } from '@react-leaflet/core'

const POSITION_CLASSES = {
  bottomleft: 'leaflet-bottom leaflet-left',
  bottomright: 'leaflet-bottom leaflet-right',
  topleft: 'leaflet-top leaflet-left',
  topright: 'leaflet-top leaflet-right'
}

const BOUNDS_STYLE = { weight: 1, color: 'red' }

function MinimapBounds({ parentMap, zoom }: { parentMap: any; zoom: number }) {
  const minimap = useMap()

  // Clicking a point on the minimap sets the parent's map center
  const onClick = useCallback(
    e => {
      parentMap.setView(e.latlng, parentMap.getZoom())
    },
    [parentMap]
  )
  useMapEvent('click', onClick)

  // Keep track of bounds in state to trigger renders
  const [bounds, setBounds] = useState(parentMap.getBounds())
  const onChange = useCallback(() => {
    setBounds(parentMap.getBounds())
    const minimapZoom = parentMap.getZoom() - 6
    // Update the minimap's view to match the parent map's center and zoom
    minimap.setView(parentMap.getCenter(), minimapZoom)
    //    if (minimap.getZoom() !== minimapZoom) minimap.setZoom  (minimapZoom)
  }, [minimap, parentMap, zoom])

  // Listen to events on the parent map
  const handlers = useMemo(() => ({ move: onChange, zoom: onChange }), [])
  useEventHandlers({ instance: parentMap, context: parentMap.context }, handlers)

  return <Rectangle bounds={bounds} pathOptions={BOUNDS_STYLE} />
}

export function MinimapControl({ position }: { position?: string }) {
  const parentMap = useMap()

  // Memoize the minimap so it's not affected by position changes
  const minimap = useMemo(
    () => (
      <MapContainer
        style={{ height: 80, width: 120 }}
        center={parentMap.getCenter()}
        zoom={parentMap.getZoom() - 6}
        dragging={false}
        doubleClickZoom={false}
        scrollWheelZoom={false}
        attributionControl={false}
        zoomControl={false}
      >
        <TileLayer
          url="http://sgx.geodatenzentrum.de/wmts_topplus_open/tile/1.0.0/web/default/WEBMERCATOR/{z}/{y}/{x}.png"
          maxZoom={18}
          attribution='Map data: &copy; <a href="http://www.govdata.de/dl-de/by-2-0">dl-de/by-2-0</a>'
        />{' '}
        <MinimapBounds parentMap={parentMap} zoom={parentMap.getZoom() - 6} />
      </MapContainer>
    ),
    []
  )

  const positionClass = (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright
  return (
    <div className={positionClass}>
      <div className="leaflet-control leaflet-bar">{minimap}</div>
    </div>
  )
}
