import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const deleteLoginMutation= gql`
  mutation deleteLogin($loginId: Int!) {
    deleteLogin(loginId: $loginId) {
      status {
        reason
        result
      }
    }
  }
`

export function useDeleteLoginMutation() {
  return useMutation<{ deleteLogin: IMutationResult }>(deleteLoginMutation)
}
