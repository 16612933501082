import { gql, useQuery } from '@apollo/client'
import { AccountingGroupOrders, FioTransactionStateStat } from '../../../../graphql/aminTypes'

const accountingGetGroupOrdersQuery = gql`
  query accountingGetGroupOrders($dateFrom: String, $dateTo: String, $companyId: String, $isDek: String) {
    accountingGetGroupOrders(dateFrom: $dateFrom, dateTo: $dateTo, companyId: $companyId, isDek: $isDek) {
      totalInclVatKc
      totalWoVatKc
      customer {
        id
        companyName
        lastname
        firstname
        email
        streetNumber
        street
        city
        zip
        companyIdentification
      }
      invoicingCustomer {
        id
        companyName
        lastname
        firstname
        email
        streetNumber
        street
        city
        zip
        companyIdentification
      }

      orders {
        id
        orderNumber
        totalWoVatKc
        totalInclVatKc
        totalInclVatRoundingKc
        gps {
          coordinates
        }
        paymentStatus
        paymentType
        street
        streetNumber
        city
        zip
        email
        companyName
        ico
        firstname
        lastname
        companyResidence {
          id
          street
          streetNumber
          city
          zip
        }
        orderContent {
          id
          orderContentState {
            name
            id
          }
          serviceType
          containerCount
          weightTonnes
          arriveTime
          collectionTime
          containerFromDate
          containerToDate
          container {
            id
            name
            capacityM3
          }
          product {
            id
            name
          }
          weightTonnes
          containerCount
          orderContentState {
            name
            id
          }
        }
      }
    }
  }
`

export function useAccountingGetGroupOrdersQuery(params: any) {
  return useQuery<{ accountingGetGroupOrders: AccountingGroupOrders[] }>(accountingGetGroupOrdersQuery, {
    fetchPolicy: 'network-only',
    variables: params
  })
}
