export const groupOrdersTableSelectRowConfig = (
  handleOnSelect: (row: any, isSelect: boolean, rowIndex: number, e: any) => boolean,
  handleOnSelectAll: Function,
  selected: number[]
): SelectRowOptions => ({
  mode: 'checkbox',
  clickToSelect: true,
  selected: selected,
  onSelect: handleOnSelect,
  onSelectAll: handleOnSelectAll,
  headerColumnStyle: {
    textAlign: 'center'
  }
})
