import PageCard from '../../components/Cards/pageCard'
import React, { useEffect, useState } from 'react'
import useBootstrapTableDataMapper from '../../services/bootstrapTable/useBootstrapTableDataMapper'
import { isAnyQueryLoading } from '../../services/functions/queryHelpers'
import BootstrapTable from 'react-bootstrap-table-next'
import { SearchWholeDbResult, useSearchWholeDbQuery } from './services/searchWholeDbQuery'
import { FormProvider, useForm } from 'react-hook-form'
import FormField, { inputType } from '../../components/formComponents/FormField'
import { searchWholeDbDataDefinition } from './services/searchWholeDbDataDefinition'
import { useNavigate } from 'react-router-dom'
import { getParamFromUrl } from '../../services/functions/getParamFromUrl'

export default function SearchWholeDb() {
  const formMethods = useForm({ defaultValues: { searchString: getParamFromUrl('searchString') ?? '' } })
  const navigate = useNavigate()
  const [searchResultData, setSearchResultData] = useState<any[]>([])

  const { generateColumnListForBootstrapTable, mapDataArrayToTable } = useBootstrapTableDataMapper<SearchWholeDbResult>(searchWholeDbDataDefinition)

  const searchWholeDbQR = useSearchWholeDbQuery(getParamFromUrl('searchString') ?? '')
  useEffect(() => {
    if (searchWholeDbQR.data && searchWholeDbQR.data.searchWholeDb && searchWholeDbQR.data.searchWholeDb.length > 0) {
      setSearchResultData(mapDataArrayToTable(searchWholeDbQR.data.searchWholeDb))
    } else setSearchResultData([])
  }, [searchWholeDbQR])
  const columnDefinition = generateColumnListForBootstrapTable({
    columnAction: handleTableClick
  })

  return (
    <PageCard showLoader={isAnyQueryLoading(searchWholeDbQR)} headerTitle={'Vyhledávání v celé aplikace'}>
      <>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
            <div className="row mb-3">
              <div className="col-md-6">
                <FormField type={inputType.text} name={'searchString'} label={'Napište co hledáte:'} />
              </div>
              <div className="col-md-3">
                <button type="submit" className="btn btn-primary ms-auto btn-lg mt-3">
                  Prohledat
                </button>
              </div>
            </div>
          </form>
        </FormProvider>

        <div className="table-responsive-xl">
          <BootstrapTable
            bootstrap4
            striped
            hover
            condensed
            remote
            classes="table-responsive-lg digiTable"
            noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné záznamy"</>}
            keyField="keyField"
            data={searchResultData}
            columns={columnDefinition}
          />
        </div>
      </>
    </PageCard>
  )

  function handleTableClick(e: Event, column: number, columnIndex: number, row: any) {
    switch (row.tableName) {
      case 'order_contents':
        navigate(`/order-detail?orderContentId=${row.id}`)
        break
      case 'orders':
        navigate(`/order-detail?orderId=${row.id}`)
        break
      case 'suppliers':
        navigate(`/supplier-list?id=${row.id}`)
        break
      case 'b2b_customers':
        navigate(`/customer-list?id=${row.id}`)
        break
      case 'containers':
        navigate(`/container-list?id=${row.id}`)
        break
      case 'logins':
        navigate(`/login-list?id=${row.id}`)
        break
      case 'terminals':
        break
      case 'products':
        navigate(`/product-list?id=${row.id}`)
        break
      case 'waste_types':
        break
    }
  }

  async function handleFormSubmit(data) {
    if (!data.searchString) return
    navigate(`/search-whole-db?searchString=${data.searchString}`)
    await searchWholeDbQR.refetch({ searchString: data.searchString })
  }
}
