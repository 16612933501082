import { IStringObject } from '../types/IStringObject'

export function sortArrayBy<T = IStringObject>(c: T[], selector: (x: T) => string) {
  c.sort((a: T, b: T): number => {
    return selector(a).localeCompare(selector(b), "cs")
  })
  return c
}

export function sortArrayByNumeric<T = IStringObject>(c: T[], selector: (x: T) => number) {
  c.sort((a: T, b: T) => compareNumeric(selector(a), selector(b)))
  return c
}

function compareNumeric(a: number, b: number): number {
  if (a > b) return 1
  if (a < b) return -1
  return 0
}
