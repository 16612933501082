import {
  ColumnAlign,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../../../services/bootstrapTable/services/tableSpecificationTypes'

export const suppliersToLoginDataDefinition: TableDefinitionTypes<any> = {
  columnDefinition: {
    id: { hidden: true },
    supplierId: { hidden: true },
    supplier: { name: 'Dodavatel' },
    actions: {
      name: 'Akce',
      columnFormatterType: ColumnFormatterTypes.actionsObject,
      align: ColumnAlign.right,
      headerStyle: { width: '80px' }
    }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    supplierId: x => x.supplier.id,
    supplier: x => x.supplier.name,
    actions: x => {
      const ret: IRowAction[] = []
      ret.push({
        type: RowActionType.codeAction,
        value: 'deleteSupplier',
        icon: 'fe-x',
        title: 'Odstranit'
      })
      return ret
    }
  }
}
