import {
  ColumnAlign,
  ColumnFilterTypes,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../../services/bootstrapTable/services/tableSpecificationTypes'
import { moneyToLocaleString } from '../../../../services/dataToString/decimalToLocaleString'
import React from 'react'
import { FioTransaction, PairingStatusEnum } from '../../../../graphql/aminTypes'
import dayjs from 'dayjs'
import { pairingStatusBadgeColor, pairingStatusTranslation } from '../types/pairing-status'
import { processingStatusBadgeColor, processingStatusTranslation } from '../types/processing-status.type'

export const transactionPairingDataDefinition: TableDefinitionTypes<FioTransaction> = {
  columnDefinition: {
    id: { hidden: true },
    order: { name: 'Spárovaná objednávka', align: ColumnAlign.center, headerStyle: { width: '110px' } },
    transactionId: { name: 'Fio ID', align: ColumnAlign.left, headerStyle: { width: '110px' } },
    transactionDate: {
      name: 'Datum',
      filterType: ColumnFilterTypes.dateRange,
      align: ColumnAlign.center,
      remoteSort: true,
      defaultRemoteSort: 'desc',
      headerStyle: { width: '130px' },
      nameForGqlSort: 'transactionId'
    },
    payerName: { name: 'Jméno plátce', filterType: ColumnFilterTypes.customText },
    payerAccountNumber: { name: 'Číslo účtu plátce', headerStyle: { width: '170px' } },
    variableSymbol: { name: 'VS', filterType: ColumnFilterTypes.customText, headerStyle: { width: '120px' } },
    message: { name: 'Zpráva pro příjemce', headerStyle: { width: '180px' }, filterType: ColumnFilterTypes.customText },
    paymentType: { name: 'Typ platby' },
    amount: { name: 'Částka', align: ColumnAlign.right },
    pairingStatus: {
      name: 'Stav párování',
      filterType: ColumnFilterTypes.customSelect,
      align: ColumnAlign.center,
      columnFormatterType: ColumnFormatterTypes.showInBadge,
      badgeColours: pairingStatusBadgeColor,
      headerStyle: { maxWidth: '130px' }
    },
    processingStatus: {
      name: 'Stav zpracování',
      filterType: ColumnFilterTypes.customSelect,
      align: ColumnAlign.center,
      columnFormatterType: ColumnFormatterTypes.showInBadge,
      badgeColours: processingStatusBadgeColor,
      headerStyle: { maxWidth: '130px' }
    },
    actions: {
      name: 'Akce',
      columnFormatterType: ColumnFormatterTypes.actionsObject,
      align: ColumnAlign.right,
      headerStyle: { width: '90px' }
    }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    order: x =>
      x.order?.orderNumber ? (
        <a href={`/order-detail?orderNumber=${x.order.orderNumber}`} target="_blank" rel="noopener noreferrer">{`${x.order.orderNumber}`}</a>
      ) : x.groupInvoiceId ? (
        <a
          href={`/accounting/group-invoices?page=1&variableSymbol=${x.variableSymbol}&limit=50`}
          target="_blank"
          rel="noopener noreferrer"
        >{`Hromadná faktura ${x.variableSymbol}`}</a>
      ) : (
        '-'
      ),
    transactionId: x => x.transactionId,
    transactionDate: x => dayjs(x.transactionDate).format('DD.MM.YYYY'),
    payerName: x => <strong>{x.payerName}</strong>,
    payerAccountNumber: x => x.payerAccountNumber,
    variableSymbol: x => x.variableSymbol,
    message: x => x.message,
    paymentType: x => x.paymentType,
    amount: x => moneyToLocaleString(x.amount, x.currency),
    pairingStatus: x => pairingStatusTranslation[x.status],
    processingStatus: x => processingStatusTranslation[x.processingStatus],
    actions: x => {
      const ret: IRowAction[] = []
      if (![PairingStatusEnum.MatchedAndPaid, PairingStatusEnum.Matched].includes(x.status))
        ret.push({
          type: RowActionType.codeAction,
          value: 'forceMatchFioTransaction',
          icon: 'fe-rotate-cw',
          title: 'Znovu zkusit napárovat platbu'
        })
      if (x.processingStatus !== 'APPROVED')
        ret.push({
          type: RowActionType.codeAction,
          value: 'approve',
          icon: 'fe-check-square',
          title: 'Označit jako schválené'
        })
      if (!x.order?.orderNumber && !x.groupInvoiceId)
        ret.push({
          type: RowActionType.codeAction,
          value: 'assignOrder',
          icon: 'fe-inbox',
          title: 'Napárovat k objednávce'
        })
      return ret
    }
  }
}
