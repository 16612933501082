import React, { useEffect } from 'react'
import LoadingContainer from '../LoadingContainer'
import { IReactSelectItem } from '../../services/types/ReactSelect'
import SelectGroup from '../formComponents/SelectGroup'
import { useFormContext } from 'react-hook-form'
import classNames from 'classnames'

type PageCardType = {
  headerTitle?: string | React.ReactElement
  bodyTitle?: string
  showLoader?: boolean
  pageCardClass?: string
  cardOptions?: React.ReactElement | Array<React.ReactElement>
  children?: React.ReactNode
  cardTabs?: IReactSelectItem[]
  documentTitle?: string
  showRouteChangeConfirmation?: boolean
  onRouteChangeCancel?: () => void
  onRouteChangeConfirm?: () => void
  stickyHeader?: boolean
}

export default function PageCard({
  headerTitle,
  cardTabs,
  bodyTitle,
  showLoader,
  pageCardClass,
  cardOptions,
  children,
  documentTitle,
  ...otherProps
}: PageCardType) {
  useEffect(() => {
    document.title = ((documentTitle ? documentTitle : headerTitle) ?? '') + ' | Metrák - Administrace'
  })

  const customPageCardClass = pageCardClass ? pageCardClass : 'card'
  const formMethods = useFormContext()

  if (!headerTitle && formMethods && cardTabs && cardTabs.length > 0) {
    const selectedTab = cardTabs.find(x => x.value === formMethods.watch('pageCardTabs'))
    if (selectedTab) headerTitle = selectedTab.label
  }
  return (
    <>
      <section className={customPageCardClass}>
        {headerTitle && (
          <>
            <div className="card-status bg-primaryColor" />
            <header className={classNames('card-header', otherProps.stickyHeader ? 'sticky-header' : undefined)}>
              <h1 className="card-title">{headerTitle}</h1>
              {cardTabs && cardTabs.length > 0 && <SelectGroup name={'pageCardTabs'} inline={true} options={cardTabs} />}
              {cardOptions && <div className="card-options">{cardOptions}</div>}
            </header>
          </>
        )}

        <LoadingContainer showLoader={showLoader}>
          <article className="card-body">
            {bodyTitle && <h3 className="card-title text-center mb-4">{bodyTitle}</h3>}
            {children}
          </article>
        </LoadingContainer>
      </section>
    </>
  )
}
