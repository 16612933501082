import { DataListItem } from '../../../../../components/HtmlDataList'
import React from 'react'
import { moneyToLocaleString } from '../../../../../services/dataToString/decimalToLocaleString'
import { AccountingDocument, OrderContentsResult } from '../../../../../graphql/aminTypes'

export function orderAccountingDataBuilder(orderContent: OrderContentsResult, accountingDocuments?: AccountingDocument[]): DataListItem[] {
  const invoice = accountingDocuments?.find(x => x.type === 'INVOICE' && !x.isCancelled)
  const proforma = accountingDocuments?.find(x => x.type === 'PROFORMA_INVOICE' && !x.isCancelled)
  const incomeReceipt = accountingDocuments?.find(x => x.type === 'INCOME_RECEIPT' && !x.isCancelled)
  const opravnyDanovyDoklad = accountingDocuments?.find(x => x.type === 'OPRAVNY_DANOVY_DOKLAD' && !x.isCancelled)

  return [
    {
      label: 'Celkem bez DPH',
      text: <>{moneyToLocaleString(orderContent.order.totalWoVatKc, 'CZK')}</>
    },
    {
      label: 'Celkem s DPH',
      text: <strong>{moneyToLocaleString(orderContent.order.totalInclVatKc, 'CZK')}</strong>
    },
    {
      label: 'Vráceno',
      text: orderContent.order.refundedKc ? <strong>{moneyToLocaleString(orderContent.order.refundedKc, 'CZK')}</strong> : <>&nbsp;</>
    },
    {
      label: 'Vybrat dobírku dodavatelem',
      text: orderContent.codToCollect ? <>{moneyToLocaleString(orderContent.codToCollect, 'CZK')}</> : <>&nbsp;</>
    },
    {
      label: 'Faktura',
      text:
        invoice && (invoice.documentWebUrl ?? invoice.documentUrl) ? (
          <a href={invoice.documentWebUrl ?? invoice.documentUrl!} target="_blank" rel="noreferrer noopener">
            Zobrazit
          </a>
        ) : (
          'Ne'
        )
    },
    { label: 'Hromadná faktura', text: invoice?.isGroupInvoice ? 'Ano' : 'Ne' },
    {
      label: 'Proforma faktura',
      text:
        proforma && (proforma.documentWebUrl ?? proforma.documentUrl) ? (
          <a href={proforma.documentWebUrl ?? proforma.documentUrl!} target="_blank" rel="noreferrer noopener">
            Zobrazit
          </a>
        ) : (
          'Ne'
        )
    },
    {
      label: 'Příjmový doklad',
      text:
        incomeReceipt && (incomeReceipt.documentWebUrl ?? incomeReceipt.documentUrl) ? (
          <a href={incomeReceipt.documentWebUrl ?? incomeReceipt.documentUrl!} target="_blank" rel="noreferrer noopener">
            Zobrazit
          </a>
        ) : (
          'Ne'
        )
    },
    {
      label: 'Opravný daňový doklad',
      text:
        opravnyDanovyDoklad && (opravnyDanovyDoklad.documentWebUrl ?? opravnyDanovyDoklad.documentUrl) ? (
          <a href={opravnyDanovyDoklad.documentWebUrl ?? opravnyDanovyDoklad.documentUrl!} target="_blank" rel="noreferrer noopener">
            Zobrazit
          </a>
        ) : (
          'Ne'
        )
    },
    { label: 'Poznámka k fakturaci', text: orderContent.order.invoicingNote }
  ]
}
