import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const deleteProductContainerMutation = gql`
  mutation deleteProductContainer($id: ID!) {
    deleteProductContainer(id: $id) {
      status {
        reason
        result
      }
    }
  }
`

export function useDeleteProductContainerMutation() {
  return useMutation<{ deleteProductContainer: IMutationResult }>(deleteProductContainerMutation, { refetchQueries: ['productContainerList'] })
}
