import { SupplierBranchServiceEntity } from '../../graphql/aminTypes'

export type ServiceCategoryType =
  | 'LOAD_TRANSPORT'
  | 'WASTE_COLLECTION_SOLID'
  | 'WASTE_COLLECTION_LIQUID'
  | 'WASTE_COLLECTION_ADR'
  | 'WASTE_COLLECTION_SCRAP'
  | 'WASTE_TERMINAL'
  | 'TRANSPORT_OF_MATERIAL'
  | 'TRANSPORT_OF_CONCRETE'
  | 'TRANSPORT_OF_FUEL'
  | 'TRANSPORT_OF_DEK_MACHINERY'
  | 'MATERIAL_SELLER'
  | 'CONCRETE_SELLER'

export enum ServiceCategoryEnum {
  LOAD_TRANSPORT = 'LOAD_TRANSPORT',
  WASTE_COLLECTION_SOLID = 'WASTE_COLLECTION_SOLID',
  WASTE_COLLECTION_LIQUID = 'WASTE_COLLECTION_LIQUID',
  WASTE_COLLECTION_ADR = 'WASTE_COLLECTION_ADR',
  WASTE_COLLECTION_SCRAP = 'WASTE_COLLECTION_SCRAP',
  WASTE_TERMINAL = 'WASTE_TERMINAL',
  TRANSPORT_OF_MATERIAL = 'TRANSPORT_OF_MATERIAL',
  TRANSPORT_OF_CONCRETE = 'TRANSPORT_OF_CONCRETE',
  TRANSPORT_OF_FUEL = 'TRANSPORT_OF_FUEL',
  TRANSPORT_OF_DEK_MACHINERY = 'TRANSPORT_OF_DEK_MACHINERY',
  MATERIAL_SELLER = 'MATERIAL_SELLER',
  CONCRETE_SELLER = 'CONCRETE_SELLER'
}

export const serviceCategoryTranslation = {
  LOAD_TRANSPORT: 'Autodoprava',
  WASTE_COLLECTION_SOLID: 'Pevný odpad',
  WASTE_COLLECTION_LIQUID: 'Kapalný odpad',
  WASTE_COLLECTION_ADR: 'Nebezpečný odpad',
  WASTE_COLLECTION_SCRAP: 'Sběr druhotných surovin',
  WASTE_TERMINAL: 'Koncovka',
  TRANSPORT_OF_MATERIAL: 'Doprava materiálu',
  TRANSPORT_OF_CONCRETE: 'Doprava betonu',
  TRANSPORT_OF_FUEL: 'Doprava paliva',
  TRANSPORT_OF_DEK_MACHINERY: 'Doprava strojů DEK',
  MATERIAL_SELLER: 'Prodej materiálu',
  CONCRETE_SELLER: 'Prodej betonu'
}

export function sortServiceCategoryEntityFn(a: SupplierBranchServiceEntity, b: SupplierBranchServiceEntity): number {
  return ServiceCategoryOrder[a.serviceCategory] - ServiceCategoryOrder[b.serviceCategory]
}

export function sortServiceCategoryFn(a: string, b: string): number {
  return ServiceCategoryOrder[a] - ServiceCategoryOrder[b]
}

export const ServiceCategoryOrder = {
  LOAD_TRANSPORT: 0,
  WASTE_COLLECTION_SOLID: 1,
  WASTE_COLLECTION_LIQUID: 2,
  WASTE_COLLECTION_ADR: 3,
  WASTE_COLLECTION_SCRAP: 4,
  WASTE_TERMINAL: 5,
  TRANSPORT_OF_MATERIAL: 6,
  TRANSPORT_OF_CONCRETE: 7,
  TRANSPORT_OF_FUEL: 8,
  TRANSPORT_OF_DEK_MACHINERY: 9,
  MATERIAL_SELLER: 10,
  CONCRETE_SELLER: 11
}

export const serviceCategoryOptions = Object.keys(ServiceCategoryEnum).map(key => ({
  value: key as ServiceCategoryType,
  label: serviceCategoryTranslation[key as ServiceCategoryType]
}))

export const getServiceType = (serviceCategory?: ServiceCategoryType): 'COLLECTION' | 'DELIVERY' | undefined => {
  if (!serviceCategory) return undefined
  if (['WASTE_COLLECTION_SOLID', 'WASTE_COLLECTION_LIQUID', 'WASTE_COLLECTION_ADR', 'WASTE_COLLECTION_SCRAP'].includes(serviceCategory)) {
    return 'COLLECTION'
  }
  if (['TRANSPORT_OF_MATERIAL', 'TRANSPORT_OF_CONCRETE', 'TRANSPORT_OF_FUEL'].includes(serviceCategory)) {
    return 'DELIVERY'
  }
}

export const getWasteCategory = (serviceCategory?: ServiceCategoryType): 'COLLECTION' | 'DELIVERY' | undefined => {
  if (!serviceCategory) return undefined
  if (['WASTE_COLLECTION_SOLID', 'WASTE_COLLECTION_LIQUID', 'WASTE_COLLECTION_ADR', 'WASTE_COLLECTION_SCRAP'].includes(serviceCategory)) {
    return 'COLLECTION'
  }
  if (['TRANSPORT_OF_MATERIAL', 'TRANSPORT_OF_CONCRETE', 'TRANSPORT_OF_FUEL'].includes(serviceCategory)) {
    return 'DELIVERY'
  }
}
