import { pairingStatusTranslation } from './pairing-status'

export type ProcessingStatusType = 'NEW_BY_PAYMENT_SERVICE' | 'APPROVED'

export const processingStatusTranslation = {
  NEW_BY_PAYMENT_SERVICE: 'Automaticky zpracováno',
  APPROVED: 'Potvrzeno adminem'
}

export const processingStatusBadgeColor = {
  'Automaticky zpracováno': 'bg-secondary',
  'Potvrzeno adminem': 'bg-success'
}

export const processingStatusOptions = () => {
  return Object.keys(processingStatusTranslation).map(key => {
    return {
      value: key,
      label: processingStatusTranslation[key]
    }
  })
}
