import React from 'react'
import { ServiceCategoryEnum, serviceCategoryTranslation, ServiceCategoryType } from '../../../services/types/service-category.type'
import { useFormContext } from 'react-hook-form'

export default function ServicesSelector({ servicesList }: { servicesList?: ServiceCategoryType[] }) {
  const formMethods = useFormContext()
  const availableServices = servicesList ?? []
  const omitServices = ['LOAD_TRANSPORT', 'WASTE_TERMINAL', 'MATERIAL_SELLER', 'CONCRETE_SELLER']
  return (
    <div className="row mb-2">
      <div className="col-md-12">
        <label className="form-label stronger">Vyberte službu</label>
        <div className="form-selectgroup">
          {Object.keys(ServiceCategoryEnum)
            .filter(c => !omitServices.includes(c))
            .map(serviceCategory => (
              <label className="form-selectgroup-item " key={serviceCategoryTranslation[serviceCategory]}>
                <input
                  type="radio"
                  value={serviceCategory}
                  className="form-selectgroup-input"
                  {...formMethods.register('serviceCategory', { required: true })}
                  disabled={!availableServices.includes(serviceCategory as ServiceCategoryType)}
                />
                <span className="form-selectgroup-label position-relative d-inline-block">
                  <img src={`/images/service-category/${serviceCategory}.png`} width="96" height="96" alt={serviceCategoryTranslation[serviceCategory]} />
                  {!availableServices.includes(serviceCategory as ServiceCategoryType) && (
                    <div className={'disabled top-0 left-0 position-absolute w-100 h-100 cursor-not-allowed bg-overlay-80'}></div>
                  )}
                </span>
              </label>
            ))}
        </div>
      </div>
    </div>
  )
}
