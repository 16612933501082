import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'

const upsertSupplierMaterialPriceListMutation = gql`
  mutation upsertSupplierMaterialPriceList($materialPriceListId: ID, $data: SupplierMaterialPriceListInputArgs!, $branchServiceId: ID!) {
    upsertSupplierMaterialPriceList(data: $data, materialPriceListId: $materialPriceListId, branchServiceId: $branchServiceId) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpsertSupplierMaterialPriceListMutation() {
  return useMutation<{ upsertSupplierMaterialPriceList: IMutationResult }>(upsertSupplierMaterialPriceListMutation, {
    refetchQueries: ['supplierMaterialPriceLists']
  })
}
