import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'

const deleteContainerWasteTypeQuery = gql`
  mutation deleteContainerWasteType($containerWasteTypeId: Int!) {
    deleteContainerWasteType(containerWasteTypeId: $containerWasteTypeId) {
      status {
        reason
        result
      }
    }
  }
`

export function useDeleteContainerWasteType() {
  return useMutation<{ deleteContainerWasteType: IMutationResult }>(deleteContainerWasteTypeQuery, { refetchQueries: ['containerTypes'] })
}
