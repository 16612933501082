import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'
import { contextEndpoint, EndpointEnum } from '../../../services/appConfiguration/contextEndpoint'
import { listOrderContentFilesQuery } from '../queries/listfOfFilesQuery'
import { getZpoDetailQuery } from '../components/zpo/query/getZpoDetailQuery'

const deleteFileMutation = gql`
  mutation deleteFile($key: String!, $orderContentId: Int!) {
    deleteFile(key: $key, orderContentId: $orderContentId) {
      status {
        reason
        result
      }
    }
  }
`

export function useDeleteFileMutation(orderContentId: () => number) {
  return useMutation<{ deleteFile: IMutationResult }>(deleteFileMutation, {
    context: contextEndpoint(EndpointEnum.admin),
    refetchQueries: result => [
      {
        query: listOrderContentFilesQuery,
        variables: { orderContentId: orderContentId() },
        context: contextEndpoint(EndpointEnum.admin)
      },
      {
        query: getZpoDetailQuery,
        variables: { orderContentId },
        context: contextEndpoint(EndpointEnum.admin)
      }
    ]
  })
}
