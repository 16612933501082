import { useFioTransactionListQuery } from './queries/transactions.query'
import useBootstrapTableDataMapper from '../../../services/bootstrapTable/useBootstrapTableDataMapper'
import { FioTransaction } from '../../../graphql/aminTypes'
import { transactionPairingDataDefinition } from './services/transaction-pairing-data-definition'
import usePagingFromURLQuery from '../../../services/bootstrapTable/usePagingFromURLQuery'
import useBootstrapTablePagingAndFiltration from '../../../services/bootstrapTable/useBootstrapTablePagingAndFiltration'
import { usePrepareFilterOptions } from '../../OrderList/services/usePrepareFilterOptions'
import { pairingStatusOptions } from './types/pairing-status'
import { processingStatusOptions } from './types/processing-status.type'
import LoadingContainer from '../../../components/LoadingContainer'
// @ts-ignore
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import React, { useContext, useState } from 'react'
import { BootstrapTablePagingWrapper } from '../../../components/bootstrapTable/BootstrapTablePagingWrapper'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import { transactionTableSelectRowConfig } from './services/table-select-row-setting'
import { useForceFioTransactionDownloadMutation } from './mutations/force-fio-transaction-download.mutation'
import { isAnyQueryLoading } from '../../../services/functions/queryHelpers'
import { processPageOperation } from '../../../services/formsServices/pageOperationProcessor'
import { useApproveFioTransactionsMutation } from './mutations/approve-fio-transactions.mutation'
import FioTransactionsStatsButtonRow from './components/FioTransactionsStatsButtonRow'
import { ModalContext } from '../../../components/Modal/ModalProvider'
import { PairWithOrderComponent } from './components/PairWithOrderComponent'
import { TransactionPairingTableRowDataType } from './types/table-row-data'
import { useGetFioSyncSettingsQuery } from './components/StatusBar/queries/get-fio-sync-settings.query'
import { useGetFioSyncLatestResultQuery } from './components/StatusBar/queries/get-fio-sync-latest-sync-result.query'
import StatusBar from './components/StatusBar/StatusBar'
import { usePayMatchedFioTransactionsMutation } from './mutations/pay-matched-fio-transactions'
import { useForceMatchFioTransactionMutation } from './mutations/force-match-fio-transaction.mutation'

export function TransactionPairing() {
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set())
  const [forceFioTransactionDownloadMut, forceFioTransactionDownloadQR] = useForceFioTransactionDownloadMutation()
  const [approveFioTransactionsMut, approveFioTransactionsQR] = useApproveFioTransactionsMutation()
  const [forceMatchFioTransactionMut, forceMatchFioTransactionQR] = useForceMatchFioTransactionMutation()
  const [payMatchedFioTransactionsMut, payMatchedFioTransactionsQR] = usePayMatchedFioTransactionsMutation()
  const { showModal, hideModal } = useContext(ModalContext)

  const { generateColumnListForBootstrapTable, mapDataArrayToTable, nameForGqlTranslation, defaultSort } =
    useBootstrapTableDataMapper<FioTransaction>(transactionPairingDataDefinition)
  const { pageConfiguration, gqlQueryParams } = usePagingFromURLQuery(
    ['transactionDateFrom', 'transactionDateTo', 'pairingStatus', 'processingStatus', 'variableSymbol', 'message', 'payerName'],
    nameForGqlTranslation,
    { defaultSort, defaultPageSize: 50, alternativeOrderByName: true }
  )
  const fioTransactionsQR = useFioTransactionListQuery(gqlQueryParams, undefined)
  const { bootstrapTablePaginationSetup, handleTableChange } = useBootstrapTablePagingAndFiltration(
    pageConfiguration,
    undefined,
    undefined,
    fioTransactionsQR.data?.getFioTransactionList.total
  )
  const { filterOptions } = usePrepareFilterOptions()
  filterOptions.addFilterOption('pairingStatus', pairingStatusOptions())
  filterOptions.addFilterOption('processingStatus', processingStatusOptions())

  const columnDefinition = generateColumnListForBootstrapTable({
    columnAction: handleTableClick,
    filterDefaultValues: pageConfiguration.filterBy,
    currentSort: pageConfiguration.sort,
    handleTableChange: handleTableChange,
    filterOptions4CustomSelectCreator: filterOptions,
    actionsReference: {
      assignOrder: assignOrderHandler,
      approve: approveHandler,
      forceMatchFioTransaction: forceMatchFioTransactionHandler
    }
  })

  let transactionData: any[] = []
  if (fioTransactionsQR.data && fioTransactionsQR.data.getFioTransactionList.data)
    transactionData = mapDataArrayToTable(fioTransactionsQR.data.getFioTransactionList.data)

  return (
    <LoadingContainer
      showLoader={isAnyQueryLoading(
        fioTransactionsQR,
        forceFioTransactionDownloadQR,
        approveFioTransactionsQR,
        payMatchedFioTransactionsQR,
        forceMatchFioTransactionQR
      )}
    >
      <StatusBar />
      <FioTransactionsStatsButtonRow setSelectedRows={setSelectedRows} />
      <PaginationProvider pagination={paginationFactory(bootstrapTablePaginationSetup)}>
        {({ paginationProps, paginationTableProps }: { paginationProps: any; paginationTableProps: any }) => (
          <div className="table-responsive-xl">
            <BootstrapTablePagingWrapper paginationProps={paginationProps} paginationTableProps={paginationTableProps}>
              <BootstrapTable
                bootstrap4
                striped
                condensed
                hover
                remote
                classes="table-responsive-lg digiTable"
                noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné objednávky"</>}
                keyField="id"
                selectRow={transactionTableSelectRowConfig(handleOnSelect, handleOnSelectAll, Array.from(selectedRows))}
                data={transactionData}
                columns={columnDefinition}
                filter={filterFactory()}
                filterPosition={'inline'}
                sort={pageConfiguration.sort}
                onTableChange={handleTableChange}
                {...paginationTableProps}
              />
            </BootstrapTablePagingWrapper>
          </div>
        )}
      </PaginationProvider>

      <div className="row mt-3">
        <div className="col-md-9">
          <div className="d-flex flex-row flex-wrap justify-content-md-around">
            <button type="button" className="btn bg-warning btn-block m-1" onClick={forceFioTransactionDownloadHandler}>
              <i className="fe fe-refresh-cw p-1" />
              Načíst nové transakce z FIO
            </button>
            <button
              type="button"
              disabled={selectedRows.size === 0}
              className="btn bg-blue btn-block m-1"
              onClick={payMatchedFioTransactionsHandler}
              title={'U napárovaných transakcí změní platební metodu na převodem a zaplatí je.'}
            >
              <i className="fe fe-dollar-sign p-1" />
              Zaplatit označené transakce
            </button>
            <button type="button" disabled={selectedRows.size === 0} className="btn bg-lime btn-block m-1" onClick={approveSelectedTransactionsHandler}>
              <i className="fe fe-check-square p-1" />
              Schválit označené transakce
            </button>
          </div>
        </div>
      </div>
    </LoadingContainer>
  )

  function assignOrderHandler(row: TransactionPairingTableRowDataType) {
    showModal({
      title: 'Párování transakce k objednávce',
      hideFooter: true,
      modalContent: (
        <PairWithOrderComponent
          rowData={row}
          onExit={() => {
            fioTransactionsQR.refetch()
            hideModal()
          }}
        />
      )
    })
  }

  function approveHandler(row: any) {
    const promise = approveFioTransactionsMut({ variables: { ids: [row.id] } })
    return processPageOperation({
      promise: promise,
      successMessage: 'Vybraná transakce byla označena jako schválená.',
      successAction: () => {
        setSelectedRows(new Set())
        fioTransactionsQR.refetch()
      }
    })
  }
  function forceMatchFioTransactionHandler(row: any) {
    const promise = forceMatchFioTransactionMut({ variables: { id: row.id } })
    return processPageOperation({
      promise: promise,
      successMessage: 'Pokus o zpárování byl proveden.'
    })
  }

  async function forceFioTransactionDownloadHandler() {
    const promise = forceFioTransactionDownloadMut()
    return processPageOperation({
      promise: promise,
      successMessage: 'Synchronizace s FIO a párování plateb bylo provedeno.',
      successAction: () => {
        setSelectedRows(new Set())
        fioTransactionsQR.refetch()
      }
    })
  }

  function approveSelectedTransactionsHandler() {
    const promise = approveFioTransactionsMut({ variables: { ids: Array.from(selectedRows) } })
    return processPageOperation({
      promise: promise,
      successMessage: 'Vybrané transakce byly označeny jako schválené.',
      successAction: () => {
        setSelectedRows(new Set())
        fioTransactionsQR.refetch()
      }
    })
  }
  function payMatchedFioTransactionsHandler() {
    const promise = payMatchedFioTransactionsMut({ variables: { ids: Array.from(selectedRows) } })
    return processPageOperation({
      promise: promise,
      successMessage: 'Vybrané transakce byly zaplaceny',
      successAction: () => {
        setSelectedRows(new Set())
        fioTransactionsQR.refetch()
      }
    })
  }

  function handleOnSelect(row: any, isSelect: boolean) {
    if (isSelect) setSelectedRows(new Set(selectedRows).add(row.id))
    else {
      selectedRows.delete(row.id)
      setSelectedRows(new Set(selectedRows))
    }
    return true
  }

  function handleOnSelectAll(isSelect: boolean, rows: any[]) {
    if (isSelect) {
      rows.forEach((r: any) => selectedRows.add(r.id))
    } else {
      rows.forEach((r: any) => selectedRows.delete(r.id))
    }
    setSelectedRows(new Set(selectedRows))
    return true
  }
  function handleTableClick(e: Event, column: number, columnIndex: number, row: any) {
    if (columnIndex === 0 || columnIndex > 9) return
    handleOnSelect(row, !selectedRows.has(row.id))
  }
}
