import { gql, useQuery } from '@apollo/client'
import { CustomerListPaginatedResponse } from '../../../graphql/aminTypes'

export const customerDetailQuery = gql`
  query customerList($id: ID!) {
    customerList(id: $id) {
      total
      items {
        id
        loginId
        companyIdentification
        companyTaxId
        companyName
        firstname
        lastname
        email
        invoicingEmail
        phone
        street
        streetNumber
        city
        zip
        countryIso
        allowedPaymentMethods
        primaryPaymentMethod
        b2bMarginCorrection
        metrakMarginCorrection
        customerDiscount
        isResellCompany
        totalContainersOrdered
        isActive
        passwordSetEmailExpiry
        hasFixedPrice
        updatedAt
        notes
        requireCertificates
        advanceAccountsSetup {
          isUsingAdvanceAccounts
          negativeBalanceAllowance
          useMultipleAccounts
        }
      }
    }
  }
`

export function useCustomerDetailQuery(id?: string) {
  return useQuery<{ customerList: CustomerListPaginatedResponse }>(customerDetailQuery, {
    fetchPolicy: 'network-only',
    variables: { id },
    skip: !id
  })
}
