import { gql, useQuery } from '@apollo/client'

const orderContentPriceItemsQuery = gql`
  query orderContentPriceItems {
    orderContentPriceItems {
      id
      name
      sysName
      partOfTotalPrice
      uiOrder
      incomeForSupplier
    }
  }
`

export function useOrderContentPriceItems() {
  return useQuery<{ orderContentPriceItems: OrderContentPriceItemType[] }>(orderContentPriceItemsQuery)
}

export type OrderContentPriceItemType = {
  id: number
  name: string
  sysName: string
  partOfTotalPrice: boolean
  incomeForSupplier: boolean
  uiOrder: number
}
