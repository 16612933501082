import MutationEvaluation from './mutationEvaluation'
import { IMutationResult } from '../types/gqlTypes'
import { toast } from 'react-toastify'

type processPageOperationType<T> = {
  promise: any
  successMessage?: string
  successAction?: (result: T, data: any) => void
  failedAction?: (result?: T, reason?: string) => void
  failedMessage?: (result?: T, reason?: string) => string
  reasonErrorTransPrefix?: string
  persistentError?: boolean
}

// controls setting of state variables: errorList, timeoutId
// handle error during processing for functional components
export function processPageOperation<T = IMutationResult>({
  promise,
  successMessage,
  successAction,
  failedAction,
  failedMessage,
  persistentError
}: processPageOperationType<T>) {
  if (!promise) throw new Error('No operation to process')
  return promise
    .then(async ({ data }: any) => {
      const mutationName = Object.keys(data)[0]
      const evalResult = new MutationEvaluation(data, mutationName)
      if (evalResult.isMutationFailed()) {
        const message = evalResult.getErrorReasonMessage()
        const customFailedMessage = failedMessage ? `\n ${(failedMessage(data[mutationName]), evalResult.reason)}` : message
        toast.error(customFailedMessage, { autoClose: persistentError ? false : 10000 })

        if (failedAction) await failedAction(data[mutationName], evalResult.reason)
        return
      } else {
        if (successMessage) toast.success(successMessage, { autoClose: 5000 })

        if (successAction) await successAction(data[mutationName], data)
        return
      }
    })
    .catch(async (error: Error) => {
      if (failedAction) await failedAction()
      console.log(JSON.stringify(error))
      if (error['graphQLErrors'] && error['graphQLErrors'].length > 0)
        error['graphQLErrors'].forEach((err: any) => {
          console.log(JSON.stringify(err))
          toast.error(err.message, { autoClose: persistentError ? false : 10000 })
        })
      else if (error['networkError']) {
        if (error['networkError'].result && error['networkError'].result['errors'] && error['networkError'].result['errors'].length > 0) {
          error['networkError'].result['errors'].forEach((err: any) => {
            console.log(JSON.stringify(err))
            toast.error(err.message.replace('Context creation failed: ', ''), { autoClose: persistentError ? false : 10000 })
          })
        } else toast.error(error['networkError'].message, { autoClose: persistentError ? false : 10000 })
      } else toast.error(error.message, { autoClose: persistentError ? false : 10000 })
      return
    })
}
