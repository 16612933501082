import Select, { SingleValue } from 'react-select'
import React, { useEffect, useState } from 'react'
import { useSuggestPouQuery } from './suggest-pou.query'
import { useFormContext } from 'react-hook-form'
import { IReactSelectItem } from '../../../../services/types/ReactSelect'
import { FormComponentBaseInput } from '../../../../components/formComponents/components/FormComponentBaseInput'
import { FaSearchLocation } from 'react-icons/fa'
import { useSuggestPouFromCityQuery } from './suggest-pou-from-city.query'

export default function SuggestPou({ loadedValue }: { loadedValue: string | undefined }) {
  const formMethods = useFormContext()
  const [selectedOption, setSelectedOption] = useState<SingleValue<IReactSelectItem> | undefined>(undefined)

  const [pouOptions, setPouOptions] = useState<IReactSelectItem[]>([])
  const suggestPouQR = useSuggestPouQuery()
  const suggestPouFromCityQR = useSuggestPouFromCityQuery()

  const handleInputChange = (inputValue: string) => {
    if (inputValue.length < 0) return
    suggestPouQR.refetch({ search: inputValue }).then(result => {
      setPouOptions(result.data.suggestPou.map(x => ({ value: x.id, label: `${x.name} (ORP: ${x.orp.name})` })))
    })
  }
  formMethods.register('pouId')
  useEffect(() => {
    if (formMethods.watch('pouId') && !selectedOption) {
      setSelectedOption({ value: formMethods.watch('pouId'), label: loadedValue ?? '' })
    }
  }, [formMethods.watch('pouId')])

  function setValue(value: SingleValue<IReactSelectItem>) {
    setSelectedOption(value)
    formMethods.setValue('pouId', value?.value ?? '')
  }

  return (
    <div className="row">
      <div className="col-md-10">
        <FormComponentBaseInput name={'pouId'} label={'POU'} registerOptions={{ required: true }} required={true}>
          <Select
            isLoading={suggestPouQR.loading || suggestPouFromCityQR.loading}
            value={selectedOption}
            onChange={setValue}
            onInputChange={handleInputChange}
            options={pouOptions}
            isSearchable={true}
            isClearable={true}
            required={true}
            menuPlacement="auto"
            menuPosition="fixed"
            className={`react-select`}
            classNamePrefix="reactSelect"
          />
        </FormComponentBaseInput>
      </div>
      <div className="col-md-2">
        <button className="btn btn-info mt-3" type={'button'} onClick={() => suggestPouFromCity()}>
          <FaSearchLocation />
        </button>
      </div>
    </div>
  )

  function suggestPouFromCity() {
    const city = formMethods.watch('address').city
    if (city) {
      suggestPouFromCityQR.refetch({ city }).then(result => {
        const pou = result.data?.suggestPouFromCity
        if (pou) {
          setPouOptions([{ value: pou.id, label: `${pou.name} (ORP: ${pou.orp.name})` }])
          setSelectedOption({ value: pou.id, label: `${pou.name} (ORP: ${pou.orp.name})` })
          formMethods.setValue('pouId', pou.id)
        }
      })
    }
  }
}
