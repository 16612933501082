import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const updateProductConcreteComponentMutation = gql`
  mutation updateProductConcreteComponent($id: ID!, $data: ProductConcreteComponentInputArgs!) {
    updateProductConcreteComponent(id: $id, data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpdateProductConcreteComponentMutation() {
  return useMutation<{ updateProductConcreteComponent: IMutationResult }>(updateProductConcreteComponentMutation, {
    refetchQueries: ['productConcreteComponents']
  })
}

export function createParamsForUpdateConcreteMutation(row, field, newValue) {
  const update = {
    name: row.name,
    cubicToTonnes: +row.cubicToTonnes,
    tooltip: row.tooltip,
    type: row.type,
    uiOrder: +row.uiOrder,
    isForPublic: row.isForPublic
  }
  if (field === 'cubicToTonnes' || field === 'uiOrder') {
    update[field] = +newValue
  } else if (field === 'isForPublic') {
    update[field] = JSON.parse(newValue)
  } else {
    update[field] = newValue
  }

  return update
}
