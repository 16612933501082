import {
  ColumnAlign,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../../../services/bootstrapTable/services/tableSpecificationTypes'
import { materialQueryItemType } from '../../../../../services/queries/materialsQuery'

export const materialListDataDefinition: TableDefinitionTypes<materialQueryItemType> = {
  columnDefinition: {
    id: { hidden: true },
    uiOrder: { name: 'Pořadí na FE', editable: true },
    name: { name: 'Jméno', sort: true, editable: true },
    tooltip: { name: 'Tooltip', editable: true },
    tonToCubes: { name: 'Výchozí t/m³', editable: true },
    actions: {
      name: 'Akce',
      columnFormatterType: ColumnFormatterTypes.actionsObject,
      align: ColumnAlign.right,
      headerStyle: { width: '60px' }
    }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    uiOrder: x => x.uiOrder,
    name: x => x.name,
    tooltip: x => x.tooltip,
    tonToCubes: x => x.tonToCubes,
    actions: x => {
      const ret: IRowAction[] = []
      ret.push({
        type: RowActionType.codeAction,
        value: 'deletePriceList',
        icon: 'fe-x',
        title: `Odstranit`
      })
      return ret
    }
  }
}
