export const tableNameToSection = {
  'b2b_customers': 'B2B zákazník',
  'containers': 'Kontejner',
  'logins': 'Uživatelský přístup',
  'order_contents': 'Zakázka',
  'orders': 'Objednávka',
  'suppliers': 'Dodavatel',
  'terminals': 'Koncovka',
  'products': 'Produkt',
  'waste_types': 'Typ odpadu'
}