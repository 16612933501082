import React from 'react'
import SelectField from '../../formComponents/SelectField'
import { IReactSelectItem } from '../../../services/types/ReactSelect'
import { sortArrayBy } from '../../../services/functions/sortArrayBy'
import { useTerminals } from './services/terminalsQuery'

export default function TerminalSelector({ required = true }: { required: boolean }) {
  const terminalsQR = useTerminals()
  let terminalsData: IReactSelectItem<any>[] = []
  if (!terminalsQR.loading && terminalsQR.data) {
    terminalsData = terminalsQR.data.terminals.map(x => ({
      label: x.name + (x.city ? ` (${x.city})` : ''),
      value: x.id
    }))
    sortArrayBy(terminalsData, x => x.label)
  }

  return (
    <SelectField
      name={'terminalId'}
      label={'Koncovka'}
      required={required}
      registerOptions={{ required: required }}
      optionsData={terminalsData}
      isLoading={terminalsQR.loading}
    />
  )
}
