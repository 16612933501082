import { FormProvider, useForm } from 'react-hook-form'
import { SupplierContainerRentRatesEntity } from '../../../graphql/aminTypes'
import FormField, { inputType } from '../../../components/formComponents/FormField'
import React, { useEffect } from 'react'
import { useUpsertContainerRentRateMutation } from './mutations/upsert-container-rent-rate.mutation'
import { processPageOperation } from '../../../services/formsServices/pageOperationProcessor'

export function ContainerRentRateEditor({ row, branchId, hideModal }: { row?: SupplierContainerRentRatesEntity; branchId?: string; hideModal: () => void }) {
  const [upsertContainerRentRateMut, upsertContainerRentRateMR] = useUpsertContainerRentRateMutation()
  const formMethods = useForm<SupplierContainerRentRatesEntity>()
  useEffect(() => {
    if (!row) return
    formMethods.setValue('name', row.name)
    formMethods.setValue('rentFreeForDays', row.rentFreeForDays)
    formMethods.setValue('baseRentPerDayKc', row.baseRentPerDayKc)
    formMethods.setValue('maximumRentDays', row.maximumRentDays)
    formMethods.setValue('monthlyRentPriceKc', row.monthlyRentPriceKc)
    formMethods.setValue('monthlyRentAfterDays', row.monthlyRentAfterDays)
    formMethods.setValue(
      'additionalPrices',
      row.additionalPrices.map(x => ({ fromDay: x.fromDay, rentPerDayKc: x.rentPerDayKc }))
    )
  }, [row])
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
        <div className="row mb-2">
          <div className="col-md-2">
            <FormField type={inputType.text} isPositive name={'name'} label={'Název'} />
          </div>
          <div className="col-md-2">
            <FormField type={inputType.number} isPositive name={'rentFreeForDays'} label={'Dní pronájmu zdarma'} valueAsNumber />
          </div>
          <div className="col-md-2">
            <FormField type={inputType.number} isPositive isMoney name={'baseRentPerDayKc'} label={'Základní cena za den'} valueAsNumber />
          </div>
          <div className="col-md-2">
            <FormField type={inputType.number} isPositive name={'maximumRentDays'} label={'Maximální délka pronájmu'} valueAsNumber />
          </div>
          <div className="col-md-2">
            <FormField type={inputType.number} isMoney name={'monthlyRentPriceKc'} label={'Měsíční pronájem Kč'} valueAsNumber />
          </div>
          <div className="col-md-2">
            <FormField type={inputType.number} isPositive name={'monthlyRentAfterDays'} label={'Měsíční pronájem po tomto dni'} valueAsNumber />
          </div>
        </div>
        <h3>Rozšířený ceník</h3>
        <div className="row mb-2">
          <div className="col-md-2">
            <FormField type={inputType.number} isPositive name={'additionalPrices[0].fromDay'} label={'Ode dne'} valueAsNumber />
          </div>
          <div className="col-md-2">
            <FormField type={inputType.number} isPositive name={'additionalPrices[0].rentPerDayKc'} label={'Cena'} valueAsNumber isMoney />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-md-2">
            <FormField type={inputType.number} isPositive name={'additionalPrices[1].fromDay'} label={'Ode dne'} valueAsNumber />
          </div>
          <div className="col-md-2">
            <FormField type={inputType.number} isPositive name={'additionalPrices[1].rentPerDayKc'} label={'Cena'} valueAsNumber isMoney />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-md-2">
            <FormField type={inputType.number} isPositive name={'additionalPrices[2].fromDay'} label={'Ode dne'} valueAsNumber />
          </div>
          <div className="col-md-2">
            <FormField type={inputType.number} isPositive name={'additionalPrices[2].rentPerDayKc'} label={'Cena'} valueAsNumber isMoney />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-md-2">
            <FormField type={inputType.number} isPositive name={'additionalPrices[3].fromDay'} label={'Ode dne'} valueAsNumber />
          </div>
          <div className="col-md-2">
            <FormField type={inputType.number} isPositive name={'additionalPrices[3].rentPerDayKc'} label={'Cena'} valueAsNumber isMoney />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <button className={'btn btn-primary'} type={'submit'}>
              {row ? 'Upravit sazbu' : 'Přidat sazbu'}
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  )

  function handleFormSubmit(data: SupplierContainerRentRatesEntity) {
    if (!branchId) return
    const transformedData = {
      ...data,
      branchId,
      additionalPrices: data.additionalPrices?.filter(x => x.fromDay && x.rentPerDayKc).map(x => ({ fromDay: x.fromDay, rentPerDayKc: x.rentPerDayKc })) ?? []
    }
    let promise = upsertContainerRentRateMut({ variables: { containerRentRateId: row?.id, data: transformedData } })
    processPageOperation({
      promise,
      successMessage: row ? 'Sazba byla úspěšně upravena' : 'Sazba byla úspěšně přidána',
      successAction: hideModal
    })
  }
}
