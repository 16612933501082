import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'
import { contextEndpoint, EndpointEnum } from '../../../services/appConfiguration/contextEndpoint'

export const setupPasswordMutation = gql`
  mutation setupPassword($password: String!, $token: String!) {
    setupPassword(newPassword: $password, token: $token) {
      status {
        reason
        result
      }
    }
  }
`

export function createParamsForSetupPassword(password: string, token: string) {
  return {
    password: password,
    token: token
  }
}

export function useSetupPasswordMutation() {
  return useMutation<{ setupPassword: IMutationResult }>(setupPasswordMutation, { context: contextEndpoint(EndpointEnum.login) })
}
