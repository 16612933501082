import { gql, useQuery } from '@apollo/client'

export const orderContentStatesStatQuery = gql`
  query orderContentStatesStat {
    orderContentStatesStat {
      contentStateSysName
      contentStateName
      count
    }
  }
`

export function useOrderContentStatesStatQuery() {
  return useQuery<{
    orderContentStatesStat: {
      contentStateSysName: string
      contentStateName: string
      count: number
    }[]
  }>(orderContentStatesStatQuery, { fetchPolicy: 'network-only' })
}
