import { gql, useMutation } from '@apollo/client'
import { IMutationResult} from "../../../../../services/types/gqlTypes";

const deleteB2bPriceListMutation = gql`
    mutation deleteB2bPriceList($id: Int!) {
        deleteB2bPriceList(id: $id) {
            status {
                reason
                result
            }
        }
    }
`

export function useDeleteB2bPriceListMutation() {
    return useMutation<{ deleteB2bPriceList: IMutationResult }>(deleteB2bPriceListMutation)
}
