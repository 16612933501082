import { gql, useMutation } from '@apollo/client'
import { BaseMutationResult } from '../../../../graphql/aminTypes'

const updateGroupInvoiceMutation = gql`
  mutation updateGroupInvoice($id: ID!, $updates: String!) {
    updateGroupInvoice(id: $id, updates: $updates) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpdateGroupInvoiceMutation() {
  return useMutation<{ updateGroupInvoice: BaseMutationResult }>(updateGroupInvoiceMutation, {
    refetchQueries: ['accountingGroupInvoices']
  })
}
