import { gql, useQuery } from '@apollo/client'
import { ListIczEntity, Maybe } from '../../../../../graphql/aminTypes'

export const findIczQuery = gql`
  query findIcz($companyId: String!, $city: String) {
    findIcz(companyId: $companyId, city: $city) {
      id
      iczNumber
      address
      name
      type
    }
  }
`

export function useFindIczQuery(companyId?: Maybe<string> | undefined, city?: string) {
  return useQuery<{ findIcz: ListIczEntity[] }>(findIczQuery, {
    fetchPolicy: 'network-only',
    variables: { companyId, city },
    skip: !companyId
  })
}
