import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const deleteSupplierBranchMutation = gql`
  mutation deleteSupplierBranch($branchId: ID!) {
    deleteSupplierBranch(branchId: $branchId) {
      status {
        reason
        result
      }
    }
  }
`

export function useDeleteSupplierBranchMutation() {
  return useMutation<{ deleteSupplierBranch: IMutationResult }>(deleteSupplierBranchMutation, { refetchQueries: ['supplierBranchList'] })
}
