import React, { useReducer } from 'react'
import { SupplierBranchEntity, SupplierSupplierEntity } from '../../graphql/aminTypes'

export const SelectedSupplierModalContext = React.createContext<{ state: SelectedSupplierModalContextType; dispatch: any }>({
  state: { selectedSupplier: undefined, selectedBranch: undefined, reloadBranchData: undefined },
  dispatch: undefined
})

export type SelectedSupplierModalContextType = {
  selectedSupplier?: SupplierSupplierEntity
  selectedBranch?: SupplierBranchEntity
  reloadBranchData?: () => Promise<void>
}

export type Action =
  | { type: 'SET_SELECTED_SUPPLIER'; payload: SupplierSupplierEntity }
  | {
      type: 'SET_SELECTED_BRANCH'
      payload: any
    }
  | { type: 'CLEAR_ALL' }
  | { type: 'SET_RELOAD_FUNCTION'; payload: () => Promise<void> }

export function SelectedSupplierModalProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(
    (state: SelectedSupplierModalContextType, action: Action) => {
      switch (action.type) {
        case 'SET_SELECTED_SUPPLIER':
          return { selectedSupplier: action.payload, selectedBranch: undefined }
        case 'SET_SELECTED_BRANCH':
          return { ...state, selectedBranch: action.payload }
        case 'SET_RELOAD_FUNCTION':
          return { ...state, reloadBranchData: action.payload }
        case 'CLEAR_ALL':
          return { selectedSupplier: undefined, selectedBranch: undefined }
        default:
          return state
      }
    },
    { selectedSupplier: undefined, selectedBranch: undefined, reloadBranchData: undefined }
  )
  return <SelectedSupplierModalContext.Provider value={{ state, dispatch }}>{children}</SelectedSupplierModalContext.Provider>
}
