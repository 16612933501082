import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../services/types/gqlTypes'

const deleteAdvanceAccountMutation = gql`
  mutation deleteAdvanceAccount($advanceAccountId: ID!) {
    deleteAdvanceAccount(advanceAccountId: $advanceAccountId) {
      status {
        reason
        result
      }
    }
  }
`

export function useDeleteAdvanceAccountMutation() {
  return useMutation<{ deleteAdvanceAccount: IMutationResult }>(deleteAdvanceAccountMutation, {
    awaitRefetchQueries: true,
    refetchQueries: ['customerAdvanceAccounts']
  })
}
