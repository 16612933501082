import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import SelectField from '../../../../../components/formComponents/SelectField'
import LoadingContainer from '../../../../../components/LoadingContainer'
import { isAnyQueryLoading } from '../../../../../services/functions/queryHelpers'
import { processPageOperation } from '../../../../../services/formsServices/pageOperationProcessor'
import { TransportCategory } from '../../../../../graphql/aminTypes'
import { useCreateSupplierTransportSupplierLinkMutation } from './mutations/create-supplier-transport-supplier-link.mutation'
import { useGetBranchListByTransportCategoryQuery } from './queries/get-branch-list-by-transport-category.query'

export function AddSupplier({
  branchServiceId,
  doNotShowSuppliers,
  transportCategory
}: {
  transportCategory: TransportCategory
  branchServiceId: string
  doNotShowSuppliers: string[]
}) {
  const suppliersQR = useGetBranchListByTransportCategoryQuery(transportCategory)
  const formMethods = useForm({})
  const [createSupplierTransportSupplierLinkMut, createSupplierTransportSupplierLinkQR] = useCreateSupplierTransportSupplierLinkMutation()
  const suppliers =
    suppliersQR.data?.getBranchListByTransportCategory
      .filter(x => !doNotShowSuppliers.includes(x.id))
      .map(x => ({ value: x.id, label: `${x.supplier?.name}${x.specification ? ` - ${x.specification}` : ''} (${x.address?.city})` })) || []
  return (
    <LoadingContainer showLoader={isAnyQueryLoading(suppliersQR, createSupplierTransportSupplierLinkQR)}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
          <div className="row mt-2">
            <div className="col-4">
              <SelectField name={'supplierBranchId'} optionsData={suppliers} label={'Pobočka dodavatele'}></SelectField>
            </div>
            <div className="col-4 pt-3">
              <button type={'submit'} className="btn btn-primary d-inline-block ml-3">
                Přidat dodavatele
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </LoadingContainer>
  )

  function handleFormSubmit(formData: any) {
    const promise = createSupplierTransportSupplierLinkMut({
      variables: {
        branchServiceId,
        supplierBranchId: formData.supplierBranchId,
        transportCategory: transportCategory
      }
    })
    processPageOperation({
      promise,
      successMessage: 'Dodavatel byl přidán'
    })
  }
}
