export const mrtNumberColumn = {
  muiEditTextFieldProps: {
    type: 'number',
    slotProps: {
      htmlInput: {
        step: '1',
        min: '0'
      }
    }
  }
}
