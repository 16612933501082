import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const updateSupplierDisabledMutation = gql`
  mutation updateSupplierDisabled($supplierId: Int!, $disabled: Boolean!) {
    updateSupplierDisabled(supplierId: $supplierId, disabled: $disabled) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpdateSupplierDisabledMutation() {
  return useMutation<{ updateSupplierDisabled: IMutationResult }>(updateSupplierDisabledMutation)
}
