import { IReactSelectItem } from '../../../../services/types/ReactSelect'

export const truckTypeList = ['CONTAINER', 'DUMP_TRUCK', 'SUCTION_DREDGER', 'BOX_TRUCK', 'AUTO_MIXER', 'TOW_TRUCK']

export const truckTypeTranslation = {
  CONTAINER: 'Kontejner',
  DUMP_TRUCK: 'Sklápěčka',
  SUCTION_DREDGER: 'Sací bagr',
  BOX_TRUCK: 'Skříň',
  AUTO_MIXER: 'Autodomíchávač',
  TOW_TRUCK: 'Odtahovka'
}

export const truckTypeOptions: IReactSelectItem[] = truckTypeList.map(truckType => ({ value: truckType, label: truckTypeTranslation[truckType] }))
