import React from 'react'
import { formatAddressToDisplay } from '../../../services/dataToString/formatAddressToDisplay'
import { decimalToLocaleString, moneyToLocaleString } from '../../../services/dataToString/decimalToLocaleString'
import { formatPhoneToDisplay } from '../../../services/dataToString/formatPhoneToDisplay'
import { formatDateTimeForDisplayShort } from '../../../services/dataToString/dateTimeFormatter'
import classNames from 'classnames'
import { orderContentStateBadgeColors } from '../../../services/types/order-content-state-badge-colors'
import { DataListItem } from '../../../components/HtmlDataList'
import { paymentMethodBadgeColors, paymentMethodsMap } from '../../../services/types/payment-method'
import dayjs from 'dayjs'
import { paymentStateBadgeColors, paymentStatusMap, paymentStatusMapInclGP } from '../../../services/types/payment-status'
import { customerName } from '../../../services/functions/customer-name'
import { CheckAdvanceAccountStatusResponseEnum, Maybe, OrderContentPrices, OrderContentsResult, OrderLocksEntity } from '../../../graphql/aminTypes'
import { OperationVariables, QueryResult } from '@apollo/client'
import { advanceAccountStatusMap } from '../../../services/types/advance-account-status'

export function getOrderData(
  oc: OrderContentsResult,
  refreshGPStatus: () => void,
  forceFioTransactionDownload: () => void,
  advanceAccountStatusQR: QueryResult<{ checkAdvanceAccountStatus: CheckAdvanceAccountStatusResponseEnum }, OperationVariables>,
  payOrderFromAdvanceAccountHandler: () => void
): DataListItem[] {
  const result: DataListItem[] = []
  const data = oc.order
  const paymentMethod = data.paymentType ? paymentMethodsMap[data.paymentType] : 'N/A'
  const paymentStatus = data.paymentStatus ? paymentStatusMap[data.paymentStatus] : 'N/A'
  const goPayPaymentStatus = data.goPayPaymentStatus ? paymentStatusMapInclGP[data.goPayPaymentStatus] : 'N/A'
  const orderContentStatus = oc.orderContentState.name
  const productImage = oc.product.uiPicture ?? oc.product.material?.uiPicture ?? ''

  result.push({
    label: 'Produkt',
    iconBeforeLabel: 'fe-shopping-cart',
    text: (
      <>
        {oc.product.name}
        <br />
        {productImage && <img src={`https://${process.env.REACT_APP_FE_HOSTNAME}/assets/images/${productImage}`} width={80} className="mb-3" />}
      </>
    )
  })
  if (oc.serviceType === 'WASTE_COLLECTION' && oc.container)
    result.push({
      label: 'Kontejner',
      iconBeforeLabel: 'fe-truck',
      text: (
        <>
          {`${oc.container.name} ${oc.container.capacityM3}m³`}
          <br />
          {oc.container.uiPicture && (
            <img src={`https://${process.env.REACT_APP_FE_HOSTNAME}/assets/images/containers/${oc.container.uiPicture}.svg`} width={120} className="mb-3" />
          )}
        </>
      )
    })
  result.push({
    label: 'Přistavení',
    iconBeforeLabel: 'fe-arrow-down-right',
    text: `${dayjs(oc.containerFromDate).format('dddd L')}, ${oc.arriveTime}`
  })
  if (oc.serviceType === 'MATERIAL_DELIVERY' && oc.weightTonnes && oc.product.tonToCubes) {
    result.push({
      label: 'Počet m³',
      iconBeforeLabel: 'fe-truck',
      text: (
        <>
          {Math.round((oc.weightTonnes / oc.product.tonToCubes) * 100) / 100} m<sup>3</sup> ({oc.weightTonnes} t)
        </>
      )
    })
  }
  if (oc.serviceType === 'WASTE_COLLECTION') {
    result.push({
      label: 'Odvoz',
      iconBeforeLabel: 'fe-arrow-up-right',
      text: `${oc.containerToDate ? dayjs(oc.containerToDate).format('dddd L') : ''}, ${oc.collectionTime ? oc.collectionTime : ''}`
    })
    result.push({ label: 'Počet kontejnerů', iconBeforeLabel: 'fe-hash', text: oc.containerCount })
    result.push({
      label: 'Složení odpadu a jeho vznik',
      iconBeforeLabel: 'fe-trash',
      text: oc.wasteDescription || <>&nbsp;</>
    })
  }
  result.push({
    label: 'Poznámka od objednatele',
    iconBeforeLabel: 'fe-message-circle',
    text: data.orderNote || <>&nbsp;</>
  })
  result.push({
    label: 'Platební metoda',
    iconBeforeLabel: 'fe-dollar-sign',
    text: <span className={classNames('badge', paymentMethodBadgeColors[paymentMethod])}>{paymentMethod}</span>
  })
  result.push({
    label: 'Stav platby',
    iconBeforeLabel: 'fe-dollar-sign',
    text: (
      <>
        <span className={classNames('badge', paymentStateBadgeColors[paymentStatus])}>{paymentStatus}</span>
        {paymentStatus === 'Čeká na platbu' && data.paymentType !== 'ADVANCE_PAYMENT' && (
          <button type="button" className="btn btn-sm w-5 bg-blue-lt ml-2" onClick={forceFioTransactionDownload}>
            <i className={`pl-2 pt-1 fe fe-refresh-cw`}></i> FIO
          </button>
        )}
      </>
    )
  })
  if (data.paymentType === 'ADVANCE_PAYMENT' && advanceAccountStatusQR.data)
    result.push({
      label: 'Stav zálohového účtu',
      iconBeforeLabel: 'fe-activity',
      text: (
        <>
          <span
            className={classNames(
              'badge',
              advanceAccountStatusQR.data.checkAdvanceAccountStatus === CheckAdvanceAccountStatusResponseEnum.MatchingAdvanceAccountFound
                ? 'bg-success'
                : 'bg-danger'
            )}
          >
            {advanceAccountStatusMap[advanceAccountStatusQR.data?.checkAdvanceAccountStatus]}
          </span>
          {advanceAccountStatusQR.data.checkAdvanceAccountStatus === CheckAdvanceAccountStatusResponseEnum.MatchingAdvanceAccountFound &&
            data.paymentStatus === 'UNPAID' && (
              <button type="button" className="btn btn-sm w-6 bg-blue-lt ml-2" onClick={payOrderFromAdvanceAccountHandler}>
                <i className={`pl-2 pt-1 fe fe-refresh-cw`}></i>Zaplatit z účtu
              </button>
            )}
        </>
      )
    })

  result.push({
    label: 'Stav platby Go Pay',
    iconBeforeLabel: 'fe-dollar-sign',
    text: (
      <>
        <span className={classNames('badge', paymentStateBadgeColors[goPayPaymentStatus])}>{goPayPaymentStatus}</span>
        {data.goPayTransactionId && data.goPayPaymentStatus !== 'PAID' && (
          <button type="button" className="btn btn-sm w-4 bg-blue-lt ml-2" onClick={refreshGPStatus}>
            <i className={`pl-2 pt-1 fe fe-refresh-cw`}></i>
          </button>
        )}
      </>
    )
  })
  result.push({
    label: 'Stav objednávky',
    iconBeforeLabel: 'fe-git-pull-request',
    text: <span className={classNames('badge', orderContentStateBadgeColors[orderContentStatus])}>{orderContentStatus}</span>
  })
  if (oc.orderContentStateReason)
    result.push({
      label: 'Důvod zrušení',
      text: oc.orderContentStateReason
    })

  return result
}

export function getTechnicalData(orderContent: OrderContentsResult): DataListItem[] {
  const orderData = orderContent.order
  const result: DataListItem[] = []
  result.push({ label: 'order_id', text: orderData.id })
  result.push({ label: 'order_content_id', text: orderContent.id })
  result.push({
    label: 'uuid',
    text: (
      <>
        <a href={`https://${process.env.REACT_APP_FE_HOSTNAME}/objednavka?uuid=${orderData.uuid}`} target="_blank" rel="noopener noreferrer">
          {orderData.uuid} <i className="fe fe-external-link" />
        </a>
      </>
    )
  })
  result.push({
    label: 'GoPay trans. ID',
    text: !orderData.goPayTransactionId ? (
      <>&nbsp;</>
    ) : (
      <>
        {orderData.goPayTransactionId}
        &nbsp;
        <a href="https://account.gopay.com/gopay-monitor/merchant-accounts-overview" target="_blank" rel="noopener noreferrer">
          <i className="fe fe-external-link" />
        </a>
      </>
    )
  })
  result.push({
    label: 'Vytvořil',
    text: `${orderData.createdBy ? `${orderData.createdBy.firstname} ${orderData.createdBy.lastname}, ` : ''} ${formatDateTimeForDisplayShort(
      orderData.createdAt
    )}`
  })

  if (orderData.updatedAt) {
    result.push({
      label: 'Upravil',
      text: `${orderData.updatedBy ? `${orderData.updatedBy.firstname} ${orderData.updatedBy.lastname}, ` : ''} ${formatDateTimeForDisplayShort(
        orderData.updatedAt
      )}`
    })
  }
  result.push({
    label: 'Vlastník',
    text: orderData.adminOwner ? `${orderData.adminOwner.firstname} ${orderData.adminOwner.lastname}` : 'N/A'
  })
  return result
}

export function getCustomerData(orderContent: OrderContentsResult): DataListItem[] {
  const order = orderContent.order
  const result: DataListItem[] = []
  const isResellCompany = order.customer?.isResellCompany || false
  if (order.b2bCustomerId) {
    result.push({
      label: 'Na registrovaného zákazníka',
      iconBeforeLabel: 'fe-briefcase',
      text:
        order.b2bCustomerId && order.customer ? (
          <a href={'/customer-list?id=' + order.b2bCustomerId} target="_blank" rel="noopener noreferrer">
            {order.customer.companyName || order.customer.lastname} <i className="fe fe-external-link" />
          </a>
        ) : (
          <>&nbsp;</>
        )
    })
    result.push({
      label: 'Přeprodej služeb (DEK)',
      iconBeforeLabel: 'fe-repeat',
      text: order.customer ? order.customer.isResellCompany ? 'Ano' : 'Ne' : <>&nbsp;</>
    })
  }

  result.push({
    label: 'Objednatel',
    iconBeforeLabel: 'fe-user',
    text: customerName(order)
  })
  result.push({
    label: 'Objednatel firemní info',
    iconBeforeLabel: 'fe-briefcase',
    text: (
      <>
        {'Stát: ' + (order.companyCountryIso || 'CZ')} <br />
        {'IČO: ' + (order.ico || '')} <br />
        {order.companyCountryIso === 'SK' ? 'IČ DPH: ' + (order.dic || '') : 'DIČ: ' + (order.dic || '')}
      </>
    )
  })
  result.push({
    label: 'Objednatel sídlo',
    iconBeforeLabel: 'fe-map-pin',
    text: order.companyResidence ? formatAddressToDisplay(order.companyResidence) : <>&nbsp;</>
  })
  result.push({
    label: isResellCompany ? 'Telefon koncový zákazník nebo objednatel' : 'Objednatel telefon',
    iconBeforeLabel: 'fe-phone',
    text: order.phone ? formatPhoneToDisplay(order.phone) : <>&nbsp;</>
  })
  result.push({
    label: isResellCompany ? 'Email koncový zákazník nebo objednatel' : 'Objednatel email',
    iconBeforeLabel: 'fe-mail',
    text: (
      <a href={'mailto:' + order.email}>
        {order.email} <i className="fe fe-external-link" />
      </a>
    )
  })
  if (isResellCompany && order.targetCompanyIco && order.targetCompanyName) {
    result.push({
      label: 'Koncový zákazník',
      iconBeforeLabel: 'fe-target',
      text: `${order.targetCompanyName} - IČO: ${order.targetCompanyIco}`
    })
  }

  result.push({
    label: 'Kontakt na místě',
    iconBeforeLabel: 'fe-move',
    text: (
      <>
        {`${orderContent.onsiteContactFirstname || ''} ${orderContent.onsiteContactLastname || ''}`.trim()} <br />
        {`${formatPhoneToDisplay(orderContent.onsiteContactPhone || '')}`}
      </>
    )
  })

  return result
}

export function getContentData(orderContent: OrderContentsResult): DataListItem[] {
  const result: DataListItem[] = []
  const order = orderContent.order
  result.push({
    label: 'Dodavatel',
    text: (
      <>
        {orderContent.supplier?.name}
        <br />
        <a href={'/supplier-list?id=' + orderContent.supplier?.id} target="_blank" rel="noopener noreferrer" className="pl-4">
          Zobrazit ceník <i className="fe fe-external-link" />
        </a>
      </>
    )
  })

  if (orderContent.terminal) result.push({ label: 'Koncovka', text: orderContent.terminal.name })

  result.push({
    label: 'Celková vzdálenost',
    text: orderContent.distanceTotalKm ? decimalToLocaleString(Math.ceil(orderContent.distanceTotalKm)) + ' km' : ''
  })
  result.push({ label: 'Celkem bez DPH', text: moneyToLocaleString(order.totalWoVatKc, 'CZK') })
  result.push({ label: 'Celkem s DPH', text: <strong>{moneyToLocaleString(order.totalInclVatKc, 'CZK')} </strong> })
  result.push({ label: 'Vybrat dodavatelem dobírku', text: orderContent.codToCollect ? moneyToLocaleString(orderContent.codToCollect, 'CZK') : <>&nbsp;</> })
  result.push({
    label: 'Výdělek pro dodavatele',
    text: <i>{moneyToLocaleString(orderContent.supplierProfitWoVatKc, 'CZK')}</i>
  })
  result.push({
    label: 'Výdělek pro dodavatele z prvního výpočtu (co zaplatil zákazník)',
    text: <i>{moneyToLocaleString(orderContent.supplierProfitOriginalWoVatKc, 'CZK')}</i>
  })
  result.push({
    label: 'Refundováno',
    text: order.refundedKc ? <strong>{moneyToLocaleString(order.refundedKc, 'CZK')} </strong> : <>&nbsp;</>
  })
  result.push({
    label: 'Poznámka pro administrátora',
    iconBeforeLabel: 'fe-type',
    text: orderContent.adminNote || <>&nbsp;</>
  })
  result.push({
    label: 'Poznámka pro dodavatele',
    iconBeforeLabel: 'fe-message-square',
    text: orderContent.supplierNote || <>&nbsp;</>
  })
  result.push({
    label: 'Poznámka pro zákazníka',
    iconBeforeLabel: 'fe-book-open',
    text: orderContent.customerNote || <>&nbsp;</>
  })
  result.push({
    label: 'Poznámka pro fakturaci',
    iconBeforeLabel: 'fe-bookmark',
    text: order.invoicingNote || <>&nbsp;</>
  })
  result.push({
    label: 'Poznámka pro fakturaci zapracována',
    iconBeforeLabel: 'fe-bookmark',
    text: order.invoicingNoteDone ? 'Ano' : 'Ne'
  })
  if (orderContent.serviceType === 'WASTE_COLLECTION' && order.requireCertificateState > 0) {
    result.push({
      label: 'Zákazník požaduje vystavení certifikátu',
      iconBeforeLabel: order.requireCertificateState === 1 ? 'icon-file-certificate--red' : 'icon-file-certificate--green',
      text:
        order.requireCertificateState === 1 ? (
          <div className="alert alert-danger" role="alert">
            Čeká na vystavení certifikátu
          </div>
        ) : (
          'Certifikát vystaven'
        )
    })
  }
  return result
}

export function getPriceSeparation(orderPriceData?: Maybe<OrderContentPrices[]>) {
  if (!orderPriceData || orderPriceData.length <= 0) return []
  const result: DataListItem[] = []
  for (const x of orderPriceData) {
    result.push({
      label: x.orderContentPriceItem?.name,
      text: moneyToLocaleString(x.priceWoVatKc, 'CZK'),
      fontWeightLight: !x.orderContentPriceItem.partOfTotalPrice
    })
  }
  return result
}
