import {gql, useMutation} from '@apollo/client'
import {IMutationResult} from '../../../services/types/gqlTypes'

const sendPwdSetupEmailForB2bCustomerMutation = gql`
    mutation sendPwdSetupEmailForB2bCustomer($loginId: Int!) {
        sendPwdSetupEmailForB2bCustomer(loginId: $loginId) {
            status {
                reason
                result
            }
        }
    }
`

export function useSendPwdSetupEmailForB2bCustomerMutation() {
    return useMutation<{ sendPwdSetupEmailForB2bCustomer: IMutationResult }>(sendPwdSetupEmailForB2bCustomerMutation)
}
