import { EmailHistory } from '../../../../../graphql/aminTypes'
import {
  ColumnAlign,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../../../services/bootstrapTable/services/tableSpecificationTypes'
import dayjs from 'dayjs'

export const emailHistoryDataDefinition: TableDefinitionTypes<EmailHistory> = {
  columnDefinition: {
    id: { hidden: true },
    createdAt: { name: 'Odeslán' },
    recipients: { name: 'Komu' },
    subject: { name: 'Předmět' }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    createdAt: x => dayjs(x.createdAt).format('LLL'),
    recipients: x => x.recipientsTo.join(', '),
    subject: x => x.subject
  }
}
