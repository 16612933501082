import {
  ColumnAlign,
  ColumnFilterTypes,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes,
  WayOfIdHandover
} from '../../../services/bootstrapTable/services/tableSpecificationTypes'
import { httpBuildQueryString } from '../../../services/functions/httpBuildQuery'
import { TerminalItemType } from './terminalsQuery'
import { formatPhoneToDisplay } from '../../../services/dataToString/formatPhoneToDisplay'

export const terminalListDataDefinition: TableDefinitionTypes<TerminalItemType> = {
  columnDefinition: {
    id: { hidden: true },
    disabled: {
      name: 'Aktivní',
      columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
      headerStyle: { width: '50px' },
      align: ColumnAlign.center,
      remoteSort: true,
      filterType: ColumnFilterTypes.customSelect,


    },
    name: { name: 'Jméno', filterType: ColumnFilterTypes.customText, remoteSort: true},
    address: { name: 'Adresa', headerStyle: { minWidth: '150px' } },
    ico: { name: 'IČO' },
    contactPerson: { name: 'Kontaktní osoba' },
    email: { name: 'E-mail' },
    phone: { name: 'Telefon' },
    gps: { name: 'gps', hidden: true },
    actions: {
      name: 'Akce',
      columnFormatterType: ColumnFormatterTypes.actionsObject,
      align: ColumnAlign.right,
      headerStyle: { width: '100px' }
    }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    name: x => x.name,
    disabled: x => !x.disabled,
    address: x => (
        <a
            href={`http://www.google.com/maps/place/${x.gps.coordinates[1]},${x.gps.coordinates[0]}`}
            target="_blank"
            rel="noopener noreferrer"
        >{`${x.city}, ${x.street} ${x.streetNumber}`}</a>
    ),    ico: x => x.companyIdentification,
    contactPerson: x => x.contactPerson,
    email: x => x.email,
    phone: x => <a href={'tel:' + x.phone}> {formatPhoneToDisplay(x.phone)} </a>,
    gps: x => x.gps.coordinates,
    actions: x => {
      const ret: IRowAction[] = []
      if (x.disabled)
        ret.push({
          type: RowActionType.codeAction,
          value: 'enableTerminal',
          icon: 'fe-arrow-up',
          title: 'Povolit koncovku'
        })
      else
        ret.push({
          type: RowActionType.codeAction,
          value: 'disableTerminal',
          icon: 'fe-arrow-down',
          title: 'Nepoužívat koncovku'
        })

      ret.push({
        type: RowActionType.routerLink,
        value: 'edit-terminal',
        wayOfIdHandover: WayOfIdHandover.search,
        searchStringBuilder: row => httpBuildQueryString({ terminalId: row.id }),
        icon: 'fe-edit',
        title: 'Upravit'
      })
      ret.push({
        type: RowActionType.codeAction,
        value: 'deleteTerminal',
        icon: 'fe-x',
        title: 'Odstranit terminal'
      })
      return ret
    }
  }
}
