import { RegisterOptions } from 'react-hook-form'
import { CustomRegisterOptions } from '../../components/formComponents/FormField'

export function createErrorMessages(registerOptions?: CustomRegisterOptions): RegisterOptions {
  if (!registerOptions) return {}
  let registerOptionsWithErrorMsg: CustomRegisterOptions = { ...registerOptions }
  if (registerOptions.required) registerOptionsWithErrorMsg.required = 'Vyplňte prosím toto pole'
  if (registerOptions.min)
    registerOptionsWithErrorMsg.min = {
      value: registerOptions.min as number,
      message: 'Minimální hodnota pole je ' + registerOptions.min
    }
  if (registerOptions.max)
    registerOptionsWithErrorMsg.max = {
      value: registerOptions.max as number,
      message: 'Maximální hodnota pole je ' + registerOptions.max
    }
  if (registerOptions.minLength)
    registerOptionsWithErrorMsg.minLength = {
      value: registerOptions.minLength as number,
      message: 'Minimální počet znaků pole je ' + registerOptions.minLength
    }
  if (registerOptions.maxLength)
    registerOptionsWithErrorMsg.maxLength = {
      value: registerOptions.maxLength as number,
      message: 'Maximální počet znaků pole je ' + registerOptions.maxLength
    }

  return registerOptionsWithErrorMsg as RegisterOptions
}
