import { gql, useQuery } from '@apollo/client'
import { contextEndpoint, EndpointEnum } from '../../../../../services/appConfiguration/contextEndpoint'
import { SupplierList } from '../../../../../graphql/aminTypes'

export const suppliersQuery = gql`
  query suppliers2($id: Int) {
    suppliers2(id: $id) {
      items {
        id
        name
        orpName
        zpoNote
        note
        containerRentPricePerDayKc
      }
    }
  }
`

export function useSupplierOrderInfo(id?: number) {
  return useQuery<{ suppliers2: SupplierList }>(suppliersQuery, {
    fetchPolicy: 'network-only',
    variables: { id },
    skip: !id,
    context: contextEndpoint(EndpointEnum.admin)
  })
}
