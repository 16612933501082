import React from 'react'
import { IReactSelectItem } from '../../services/types/ReactSelect'
import { noFiltrationId } from '../../services/bootstrapTable/columnFilterFactory'
import Select from 'react-select'
import classNames from 'classnames'

interface ICustomSelectFilterParams {
  onFilter: (value: string) => {}
  defaultValue?: any
  selectOptions: IReactSelectItem[]
}

export default function CustomSelectFilter({ onFilter, defaultValue, selectOptions }: ICustomSelectFilterParams) {
  const selectedValue =
    selectOptions && selectOptions.length > 0
      ? defaultValue
        ? selectOptions.find(x => x.value === defaultValue)
        : selectOptions.find(x => x.value === noFiltrationId)
      : undefined

  return (
    <Select
      options={selectOptions}
      noOptionsMessage={() => 'Žádné možnosti'}
      value={selectedValue}
      onChange={(v: any, a: any) => {
        if (a.action === 'clear') onFilter(noFiltrationId)
        else onFilter(v.value)
      }}
      isClearable={selectedValue?.value !== noFiltrationId}
      menuPlacement="auto"
      menuPosition="fixed"
      classNamePrefix="reactSelect"
      className={classNames('customSelectFilter', selectedValue?.value === noFiltrationId ? '' : 'isSelected')}
    />
  )
}
