import { PaymentMethod } from '../types/payment-method'
import { GoPayPaymentState, PaymentStatus, paymentStatusMapInclGP } from '../types/payment-status'

export function decideProperPaymentStatus(
  paymentType: PaymentMethod,
  gpStatus: GoPayPaymentState,
  orderPaymentStatus: PaymentStatus
): {
  showWarning: boolean
  translatedPaymentStatus: string
} {
  let showPaymentStatus = orderPaymentStatus //paymentType === 'PAYMENT_GW' && gpStatus ? gpStatus : orderPaymentStatus
  let showWarning = false
  if (
    paymentType === 'PAYMENT_GW' &&
    ((orderPaymentStatus === 'PAID' && gpStatus !== 'PAID') ||
      (orderPaymentStatus === 'REFUNDED' && gpStatus !== 'REFUNDED') ||
      (orderPaymentStatus === 'REFUNDED' && gpStatus !== 'PARTIALLY_REFUNDED') ||
      (orderPaymentStatus === 'UNPAID' && gpStatus === 'PAID'))
  ) {
    showPaymentStatus = orderPaymentStatus
    showWarning = true
  }
  const translatedPaymentStatus = paymentStatusMapInclGP[showPaymentStatus]
  return { showWarning, translatedPaymentStatus }
}
