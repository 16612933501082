import { ZpoDetail } from '../../../../../graphql/aminTypes'
import { formatDateTimeForDisplayLong } from '../../../../../services/dataToString/dateTimeFormatter'

export function zpoDetailContent(data?: ZpoDetail, zpoNote?: string) {
  return [
    { label: 'Poznámka pro tohoto dopravce', text: zpoNote ?? '' },
    { label: 'Typ odpadu', text: data?.wasteType ? `${data.wasteType.code} - ${data.wasteType.name}` : '' },
    { label: 'ZUJ', text: data?.zuj?.name ?? '' },
    { label: 'ORP', text: data?.orp?.name ?? '' },
    { label: 'Popis vzniku odpadu', text: data?.wasteOriginDesc ?? '' },
    { label: 'ZPO poslat na emaily', text: data?.notificationEmails ?? '' },
    { label: 'ZPO odesláno emailem', text: data?.supplierNotifiedTimestamp ? formatDateTimeForDisplayLong(data?.supplierNotifiedTimestamp) : '' }
  ]
}
