import { FormProvider, useForm } from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import AddressPicker from '../../components/formComponents/AddressPicker/AddressPicker'
import PageCard from '../../components/Cards/pageCard'
import { getServiceType, ServiceCategoryEnum } from '../../services/types/service-category.type'
import DistanceMap from './components/DistanceMap'
import { useGetAvailableServicesForLocalityQuery } from './queries/get-available-services-for-locality.query'
import { isAnyQueryLoading } from '../../services/functions/queryHelpers'
import dayjs from 'dayjs'
import DatePickerComp from '../../components/formComponents/datePickerComp'
import FormField, { inputType } from '../../components/formComponents/FormField'
import ServicesSelector from './components/ServicesSelector'
import WasteCollectionSolid from './service-category/waste-collection-solid/WasteCollectionSolid'
import {
  createCalculateCollectionServiceOptionsVariables,
  useCalculateCollectionServiceOptionsQuery
} from './queries/calculate-collection-service-options.query'
import { PriceCalculatorResponse, TransportCategory } from '../../graphql/aminTypes'
import CalculationResultVisualizer from './components/calculation-result-visualizer/CalculationResultVisualizer'
import { formatAddressToDisplay } from '../../services/dataToString/formatAddressToDisplay'
import classNames from 'classnames'
import DeliveryOfMaterial from './service-category/delivery-of-material/DeliveryOfMaterial'
import { createCalculateDeliveryServiceOptionsVariables, useCalculateDeliveryServiceOptionsQuery } from './queries/calculate-delivery-service-options.query'

export default function Calculator() {
  const formMethods = useForm({
    defaultValues: { radiusKm: 20, calculationDate: dayjs().format('YYYY-MM-DD'), customerAddress: undefined, serviceCategory: undefined }
  })
  const address = formMethods.watch('customerAddress')

  const [calculationsResults, setCalculationsResults] = useState<PriceCalculatorResponse[] | undefined>(undefined)
  useEffect(() => {
    setCalculationsResults(undefined)
  }, [address])

  const [selectedResultIndex, setSelectedResultIndex] = useState<{ resultIndex: number; optionIndex: number } | undefined>(undefined)
  const [refetchIsRunnning, setRefetchIsRunning] = useState(false)
  const availableServicesQR = useGetAvailableServicesForLocalityQuery({
    customerLatLng: address ? { lat: parseFloat(address['lat']), lng: parseFloat(address['lng']) } : undefined,
    radiusKm: formMethods.watch('radiusKm'),
    calculationDate: dayjs(formMethods.watch('calculationDate')).format('YYYY-MM-DD')
  })
  const calculateCollectionServiceOptionsQR = useCalculateCollectionServiceOptionsQuery()
  const useCalculateDeliveryServiceOptionsQR = useCalculateDeliveryServiceOptionsQuery()

  const serviceCategory = formMethods.watch('serviceCategory')
  const serviceType = getServiceType(serviceCategory)
  return (
    <PageCard
      headerTitle="Výpočet cen za služby"
      showLoader={isAnyQueryLoading(calculateCollectionServiceOptionsQR, availableServicesQR, useCalculateDeliveryServiceOptionsQR) || refetchIsRunnning}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
          <div className="row mb-2">
            <div className="col-md-4">
              <FormField type={inputType.number} name={'radiusKm'} label={'Radius vzdušnou čarou'} valueAsNumber />
            </div>
            <div className="col-md-4">
              <DatePickerComp name={'calculationDate'} label={'Datum výpočtu (ovlivní platnost ceníků služeb)'} />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-8">
              {!address && (
                <AddressPicker name={'customerAddress'} required registerOptions={{ required: 'Poloha nebyla nalezena.' }} className="bg-orange-lt" />
              )}
              {address && (
                <fieldset className={classNames('form-fieldset', 'bg-orange-lt')}>
                  Máte vybranou adresu <strong>{formatAddressToDisplay(address)}</strong>{' '}
                  <button type="button" className={'btn-link'} onClick={() => formMethods.setValue('customerAddress', undefined)}>
                    Změnit
                  </button>
                </fieldset>
              )}
              <ServicesSelector servicesList={availableServicesQR.data?.getAvailableServicesForLocality?.availableServices} />

              {serviceCategory === ServiceCategoryEnum.WASTE_COLLECTION_SOLID && <WasteCollectionSolid />}
              {serviceCategory === ServiceCategoryEnum.TRANSPORT_OF_MATERIAL && <DeliveryOfMaterial />}
              {calculationsResults && serviceCategory && address && (
                <CalculationResultVisualizer calculationResults={calculationsResults} setSelectedResultIndex={setSelectedResultIndex} />
              )}
            </div>
            <div className="col-md-4 border-1 border-danger ">
              {address && (
                <DistanceMap
                  encodedGeometry={
                    selectedResultIndex && calculationsResults && calculationsResults[selectedResultIndex.resultIndex]
                      ? calculationsResults?.[selectedResultIndex.resultIndex].calculationOptions[selectedResultIndex.optionIndex].encodedGeometry
                      : undefined
                  }
                  customerPosition={address ? { lat: parseFloat(address['lat']), lng: parseFloat(address['lng']) } : undefined}
                  supplierPosition={
                    selectedResultIndex && calculationsResults && calculationsResults[selectedResultIndex.resultIndex]
                      ? calculationsResults[selectedResultIndex.resultIndex].supplierBranch.gps
                      : undefined
                  }
                  terminalPosition={
                    selectedResultIndex && calculationsResults && calculationsResults[selectedResultIndex.resultIndex]
                      ? calculationsResults?.[selectedResultIndex.resultIndex].calculationOptions[selectedResultIndex.optionIndex].thirdPartySupplierBranch?.gps
                      : undefined
                  }
                  availableBranches={availableServicesQR.data?.getAvailableServicesForLocality?.supplierBranches}
                />
              )}
            </div>
          </div>
        </form>
      </FormProvider>
    </PageCard>
  )

  function handleFormSubmit(data: any) {
    let params, query
    if (serviceCategory === ServiceCategoryEnum.WASTE_COLLECTION_SOLID) {
      params = createCalculateCollectionServiceOptionsVariables(data, 'SOLID_WASTE')
      query = calculateCollectionServiceOptionsQR
    }
    if (serviceCategory === ServiceCategoryEnum.TRANSPORT_OF_MATERIAL) {
      params = createCalculateDeliveryServiceOptionsVariables(data, TransportCategory.Material)
      query = useCalculateDeliveryServiceOptionsQR
    }

    setRefetchIsRunning(true)
    query
      .refetch(params)
      .then(result => {
        if (result.data) {
          setCalculationsResults(result.data[Object.keys(result.data)[0]])
          if (result.data[Object.keys(result.data)[0]].length > 0) {
            setSelectedResultIndex({ resultIndex: 0, optionIndex: 0 })
          }
        }
        setRefetchIsRunning(false)
      })
      .catch(() => setRefetchIsRunning(false))
  }
}
