import UserAvatar from 'react-user-avatar'
import { avatarColor } from '../../../components/appComponents/avatar-color'
import React from 'react'
import { OrderCommentsEntity } from '../../../graphql/aminTypes'
import dayjs from 'dayjs'

export function OrderCommentItem({ item }: { item: OrderCommentsEntity }) {
  const avatarName = `${item.login.firstname} ${item.login.lastname}`
  return (
    <div>
      <div className="row">
        <div className="col-auto">
          <span className="avatar">
            <UserAvatar size={32} name={avatarName} colors={avatarColor(avatarName)} />
          </span>
        </div>
        <div className="col">
          <div className="">{item.comment}</div>
          <div className="text-muted">
            <strong>{avatarName}</strong> {dayjs(item.createdAt).format('DD.MM.YYYY HH:mm')}
          </div>
        </div>
      </div>
    </div>
  )
}
