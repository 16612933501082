import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'
import { contextEndpoint, EndpointEnum } from '../../../../../services/appConfiguration/contextEndpoint'
import { getZpoDetailQuery } from '../query/getZpoDetailQuery'
import { listOrderContentFilesQuery } from '../../../queries/listfOfFilesQuery'

const generateZpoMutation = gql`
  mutation generateZpo($orderContentId: Int!) {
    generateZpo(orderContentId: $orderContentId) {
      status {
        reason
        result
      }
    }
  }
`

export function useGenerateZpo(orderContentId: number) {
  return useMutation<{ generateZpo: IMutationResult }>(generateZpoMutation, {
    context: contextEndpoint(EndpointEnum.admin),
    refetchQueries: [
      {
        query: getZpoDetailQuery,
        variables: { orderContentId: orderContentId },
        context: contextEndpoint(EndpointEnum.admin)
      },
      {
        query: listOrderContentFilesQuery,
        variables: { orderContentId: orderContentId },
        context: contextEndpoint(EndpointEnum.admin)
      }
    ]
  })
}
