import { gql, useQuery } from '@apollo/client'
import { ProductContainerEntity, SupplierWasteCollectionFlatResponse } from '../../../graphql/aminTypes'

export const supplierWasteCollectionFlatRatesQuery = gql`
  query supplierWasteCollectionFlatRates($branchServiceId: ID!) {
    supplierWasteCollectionFlatRates(branchServiceId: $branchServiceId) {
      id
      isActive
      productContainers {
        id
        name
        volumeM3
      }
      pricesKc
      pricesForPublicKc
      productWaste {
        id
        name
      }
    }
  }
`

export function useSupplierWasteCollectionFlatRates(branchServiceId?: string) {
  return useQuery<{ supplierWasteCollectionFlatRates: SupplierWasteCollectionFlatResponse[] }>(supplierWasteCollectionFlatRatesQuery, {
    fetchPolicy: 'network-only',
    variables: { branchServiceId },
    skip: !branchServiceId
  })
}
