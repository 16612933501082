import { gql, useQuery } from '@apollo/client'
import { contextEndpoint, EndpointEnum } from '../../../../../services/appConfiguration/contextEndpoint'
import { ListOrp } from '../../../../../graphql/aminTypes'

export const listOrpQuery = gql`
  query listOrp {
    listOrp {
      id
      name
      code
    }
  }
`

export function useListOrpQuery() {
  return useQuery<{ listOrp: ListOrp[] }>(listOrpQuery, {
    context: contextEndpoint(EndpointEnum.admin)
  })
}
