import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import { pageListRenderer, paginationTotalRenderer } from '../../components/bootstrapTable/bootstrapTableRenderers'
import { DEFAULT_PAGE_SIZE, IQueryParamsInclPage, pageConfigurationType } from './usePagingFromURLQuery'
import { IQueryResult } from '../types/gqlTypes'
import { useLocation, useNavigate } from 'react-router-dom'
import { httpBuildQueryString } from '../functions/httpBuildQuery'
import { filterAction, ICustomFilterReturnValue } from '../../components/bootstrapTable/CustomTextFilterInner'
import { noFiltrationId } from './columnFilterFactory'
import { IStringObject } from '../types/IStringObject'
import { hasQueryData } from '../functions/queryHelpers'
import { convertDateForGql } from '../functions/convertDateForGql'
import CustomSizePerPageRenderer from '../../components/bootstrapTable/CustomSizePerPageRenderer'

export default function useBootstrapTablePagingAndFiltration(
  pageConfiguration: pageConfigurationType,
  totalCountQR?: IQueryResult,
  filtrationOnly?: boolean,
  totalCountDirect?: number
) {
  // as QueryResult<IQRWithPaging, any>
  const navigate = useNavigate()
  const location = useLocation()
  //first we do not know maximum records = so we guess maximum
  let totalCount: number = totalCountDirect ?? pageConfiguration.currentPage * DEFAULT_PAGE_SIZE
  // @ts-ignore
  if (totalCountQR && hasQueryData(totalCountQR) && totalCountQR.data[Object.keys(totalCountQR.data)[0]] >= 0) {
    totalCount = totalCountQR.data[Object.keys(totalCountQR.data)[0]]
  }
  const bootstrapTablePaginationSetup: PaginationProps = {
    page: pageConfiguration.currentPage,
    sizePerPage: pageConfiguration.pageSize,
    totalSize: totalCount,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    hidePageListOnlyOnePage: false,
    showTotal: true,
    pageStartIndex: 1,
    pageListRenderer: pageListRenderer,
    paginationTotalRenderer: paginationTotalRenderer,
    sizePerPageList: [
      { value: 10, text: '10' },
      { value: 25, text: '25' },
      { value: 50, text: '50' },
      { value: 100, text: '100' },
      { value: 200, text: '200' }
    ],
    sizePerPageRenderer: CustomSizePerPageRenderer,
    custom: true
  }

  return { bootstrapTablePaginationSetup, handleTableChange }

  function handleTableChange(type: TableChangeType, { filters, page, sizePerPage, sortField, sortOrder }: TableChangeNewState) {
    let newParams: IQueryParamsInclPage = filtrationOnly ? {} : { page: 1, offset: 0 } //init with reset to first page
    //keep current filters and sorting
    newParams = { ...newParams, ...pageConfiguration.filterBy }
    if (pageConfiguration.sort) {
      newParams.sortField = pageConfiguration.sort.dataField
      newParams.sortOrder = pageConfiguration.sort.order
    }
    switch (type) {
      case 'sort':
        if (!filtrationOnly) newParams.limit = pageConfiguration.pageSize //reset to first page
        processSorting(newParams)
        break
      case 'filter':
        if (!filtrationOnly) newParams.limit = pageConfiguration.pageSize //reset to first page
        processFilters()
        break
      case 'pagination':
        if (!page || !sizePerPage) return
        const currentSizePerPage = pageConfiguration.pageSize
        const currentPage = pageConfiguration.currentPage
        if (currentSizePerPage === sizePerPage && currentPage === page) break
        if ((page - 1) * sizePerPage >= totalCount) return //move behind possible page

        if (currentSizePerPage !== sizePerPage || page === 1) {
          //move to fist entry
          newParams.limit = sizePerPage
          break
        } else {
          newParams.page = page
          newParams.offset = (page - 1) * sizePerPage
          newParams.limit = sizePerPage
          break
        }
      default:
      case 'cellEdit':
        return
    }
    if (location.search !== httpBuildQueryString(newParams)) {
      navigate(location.pathname + httpBuildQueryString(newParams))
      return httpBuildQueryString(newParams) //is used only for testing purposes
    }
    /* istanbul ignore next */
    return

    //only functions below!

    function processFilters() {
      for (const filter in filters) {
        newParams = { ...newParams, ...getFilterValue(filters[filter], filter) }
      }
    }

    function processSorting(newParams: IQueryParamsInclPage) {
      if (!sortField) return
      if (!!sortOrder) {
        newParams.sortField = sortField
        newParams.sortOrder = sortOrder
      } else {
        newParams.sortField = undefined
        newParams.sortOrder = undefined
      }
    }
  }
}

//HACK: exports only because of tests
export function getFilterValue(filter: Filter<unknown>, filterName: string) {
  if (filter.filterType === 'customText') {
    if ((filter.filterVal as ICustomFilterReturnValue).action === filterAction.set)
      return { [filterName]: (filter.filterVal as ICustomFilterReturnValue).value }
    else return { [filterName]: undefined }
  }
  if (filter.filterType === 'customNumeric') {
    if ((filter.filterVal as ICustomFilterReturnValue).action === filterAction.set)
      return { [filterName]: parseInt(((filter.filterVal as ICustomFilterReturnValue).value as string).trim()) }
    else return { [filterName]: undefined }
  }

  if (filter.filterType === 'dateRange' && filter.filterVal) {
    const filterValue = filter.filterVal as { from: Date; to: Date }
    return {
      [`${filterName}From`]: filterValue.from ? convertDateForGql(filterValue.from) : '',
      [`${filterName}To`]: filterValue.to ? convertDateForGql(filterValue.to) : ''
    }
  }
  if (filter.filterType === 'timeRange' && filter.filterVal) {
    const filterValue = filter.filterVal as { from: string; to: string }
    return {
      [`${filterName}From`]: filterValue.from ? filterValue.from : '',
      [`${filterName}To`]: filterValue.to ? filterValue.to : ''
    }
  }
  if (filter.filterType === 'numberRange' && filter.filterVal) {
    const filterValue = filter.filterVal as { from: ICustomFilterReturnValue; to: ICustomFilterReturnValue }
    let resFilter: IStringObject = {}
    if (filterValue.from.action === filterAction.set) resFilter[`${filterName}From`] = parseInt((filterValue.from.value as string).trim())
    else resFilter[`${filterName}From`] = undefined

    if (filterValue.to.action === filterAction.set) resFilter[`${filterName}To`] = parseInt((filterValue.to.value as string).trim())
    else resFilter[`${filterName}To`] = undefined

    return resFilter
  }
  if (filter.filterType === 'SELECT' || filter.filterType === 'customSelect') {
    if (filter.filterVal === noFiltrationId.toString()) return { [filterName]: '' }
    return { [filterName]: filter.filterVal }
  }
  if (filter.filterType === 'date') {
    const filterValue = filter.filterVal as ICustomFilterReturnValue
    if (filterValue.action === filterAction.set) return { [filterName]: convertDateForGql(filterValue.value) }
    else return { [filterName]: undefined }
  }

  /* istanbul ignore next */
  throw new Error(`Filter type ${filter.filterType} cannot be handled`)
}
