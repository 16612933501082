import { IReactSelectItem } from './ReactSelect'

export const AccountingDocumentList: IReactSelectItem[] = [
  { value: 'PROFORMA_INVOICE', label: 'Proforma faktura' },
  { value: 'INVOICE', label: 'Faktura' },
  { value: 'INCOME_RECEIPT', label: 'Daňový doklad' },
  { value: 'OPRAVNY_DANOVY_DOKLAD', label: 'Opravný daňový doklad' }
]

export const AccountingDocumentTranslation = {
  PROFORMA_INVOICE: 'Proforma faktura',
  INVOICE: 'Faktura',
  INCOME_RECEIPT: 'Daňový doklad',
  OPRAVNY_DANOVY_DOKLAD: 'Opravný daňový doklad'
}
