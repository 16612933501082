import { gql, useQuery } from '@apollo/client'
import { IGqlPagingQueryParams } from '../../../services/bootstrapTable/usePagingFromURLQuery'
import { SupplierSupplierListResponse } from '../../../graphql/aminTypes'
import { ServiceCategoryType } from '../../../services/types/service-category.type'

export const supplierSupplierByIdQuery = gql`
  query supplierSupplierList($id: ID) {
    supplierSupplierList(id: $id) {
      items {
        id
        isActive
        name
        branches {
          id
          gps {
            lng
            lat
          }
          address {
            id
            city
            street
            streetNumber
            id
            zip
          }
          branchServices {
            id
            higherPriority
            isActive
            metrakMargin
            settings
            validFrom
            validTo
            serviceCategory
          }
        }
      }
    }
  }
`

export function useSupplierSupplierByIdQuery(id?: string | null, skip?: boolean) {
  return useQuery<{ supplierSupplierList: SupplierSupplierListResponse }>(supplierSupplierByIdQuery, {
    fetchPolicy: 'network-only',
    variables: { id },
    skip: skip || !id
  })
}
