import { gql, useQuery } from '@apollo/client'
import { SupplierList } from '../../../../../graphql/aminTypes'

const suppliers2Query = gql`
  query suppliers2($operationType: SupplierOperationType, $productId: Int, $disabled: String, $aroundCustomerGps: GpsCoordinations) {
    suppliers2(operationType: $operationType, withProductId: $productId, disabled: $disabled, aroundCustomerGps: $aroundCustomerGps) {
      items {
        id
        name
        gps {
          coordinates
        }
        operationRadiusM
        disabled
        orpName
        regionCode
        priority
      }
    }
  }
`

export function useSuppliersQuery(opts: { isForDelivery?: boolean; productId?: number; disabled?: boolean; customerGps?: { lat: string; lng: string } }) {
  let variables = {
    operationType: opts.isForDelivery ? 'DELIVERY' : 'COLLECTION',
    aroundCustomerGps: {
      lng: opts.customerGps?.lng,
      lat: opts.customerGps?.lat
    }
  }
  if (opts.productId) variables['productId'] = opts.productId
  if (opts.disabled !== undefined && !opts.disabled) variables['disabled'] = 'false'

  return useQuery<{ suppliers2: SupplierList }>(suppliers2Query, {
    variables,
    fetchPolicy: 'network-only',
    skip: !opts.customerGps
  })
}
