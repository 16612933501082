import React from 'react'
import SelectField from '../../formComponents/SelectField'
import {materialQueryItemType} from "../../../services/queries/materialsQuery";

export default function MaterialSelector({ disabled, materialsQuery }: {
    disabled?: boolean,
    materialsQuery: any
}) {
    let materialData: any[] = []
    if (!materialsQuery.loading && materialsQuery.data) {
        const sortedMaterials = materialsQuery.data.materials?.slice().sort((a: materialQueryItemType, b: materialQueryItemType) => a.uiOrder - b.uiOrder)

        for (let i = 0; i < sortedMaterials?.length; i++) {
            if (sortedMaterials[i].isMaterial) materialData.push({
                label: sortedMaterials[i].name,
                value: sortedMaterials[i].id,
                id: sortedMaterials[i].id,
                color: 'black'
            })
        }
    }

    return (
        <SelectField
            disabled={disabled}
            name={'materialId'}
            label={'Materiál'}
            required
            registerOptions={{ required: true }}
            optionsData={materialData}
            isLoading={materialsQuery.loading}
        />
    )
}
