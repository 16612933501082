import dayjs from 'dayjs'

export function formatDateTimeForDisplayLong(dateToFormat: string | Date | number) {
  if (!dateToFormat) return null
  const datetimeToShow = dayjs(dateToFormat)
  return `${datetimeToShow.format('LLL')}`
}

export function formatDateTimeForDisplayShort(dateToFormat: string | Date | number) {
  if (!dateToFormat) return null
  const datetimeToShow = dayjs(dateToFormat)
  return datetimeToShow.format('l').replaceAll(' ', '') + ' ' + datetimeToShow.format('LTS')
}

export function formatDateForDisplayShort(dateToFormat: string | Date | number) {
  if (!dateToFormat) return null
  return dayjs(dateToFormat).format('L')
}
