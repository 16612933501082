import { AccountingAdvanceAccountEntity } from '../../../../graphql/aminTypes'
import {
  ColumnAlign,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../../services/bootstrapTable/services/tableSpecificationTypes'
import { formatAddressToDisplay } from '../../../../services/dataToString/formatAddressToDisplay'
import { formatDateForDisplayShort } from '../../../../services/dataToString/dateTimeFormatter'

export const advanceAccountsDataDefinition: TableDefinitionTypes<AccountingAdvanceAccountEntity> = {
  columnDefinition: {
    id: { hidden: true },
    streetWithNumber: { hidden: true },
    createdAt: {
      name: 'Vytvořen'
    },
    name: {
      name: 'Jméno akce',
      editable: true
    },
    address: {
      name: 'Adresa',
      columnFormatterType: ColumnFormatterTypes.showAsLink
    },
    remainingKc: {
      name: 'Zůstatek',
      columnFormatterType: ColumnFormatterTypes.moneyFormatter
    },
    note: { name: 'Poznámky', align: ColumnAlign.left, editable: true },
    transactionsCount: {
      name: 'Transakce',
      align: ColumnAlign.center,
      columnFormatterType: ColumnFormatterTypes.showAsLink
    },
    invoicesCount: {
      name: 'Faktur',
      align: ColumnAlign.center,
      columnFormatterType: ColumnFormatterTypes.showAsLink
    },
    actions: {
      name: 'Akce',
      columnFormatterType: ColumnFormatterTypes.actionsObject,
      align: ColumnAlign.left,
      headerStyle: { width: '120px' }
    }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    streetWithNumber: x => formatAddressToDisplay(x.address).split(',')[0],
    name: x => x.name,
    createdAt: x => formatDateForDisplayShort(x.createdAt),
    address: x => formatAddressToDisplay(x.address),
    remainingKc: x => x.remainingKc,
    transactionsCount: x => x.transactions?.length ?? 0,
    invoicesCount: x => x.accountingDocuments?.length ?? 0,
    note: x => x.note,
    actions: x => {
      if ((x.transactions && x.transactions.length > 0) || (x.accountingDocuments && x.accountingDocuments.length > 0)) return []
      const ret: IRowAction[] = []
      ret.push({
        type: RowActionType.codeAction,
        value: 'DeleteAdvanceAccount',
        icon: 'fe-x',
        title: 'Zrušit zálohový účet'
      })
      return ret
    }
  }
}
