import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const clearCalcCacheMutation = gql`
  mutation clearCalcCache {
    clearCalcCache {
      status {
        reason
        result
      }
    }
  }
`

export function useClearCalcCacheMutation() {
  return useMutation<{ clearCalcCache: IMutationResult }>(clearCalcCacheMutation)
}
