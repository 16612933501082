import {gql, useQuery} from '@apollo/client'
import {contextEndpoint, EndpointEnum} from "../../../services/appConfiguration/contextEndpoint";

const getDownloadUrlQuery = gql`
  query getDownloadUrl($key: String!) {
    getDownloadUrl(key: $key)
  }
`

export function useGetDownloadUrlQuery() {
  return useQuery<{ getDownloadUrl: string }>(getDownloadUrlQuery, {
    skip: true,
    context: contextEndpoint(EndpointEnum.supplier),
    fetchPolicy: 'network-only'
  })
}
