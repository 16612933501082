import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const upsertProductContainerCapacityMutation = gql`
  mutation upsertProductContainerCapacity($data: ProductContainerCapacityInputArgs!, $containerCapacityId: ID) {
    upsertProductContainerCapacity(data: $data, containerCapacityId: $containerCapacityId) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpsertProductContainerCapacityMutation() {
  return useMutation<{ upsertProductContainerCapacity: IMutationResult }>(upsertProductContainerCapacityMutation, {
    refetchQueries: ['productContainerCapacity']
  })
}
