import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { TransactionPairingTableRowDataType } from '../types/table-row-data'
import SelectField from '../../../../components/formComponents/SelectField'
import classNames from 'classnames'
import { IReactSelectItem } from '../../../../services/types/ReactSelect'
import { OrderContentsResult } from '../../../../graphql/aminTypes'
import { useOrderListForTransactionMatchingAutocompleteQuery } from '../queries/order-content-list-for-autocomplete.query'
import { HtmlDataList } from '../../../../components/HtmlDataList'
import { moneyToLocaleString } from '../../../../services/dataToString/decimalToLocaleString'
import { formatDateTimeForDisplayShort } from '../../../../services/dataToString/dateTimeFormatter'
import ToggleField from '../../../../components/formComponents/ToggleField'
import { usePairFioTransactionWithOrderMutation } from '../mutations/pair-fio-transaction-with-order.mutation'
import LoadingContainer from '../../../../components/LoadingContainer'
import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import { WarningAlert } from '../../../../components/formComponents/alert'
import { orderContentStateBadgeColors } from '../../../../services/types/order-content-state-badge-colors'
import { paymentStateBadgeColors, paymentStatusMap } from '../../../../services/types/payment-status'
import { paymentMethodBadgeColors, paymentMethodsMap } from '../../../../services/types/payment-method'

export function PairWithOrderComponent({ rowData, onExit }: { rowData: TransactionPairingTableRowDataType; onExit: () => void }) {
  const formMethods = useForm({
    defaultValues: { orderSelectId: undefined, performOrderContentStateTransition: true }
  })
  const orderContents = useOrderListForTransactionMatchingAutocompleteQuery()
  const [selectedOrder, setSelectedOrder] = useState<OrderContentsResult | undefined>(undefined)

  const [pairFioTransactionWithOrderMut, pairFioTransactionWithOrderQR] = usePairFioTransactionWithOrderMutation()

  return (
    <LoadingContainer showLoader={pairFioTransactionWithOrderQR.loading}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
          <h2>Přiřazení transakce k objednávce:</h2>
          <fieldset className={classNames('form-fieldset')}>
            <div className="row mb-2">
              <div className="col-md-6">
                <SelectField
                  name={'orderSelectId'}
                  label={'Vyberte objednávku'}
                  placeholder={'Napište číslo objednávky'}
                  isAsync
                  loadOptions={loadOptionsForHistorySearch}
                  handleChange={setupOrderData}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-6">
                <HtmlDataList
                  label={'Transakce'}
                  data={[
                    { label: 'Jméno plátce', text: rowData.payerName },
                    { label: 'VS', text: rowData.variableSymbol },
                    { label: 'Částka', text: rowData.amount },
                    { label: 'Datum', text: rowData.transactionDate }
                  ]}
                />
              </div>
              <div className="col-md-6">
                {selectedOrder && (
                  <HtmlDataList
                    label={'Objednávka'}
                    data={[
                      { label: 'Číslo', text: selectedOrder.order.orderNumber },
                      {
                        label: 'Jméno',
                        text:
                          selectedOrder.order.firstname +
                          ' ' +
                          selectedOrder.order.lastname +
                          ' ' +
                          (selectedOrder.order.companyName ? selectedOrder.order.companyName : '')
                      },
                      { label: 'Částka', text: moneyToLocaleString(selectedOrder.order.totalInclVatKc ?? 0, 'CZK') },
                      {
                        label: 'Datum objednání',
                        text: selectedOrder.order.createdAt && formatDateTimeForDisplayShort(selectedOrder.order.createdAt)
                      },
                      {
                        label: 'Stav',
                        text: (
                          <span className={classNames('badge', orderContentStateBadgeColors[selectedOrder.orderContentState.name])}>
                            {selectedOrder.orderContentState.name}
                          </span>
                        )
                      },
                      {
                        label: 'Stav platby',
                        text: (
                          <span className={classNames('badge', paymentStateBadgeColors[paymentStatusMap[selectedOrder.order.paymentStatus!]])}>
                            {paymentStatusMap[selectedOrder.order.paymentStatus!]}
                          </span>
                        )
                      },
                      {
                        label: 'Způsob platby',
                        text: (
                          <span className={classNames('badge', paymentMethodBadgeColors[paymentMethodsMap[selectedOrder.order.paymentType!]])}>
                            {paymentMethodsMap[selectedOrder.order.paymentType!]}
                          </span>
                        )
                      }
                    ]}
                  />
                )}
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12">
                <ToggleField name={'performOrderContentStateTransition'} label={'Zaplatit objednávku a provést akce jako při zaplacení'} />
              </div>
            </div>
            {formMethods.watch('performOrderContentStateTransition') && ['COD', 'ADVANCE_PAYMENT'].includes(selectedOrder?.order.paymentType!) && (
              <div className="row mb-2">
                <div className="col-md-12">
                  <WarningAlert>Objednávce bude nastaven typ platby na Převodem</WarningAlert>
                </div>
              </div>
            )}
            <div className="row mb-2">
              <div className="col-md-6">
                <div className="text-right mt-4">
                  <button type="button" className="btn btn-primary ms-auto btn-lg" onClick={onExit}>
                    Zpět
                  </button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-right mt-4">
                  <button type="submit" className="btn btn-red ms-auto btn-lg" disabled={!formMethods.watch('orderSelectId')}>
                    Spárovat transakci s objednávkou
                  </button>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </FormProvider>
    </LoadingContainer>
  )

  function loadOptionsForHistorySearch(inputValue: string, callback: (options: IReactSelectItem<OrderContentsResult>[]) => void) {
    orderContents.refetch({ limit: 20, offset: 0, orderNumber: inputValue }).then(x => {
      if (!x.data || !x.data.orderContentList || x.data.orderContentList.items.length === 0) {
        callback([])
        return
      }
      callback(
        x.data.orderContentList.items.map(y => ({
          value: y.order.id,
          label: `${y.order.orderNumber} - ${y.order.firstname ?? ''} ${y.order.lastname ?? ''} (${y.order.companyName ?? ''}}`,
          data: y
        }))
      )
    })
  }

  function setupOrderData(orderContentId: number, data?: OrderContentsResult) {
    setSelectedOrder(data)
  }

  function handleFormSubmit(data: Record<string | number | symbol, any>) {
    const variables = {
      fioTransactionPairingId: rowData.id,
      orderId: data.orderSelectId,
      performOrderContentStateTransition: data.performOrderContentStateTransition
    }

    let resultPromise = pairFioTransactionWithOrderMut({ variables })

    processPageOperation({
      promise: resultPromise,
      successMessage: 'Transakce byla úspěšně přiřazena k objednávce.',
      successAction: () => {
        onExit()
      }
    })
  }
}
