import {gql, useQuery} from '@apollo/client'
import {contextEndpoint, EndpointEnum} from "../../../../../services/appConfiguration/contextEndpoint";

const getWasteOriginDescSuggestionsQuery = gql`
    query getWasteOriginDescSuggestions{
        getWasteOriginDescSuggestions
    }
`
export function useGetWasteOriginDescSuggestions() {
    return useQuery<{ getWasteOriginDescSuggestions: string[]}>(getWasteOriginDescSuggestionsQuery, {
        context: contextEndpoint(EndpointEnum.admin),
        fetchPolicy: 'network-only'
    })
}
