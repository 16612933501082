import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { getParamFromUrl } from '../../../services/functions/getParamFromUrl'

export default function StatsButtonRow({
  orderContentStatesStat,
  setSelectedRows,
  showSearchState
}: {
  orderContentStatesStat: { contentStateSysName: string; count: number }[]
  setSelectedRows: any
  showSearchState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}) {
  const draft = orderContentStatesStat.find(x => x.contentStateSysName === 'Draft')?.count ?? 0
  const forAdminToApprove = orderContentStatesStat.find(x => x.contentStateSysName === 'ForAdminToApprove')?.count ?? 0
  const forSupplierToConfirm = orderContentStatesStat.find(x => x.contentStateSysName === 'ForSupplierToConfirm')?.count ?? 0
  const refusedBySupplier = orderContentStatesStat.find(x => x.contentStateSysName === 'RefusedBySupplier')?.count ?? 0
  const orderFinishedBySupplier = orderContentStatesStat.find(x => x.contentStateSysName === 'OrderFinishedBySupplier')?.count ?? 0
  const active = orderContentStatesStat.find(x => x.contentStateSysName === 'Active')?.count ?? 0

  const navigate = useNavigate()
  const filterPage = useCallback(
    (sys: string) => (event: any) => {
      setSelectedRows(new Set())
      if (!sys || getParamFromUrl('state') === sys) navigate('/order-list')
      else navigate(sys ? '/order-list?state=' + sys : '/order-list')
    },
    [navigate]
  )

  return (
    <div className="row mb-3">
      <div className="col-12">
        <div className="d-flex flex-row flex-wrap justify-content-md-around">
          <div onClick={() => showSearchState[1](v => !v)}>
            <i className={`fe fe-search text-primary display-6`} />
            <i className={`fe ${showSearchState[0] ? 'fe-chevrons-up' : 'fe-chevrons-down'} text-primary display-6`} />
          </div>
          <StatStateButton colour={'bg-dark text-white'} name={'Aktivní'} sysName={'Active'} count={active} />
          <StatStateButton colour={'bg-pink text-white'} name={'Drafty'} sysName={'Draft'} count={draft} />
          <StatStateButton colour={'bg-warning'} name={'Ke schválení adminem'} sysName={'ForAdminToApprove'} count={forAdminToApprove} />
          <StatStateButton colour={'bg-info'} name={'K potvrzení dodavatelem'} sysName={'ForSupplierToConfirm'} count={forSupplierToConfirm} />
          <StatStateButton colour={'bg-danger text-white'} name={'Odmítnuto dodavatelem'} sysName={'RefusedBySupplier'} count={refusedBySupplier} />
          <StatStateButton colour={'bg-success'} name={'Dokončeno dodavatelem'} sysName={'OrderFinishedBySupplier'} count={orderFinishedBySupplier} />
        </div>
      </div>
    </div>
  )

  function StatStateButton({ name, sysName, colour, count }: { name: string; sysName: string; colour: string; count: number }) {
    return (
      <>
        {getParamFromUrl('state') === sysName && (
          <button type="button" className="btn m-1 bg-golden-yellow btn-block" onClick={filterPage(sysName)}>
            <strong>
              {name} ({count})
            </strong>
          </button>
        )}
        {getParamFromUrl('state') !== sysName && (
          <button type="button" className={`btn m-1 ${colour} btn-block`} onClick={filterPage(sysName)}>
            {name} ({count})
          </button>
        )}
      </>
    )
  }
}
