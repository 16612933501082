import {gql, useMutation} from '@apollo/client'
import {IMutationResult} from "../../../../../services/types/gqlTypes";
import {contextEndpoint, EndpointEnum} from "../../../../../services/appConfiguration/contextEndpoint";
import {getZpoDetailQuery} from "../query/getZpoDetailQuery";

const markManualDeliveryMutation = gql`
    mutation markManualDelivery($orderContentId: Int!) {
        markManualDelivery(orderContentId: $orderContentId) {
            status {
                reason
                result
            }
        }
    }
`

export function useMarkManualDelivery(orderContentId: number) {
    return useMutation<{ markManualDelivery: IMutationResult }>(markManualDeliveryMutation, {
        context: contextEndpoint(EndpointEnum.admin),
        refetchQueries:
            [
                {
                    query: getZpoDetailQuery,
                    variables: {orderContentId: orderContentId},
                    context: contextEndpoint(EndpointEnum.admin)
                }
            ]
    })
}
