import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../services/types/gqlTypes'
import { customerListQuery } from '../../../CustomerList/services/customerListQuery'

const createAdvanceAccountInvoiceMutation = gql`
  mutation createAdvanceAccountInvoice($advanceAccountId: ID!, $data: CreateAdvanceAccountInvoiceInputArgs!) {
    createAdvanceAccountInvoice(advanceAccountId: $advanceAccountId, data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useCreateAdvanceAccountInvoice() {
  return useMutation<{ createAdvanceAccountInvoice: IMutationResult }>(createAdvanceAccountInvoiceMutation, {
    awaitRefetchQueries: true,
    refetchQueries: ['advanceAccountInvoices', 'customerAdvanceAccounts']
  })
}
