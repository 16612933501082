import { DataListItem } from '../../../../../components/HtmlDataList'
import { paymentMethodsMap, paymentMethodBadgeColors } from '../../../../../services/types/payment-method'
import { paymentStateBadgeColors, paymentStatusMapInclGP } from '../../../../../services/types/payment-status'
import { orderContentStateBadgeColors } from '../../../../../services/types/order-content-state-badge-colors'
import classNames from 'classnames'
import React from 'react'
import { OrderContentsResult } from '../../../../../graphql/aminTypes'

export function orderStateDataBuilder(orderContent: OrderContentsResult): DataListItem[] {
  const order = orderContent.order
  const paymentMethod = order.paymentType ? paymentMethodsMap[order.paymentType] : 'N/A'
  const orderState = orderContent.orderContentState.name
  const paymentState = order.paymentStatus ? paymentStatusMapInclGP[order.paymentStatus] : 'N/A'
  const goPayPaymentState = order.goPayPaymentStatus ? paymentStatusMapInclGP[order.goPayPaymentStatus] : 'N/A'

  return [
    {
      label: 'Stav objednávky',
      text: <span className={classNames('badge', orderContentStateBadgeColors[orderState])}>{orderState}</span>
    },
    { label: 'Platební metoda', text: <span className={classNames('badge', paymentMethodBadgeColors[paymentMethod])}>{paymentMethod}</span> },
    { label: 'Stav platby', text: <span className={classNames('badge', paymentStateBadgeColors[paymentState])}>{paymentState}</span> },
    { label: 'Stav GoPay platby', text: <span className={classNames('badge', paymentStateBadgeColors[goPayPaymentState])}>{goPayPaymentState}</span> },
    { label: 'Vlastník', text: !order.adminOwner ? 'N/A' : order.adminOwner.lastname + ' ' + order.adminOwner.firstname },
    { label: 'Dodavatel', text: orderContent.supplier?.name }
  ]
}
