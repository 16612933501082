import React, { useEffect, useRef } from 'react'

export const AddressPickerContext = React.createContext<
  ((instanceName: string | undefined, announceFn: (validationType: any, addressArray: any) => void) => void) | undefined
>(undefined)

export default function AddressPickerWrapper({ instancesCount, children }: { instancesCount: number; children: React.ReactNode }) {
  const pickerList = useRef<{ instanceName: any; announceFn: any }[]>([])
  useEffect(() => {
    if (pickerList.current.length === instancesCount) {
      if (!window.smartform.rebindAllForms) setTimeout(() => startSmartForm(), 1000)
      else startSmartForm()
    }
  }, [pickerList.current, instancesCount])

  function startSmartForm() {
    const smartform = window.smartform
    smartform.rebindAllForms(function () {
      for (const picker of pickerList.current) {
        window.smartform.getInstance(picker.instanceName).addressControl.addValidationCallback(picker.announceFn)
      }
    })
  }

  return <AddressPickerContext.Provider value={registerPicker}>{children}</AddressPickerContext.Provider>

  function registerPicker(instanceName: string | undefined, announceFn: (validationType: any, addressArray: any) => void) {
    pickerList.current.push({ instanceName, announceFn })
  }
}
