import React, { useContext, useEffect } from 'react'
import { SelectedSupplierModalContext } from '../SelectedSupplierModal/SelectedSupplierModal'
import { useSupplierSupplierByIdQuery } from './queries/supplier-supplier-by-id.query'
import { getParamFromUrl } from '../../services/functions/getParamFromUrl'
import { useSupplierBranchByIdQuery } from './queries/supplier-branch-by-id.query'

export default function SupplierContextLoader() {
  const supplierSupplierId = getParamFromUrl('supplierId')
  const supplierBranchId = getParamFromUrl('supplierBranchId')
  const supplierSupplierQR = useSupplierSupplierByIdQuery(supplierSupplierId, !supplierSupplierId || !supplierBranchId)
  const supplierBranchesQR = useSupplierBranchByIdQuery(supplierBranchId, true)
  const { state, dispatch } = useContext(SelectedSupplierModalContext)

  useEffect(() => {
    if (!supplierSupplierQR.data) return
    if (!supplierBranchId || !supplierSupplierId) return

    if (!state.selectedSupplier || !state.selectedBranch || state.selectedSupplier.id !== supplierSupplierId || state.selectedBranch.id !== supplierBranchId) {
      dispatch({
        type: 'SET_SELECTED_SUPPLIER',
        payload: supplierSupplierQR.data?.supplierSupplierList.items[0]
      })
      dispatch({
        type: 'SET_SELECTED_BRANCH',
        payload: supplierSupplierQR.data?.supplierSupplierList.items[0].branches?.find(branch => branch.id === supplierBranchId)
      })
      dispatch({
        type: 'SET_RELOAD_FUNCTION',
        payload: reloadBranch(supplierBranchId)
      })
    }
  }, [supplierSupplierQR.data])

  return <></>

  function reloadBranch(supplierBranchId: string) {
    return async () => {
      const resp = await supplierBranchesQR.refetch({ id: supplierBranchId })
      dispatch({
        type: 'SET_SELECTED_BRANCH',
        payload: resp.data?.supplierBranchList.items[0]
      })
    }
  }
}
