import { FormProvider, useForm } from 'react-hook-form'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import FormField, { inputType } from '../../../components/formComponents/FormField'
import ContainerSelector from '../../../components/dataComponents/ContainerSelector/ContainerSelector'
import { useUpdateSupplierPriceListMutation } from './services/updateSupplierPriceList'
import { createParamsForCreateSupplierPriceListMutation, useCreateSupplierPriceListMutation } from './services/createSupplierPriceListMutation'
import { isAnyQueryLoading } from '../../../services/functions/queryHelpers'
import PageCard from '../../../components/Cards/pageCard'
import cellEditFactory from 'react-bootstrap-table2-editor'
import BootstrapTable from 'react-bootstrap-table-next'
import useBootstrapTableDataMapper from '../../../services/bootstrapTable/useBootstrapTableDataMapper'
import { SupplierFlatRateType, SupplierPriceListType, useSupplierPriceListQuery } from './services/supplierPriceListQuery'
import { flatRateListDataDefinition } from './services/flatRateListDataDefinition'
import { processPageOperation } from '../../../services/formsServices/pageOperationProcessor'
import ProductsSelector, { ProductTypeEnum } from '../../../components/dataComponents/ProductsSelector/ProductsSelector'
import classNames from 'classnames'
import {
  AddSupplierFlatRateMutationResult,
  createParamsForAddSupplierFlatRateMutation,
  useAddSupplierFlatRateMutation
} from './services/addSupplierFlatRateMutation'
import { useDeleteSupplierFlatRateMutation } from './services/deleteSupplierFlatRateMutation'
import LoadingContainer from '../../../components/LoadingContainer'
import { IMutationResultWithId, mutationStatusResult } from '../../../services/types/gqlTypes'
import { createParamsForUpdateSupplierFlatRateMutation, useUpdateSupplierFlatRateMutation } from './services/updateFlatRateMutation'
import { Alert, alertType } from '../../../components/formComponents/alert'
import ToggleField from '../../../components/formComponents/ToggleField'
import DisableContainer from '../../../components/DisableContainer'
import { useSupplierDeliveryPriceListQuery } from './services/supplierDeliveryPriceListQuery'

export default function SupplierPriceListEditor({
  supplierId,
  hideContainers = [],
  supplierPriceListId,
  onFinish,
  isForFlatRate
}: {
  supplierId: number
  hideContainers?: number[]
  onFinish: Function
  isForFlatRate: boolean
  supplierPriceListId?: number
}) {
  const [supplierPriceListArray, setSupplierPriceListArray] = useState<SupplierPriceListType[] | undefined>(undefined)
  const [supplierPriceListArrayIndex, setSupplierPriceListArrayIndex] = useState<number | undefined>(undefined)
  const supplierPriceListsQR = useSupplierPriceListQuery(supplierId, data => {
    setupPriceListArrayFromResponse(data, supplierPriceListId)
  })

  const supplierPriceList =
    supplierPriceListArray && supplierPriceListArrayIndex !== undefined && supplierPriceListArrayIndex >= 0
      ? supplierPriceListArray[supplierPriceListArrayIndex]
      : undefined

  const supplierDeliveryPriceListQR = useSupplierDeliveryPriceListQuery(supplierId)

  const formMethods = useForm({ defaultValues: { ...supplierPriceList, containerId: supplierPriceList?.container?.id } })
  const formMethodsPrices = useForm()

  const [createPriceListMut, createPriceListQR] = useCreateSupplierPriceListMutation()
  const [updatePriceListMut, updatePriceListQR] = useUpdateSupplierPriceListMutation()
  const [updateFlatRateMut, updateFlatRateQR] = useUpdateSupplierFlatRateMutation()
  const [addSupplierFlatRateMut, addSupplierFlatRateQR] = useAddSupplierFlatRateMutation()
  const [deleteSupplierFlatRateMut, deleteSupplierFlatRateQR] = useDeleteSupplierFlatRateMutation()
  const { generateColumnListForBootstrapTable, mapDataArrayToTable } = useBootstrapTableDataMapper<SupplierFlatRateType>(flatRateListDataDefinition)

  const columnDefinition = generateColumnListForBootstrapTable({
    actionsReference: {
      deletePriceList: deleteSupplierFlatRateHandler
    }
  })

  const updateFormValues = useCallback(() => {
    if (!supplierPriceList) return
    for (const key of Object.keys(supplierPriceList)) {
      formMethods.setValue(key as any, supplierPriceList[key])
    }
    formMethods.setValue('containerId', supplierPriceList.container.id)
  }, [supplierPriceListArray, formMethods.setValue, supplierPriceList])

  useEffect(() => {
    updateFormValues()
  }, [supplierPriceListArrayIndex, supplierPriceListArray, updateFormValues])

  const fixedRates = useMemo(
    () =>
      supplierPriceList?.supplierFlatRates && supplierPriceList?.supplierFlatRates.length > 0 ? mapDataArrayToTable(supplierPriceList.supplierFlatRates) : [],
    [supplierPriceListArray, supplierPriceListArrayIndex]
  )

  const productId = formMethodsPrices.watch('productId')
  const flatRate = formMethodsPrices.watch('flatRateKc')

  return (
    <PageCard showLoader={isAnyQueryLoading(createPriceListQR, updatePriceListQR, updateFlatRateQR, supplierDeliveryPriceListQR)}>
      <div className="row ">
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(handleFormSubmit())} autoComplete={'off'}>
            <div className="col-md-12 mb-2">
              <ContainerSelector disabled={!!supplierPriceList} removeContainers={(supplierPriceListArrayIndex ?? -1) < 0 ? (hideContainers ?? []) : []} />
            </div>
            <div className="col-md-12 mb-2">
              {!isForFlatRate && (
                <FormField
                  type={inputType.number}
                  isMoney
                  name={'baseRentPriceKc'}
                  label={'Cena za manipulaci [Kč]'}
                  required
                  registerOptions={{ required: true }}
                />
              )}
            </div>
            <div className="col-md-12 mb-2">
              <FormField type={inputType.number} isMoney name={'pricePerKmKc'} label={'Cena za km [Kč]'} required registerOptions={{ required: true }} />
            </div>
            {isForFlatRate && (
              <div className="col-md-12 mb-2">
                <FormField
                  type={inputType.number}
                  isDecimal
                  isPositive
                  name={'flatRateRadius'}
                  label={'Vzdálenost započítaná v paušálu (ujetá, ne vzdušnou čarou) [km]'}
                  required
                  registerOptions={{ required: true }}
                />
              </div>
            )}
            {!isForFlatRate && (
              <div className="col-md-12 mb-2">
                <FormField
                  type={inputType.number}
                  isMoney
                  name={'minimumPriceForTransport'}
                  label={'Minimální cena za dopravu [Kč]'}
                  required
                  registerOptions={{ required: true }}
                />
              </div>
            )}
            <div className="col-md-12 mt-2">
              <ToggleField
                name={'disabled'}
                label={'Zakázán'}
                registerOptions={{
                  setValueAs: value => {
                    return value
                  }
                }}
              />
            </div>
          </form>
        </FormProvider>

        <FormProvider {...formMethodsPrices}>
          <form>
            {isForFlatRate && supplierPriceList && (
              <LoadingContainer showLoader={isAnyQueryLoading(addSupplierFlatRateQR, deleteSupplierFlatRateQR)}>
                <DisableContainer disabled={formMethods.formState.isDirty}>
                  <hr />
                  <h2>Paušální ceník</h2>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-flex">
                        <div className="p-2 flex-fill">
                          <ProductsSelector removeIds={fixedRates.map(x => x.productId)} required={false} productType={ProductTypeEnum.isCollection} />
                        </div>
                        <div className="p-2 flex-fill">
                          <FormField type={inputType.number} isMoney isPositive name={'flatRateKc'} label={'Paušální cena [Kč]'} />
                        </div>
                        <div className="p-2 align-self-center">
                          <i
                            role={productId && flatRate ? 'button' : ''}
                            tabIndex={0}
                            className={classNames('fe fe-plus-circle strong h1', productId && flatRate ? 'cursorPointer text-primary' : '')}
                            onClick={addFlatRateToSupplier}
                            onKeyDown={addFlatRateToSupplier}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <BootstrapTable
                    bootstrap4
                    striped
                    hover
                    condensed
                    classes="table-responsive-lg digiTable"
                    noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné záznamy"</>}
                    keyField="id"
                    data={fixedRates}
                    columns={columnDefinition}
                    defaultSorted={[{ dataField: 'productOrder', order: 'asc' }]}
                    cellEdit={cellEditFactory({
                      mode: 'click',
                      blurToSave: true,
                      autoSelectText: true,
                      beforeSaveCell: onFlatRateUpdate
                    })}
                  />
                </DisableContainer>
              </LoadingContainer>
            )}
          </form>
        </FormProvider>
      </div>
      {formMethods.formState.isDirty && <Alert type={alertType.warning}>Změny nejsou uloženy</Alert>}
      <div className="text-right">
        {supplierPriceList !== undefined && supplierPriceListArray && (
          <button
            type="button"
            className="btn btn-secondary ms-auto btn-lg"
            onClick={moveToPriceListIndex(prevState => ((prevState ?? 0) - 1 + supplierPriceListArray?.length) % supplierPriceListArray?.length)}
          >
            Předchozí
          </button>
        )}
        {supplierPriceList !== undefined && supplierPriceListArray && (
          <button
            type="button"
            className="btn btn-secondary ms-auto btn-lg ml-2"
            onClick={moveToPriceListIndex(prevState => ((prevState ?? 0) + 1) % supplierPriceListArray?.length)}
          >
            Další
          </button>
        )}
        <button type="button" onClick={() => handleFormSubmit()(formMethods.getValues())} className="btn btn-primary ms-auto btn-lg ml-2">
          {supplierPriceList || supplierPriceListId ? 'Uložit změny' : 'Přidat ceník'}
        </button>
        <button type="button" className="btn btn-secondary ms-auto btn-lg ml-1" onClick={handleFormSubmitAndClose}>
          {supplierPriceList || supplierPriceListId ? 'Uložit & zavřít' : 'Přidat & zavřít'}
        </button>
      </div>
    </PageCard>
  )

  function moveToPriceListIndex(to: (prevIndex: number | undefined) => number) {
    return () => {
      if (formMethods.formState.isDirty) {
        if (!window.confirm('Některé hodnoty nebyly po upravení uloženy, chcete pokračovat?')) return
        formMethods.reset({})
      }
      setSupplierPriceListArrayIndex(prevState => to(prevState))
      supplierPriceListsQR.refetch()
    }
  }

  function addFlatRateToSupplier() {
    if (!supplierPriceList || !productId || !flatRate) return

    let resultPromise = addSupplierFlatRateMut({
      variables: createParamsForAddSupplierFlatRateMutation(supplierPriceList?.id, productId, flatRate),
      update: (cache, mutationResult) => {
        if (
          mutationResult.data &&
          mutationResult.data.addSupplierFlatRate &&
          mutationResult.data.addSupplierFlatRate.status.result === mutationStatusResult.success
        ) {
          fixedRates.push(mapDataArrayToTable([mutationResult.data.addSupplierFlatRate.data])[0])
        }
      }
    })

    processPageOperation<AddSupplierFlatRateMutationResult>({
      promise: resultPromise,
      successAction: () => {
        formMethodsPrices.setValue('flatRateKc', '')
        reloadData()
      }
    })
  }

  function deleteSupplierFlatRateHandler(row: any) {
    let resultPromise = deleteSupplierFlatRateMut({
      variables: { flatRateId: row.id },
      update: (cache, mutationResult) => {
        if (mutationResult.data && mutationResult.data.deleteSupplierFlatRate.status.result === mutationStatusResult.success) {
          const index = fixedRates.findIndex(x => x.id === row.id)
          fixedRates.splice(index, 1)
        }
      }
    })
    processPageOperation({
      promise: resultPromise,
      successAction: () => reloadData()
    })
  }

  async function handleFormSubmitAndClose() {
    await handleFormSubmit(onFinish)(formMethods.getValues())
  }

  function handleFormSubmit(onSuccess?: Function) {
    return async (data: Record<string | number | symbol, any>) => {
      await formMethods.trigger()
      if (Object.keys(formMethods.formState.errors).length > 0) return

      let resultPromise
      resultPromise =
        supplierPriceList === undefined
          ? createPriceListMut({ variables: { supplierId: supplierId, ...createParamsForCreateSupplierPriceListMutation(data) } })
          : updatePriceListMut({ variables: { supplierPriceListId: supplierPriceList.id, ...createParamsForCreateSupplierPriceListMutation(data) } })

      processPageOperation({
        promise: resultPromise,
        successMessage: 'Nastavení bylo uloženo',
        successAction: mutationResult => {
          formMethods.reset(data)
          reloadData(mutationResult)
          if (onSuccess) onSuccess()
        }
      })

      return false
    }
  }

  function onFlatRateUpdate(oldValue: string, newValue: string, row: any, column: any, done: Function) {
    newValue = newValue.replace(',', '.')
    if (!row || !row.id || isNaN(Number(newValue))) {
      done(false)
      return
    }
    if (Number(row['flatRate']) === Number(newValue)) {
      done(false)
      return
    }
    const promise = updateFlatRateMut({ variables: createParamsForUpdateSupplierFlatRateMutation(row.id, Number(newValue)) })
    processPageOperation({
      promise,
      successMessage: 'Cena nastavena',
      successAction: () => reloadData()
    })
    done(true)
  }

  function setupPriceListArrayFromResponse(data: any, supplierPriceListId: number | undefined) {
    const sortedList = data?.supplierPriceList.slice().sort((a, b) => a.container.uiOrder - b.container.uiOrder)
    setSupplierPriceListArray(sortedList)
    setSupplierPriceListArrayIndex(sortedList?.findIndex(x => x.id === supplierPriceListId))
  }

  function reloadData(mutationResult?: any) {
    const isCreateNew = supplierPriceList === undefined
    supplierPriceListsQR.refetch().then(response => {
      setupPriceListArrayFromResponse(response.data, isCreateNew && mutationResult ? (mutationResult as IMutationResultWithId).id : supplierPriceList?.id)
    })
  }
}
