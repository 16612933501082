import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const updateTerminalDisabledMutation = gql`
  mutation updateTerminalDisabled($terminalId: Int!, $disabled: Boolean!) {
    updateTerminalDisabled(terminalId: $terminalId, disabled: $disabled) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpdateTerminalDisabledMutation() {
  return useMutation<{ updateTerminalDisabled: IMutationResult }>(updateTerminalDisabledMutation)
}
