import React from 'react'
import dayjs from 'dayjs'
import { OrderResult } from '../../../../../../graphql/aminTypes'
import { paymentMethodBadgeColors, paymentMethodsMap } from '../../../../../../services/types/payment-method'
import { serviceTypeTranslation } from '../../../../../../services/types/service-type'
import { paymentStateBadgeColors, paymentStatusMap } from '../../../../../../services/types/payment-status'
import { orderContentStateBadgeColors } from '../../../../../../services/types/order-content-state-badge-colors'
import { ColumnAlign, ColumnFormatterTypes, TableDefinitionTypes } from '../../../../../../services/bootstrapTable/services/tableSpecificationTypes'
import { moneyToLocaleString } from '../../../../../../services/dataToString/decimalToLocaleString'

export function groupOrdersDataDefinition(): TableDefinitionTypes<OrderResult> {
  return {
    columnDefinition: {
      id: { hidden: true },
      totalWoVatForSum: { hidden: true },
      createdAt: {
        name: 'Vytvořena'
      },
      orderNumber: {
        name: 'Objedn.'
      },
      company: {
        name: 'Firma'
      },
      serviceType: {
        name: 'Typ zakázky',
        align: ColumnAlign.center
      },
      containerFromDate: {
        name: 'Přist.',
        headerStyle: { width: '150px' },
        align: ColumnAlign.right
      },
      containerToDate: {
        name: 'Odvoz',
        headerStyle: { width: '150px' },
        align: ColumnAlign.right
      },
      product: {
        name: 'Produkt'
      },
      container: {
        name: 'Kontejner / Množství'
      },
      totalWoVatKc: { name: 'Celkem bez DPH', align: ColumnAlign.right },
      totalInclVatKc: { name: 'Celkem s DPH', align: ColumnAlign.right },
      paymentType: {
        name: 'Platba',
        align: ColumnAlign.center,
        headerStyle: { minWidth: '90px' },
        badgeColours: paymentMethodBadgeColors,
        translateMap: paymentMethodsMap,
        columnFormatterType: ColumnFormatterTypes.badgeAndTranslate
      },
      paymentStatus: {
        name: 'Stav platby',
        align: ColumnAlign.center,
        headerStyle: { minWidth: '90px' },
        badgeColours: paymentStateBadgeColors,
        translateMap: paymentStatusMap,
        columnFormatterType: ColumnFormatterTypes.badgeAndTranslate
      },
      state: {
        name: 'Stav',
        align: ColumnAlign.center,
        headerStyle: { minWidth: '100px' },
        badgeColours: orderContentStateBadgeColors,
        columnFormatterType: ColumnFormatterTypes.showInBadge
      }
    },
    sourceToColumnMapping: {
      id: x => x.id,
      totalWoVatForSum: x => x.totalWoVatKc,
      createdAt: x => dayjs(x.createdAt).format('L'),
      company: x => x.companyName,
      orderNumber: x => x.orderNumber,
      serviceType: x => (x.orderContent?.serviceType ? serviceTypeTranslation[x.orderContent.serviceType] : ''),
      containerFromDate: x => (
        <>
          {`${dayjs(x.orderContent?.containerFromDate).format('ddd DD.MM.YYYY')} `} <br /> {x.orderContent?.arriveTime}
        </>
      ),
      containerToDate: x =>
        x.orderContent?.containerToDate ? (
          <>
            {dayjs(x.orderContent.containerToDate).format('ddd DD.MM.YYYY')}
            <br /> {x.orderContent.collectionTime ?? ''}
          </>
        ) : (
          ''
        ),
      product: x => x.orderContent?.product.name,
      container: x => {
        if (x.orderContent?.serviceType === 'MATERIAL_DELIVERY') {
          return <>{x.orderContent.weightTonnes}t </>
        } else {
          return (
            <>
              <strong>{x.orderContent?.containerCount}× </strong>
              {x.orderContent?.container?.name} {x.orderContent?.container?.capacityM3}m³
            </>
          )
        }
      },
      totalWoVatKc: x => moneyToLocaleString(x.totalWoVatKc, 'CZK', false),
      totalInclVatKc: x => moneyToLocaleString(x.totalInclVatKc, 'CZK', false),
      paymentType: x => x.paymentType,
      paymentStatus: x => x.paymentStatus,
      state: x => x.orderContent?.orderContentState?.name
    }
  }
}
