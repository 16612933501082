import { gql, useQuery } from '@apollo/client'
import { ProductMaterialEntity, ProductWasteEntity, WasteCategory } from '../../../../../graphql/aminTypes'

export const productMaterialListQuery = gql`
  query productMaterialList {
    productMaterialList {
      id
      isActive
      name
      tooltip
      uiOrder
      cubicToTonnes
    }
  }
`

export function useProductMaterialListQuery() {
  return useQuery<{ productMaterialList: ProductMaterialEntity[] }>(productMaterialListQuery, {
    fetchPolicy: 'network-only'
  })
}
