import {
  ColumnAlign,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../../services/bootstrapTable/services/tableSpecificationTypes'
import { moneyToLocaleString } from '../../../../services/dataToString/decimalToLocaleString'
import { SupplierFlatRateType } from './supplierPriceListQuery'

export const flatRateListDataDefinition: TableDefinitionTypes<SupplierFlatRateType> = {
  columnDefinition: {
    id: { hidden: true },
    productOrder: { name: 'order', sort: true, hidden: true },
    product: { name: 'Druh odpadu' },
    productId: { hidden: true },
    flatRate: { name: 'Paušální cena [Kč]', editable: true, type: 'number' },
    actions: {
      name: 'Akce',
      columnFormatterType: ColumnFormatterTypes.actionsObject,
      align: ColumnAlign.right,
      headerStyle: { width: '60px' }
    }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    productOrder: x => x.product.uiOrder,
    product: x => x.product.name,
    productId: x => x.product.id,
    flatRate: x => moneyToLocaleString(x.flatPriceKc),
    actions: x => {
      const ret: IRowAction[] = []
      ret.push({
        type: RowActionType.codeAction,
        value: 'deletePriceList',
        icon: 'fe-x',
        title: `Odstranit`
      })
      return ret
    }
  }
}
