import {
  ColumnAlign,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../../../services/bootstrapTable/services/tableSpecificationTypes'
import { WasteCapacitiesType } from '../../../../../services/queries/containersQuery'

export const containerListExpandDataDefinition: TableDefinitionTypes<WasteCapacitiesType> = {
  columnDefinition: {
    id: { hidden: true },
    product: { name: 'Typ odpadu' },
    fullCapacity: { name: 'Kapacita [tuny]', editable: true },
    productId: { name: 'Typ odpadu', hidden: true },
    actions: {
      name: 'Akce',
      columnFormatterType: ColumnFormatterTypes.actionsObject,
      align: ColumnAlign.right,
      headerStyle: { width: '50px' }
    }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    product: x => x.product.name,
    fullCapacity: x => x.fullCapacityTonne,
    productId: x => x.product.id,
    actions: x => {
      const ret: IRowAction[] = []
      ret.push({
        type: RowActionType.codeAction,
        value: 'deleteWasteType',
        icon: 'fe-x',
        title: 'Odstranit odpad'
      })
      return ret
    }
  }
}
