import { gql } from '@apollo/client'

export const refreshTokenMutation = gql`
  mutation refreshToken {
    refreshToken {
      accessToken
      status {
        reason
        result
      }
    }
  }
`
