import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../services/types/gqlTypes'

const createAdvanceAccountMutation = gql`
  mutation createAdvanceAccount($customerId: ID!, $data: CreateAdvanceAccountInputArgs!) {
    createAdvanceAccount(customerId: $customerId, data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useCreateAdvanceAccountMutation() {
  return useMutation<{ createAdvanceAccount: IMutationResult }>(createAdvanceAccountMutation, {
    awaitRefetchQueries: true,
    refetchQueries: ['customerAdvanceAccounts']
  })
}
