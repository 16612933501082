import React from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { cs } from 'date-fns/locale'
import { IFormComponentBaseInput } from './components/IFormComponentBaseInput'
import { FormComponentBaseInput } from './components/FormComponentBaseInput'
import { useFormContext } from 'react-hook-form'
import dayjs from 'dayjs'

registerLocale('cs', cs) // register it with the name you want

interface IDatePickerComp extends IFormComponentBaseInput {
  onSelect?: Function
  minDate?: Date
  maxDate?: Date
  id?: string
  dateAsString?: boolean
}

export default function DatePickerComp({ minDate, maxDate, onSelect, dateAsString, ...otherOptions }: IDatePickerComp) {
  const { watch, setValue, register } = useFormContext()
  register(otherOptions.name, otherOptions.registerOptions)
  const currentValue = watch(otherOptions.name)
  return (
    <FormComponentBaseInput {...otherOptions}>
      <DatePicker
        dateFormat="dd/MM/yyyy"
        selected={dateAsString ? (currentValue ? new Date(currentValue) : null) : currentValue ?? null}
        locale="cs"
        placeholderText={'Vyberte datum'}
        isClearable={!otherOptions.required}
        todayButton="Dnes"
        className="form-control"
        disabled={otherOptions.disabled}
        id={otherOptions.id ?? 'datepicker'}
        minDate={minDate}
        maxDate={maxDate}
        onChange={(selectedDate: Date | null) => {
          setValue(otherOptions.name, dateAsString ? dayjs(selectedDate).format('YYYY-MM-DD') : selectedDate)
          if (onSelect) onSelect(selectedDate)
        }}
      />
    </FormComponentBaseInput>
  )
}
