import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'

const releaseOrderLockMutation = gql`
  mutation releaseOrderLock($orderId: Int!) {
    releaseOrderLock(orderId: $orderId) {
      status {
        reason
        result
      }
    }
  }
`

export function useReleaseOrderLockMutation() {
  return useMutation<{ releaseOrderLock: IMutationResult }>(releaseOrderLockMutation, {
    refetchQueries: ['getOrderLock']
  })
}
