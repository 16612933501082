import { gql, useQuery } from '@apollo/client'

const materialQuery = gql`
    query materials {
        materials {
            id
            name
            tooltip
            uiPicture
            tonToCubes
            uiOrder
            isFraction
            isMaterial
            isType
        }
    }
`

export type materialQueryItemType = {
    id: number
    name: string
    tooltip: string
    uiPicture: string
    uiOrder: number
    isFraction: boolean
    isMaterial: boolean
    tonToCubes: number
    isType: MaterialTypeEnum
}


export function useMaterialQuery(onCompleted?: (data) => void) {
    return useQuery<{ materials: materialQueryItemType[] }>(materialQuery, {
        fetchPolicy: 'network-only',
        onCompleted
    })
}

export enum MaterialTypeEnum {
    FRACTION,
MATERIAL,
TYPE,
}
