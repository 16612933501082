import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import PageCard from '../../../components/Cards/pageCard'
import { isAnyQueryLoading } from '../../../services/functions/queryHelpers'
import FormField, { inputType } from '../../../components/formComponents/FormField'
import { processPageOperation } from '../../../services/formsServices/pageOperationProcessor'
import { useAttachFileMutation } from './services/attachFileMutation'
import { toast } from 'react-toastify'
import WasteTypesSelector from '../../../components/dataComponents/WasteTypesSelector/WasteTypesSelector'
import { useCreateWasteFinalMutation } from './services/create-waste-final.mutation'
import WasteTerminalAutocomplete from '../../../components/dataComponents/WasteTerminalAutocomplete/WasteTerminalAutocomplete'
import { createParamsForCreateWasteFinalMutation } from '../../../scenesAdmin/OrderDetail/components/WasteFinals/mutation/create-waste-final.mutation'

export function FinishOrder({ orderContentId, onFinish, onCancel }: { orderContentId: number; onFinish: () => void; onCancel: () => void }) {
  const formMethods = useForm()
  const [attachFileMutation, attachFileQR] = useAttachFileMutation()
  const [createWasteFinalMut, createWasteFinalQR] = useCreateWasteFinalMutation()

  return (
    <PageCard headerTitle={'Váženka'} showLoader={isAnyQueryLoading(attachFileQR, createWasteFinalQR)}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
          <fieldset className="form-fieldset">
            <div className="row mb-2">
              <div className="col-md-12">
                <WasteTypesSelector isForSupplier />
                <FormField
                  type={inputType.number}
                  name={'weightTonne'}
                  registerOptions={{ required: true }}
                  label={'Množství [t]'}
                  is2Decimal
                  required
                  isPositive
                />
                <WasteTerminalAutocomplete isForSupplier={true} />
              </div>
            </div>
          </fieldset>
          <div className="d-flex justify-content-md-center">
            <div className="text-right mt-4">
              <button type="button" className="btn btn-secondary ms-auto btn-lg mr-5" onClick={onCancel}>
                Zrušit
              </button>
            </div>
            <div className="text-right mt-4">
              <button type="submit" className="btn btn-primary ms-auto btn-lg">
                Dokončit zakázku
              </button>
            </div>
            <div className="text-right mt-4">
              <button type="button" className="btn btn-primary ms-auto btn-lg ml-5" onClick={onFinish}>
                Dokončit bez váženky
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </PageCard>
  )

  function handleFormSubmit(data: any) {
    if (window.confirm('Opravdu dokončit objednávku?')) {
      if (!data.wasteTypeId || !data.weightTonne) {
        onFinish()
        return
      }

      const promise = createWasteFinalMut({
        variables: createParamsForCreateWasteFinalMutation(orderContentId, data, false)
      })

      processPageOperation({
        promise: promise,
        successMessage: 'Zakázka byla dokončena',
        successAction: result => {
          onFinish()
        }
      })
    }
  }

  function processImport(acceptedFiles: File[]) {
    if (acceptedFiles.length !== 1) {
      toast.error('Soubor může být jen jeden o velikosti max 10MB.')
    } else {
      const promise = attachFileMutation({ variables: { orderContentId: orderContentId, file: acceptedFiles[0] } })
      processPageOperation({
        promise: promise,
        successMessage: 'Soubor byl přiložen k zakázce'
      })
    }
  }
}
