import React from 'react'
import SelectField from '../../formComponents/SelectField'
import { IReactSelectItem } from '../../../services/types/ReactSelect'
import { useProducts } from './services/productsQuery'

export enum ProductTypeEnum {
  isCollection = 'isCollection',
  isDelivery = 'isDelivery'
}

export default function ProductsSelector({
  disabled,
  removeIds,
  required = true,
  productType,
  availableProductIds
}: {
  disabled?: boolean
  removeIds?: number[]
  required?: boolean
  productType?: ProductTypeEnum
  availableProductIds?: number[]
}) {
  const productsQR = useProducts()
  let productsData: IReactSelectItem<any>[] = []
  let productsDataUpdated: IReactSelectItem<any>[] = []
  if (!productsQR.loading && productsQR.data) {
    let filteredProducts = [...productsQR.data.products]
    if (productType === ProductTypeEnum.isCollection) filteredProducts = filteredProducts.filter(x => x.isCollection)
    if (productType === ProductTypeEnum.isDelivery) filteredProducts = filteredProducts.filter(x => x.isDelivery)

    productsData = [...filteredProducts].map(x => ({
      label: x.name,
      value: x.id,
      id: x.id,
      color: !availableProductIds || availableProductIds.includes(x.id) ? '#000' : '#ccc'
    }))
  }

  if (productsData.length > 0 && removeIds && removeIds.length > 0) {
    productsDataUpdated = productsData.filter(x => !removeIds?.includes(x.id as number))
  }
  return (
    <SelectField
      name={'productId'}
      label={'Produkt'}
      required={required}
      disabled={disabled}
      registerOptions={{ required: required }}
      optionsData={removeIds && removeIds.length > 0 ? productsDataUpdated : productsData}
      isLoading={productsQR.loading}
    />
  )
}
