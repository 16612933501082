import { gql, useQuery } from '@apollo/client'
import { OrderContentListResult } from '../../../../graphql/aminTypes'

const ordersForDashboardQuery = gql`
  query ordersForDashboard($flags: FlagEnum, $state: String) {
    orderContentList(limit: 1000, flags: $flags, state: $state) {
      items {
        id
        orderContentState {
          id
          name
          sysName
        }
        supplier {
          id
          name
          orpName
          regionCode
        }
        order {
          id
          orderNumber
          firstname
          lastname
          companyName
          gps {
            coordinates
          }
        }
      }
    }
  }
`

function createParamsForOrdersForDashboardQuery(orderContentState: string) {
  if (orderContentState === 'ActiveOrders') return { flags: 'ACTIVE_ORDERS', state: undefined }
  return { flags: undefined, state: orderContentState }
}

export function useOrdersForDashboardQuery(orderContentState: string, poolInterval: number) {
  return useQuery<{ orderContentList: OrderContentListResult }>(ordersForDashboardQuery, {
    variables: createParamsForOrdersForDashboardQuery(orderContentState),
    pollInterval: poolInterval
  })
}
