import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const deleteProductMaterialMutation = gql`
  mutation deleteProductMaterial($id: ID!) {
    deleteProductMaterial(id: $id) {
      status {
        reason
        result
      }
    }
  }
`

export function useDeleteProductMaterialMutation() {
  return useMutation<{ deleteProductMaterial: IMutationResult }>(deleteProductMaterialMutation, { refetchQueries: ['productMaterialList'] })
}
