import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const changeOwnerMutation = gql`
    mutation changeOwner($orderId: Int!, $loginId: Int) {
        changeOwner(orderId: $orderId, loginId: $loginId) {
            status {
                reason
                result
            }
        }
    }
`

export function useChangeOwnerMutation() {
    return useMutation<{ changeOwnerMutation: IMutationResult }>(changeOwnerMutation)
}