import { FormProvider, useForm } from 'react-hook-form'
import React from 'react'
import { useUpdateLoginMutation } from './services/updateLogin'
import { createParamsForCreateLoginMutation, useCreateLoginMutation } from './services/createLoginMutation'
import ToggleField from '../formComponents/ToggleField'
import { isAnyQueryLoading } from '../../services/functions/queryHelpers'
import PageCard from '../Cards/pageCard'
import FormField, { inputType } from '../formComponents/FormField'
import { LoginItemType } from '../../scenesAdmin/LoginList/services/loginsQuery'
import SupplierChooser from './components/SupplierChooser'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import { IStringObject } from '../../services/types/IStringObject'

export default function LoginEditor({
  loginId,
  loadData,
  onFinish,
  assignSupplierId
}: {
  loginId?: number
  loadData?: LoginItemType | IStringObject
  onFinish: Function
  assignSupplierId?: number
}) {
  const formMethods = useForm({ defaultValues: { ...loadData } })
  const [createLoginMut, createLoginQR] = useCreateLoginMutation()
  const [updateLoginMut, updateLoginQR] = useUpdateLoginMutation()

  return (
    <PageCard showLoader={isAnyQueryLoading(createLoginQR, updateLoginQR)}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
          <div className="row  mb-2">
            <div className="col-md-6">
              <FormField type={inputType.text} name={'firstname'} label={'Jméno'} registerOptions={{ required: false }} />
            </div>
            <div className="col-md-6">
              <FormField type={inputType.text} name={'lastname'} label={'Příjmení'} registerOptions={{ required: false }} />
            </div>
          </div>
          <div className="row  mb-2">
            <div className="col-md-12">
              <FormField type={inputType.email} name={'email'} label={'E-mail'} required registerOptions={{ required: true }} />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-12">
              <ToggleField
                name={'isMasterAdmin'}
                prependText={'Dodavatelský přístup'}
                label={'Administrátorský přístup Metrák.cz'}
                disabled={assignSupplierId !== undefined}
              />
            </div>
          </div>
          {!loginId && (
            <div className="col-md-12 mt-4">
              <ToggleField name={'sendNewAccountEmail'} label={'Po přidání zaslat uživateli email pro nastavení hesla'} />
            </div>
          )}
          {loginId && formMethods.watch('isMasterAdmin') === false && (
            <div className="row  mb-2">
              <div className="col-md-12">
                <SupplierChooser loadData={loadData?.loginToSupplier ?? []} loginId={loadData?.id} />
              </div>
            </div>
          )}
          <div className="text-right mt-4">
            <button type="submit" className="btn btn-primary ms-auto btn-lg">
              {loginId ? 'Uložit změny' : 'Přidat uživatele'}
            </button>
          </div>
        </form>
      </FormProvider>
    </PageCard>
  )

  function handleFormSubmit(data: Record<string | number | symbol, any>) {
    let resultPromise = loginId
      ? updateLoginMut({ variables: { loginId: loginId, ...createParamsForCreateLoginMutation(data) } })
      : createLoginMut({
          variables: {
            assignSupplierId,
            sendNewAccountEmail: data.sendNewAccountEmail,
            ...createParamsForCreateLoginMutation(data)
          }
        })

    processPageOperation({
      promise: resultPromise,
      successAction: () => {
        onFinish()
      }
    })
  }
}
