import { gql, useQuery } from '@apollo/client'
import { contextEndpoint, EndpointEnum } from '../../../../services/appConfiguration/contextEndpoint'

const wasteTypesQuery = gql`
  query wasteTypes {
    wasteTypes {
      id
      code
      name
    }
  }
`

export type WasteTypeItem = {
  id: number
  code: string
  name: string
}

export function useWasteTypes(isForSupplier?: boolean) {
  return useQuery<{ wasteTypes: WasteTypeItem[] }>(wasteTypesQuery, {
    context: contextEndpoint(isForSupplier ? EndpointEnum.supplier : EndpointEnum.admin)
  })
}
