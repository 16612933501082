import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import FormField, { inputType } from '../../../../components/formComponents/FormField'
import ToggleField from '../../../../components/formComponents/ToggleField'

export default function WorkingHours() {
  const { register, setValue, watch } = useFormContext()
  return (
    <>
      <div className="row mb-3">
        <div className="col-md-12">
          <h2>Pracovní doba</h2>
        </div>
        <div className="col-md-6">
          <table className="">
            <tbody>
              <tr>
                <th className={'pl-3 pb-3'}>Pracuje?</th>
                <td className={'pl-3 pb-3'}>Od</td>
                <td className={'pl-3 pb-3'}>Do</td>
              </tr>
              <DayRow index={0} day={'Mo'} dayLabel={'Pondělí'} />
              <DayRow index={1} day={'Tu'} dayLabel={'Úterý'} />
              <DayRow index={2} day={'We'} dayLabel={'Středa'} />
              <DayRow index={3} day={'Th'} dayLabel={'Čtvrtek'} />
              <DayRow index={4} day={'Fr'} dayLabel={'Pátek'} />
            </tbody>
          </table>
        </div>
        <div className="col-md-6">
          <table className="">
            <tbody>
              <tr>
                <th className={'pl-3 pb-3'}>Pracuje?</th>
                <td className={'pl-3 pb-3'}>Od</td>
                <td className={'pl-3 pb-3'}>Do</td>
                <td className={'pl-3 pb-3'}>Příplatek Kč</td>
              </tr>
              <DayRow index={5} day={'Sa'} dayLabel={'Sobota'} includeSurcharge />
              <DayRow index={6} day={'Su'} dayLabel={'Neděle'} includeSurcharge />
              <DayRow index={7} day={'Holiday'} dayLabel={'Svátky'} includeSurcharge />
              <tr>
                <th>&nbsp;</th>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <th>Dovolená</th>
                <td>
                  <FormField type={inputType.text} label="Datum od (včetně)" name={`vacations.0.vacationFrom`} maxLength={10} />
                </td>
                <td>
                  <FormField type={inputType.text} label="Datum do (včetně)" name={`vacations.0.vacationTo`} maxLength={10} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
  function DayRow({ day, dayLabel, includeSurcharge, index }: { day: string; dayLabel: string; includeSurcharge?: boolean; index: number }) {
    const switchValue = watch(`workingHours.${index}.isWorking`)
    register(`workingHours.${index}.isWorking`)
    return (
      <tr>
        <th>
          <input type="hidden" id={`workingHours.${index}.dayName`} value={day} {...register(`workingHours.${index}.dayName`)} />
          <label className="form-check form-switch d-inline-block">
            <input
              name={`workingHours${index}IsWorking`}
              type="checkbox"
              className="form-check-input"
              checked={switchValue}
              onChange={e => {
                setValue(`workingHours.${index}.isWorking`, e.target.checked)
              }}
            />
            <span className="form-check-label">{dayLabel}</span>
          </label>
        </th>
        <td>
          <FormField type={inputType.text} name={`workingHours.${index}.from`} maxLength={5} defaultValue={'07:00'} />
        </td>
        <td>
          <FormField type={inputType.text} name={`workingHours.${index}.to`} maxLength={5} defaultValue={'17:00'} />
        </td>
        {includeSurcharge && (
          <td>
            <FormField type={inputType.number} isMoney name={`workingHours.${index}.surcharge`} defaultValue={0} valueAsNumber />
          </td>
        )}
      </tr>
    )
  }
}
