import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'
import { supplierBranchListQuery } from '../../../queries/supplier-branch-list.query'

const upsertSupplierBranchMutation = gql`
  mutation upsertSupplierBranch($id: ID, $supplierId: String!, $data: SupplierBranchInputArgs!) {
    upsertSupplierBranch(id: $id, data: $data, supplierId: $supplierId) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpsertSupplierBranchMutation() {
  return useMutation<{ updateSupplierBranch: IMutationResult }>(upsertSupplierBranchMutation, { refetchQueries: [supplierBranchListQuery] })
}
