import { gql, useMutation } from '@apollo/client'
import {IMutationResult} from "../../../../../services/types/gqlTypes";
import {contextEndpoint, EndpointEnum} from "../../../../../services/appConfiguration/contextEndpoint";
import {getZpoDetailQuery} from "../query/getZpoDetailQuery";

const updateZpoDetailMutation = gql`
  mutation updateZpoDetail($orderContentId: Int!, $data: ZpoDetailInput!) {
    updateZpoDetail(orderContentId: $orderContentId, data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpdateZpoDetailMutation(orderContentId: number) {
  return useMutation<{ updateZpoDetail: IMutationResult }>(updateZpoDetailMutation, {
    context: contextEndpoint(EndpointEnum.admin),
    refetchQueries: result => [
      {
        query: getZpoDetailQuery,
        variables: { orderContentId },
        context: contextEndpoint(EndpointEnum.admin)
      }
    ]
  })
}
