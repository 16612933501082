import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import PageCard from '../../components/Cards/pageCard'
import { FormProvider, useForm } from 'react-hook-form'
import AddressPicker from '../../components/formComponents/AddressPicker/AddressPicker'
import FormField, { inputType } from '../../components/formComponents/FormField'
import ContainerSelector from '../../components/dataComponents/ContainerSelector/ContainerSelector'
import ProductsSelector, { ProductTypeEnum } from '../../components/dataComponents/ProductsSelector/ProductsSelector'
import DatePickerComp from '../../components/formComponents/datePickerComp'
import SupplierSelector from '../../components/dataComponents/SupplierSelector/SupplierSelector'
import TerminalSelector from '../../components/dataComponents/TerminalSelector/TerminalSelector'
import ToggleField from '../../components/formComponents/ToggleField'
import { useOrderDetailQuery } from './queries/orderDetailQuery'
import { getParamFromUrl } from '../../services/functions/getParamFromUrl'
import { createParamsForCreateOrderMutation, useCreateOrderMutation } from './mutations/createOrderMutation'
import { isAnyQueryLoading } from '../../services/functions/queryHelpers'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import { useUpdateOrderMutation } from './mutations/editOrderMutation'
import { WarningAlert } from '../../components/formComponents/alert'
import { addVatToPrice } from './services/addVatToPrice'
import { OrderContentTypesEnum } from '../../services/types/orderContentTypesEnum'
import SelectField from '../../components/formComponents/SelectField'
import { setupFields } from '../../services/formsServices/setupFields'
import { useReturnBackWithHighlight } from '../../services/pageHooks/highlightRowAfterReturn'
import { useCollectionProductsForLocalityQuery } from './queries/collectionProductsForLocalityQuery'
import OrderMap from './components/OrderMap/OrderMap'
import Customer from './components/Customer/customer'
import { containerTimesArrive, containerTimesLeave } from './services/containerTimes'
import { useContainerTypesForProductQuery } from './queries/containerTypesForProductQuery'
import { createParamsForCalculatePriceQuery, useCalculatePriceQuery } from './queries/calculatePriceQuery'
import { DataListItem, HtmlDataList } from '../../components/HtmlDataList'
import { getPriceSeparation, ProcessedOrderContentPrices, useGetPriceSeparationForCalc } from './services/getPriceSeparation'
import { useActivateDraftMutation } from './mutations/activateDraftMutation'
import { useNavigate } from 'react-router-dom'
import { createParamsForCalculateDeliveryPriceQuery, useCalculateDeliveryPriceQuery } from './queries/useCalculateDeliveryPriceQuery'
import { ModalContext } from '../../components/Modal/ModalProvider'
import PriceBreakUp from './components/PriceBreakUp/PriceBreakUp'
import { useOrderContentPriceItems } from './queries/orderContentPriceItemsQuery'
import { toast } from 'react-toastify'
import { useDeliveryProductsForLocalityQuery } from './queries/deliveryProductsForLocalityQuery'
import { decimalRound } from '../../services/functions/decimalRound'
import { paymentMethodsOptions } from '../../services/types/payment-method'
import dayjs from 'dayjs'
import { OrderContentPrices, OrderContentsResult } from '../../graphql/aminTypes'
import { isValidEmail } from '../../services/functions/is-email-validation'
import { useProducts } from './queries/productsQuery'
import AdvanceAccountInfo from './components/AdvanceAccount/AdvanceAccountInfo'
import OrderLock from './components/OrderLock/OrderLock'
import OrderComments from '../../components/order-comments/OrderComments'
import ReactRouterPrompt from 'react-router-prompt'
import { LeavePrompt } from './LeavePrompt'
import { SupplierInfo } from './components/SupplierInfo/SupplierInfo'
import { CustomerInfo } from './components/Customer/CustomerInfo'
import { requireCertificateStateOptions } from './services/requireCertificateStateOptions'

const handleKeyDown = event => {
  if (event.key === 'Enter') {
    event.preventDefault() // Prevent form submission on Enter key press
  }
}
export default function AddOrEditOrder() {
  const navigate = useNavigate()
  const { showModal, hideModal } = useContext(ModalContext)
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false)
  const orderNumber = getParamFromUrl('orderNumber') as string | null
  const action = getParamFromUrl('action') as string | null
  const isNewOrder = (action === 'duplicate' && !!orderNumber) || (!action && !orderNumber)

  const formMethods = useForm<Record<string, any>>({
    defaultValues: {
      createDraft: isNewOrder,
      paymentType: 'PAYMENT_GW',
      companyResidenceCountryIso: 'CZ',
      requireCertificateState: 0
    }
  })
  const orderDetailQR = useOrderDetailQuery(orderNumber, loadOrderData)
  const orderContentPriceItemsQR = useOrderContentPriceItems()
  const { getPriceSeparationForCalc } = useGetPriceSeparationForCalc()
  const [createOrderMut, createOrderMutQR] = useCreateOrderMutation()
  const [updateOrderMut, updateOrderMutQR] = useUpdateOrderMutation()
  const [activateDraftMut, activateDraftQR] = useActivateDraftMutation()
  const [visualPriceBreakUp, setVisualPriceBreakUp] = useState<DataListItem[] | undefined>(undefined)
  const [currentPriceBreakUp, setCurrentPriceBreakUp] = useState<ProcessedOrderContentPrices[] | undefined | null>(undefined)
  const totalWoVatKc = formMethods.watch('totalWoVatKc')
  const address = formMethods.watch('address')
  const isDraft = useRef(false)
  const { returnBack } = useReturnBackWithHighlight(isDraft.current ? '/order-list?state=Draft' : isNewOrder ? '/order-list' : undefined)
  const isForDelivery = formMethods.watch('isForDelivery')
  const isCashOnDelivery = formMethods.watch('paymentType') === 'COD'

  const supplierId: number = formMethods.watch('supplierId')
  const productId: number = formMethods.watch('productId')

  const collectionProductsForLocalityQR = useCollectionProductsForLocalityQuery(address?.lng, address?.lat, isForDelivery)
  const deliveryProductsForLocalityQR = useDeliveryProductsForLocalityQuery(address?.lng, address?.lat, !isForDelivery)
  const containerTypesForProductQR = useContainerTypesForProductQuery(address?.lng, address?.lat, productId)
  const calculatePriceQR = useCalculatePriceQuery()
  const calculateDeliveryPriceQR = useCalculateDeliveryPriceQuery()
  const productsQR = useProducts(productId)
  const tonToCubesForSelectedProduct = productsQR.data?.products[0]?.tonToCubes ? productsQR.data?.products[0]?.tonToCubes : undefined
  useEffect(() => {
    setShouldBlockNavigation(formMethods.formState.isDirty)
  }, [formMethods.formState.isDirty])

  useEffect(() => {
    const vat = addVatToPrice(totalWoVatKc)
    if (totalWoVatKc) formMethods.setValue('totalInclVatKc', vat.priceInclVatRounded)
  }, [totalWoVatKc])
  useEffect(() => {
    const volume = parseFloat(formMethods.watch('volumeM3'))
    if (tonToCubesForSelectedProduct && !isNaN(volume)) {
      const tonnes = decimalRound(volume * tonToCubesForSelectedProduct, 2)
      formMethods.setValue('weightTonnes', tonnes)
      if (tonnes > 8) toast.warning('Maximální hmotnost dopravcem je 8 tun. Zjistěte, zda je možno transportovat vyšší hmotnost.', { autoClose: false })
    }

    const input = window.document.getElementById('volumeM3') as HTMLInputElement
    if (input?.validity.stepMismatch) {
      input.step = '0.01'
    }
  }, [formMethods.watch('volumeM3')])

  useEffect(() => {
    if (tonToCubesForSelectedProduct) {
      const tonnes = decimalRound(tonToCubesForSelectedProduct * parseFloat(formMethods.watch('volumeM3')), 2)
      formMethods.setValue('weightTonnes', tonnes)
    }
  }, [tonToCubesForSelectedProduct])
  const orderPaid = orderDetailQR.data?.orderDetail.order.paymentStatus === 'PAID'
  useLayoutEffect(() => {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
  }, [])

  const supplierDisabled =
    !isNewOrder &&
    [
      'ConfirmedBySupplier',
      OrderContentTypesEnum.ContainerArrived,
      OrderContentTypesEnum.ContainerTakenBack,
      OrderContentTypesEnum.OrderFinishedBySupplier,
      OrderContentTypesEnum.OrderChargedByAdmin,
      OrderContentTypesEnum.ForSupplierToConfirm
    ].includes(orderDetailQR.data?.orderDetail?.orderContentState.sysName ?? '')
  const flatRateBreakupPrice = currentPriceBreakUp && currentPriceBreakUp.find(p => p.orderContentPriceItem.sysName === 'flatRate')
  return (
    <PageCard
      headerTitle={isNewOrder ? 'Zadání nové objednávky' : `Editace objednávky ${orderNumber}`}
      showLoader={isAnyQueryLoading(orderDetailQR, createOrderMutQR, updateOrderMutQR, calculatePriceQR, activateDraftQR)}
    >
      <ReactRouterPrompt when={shouldBlockNavigation}>
        {({ isActive, onConfirm, onCancel }) => <>{isActive && <LeavePrompt onConfirm={onConfirm} onCancel={onCancel} />}</>}
      </ReactRouterPrompt>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'} onKeyDown={handleKeyDown}>
          <div className="row">
            <div className="col-md-6">
              <h2>Adresa realizace:</h2>
              <AddressPicker name={'address'} required registerOptions={{ required: 'Poloha nebyla nalezena.' }} className="bg-orange-lt" />
              <div className="row">
                <div className="col col-is-6">
                  <h2>Objednávka:</h2>
                </div>
                <div className="col col-is-6 text-right">
                  <ToggleField
                    name={'isForDelivery'}
                    label="Přepnout na objednávku návozu materiálu"
                    disabled={!isNewOrder && !!orderDetailQR?.data?.orderDetail && !isDraft.current}
                    registerOptions={{ onChange: () => switchDeliveryAndCollection() }}
                  />
                </div>
              </div>

              <fieldset className="form-fieldset bg-pink-lt" disabled={!address || !address.lat}>
                <div className="row mb-2">
                  <div className={isForDelivery ? 'col-md-4' : 'col-md-6'}>
                    <ProductsSelector
                      productType={isForDelivery ? ProductTypeEnum.isDelivery : ProductTypeEnum.isCollection}
                      availableProductIds={
                        isForDelivery
                          ? deliveryProductsForLocalityQR.data?.deliveryProductsForLocality.map(x => x.id)
                          : collectionProductsForLocalityQR.data?.collectionProductsForLocality
                      }
                    />
                  </div>
                  {!isForDelivery && (
                    <div className="col-md-6">
                      <ContainerSelector allowedContainerIds={containerTypesForProductQR.data?.containerTypesForProduct.map(x => x.containerId)} />
                    </div>
                  )}
                  {isForDelivery && tonToCubesForSelectedProduct && (
                    <>
                      <div className="col-md-4">
                        <FormField
                          type={inputType.number}
                          name={'volumeM3'}
                          label={'Počet m³ (kubíků) - 1m³ = ' + tonToCubesForSelectedProduct?.toFixed(2) + ' t'}
                          isWeight
                          required
                          defaultValue={
                            orderNumber ? decimalRound((orderDetailQR.data?.orderDetail.weightTonnes ?? 1) / tonToCubesForSelectedProduct, 2).toString() : '1'
                          }
                        />
                      </div>
                      <div className="col-md-3">
                        <FormField
                          type={inputType.number}
                          name={'weightTonnes'}
                          label={'Počet t'}
                          is2Decimal
                          disabled
                          required
                          defaultValue={
                            orderNumber && orderDetailQR.data?.orderDetail.weightTonnes ? orderDetailQR.data?.orderDetail.weightTonnes.toString() : '1'
                          }
                        />
                      </div>
                      <div className="col-md-1">
                        <button type="button" onClick={enterWeightTonnes} className="btn btn-primary ms-auto mt-3">
                          t
                        </button>
                      </div>
                    </>
                  )}
                </div>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <DatePickerComp
                      id={'containerFromDate'}
                      name={'containerFromDate'}
                      required
                      registerOptions={{ required: true }}
                      label={'Datum přistavení'}
                      maxDate={formMethods.watch('containerToDate') ?? undefined}
                    />
                  </div>
                  <div className="col-md-6">
                    <SelectField required isCreatable name="arriveTime" label={'Čas přistavení kontejneru'} optionsData={containerTimesArrive} />
                  </div>
                </div>
                {!isForDelivery && (
                  <>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <DatePickerComp
                          id={'containerToDate'}
                          name={'containerToDate'}
                          label={'Datum odvozu'}
                          minDate={formMethods.watch('containerFromDate') ?? undefined}
                        />
                      </div>
                      <div className="col-md-6">
                        <SelectField isCreatable name="collectionTime" label={'Čas odvozu kontejneru'} optionsData={containerTimesLeave} />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <FormField
                        defaultValue={'1'}
                        isPositive
                        type={inputType.number}
                        name={'containerCount'}
                        label={'Počet kontejnerů'}
                        required
                        registerOptions={{
                          required: true,
                          min: 1,
                          max: 10
                        }}
                      />
                    </div>
                    <div className="row mb-3">
                      <FormField
                        type={inputType.text}
                        name={'wasteDescription'}
                        label={'Složení odpadu a jeho vznik'}
                        registerOptions={{
                          required: false,
                          min: 1,
                          max: 512
                        }}
                      />
                    </div>
                  </>
                )}
                <div className="row">
                  <div className="col-md-4">
                    <SelectField
                      required
                      name="paymentType"
                      label={'Platební metoda'}
                      optionsData={paymentMethodsOptions}
                      disabled={!isNewOrder && !isDraft.current}
                    />
                  </div>
                  <div className="col-md-8">
                    {isCashOnDelivery && (isNewOrder || (!isNewOrder && !orderPaid)) && (
                      <WarningAlert message={'Při ručním vyplnění nezapomeňte připočíst poplatek za dobírku a vyplnit částku kterou má vybrat dodavatel.'} />
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="justify-content-center d-flex">
                      <button
                        type="button"
                        onClick={isForDelivery ? () => fillBestSupplierAndPricesDelivery(false) : () => fillBestSupplierAndPricesCollection(false)}
                        className="btn btn-primary ms-auto"
                        disabled={
                          !productId ||
                          !formMethods.watch('containerFromDate') ||
                          (!isForDelivery && !formMethods.watch('containerId')) ||
                          (isForDelivery && !formMethods.watch('weightTonnes'))
                        }
                      >
                        Vyplnit nejlepšího dodavatele a spočítat ceny
                      </button>
                    </div>
                  </div>
                </div>
              </fieldset>

              <h2>Zakázka:</h2>
              <fieldset className="form-fieldset bg-lime-lt" disabled={!address || !address.lat}>
                <div className="row mb-2 mt-2">
                  <div className="col-md-6">
                    <SupplierSelector required disabled={supplierDisabled} withProductId={productId} includingDisabled />
                    <div className="d-flex">
                      {supplierId && (
                        <>
                          <a href={'/supplier-list?id=' + supplierId} target="_blank" rel="noopener noreferrer" className="pl-4 mt-2">
                            Zobrazit ceník <i className="fe fe-external-link" />
                          </a>
                          <button type={'button'} className={'m-2 ml-3'} onClick={recalcWithSelectedSupplier}>
                            Přepočítat s vybraným dodavatelem
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <TerminalSelector required={false} />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <FormField
                      isPositive
                      isDecimal
                      type={inputType.number}
                      name={'distanceTotalKm'}
                      label={'Celková vzdálenost [km]'}
                      registerOptions={{ min: 1, max: 500 }}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormField
                      isPositive
                      isMoney
                      type={inputType.number}
                      name={'totalForContentWoVatKc'}
                      label={'Celkem bez DPH za zakázku (bez dobírky)'}
                      registerOptions={{
                        required: true,
                        min: 0
                      }}
                    />
                  </div>
                </div>
                <fieldset className="form-fieldset bg-red-lt" disabled={!address || !address.lat}>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <FormField
                        isPositive
                        isMoney
                        type={inputType.number}
                        name={'totalWoVatKc'}
                        label={'Celkem bez DPH'}
                        registerOptions={{
                          required: true,
                          min: 0
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <FormField
                        type={inputType.number}
                        name={'totalInclVatKc'}
                        label={'Celkem s DPH'}
                        isPositive
                        isMoney
                        disabled
                        valueAsNumber
                        registerOptions={{
                          required: false,
                          min: 0
                        }}
                      />
                    </div>
                  </div>
                </fieldset>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <FormField
                      isPositive
                      isMoney
                      type={inputType.number}
                      name={'supplierProfitWoVatKc'}
                      label={'Výdělek pro dodavatele bez DPH'}
                      required
                      registerOptions={{
                        required: true,
                        min: 0
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormField
                      defaultValue={'0'}
                      isPositive
                      isMoney
                      type={inputType.number}
                      name={'codToCollect'}
                      label={'Vybrat dodavatelem dobírku v částce [Kč]'}
                      required
                      valueAsNumber
                      registerOptions={{
                        required: true,
                        min: 0
                      }}
                    />
                  </div>
                  {((isCashOnDelivery && formMethods.watch('totalInclVatKc') !== formMethods.watch('codToCollect')) ||
                    (!isCashOnDelivery && formMethods.watch('codToCollect') > 0)) && (
                    <div className="col-md-12 mt-2 p-1">
                      <WarningAlert message={'Zkontrolujte celkovou cenu a částku, která má být vybrána dodavatelem'} />
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <FormField type={inputType.text} name={'orderNote'} label={'Poznámka od zákazníka, viditelná i pro dodavatele'} />
                  </div>
                  <div className="col-md-12">
                    <FormField name={'adminNote'} label={'Poznámka pro administrátora'} isTextArea={true} textAreaRows={3} />
                  </div>
                  <div className="col-md-12">
                    <FormField type={inputType.text} name={'supplierNote'} label={'Poznámka pro dodavatele'} />
                  </div>
                  <div className="col-md-12">
                    <FormField type={inputType.text} name={'customerNote'} label={'Poznámka pro zákazníka'} />
                  </div>
                  <div className="col-md-12">
                    <FormField type={inputType.text} name={'invoicingNote'} label={'Poznámka pro fakturaci'} />
                  </div>
                  <div className="col-md-12">
                    <ToggleField name={'invoicingNoteDone'} label={'Poznámka k fakturaci zapracována'} disabled={!formMethods.watch('invoicingNote')} />
                  </div>
                  {!isForDelivery && (
                    <div className="col-md-4">
                      <SelectField name={'requireCertificateState'} label={'Váženka k objednávce'} optionsData={requireCertificateStateOptions} required />
                    </div>
                  )}
                </div>
              </fieldset>
              <Customer disabled={!address || !address.lat} className="bg-purple-lt" orderNumber={orderNumber} isNewOrder={isNewOrder} />
            </div>
            <div className="col-md-6">
              {address && address.lat && (
                <>
                  <OrderMap customerGps={address} supplierId={supplierId} isForDelivery={isForDelivery} />
                  <fieldset className="form-fieldset mt-3">
                    {visualPriceBreakUp && <HtmlDataList label="Rozpad ceny" data={visualPriceBreakUp} />}
                    <div className="row mb-2">
                      <div className="col-6">
                        <div className="justify-content-center d-flex">
                          <button
                            type="button"
                            onClick={showPriceBreakUpEditor}
                            className="btn btn-primary ms-auto"
                            disabled={!isNewOrder && currentPriceBreakUp?.length === 0}
                          >
                            Editovat cenový rozpad
                          </button>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="justify-content-center d-flex">
                          {!isForDelivery && !isNewOrder && !isDraft.current && flatRateBreakupPrice && flatRateBreakupPrice.priceWoVatKc > 0 && (
                            <button type="button" onClick={showPriceSplitEditor} className="btn btn-primary ms-auto">
                              Rozdělit cenu na koncovku
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </>
              )}
              <SupplierInfo supplierId={supplierId} />
              <CustomerInfo customerId={formMethods.watch('b2bCustomerId')} />
              {!isNewOrder && (
                <fieldset className="form-fieldset">
                  <header>
                    <h2>Komentáře</h2>
                  </header>
                  <OrderComments orderId={orderDetailQR.data?.orderDetail.order.id} />
                </fieldset>
              )}
              <AdvanceAccountInfo />
              {!isNewOrder && <OrderLock orderId={orderDetailQR?.data?.orderDetail?.order.id} />}
            </div>
          </div>
          <div className="card-footer" style={{ minHeight: '110px' }}>
            <div className="row">
              {formMethods.watch('ico') && !formMethods.watch('companyResidenceStreet') && (
                <WarningAlert message={'Není vyplněné sídlo, přestože známe IČO firmy'} />
              )}
              {isNewOrder && (
                <div className="col-md-1 mt-2">
                  <div className="mt-2">
                    <ToggleField name={'createDraft'} label={'Pouze draft'} />
                  </div>
                </div>
              )}
              <div className="col-md-2 mt-2">
                <button type="button" onClick={() => returnBack()} className="btn btn-primary ms-auto btn-lg">
                  Zpět bez uložení
                </button>
              </div>
              <div className="col-md-2 mt-2">
                <button type="submit" className="btn btn-primary ms-auto btn-lg">
                  {!!formMethods.watch('createDraft') ? 'Založit draft' : isNewOrder ? 'Vytvořit objednávku' : 'Uložit změny'}
                </button>
              </div>
              {!isNewOrder && isDraft.current && (
                <div className="col-md-2 mt-2">
                  <button type="button" className="btn btn-primary ms-auto btn-lg ml-2" onClick={activateDraftHandler}>
                    Aktivovat objednávku
                  </button>
                </div>
              )}
              {(isNewOrder || (!isNewOrder && isDraft.current)) && !formMethods.watch('createDraft') && (
                <div className="col-md-6">
                  <div className="justify-content-center d-flex">
                    <WarningAlert message="Bude odeslán email klientovi, při platbě kartou bude vytvořena platba a v případě automatického dodavatele na něj bude odeslána poptávka." />
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
    </PageCard>
  )

  function recalcWithSelectedSupplier() {
    if (!isForDelivery) fillBestSupplierAndPricesCollection(true)
    else fillBestSupplierAndPricesDelivery(true)
  }
  function showPriceBreakUpEditor() {
    showModal({
      title: 'SupplierSupplierEditor cenového rozpadu',
      modalContent: (
        <PriceBreakUp
          isForDelivery={isForDelivery}
          priceBreakUp={currentPriceBreakUp}
          orderContentPriceItems={orderContentPriceItemsQR.data?.orderContentPriceItems ?? []}
          handlePriceUpdate={handlePriceBreakUpUpdate}
        />
      ),
      onClose: hideModal,
      hideFooter: true
    })
  }

  function showPriceSplitEditor() {
    if (!formMethods.watch('terminalId')) {
      window.alert('Vyberte nejprve koncovku')
      return
    }
    showModal({
      title: 'Rozdělit cenu na dodavatele a koncovku ',
      modalContent: (
        <PriceBreakUp
          priceBreakUp={currentPriceBreakUp}
          orderContentPriceItems={orderContentPriceItemsQR.data?.orderContentPriceItems ?? []}
          handlePriceUpdate={handlePriceBreakUpUpdate}
          splitPriceWithTerminal
          originalTotalPrice={formMethods.watch('totalWoVatKc')}
          supplierId={supplierId}
        />
      ),
      onClose: hideModal,
      hideFooter: true
    })
  }

  function enterWeightTonnes() {
    if (!tonToCubesForSelectedProduct) return
    const weight = prompt('Zadejte váhu v tunách:')
    const weightNumber = parseFloat(weight?.replace(',', '.') ?? '')
    if (weight && !isNaN(weightNumber)) {
      formMethods.setValue('weightTonnes', weight)
      formMethods.setValue('volumeM3', decimalRound(weightNumber / tonToCubesForSelectedProduct, 2).toString())
    }
  }

  function handlePriceBreakUpUpdate(data: Record<string, string>) {
    if (!orderContentPriceItemsQR.data?.orderContentPriceItems || orderContentPriceItemsQR.data?.orderContentPriceItems.length === 0)
      throw new Error('Nejsou načteny cenové položky')
    const priceItems = orderContentPriceItemsQR.data?.orderContentPriceItems

    const items = Object.entries(data).map(([key, value]) => ({
      priceWoVatKc: parseFloat(value),
      orderContentPriceItem: priceItems.find(x => x.sysName === key)
    }))
    if (items.find(x => !x.orderContentPriceItem)) throw new Error('Nenalezen správný typ položky')
    setCurrentPriceBreakUp(items as OrderContentPrices[])
    setVisualPriceBreakUp(getPriceSeparation(items as OrderContentPrices[]))

    const forSupplier = priceItems!.filter(x => x.incomeForSupplier).map(x => x.sysName)
    const forTotalPrice = priceItems!.filter(x => x.partOfTotalPrice).map(x => x.sysName)
    const forTotalPriceWoCOD = priceItems!.filter(x => x.partOfTotalPrice && x.sysName !== 'COD').map(x => x.sysName)
    formMethods.setValue(
      'supplierProfitWoVatKc',
      items
        .filter(x => forSupplier.includes(x.orderContentPriceItem!.sysName))
        .reduce((previousValue, currentValue) => previousValue + currentValue.priceWoVatKc, 0)
    )

    const newTotalWoVatKc = items
      .filter(x => forTotalPrice.includes(x.orderContentPriceItem!.sysName))
      .reduce((previousValue, currentValue) => previousValue + currentValue.priceWoVatKc, 0)

    formMethods.setValue('totalWoVatKc', newTotalWoVatKc)
    const vat = addVatToPrice(newTotalWoVatKc)
    formMethods.setValue('totalInclVatKc', vat.priceInclVatRounded)

    formMethods.setValue(
      'totalForContentWoVatKc',
      items
        .filter(x => forTotalPriceWoCOD.includes(x.orderContentPriceItem!.sysName))
        .reduce((previousValue, currentValue) => previousValue + currentValue.priceWoVatKc, 0)
    )
    if (isCashOnDelivery || items.findIndex(f => f.orderContentPriceItem!.sysName === 'COD') >= 0) {
      formMethods.setValue('codToCollect', vat.priceInclVatRounded)
      formMethods.setValue('paymentType', 'COD')
    } else {
      formMethods.setValue('codToCollect', 0)
    }
    hideModal()
  }

  function switchDeliveryAndCollection() {
    if (formMethods.watch('isForDelivery')) {
      formMethods.unregister('containerId')
      formMethods.unregister('containerToDate')
      formMethods.unregister('containerCount')
      formMethods.register('weightTonnes', { required: true })
      formMethods.register('volumeM3', { required: true })
    } else {
      formMethods.register('containerId', { required: true })
      formMethods.register('containerToDate')
      formMethods.register('containerCount', { required: true })
      formMethods.unregister('weightTonnes')
      formMethods.unregister('volumeM3')
    }
  }

  function handleFormSubmit(data: Record<string | number | symbol, any>) {
    saveCurrentEdits(data, undefined)
  }

  function activateDraftHandler() {
    if (Object.keys(formMethods.formState.errors).length > 0) {
      toast.error('Formulář obsahuje chyby')
      return
    }
    saveCurrentEdits(formMethods.getValues(), activateDraft)
  }

  function activateDraft() {
    const orderId = orderDetailQR?.data?.orderDetail?.order.id
    if (!orderId) {
      toast.error('Nelze zjistit ID tohoto draftu.')
      return
    }
    const resultPromise = activateDraftMut({ variables: { orderId: orderId } })

    processPageOperation({
      promise: resultPromise,
      successAction: () => navigate('/order-list')
    })

    return false
  }

  function saveCurrentEdits(data: Record<string | number | symbol, any>, successAction?: () => void) {
    setShouldBlockNavigation(false)
    if (isForDelivery && !tonToCubesForSelectedProduct) {
      toast.error('Není vybrán produkt pro návoz')
      return
    }
    if (
      data.paymentType === 'COD' &&
      data.codToCollect <= 0 &&
      !window.confirm('Je zvolena platba dobírkou, ale částka k vybrání dodavatelem není vyplněna. Chcete pokračovat?')
    )
      return
    if (data.paymentType === 'COD' && data.codToCollect !== formMethods.watch('totalInclVatKc')) {
      toast.error(`Pokud je zvolena platba dobírkou, musí být částka k vybrání dodavatelem stejná jako celková cena`)
      return
    }
    if (data.paymentType !== 'COD' && data.codToCollect !== 0) {
      toast.error('Pokud není zvolena platba dobírkou, musí být částka k vybrání dodavatelem 0')
      return
    }
    if (
      data.paymentType !== 'COD' &&
      data.codToCollect > 0 &&
      !window.confirm('Je vyplněna částka k vybrání dodavatelem, ale není vybrána platba dobírkou. Chcete pokračovat?')
    )
      return
    if (
      !isNewOrder &&
      !isDraft.current &&
      orderDetailQR.data?.orderDetail &&
      !['INVOICE', 'ADVANCE_INVOICE'].includes(data.paymentType) &&
      parseFloat(formMethods.watch('totalWoVatKc')) !== orderDetailQR.data?.orderDetail.order.totalWoVatKc &&
      !window.confirm(
        'Byla provedena úprava ceny, opravdu chcete pokračovat? Lze pouze pokud nebylo zaplaceno a budou upraveny již vytvořené dokumenty a odeslán email zákazníkovi.'
      )
    )
      return

    if (
      !(isNewOrder && formMethods.watch('createDraft')) &&
      !isDraft.current &&
      data.email
        .replace(',', ';')
        .split(';')
        .some(email => !isValidEmail(email))
    ) {
      toast.error('Email (jeden z emailů) ' + data.email + ' není správně zadán')
      return
    }

    const orderId = orderDetailQR?.data?.orderDetail?.order.id

    if (orderPaid) data['totalForContentWoVatKc'] = formMethods.watch('totalForContentWoVatKc')
    if (orderPaid) data['totalWoVatKc'] = formMethods.watch('totalWoVatKc')
    let resultPromise =
      orderId && !isNewOrder
        ? updateOrderMut({ variables: { orderId: orderId, ...createParamsForCreateOrderMutation(data, currentPriceBreakUp, tonToCubesForSelectedProduct) } })
        : createOrderMut({
            variables: {
              isDraft: !!formMethods.watch('createDraft'),
              ...createParamsForCreateOrderMutation(data, currentPriceBreakUp, tonToCubesForSelectedProduct)
            }
          })

    processPageOperation({
      promise: resultPromise,
      successAction: successAction ? successAction : returnBack
    })
  }

  function loadOrderData(data: { orderDetail: OrderContentsResult }) {
    const orderContent = data.orderDetail
    const orderData = orderContent.order
    isDraft.current = orderContent.orderContentState.sysName === 'Draft'

    formMethods.setValue('isForDelivery', orderContent.serviceType === 'MATERIAL_DELIVERY')
    switchDeliveryAndCollection()
    setupFields(orderData, {
      includeKeys: [
        'b2bCustomerId',
        'firstname',
        'lastname',
        'email',
        'phone',
        'ico',
        'dic',
        'orderNote',
        'totalWoVatKc',
        'totalInclVatKc',
        'companyName',
        'targetCompanyName',
        'targetCompanyIco',
        'notificationSettings',
        'paymentType',
        'companyCountryIso',
        ...(action === 'duplicate' ? [] : ['orderNote', 'invoicingNote', 'invoicingNoteDone', 'requireCertificateState'])
      ],
      formMethods
    })
    if (action === 'duplicate' && orderData.requireCertificateState === 2) formMethods.setValue('requireCertificateState', 1)
    if (orderData.companyResidence) {
      formMethods.setValue('companyResidenceStreet', orderData.companyResidence.street ?? '')
      formMethods.setValue('companyResidenceStreetNumber', orderData.companyResidence.streetNumber ?? '')
      formMethods.setValue('companyResidenceZip', orderData.companyResidence.zip ?? '')
      formMethods.setValue('companyResidenceCity', orderData.companyResidence.city ?? '')
      formMethods.setValue('companyResidenceCountryIso', orderData.companyResidence.countryIso ?? '')
    }
    formMethods.setValue('address', {
      lng: orderData.gps.coordinates[0].toString(),
      lat: orderData.gps.coordinates[1].toString(),
      street: orderData.street,
      streetNumber: orderData.streetNumber,
      city: orderData.city,
      zip: orderData.zip
    })

    setupFields(orderContent, {
      includeKeys: [
        'serviceType',
        'collectionTime',
        'arriveTime',
        'containerCount',
        'weightTonnes',
        'distanceTotalKm',
        'codToCollect',
        'totalForContentWoVatKc',
        'supplierProfitWoVatKc',
        'onsiteContactFirstname',
        'onsiteContactLastname',
        'onsiteContactPhone',
        ...(action === 'duplicate' ? [] : ['adminNote', 'supplierNote', 'customerNote', 'wasteDescription'])
      ],
      formMethods
    })
    formMethods.setValue('productId', orderContent.product.id)

    if (orderContent.container) formMethods.setValue('containerId', orderContent.container.id)
    if (orderContent.supplier) formMethods.setValue('supplierId', orderContent.supplier.id)
    if (orderContent.terminal) formMethods.setValue('terminalId', orderContent.terminal.id)
    if (!isNewOrder) formMethods.setValue('containerFromDate', dayjs(orderContent.containerFromDate).toDate())
    if (orderContent.containerToDate && !isNewOrder) formMethods.setValue('containerToDate', dayjs(orderContent.containerToDate).toDate())
    setVisualPriceBreakUp(getPriceSeparation(orderContent.orderContentPrices))
    setCurrentPriceBreakUp(orderContent.orderContentPrices)
  }

  async function fillBestSupplierAndPricesCollection(forceSupplier?: boolean) {
    const containerId = formMethods.watch('containerId')
    const containerFromDate = formMethods.watch('containerFromDate')
    const containerToDate = formMethods.watch('containerToDate')
    const paymentType = formMethods.watch('paymentType')
    const containerCount = formMethods.watch('containerCount')
    const arriveTime = formMethods.watch('arriveTime')
    const collectionTime = formMethods.watch('collectionTime')
    const b2bCustomerId = formMethods.watch('b2bCustomerId')
    const forcedSupplierId = formMethods.watch('supplierId')
    setVisualPriceBreakUp(undefined)

    calculatePriceQR
      .refetch(
        createParamsForCalculatePriceQuery(
          address.lng,
          address.lat,
          paymentType === 'COD',
          [
            {
              productId: productId,
              containerId: containerId,
              fromDate: dayjs(containerFromDate).toISOString(),
              toDate: containerToDate ? dayjs(containerToDate).toISOString() : undefined,
              count: parseInt(containerCount),
              arriveTime,
              collectionTime
            }
          ],
          b2bCustomerId,
          forceSupplier ? forcedSupplierId : undefined
        )
      )
      .then(result => {
        if (result.data && result.data.calculatePrice?.routeSpecification) {
          formMethods.setValue('supplierId', result.data.calculatePrice.routeSpecification.bestSupplierId)
          formMethods.setValue('terminalId', result.data.calculatePrice.routeSpecification.bestTerminalId)
        }
        if (result.data && result.data.calculatePrice?.totalPrice) {
          const totalPrice = result.data.calculatePrice?.totalPrice
          formMethods.setValue('distanceTotalKm', result.data.calculatePrice.routeSpecification.distanceKm)

          formMethods.setValue('totalInclVatKc', totalPrice.totalPriceKcInclVat)
          formMethods.setValue('totalWoVatKc', totalPrice.totalPriceKcWoVat)
          formMethods.setValue(
            'totalForContentWoVatKc',
            totalPrice.containerPrices[0].basePriceKcWoVat +
              totalPrice.containerPrices[0].additionalFees.reduce((previousValue, currentValue) => previousValue + currentValue.priceKcWoVat, 0.0)
          )
          formMethods.setValue('codToCollect', paymentType === 'COD' ? totalPrice.totalPriceKcInclVat : 0)
          formMethods.setValue('supplierProfitWoVatKc', totalPrice.containerPrices[0].supplierProfitWoVatKc)
          const priceBreakupSeparation = getPriceSeparationForCalc(
            totalPrice.containerPrices[0].basePriceParts,
            totalPrice.additionalFees,
            totalPrice.containerPrices[0].additionalFees,
            isForDelivery
          )

          setCurrentPriceBreakUp(priceBreakupSeparation.parsedData)
          setVisualPriceBreakUp(priceBreakupSeparation.visualResult)
        }
      })
      .catch(error => toast.error(error.toString()))
  }

  async function fillBestSupplierAndPricesDelivery(forceSupplier?: boolean) {
    const weightTonnes = formMethods.watch('weightTonnes')
    const containerFromDate = formMethods.watch('containerFromDate')
    const paymentType = formMethods.watch('paymentType')
    const b2bCustomerId = formMethods.watch('b2bCustomerId')
    const forcedSupplierId = formMethods.watch('supplierId')
    setVisualPriceBreakUp(undefined)

    try {
      const result = await calculateDeliveryPriceQR.refetch(
        createParamsForCalculateDeliveryPriceQuery(
          address.lng,
          address.lat,
          productId,
          weightTonnes,
          paymentType === 'COD',
          containerFromDate ? dayjs(containerFromDate).toISOString() : undefined,
          b2bCustomerId,
          forceSupplier ? forcedSupplierId : undefined
        )
      )

      if (result.data && result.data.calculateDeliveryPrice) {
        formMethods.setValue('supplierId', result.data.calculateDeliveryPrice.supplierId)
        formMethods.setValue('distanceTotalKm', result.data.calculateDeliveryPrice.distanceKm)
        const totalPrice = result.data.calculateDeliveryPrice.totalPrice

        formMethods.setValue('totalInclVatKc', totalPrice.totalPriceKcInclVat)
        formMethods.setValue('totalWoVatKc', totalPrice.totalPriceKcWoVat)
        formMethods.setValue(
          'totalForContentWoVatKc',
          totalPrice.containerPrices[0].basePriceKcWoVat +
            totalPrice.containerPrices[0].additionalFees.reduce((previousValue, currentValue) => previousValue + currentValue.priceKcWoVat, 0.0)
        )
        formMethods.setValue('codToCollect', paymentType === 'COD' ? totalPrice.totalPriceKcInclVat : 0)
        formMethods.setValue('supplierProfitWoVatKc', totalPrice.containerPrices[0].supplierProfitWoVatKc)
        const priceBreakupValues = getPriceSeparationForCalc(
          totalPrice.containerPrices[0].basePriceParts,
          totalPrice.additionalFees,
          totalPrice.containerPrices[0].additionalFees,
          isForDelivery
        )
        setVisualPriceBreakUp(priceBreakupValues.visualResult)
        setCurrentPriceBreakUp(priceBreakupValues.parsedData)
      }
      if (parseFloat(weightTonnes) > 10) window.alert('Pozor, váha je vyšší než 10 tun, zkontrolujte zda je cena správně vypočítaná.')
    } catch (error: any) {
      toast.error(error.toString())
    }
  }
}
