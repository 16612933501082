import { gql, useQuery } from '@apollo/client'

export const searchWholeDbQuery = gql`
  query searchWholeDb($searchString: String) {
    searchWholeDb(searchString: $searchString) {
      id
      tableName
    }
  }
`

export type SearchWholeDbResult = {
  id: number
  tableName: string
}

export function useSearchWholeDbQuery(searchString: String) {
  return useQuery<{ searchWholeDb: SearchWholeDbResult[] }>(searchWholeDbQuery, {
    fetchPolicy: 'network-only',
    variables: { searchString },
    skip: !searchString
  })
}
