import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const duplicateSupplierBranchMutation = gql`
  mutation duplicateSupplierBranch($branchId: ID!) {
    duplicateSupplierBranch(branchId: $branchId) {
      status {
        reason
        result
      }
    }
  }
`

export function useDuplicateSupplierBranchMutation() {
  return useMutation<{ duplicateSupplierBranch: IMutationResult }>(duplicateSupplierBranchMutation, { refetchQueries: ['supplierBranchList'] })
}
