import PageCard from '../../components/Cards/pageCard'
import React, { useContext } from 'react'
import useBootstrapTableDataMapper from '../../services/bootstrapTable/useBootstrapTableDataMapper'
import { hasQueryArrayData, isAnyQueryLoading } from '../../services/functions/queryHelpers'
import BootstrapTable from 'react-bootstrap-table-next'
import usePagingFromURLQuery from '../../services/bootstrapTable/usePagingFromURLQuery'
import useBootstrapTablePagingAndFiltration from '../../services/bootstrapTable/useBootstrapTablePagingAndFiltration'
// @ts-ignore
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import { TerminalItemType, useTerminalsQuery } from './services/terminalsQuery'
import { useTerminalsCountQuery } from './services/terminalsCount'
import { terminalListDataDefinition } from './services/terminalListDataDefinition'
import { pageCardHeaderButton } from '../../components/Cards/pageCardHeaderButton'
import { useNavigate } from 'react-router-dom'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import { useDeleteTerminalMutation } from './services/deleteTerminalMutation'
import { useUpdateTerminalDisabledMutation } from './services/updateTerminalDisabledMutation'
import { useFilterOptions4CustomSelectCreator } from '../../services/bootstrapTable/useFilterOptions4CustomSelectCreator'
import { BootstrapTablePagingWrapper } from '../../components/bootstrapTable/BootstrapTablePagingWrapper'
import { ModalContext } from '../../components/Modal/ModalProvider'
import TerminalPriceAndMaterialListEditor from './components/TerminalPriceAndMaterialEditor'

export default function TerminalList() {
  const navigate = useNavigate()
  const { generateColumnListForBootstrapTable, mapDataArrayToTable, nameForGqlTranslation } =
    useBootstrapTableDataMapper<TerminalItemType>(terminalListDataDefinition)
  const { pageConfiguration, gqlQueryParams } = usePagingFromURLQuery(['id', 'name', 'disabled'], nameForGqlTranslation, { defaultPageSize: 100 })
  const { showModal } = useContext(ModalContext)

  const terminalsQR = useTerminalsQuery(gqlQueryParams)
  const terminalsCountQR = useTerminalsCountQuery(gqlQueryParams)
  const [deleteTerminalMut, deleteTerminalQR] = useDeleteTerminalMutation()
  const [updateTerminalDisabledMut, updateTerminalDisabledQR] = useUpdateTerminalDisabledMutation()
  const { bootstrapTablePaginationSetup, handleTableChange } = useBootstrapTablePagingAndFiltration(pageConfiguration, terminalsCountQR)
  const filterOptions = useFilterOptions4CustomSelectCreator()
  filterOptions.addFilterOption(
    'disabled',
    [
      { key: 'false', value: 'Aktivní' },
      { key: 'true', value: 'Neaktivní' }
    ],
    (item: any) => item.key,
    (item: any) => item.value
  )
  const columnDefinition = generateColumnListForBootstrapTable({
    columnAction: handleTableClick,
    filterDefaultValues: pageConfiguration.filterBy,
    currentSort: pageConfiguration.sort,
    filterOptions4CustomSelectCreator: filterOptions,
    handleTableChange: handleTableChange,
    actionsReference: {
      deleteTerminal: deleteTerminalHandler,
      enableTerminal: enableTerminalHandler,
      disableTerminal: disableTerminalHandler
    }
  })

  let terminalsData: any[] = []
  if (terminalsQR.data && hasQueryArrayData(terminalsQR)) terminalsData = mapDataArrayToTable(terminalsQR.data.terminals)

  return (
    <PageCard
      showLoader={isAnyQueryLoading(terminalsQR, terminalsCountQR, updateTerminalDisabledQR, deleteTerminalQR)}
      headerTitle={'Koncovky'}
      cardOptions={pageCardHeaderButton('Přidat', () => navigate('/add-terminal'), 'addTerminal')}
    >
      <PaginationProvider pagination={paginationFactory(bootstrapTablePaginationSetup)}>
        {({ paginationProps, paginationTableProps }: { paginationProps: any; paginationTableProps: any }) => (
          <div className="table-responsive-xl">
            <BootstrapTablePagingWrapper paginationProps={paginationProps} paginationTableProps={paginationTableProps}>
              <BootstrapTable
                bootstrap4
                striped
                hover
                condensed
                remote
                classes="table-responsive-lg digiTable"
                noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné záznamy"</>}
                keyField="id"
                data={terminalsData}
                columns={columnDefinition}
                filter={filterFactory()}
                filterPosition={'inline'}
                sort={pageConfiguration.sort}
                onTableChange={handleTableChange}
                {...paginationTableProps}
              />
            </BootstrapTablePagingWrapper>
          </div>
        )}
      </PaginationProvider>
    </PageCard>
  )

  function handleTableClick(e: Event, column: number, columnIndex: number, row: any) {
    if (columnIndex !== 7)
      showModal({
        title: 'Upravit ceník pro koncovku ' + row['name'],
        modalContent: <TerminalPriceAndMaterialListEditor terminalId={row['id']} />,
        hideFooter: true
      })
  }

  function deleteTerminalHandler(row: any) {
    if (!row.id) return
    if (window.confirm(`Opravdu zrušit dodavatele ${row.name}?`)) {
      const deleteTerminalPromise = deleteTerminalMut({ variables: { terminalId: row.id } })
      processPageOperation({
        promise: deleteTerminalPromise,
        successMessage: `Dodavatel '${row.name}' byl odstraněn.`,
        successAction: result => {
          terminalsQR.refetch()
          terminalsCountQR.refetch()
        }
      })
    }
  }

  function disableTerminalHandler(row: any) {
    if (!row.id) return
    switchTerminalHandler(row.id, true)
  }

  function enableTerminalHandler(row: any) {
    if (!row.id) return
    switchTerminalHandler(row.id, false)
  }

  function switchTerminalHandler(terminalId: number, disabled: boolean) {
    const updateTerminalDisabledPromise = updateTerminalDisabledMut({ variables: { terminalId, disabled } })
    processPageOperation({
      promise: updateTerminalDisabledPromise,
      successMessage: `Koncovce byla změněna dostupnost`,
      successAction: result => {
        terminalsQR.refetch()
        terminalsCountQR.refetch()
      }
    })
  }
}
