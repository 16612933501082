import { moneyToLocaleString } from '../../../services/dataToString/decimalToLocaleString'

export function SelectedTotals({ totals }: { totals: { woVat: number; withVat: number } }) {
  return (
    <div className="row">
      <div className="col-md-6">
        Celkem bez DPH: <strong>{moneyToLocaleString(totals.woVat, 'CZK')}</strong>
      </div>
      <div className="col-md-6">
        Celkem s DPH: <strong>{moneyToLocaleString(totals.withVat, 'CZK')}</strong>
      </div>
    </div>
  )
}
