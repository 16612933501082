export const cacheTypePolicies = {
  FileList: {
    keyFields: ['filename']
  },
  listOrderContentFiles: {
    keyFields: ['filename']
  },
  suppliers: {
    keyFields: ['id']
  },
  Supplier: {
    keyFields: ['id']
  },
  SupplierPriceList: {
    keyFields: ['id']
  },
  orderContentList: {
    keyFields: ['id']
  },
  OrderContentsResult: {
    keyFields: ['id']
  },
  OrderResult: {
    keyFields: ['id']
  }
}
