export type PaymentMethod = 'COD' | 'PAYMENT_GW' | 'INVOICE' | 'PROFORMA_INVOICE' | 'BANK_TRANSFER' | 'ADVANCE_PAYMENT' | 'INVOICE_ADVANCE'

export const paymentMethodsMap = {
  COD: 'Dobírka',
  PAYMENT_GW: 'GoPay',
  INVOICE: 'Faktura',
  PROFORMA_INVOICE: 'Proforma faktura',
  BANK_TRANSFER: 'Převodem',
  ADVANCE_PAYMENT: 'Záloha',
  INVOICE_ADVANCE: 'Faktura předem'
}

export const paymentMethodsList = Object.keys(paymentMethodsMap) as PaymentMethod[]

export const paymentMethodsOptions = Object.keys(paymentMethodsMap).map(key => ({
  label: paymentMethodsMap[key],
  value: key as PaymentMethod
}))
export const paymentMethodBadgeColors = {
  Dobírka: 'bg-info',
  GoPay: 'bg-dark-beige',
  Převodem: 'bg-secondary',
  Faktura: 'bg-success',
  'Proforma faktura': 'bg-yellow',
  Záloha: 'bg-success',
  'Faktura předem': 'bg-blue-lt'
}
