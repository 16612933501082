import { gql, useQuery } from '@apollo/client'

const statisticsContainersQuery = gql`
  query statisticsContainers($fromDate: String, $toDate: String){
    statisticsContainers(fromDate: $fromDate, toDate: $toDate){
      capacityM3
      count
      name
    }
  }
`

export function useStatisticsContainersQuery(params?: {fromDate?: string, toDate?: string}) {
  return useQuery<{ statisticsContainers: StatisticsContainersType[] }>(statisticsContainersQuery, {variables: params})
}

export type StatisticsContainersType = {
  name: string
  capacityM3: number
  count: number
}
