import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'

const deleteSupplierToLoginMutation = gql`
  mutation deleteSupplierToLogin($supplierToLoginId: Int!) {
    deleteSupplierToLogin(supplierToLoginId: $supplierToLoginId) {
      id
      status {
        reason
        result
      }
    }
  }
`
export function useDeleteSupplierToLoginMutation() {
  return useMutation<{ deleteSupplierToLogin: IMutationResult }>(deleteSupplierToLoginMutation)
}

