import { gql, useQuery } from '@apollo/client'
import { FioSyncSettings } from '../../../../../../graphql/aminTypes'

export const getFioSyncSettingsQuery = gql`
  query getFioSyncSettings {
    getFioSyncSettings {
      allowAutoPaymentPaired
      allowScheduledSync
    }
  }
`

export function useGetFioSyncSettingsQuery() {
  return useQuery<{ getFioSyncSettings: FioSyncSettings }>(getFioSyncSettingsQuery, {
    fetchPolicy: 'network-only'
  })
}
