import { useFilterOptions4CustomSelectCreator } from '../../../services/bootstrapTable/useFilterOptions4CustomSelectCreator'
import { useOrderContentStatesQuery } from '../queries/order-content-states.query'
import { useSuppliersQuery } from '../queries/suppliers.query'
import { useProducts } from '../../../components/dataComponents/ProductsSelector/services/productsQuery'
import { useContainerTypesQuery } from '../../../services/queries/containersQuery'

export function usePrepareFilterOptions() {
  const orderContentStatesQR = useOrderContentStatesQuery()
  const productsQR = useProducts()
  const containersQR = useContainerTypesQuery()
  const suppliersQR = useSuppliersQuery()

  const filterOptions = useFilterOptions4CustomSelectCreator()
  if (orderContentStatesQR.data?.orderContentStates && !filterOptions.getFilterOption('state'))
    filterOptions.addFilterOption(
      'state',
      orderContentStatesQR.data.orderContentStates.filter(x => x.sysName !== 'Initial').map(x => ({ key: x.sysName, value: x.name })),
      (item: any) => item.key,
      (item: any) => item.value
    )
  if (suppliersQR.data?.suppliers && !filterOptions.getFilterOption('supplier'))
    filterOptions.addFilterOption(
      'supplier',
      suppliersQR.data.suppliers.map(x => ({ key: x.id.toString(), value: x.name })),
      (item: any) => item.key,
      (item: any) => item.value
    )
  if (productsQR.data?.products && !filterOptions.getFilterOption('products'))
    filterOptions.addFilterOption(
      'product',
      [...productsQR.data.products].map(x => ({
        key: x.id.toString(),
        value: x.name
      })),
      (item: any) => item.key,
      (item: any) => item.value
    )
  if (containersQR.data?.containerTypes && !filterOptions.getFilterOption('container'))
    filterOptions.addFilterOption(
      'container',
      [...containersQR.data.containerTypes]
        .sort((a, b) => a.uiOrder - b.uiOrder)
        .map(x => ({
          key: x.id.toString(),
          value: `${x.name} ${x.capacityM3}m³`
        })),
      (item: any) => item.key,
      (item: any) => item.value
    )
  filterOptions.addFilterOption(
    'paymentStatus',
    [
      { key: 'UNPAID', value: 'Čeká na platbu' },
      { key: 'PAID', value: 'Zaplaceno' },
      { key: 'REFUNDED', value: 'Vráceno' },
      { key: 'GP_CREATED', value: 'GP Vytvořena' },
      { key: 'GP_PAID', value: 'GP Zaplaceno' },
      { key: 'GP_TIMEOUTED', value: 'GP Vypršela' },
      { key: 'GP_PAYMENT_METHOD_CHOSEN', value: 'GP Převodem' },
      { key: 'GP_CANCELED', value: 'GP Zrušeno' }
    ],
    (item: any) => item.key,
    (item: any) => item.value
  )
  filterOptions.addFilterOption(
    'paymentType',
    [
      { key: 'PAYMENT_GW', value: 'GoPay' },
      { key: 'COD', value: 'Dobírka' },
      { key: 'BANK_TRANSFER', value: 'Převodem' },
      { key: 'INVOICE', value: 'Faktura' },
      { key: 'PROFORMA_INVOICE', value: 'Proforma faktura' },
      { key: 'ADVANCE_PAYMENT', value: 'Záloha' },
      { key: 'INVOICE_ADVANCE', value: 'Faktura předem' }
    ],
    (item: any) => item.key,
    (item: any) => item.value
  )
  filterOptions.addFilterOption(
    'flags',
    [
      { key: 'CERT_REQ', value: 'S žádostí o potvrzení o odvozu odpadu' },
      { key: 'CERT_DONE', value: 'S potvrzením o odvozu odpadu' },
      { key: 'BY_B2B', value: 'Pro registrované zákazníky' },
      { key: 'WO_FINALS', value: 'Bez váženky' },
      { key: 'INCL_FINALS', value: 'S váženkou' },
      { key: 'MANUAL_ORDER', value: 'Ruční objednávka' },
      { key: 'ACTIVE_ORDERS', value: 'Aktivní objednávky' },
      { key: 'INVOICING_NOTE_NOT_DONE', value: 'S fakturační poznámkou k zapracování' },
      { key: 'INVOICING_NOTE_DONE', value: 'S fakturační poznámkou hotovou' },
      { key: 'ORDERS_WO_INVOICE', value: 'Objednávky bez faktury' },
      { key: 'PRICE_LIMIT_ORDERS', value: 'S cenovým stropem' },
      { key: 'WITH_GROUP_INVOICE', value: 'S hromadnou fakturou' },
      { key: 'WITH_ZPO_NOTE', value: 'Dodavatel s poznámkou k ZPO' }
    ],
    (item: any) => item.key,
    (item: any) => item.value
  )

  return { filterOptions }
}
