import { AccountingAdvanceAccountTransactionEntity, AccountingDocumentEntity } from '../../../../graphql/aminTypes'
import { ColumnFormatterTypes, TableDefinitionTypes } from '../../../../services/bootstrapTable/services/tableSpecificationTypes'
import { formatDateTimeForDisplayShort } from '../../../../services/dataToString/dateTimeFormatter'

export const advanceAccountsTransactionsDataDefinition: TableDefinitionTypes<AccountingAdvanceAccountTransactionEntity> = {
  columnDefinition: {
    id: { hidden: true },
    createdAt: {
      name: 'Datum zaúčtování'
    },
    type: {
      name: 'Typ'
    },
    amountInclVatKc: {
      name: 'Částka s DPH',
      columnFormatterType: ColumnFormatterTypes.moneyFormatter
    },
    newBalanceKc: {
      name: 'Zůstatek po zaúčtování',
      columnFormatterType: ColumnFormatterTypes.moneyFormatter
    },
    fioTransactionId: {
      name: 'Fio ID'
    },
    order: {
      name: 'Objednávka'
    },
    description: {
      name: 'Popis'
    }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    createdAt: x => formatDateTimeForDisplayShort(x.createdAt),
    type: x => (x.type === 'CREDIT' ? 'kredit' : 'debet'),
    amountInclVatKc: x => x.amountKc,
    newBalanceKc: x => x.newBalanceKc,
    fioTransactionId: x => x.fioTransaction?.transactionId,
    order: x => x.order?.orderNumber,
    description: x => x.description
  }
}
