import useBootstrapTableDataMapper from '../../../services/bootstrapTable/useBootstrapTableDataMapper'
import { AccountingGroupOrders } from '../../../graphql/aminTypes'
import usePagingFromURLQuery from '../../../services/bootstrapTable/usePagingFromURLQuery'
import LoadingContainer from '../../../components/LoadingContainer'
import React, { useContext, useEffect } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import { isAnyQueryLoading } from '../../../services/functions/queryHelpers'
import { ModalContext } from '../../../components/Modal/ModalProvider'
import { groupOrdersDataDefinition } from './services/group-orders-data-definition'
import { useAccountingGetGroupOrdersQuery } from './queries/accounting-get-group-orders.query'
import { FormProvider, useForm } from 'react-hook-form'
import DatePickerComp from '../../../components/formComponents/datePickerComp'
import { removeParamFromSearchString } from '../../../services/functions/httpBuildQuery'
import { useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import ToggleField from '../../../components/formComponents/ToggleField'
import { getDefaultValues } from './services/get-default-values'
import { CreateGroupInvoice } from './components/create-group-invoice/CreateGroupInvoice'

export function GroupOrders() {
  const { showModal, hideModal } = useContext(ModalContext)
  const formMethods = useForm({ defaultValues: getDefaultValues() })
  const navigate = useNavigate()
  const location = useLocation()

  const { generateColumnListForBootstrapTable, mapDataArrayToTable, nameForGqlTranslation, defaultSort } =
    useBootstrapTableDataMapper<AccountingGroupOrders>(groupOrdersDataDefinition)
  const { pageConfiguration, gqlQueryParams } = usePagingFromURLQuery(['dateFrom', 'dateTo', 'isDek'], nameForGqlTranslation, {
    defaultSort,
    defaultPageSize: 50,
    alternativeOrderByName: true
  })
  const groupOrdersQR = useAccountingGetGroupOrdersQuery(gqlQueryParams)

  const columnDefinition = generateColumnListForBootstrapTable({
    columnAction: handleTableClick,
    filterDefaultValues: pageConfiguration.filterBy,
    currentSort: pageConfiguration.sort
  })

  let groupOrders: any[] = []
  if (groupOrdersQR.data && groupOrdersQR.data.accountingGetGroupOrders) groupOrders = mapDataArrayToTable(groupOrdersQR.data.accountingGetGroupOrders)

  return (
    <FormProvider {...formMethods}>
      <LoadingContainer showLoader={isAnyQueryLoading(groupOrdersQR)}>
        <div className={'row'}>
          <div className={'col-md-2'}>
            <DatePickerComp name={'dateFrom'} label={'Datum od'} required onSelect={updateQueryString('dateFrom', 'date')} />
          </div>
          <div className={'col-md-2'}>
            <DatePickerComp name={'dateTo'} label={'Datum do'} required onSelect={updateQueryString('dateTo', 'date')} />
          </div>
          <div className={'col-md-3 offset-1'}>
            <div className={'mt-4'}>
              <ToggleField
                name={'isDek'}
                label={formMethods.watch('isDek') ? 'Zobrazuji pouze DEK objednávky' : 'Zobrazuji ostatní dodavatele mimo DEK'}
                registerOptions={{ onChange: e => updateQueryString('isDek', 'bool')(e.target.checked) }}
              />
            </div>
          </div>
        </div>
        <div className={'row  mb-4'}>
          <div className={'col-md-5'}>
            <button type="button" className={`btn btn-primary btn-block m-1`} onClick={showLastMonthHandler}>
              Minulý měsíc
            </button>
            <button type="button" className={`btn btn-primary btn-block m-1`} onClick={showThisMonthHandler}>
              Tento měsíc
            </button>
          </div>
          <div className={'col-md-2'}></div>
        </div>
        <div className="table-responsive-xl">
          <BootstrapTable
            bootstrap4
            striped
            condensed
            hover
            remote
            classes="table-responsive-lg digiTable"
            noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné objednávky"</>}
            keyField="id"
            data={groupOrders}
            columns={columnDefinition}
          />
        </div>
      </LoadingContainer>
    </FormProvider>
  )

  function handleTableClick(e: Event, column: number, columnIndex: number, row: any) {
    const rowData = groupOrdersQR.data?.accountingGetGroupOrders[row['index']]
    if (!rowData) return
    showModal({
      title: 'Hromadná faktura',
      modalContent: <CreateGroupInvoice groupOrdersData={rowData} isDek={formMethods.watch('isDek')} onExit={hideModal} />,
      hideFooter: true
    })
  }

  function updateQueryString(name: string, type: string) {
    return (value: any) => {
      let queryStringWoItem = removeParamFromSearchString(location.search, [name])
      if (value && type === 'date') queryStringWoItem.push(name + '=' + dayjs(value).format('YYYY-MM-DD'))
      if (value !== undefined && type === 'bool') queryStringWoItem.push(name + '=' + JSON.stringify(value))
      navigate(location.pathname + '?' + queryStringWoItem.join('&'), { replace: true })
    }
  }
  function showThisMonthHandler() {
    setMonths(dayjs().startOf('month'), dayjs().endOf('month'))
  }
  function showLastMonthHandler() {
    setMonths(dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month'))
  }
  function setMonths(dateFrom: dayjs.Dayjs, dateTo: dayjs.Dayjs) {
    let queryStringWoItem = removeParamFromSearchString(location.search, ['dateFrom', 'dateTo'])
    queryStringWoItem.push('dateFrom=' + dayjs(dateFrom).format('YYYY-MM-DD'))
    queryStringWoItem.push('dateTo=' + dayjs(dateTo).format('YYYY-MM-DD'))
    navigate(location.pathname + '?' + queryStringWoItem.join('&'), { replace: true })
    formMethods.setValue('dateTo', dateTo.toDate())
    formMethods.setValue('dateFrom', dateFrom.toDate())
  }
}
