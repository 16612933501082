import { gql, useQuery } from '@apollo/client'
import { SupplierMaterialPriceListEntity } from '../../../graphql/aminTypes'

export const supplierMaterialPriceListsQuery = gql`
  query supplierMaterialPriceLists($branchServiceId: ID!) {
    supplierMaterialPriceLists(branchServiceId: $branchServiceId) {
      id
      isActive
      priceKc
      priceForPublicKc
      productMaterialId
      material {
        id
        name
      }
    }
  }
`

export function useSupplierMaterialPriceListsQuery(branchServiceId?: string) {
  return useQuery<{ supplierMaterialPriceLists: SupplierMaterialPriceListEntity[] }>(supplierMaterialPriceListsQuery, {
    fetchPolicy: 'network-only',
    variables: { branchServiceId },
    skip: !branchServiceId
  })
}
