export function LeavePrompt({ onCancel, onConfirm }: { onConfirm: () => void; onCancel: () => void }) {
  return (
    <>
      <div className="modal modal-blur fade show d-block" id="modal-danger">
        <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
          <div className="modal-content">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => onCancel()}></button>
            <div className="modal-status bg-danger"></div>
            <div className="modal-body text-center py-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="icon mb-2 text-danger icon-lg"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M12 9v4"></path>
                <path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z"></path>
                <path d="M12 16h.01"></path>
              </svg>
              <h3>Opravdu odejít?</h3>
              <div className="text-secondary">Máte neuložené změny. Jste si jisti, že chcete odejít?</div>
            </div>
            <div className="modal-footer">
              <div className="w-100">
                <div className="row">
                  <div className="col">
                    <button className="btn w-100" data-bs-dismiss="modal" onClick={() => onCancel()}>
                      Cancel
                    </button>
                  </div>
                  <div className="col">
                    <button className="btn btn-danger w-100" data-bs-dismiss="modal" onClick={() => onConfirm()}>
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  )
}
