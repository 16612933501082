import 'dayjs/locale/cs'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import React from 'react'
import './css/tabler.scss'
import './css/app.scss'
import SetupLoginAndApollo from './services/appConfiguration/SetupLoginAndApollo'
import AppRouterSetup from './AppRouterSetup'
import { ModalProvider } from './components/Modal/ModalProvider'
import { SelectedSupplierModalProvider } from './ScenesAdminPriceLists/SelectedSupplierModal/SelectedSupplierModal'

dayjs.extend(localizedFormat)
dayjs.locale('cs') // Set Czech as the default locale

export default function MetrakAdminMain() {
  return (
    <SetupLoginAndApollo>
      <SelectedSupplierModalProvider>
        <ModalProvider>
          <AppRouterSetup />
        </ModalProvider>
      </SelectedSupplierModalProvider>
    </SetupLoginAndApollo>
  )
}
