import { IStringObject } from '../types/IStringObject'
import { UseFormReturn } from 'react-hook-form'
import { Maybe } from '../../graphql/aminTypes'

export function setupFields(
  data: IStringObject | undefined | Maybe<IStringObject> | Array<IStringObject>,
  opts: { skipFields?: string[]; includeKeys?: string[]; formMethods: UseFormReturn<any>; prefix?: string }
) {
  const skipFields = [...(opts.skipFields ?? []), '__typename']

  if (!data) return
  for (const [key, value] of Object.entries(data)) {
    if (!opts.includeKeys && skipFields.findIndex(x => x === key) < 0) setObjectWithoutTypename(opts.formMethods, key, value)
    if (opts.includeKeys && opts.includeKeys.findIndex(x => x === key) >= 0) {
      setObjectWithoutTypename(opts.formMethods, `${opts.prefix ? opts.prefix + '.' : ''}${key}`, value)
    }
  }
}

function setObjectWithoutTypename(formMethods: UseFormReturn<IStringObject>, key: string, value: any) {
  if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
    const newObj = { ...value }
    if (newObj.__typename) delete newObj.__typename
    formMethods.setValue(key, newObj)
  } else formMethods.setValue(key, value)
}

export function removeTypenameFromArray(data: Maybe<Array<IStringObject>> | undefined) {
  if (!data) return []
  const arr: IStringObject[] = []
  for (let i = 0; i < data.length; i++) {
    const newObj = { ...data[i] }
    if (newObj.__typename) delete newObj.__typename
    arr.push(newObj)
  }

  return arr
}
