import React, { useState } from 'react'
import { Column } from 'react-bootstrap-table-next'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { cs } from 'date-fns/locale'
import { filterAction, ICustomFilterReturnValue } from './CustomTextFilterInner'
import dayjs from 'dayjs'

registerLocale('cs', cs) // register it with the name you want

export interface IDateSelectorParams {
  onFilter: Function
  column: Column<string>
  defaultDate?: Date
  highlightDates?: string[]
}

export default function DateSelectorFilter({ onFilter, column, defaultDate, highlightDates }: IDateSelectorParams) {
  const [currentDate, setCurrentDate] = useState(defaultDate && dayjs(defaultDate).isValid() ? dayjs(defaultDate).toDate() : undefined)
  return (
    <DatePicker
      dateFormat="dd/MM/yyyy"
      selected={currentDate ?? null}
      onChange={(selectedDate: Date | null) => {
        setCurrentDate(selectedDate ?? undefined)
        if (onFilter && selectedDate)
          onFilter({
            action: filterAction.set,
            value: selectedDate
          } as ICustomFilterReturnValue)
        if (onFilter && !selectedDate) onFilter({ action: filterAction.reset } as ICustomFilterReturnValue)
      }}
      locale="cs"
      highlightDates={highlightDates ? highlightDates.map(d => new Date(d)) : undefined}
      placeholderText={'Vyberte datum'}
      className="form-control"
      todayButton="Dnes"
      isClearable
    />
  )
}
