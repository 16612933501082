import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../services/types/gqlTypes'

const deleteContainerRentRateMutation = gql`
  mutation deleteContainerRentRate($id: ID!) {
    deleteContainerRentRate(id: $id) {
      status {
        reason
        result
      }
    }
  }
`

export function useDeleteContainerRentRateMutation() {
  return useMutation<{ deleteContainerRentRate: IMutationResult }>(deleteContainerRentRateMutation, { refetchQueries: ['supplierContainerRentRates'] })
}
