import {
  ColumnAlign,
  ColumnFilterTypes,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes,
  WayOfIdHandover
} from '../../../services/bootstrapTable/services/tableSpecificationTypes'
import { AddressType, formatAddressToDisplay } from '../../../services/dataToString/formatAddressToDisplay'
import { httpBuildQueryString } from '../../../services/functions/httpBuildQuery'
import { formatDateTimeForDisplayShort } from '../../../services/dataToString/dateTimeFormatter'
import { CustomerResponse } from '../../../graphql/aminTypes'
import { paymentMethodsMap } from '../../../services/types/payment-method'
import dayjs from 'dayjs'
import React from 'react'
import { FlagIcon } from '../../../components/svgIcons/FlagIcon'

export const customersDataDefinition: TableDefinitionTypes<CustomerResponse> = {
  columnDefinition: {
    id: { hidden: true },
    data: { hidden: true },
    loginId: { hidden: true },
    isActive: {
      name: 'Aktivní',
      columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
      align: ColumnAlign.center,
      filterType: ColumnFilterTypes.customSelect,
      headerStyle: { width: '100px' }
    },
    companyName: {
      name: 'Firma',
      filterType: ColumnFilterTypes.customText
    },
    companyIdentification: { name: 'IČO' },
    companyTaxId: { name: 'DIČ' },
    firstname: { name: 'Jméno', filterType: ColumnFilterTypes.customText },
    lastname: { name: 'Příjmení', filterType: ColumnFilterTypes.customText },
    email: { name: 'E-mail', filterType: ColumnFilterTypes.customText },
    phone: { name: 'Telefon', filterType: ColumnFilterTypes.customText },
    address: { name: 'Adresa' },
    allowedPayments: { name: 'Povolené platební metody' },
    b2bMarginCorrection: {
      name: 'Úprava marže pro B2B',
      columnFormatterType: ColumnFormatterTypes.percentage,
      type: 'number'
    },
    metrakMarginCorrection: {
      name: 'Úprava marže Metrák',
      columnFormatterType: ColumnFormatterTypes.percentage,
      type: 'number'
    },
    customerDiscount: {
      name: 'Zákaznická sleva',
      columnFormatterType: ColumnFormatterTypes.percentage,
      type: 'number'
    },
    isResellCompany: {
      name: 'Přeprodej (DEK)',
      columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
      align: ColumnAlign.center,
      type: 'boolean',
      filterType: ColumnFilterTypes.customSelect
    },
    orderedContainerCount: {
      name: '# kontejnerů v realizovaných objednávkách',
      align: ColumnAlign.center,
      remoteSort: true
    },
    passwordSetEmailExpiry: { name: 'Expirace emailu pro nastavení hesla', align: ColumnAlign.left },
    flags: { name: 'Příznaky', filterType: ColumnFilterTypes.customSelect, headerStyle: { width: '120px' } },
    actions: {
      name: 'Akce',
      columnFormatterType: ColumnFormatterTypes.actionsObject,
      align: ColumnAlign.left,
      headerStyle: { width: '70px' }
    }
  },
  sourceToColumnMapping: {
    data: x => x,
    id: x => x.id,
    loginId: x => x.loginId,
    isActive: x => x.isActive,
    companyName: x => x.companyName,
    companyIdentification: x => x.companyIdentification,
    companyTaxId: x => x.companyTaxId,
    firstname: x => x.firstname,
    lastname: x => x.lastname,
    email: x => x.email,
    phone: x => x.phone,
    address: x => formatAddressToDisplay(x as AddressType),
    allowedPayments: x => (
      <>
        {x.allowedPaymentMethods?.map(p => (
          <React.Fragment key={p}> {p === x.primaryPaymentMethod ? <strong>{paymentMethodsMap[p]}, </strong> : <>{paymentMethodsMap[p]}, </>}</React.Fragment>
        ))}
      </>
    ),
    limitEndCustomerEmails: x => x.limitEndCustomerEmails,
    createInvoices: x => x.createInvoices,
    b2bMarginCorrection: x => x.b2bMarginCorrection,
    metrakMarginCorrection: x => x.metrakMarginCorrection,
    customerDiscount: x => x.customerDiscount,
    isResellCompany: x => x.isResellCompany,
    orderedContainerCount: x => x.totalContainersOrdered,
    passwordSetEmailExpiry: x => (x.passwordSetEmailExpiry ? formatPasswordSetEmailExpiry(x.passwordSetEmailExpiry) : ''),
    flags: x => (
      <>
        <FlagIcon className="fe fe-shopping-cart" key="fixedPrice" value={x.hasFixedPrice} desc={`Fixní ceny u vlastních produktů`} />
        <FlagIcon className="icon-scale" key="requireCertificates" value={x.requireCertificates} desc={'Vyžaduje váženku'} />
        <FlagIcon
          className="icon-cash"
          key="advanceAccounts"
          value={!!x.advanceAccountsSetup?.isUsingAdvanceAccounts}
          desc={'Používá zálohové účty'}
          useEmptyIcon
        />
        <FlagIcon className="fe fe-file-text" key="hasNote" value={!!x.notes} desc={x.notes ?? ''} useEmptyIcon />
        <FlagIcon
          className="fe fe-alert-triangle"
          key="isPreRegisteredOnly"
          value={x.isPreRegisteredOnly}
          desc={'Zákazník je registrovaný administrátorem, neví o své registraci'}
          useEmptyIcon
        />
      </>
    ),
    actions: x => {
      const ret: IRowAction[] = []
      ret.push({
        type: RowActionType.routerLink,
        wayOfIdHandover: WayOfIdHandover.search,
        searchStringBuilder: row =>
          httpBuildQueryString({
            b2bCustomerId: row.id,
            customerName: x.companyName ? x.companyName : `${x.lastname} ${x.firstname}`
          }),
        value: 'order-list',
        icon: 'fe-list',
        title: 'Zakázky tohoto uživatele'
      })
      if (!x.isActive)
        ret.push({
          type: RowActionType.codeAction,
          value: 'sendPwdRetrievalEmail',
          icon: 'fe-mail',
          title: 'Zaslat uživateli email pro nastavení hesla'
        })
      return ret
    }
  }
}

function formatPasswordSetEmailExpiry(passwordSetEmailExpiry: string) {
  if (!passwordSetEmailExpiry) return <></>

  const className = dayjs().isBefore(dayjs(parseInt(passwordSetEmailExpiry))) ? 'bg-success' : 'bg-danger'
  return (
    <>
      <div className={`badge mr-1 ${className}`} />
      {formatDateTimeForDisplayShort(parseInt(passwordSetEmailExpiry))}
    </>
  )
}
