import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../services/types/gqlTypes'

const updateWasteCollectionLinkMutation = gql`
  mutation updateWasteCollectionLink($wasteCollectionLinkId: ID!, $transportFlatRateKc: Float, $includedKmInFlatRate: Int) {
    updateWasteCollectionLink(
      wasteCollectionLinkId: $wasteCollectionLinkId
      transportFlatRateKc: $transportFlatRateKc
      includedKmInFlatRate: $includedKmInFlatRate
    ) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpdateWasteCollectionLinkMutation() {
  return useMutation<{ updateWasteCollectionLink: IMutationResult }>(updateWasteCollectionLinkMutation, { refetchQueries: ['supplierWasteCollectionLinks'] })
}
