import React, { useContext } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import useBootstrapTableDataMapper from '../../../../services/bootstrapTable/useBootstrapTableDataMapper'
import { ContainerItemType, WasteCapacitiesType } from '../../../../services/queries/containersQuery'
import { containerListExpandDataDefinition } from './services/containerListExpandDataDefinition'
import { ModalContext } from '../../../../components/Modal/ModalProvider'
import AddWasteToContainer from '../AddWasteToContainer/AddWasteToContainer'
import cellEditFactory from 'react-bootstrap-table2-editor'
import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import { createParamsForUpsertContainerWasteType, useUpsertContainerWasteType } from '../AddWasteToContainer/services/upsertContainerWasteTypeMutation'
import { useDeleteContainerWasteType } from './services/deleteContainerWasteTypeMutation'

export default function ContainerRowExpand({ data, container }: { data: WasteCapacitiesType[]; container: ContainerItemType }) {
  const { generateColumnListForBootstrapTable, mapDataArrayToTable } = useBootstrapTableDataMapper<WasteCapacitiesType>(containerListExpandDataDefinition)
  const { showModal, hideModal } = useContext(ModalContext)
  const columnDefinition = generateColumnListForBootstrapTable({
    actionsReference: {
      deleteWasteType: deleteWasteTypeHandler
    }
  })
  const [upsertContainerWasteTypeMut, upsertContainerWasteTypeQR] = useUpsertContainerWasteType()
  const [deleteContainerWasteTypeMut, deleteContainerWasteTypeQR] = useDeleteContainerWasteType()

  let productsData: any[] = []
  if (data) productsData = mapDataArrayToTable(data)
  return (
    <>
      <BootstrapTable
        bootstrap4
        condensed
        classes="table-responsive-lg digiTable"
        noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné objednávky"</>}
        keyField="id"
        data={productsData}
        columns={columnDefinition}
        cellEdit={cellEditFactory({
          mode: 'click',
          blurToSave: true,
          autoSelectText: true,
          beforeSaveCell: updateB2bCustomerHandler
        })}
      />

      <div className="row">
        <div className="col col-is-12 text-right">
          <button
            type={'button'}
            className="btn btn-primary"
            onClick={() =>
              showModal({
                title: 'Přidat typ odpadu pro kontejner ' + container.name,
                modalContent: (
                  <AddWasteToContainer
                    container={container}
                    exitHandler={async () => {
                      hideModal()
                    }}
                  />
                ),
                hideFooter: true
              })
            }
          >
            Přidat produkt
          </button>
        </div>
      </div>
    </>
  )

  function deleteWasteTypeHandler(row: any) {
    if (!row.id) return
    if (window.confirm(`Opravdu zrušit typ odpadu ${row.product}?`)) {
      const deleteLoginPromise = deleteContainerWasteTypeMut({ variables: { containerWasteTypeId: row.id } })
      processPageOperation({
        promise: deleteLoginPromise,
        successMessage: `Odpad odstraněn`
      })
    }
  }

  async function updateB2bCustomerHandler(oldValue, newValue, row, column, doneCallback) {
    if (!row.id || oldValue === newValue) return
    const promise = upsertContainerWasteTypeMut({ variables: createParamsForUpsertContainerWasteType(container.id, row.productId, newValue) })
    processPageOperation({
      promise: promise,
      successMessage: 'Kapacita byla upravena'
    })
  }
}
