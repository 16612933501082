import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const upsertSellerConcretePriceListMutation = gql`
  mutation upsertSellerConcretePriceList($branchServiceId: ID!, $data: SupplierServiceConcreteSellerInput!) {
    upsertSellerConcretePriceList(branchServiceId: $branchServiceId, data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpsertSellerConcretePriceListMutation() {
  return useMutation<{ upsertSellerConcretePriceList: IMutationResult }>(upsertSellerConcretePriceListMutation, {
    refetchQueries: ['supplierServiceConcretePriceList']
  })
}
