import { useGetFioSyncSettingsQuery } from './queries/get-fio-sync-settings.query'
import { useGetFioSyncLatestResultQuery } from './queries/get-fio-sync-latest-sync-result.query'
import classNames from 'classnames'
import React from 'react'
import LoadingContainer from '../../../../../components/LoadingContainer'
import { isAnyQueryLoading } from '../../../../../services/functions/queryHelpers'
import { DataListItem, HtmlDataList } from '../../../../../components/HtmlDataList'
import { formatDateTimeForDisplayShort } from '../../../../../services/dataToString/dateTimeFormatter'
import { useSetFioSyncSettingsMutation } from './mutations/set-fio-sync-settings'
import { processPageOperation } from '../../../../../services/formsServices/pageOperationProcessor'

export default function StatusBar() {
  const fioSyncSettingsQR = useGetFioSyncSettingsQuery()
  const fioSyncLatestResultQR = useGetFioSyncLatestResultQuery()
  const fioSyncSettings = fioSyncSettingsQR.data?.getFioSyncSettings
  const fioSyncLatestResult = fioSyncLatestResultQR.data?.getFioSyncLatestResult
  const [setFioSyncSettingsMut, setFioSyncSettingsMR] = useSetFioSyncSettingsMutation()
  const fioSyncLatestResultMap: DataListItem[] = fioSyncLatestResult
    ? [
        {
          label: 'Status',
          text: fioSyncLatestResult.status ? (
            <span className={classNames('badge', 'badge-wrap', fioSyncLatestResult.status === 'FINISHED' ? 'bg-success' : 'bg-danger')}>
              {fioSyncLatestResult.status}
            </span>
          ) : (
            'N/A'
          )
        },
        {
          label: 'Datum',
          text: fioSyncLatestResult.latestSync ? formatDateTimeForDisplayShort(fioSyncLatestResult.latestSync) : 'N/A'
        },
        {
          label: 'Celkový počet transakcí',
          text: fioSyncLatestResult.foundTransactions ? fioSyncLatestResult.foundTransactions : '0'
        },
        {
          label: 'Napárovaných transakcí',
          text: fioSyncLatestResult.pairedTransactions ? fioSyncLatestResult.pairedTransactions : '0'
        }
      ]
    : []

  return (
    <LoadingContainer showLoader={isAnyQueryLoading(fioSyncSettingsQR, fioSyncLatestResultQR, setFioSyncSettingsMR)}>
      <div className="row mb-4">
        <div className="col-4">
          <section className="form-group">
            <header>
              <h2>Status služby</h2>
            </header>
          </section>
          <div className="m-2 showAsLink" onClick={toggleAllowScheduledSync}>
            <span className={classNames('mb-1', 'badge', 'badge-wrap', fioSyncSettings?.allowScheduledSync ? 'bg-success' : 'bg-danger')}></span>
            &nbsp; Pravidelná synchronizace po 30 minutách
          </div>
          <div className="m-2 showAsLink" onClick={toggleAllowAutoPaymentPaired}>
            <span className={classNames('mb-1', 'badge', 'badge-wrap', fioSyncSettings?.allowAutoPaymentPaired ? 'bg-success' : 'bg-danger')}></span>
            &nbsp; Automatické zaplacení spárovaných plateb
          </div>
        </div>
        <div className="col-4">
          <HtmlDataList data={fioSyncLatestResultMap} label={'Poslední synchronizace'} />
        </div>
      </div>
    </LoadingContainer>
  )

  function toggleAllowScheduledSync() {
    if (!fioSyncSettings) return
    setFioSettingsHandler({
      allowAutoPaymentPaired: fioSyncSettings.allowAutoPaymentPaired ?? false,
      allowScheduledSync: !fioSyncSettings.allowScheduledSync
    })
  }

  function toggleAllowAutoPaymentPaired() {
    if (!fioSyncSettings) return
    setFioSettingsHandler({
      allowAutoPaymentPaired: !fioSyncSettings.allowAutoPaymentPaired,
      allowScheduledSync: fioSyncSettings.allowScheduledSync ?? false
    })
  }

  function setFioSettingsHandler(variables: Record<string, boolean>) {
    const promise = setFioSyncSettingsMut({
      variables
    })
    processPageOperation({
      promise: promise,
      successMessage: 'Stav byl úspěšně změněn'
    })
  }
}
