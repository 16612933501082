import { gql, useQuery } from '@apollo/client'
import { contextEndpoint, EndpointEnum } from '../../../../services/appConfiguration/contextEndpoint'
import { SupplierList } from '../../../../graphql/aminTypes'

export const suppliersQuery = gql`
  query suppliers2($disabled: String, $withProductId: Int) {
    suppliers2(disabled: $disabled, withProductId: $withProductId) {
      items {
        id
        name
        orpName
        regionCode
      }
    }
  }
`

export function useSuppliers(variables?: { disabled?: string; withProductId?: number }) {
  return useQuery<{ suppliers2: SupplierList }>(suppliersQuery, {
    fetchPolicy: 'network-only',
    variables,
    context: contextEndpoint(EndpointEnum.admin)
  })
}
