import OrderContentStateSelector from '../../../components/dataComponents/OrderContentStateSelector'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { processPageOperation } from '../../../services/formsServices/pageOperationProcessor'
import LoadingContainer from '../../../components/LoadingContainer'
import { WarningAlert } from '../../../components/formComponents/alert'
import { useChangeOrderStateMutation } from './services/changeOrderStateMutation'
import { isAnyQueryLoading } from '../../../services/functions/queryHelpers'

export function ChangeOrderState({
  orderContentId,
  whenDone
}: {
  orderContentId: number
  whenDone: () => void
}) {
  const formMethods = useForm()
  const [changeOrderStateMut, changeOrderStateQR] = useChangeOrderStateMutation()

  const orderContentState = formMethods.watch('orderContentState')
  return (
    <FormProvider {...formMethods}>
      <LoadingContainer showLoader={isAnyQueryLoading(changeOrderStateQR)}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
          <OrderContentStateSelector label={'Změnit stav na'} removeStates={['Draft']}/>
          <div className="justify-content-center d-flex mt-3">
            <WarningAlert
              message={
                'Bude napřímo změněn stav zakázky. Nebude odeslán žádný notifikační email a tím se může stát, že zákazník nebo dodavatel nebude správně informován. Použijte jen mimořádně pro opravu stavu vzhledem k reálné situaci.'
              }
            />
          </div>
          <div className="text-right mt-4">
            <button type="submit" className="btn btn-red ms-auto btn-lg" disabled={!orderContentState}>
              Změnit stav
            </button>
          </div>
        </form>
      </LoadingContainer>
    </FormProvider>
  )

  function handleFormSubmit(data) {
    if (window.confirm('Opravdu si přejete změnit stav objednávky?')) {
      const promise = changeOrderStateMut({
        variables: {
          orderContentId: orderContentId,
          orderContentStateSys: orderContentState
        }
      })
      processPageOperation({
        promise: promise,
        failedAction: whenDone,
        successMessage: 'Stav byl úspěšně změněn',
        successAction: whenDone
      })
    }
  }
}
