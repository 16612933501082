import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const deleteSupplierWasteCollectionFlatRateMutation = gql`
  mutation deleteSupplierWasteCollectionFlatRate($branchServiceId: ID!, $productWasteId: ID!) {
    deleteSupplierWasteCollectionFlatRate(branchServiceId: $branchServiceId, productWasteId: $productWasteId) {
      status {
        reason
        result
      }
    }
  }
`

export function useDeleteSupplierWasteCollectionFlatRateMutation() {
  return useMutation<{ deleteSupplierWasteCollectionFlatRate: IMutationResult }>(deleteSupplierWasteCollectionFlatRateMutation, {
    refetchQueries: ['supplierWasteCollectionFlatRates']
  })
}
