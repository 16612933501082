import {gql, useMutation} from '@apollo/client'
import {IMutationResult} from '../../../../services/types/gqlTypes'

const changeOrderStateMutation = gql`
    mutation changeOrderState($orderContentId: Int!, $orderContentStateSys: String!) {
        changeOrderState(orderContentId: $orderContentId, orderContentStateSys: $orderContentStateSys) {
            status {
                reason
                result
            }
        }
    }
`

export function useChangeOrderStateMutation() {
    return useMutation<{ changeOrderState: IMutationResult }>(changeOrderStateMutation)
}