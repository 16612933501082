export type WasteCategoryType = 'SOLID_WASTE' | 'LIQUID_WASTE' | 'HAZARDOUS_WASTE' | 'SCRAP_WASTE'

export const wasteCategoryTypeList = ['SOLID_WASTE', 'LIQUID_WASTE', 'HAZARDOUS_WASTE', 'SCRAP_WASTE']

export const wasteCategoryTypeTranslation: { [key in WasteCategoryType]: string } = {
  SOLID_WASTE: 'Pevný odpad',
  LIQUID_WASTE: 'Kapalný odpad',
  HAZARDOUS_WASTE: 'Nebezpečný odpad',
  SCRAP_WASTE: 'Vraky a druhotné suroviny'
}
export const wasteCategoryTypeOptions = wasteCategoryTypeList.map(type => ({ value: type, label: wasteCategoryTypeTranslation[type] }))
