import { gql, useQuery } from '@apollo/client'
import { IGqlPagingQueryParams } from '../../../services/bootstrapTable/usePagingFromURLQuery'
import { SupplierSupplierListResponse } from '../../../graphql/aminTypes'
import { ServiceCategoryType } from '../../../services/types/service-category.type'

export const supplierSupplierListQuery = gql`
  query supplierSupplierList($name: String, $serviceCategories: [ServiceCategory!], $hasContractWithMetrak: Boolean) {
    supplierSupplierList(name: $name, serviceCategories: $serviceCategories, hasContractWithMetrak: $hasContractWithMetrak) {
      total
      items {
        id
        isActive
        name
        branches {
          id
          specification
          gps {
            lng
            lat
          }
          address {
            id
            city
            street
            streetNumber
            id
            zip
          }
          branchServices {
            id
            serviceCategory
          }
        }
      }
    }
  }
`

export function useSupplierSupplierListQuery(params?: { name?: string; serviceCategories?: ServiceCategoryType[]; hasContractWithMetrak: boolean }) {
  return useQuery<{ supplierSupplierList: SupplierSupplierListResponse }>(supplierSupplierListQuery, {
    fetchPolicy: 'network-only',
    variables: params
  })
}
