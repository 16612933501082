import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'
import { IStringObject } from '../../../../../services/types/IStringObject'

const updateProductMutation = gql`
  mutation updateProduct($productId: Int!, $data: ProductDataInput!) {
    updateProduct(productId: $productId, data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpdateProductMutation() {
  return useMutation<{ updateContainer: IMutationResult }>(updateProductMutation)
}

export function createParamsForUpdateProductMutation(data: IStringObject, productId: number) {
  if (!data) return
  return {
    productId: productId,
    data: {
      disabled: !data.isActive,
      fractionId: +data.fractionId > 0 ? +data.fractionId : null,
      isForPublic: data.isForPublic,
      margin: +data.margin,
      materialId: +data.materialId,
      name: data.name,
      tonToCubes: +data.tonToCubes,
      tooltip: data.tooltip,
      typeId: +data.materialTypeId,
      uiOrder: +data.uiOrder,
      uiPicture: data.uiPicture,
      wasteTypeId: +data.wasteTypeId,
      ignoreContainerRentPrice: data.ignoreContainerRentPrice,
      wasteDescription: data.wasteDescription,
      acceptedWaste: data.acceptedWaste,
      unsupportedWaste: data.unsupportedWaste
    }
  }
}
