import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'
import { contextEndpoint, EndpointEnum } from '../../../../../services/appConfiguration/contextEndpoint'
import { listOrderContentFilesQuery } from '../../../queries/listfOfFilesQuery'
import { getZpoDetailQuery } from '../query/getZpoDetailQuery'

const reGenerateZpoMutation = gql`
  mutation reGenerateZpo($orderContentId: Int!) {
    reGenerateZpo(orderContentId: $orderContentId) {
      status {
        reason
        result
      }
    }
  }
`

export function useReGenerateZpo(orderContentId: number) {
  return useMutation<{ reGenerateZpo: IMutationResult }>(reGenerateZpoMutation, {
    context: contextEndpoint(EndpointEnum.admin),
    refetchQueries: [
      {
        query: getZpoDetailQuery,
        variables: { orderContentId: orderContentId },
        context: contextEndpoint(EndpointEnum.admin)
      },
      {
        query: listOrderContentFilesQuery,
        variables: { orderContentId: orderContentId },
        context: contextEndpoint(EndpointEnum.admin)
      }
    ]
  })
}
