export const selectRow = (handleOnSelect: Function, handleOnSelectAll: Function, selected: any) => ({
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    headerColumnStyle: {
        textAlign: 'center'
    },
    selected: selected

})
