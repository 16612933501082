import { SelectedSupplierModalContext } from '../SelectedSupplierModal/SelectedSupplierModal'
import React, { useContext } from 'react'
import PageCard from '../../components/Cards/pageCard'
import SupplierServiceEnabler from '../common/components/SupplierServiceEnabler/SupplierServiceEnabler'
import { ModalContext } from '../../components/Modal/ModalProvider'
import { useSupplierServiceLoadTransport } from './queries/supplier-service-load-transport.query'
import useBootstrapTableDataMapper from '../../services/bootstrapTable/useBootstrapTableDataMapper'
import { SupplierTruckEntity } from '../../graphql/aminTypes'
import { supplierServiceLoadTransportDataDefinition } from './services/supplier-service-load-transport-data-definition'
import BootstrapTable from 'react-bootstrap-table-next'
import { SettingsNode } from './components/SettingsNode'
import SupplierTruckEditor from './components/SupplierTruck/SupplierTruckEditor'
import { useDeleteSupplierServiceLoadTransportMutation } from './mutations/delete-supplier-service-load-transport.mutation'
import { isAnyQueryLoading } from '../../services/functions/queryHelpers'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'

export default function SupplierServiceLoadTransport() {
  const { state } = useContext(SelectedSupplierModalContext)
  const { showModal, hideModal } = useContext(ModalContext)
  const currentServiceSetting = state.selectedBranch?.branchServices?.find(x => x.serviceCategory === 'LOAD_TRANSPORT')
  const [deleteSupplierServiceLoadTransportMut, deleteSupplierServiceLoadTransportQR] = useDeleteSupplierServiceLoadTransportMutation()
  const serviceLoadTransportQR = useSupplierServiceLoadTransport(currentServiceSetting?.id)

  const { generateColumnListForBootstrapTable, mapDataArrayToTable } = useBootstrapTableDataMapper<SupplierTruckEntity>(
    supplierServiceLoadTransportDataDefinition
  )
  const columnDefinition = generateColumnListForBootstrapTable({
    columnAction: handleTableClick,
    actionsReference: {
      deleteTruck: deleteTruckHandler
    }
  })

  const tableData = mapDataArrayToTable(serviceLoadTransportQR.data?.supplierServiceLoadTransport)

  return (
    <PageCard showLoader={isAnyQueryLoading(deleteSupplierServiceLoadTransportQR, serviceLoadTransportQR)} headerTitle={'Autodoprava'}>
      <SupplierServiceEnabler currentServiceSetting={currentServiceSetting} serviceCategory={'LOAD_TRANSPORT'} settingsNode={<SettingsNode />} />
      {currentServiceSetting && (
        <>
          <button type="button" className="btn btn-primary btn-block mb-2" onClick={addTruckHandler}>
            Přidat auto
          </button>
          <BootstrapTable
            bootstrap4
            striped
            hover
            condensed
            classes="table-responsive-lg digiTable"
            noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné záznamy"</>}
            keyField="id"
            data={tableData ? tableData : []}
            columns={columnDefinition}
            defaultSorted={[{ dataField: 'uiOrder', order: 'asc' }]}
          />
        </>
      )}
    </PageCard>
  )

  function addTruckHandler() {
    showModal({
      title: 'Přidat auto',
      modalContent: <SupplierTruckEditor branchServiceId={currentServiceSetting?.id} onClose={hideModal} />,
      hideFooter: true
    })
  }
  function handleTableClick(e: Event, column: number, columnIndex: number, row: any) {
    if (columnIndex > 6) return
    const item = serviceLoadTransportQR.data?.supplierServiceLoadTransport.find(x => x.id === row.id)
    showModal({
      title: 'Editovat auto',
      modalContent: <SupplierTruckEditor branchServiceId={currentServiceSetting?.id} onClose={hideModal} item={item} />,
      hideFooter: true
    })
  }
  function deleteTruckHandler(row: any) {
    const promise = deleteSupplierServiceLoadTransportMut({ variables: { id: row.id } })
    return processPageOperation({
      promise: promise,
      successMessage: 'Auto bylo odstraněno'
    })
  }
}
