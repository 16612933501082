import { gql, useMutation } from '@apollo/client'
import {IMutationResult} from "../../../../../services/types/gqlTypes";

const updateMaterialMutation = gql`
    mutation updateMaterial($id: Int!, $data: UpdateMaterialInput!) {
        updateMaterial(id: $id, data: $data) {
            status {
                reason
                result
            }
        }
    }
`

export function useUpdateMaterialMutation() {
    return useMutation<{ updateContainer: IMutationResult }>(updateMaterialMutation)
}

export function createParamsForUpdateMaterialMutation(row, field, newValue) {
    const update = {
            name: row.name,
            tonToCubes: +row.tonToCubes,
            tooltip: row.tooltip,
            type: row.type,
            uiOrder: +row.uiOrder,
            uiPicture: row.uiPicture
    }
    if(field === 'tonToCubes' || field === 'uiOrder') {
        update[field] = +newValue
    } else {
        update[field] = newValue
    }
    return update
}

