import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'
import { convertDateForGql } from '../../../services/functions/convertDateForGql'
import { AddressType } from '../../../services/dataToString/formatAddressToDisplay'
import { decimalRound } from '../../../services/functions/decimalRound'
import { OrderContentPrices } from '../../../graphql/aminTypes'
import { ProcessedOrderContentPrices } from '../services/getPriceSeparation'

const createOrderMutation = gql`
  mutation createOrder($data: OrderDataInput!, $isDraft: Boolean!) {
    createOrder(data: $data, isDraft: $isDraft) {
      status {
        reason
        result
      }
    }
  }
`

export function useCreateOrderMutation() {
  return useMutation<{ createOrder: IMutationResult }>(createOrderMutation)
}

export function createParamsForCreateOrderMutation(data: any, priceBreakUp?: ProcessedOrderContentPrices[] | null, tonToCubesForSelectedProduct?: number) {
  let companyResidence: AddressType | undefined = undefined
  if (data.companyResidenceCity || data.companyResidenceStreet || data.companyResidenceStreetNumber || data.companyResidenceZip)
    companyResidence = {
      street: data.companyResidenceStreet,
      city: data.companyResidenceCity,
      streetNumber: data.companyResidenceStreetNumber,
      zip: data.companyResidenceZip,
      countryIso: data.companyResidenceCountryIso
    }

  return {
    data: {
      firstname: data.firstname,
      lastname: data.lastname,
      companyName: data.companyName,
      street: data.address.street,
      streetNumber: data.address.streetNumber,
      city: data.address.city,
      zip: data.address.zip,
      gps: { lng: data.address.lng, lat: data.address.lat },
      ico: data.ico,
      dic: data.dic,
      companyCountryIso: data.companyResidenceCountryIso ?? 'CZ',
      phone: data.phone,
      email: data.email,
      targetCompanyIco: data.targetCompanyIco,
      targetCompanyName: data.targetCompanyName,
      orderNote: data.orderNote,
      totalWoVatKc: parseFloat(data.totalWoVatKc),
      paymentType: data.paymentType,
      invoicingNote: data.invoicingNote,
      invoicingNoteDone: data.invoicingNote ? data.invoicingNoteDone : undefined,
      b2bCustomerId: data.b2bCustomerId ? data.b2bCustomerId : undefined,
      companyResidence: companyResidence,
      notificationSettings: data.notificationSettings ? data.notificationSettings : {},
      requireCertificateState: data.requireCertificateState,
      orderContents: [
        {
          productId: parseInt(data.productId),
          containerId: data.containerId,
          terminalId: data.terminalId,
          supplierId: data.supplierId,
          distanceTotalKm: data.distanceTotalKm ? parseFloat(data.distanceTotalKm) : null,
          containerFromDate: convertDateForGql(data.containerFromDate),
          containerToDate: data.containerToDate ? convertDateForGql(data.containerToDate) : null,
          containerCount: parseInt(data.containerCount),
          totalForContentWoVatKc: parseFloat(data.totalForContentWoVatKc),
          codToCollect: parseFloat(data.codToCollect),
          adminNote: data.adminNote,
          supplierNote: data.supplierNote,
          customerNote: data.customerNote,
          wasteDescription: data.wasteDescription,
          supplierProfitWoVatKc: parseFloat(data.supplierProfitWoVatKc),
          arriveContainerAtTimeRangeOfDay: data.arriveContainerAtTimeRangeOfDay,
          serviceType: data.isForDelivery ? 'MATERIAL_DELIVERY' : 'WASTE_COLLECTION',
          weightTonnes: data.isForDelivery && tonToCubesForSelectedProduct ? decimalRound(data.volumeM3 * tonToCubesForSelectedProduct, 2) : undefined,
          arriveTime: data.arriveTime,
          collectionTime: data.collectionTime,
          onsiteContactFirstname: data.onsiteContactFirstname,
          onsiteContactLastname: data.onsiteContactLastname,
          onsiteContactPhone: data.onsiteContactPhone,
          orderContentPriceItems: priceBreakUp
            ? priceBreakUp.map(pb => ({
                priceWoVatKc: pb.priceWoVatKc,
                orderContentPriceItemSys: pb.orderContentPriceItem.sysName
              }))
            : []
        }
      ]
    }
  }
}
