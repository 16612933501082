import {IMutationResult, mutationStatusResult} from "../types/gqlTypes";


export default class MutationEvaluation {
  constructor(mutationOutput: { [key: string]: IMutationResult | Record<string, any> }, mutationName: string) {
    this.reason = mutationOutput[mutationName].status.reason
    this.result = mutationOutput[mutationName].status.result
    this.transPrefix = mutationName
  }

  reason: string
  result: string
  transPrefix: string

  isMutationSuccessful() {
    return this.result === mutationStatusResult.success
  }

  isMutationFailed() {
    return this.result === mutationStatusResult.failed
  }

  getErrorReasonMessage() {
    if (!this.reason || this.reason.length <= 0) return 'Důvod nebyl zadán'
    return this.reason
  }

}
