import React from 'react'
import { Link } from 'react-router-dom'
import { formatDateForDisplayShort, formatDateTimeForDisplayShort } from '../dataToString/dateTimeFormatter'
import classNames from 'classnames'
import { decimalToLocaleString, moneyToLocaleString } from '../dataToString/decimalToLocaleString'
import { ColumnFormatterTypes, IRowAction, RowActionType, WayOfIdHandover } from './services/tableSpecificationTypes'
import { IStringObject } from '../types/IStringObject'
import { httpBuildQueryString } from '../functions/httpBuildQuery'

export function columnFormatterFactory(type: ColumnFormatterTypes) {
  if (type === ColumnFormatterTypes.actionsObject) return actionsFormatter
  if (type === ColumnFormatterTypes.htmlListWithTranslations) return htmlListWithTranslationsFormatter
  if (type === ColumnFormatterTypes.htmlList) return htmlListFormatter
  if (type === ColumnFormatterTypes.translateCell) return translateCell
  if (type === ColumnFormatterTypes.showInBadge) return showInBadgeCell
  if (type === ColumnFormatterTypes.badgeAndTranslate) return badgeAndTranslate
  if (type === ColumnFormatterTypes.dateFormatter) return dateFormatter
  if (type === ColumnFormatterTypes.datetimeFormatter) return datetimeFormatter
  if (type === ColumnFormatterTypes.yesNoOptionalFormatter) return yesNoOptionalFormatter
  if (type === ColumnFormatterTypes.numberFormatter) return numberFormatter
  if (type === ColumnFormatterTypes.moneyFormatter) return moneyFormatter
  if (type === ColumnFormatterTypes.monospacedFont) return monospacedFontFormatter
  if (type === ColumnFormatterTypes.percentage) return percentageFormatter
  if (type === ColumnFormatterTypes.showAsLink) return showAsLinkFormatter

  throw new Error('Wrong column formatter type: ' + type)
}

export function actionsFormatter(
  cell: IRowAction[],
  row: any,
  rowIndex: number,
  formatExtraData: { actionsReference: IStringObject<(row: any, rowIndex: number) => void>; isMobile?: boolean }
) {
  if (!cell || cell.length <= 0) return null
  const { actionsReference } = formatExtraData
  const htmlOutput: any = []
  const paddingClass = formatExtraData.isMobile ? 'p-3 d-block' : ''
  for (const action of cell) {
    switch (action.type) {
      case RowActionType.link:
        htmlOutput.push(
          <a key={action.value + row.id} className={paddingClass} href={action.value} target="_blank" rel="noopener noreferrer" title={action.title}>
            <i className={classNames('m-1 fe', action.icon)} />
          </a>
        )
        break
      case RowActionType.codeAction:
        htmlOutput.push(
          <button
            key={action.value + row.id}
            type="button"
            className={classNames('link', paddingClass)}
            onClick={() => actionsReference[action.value](row, rowIndex)}
            title={action.title}
          >
            <i className={classNames('m-1 fe', action.icon)} />
          </button>
        )
        break
      case RowActionType.routerLink:
        const to: IStringObject = { pathname: '/' + action.value }

        if (action.wayOfIdHandover === WayOfIdHandover.search)
          to.search = action.searchStringBuilder ? action.searchStringBuilder(row) : httpBuildQueryString({ id: row.id, ...action.stateValueExtension })
        if (action.wayOfIdHandover === WayOfIdHandover.state) to.state = { ...action.stateValueExtension, id: row.id }
        htmlOutput.push(
          <Link key={action.title + row.id} to={to} className={paddingClass}>
            <i className={classNames('m-1 fe', action.icon)} title={action.title} />
          </Link>
        )
        break
      default:
        throw new Error('action formatter has wrong action specified')
    }
  }
  return <>{htmlOutput}</>
}

export function badgeAndTranslate(cell: string, row: any, rowIndex: number, formatExtraData: any) {
  const showData = formatExtraData.translateMap[cell]
  let badgeClass = 'badge-success'
  if (formatExtraData.badgeColours) {
    badgeClass = formatExtraData.badgeColours[showData] || 'badge-success'
  }
  return <span className={classNames('badge', badgeClass)}>{showData}</span>
}

export function showInBadgeCell(cell: any, row: any, rowIndex: number, formatExtraData: any) {
  let badgeClass = 'badge-success'
  if (formatExtraData.badgeColours) {
    badgeClass = formatExtraData.badgeColours[cell] || 'badge-success'
  }
  return <span className={classNames('badge', 'badge-wrap', badgeClass)}>{cell}</span>
}

export function translateCell(cell: string, row: any, rowIndex: number, formatExtraData: any) {
  if (cell === undefined || cell === null || cell === '') return ''
  return cell.toString()
}

export function dateFormatter(cell: any) {
  return formatDateForDisplayShort(cell)
}

export function datetimeFormatter(cell: any) {
  return formatDateTimeForDisplayShort(cell)
}

export function numberFormatter(cell: any) {
  return decimalToLocaleString(cell)
}

export function moneyFormatter(cell: any) {
  return moneyToLocaleString(cell, 'CZK')
}

export function htmlListWithTranslationsFormatter(cell: any, row: any, rowIndex: number, formatExtraData: any) {
  if (!cell) return ''
  const { separator } = formatExtraData
  const dataToDisplay = cell.split(separator || ',')
  return (
    <ul>
      {dataToDisplay.map(function (name: string, index: number) {
        return <li key={index}>{name}</li>
      })}
    </ul>
  )
}

export function htmlListFormatter(cell: any, row: any, rowIndex: number, formatExtraData: any) {
  if (!cell) return ''
  const { separator } = formatExtraData
  const dataToDisplay = cell.split(separator || ',')
  return (
    <ul>
      {dataToDisplay.map(function (name: string, index: number) {
        return <li key={index}>{name}</li>
      })}
    </ul>
  )
}

export function yesNoOptionalFormatter(cell: any) {
  switch (cell) {
    case true:
    case 'yes':
      return <i className="fe fe-check" />
    case false:
    case 'no':
      return <i className="fe fe-x" />
    case 'optional':
      return <i className="fe fe-help-circle" />
    default:
      return cell
  }
}

export function monospacedFontFormatter(cell: any) {
  return <code>{cell}</code>
}

export function percentageFormatter(cell: any) {
  return decimalToLocaleString(cell) + '%'
}

export function showAsLinkFormatter(cell: any) {
  return <span className="showAsLink">{cell}</span>
}
