import React from 'react'
import ToggleField from '../../../components/formComponents/ToggleField'

export function SettingsNode() {
  return (
    <div className="row mb-2">
      <div className="col-md-6">
        <ToggleField name={'settings.finishedOnlyWhenAllDocumentsAreUploaded'} label={'Dokončeno dodavatelem až po dodání všech podkladů (3 dokumenty)'} />
      </div>
    </div>
  )
}
