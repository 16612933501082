import {useGetDownloadUrlQuery} from "./query/getDownloadUrlQuery";
import {toast} from "react-toastify";

export function useDownloadS3File(){
    const getDownloadUrlQR = useGetDownloadUrlQuery()
    function downloadS3FileHandler(s3key: string): void {
        const ret = getDownloadUrlQR.refetch({key: s3key})
        if (!ret) toast.error('Error while getting download link')
        ret
            .then(data => {
                window.open(data.data.getDownloadUrl)
            })
            .catch(error => {
                toast.error(error.toString())
            })
    }
    return {downloadS3FileHandler, s3FileLoading: getDownloadUrlQR.loading}

}