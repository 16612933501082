import { AccountingAdvanceAccountEntity, AccountingDocumentEntity } from '../../../../graphql/aminTypes'
import {
  ColumnAlign,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../../services/bootstrapTable/services/tableSpecificationTypes'
import { formatAddressToDisplay } from '../../../../services/dataToString/formatAddressToDisplay'
import { addInvoiceActions } from '../../../../services/bootstrapTable/add-invoice-actions'
import { formatDateForDisplayShort, formatDateTimeForDisplayShort } from '../../../../services/dataToString/dateTimeFormatter'
import { accountingDocumentsTranslation } from '../../../OrderDetail/components/AccountingDocuments/services/accounting-document-translation'

export const advanceAccountsInvoicesDataDefinition: TableDefinitionTypes<AccountingDocumentEntity> = {
  columnDefinition: {
    id: { hidden: true },
    isCancelled: { hidden: true },
    createdAt: {
      name: 'Vystavena'
    },
    type: {
      name: 'Typ'
    },
    amountInclVatKc: {
      name: 'Částka s DPH',
      columnFormatterType: ColumnFormatterTypes.moneyFormatter
    },
    variableSymbol: {
      name: 'Variabilní symbol'
    },
    dueDate: {
      name: 'Splatnost',
      align: ColumnAlign.center,
      columnFormatterType: ColumnFormatterTypes.dateFormatter
    },
    paidDate: {
      name: 'Zaplacena',
      align: ColumnAlign.center,
      columnFormatterType: ColumnFormatterTypes.dateFormatter
    },
    note: { name: 'Poznámky', align: ColumnAlign.left },
    emailSentAt: { name: 'Odeslána emailem', columnFormatterType: ColumnFormatterTypes.datetimeFormatter },
    actions: {
      name: 'Akce',
      columnFormatterType: ColumnFormatterTypes.actionsObject,
      align: ColumnAlign.left,
      headerStyle: { width: '160px' }
    }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    isCancelled: x => x.isCancelled,
    type: x => accountingDocumentsTranslation[x.type],
    createdAt: x => formatDateTimeForDisplayShort(x.createdAt),
    amountInclVatKc: x => x.amountInclVatKc,
    variableSymbol: x => x.variableSymbol,
    dueDate: x => x.dueDate,
    paidDate: x => x.datePaid,
    note: x => x.note,
    emailSentAt: x => x.emailSentAt,
    actions: x => {
      const ret: IRowAction[] = []
      addInvoiceActions(ret, x)
      if (!x.isCancelled)
        ret.push({
          type: RowActionType.codeAction,
          value: 'cancelAccountingDocument',
          icon: 'fe-x-square',
          title: 'Storno faktury'
        })
      if (!x.isCancelled)
        ret.push({
          type: RowActionType.codeAction,
          value: 'sendInvoiceByEmail',
          icon: 'fe-mail',
          title: 'Odeslat zákazníkovi fakturu emailem'
        })
      return ret
    }
  }
}
