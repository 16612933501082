import React, { useContext, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import FormField, { inputType } from '../../../../components/formComponents/FormField'
import ToggleField from '../../../../components/formComponents/ToggleField'
import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import LoadingContainer from '../../../../components/LoadingContainer'
import { isAnyQueryLoading } from '../../../../services/functions/queryHelpers'
import { useSupplierBranchListQuery } from '../../queries/supplier-branch-list.query'
import { SupplierBranchContact, SupplierBranchInputArgs } from '../../../../graphql/aminTypes'
import AddressPicker from '../../../../components/formComponents/AddressPicker/AddressPicker'
import { nullFieldsWithEmptyStrings } from '../../../../services/formsServices/null-fields-with-empty-strings'
import { useUpsertSupplierBranchMutation } from './mutations/upsert-supplier-branch.mutation'
import SuggestPou from '../SuggestPou/SuggestPou'
import { Alert, alertType } from '../../../../components/formComponents/alert'
import WorkingHours from '../WorkingHours/WorkingHours'
import { SelectedSupplierModalContext } from '../../../SelectedSupplierModal/SelectedSupplierModal'
import { useFindIczQuery } from './queries/find-icz.query'
import { MdOutlinePersonSearch } from 'react-icons/md'
import AddressGpsPicker from '../../../../components/formComponents/AddressPicker/AddressGpsPicker'
import { removeTypenameFromArray, setupFields } from '../../../../services/formsServices/setupFields'
import SelectField from '../../../../components/formComponents/SelectField'

export default function SupplierBranchEditor({ supplierBranchId, exitHandler }: { supplierBranchId?: string | undefined; exitHandler: () => void }) {
  const formMethods = useForm<SupplierBranchInputArgs>({
    defaultValues: {
      isActive: true,
      defaultMetrakMargin: 17,
      'paymentCapabilities.CASH': false,
      'orderNotificationOptions.EMAIL': true,
      'workingHours.0.isWorking': true,
      'workingHours.1.isWorking': true,
      'workingHours.2.isWorking': true,
      'workingHours.3.isWorking': true,
      'workingHours.4.isWorking': true,
      'workingHours.5.isWorking': false,
      'workingHours.6.isWorking': false,
      'workingHours.7.isWorking': false,
      useGpsAddressInput: false
    } as any
  })

  const supplierBranchListQR = useSupplierBranchListQuery({ id: supplierBranchId }, { skip: supplierBranchId === undefined })
  const [upsertSupplierBranchMut, upsertSupplierBranchQR] = useUpsertSupplierBranchMutation()
  const { state } = useContext(SelectedSupplierModalContext)
  const findIczQR = useFindIczQuery(
    state.selectedSupplier?.companyIdentification,
    formMethods.watch('useGpsAddressInput') ? undefined : formMethods.watch('address')?.city
  )
  const iczSuggestList = useMemo(() => {
    return findIczQR.data?.findIcz.map(item => ({ value: item.iczNumber, label: `${item.iczNumber} - ${item.name} (${item.address}) [${item.type}]` })) ?? []
  }, [findIczQR, findIczQR.data])

  useEffect(() => {
    if (supplierBranchListQR.data?.supplierBranchList.items.length === 1) {
      const data = supplierBranchListQR.data?.supplierBranchList.items[0]!
      let transformedData: any = {
        ordersContact: {},
        paymentCapabilities: {},
        orderNotificationOptions: {},
        extraServices: {},
        workingHoursOn: {},
        settings: {}
      }
      transformedData['setupIsFinal'] = data.setupIsFinal
      transformedData['setupIsFinal'] = data?.setupIsFinal
      transformedData['isActive'] = data.isActive
      transformedData['useGpsAddressInput'] = data.useGpsAddressInput
      transformedData['specification'] = data.specification
      transformedData['icz'] = data.icz
      transformedData['defaultMetrakMargin'] = data.defaultMetrakMargin
      transformedData['address'] = { ...data['address'], lat: `${data.gps.lat}`, lng: `${data.gps.lng}` }
      delete transformedData['address'].__typename
      if (data.pou?.id) transformedData['pouId'] = data.pou.id
      transformedData['workingHours'] = removeTypenameFromArray(data.workingHours)
      transformedData['vacations'] = removeTypenameFromArray(data.vacations)
      for (const capability of data['paymentCapabilities'] ?? []) transformedData[`paymentCapabilities`][capability] = true
      for (const notify of data['orderNotificationOptions'] ?? []) transformedData[`orderNotificationOptions`][notify] = true
      for (const extraService of data['extraServices'] ?? []) transformedData[`extraServices`][extraService] = true
      formMethods.reset(transformedData)
      setupFields(data['settings'], {
        formMethods,
        includeKeys: [
          'sendAllEmailsAlsoToContactPerson',
          'sendMaterialOrderWhenPaidAndIAmNotTransportingOrder',
          'sendZpoToContactAutomaticallyAfterApprovalByCarrier',
          'sendZpoToCarrier',
          'hasContractWithMetrak'
        ],
        prefix: 'settings'
      })
      setupFields(data.notes, { formMethods, includeKeys: ['zpoNote', 'internalNote', 'carrierNote'], prefix: 'notes' })
      if (data.contacts && Object.keys(data.contacts).length > 0) {
        Object.keys(data.contacts).forEach(key =>
          setupFields((data.contacts as SupplierBranchContact)[key], { formMethods, includeKeys: ['name', 'email', 'phone'], prefix: `contacts.${key}` })
        )
      }
      setupFields(data.orderDeliverySettings, {
        formMethods,
        includeKeys: ['allowOrdersForToday', 'requiredDaysBeforeOrderStart', 'shiftOrderPossibleStartByOneFromTodayTime', 'todayDeliveryAvailableTillTime'],
        prefix: 'orderDeliverySettings'
      })
    }
  }, [formMethods.setValue, supplierBranchListQR.data])

  const useGpsAddressInput = formMethods.watch('useGpsAddressInput')
  return (
    <LoadingContainer showLoader={isAnyQueryLoading(supplierBranchListQR, upsertSupplierBranchQR, findIczQR)}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
          <div className="row mb-2">
            <div className="col-md-3">
              <ToggleField name={'isActive'} label={'Aktivní'} />
            </div>
            <div className="col-md-6">
              <ToggleField name={'setupIsFinal'} label={'Finálně nastaveno'} />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-12">
              <ToggleField name={'useGpsAddressInput'} label={'Zadat adresu přes GPS souřadnice'} />
            </div>
            <div className="col-md-8">
              <>
                {(!useGpsAddressInput || formMethods.watch('address')?.lat) && <AddressPicker name={'address'} label={'Adresa'} />}
                {useGpsAddressInput && !formMethods.watch('address')?.lat && <AddressGpsPicker name={'address'} label={'Adresa'} />}
              </>
            </div>
            <div className="col-md-4">
              <SuggestPou loadedValue={supplierBranchListQR.data?.supplierBranchList?.items[0]?.pou?.name} /> <br />
              <SelectField name={'icz'} label={'IČZ'} isLoading={findIczQR.loading} optionsData={iczSuggestList} isCreatable />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-4">
              <FormField type={inputType.text} name={'contacts.main.name'} label={'Kontaktní jméno'} />
            </div>
            <div className="col-md-4">
              <FormField type={inputType.text} name={'contacts.main.email'} label={'Kontaktní Email'} />
            </div>
            <div className="col-md-4">
              <FormField type={inputType.text} name={'contacts.main.phone'} label={'Kontaktní telefon'} />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-4">
              <FormField type={inputType.text} name={'contacts.transport.name'} label={'Doprava jméno'} />
            </div>
            <div className="col-md-4">
              <FormField type={inputType.text} name={'contacts.transport.email'} label={'Doprava Email'} />
            </div>
            <div className="col-md-4">
              <FormField type={inputType.text} name={'contacts.transport.phone'} label={'Doprava telefon'} />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-4">
              <FormField type={inputType.text} name={'contacts.shop.name'} label={'Prodejna jméno'} />
            </div>
            <div className="col-md-4">
              <FormField type={inputType.text} name={'contacts.shop.email'} label={'Prodejna Email'} />
            </div>
            <div className="col-md-4">
              <FormField type={inputType.text} name={'contacts.shop.phone'} label={'Prodejna telefon'} />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-4">
              <FormField type={inputType.text} name={'contacts.weight.name'} label={'Váha jméno'} />
            </div>
            <div className="col-md-4">
              <FormField type={inputType.text} name={'contacts.weight.email'} label={'Váha Email'} />
            </div>
            <div className="col-md-4">
              <FormField type={inputType.text} name={'contacts.weight.phone'} label={'Váha telefon'} />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-4">
              <FormField type={inputType.text} name={'contacts.zpo.name'} label={'ZPO jméno'} />
            </div>
            <div className="col-md-4">
              <FormField type={inputType.text} name={'contacts.zpo.email'} label={'ZPO Email'} />
            </div>
            <div className="col-md-4">
              <FormField type={inputType.text} name={'contacts.zpo.phone'} label={'ZPO telefon'} />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-6">
              <FormField type={inputType.text} name={'specification'} label={'Upřesnění'} />
            </div>
            <div className="col-md-12">
              <FormField type={inputType.text} name={'notes.internalNote'} label={'Poznámka pro Metrák'} />
            </div>
            <div className="col-md-12">
              <FormField type={inputType.text} name={'notes.carrierNote'} label={'Poznámka pro dopravce'} />
            </div>
            <div className="col-md-12">
              <FormField type={inputType.text} name={'notes.zpoNote'} label={'Poznámka pro ZPO'} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <FormField type={inputType.number} name={'defaultMetrakMargin'} label={'Výchozí marže pro Metrák'} valueAsNumber />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <ToggleField name={'settings.sendAllEmailsAlsoToContactPerson'} label={'Odesílat všechny emaily i na kontaktní osobu'} />
            </div>
            <div className="col-md-12">
              <ToggleField
                name={'settings.sendMaterialOrderWhenPaidAndIAmNotTransportingOrder'}
                label={'Odeslat objednávku na prodej materiálu automaticky po schválení objednávky dopravcem (pokud pobočka nezařizuje i dopravu)'}
              />
            </div>
            <div className="col-md-12">
              <ToggleField
                name={'settings.sendZpoToContactAutomaticallyAfterApprovalByCarrier'}
                label={'Odeslat ZPO na kontakt ZPO automaticky po schválení objednávky dopravcem'}
              />
            </div>
            <div className="col-md-12">
              <ToggleField name={'settings.sendZpoToCarrier'} label={'Odesílat ZPO dopravci'} />
            </div>
            <div className="col-md-12">
              <ToggleField name={'settings.hasContractWithMetrak'} label={'Zasmluvněno, hradí Metrák'} />
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-5">
              <h3>Připomínat realizaci služby v daný den pomocí</h3>
              <ToggleField name={'orderNotificationOptions.EMAIL'} label={'E-mailem'} />
              <br />
              <ToggleField name={'orderNotificationOptions.SMS'} label={'SMS'} />
              <br />
              <ToggleField name={'orderNotificationOptions.WHATSAPP'} label={'Whatsapp'} />
            </div>
            <div className="col-md-3">
              <h3>Dobírka</h3>
              <ToggleField name={'paymentCapabilities.CASH'} label={'Hotovost'} />
              <br />
              <ToggleField name={'paymentCapabilities.CARD'} label={'Kartou'} />
            </div>
            <div className="col-md-4">
              <h3>Extra služby</h3>
              <ToggleField name={'extraServices.OFFERS_EARTHWORKS'} label={'Nabízí zemní práce'} />
              <br />
              <ToggleField name={'extraServices.OFFERS_CLEARANCE'} label={'Nabízí vyklízení'} />
              <br />
              <ToggleField name={'extraServices.OFFERS_SPACE_ALLOCATION'} label={'Zábory'} />
            </div>
          </div>
          <hr />
          <div className="row mb-3">
            <div className="col-md-12">
              <h2>Rychlost objednání</h2>
            </div>
            <div className="col-md-3 pt-3">
              <ToggleField name={'orderDeliverySettings.allowOrdersForToday'} label={'Umožnit objednávky na dnešní den'} />
            </div>
            <div className="col-md-4">
              <FormField
                type={inputType.text}
                name={'orderDeliverySettings.todayDeliveryAvailableTillTime'}
                label={'Na dnešní den je možné objednat do'}
                maxLength={5}
                defaultValue={'11:00'}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-6">
              <FormField
                type={inputType.number}
                name={'orderDeliverySettings.requiredDaysBeforeOrderStart'}
                label={'Počet celých dní, které potřebujeme na zpracování objednávky'}
                maxLength={1}
                isPositive
                defaultValue={1}
                valueAsNumber
              />
            </div>
            <div className="col-md-6">
              <FormField
                type={inputType.text}
                name={'orderDeliverySettings.shiftOrderPossibleStartByOneFromTodayTime'}
                label={'Možný termín odvozu se posouvá o +1 den od dnešních'}
                maxLength={5}
                defaultValue={'16:00'}
              />
            </div>
            <div className="col-md-12 mt-1">
              <Alert type={alertType.info} message={'Při počtu dní 0, lze objednat na další den do hodiny uvedené ve vedlejším poli.'} />
            </div>
          </div>
          <hr />
          <WorkingHours />
          <div className="form-footer">
            <button type="submit" className="btn btn-primary w-100">
              {supplierBranchId ? 'Uložit' : 'Přidat'}
            </button>
          </div>
        </form>
      </FormProvider>
    </LoadingContainer>
  )

  function handleFormSubmit(data: SupplierBranchInputArgs) {
    const supplierId = state.selectedSupplier?.id
    if (!supplierId) return
    const transformedData: SupplierBranchInputArgs = {
      address: data.address,
      settings: data.settings,
      defaultMetrakMargin: data.defaultMetrakMargin,
      extraServices: Object.keys(data.extraServices).filter(key => data.extraServices[key]),
      icz: data.icz,
      isActive: data.isActive,
      notes: data.notes,
      orderDeliverySettings: data.orderDeliverySettings,
      orderNotificationOptions: Object.keys(data.orderNotificationOptions).filter(key => data.orderNotificationOptions[key]),
      contacts: {
        main:
          data.contacts!.main?.name || data.contacts!.main?.email || data.contacts!.main?.phone ? nullFieldsWithEmptyStrings(data.contacts!.main) : undefined,
        shop:
          data.contacts!.shop?.name || data.contacts!.shop?.email || data.contacts!.shop?.phone ? nullFieldsWithEmptyStrings(data.contacts!.shop) : undefined,
        zpo: data.contacts!.zpo?.name || data.contacts!.zpo?.email || data.contacts!.zpo?.phone ? nullFieldsWithEmptyStrings(data.contacts!.zpo) : undefined,
        transport:
          data.contacts!.transport?.name || data.contacts!.transport?.email || data.contacts!.transport?.phone
            ? nullFieldsWithEmptyStrings(data.contacts!.transport)
            : undefined,
        weight:
          data.contacts!.weight?.name || data.contacts!.weight?.email || data.contacts!.weight?.phone
            ? nullFieldsWithEmptyStrings(data.contacts!.weight)
            : undefined
      },

      paymentCapabilities: Object.keys(data.paymentCapabilities).filter(key => data.paymentCapabilities[key]),
      pouId: data.pouId,
      setupIsFinal: data.setupIsFinal,
      specification: data.specification,
      useGpsAddressInput: data.useGpsAddressInput,
      vacations: data.vacations,
      workingHours: data.workingHours
    }
    const resultPromise = upsertSupplierBranchMut({ variables: { id: supplierBranchId, supplierId: supplierId, data: transformedData } })
    processPageOperation({
      promise: resultPromise,
      successAction: exitHandler,
      successMessage: 'Pobočka byla úspěšně uložena'
    })
    return false
  }
}
