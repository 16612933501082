import { gql, useQuery } from '@apollo/client'

const terminalsDetailQuery = gql`
  query terminals($id: String) {
    terminals(id: $id) {
      id
      name
      city
      street
      streetNumber
      zip
      gps {
        coordinates
      }
      phone
      email
      web
      contactPerson
      companyIdentification
      operationRadiusM
    }
  }
`
export type TerminalDetailItemType = {
  id: number
  name: string
  city: string
  street: string
  streetNumber: string
  zip: string
  gps: {
    coordinates: number[]
  }
  companyIdentification: string
  contactPerson: string
  email: string
  phone: string
  web: string
  operationRadiusM: number
}

export function useTerminalsDetailQuery(id: string | null, setupFields: (data: { terminals: TerminalDetailItemType[] }) => void) {
  return useQuery<{ terminals: TerminalDetailItemType[] }>(terminalsDetailQuery, {
    fetchPolicy: 'network-only',
    variables: { id: id },
    skip: !id,
    onCompleted: setupFields
  })
}
