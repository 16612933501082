import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const updateFieldMutation = gql`
  mutation updateField($orderContentId: Int!, $field: UpdateFieldEnum!, $newValue: String!) {
    updateField(orderContentId: $orderContentId, field: $field, newValue: $newValue) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpdateFieldMutation() {
  return useMutation<{ updateField: IMutationResult }>(updateFieldMutation)
}

export enum updateFieldEnum {
  'ADMIN_NOTE' = 'ADMIN_NOTE'
}
