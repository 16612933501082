import { IRowAction, RowActionType } from './services/tableSpecificationTypes'

export function addInvoiceActions(ret: IRowAction[], x: any) {
  if (x.documentUrl)
    ret.push({
      type: RowActionType.link,
      value: x.documentUrl,
      icon: 'fe-download',
      title: 'Stažení faktury'
    })
  if (x.documentUrl)
    ret.push({
      type: RowActionType.link,
      value: x.documentWebUrl,
      icon: 'fe-external-link',
      title: 'Náhled faktury'
    })
  if (x.documentId !== 0 && x.documentId)
    ret.push({
      type: RowActionType.link,
      value: `https://app.vyfakturuj.cz/faktury/detail/${x.documentId}/`,
      icon: 'fe-edit-2',
      title: 'Editace faktury ve Vyfakturuj'
    })
  ret.push({
    type: RowActionType.codeAction,
    value: 'SyncAccountingDocument',
    icon: 'fe-refresh-cw',
    title: 'Opravit údaje podle vyfakturuj'
  })
  return ret
}
