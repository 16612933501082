import { SupplierContainerEntity } from '../../graphql/aminTypes'
import { FormProvider, useForm } from 'react-hook-form'
import { useUpdateSupplierContainerMutation } from './mutations/update-supplier-container.mutation'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import React from 'react'
import SelectField from '../../components/formComponents/SelectField'
import { useSupplierContainerRentRatesQuery } from './components/queries/supplier-container-rent-rates.query'

export function UpdateContainerRentRate({ row, hideModal, branchId }: { row: SupplierContainerEntity; branchId: string; hideModal: () => void }) {
  const formMethods = useForm({ defaultValues: { containerRentRateId: row.containerRentRateId } })
  const [updateSupplierContainerMut, updateSupplierContainerQR] = useUpdateSupplierContainerMutation()
  const rentRatesQR = useSupplierContainerRentRatesQuery(branchId)

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
        <div className="row mb-4">
          <div className="col-md-4">
            <SelectField
              name={'containerRentRateId'}
              label={'Sazba za pronájem'}
              optionsData={(rentRatesQR.data?.supplierContainerRentRates ?? []).map(x => ({
                label: x.name,
                value: x.id
              }))}
              registerOptions={{ required: true }}
              required
              isLoading={rentRatesQR.loading}
            />
          </div>
        </div>
        <div className="row ">
          <div className="col-md-5 offset-md-3">
            <button className={'btn btn-primary'} type={'submit'}>
              Upravit sazbu
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  )

  function handleFormSubmit(data: any) {
    const resultPromise = updateSupplierContainerMut({
      variables: {
        id: row.id,
        data: {
          ...row,
          containerRentRateId: data.containerRentRateId,
          __typename: undefined,
          container: undefined,
          containerRentRate: undefined,
          id: undefined
        }
      }
    })
    processPageOperation({
      promise: resultPromise,
      successMessage: 'Sazba byla změněna',
      successAction: hideModal
    })
  }
}
