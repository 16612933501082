import { gql, useMutation } from '@apollo/client'
import { BaseMutationResult } from '../../../../graphql/aminTypes'

const deleteAccountingDocumentMutation = gql`
  mutation deleteAccountingDocument($accountingGroupInvoiceId: ID!) {
    deleteAccountingDocument(accountingGroupInvoiceId: $accountingGroupInvoiceId) {
      status {
        reason
        result
      }
    }
  }
`

export function useDeleteAccountingDocumentMutation() {
  return useMutation<{ deleteAccountingDocument: BaseMutationResult }>(deleteAccountingDocumentMutation, {
    refetchQueries: ['accountingGroupInvoices']
  })
}
