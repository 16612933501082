import React, { useContext, useEffect } from 'react'
import { hasQueryArrayData, isAnyQueryLoading } from '../../../services/functions/queryHelpers'
import { ModalContext } from '../../../components/Modal/ModalProvider'
import { getParamFromUrl } from '../../../services/functions/getParamFromUrl'
import { FormProvider, useForm } from 'react-hook-form'
import { useCustomerAdvanceAccounts } from './queries/customer-advance-accounts.query'
import AddressPicker from '../../../components/formComponents/AddressPicker/AddressPicker'
import FormField, { inputType } from '../../../components/formComponents/FormField'
import { processPageOperation } from '../../../services/formsServices/pageOperationProcessor'
import LoadingContainer from '../../../components/LoadingContainer'
import BootstrapTable from 'react-bootstrap-table-next'
import useBootstrapTableDataMapper from '../../../services/bootstrapTable/useBootstrapTableDataMapper'
import CustomerAdvanceAccountInvoices from './CustomerAdvanceAccountInvoices'
import { useCreateAdvanceAccountMutation } from './mutations/create-advance-account.mutation'
import { advanceAccountsDataDefinition } from './services/advance-accounts-data-definition'
import { AccountingAdvanceAccountEntity, CustomerResponse } from '../../../graphql/aminTypes'
import CustomerAdvanceAccountTransactions from './CustomerAdvanceAccountTransactions'
import { useDeleteAdvanceAccountMutation } from './mutations/delete-advance-account.mutation'
import { useUpdateAdvanceAccountMutation } from './mutations/update-advance-account.mutation'
import cellEditFactory from 'react-bootstrap-table2-editor'

export default function CustomerAdvanceAccount({ customerName }: { customerName: string }) {
  const { showModal, hideModal } = useContext(ModalContext)
  const customerId = getParamFromUrl('customerId') as string | null
  const accountsQR = useCustomerAdvanceAccounts(customerId ?? undefined)
  const [createAdvanceAccountMut, createAdvanceAccountQR] = useCreateAdvanceAccountMutation()
  const [deleteAdvanceAccountMut, deleteAdvanceAccountQR] = useDeleteAdvanceAccountMutation()
  const [updateAdvanceAccountMut, updateAdvanceAccountQR] = useUpdateAdvanceAccountMutation()

  const formMethods = useForm<Record<string, any>>()
  const gps = formMethods.watch('actionAddress')
  const { generateColumnListForBootstrapTable, mapDataArrayToTable } =
    useBootstrapTableDataMapper<AccountingAdvanceAccountEntity>(advanceAccountsDataDefinition)

  const columnDefinition = generateColumnListForBootstrapTable({
    columnAction: handleTableClick,
    actionsReference: {
      DeleteAdvanceAccount: deleteAdvanceAccountHandler
    }
  })

  let advanceAccounts: any[] = []
  if (accountsQR.data && hasQueryArrayData(accountsQR)) advanceAccounts = mapDataArrayToTable(accountsQR.data.customerAdvanceAccounts)

  useEffect(() => {
    if (gps) formMethods.setValue('name', `${gps['city']}, ${gps['street']}`)
  }, [gps, formMethods])

  return (
    <LoadingContainer showLoader={isAnyQueryLoading(accountsQR, createAdvanceAccountQR, deleteAdvanceAccountQR, updateAdvanceAccountQR)}>
      <div className="table-responsive-xl">
        <BootstrapTable
          bootstrap4
          striped
          hover
          condensed
          classes="table-responsive-lg digiTable"
          noDataIndication={() => <>"Nejsou vytvořené žádné zálohové účty"</>}
          keyField="id"
          data={advanceAccounts}
          columns={columnDefinition}
          cellEdit={cellEditFactory({
            mode: 'click',
            blurToSave: true,
            autoSelectText: true,
            beforeSaveCell: updateAdvanceAccountHandler
          })}
        />
      </div>
      <h2>Nový zálohový účet</h2>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
          <fieldset className="form-fieldset bg-lime-lt">
            <div className="row ">
              <div className="col-md-12">
                <AddressPicker name={'actionAddress'} required registerOptions={{ required: 'Poloha nebyla nalezena.' }} label={'Adresa realizace'} />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-6">
                <FormField type={inputType.text} name={'name'} label={'Název akce'} />
              </div>
              <div className="col-md-6">
                <FormField type={inputType.text} name={'note'} label={'Poznámka'} />
              </div>
            </div>
            <div className="row ">
              <div className="col-md-2 mt-2 offset-9">
                <button type="submit" className="btn btn-primary ms-auto btn-lg">
                  {'Vytvořit zálohový účet'}
                </button>
              </div>
            </div>
          </fieldset>
        </form>
      </FormProvider>
    </LoadingContainer>
  )

  function handleFormSubmit(data: Record<string | number | symbol, any>) {
    const resultPromise = createAdvanceAccountMut({
      variables: {
        customerId,
        data
      }
    })

    processPageOperation({
      promise: resultPromise,
      successMessage: 'Nový zákaznický účet byl vytvořen',
      successAction: () => {
        formMethods.reset()
      }
    })
  }
  function handleTableClick(e: Event, column: number, columnIndex: number, row: any) {
    if (columnIndex === 5) showTransactionsHandler(row)
    if (columnIndex === 6) showInvoicesHandler(row)
    if (columnIndex === 2)
      window.open(`/order-list?page=1&city=${row['streetWithNumber']}&name=${customerName}&sortField=orderNumber&sortOrder=desc&limit=50`, '_blank')
  }

  function showInvoicesHandler(row: any) {
    showModal({
      title: `Faktury k akci ${row.address}`,
      hideFooter: true,
      modalContent: (
        <CustomerAdvanceAccountInvoices
          advanceAccountId={row.id}
          exitHandler={async () => {
            await Promise.all([accountsQR.refetch()]).then(() => hideModal())
          }}
        />
      )
    })
  }

  function showTransactionsHandler(row: any) {
    showModal({
      title: `Transakce k akci ${row.address}`,
      hideFooter: true,
      modalContent: (
        <CustomerAdvanceAccountTransactions
          advanceAccountId={row.id}
          exitHandler={async () => {
            await Promise.all([accountsQR.refetch()]).then(() => hideModal())
          }}
        />
      )
    })
  }
  function deleteAdvanceAccountHandler(row: any) {
    const promise = deleteAdvanceAccountMut({
      variables: {
        advanceAccountId: row.id
      }
    })
    processPageOperation({
      promise: promise,
      successMessage: 'Zálohový účet byl odstraněn.'
    })
  }
  async function updateAdvanceAccountHandler(oldValue, newValue, row, column, doneCallback) {
    if (!row.id || oldValue === newValue) return
    const promise = updateAdvanceAccountMut({ variables: { advanceAccountId: row.id, stringifiesUpdates: JSON.stringify({ [column.dataField]: newValue }) } })
    processPageOperation({
      promise: promise,
      successMessage: 'Změny byly uloženy'
    })
  }
}
