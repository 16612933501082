import SelectField from '../../../../components/formComponents/SelectField'
import React from 'react'
import { useProductMaterialListQuery } from './queries/product-material-list.query'

export function ProductMaterialSelector({ availableWastes, disabled, label }: { availableWastes?: string[]; disabled?: boolean; label: string }) {
  const productMaterialListQR = useProductMaterialListQuery()
  const filteredProductMaterials =
    availableWastes && availableWastes.length > 0
      ? productMaterialListQR.data?.productMaterialList.filter(x => availableWastes.includes(x.id)) || []
      : productMaterialListQR.data?.productMaterialList || []

  const productMaterials =
    filteredProductMaterials.map(x => ({
      value: x.id,
      disabled: !x.isActive,
      color: availableWastes ? (availableWastes.includes(x.id) ? 'black' : '#ccc') : 'black',
      label: x.name
    })) || []

  return <SelectField name={'materialId'} label={label} optionsData={productMaterials} disabled={disabled} required isLoading={productMaterialListQR.loading} />
}
