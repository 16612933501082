import { gql, useQuery } from '@apollo/client'
import { IGqlPagingQueryParams } from '../../../services/bootstrapTable/usePagingFromURLQuery'

const terminalsCountQuery = gql`
  query terminalsCount($offset: Int, $limit: Int, $order: String, $name: String, $disabled: String) {
    terminalsCount(offset: $offset, limit: $limit, order: $order, name: $name, disabled: $disabled)
  }
`

export function useTerminalsCountQuery(params: IGqlPagingQueryParams) {
  return useQuery<{ terminalsCount: number }>(terminalsCountQuery, { fetchPolicy: 'network-only', variables: params })
}
