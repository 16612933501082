import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../services/types/gqlTypes'
import { SupplierFlatRateType } from './supplierPriceListQuery'

const addSupplierFlatRateMutation = gql`
  mutation addSupplierFlatRate($productId: Int!, $flatPriceKc: Float!, $supplierPriceListId: Int!) {
    addSupplierFlatRate(supplierPriceListId: $supplierPriceListId, flatPriceKc: $flatPriceKc, productId: $productId) {
      data {
        flatPriceKc
        id
        product {
          id
          name
        }
      }
      status {
        reason
        result
      }
    }
  }
`

export interface AddSupplierFlatRateMutationResult extends IMutationResult {
  data: SupplierFlatRateType
}

export function useAddSupplierFlatRateMutation() {
  return useMutation<{ addSupplierFlatRate: AddSupplierFlatRateMutationResult }>(addSupplierFlatRateMutation)
}

export function createParamsForAddSupplierFlatRateMutation(supplierPriceListId, productId, flatPriceKc) {
  return {
    supplierPriceListId: supplierPriceListId,
    productId: productId,
    flatPriceKc: parseFloat(flatPriceKc),
  }
}
