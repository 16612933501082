/* istanbul ignore file */

export const colorsSchema1 = [
    '#1F77B4',
    '#FF7F0E',
    '#2CA02C',
    '#D62728',
    '#9467BD',
    '#8C564B',
    '#E377C2',
    '#7F7F7F',
    '#BCBD22',
    '#17BECF'
]
export const colorsSchema2 = [
    '#E38627',
    '#E2615C',
    '#BC5581',
    '#81578D',
    '#48547B',
    '#2F4858',
    '#C13C37',
    '#6A2135',
    '#1D7DDD',
    '#5E16A7',
    '#4F9CE8',
    '#16A7A7',
    '#16A716'
]
