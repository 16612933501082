import { gql, useQuery } from '@apollo/client'
import { IczSuggest, ListIczEntity, WasteFinal } from '../../../graphql/aminTypes'
import { contextEndpoint, EndpointEnum } from '../../../services/appConfiguration/contextEndpoint'

export const suggestIczQuery = gql`
  query suggestIcz($icz: String!) {
    suggestIcz(icz: $icz) {
      id
      iczNumber
      name
    }
  }
`

export function useSuggestIczQuery(isForSupplier: boolean) {
  return useQuery<{ suggestIcz: ListIczEntity[] }>(suggestIczQuery, {
    context: contextEndpoint(isForSupplier ? EndpointEnum.supplier : EndpointEnum.admin),
    skip: true
  })
}
