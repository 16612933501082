import { gql, useQuery } from '@apollo/client'
import { SupplierBranchEntity } from '../../../../../../graphql/aminTypes'
import { WasteCategoryType } from '../../../../../../services/types/waste-category'

export const getTerminalsWithWasteCategoryQuery = gql`
  query getTerminalsWithWasteCategory($wasteCategory: WasteCategory!) {
    getTerminalsWithWasteCategory(wasteCategory: $wasteCategory) {
      id
      specification
      address {
        id
        city
        street
        zip
      }
      pou {
        id
        name
      }
      supplier {
        id
        name
      }
    }
  }
`

export function useGetTerminalsWithWasteCategoryQuery(wasteCategory: WasteCategoryType) {
  return useQuery<{ getTerminalsWithWasteCategory: SupplierBranchEntity[] }>(getTerminalsWithWasteCategoryQuery, {
    fetchPolicy: 'network-only',
    variables: { wasteCategory }
  })
}
