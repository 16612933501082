import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../services/types/gqlTypes'

const deleteWasteCollectionLinkMutation = gql`
  mutation deleteWasteCollectionLink($id: ID!) {
    deleteWasteCollectionLink(id: $id) {
      status {
        reason
        result
      }
    }
  }
`

export function useDeleteWasteCollectionLinkMutation() {
  return useMutation<{ deleteWasteCollectionLink: IMutationResult }>(deleteWasteCollectionLinkMutation, { refetchQueries: ['supplierWasteCollectionLinks'] })
}
