import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const deleteSupplierServiceLoadTransportMutation = gql`
  mutation deleteSupplierServiceLoadTransport($id: ID!) {
    deleteSupplierServiceLoadTransport(id: $id) {
      status {
        reason
        result
      }
    }
  }
`

export function useDeleteSupplierServiceLoadTransportMutation() {
  return useMutation<{ deleteSupplierServiceLoadTransport: IMutationResult }>(deleteSupplierServiceLoadTransportMutation, {
    refetchQueries: ['supplierServiceLoadTransport']
  })
}
