import React, { useState } from 'react'
import { Column } from 'react-bootstrap-table-next'
import CustomTextFilterInner, { filterAction, ICustomFilterReturnValue } from './CustomTextFilterInner'

interface INumberRangeSelectorFilterParams {
  onFilter: (value: { from: ICustomFilterReturnValue; to: ICustomFilterReturnValue }) => void
  column: Column<string>
  defaultValueFrom?: string
  defaultValueTo?: string
}

export default function NumberRangeFilter({ onFilter, column, defaultValueFrom, defaultValueTo }: INumberRangeSelectorFilterParams) {
  const valueLinkFrom = useState(defaultValueFrom ? defaultValueFrom : '')
  const valueLinkTo = useState(defaultValueTo ? defaultValueTo : '')

  return (
    <>
      <CustomTextFilterInner
        placeholder={'Od'}
        valueLink={valueLinkFrom}
        onFilter={newValue => {
          runFiltration('from', newValue)
        }}
      />
      <CustomTextFilterInner
        placeholder={'Do'}
        valueLink={valueLinkTo}
        onFilter={newValue => {
          runFiltration('to', newValue)
        }}
      />
    </>
  )

  function runFiltration(type: string, value: ICustomFilterReturnValue) {
    if (type === 'from')
      onFilter({
        from: value,
        to: valueLinkTo[0] ? { action: filterAction.set, value: valueLinkTo[0] } : { action: filterAction.reset }
      })
    if (type === 'to') onFilter({ to: value, from: valueLinkFrom[0] ? { action: filterAction.set, value: valueLinkFrom[0] } : { action: filterAction.reset } })
  }
}
