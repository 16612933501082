import { useProductContainerCapacityQuery } from './queries/product-container-capacity.query'
import useBootstrapTableDataMapper from '../../services/bootstrapTable/useBootstrapTableDataMapper'
import cellEditFactory from 'react-bootstrap-table2-editor'
import { isAnyQueryLoading } from '../../services/functions/queryHelpers'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import { useDeleteProductContainerCapacityMutation } from './mutations/delete-product-container-capacity.mutation'
import { useUpsertProductContainerCapacityMutation } from './mutations/upsert-product-container-capacity.mutation'
import LoadingContainer from '../../components/LoadingContainer'
import BootstrapTable from 'react-bootstrap-table-next'
import React from 'react'
import { useProductWasteListFlatQuery } from './queries/product-waste-list.query'
import { ProductWasteEntityWithCapacity } from './types/product-waste-entity-with-capacity'
import { productContainerCapacityOfWasteDataDefinition } from './services/product-container-capacity-of-waste-data-definition'

/*For one container, show all wastes and their capacity in*/
export function ProductContainerCapacityOfContainer({ containerId }: { containerId: String }) {
  const containerCapacityQR = useProductContainerCapacityQuery({ containerId })
  const wasteListQR = useProductWasteListFlatQuery()
  const [deleteCapacityMut, deleteCapacityQR] = useDeleteProductContainerCapacityMutation()
  const [upsertCapacityMut, upsertCapacityQR] = useUpsertProductContainerCapacityMutation()

  const { generateColumnListForBootstrapTable, mapDataArrayToTable, nameForGqlTranslation } = useBootstrapTableDataMapper<ProductWasteEntityWithCapacity>(
    productContainerCapacityOfWasteDataDefinition
  )

  const columnDefinition = generateColumnListForBootstrapTable({
    actionsReference: {
      deleteCapacity: deleteCapacityHandler
    }
  })

  let tableData: any[] = []
  if (containerCapacityQR.data && wasteListQR.data) {
    const items: ProductWasteEntityWithCapacity[] = []
    for (const item of wasteListQR.data.productWasteListFlat) {
      const containerCapacity = containerCapacityQR.data.productContainerCapacity.find(x => x.wasteId === item.id)
      items.push({
        ...item,
        capacityTonne: containerCapacity?.capacityTonne ?? undefined,
        calcCapacityTonne: containerCapacity?.calcCapacityTonne ?? undefined,
        containerCapacityId: containerCapacity?.id
      })
    }
    tableData = mapDataArrayToTable(items)
  }

  return (
    <LoadingContainer showLoader={isAnyQueryLoading(wasteListQR, containerCapacityQR, deleteCapacityQR, upsertCapacityQR)}>
      <BootstrapTable
        bootstrap4
        striped
        condensed
        classes="table-responsive-lg digiTable"
        noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné objednávky"</>}
        keyField="id"
        data={tableData}
        columns={columnDefinition}
        cellEdit={cellEditFactory({
          mode: 'click',
          blurToSave: true,
          autoSelectText: true,
          beforeSaveCell: onCellUpdate
        })}
      />
    </LoadingContainer>
  )

  function onCellUpdate(oldValue, newValue, row, column) {
    if (!row.id || (oldValue === newValue && isNaN(newValue))) return false
    const promise = upsertCapacityMut({
      variables: {
        containerCapacityId: row.containerCapacityId,
        data: {
          capacityTonne: column.dataField === 'capacityTonne' ? parseFloat(newValue) : typeof row.capacityTonne !== 'number' ? undefined : row.capacityTonne,
          calcCapacityTonne:
            column.dataField === 'calcCapacityTonne' ? parseFloat(newValue) : typeof row.calcCapacityTonne !== 'number' ? undefined : row.calcCapacityTonne,
          containerId: containerId,
          wasteId: row.id
        }
      }
    })
    processPageOperation({
      promise: promise,
      successMessage: `Produkt '${row.name}' byl upraven.`
    })
  }

  function deleteCapacityHandler(row: any) {
    const promise = deleteCapacityMut({
      variables: {
        containerCapacityId: row.containerCapacityId
      }
    })
    processPageOperation({
      promise: promise,
      successMessage: 'Kapacita byla odstraněna'
    })
  }
}
