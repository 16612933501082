import { IReactSelectItem } from '../types/ReactSelect'

//'CZ', 'SK', 'PL', 'DE', 'AT', 'HU', 'SI', 'HR', 'IT', 'FR', 'BE', 'NL'
export const countryIsoOptions: IReactSelectItem[] = [
  {
    value: 'CZ',
    label: 'Česká republika'
  },
  {
    value: 'SK',
    label: 'Slovensko'
  },
  {
    value: 'PL',
    label: 'Polsko'
  },
  {
    value: 'DE',
    label: 'Německo'
  },
  {
    value: 'AT',
    label: 'Rakousko'
  },
  {
    value: 'HU',
    label: 'Maďarsko'
  },
  {
    value: 'SI',
    label: 'Slovinsko'
  },
  {
    value: 'HR',
    label: 'Chorvatsko'
  },
  {
    value: 'IT',
    label: 'Itálie'
  },
  {
    value: 'FR',
    label: 'Francie'
  },
  {
    value: 'BE',
    label: 'Belgie'
  },
  {
    value: 'NL',
    label: 'Nizozemsko'
  }
]
