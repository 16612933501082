import { FormProvider, useForm } from 'react-hook-form'
import React from 'react'
import FormField, { inputType } from '../../../components/formComponents/FormField'
import { processPageOperation } from '../../../services/formsServices/pageOperationProcessor'
import { isAnyQueryLoading } from '../../../services/functions/queryHelpers'
import PageCard from '../../../components/Cards/pageCard'
import {
    createParamsForCreateTerminalPriceListMutation,
    useCreateTerminalPriceListMutation
} from "./services/createTerminalPriceListMutation";
import {useUpdateTerminalPriceListMutation} from "./services/updateTerminalPriceList";
import TerminalPriceListRow from "./TerminalPriceListRow";
import {useTerminalPriceListQuery} from "./services/terminalPriceListQuery";
import WasteTypesSelector from "../../../components/dataComponents/WasteTypesSelector/WasteTypesSelector";

export default function TerminalPriceAndMaterialListEditor({
                                                    terminalId,
                                                    terminalPriceListId,
                                                    loadData,
                                                }: {
    terminalId: number
    terminalPriceListId?: number
    loadData?: any
}) {
    const formMethods = useForm({ defaultValues: { ...loadData, productId: loadData?.product?.id } })
    const [createPriceListMut, createPriceListQR] = useCreateTerminalPriceListMutation()
    const [updatePriceListMut, updatePriceListQR] = useUpdateTerminalPriceListMutation()
    const terminalPriceListsQR = useTerminalPriceListQuery(terminalId)

    const availableWasteTypesId = terminalPriceListsQR.data?.terminalPriceList.map(x => x.wasteType.id)

    return (
        <PageCard showLoader={isAnyQueryLoading(createPriceListQR, updatePriceListQR)} >
            <FormProvider {...formMethods}>
                <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
                    <div className="row ">
                        <div className="col-md-6 mb-2 offset-md-1">
                            <WasteTypesSelector disabled={!!terminalPriceListId} removeWasteIds={availableWasteTypesId}/>
                        </div>
                        <div className="col-md-3 mb-2">
                            <FormField
                                type={inputType.number}
                                isMoney
                                name={'pricePerTonneKc'}
                                label={'Cena za tunu [Kč]'}
                                required
                                registerOptions={{ required: true }}
                            />
                        </div>
                        <div className="col-md-2 text-right mt-3">
                        <button type="submit" className="btn btn-primary ms-auto btn-lg">
                            Uložit
                        </button>
                    </div>
                    </div>
                </form>
                <br/>
                <br/>
            </FormProvider>
            <div className="max-height500">
            <TerminalPriceListRow terminalId={terminalId} />
            </div>
        </PageCard>
    )

    function handleFormSubmit(data: Record<string | number | symbol, any>) {
        let resultPromise = terminalPriceListId
            ? updatePriceListMut({ variables: { terminalPriceListId: terminalPriceListId, ...createParamsForCreateTerminalPriceListMutation(data) } })
            : createPriceListMut({ variables: { terminalId: terminalId, ...createParamsForCreateTerminalPriceListMutation(data) } })

        processPageOperation({
            promise: resultPromise,
            successAction: () => {
                terminalPriceListsQR.refetch()
            }
        })

        return false
    }
}
