import { gql, useQuery } from '@apollo/client'

export const orderContentStatesQuery = gql`
  query orderContentStates {
    orderContentStates {
      id
      name
      sysName
    }
  }
`

export function useOrderContentStatesQuery() {
  return useQuery<{ orderContentStates: { id: number; name: string; sysName: string }[] }>(orderContentStatesQuery)
}
