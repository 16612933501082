import React, { useContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useGetOrderComments } from './queries/get-order-comments'
import { useAddOrderCommentMutation } from './mutations/add-order-comment'
import { OrderCommentItem } from './components/OrderCommentItem'
import UserAvatar from 'react-user-avatar'
import { avatarColor } from '../../components/appComponents/avatar-color'
import { LoginContext } from '../../services/loginAndToken/useLogin'
import FormField, { inputType } from '../../components/formComponents/FormField'
import LoadingContainer from '../../components/LoadingContainer'
import { isAnyQueryLoading } from '../../services/functions/queryHelpers'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'

export default function OrderComments({ orderId }: { orderId?: number }) {
  const login = useContext(LoginContext)
  const formMethods = useForm()
  const orderCommentsQR = useGetOrderComments(orderId)
  const [addOrderCommentMut, addOrderCommentMR] = useAddOrderCommentMutation()
  const currentUser = login?.currentJWT?.firstname + ' ' + login?.currentJWT?.lastname
  return (
    <LoadingContainer showLoader={isAnyQueryLoading(addOrderCommentMR, orderCommentsQR)}>
      <div className="card">
        <div className="card-body">
          <div className="divide-y-4">
            {orderCommentsQR.data?.getOrderComments?.map(comment => <OrderCommentItem key={comment.id} item={comment} />)}
            <div>
              <FormProvider {...formMethods}>
                <div className="row">
                  <div className="col-auto">
                    <span className="avatar">
                      <UserAvatar size={32} name={currentUser} colors={avatarColor(currentUser)} />
                    </span>
                  </div>
                  <div className="col">
                    <FormField type={inputType.text} name={'comment'} placeholder={'Napište svůj komentář'} />
                  </div>
                  <div className="col-2">
                    <button type="button" className="btn btn-primary ms-auto" onClick={() => handleFormSubmit()}>
                      <i className="fe fe-check pl-2 pt-1" />
                    </button>
                  </div>
                </div>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </LoadingContainer>
  )

  function handleFormSubmit() {
    const data = formMethods.getValues()
    if (!data.comment || !data.comment.trim()) return

    const promise = addOrderCommentMut({
      variables: {
        orderId: orderId,
        comment: data.comment
      }
    })
    processPageOperation({
      promise: promise,
      successAction: () => formMethods.reset({}),
      successMessage: `Komentář byl úspěšně přidán`
    })
  }
}
