import { gql, useMutation } from '@apollo/client'
import { BaseMutationResult } from '../../../../../graphql/aminTypes'

const deleteAccountingDocumentMutation = gql`
  mutation deleteAccountingDocument($accountingDocumentId: ID!) {
    deleteAccountingDocument(accountingDocumentId: $accountingDocumentId) {
      status {
        reason
        result
      }
    }
  }
`

export function useDeleteAccountingDocumentMutation() {
  return useMutation<{ deleteAccountingDocument: BaseMutationResult }>(deleteAccountingDocumentMutation, {
    refetchQueries: ['accountingDocuments']
  })
}
