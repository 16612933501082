import { gql, useQuery } from '@apollo/client'

const statisticsStatesQuery = gql`
  query statisticsStates($fromDate: String, $toDate: String){
    statisticsStates(fromDate: $fromDate, toDate: $toDate){
      count
      name
    }
  }
`

export function useStatisticsStatesQuery(params?: {fromDate?: string, toDate?: string}) {
  return useQuery<{ statisticsStates: StatisticsStatesType[] }>(statisticsStatesQuery, {variables: params})
}

export type StatisticsStatesType = {
  name: string
  count: number
}
