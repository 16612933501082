import { gql, useQuery } from '@apollo/client'
import { AccountingAdvanceAccountEntity, CustomerListPaginatedResponse } from '../../../../graphql/aminTypes'

export const customerAdvanceAccountsQuery = gql`
  query customerAdvanceAccounts($id: ID!) {
    customerAdvanceAccounts(customerId: $id) {
      id
      createdAt
      name
      address {
        id
        street
        streetNumber
        city
        zip
        gps {
          lat
          lng
        }
      }
      accountingDocuments {
        id
        amountInclVatKc
        documentId
        documentUrl
        documentWebUrl
        dueDate
        variableSymbol
      }
      note
      remainingKc
      transactions {
        id
        amountKc
        createdAt
        fioTransactionId
        newBalanceKc
        orderId
        type
      }
    }
  }
`

export function useCustomerAdvanceAccounts(id?: string) {
  return useQuery<{ customerAdvanceAccounts: AccountingAdvanceAccountEntity[] }>(customerAdvanceAccountsQuery, {
    fetchPolicy: 'network-only',
    variables: { id },
    skip: !id
  })
}
