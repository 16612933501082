import { gql, useQuery } from '@apollo/client'
import { AccountingGroupInvoiceResult } from '../../../../graphql/aminTypes'
import { IGqlPagingQueryParams } from '../../../../services/bootstrapTable/usePagingFromURLQuery'

export const accountingGroupInvoicesQuery = gql`
  query accountingGroupInvoices(
    $offset: Int
    $limit: Int
    $createdDateFrom: String
    $createdDateTo: String
    $customer: String
    $emailSentAt: String
    $externalReference: String
    $variableSymbol: String
  ) {
    accountingGroupInvoices(
      offset: $offset
      limit: $limit
      createdDateFrom: $createdDateFrom
      createdDateTo: $createdDateTo
      customer: $customer
      emailSent: $emailSentAt
      externalReference: $externalReference
      variableSymbol: $variableSymbol
    ) {
      total
      data {
        id
        documentId
        documentWebUrl
        documentUrl
        amountInclVatKc
        variableSymbol
        clientEmail
        emailSentAt
        externalReference
        createdDate
        dueDate
        duzpDate
        paidOn
        orders {
          id
          orderNumber
          firstname
          lastname
          companyName
        }
        customer {
          id
          companyName
          firstname
          lastname
        }
      }
    }
  }
`

export function useAccountingGroupInvoicesQuery(params: IGqlPagingQueryParams, onCompleted?: any) {
  return useQuery<{ accountingGroupInvoices: AccountingGroupInvoiceResult }>(accountingGroupInvoicesQuery, {
    fetchPolicy: 'network-only',
    variables: params,
    onCompleted
  })
}
