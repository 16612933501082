import { MRT_ColumnDef } from 'material-react-table'
import { moneyToLocaleString } from '../../../../../services/dataToString/decimalToLocaleString'
import { CalculationTypeEnum, PricePack } from '../../../../../graphql/aminTypes'
import { calculationResultTypeMap } from '../../../../../services/types/calculation-result-type'

export const calculationVisualizerColumnDefinition: MRT_ColumnDef<CalculationVisualizerRow, any>[] = [
  { header: 'Typ', accessorKey: 'type', maxSize: 100, Cell: ({ cell }) => calculationResultTypeMap[cell.getValue()] },
  { header: 'Dodavatel', accessorKey: 'supplier' },
  { header: 'Auto', accessorKey: 'truck', maxSize: 120 },
  { header: 'Kontejner', accessorKey: 'container', maxSize: 120 },
  { header: 'Cena bez DPH', accessorKey: 'priceWoVatKc', Cell: ({ cell }) => moneyToLocaleString(cell.getValue(), 'CZK', true), maxSize: 120 },
  {
    header: 'Cena s DPH',
    id: 'priceInclVatKc',
    accessorKey: 'priceInclVatKc',
    maxSize: 150,
    filterVariant: 'range-slider',
    filterFn: 'betweenInclusive',
    muiFilterSliderProps: {
      marks: true,
      max: 30_000, //custom max (as opposed to faceted max)
      min: 0, //custom min (as opposed to faceted min)
      step: 1_000,
      valueLabelFormat: value =>
        value.toLocaleString('cs-CZ', {
          style: 'currency',
          currency: 'CZK'
        })
    },
    Cell: ({ cell }) => moneyToLocaleString(cell.getValue(), 'CZK', true)
  },
  { header: 'Vzdálenost', accessorKey: 'distanceKm', Cell: ({ cell }) => `${cell.getValue()} km`, maxSize: 120 },
  { header: 'Koncovka/Prodejna', accessorKey: 'thirdPartySupplier' }
]

export const priceBreakdownColumns: MRT_ColumnDef<PricePack, any>[] = [
  { header: 'Položka', accessorKey: 'priceItemType.name' },
  { header: 'Částka', accessorKey: 'amount', Cell: ({ cell }) => moneyToLocaleString(cell.getValue(), 'CZK', false) },
  {
    header: 'Marže',
    accessorKey: 'marginDecimal',
    Cell: ({ cell }) => (cell.getValue() ? `${cell.getValue() * 100}%` : 'N/A')
  },
  { header: 'Výpočet', accessorKey: 'note' }
]

export type CalculationVisualizerRow = {
  id: string
  type: CalculationTypeEnum | string
  supplier: string
  truck: string
  container: string
  priceWoVatKc: number
  priceInclVatKc: number
  distanceKm: number
  thirdPartySupplier: string
  index: [number, number]
  priceBreakdown: PricePack[]
}
