import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const updateSupplierMutation = gql`
  mutation updateSupplier($supplierId: Int!, $data: SupplierDataInput!) {
    updateSupplier(supplierId: $supplierId, data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpdateSupplierMutation() {
  return useMutation<{ updateSupplier: IMutationResult }>(updateSupplierMutation)
}
