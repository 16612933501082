import { gql, useQuery } from '@apollo/client'
import { SupplierWasteCollectionSupplierLinkEntity } from '../../../../graphql/aminTypes'
import { WasteCategoryType } from '../../../../services/types/waste-category'

export const supplierWasteCollectionLinksQuery = gql`
  query supplierWasteCollectionLinks($branchServiceId: ID!, $wasteCategory: WasteCategory!) {
    supplierWasteCollectionLinks(branchServiceId: $branchServiceId, wasteCategory: $wasteCategory) {
      id
      includedKmInFlatRate
      transportFlatRateKc
      terminalBranch {
        id
        specification
        address {
          id
          city
          street
          zip
        }
        supplier {
          id
          name
        }
      }
    }
  }
`

export function useSupplierWasteCollectionLinksQuery(branchServiceId: string, wasteCategory: WasteCategoryType) {
  return useQuery<{
    supplierWasteCollectionLinks: SupplierWasteCollectionSupplierLinkEntity[]
  }>(supplierWasteCollectionLinksQuery, {
    fetchPolicy: 'network-only',
    variables: { branchServiceId, wasteCategory }
  })
}
