import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const createProductConcreteComponentMutation = gql`
  mutation createProductConcreteComponent($data: ProductConcreteComponentInputArgs!) {
    createProductConcreteComponent(data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useCreateProductConcreteComponentMutation() {
  return useMutation<{ createProductConcreteComponent: IMutationResult }>(createProductConcreteComponentMutation, {
    refetchQueries: ['productConcreteComponents']
  })
}
