import { gql, useMutation } from '@apollo/client'
import { BaseMutationResult } from '../../../../../../graphql/aminTypes'

const createGroupInvoiceForOrdersMutation = gql`
  mutation createGroupInvoiceForOrders($orderIds: [Int!]!, $opts: CreateGroupInvoiceOptsInput!) {
    createGroupInvoiceForOrders(orderIds: $orderIds, opts: $opts) {
      status {
        reason
        result
      }
    }
  }
`

export function useCreateGroupInvoiceForOrdersMutation() {
  return useMutation<{ createGroupInvoiceForOrders: BaseMutationResult }>(createGroupInvoiceForOrdersMutation, { refetchQueries: ['accountingGetGroupOrders'] })
}
