import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../services/types/gqlTypes'

const sendAdvanceAccountInvoiceEmailMutation = gql`
  mutation sendAdvanceAccountInvoiceEmail($accountingDocumentId: ID!) {
    sendAdvanceAccountInvoiceEmail(accountingDocumentId: $accountingDocumentId) {
      status {
        reason
        result
      }
    }
  }
`

export function useSendAdvanceAccountInvoiceEmailMutation() {
  return useMutation<{ sendAdvanceAccountInvoiceEmail: IMutationResult }>(sendAdvanceAccountInvoiceEmailMutation, {
    awaitRefetchQueries: true,
    refetchQueries: ['advanceAccountInvoices']
  })
}
