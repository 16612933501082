import { gql, useQuery } from '@apollo/client'

const statisticsTotalQuery = gql`
  query statisticsTotal($fromDate: String, $toDate: String){
    statisticsTotal(fromDate: $fromDate, toDate: $toDate){
      codCount
      ordersByB2b
      totalOrders
      distanceTotalKm
    }
  }
`

export function useStatisticsTotalQuery(params?: {fromDate?: string, toDate?: string}) {
  return useQuery<{ statisticsTotal: StatisticsTotalType }>(statisticsTotalQuery, {variables: params})
}

export type StatisticsTotalType = {
  codCount: number
  ordersByB2b: number
  totalOrders: number
  distanceTotalKm: number
}
