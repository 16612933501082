import { gql, useMutation } from '@apollo/client'
import { BaseMutationResult } from '../../../../graphql/aminTypes'

const forceFioTransactionDownloadMutation = gql`
  mutation forceFioTransactionDownload {
    forceFioTransactionDownload {
      status {
        reason
        result
      }
    }
  }
`

export function useForceFioTransactionDownloadMutation() {
  return useMutation<{ forceFioTransactionDownload: BaseMutationResult }>(forceFioTransactionDownloadMutation, {
    refetchQueries: ['getFioSyncLatestResult', 'getFioTransactionList']
  })
}
