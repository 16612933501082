export const selectRow = (handleOnSelect: Function, handleOnSelectAll: Function, selected: number[]) => ({
  mode: 'checkbox',
  clickToSelect: false,
  selected: selected,
  onSelect: handleOnSelect,
  onSelectAll: handleOnSelectAll,
  headerColumnStyle: {
    textAlign: 'center'
  }
})
