import { gql, useQuery } from '@apollo/client'
import { IGqlPagingQueryParams } from '../../../services/bootstrapTable/usePagingFromURLQuery'
import { CapabilityType } from '../../AddOrEditSupplier/services/suppliersDetailQuery'
import { PaymentMethod } from '../../../services/types/payment-method'
import { GoPayPaymentState, PaymentStatus } from '../../../services/types/payment-status'
import { ServiceType } from '../../../services/types/service-type'
import { AccountingDocument } from '../../../graphql/aminTypes'

export const orderList = gql`
  query orderContentList(
    $offset: Int
    $limit: Int
    $order: String
    $serviceType: ServiceTypeEnum
    $paymentStatus: String
    $paymentType: String
    $orderNumber: String
    $name: String
    $state: String
    $supplier: String
    $flags: FlagEnum
    $containerFromDateFrom: String
    $containerToDateFrom: String
    $containerFromDateTo: String
    $containerToDateTo: String
    $city: String
    $containerId: String
    $createdAt: String
    $createdAtFrom: String
    $createdAtTo: String
    $productId: String
    $b2bCustomerId: String
    $adminOwner: String
    $partnerSource: String
  ) {
    orderContentList(
      offset: $offset
      limit: $limit
      partnerSource: $partnerSource
      b2bCustomerId: $b2bCustomerId
      order: $order
      serviceType: $serviceType
      supplier: $supplier
      paymentStatus: $paymentStatus
      paymentType: $paymentType
      orderNumber: $orderNumber
      name: $name
      state: $state
      flags: $flags
      containerFromDateFrom: $containerFromDateFrom
      containerToDateFrom: $containerToDateFrom
      containerFromDateTo: $containerFromDateTo
      containerToDateTo: $containerToDateTo
      city: $city
      containerId: $containerId
      createdAt: $createdAt
      createdAtFrom: $createdAtFrom
      createdAtTo: $createdAtTo
      productId: $productId
      adminOwner: $adminOwner
    ) {
      total
      items {
        id
        isCancellableByAdmin
        serviceType
        weightTonnes
        codToCollect
        container {
          id
          name
          capacityM3
        }
        containerFromDate
        containerToDate
        arriveTime
        collectionTime
        onsiteContactFirstname
        onsiteContactLastname
        onsiteContactPhone
        containerCount
        distanceTotalKm
        supplierProfitWoVatKc
        adminNote
        supplierNote
        customerNote
        wasteDescription
        filesCount
        hasZpoDelivered
        hasWasteFinals
        approvedAt
        product {
          id
          name
        }
        orderContentStateReason
        totalForContentWoVatKc
        terminal {
          id
          name
        }
        supplier {
          id
          name
          email
          orpName
          regionCode
          capability
          zpoNote
        }
        orderContentState {
          id
          sysName
          name
        }
        orderContentPrices {
          id
          orderContentPriceItem {
            id
            sysName
          }
        }
        order {
          accountingDocuments {
            id
            documentUrl
            isGroupInvoice
            type
          }
          adminOwner {
            firstname
            lastname
          }
          id
          uuid
          partnerSource
          companyName
          createdAt
          createdById
          orderNumber
          goPayPaymentStatus
          firstname
          lastname
          email
          phone
          street
          streetNumber
          city
          zip
          totalWoVatKc
          totalInclVatKc
          paymentType
          paymentStatus
          orderNote
          requireCertificateState
          ico
          dic
          invoicingNote
          invoicingNoteDone
          b2bCustomerId
          unpaidNotificationSent
          gps {
            coordinates
          }
        }
      }
    }
  }
`

export type OrderContentResult = {
  total: number
  items: IOrderContentListItem[]
}

export interface IOrderContentListItem {
  id: number
  isCancellableByAdmin: boolean
  codToCollect: number
  container: {
    id: number
    name: string
    capacityM3: number
  }
  orderContentPrices: {
    id: number
    orderContentPriceItem: {
      id: number
      sysName: string
    }
  }[]
  containerFromDate: string
  containerToDate: string
  containerCount: number
  arriveTime: string
  approvedAt: string
  collectionTime?: string
  onsiteContactFirstname: string
  onsiteContactLastname: string
  onsiteContactPhone: string
  distanceTotalKm: number
  supplierProfitWoVatKc: number
  filesCount: number
  hasZpoDelivered: boolean
  hasWasteFinals: boolean
  product: {
    id: number
    name: string
  }
  totalForContentWoVatKc: number
  terminal: {
    id: number
    name: string
  }
  supplier: {
    id: number
    name: string
    email: string
    orpName: string
    regionCode: number
    capability: CapabilityType
    zpoNote: string
  }
  orderContentState: {
    id: number
    name: string
    sysName: string
  }
  adminNote: string
  supplierNote: string
  customerNote: string
  wasteDescription: string
  serviceType: ServiceType
  weightTonnes: number
  orderContentStateReason: string
  order: {
    adminOwner: {
      firstname: string
      lastname: string
    }
    accountingDocuments: AccountingDocument[]
    unpaidNotificationSent: boolean
    partnerSource: string
    id: number
    b2bCustomerId: number
    createdAt: string
    createdById: number
    uuid: string
    orderNumber: string
    goPayPaymentStatus: GoPayPaymentState
    companyName: string
    firstname: string
    lastname: string
    email: string
    phone: string
    street: string
    streetNumber: string
    city: string
    zip: string
    gps: { coordinates: number[] }
    totalWoVatKc: number
    totalInclVatKc: number
    requireCertificateState: number
    paymentType: PaymentMethod
    paymentStatus: PaymentStatus
    orderNote: string
    ico: string
    dic: string
    invoicingNote?: string
    invoicingNoteDone?: boolean
  }
}

export function useOrderListQuery(params: IGqlPagingQueryParams, onCompleted: any) {
  return useQuery<{ orderContentList: OrderContentResult }>(orderList, {
    fetchPolicy: 'network-only',
    variables: params,
    onCompleted
  })
}
