import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'
import { emailHistoryQuery } from '../components/EmailHistory/query/email-history.query'

const payOrderFromAdvanceAccountMutation = gql`
  mutation payOrderFromAdvanceAccount($orderId: ID!) {
    payOrderFromAdvanceAccount(orderId: $orderId) {
      status {
        reason
        result
      }
    }
  }
`

export function usePayOrderFromAdvanceAccountMutation() {
  return useMutation<{ payOrderFromAdvanceAccount: IMutationResult }>(payOrderFromAdvanceAccountMutation, {
    refetchQueries: ['orderDetail']
  })
}
