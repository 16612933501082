import { gql, useQuery } from '@apollo/client'
import { CustomerListPaginatedResponse } from '../../../../../graphql/aminTypes'

const customerListQuery = gql`
  query customerList($id: ID) {
    customerList(id: $id) {
      items {
        id
        companyIdentification
        companyTaxId
        companyName
        firstname
        lastname
        phone
        email
        b2bMarginCorrection
        metrakMarginCorrection
        city
        streetNumber
        street
        zip
        countryIso
        primaryPaymentMethod
        allowedPaymentMethods
        isPreRegisteredOnly
        requireCertificates
        isActive
        notes
      }
    }
  }
`

export function useCustomerListQuery(customerId?: string) {
  return useQuery<{ customerList: CustomerListPaginatedResponse }>(customerListQuery, {
    variables: { id: customerId ? customerId : undefined }
  })
}
