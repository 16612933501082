import { CheckAdvanceAccountStatusResponseEnum } from '../../graphql/aminTypes'

export const advanceAccountStatusMap: Record<CheckAdvanceAccountStatusResponseEnum, string> = {
  [CheckAdvanceAccountStatusResponseEnum.AdvanceAccountNotEnabled]: 'Uživatel nemá zapnuté používání zálohového účtu',
  [CheckAdvanceAccountStatusResponseEnum.NoMatchingAdvanceAccountFound]: 'Nenalezen zálohový účet na tuto adresu',
  [CheckAdvanceAccountStatusResponseEnum.WrongPaymentMethod]: 'Chybná platební metoda',
  [CheckAdvanceAccountStatusResponseEnum.MatchingAdvanceAccountFound]: 'Zálohový účet existuje pro tuto adresu',
  [CheckAdvanceAccountStatusResponseEnum.InsufficientBalance]: 'Nedostatečný zůstatek na zálohovém účtu',
  [CheckAdvanceAccountStatusResponseEnum.MatchingAdvanceAccountHasEnoughBalance]: 'Zálohový účet existuje a má dostatečný zůstatek',
  [CheckAdvanceAccountStatusResponseEnum.OrderIsNotForRegisteredCustomer]: 'Objednávka nemá přiřazeného registrovaného zákazníka'
}
