import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import FormField, { inputType } from '../../../../components/formComponents/FormField'
import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import { ContainerItemType, useContainerTypesQuery } from '../../../../services/queries/containersQuery'
import LoadingContainer from '../../../../components/LoadingContainer'
import { isAnyQueryLoading } from '../../../../services/functions/queryHelpers'
import { createParamsForUpsertContainerWasteType, useUpsertContainerWasteType } from './services/upsertContainerWasteTypeMutation'
import ProductsSelector, { ProductTypeEnum } from '../../../../components/dataComponents/ProductsSelector/ProductsSelector'

export default function AddWasteToContainer({ container, exitHandler }: { container: ContainerItemType; exitHandler: () => Promise<void> }) {
  const formMethods = useForm()
  const containersQR = useContainerTypesQuery()
  const [upsertContainerWasteTypeMut, upsertContainerWasteTypeQR] = useUpsertContainerWasteType()
  const currentContainer = containersQR.data?.containerTypes.find(x => x.id === container.id)
  return (
    <LoadingContainer showLoader={isAnyQueryLoading(containersQR, upsertContainerWasteTypeQR)}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
          <fieldset className="form-fieldset">
            <div className="row mb-2">
              <div className="col-md-6">
                <ProductsSelector required productType={ProductTypeEnum.isCollection} removeIds={currentContainer?.wasteCapacities.map(w => w.product.id)} />
              </div>
              <div className="col-md-6">
                <FormField type={inputType.number} isDecimal name={'capacityTonne'} label={'Kapacita [tuny]'} required registerOptions={{ required: true }} />
              </div>
            </div>
            <div className="form-footer">
              <button type="submit" className="btn btn-primary w-100">
                Přidat
              </button>
            </div>
          </fieldset>
        </form>
      </FormProvider>
    </LoadingContainer>
  )

  function handleFormSubmit(data: Record<string | number | symbol, any>) {
    let resultPromise = upsertContainerWasteTypeMut({
      variables: createParamsForUpsertContainerWasteType(container.id, data['productId'], data['capacityTonne'])
    })

    processPageOperation({
      promise: resultPromise,
      successAction: exitHandler,
      successMessage: 'Odpad byl přidán do kontejneru'
    })
    return false
  }
}
