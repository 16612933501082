import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetFioTransactionStateStatsQuery } from '../queries/get-transaction-state-stats.query'
import { pairingStatusBadgeColor, pairingStatusTranslation } from '../types/pairing-status'

export default function FioTransactionsStatsButtonRow({ setSelectedRows }: { setSelectedRows: any }) {
  const fioTransactionStateStatsQR = useGetFioTransactionStateStatsQuery()

  const statesCount = Object.keys(pairingStatusTranslation).map(key => {
    return {
      value: key,
      label: pairingStatusTranslation[key],
      count: fioTransactionStateStatsQR.data?.getFioTransactionStateStats?.find(x => x.status === key)?.count ?? 0,
      color: pairingStatusBadgeColor[pairingStatusTranslation[key]]
    }
  })
  const navigate = useNavigate()
  const filterPage = useCallback(
    (sys: string) => (event: any) => {
      setSelectedRows(new Set())
      navigate(sys ? '/accounting/transaction-pairing?pairingStatus=' + sys : '/accounting')
    },
    [navigate]
  )

  return (
    <div className="row mb-3">
      <div className="col-12">
        <div className="d-flex flex-row flex-wrap justify-content-md-around">
          {statesCount.map(x => (
            <button type="button" key={x.value} className={`btn m-1 btn-block ${x.color}`} onClick={filterPage(x.value)}>
              {x.label} ({x.count})
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}
