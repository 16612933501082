import {
    ColumnAlign,
    ColumnFormatterTypes,
    IRowAction,
    RowActionType,
    TableDefinitionTypes
} from '../../../../services/bootstrapTable/services/tableSpecificationTypes'
import {SupplierDeliveryPriceListType} from "./supplierDeliveryPriceListQuery";
import {moneyToLocaleString} from "../../../../services/dataToString/decimalToLocaleString";


export function supplierPriceListDeliveryDataDefinition(): TableDefinitionTypes<SupplierDeliveryPriceListType> {
    return {
        columnDefinition: {
            id: { hidden: true },
            uiOrder: { hidden: true, sort: true },
            disabled: {
                name: "Aktivní",
                columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
                align: ColumnAlign.center,
                headerStyle: { width: '50px' }
            },
            product: {
                name: "Materiál"
            },
            pricePerTonneKc: {
                name: "Cena za tunu [Kč]",
            },
            baseTransportPriceKc: {
                name: "Paušál za dovoz [Kč]"
            },
            baseTransportPriceBiggerTruckKc: {
                name: "Paušál za dovoz nad 6.5 tun [Kč]"
            },
            baseTransportRadiusKm: {
                name: "Ujetý radius pro paušál [Km]"
            },
            pricePerKmKc: {
                name: "Cena za km nad rámec paušálu [Kč]",
            },
            pricePerKmBiggerTruckKc: {
                name: "Cena za km nad 6.5 tun nad rámec paušálu [Kč]",
            },
            margin: {
                name: "Marže navíc",
                columnFormatterType: ColumnFormatterTypes.percentage,
            },
            actions: {
                name: 'Akce',
                columnFormatterType: ColumnFormatterTypes.actionsObject,
                align: ColumnAlign.right,
                headerStyle: { width: '100px' }
            }
        },
        sourceToColumnMapping: {
            id: x => x.id,
            uiOrder: x => x.product.uiOrder,
            disabled: x => !x.disabled,
            product: x => x.product.name,
            pricePerTonneKc: x => moneyToLocaleString(x.pricePerTonneKc),
            pricePerKmKc: x => moneyToLocaleString(x.pricePerKmKc),
            pricePerKmBiggerTruckKc: x => moneyToLocaleString(x.pricePerKmBiggerTruckKc),
            margin: x => x.margin,
            baseTransportPriceKc: x => moneyToLocaleString(x.baseTransportPriceKc),
            baseTransportPriceBiggerTruckKc: x => moneyToLocaleString(x.baseTransportPriceBiggerTruckKc),
            baseTransportRadiusKm: x => x.baseTransportRadiusKm,
            actions: x => {
                const ret: IRowAction[] = []
                ret.push({
                    type: RowActionType.codeAction,
                    value: 'editPriceList',
                    icon: 'fe-edit',
                    title: `Upravit ${x.product.name}`
                })
                ret.push({
                    type: RowActionType.codeAction,
                    value: 'deletePriceList',
                    icon: 'fe-x',
                    title: `Odstranit ${x.product.name}`
                })
                return ret
            }
        }
    }
}
