import { gql, useQuery } from '@apollo/client'
import { IGqlPagingQueryParams } from '../../../../services/bootstrapTable/usePagingFromURLQuery'
import { FioTransactionListResult } from '../../../../graphql/aminTypes'

export const getFioTransactionListQuery = gql`
  query getFioTransactionList(
    $offset: Int
    $limit: Int
    $transactionDateFrom: String
    $transactionDateTo: String
    $pairingStatus: PairingStatusEnum
    $processingStatus: ProcessingStatusEnum
    $variableSymbol: String
    $payerName: String
    $message: String
    $orderBy: String
  ) {
    getFioTransactionList(
      offset: $offset
      limit: $limit
      transactionDateFrom: $transactionDateFrom
      transactionDateTo: $transactionDateTo
      pairingStatus: $pairingStatus
      processingStatus: $processingStatus
      variableSymbol: $variableSymbol
      payerName: $payerName
      message: $message
      orderBy: $orderBy
    ) {
      total
      data {
        id
        amount
        currency
        message
        payerAccountNumber
        payerName
        paymentType
        processingStatus
        variableSymbol
        transactionDate
        transactionId
        status
        groupInvoiceId
        order {
          id
          orderNumber
        }
      }
    }
  }
`

export function useFioTransactionListQuery(params: IGqlPagingQueryParams, onCompleted: any) {
  return useQuery<{ getFioTransactionList: FioTransactionListResult }>(getFioTransactionListQuery, {
    fetchPolicy: 'network-only',
    variables: params,
    onCompleted
  })
}
